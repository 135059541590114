import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_form = _resolveComponent("create-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_create_form, {
      "is-create-modal-opened": _ctx.isCreateModalOpened,
      "refresh-data": _ctx.getAllCompanies,
      onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCreateModalOpened = false))
    }, null, 8, ["is-create-modal-opened", "refresh-data"]),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 0,
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "object-id": _ctx.selectedCompany.id,
          "refresh-data": _ctx.getAllCompanies,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditModalOpened = false))
        }, null, 8, ["is-edit-modal-opened", "object-id", "refresh-data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_delete_modal, {
      "is-deleting": _ctx.isDeleting,
      selectedData: _ctx.selectedCompany,
      "delete-text": "Are you sure you want to delete organization ",
      onToggleModal: _ctx.toggleDeleteModal,
      onCancelDelete: _ctx.toggleDeleteModal,
      onConfirmDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openConfirmationModal('delete')))
    }, null, 8, ["is-deleting", "selectedData", "onToggleModal", "onCancelDelete"]),
    _createVNode(_component_confirmation_modal, {
      "delete-text": _ctx.confirmationModalText,
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "number-of-devices": _ctx.selectedRows.length,
      onConfirmAction: _ctx.confirmationModalAction,
      onCloseConfirmationModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
    }, null, 8, ["delete-text", "is-confirmation-modal-opened", "number-of-devices", "onConfirmAction"]),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, {
              key: 0,
              "inner-ring-thickness": "border-t-8",
              "outer-ring-thickness": "border-8"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateCompany,
                title: _ctx.$t('Organizations.Title'),
                description: " ",
                "button-text": "Create",
                onExecuteMethod: _ctx.toggleCreateModal
              }, null, 8, ["permission-id", "title", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                options: _ctx.options,
                "table-data": _ctx.companiesPagination.items,
                "table-headers": _ctx.tableHeaders,
                onSelectedRows: _ctx.getSelectedRows,
                onForceRefresh: _ctx.getAllCompanies
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onForceRefresh"]),
              _createVNode(_component_pagination, {
                "pagination-data": _ctx.companiesPagination,
                onPageChange: _ctx.handlePageChange,
                onPageSizeChange: _ctx.handlePageSizeChange
              }, null, 8, ["pagination-data", "onPageChange", "onPageSizeChange"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}