import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d1dd9a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex space-x-4 items-center" }
const _hoisted_2 = {
  key: 0,
  class: "w-8 h-8 rounded-full flex items-center justify-center bg-successlight"
}
const _hoisted_3 = {
  key: 1,
  class: "w-8 h-8 rounded-full flex items-center justify-center bg-errorlight"
}
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "flex space-x-4 items-center" }
const _hoisted_6 = {
  key: 0,
  class: "w-8 h-8 rounded-full flex items-center justify-center bg-successlight"
}
const _hoisted_7 = {
  key: 1,
  class: "w-8 h-8 rounded-full flex items-center justify-center bg-errorlight"
}
const _hoisted_8 = { class: "font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Motion = _resolveComponent("Motion")!

  return (Array.isArray(_ctx.message) && _ctx.showToast)
    ? (_openBlock(), _createBlock(_component_Motion, {
        key: 0,
        animate: { opacity: 1, x: -32 },
        class: _normalizeClass(` absolute top-8 -right-4 space-y-4  ${_ctx.showToast && 'z-[150]'}`),
        initial: { opacity: 0, x: 200 },
        transition: {
      duration: 0.3,
      easing: 'ease-in-out',
    }
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message, (error, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "space-y-4"
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(`py-3 px-4 w-[450px] bg-white  rounded-md flex justify-between shadow-lg  ${_ctx.isError ? 'error' : 'success'}`)
              }, [
                _createElementVNode("div", _hoisted_1, [
                  (!_ctx.isError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'check'],
                          class: "text-success text-xl"
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['fas', 'times'],
                          class: "text-error text-xl"
                        })
                      ])),
                  _createElementVNode("div", null, [
                    _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.isError ? "Error" : "Success"), 1),
                    _createElementVNode("p", null, _toDisplayString(error), 1)
                  ])
                ]),
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeToast && _ctx.closeToast(...args)))
                }, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'times'],
                    class: "text-dark text-sm cursor-pointer hover:text-error absolute top-4 right-4"
                  })
                ])
              ], 2)
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["class"]))
    : (!Array.isArray(_ctx.message) && _ctx.showToast)
      ? (_openBlock(), _createBlock(_component_Motion, {
          key: 1,
          animate: { opacity: 1, x: -32, scale: 1 },
          class: _normalizeClass(`py-3 px-4 w-[450px] bg-white  rounded-md flex justify-between shadow-lg  ${
      _ctx.isError ? 'error' : 'success'
    } absolute top-8 right-4 space-y-4  ${_ctx.showToast ? 'z-[150]' : 'z-[1]'} `),
          initial: { opacity: 0, x: 100, scale: 0.9 },
          transition: {
      duration: 0.3,
      easing: 'ease-in-out',
    }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              (!_ctx.isError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['fas', 'check'],
                      class: "text-success text-xl"
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['fas', 'times'],
                      class: "text-error text-xl"
                    })
                  ])),
              _createElementVNode("div", null, [
                _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.isError ? "Error" : "Success"), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
              ])
            ]),
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeToast && _ctx.closeToast(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'times'],
                class: "text-dark text-sm cursor-pointer hover:text-error absolute top-4 right-4"
              })
            ])
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true)
}