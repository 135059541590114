<template>
  <last-data-preview
    v-if="isDataPreviewModalOpened"
    :data="selectedMetadata"
    :is-last-data-modal-opened="isDataPreviewModalOpened"
    :name="selectedDeviceTypes[0]?.name"
    :preview="true"
    @toggle-modal="isDataPreviewModalOpened = false"
  />
  <create-form
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getDeviceTypes"
    @toggle-modal="isCreateModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :device-type="selectedDeviceTypes[0]"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getDeviceTypes"
    @toggle-modal="isEditModalOpened = false"
  />
  <create-relation-modal
    v-if="isCreateRelationModalOpened"
    :device-type-id-list="deviceTypeIdList"
    :is-create-relation-modal-opened="isCreateRelationModalOpened"
    :refresh-data="getDeviceTypes"
    @toggle-modal="isCreateRelationModalOpened = false"
  />
  <delete-modal
    v-if="isDeleteModalOpened"
    :is-deleting="isDeleteModalOpened"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    delete-text="delete"
    @confirm-action="deleteDeviceType"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <delete-relation-modal
    v-if="isDeleteRelationModalOpened"
    :device-type-id="selectedDeviceTypes[0].id"
    :is-delete-relation-modal-opened="isDeleteRelationModalOpened"
    :metadata="selectedDeviceTypes[0].metadata"
    :refresh-data="getDeviceTypes"
    @toggle-modal="isDeleteRelationModalOpened = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else>
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateDeviceType"
        :title="$t('DeviceTypes.Title')"
        description=" "
        button-text="Create"
        @execute-method="toggleCreateModal"
      >
        <the-button
          text="Apply metadata"
          isApplyButtonEnabled
          :is-disabled="
            !isApplyButtonEnabled ||
            !permissionStore.hasPermission(permissions.EditDeviceType)
          "
          :variant="
            isApplyButtonEnabled ||
            !permissionStore.hasPermission(permissions.EditDeviceType)
              ? 'default'
              : 'ghost'
          "
          @execute-method="toggleCreateRelationModal"
        />
      </table-action-header>
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :multiselect="true"
        :options="options"
        :table-data="deviceTypes"
        :table-headers="tableHeaders"
        @force-refresh="getDeviceTypes()"
        @selected-rows="getSelectedRows"
        @open-data-modal="openDataPreviewModal"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import DeviceTypeService, {
  DeviceType,
} from "@/modules/DeviceTypes/Services/DeviceTypeService";
import Loader from "@/core/Components/Loader.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import CreateForm from "../Components/CreateForm.vue";
import EditForm from "../Components/EditForm.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import TheButton from "@/core/Components/TheButton.vue";
import CreateRelationModal from "@/modules/Production/Submodules/DeviceTypes/Components/CreateRelationModal.vue";
import { DeviceTypeMetadata } from "@/modules/Production/Submodules/DeviceTypeMetadata/Services/DeviceTypeMetadataTypes";
import LastDataPreview from "@/modules/Devices/Submodules/Assignments/Components/LastDataPreview.vue";
import DeleteRelationModal from "@/modules/Production/Submodules/DeviceTypes/Components/DeleteRelationModal.vue";
import { Permissions } from "@/core/Services/Enums";
import { usePermissionStore } from "@/core/Store/permissionStore";

export default defineComponent({
  components: {
    DeleteRelationModal,
    LastDataPreview,
    CreateRelationModal,
    TheButton,
    ConfirmationModal,
    DeleteModal,
    CustomTable,
    TableActionHeader,
    Loader,
    MainSection,
    CreateForm,
    EditForm,
  },
  data() {
    return {
      deviceTypeService: new DeviceTypeService(),
      deviceTypes: [] as DeviceType[],
      selectedDeviceTypes: [] as DeviceType[],
      selectedMetadata: [] as DeviceTypeMetadata[],
      toast: useToast(),
      permissionStore: usePermissionStore(),
      permissions: Permissions,
      isLoading: false,
      isDataSourceLoading: false,
      isCreateModalOpened: false,
      isEditModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      isCreateRelationModalOpened: false,
      isDataPreviewModalOpened: false,
      isDeleteRelationModalOpened: false,
      isApplyButtonEnabled: false,
      tableHeaders: {
        id: { header: "DeviceTypes.ID", visible: false },
        name: { header: "DeviceTypes.Name", visible: true },
        productionPN: { header: "DeviceTypes.ProductionPN", visible: true },
        customerPN: { header: "DeviceTypes.CustomerPN", visible: true },
        "serialNumberType.name": {
          header: "DeviceTypes.SerialNumberType",
          visible: true,
        },
        isProductionEnabled: {
          header: "DeviceTypes.ProductionEnabled",
          visible: true,
        },
        metadata: {
          header: "DeviceTypes.Metadata",
          visible: true,
        },
      },
      options: {
        edit: {
          header: "Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditDeviceType,
          action: this.toggleEditModal,
        },
        remove: {
          header: "Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteDeviceType,
          action: this.toggleDeleteModal,
        },
        removeRelation: {
          header: "Remove metadata relation",
          icon: ["fas", "link-slash"],
          permissionId: Permissions.DeleteDeviceType,
          action: this.toggleDeleteRelationModal,
        },
      },
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getDeviceTypes();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    deviceTypeIdList(): number[] {
      return this.selectedDeviceTypes.map((deviceType) => deviceType.id);
    },
  },
  methods: {
    async getDeviceTypes() {
      this.isDataSourceLoading = true;
      try {
        this.deviceTypes = await this.deviceTypeService.getDeviceTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async deleteDeviceType() {
      try {
        await this.deviceTypeService.deleteDeviceType(
          this.selectedDeviceTypes[0].id
        );
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isConfirmationModalOpened = false;
        this.isDeleteModalOpened = false;
        await this.getDeviceTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    getSelectedRows(selectedRow: DeviceType[]) {
      this.selectedDeviceTypes = selectedRow;
      if (selectedRow.length) {
        this.isApplyButtonEnabled = true;
      } else this.isApplyButtonEnabled = false;
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },

    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },

    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
    toggleCreateRelationModal() {
      this.isCreateRelationModalOpened = !this.isCreateRelationModalOpened;
    },
    toggleDeleteRelationModal() {
      this.isDeleteRelationModalOpened = !this.isDeleteRelationModalOpened;
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
    },
    openDataPreviewModal(metadata: DeviceTypeMetadata[]) {
      this.selectedMetadata = metadata;
      this.isDataPreviewModalOpened = true;
    },
  },
});
</script>

<style scoped></style>
