<template>
  <button
    :class="cn(buttonVariants({ variant, size, className }))"
    :disabled="isLoading ? isLoading : isDisabled"
    :type="type"
    @click="executeMethod()"
  >
    <font-awesome-icon
      v-if="isLoading"
      :icon="['fas', 'spinner']"
      class="animate-spin"
    />
    <font-awesome-icon v-if="icon" :icon="icon" />
    <p v-if="text">{{ text }}</p>
  </button>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Cn } from "@/core/Services/GlobalFunctions";
import { cva } from "class-variance-authority";

export default defineComponent({
  props: {
    text: String,
    variant: String,
    size: String,
    className: String,
    type: {
      type: String,
      default: "submit",
    },
    isLoading: Boolean,
    fullWidth: Boolean,
    icon: Array as PropType<string[]>,

    floatingButtonConfiguration: String,

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttonVariants: cva(
        "active:scale-95 gap-2 inline-flex h-full items-center justify-center text-sm font-medium transition-color focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none",
        {
          variants: {
            variant: {
              default:
                "bg-primary hover:bg-primaryhover text-white border border-primary hover:border-primaryhover",
              delete:
                "bg-error hover:bg-errorhover text-white border border-error hover:border-errorhover",
              ghost:
                "bg-transparent hover:text-slate-900 hover:bg-slate-200 !shadow-none border border-transparent hover:border-slate-200",
              outline:
                "bg-transparent border text-gray-300  shadow-none hover:border-darkgrey hover:text-darkgrey !shadow-none",
            },
            size: {
              default: "py-3 px-4",
              sm: "px-2 py-2 text-xs",
              lg: "px-8",
              full: "w-full py-3 px-4",
            },
          },
          defaultVariants: {
            variant: "default",
            size: "default",
          },
        }
      ),
    };
  },
  methods: {
    cn: Cn,
    executeMethod() {
      this.$emit("execute-method");
    },
  },
  emits: ["execute-method"],
});
</script>
<style scoped>
button {
  @apply shadow-md transition duration-200 ease-in-out;
}

button:disabled {
  @apply cursor-default bg-primarylighthover;
}

button:disabled:hover {
  @apply bg-primarylighthover;
}

.linkButton {
  @apply text-darkgrey shadow-none hover:text-primary;
}
</style>
