import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_grid = _resolveComponent("grid")!

  return (_openBlock(), _createBlock(_component_grid, { "column-number": 2 }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (type) => {
        return (_openBlock(), _createElementBlock("div", {
          key: type.id,
          class: _normalizeClass(`flex flex-col items-center space-y-2 border py-4 grayscale duration-300 ease-in-out hover:grayscale-0  ${
        _ctx.selectedFileType === type.id && 'border-primary grayscale-0'
      } cursor-pointer`),
          onClick: () => _ctx.selectFileType(type.id)
        }, [
          _createElementVNode("img", {
            src: type.imgUrl,
            alt: "icon"
          }, null, 8, _hoisted_2),
          _createElementVNode("p", _hoisted_3, _toDisplayString(type.name), 1)
        ], 10, _hoisted_1))
      }), 128))
    ]),
    _: 1
  }))
}