<template>
  <dx-data-grid
    :data-source="tableData"
    :hover-state-enabled="true"
    :show-borders="false"
    height="100%"
    key-expr="assignment.id"
    width="100%"
    @selection-changed="onSelectionChanged"
  >
    <dx-filter-row :visible="true" />
    <dx-column caption="Assets" data-field="assignment.name" />
    <dx-column :width="50" cell-template="openMenu" />
    <dx-pager
      :show-info="false"
      :show-navigation-buttons="false"
      :show-page-size-selector="false"
      :visible="false"
    />
    <dx-paging :enabled="false" />
    <dx-selection mode="single" select-all-mode="allPages" />

    <dx-column
      :group-index="0"
      data-field="device.deviceTypeId"
      group-cell-template="group-header"
    />
    <template #openMenu="{ data }">
      <div
        class="relative flex h-full w-full cursor-pointer items-center justify-center text-center text-gray-600"
      >
        <dropdown-menu
          :options="returnFilteredOptions(data.data)"
          :use-for-table="true"
          :is-company-active="true"
        />
      </div>
    </template>
    <template #group-header="{ data }">
      <template v-if="data.data.items && data.data.items.length > 0">
        <div class="flex items-center">
          <div>
            <img
              :src="
                IconSelector(
                  data.data.items && data.data.items[0]?.device?.deviceTypeId
                )
              "
              class="h-auto w-10"
            />
          </div>
          <div>{{ data.data.items[0].device.deviceType.name }}</div>
        </div>
      </template>
    </template>
    <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
    <template #masterDetailTemplate="{ data: asset }">
      <AssetDetails :asset="asset" :socket-data="webSocketData" />
    </template>
  </dx-data-grid>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPager,
  DxPaging,
  DxSelection,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import { IconSelector } from "../Services/IconService";
import AssetDetails from "./AssetDetails.vue";
import DropdownMenu from "@/core/Components/Dropdowns/DropdownMenu.vue";
import { TableDropdownItem } from "@/core/Services/GlobalTypes";
import DeviceDataService from "@/modules/Devices/Services/DeviceDataService";
import moment from "moment";

export default defineComponent({
  components: {
    DxSelection,
    DxPaging,
    DxPager,
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    AssetDetails,
    DxMasterDetail,
    DropdownMenu,
  },
  props: ["tableData", "webSocketData"],
  emits: [
    "selected-rows",
    "open-generic-packet-modal",
    "show-asset-route-date-picker-modal",
  ],
  data() {
    return {
      IconSelector: IconSelector,
      isDropdownOpenedFromOutside: false,
      selectedRowsData: {} as any,
      deviceDataService: new DeviceDataService(),
      from: moment().startOf("day").format("yyyy-MM-DD HH:mm"),
      to: moment().endOf("day").format("yyyy-MM-DD HH:mm"),
      options: {
        assetDetails: {
          header: "View details",
          icon: ["fas", "eye"],
          action: this.showGenericPacket,
        },
        assetRoute: {
          header: "Show route",
          icon: ["fas", "route"],
          action: this.drawAssetRoute,
        },
      } as TableDropdownItem,
    };
  },
  methods: {
    returnFilteredOptions(assetData: any) {
      let filteredOptions = Object.assign({}, this.options);
      if (!assetData.lastTimeDeviceSentData)
        delete filteredOptions.assetDetails;
      return filteredOptions;
    },
    onSelectionChanged({ selectedRowsData }: any) {
      this.selectedRowsData = selectedRowsData;
      this.$emit("selected-rows", selectedRowsData);
    },
    showGenericPacket() {
      this.$emit(
        "open-generic-packet-modal",
        this.selectedRowsData[0].assignment?.dataUuid
      );
    },
    async drawAssetRoute() {
      this.$emit(
        "show-asset-route-date-picker-modal",
        this.selectedRowsData[0].assignment?.dataUuid
      );
    },
  },
});
</script>
<style scoped></style>
