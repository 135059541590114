<template>
  <div class="flex h-full w-full items-center justify-center">
    <form-wrapper
      :is-form-in-modal="isFormInModal"
      :is-loading="isLoading"
      :need-for-scroll="true"
    >
      <div class="relative items-center px-6 py-4">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >{{ $t("Integrations.IntegrationType") }}</label
        >
        <v-select
          v-model="integrationTypeId"
          :options="integrationTypes"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <div class="relative items-center px-6">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >{{ $t("Integrations.Group") }}</label
        >
        <v-select
          v-model="applicationId"
          :options="applications"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <div>
        <component
          :is="integrationTypeTemplates[integrationTypeId]"
          :application-id="applicationId"
          :integration-type-id="integrationTypeId"
          :refresh-data="refreshData"
        ></component>
      </div>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "../../../core/Components/MainSection.vue";
import FormWrapper from "../../../core/Components/FormElements/FormWrapper.vue";
import VSelect from "vue-select";
import IntegrationService, {
  IntegrationType,
} from "../Services/IntegrationService";
import HTTPCreate from "../Components/HTTPCreate.vue";
import UDPCreate from "../Components/UDPCreate.vue";
import WSCreate from "../Components/WSCreate.vue";
import InvocationCreate from "./InvocationCreate.vue";
import Loader from "../../../core/Components/Loader.vue";
import { IntegrationTypesTemplates } from "../Services/Helpers";
import MQTTCreate from "../Components/MQTTCreate.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ApplicationService, {
  Application,
} from "@/modules/Applications/Services/ApplicationService";
import TranslationCreate from "@/modules/Integrations/Components/TranslationCreate.vue";
import { useToast } from "vue-toastification";
import InputField from "@/core/Components/FormElements/InputField.vue";

export default defineComponent({
  components: {
    InputField,
    MainSection,
    FormWrapper,
    VSelect,
    HTTPCreate,
    UDPCreate,
    WSCreate,
    Loader,
    MQTTCreate,
    TranslationCreate,
    InvocationCreate,
  },
  props: { isFormInModal: { type: Boolean }, refreshData: Function },
  data() {
    return {
      integrationTypeId: 1,
      integrationTypes: [] as IntegrationType[],
      integrationService: new IntegrationService(),
      applicationService: new ApplicationService(),
      isLoading: false as boolean,
      integrationTypeTemplates: IntegrationTypesTemplates as any,
      applicationId: 1,
      applications: [] as Application[],
      executionOrder: null,
      toast: useToast(),
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getIntegrationTypes();
      await this.getAllApplications();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async getIntegrationTypes() {
      try {
        this.integrationTypes =
          await this.integrationService.getIntegrationTypes();
        this.integrationTypes.push({
          id: 997,
          name: "Translation integration",
          config: null,
        });
        // this.integrationTypes.push({id: 998, name: "Rule engine", config: null});
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllApplications() {
      try {
        const response = await this.applicationService.getAllApplications(
          1,
          10000
        );
        this.applications = response.items;

        if (this.applications.length && this.applications[0].id) {
          this.applicationId = this.applications[0].id;
        }
      } catch (error) {}
    },
  },
});
</script>
<style scoped></style>
