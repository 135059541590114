<template>
  <modal
    :is-displayed="isEditModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    :no-padding="true"
    :title="$t('Integrations.EditTitle')"
    @toggle-modal="toggleEditModal"
  >
    <edit-form
      :is-form-in-modal="true"
      :object-id="selectedIntegration.id"
      :refresh-data="getAllIntegrations"
    />
  </modal>
  <modal
    :is-displayed="isCreateModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    :no-padding="true"
    :title="$t('Integrations.CreateTitle')"
    @toggle-modal="toggleCreateModal"
  >
    <create-form :is-form-in-modal="true" :refresh-data="getAllIntegrations" />
  </modal>
  <delete-modal
    :is-deleting="isDeleting"
    :selected-data="selectedIntegration"
    delete-text="Are you sure you want to delete integration "
    @toggle-modal="toggleDeleteModal"
    @cancel-delete="toggleDeleteModal"
    @confirm-delete="isConfirmationModalOpened = true"
  />
  <confirmation-modal
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    delete-text="delete"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
    @confirm-action="deleteIntegration"
  />
  <main-section>
    <loader
      v-if="isLoading"
      inner-ring-thickness="border-t-8"
      outer-ring-thickness="border-8"
    />
    <div v-else>
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateIntegration"
        :title="$t('Integrations.Title')"
        button-text="Create"
        description="Integrations are used to route data from outside sources into the platform and routing data from the platform to the external systems."
        @execute-method="createIntegration"
      />
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="integrations"
        :table-headers="tableHeaders"
        @selected-rows="getSelectedRows"
        @force-refresh="getAllIntegrations"
      />
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "../../../core/Components/MainSection.vue";
import IntegrationService, {
  Integration,
  IntegrationObject,
} from "../Services/IntegrationService";
import CustomTable from "../../../core/Components/CustomTable.vue";
import Loader from "@/core/Components/Loader.vue";
import TheButton from "@/core/Components/TheButton.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Modal from "@/core/Components/Modal.vue";
import EditForm from "../Components/EditForm.vue";
import CreateForm from "../Components/CreateForm.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import { useToast } from "vue-toastification";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    TableActionHeader,
    MainSection,
    CustomTable,
    Loader,
    TheButton,
    Modal,
    EditForm,
    CreateForm,
    DeleteModal,
    ConfirmationModal,
  },
  data() {
    return {
      integrationService: new IntegrationService(),
      changeCompanyStore: useChangeCompanyStore(),
      integrationObject: {} as IntegrationObject,
      toast: useToast(),
      permissions: Permissions,
      integrations: [] as Integration[],
      isLoading: false,
      routeName: "integrationDetails",
      selectedIntegration: {} as Integration,
      selectedRows: [] as Integration[],
      isEditModalOpened: false,
      isCreateModalOpened: false,
      isDataSourceLoading: true,
      isConfirmationModalOpened: false,
      isDeleting: false,
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditIntegration,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteIntegration,
          action: this.toggleDeleteModal,
        },
      },
      tableHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "Integrations.Name", visible: true },
        "integrationType.name": { header: "Integrations.Type", visible: true },
        "application.name": { header: "Integrations.Group", visible: true },
        executionOrder: {
          header: "Integrations.ExecutionOrder",
          visible: true,
        },
        isActive: { header: "Integrations.Active", visible: true },
      },
      index: 1 as Number,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getAllIntegrations();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getAllIntegrations();
    },
  },

  methods: {
    async getAllIntegrations() {
      this.isDataSourceLoading = true;
      try {
        this.integrationObject =
          await this.integrationService.getAllIntegrations(1, 100000);
        this.integrations = this.integrationObject.items;
        this.isEditModalOpened = false;
        this.isCreateModalOpened = false;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async deleteIntegration() {
      this.isDataSourceLoading = true;
      try {
        await this.integrationService.deleteIntegration(
          this.selectedIntegration.id
        );
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isConfirmationModalOpened = false;
        this.isDeleting = false;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
        await this.getAllIntegrations();
      }
    },

    createIntegration() {
      this.toggleCreateModal();
    },

    viewDetails() {
      (this as any).$router.push({
        name: this.routeName,
        params: { integrationId: this.selectedIntegration.id },
      });
    },

    getSelectedRows(values: Integration[]) {
      this.selectedRows = values;
      if (values.length) {
        this.selectedIntegration = values[0];
      }
    },

    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleting = !this.isDeleting;
    },
  },
});
</script>
<style></style>
