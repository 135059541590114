import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_input = _resolveComponent("file-input")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "have-modal": true,
    "is-displayed": _ctx.isFloorPlanCreateModalOpened,
    title: 'Create floorplan',
    onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_file_input, {
        "floorplan-service": _ctx.floorplanService,
        onChangeToCreateMode: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('change-to-create-mode', _ctx.$data))),
        onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggle-modal')))
      }, null, 8, ["floorplan-service"])
    ]),
    _: 1
  }, 8, ["is-displayed"]))
}