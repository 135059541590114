import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-6" }
const _hoisted_2 = {
  key: 0,
  class: "flex w-full items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isUsersDevicesSharedToModalDisplayed,
    "custom-modal-size": "w-6/12",
    title: "Users device is shared to",
    onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_confirmation_modal, {
        "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
        "object-name": _ctx.userEmail,
        "delete-text": "revoke",
        onConfirmAction: _ctx.revokeShare,
        onCloseConfirmationModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
        onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
      }, null, 8, ["is-confirmation-modal-opened", "object-name", "onConfirmAction"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_small_loader)
            ]))
          : (_openBlock(), _createBlock(_component_custom_table, {
              key: 1,
              "is-action-list-not-displayed": true,
              "is-data-source-loading": _ctx.isDataSourceLoading,
              "is-revoke-action-displayed": true,
              "no-selection": true,
              "table-data": _ctx.users,
              "table-headers": _ctx.tableHeaders,
              onRevokeAction: _ctx.toggleConfirmationModal
            }, null, 8, ["is-data-source-loading", "table-data", "table-headers", "onRevokeAction"]))
      ])
    ]),
    _: 1
  }, 8, ["is-displayed"]))
}