import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "h-full"
}
const _hoisted_2 = { class: "flex h-[46px] gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_dx_date_box = _resolveComponent("dx-date-box")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createBlock(_component_main_section, null, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_dx_date_box, {
                modelValue: _ctx.from,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.from) = $event)),
                height: 46,
                "show-clear-button": true,
                "display-format": "dd.MM.yyyy HH:mm",
                type: "datetime"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_dx_date_box, {
                modelValue: _ctx.to,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.to) = $event)),
                height: 46,
                "show-clear-button": true,
                "display-format": "dd.MM.yyyy HH:mm",
                type: "datetime"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_the_button, {
                icon: ['fas', 'rotate-left'],
                "button-type": "primaryButton",
                onExecuteMethod: _ctx.getParkingSessions
              }, null, 8, ["onExecuteMethod"])
            ]),
            _createVNode(_component_custom_table, {
              "is-action-list-not-displayed": true,
              "is-data-source-loading": _ctx.isDataSourceLoading,
              "show-per-page": 16,
              "table-data": _ctx.parkingSessions,
              "table-headers": _ctx.tableHeaders,
              "table-height": "93%",
              onForceRefresh: _ctx.getParkingSessions
            }, null, 8, ["is-data-source-loading", "table-data", "table-headers", "onForceRefresh"])
          ]))
    ]),
    _: 1
  }))
}