<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    :title="$t('ParkingSpots.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <map-location-selector
      v-if="isMapLocationModalOpened"
      :coordinates-prop="{
        lat: parkingSpot.latitude,
        lng: parkingSpot.longitude,
      }"
      :is-map-location-modal-opened="isMapLocationModalOpened"
      @toggle-modal="toggleMapLocationModal"
      @get-coordinates="getMarkerCoordinates"
    />
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="parkingSpot.name"
            :label="$t('ParkingSpots.Name')"
          />
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("ParkingSpots.ParkingSpace") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isParkingSpaceListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="parkingSpot.spaceId"
                :class="`mt-1 w-full`"
                :options="parkingSpaces"
                :reduce="(space : any) => space.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("ParkingSpots.ParkingSpotType") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isParkingSpotTypesListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="parkingSpot.typeId"
                :class="`mt-1 w-full`"
                :options="parkingSpotTypes"
                :reduce="(space : any) => space.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <div
            class="grid grid-cols-2 gap-6"
            style="grid-template-columns: auto min-content"
          >
            <div>
              <input-field
                v-model="parkingSpot.latitude"
                :label="$t('ParkingSpaces.Latitude')"
                :required="true"
              />
              <input-field
                v-model="parkingSpot.longitude"
                :label="$t('ParkingSpaces.Longitude')"
                :required="true"
              />
            </div>
            <div class="pb-3 pt-10">
              <the-button
                :icon="['fas', 'earth-europe']"
                button-type="primaryButton"
                type="button"
                @execute-method="toggleMapLocationModal"
              />
            </div>
          </div>
          <input-field
            v-model="parkingSpot.index"
            :label="$t('ParkingSpots.Index')"
            type="number"
          />
          <checkbox-input
            v-model="parkingSpot.visible"
            :is-checked="parkingSpot.visible"
            :label="$t('ParkingSpots.Visible')"
          />
          <checkbox-input
            v-model="parkingSpot.public"
            :is-checked="parkingSpot.public"
            :label="$t('ParkingSpots.Public')"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import TheButton from "@/core/Components/TheButton.vue";
import MapLocationSelector from "@/core/Components/MapLocationSelector.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import Grid from "@/core/Components/Grid.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import { CreateParkingSpot } from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import SensorService from "@/modules/Parkom/Submodules/Sensors/Services/SensorService";
import { Sensor } from "@/modules/Parkom/Submodules/Sensors/Services/SensorTypes";

export default defineComponent({
  components: {
    Grid,
    CheckboxInput,
    MapLocationSelector,
    ButtonWrapper,
    InputField,
    FormDivider,
    ModalComponent,
    FormWrapper,
    VSelect,
    SmallLoader,
    TheButton,
  },
  props: {
    isCreateModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      parkingSpaceService: new ParkingSpaceService(),
      parkingSpotService: new ParkingSpotService(),
      sensorService: new SensorService(),
      parkingSpaces: [] as ParkingSpace[],
      sensors: [] as Sensor[],
      isParkingSpaceListLoading: false,
      isSensorListLoading: false,
      isMapLocationModalOpened: false,
      isParkingSpotTypesListLoading: false,
      isOverrideModalOpened: false,
      isSubmitLoading: false,
      toast: useToast(),
      parkingSpotTypes: [
        {
          id: 1,
          name: "Regular",
        },
        {
          id: 2,
          name: "Handicapped",
        },
        {
          id: 3,
          name: "Charging",
        },
      ],
      parkingSpot: {
        name: "",
        latitude: 0,
        longitude: 0,
        index: 0,
        public: false,
        spaceId: null,
        typeId: null,
        visible: true,
      } as CreateParkingSpot,
    };
  },
  async created() {
    await Promise.allSettled([this.getParkingSpaces(), this.getSensors()]);
  },

  watch: {
    async "parkingSpot.spaceId"(spaceId: number) {
      await this.getLastParkingSpotIndex(spaceId);
    },
  },
  methods: {
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        const response = await this.parkingSpotService.createSpot(
          this.parkingSpot
        );
        this.$emit("toggle-modal");
        this.toast.success("Parking spot assigned successfully");
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getParkingSpaces() {
      this.isParkingSpaceListLoading = true;
      try {
        this.parkingSpaces = await this.parkingSpaceService.getParkingSpaces({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isParkingSpaceListLoading = false;
      }
    },
    async getSensors() {
      this.isSensorListLoading = true;
      try {
        this.sensors = await this.sensorService.getSensors({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSensorListLoading = false;
      }
    },
    toggleMapLocationModal() {
      this.isMapLocationModalOpened = !this.isMapLocationModalOpened;
    },
    getMarkerCoordinates(coordinates: { lat: number; lng: number }) {
      this.parkingSpot.latitude = coordinates.lat;
      this.parkingSpot.longitude = coordinates.lng;
    },
    async getLastParkingSpotIndex(spaceId: number | null) {
      if (!spaceId) {
        this.parkingSpot.index = 0;
        return;
      }
      try {
        const lastIndex =
          await this.parkingSpaceService.getParkingSpaceLastIndex(spaceId);
        this.parkingSpot.index = lastIndex + 1;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>
