<template>
  <modal-component
    :is-displayed="isStaticActionsModalOpened"
    :need-for-scroll="true"
    :title="`Static actions for ${device.assignment.name}`"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="space-y-2 px-6">
      <div
        v-for="action in device.device.actions"
        class="flex items-center justify-between rounded p-3 hover:bg-lightgray"
      >
        <p>{{ action.name }}</p>
        <div
          v-tippy="`Run ${action.name} action`"
          class="flex h-10 w-10 items-center justify-center rounded bg-grey text-primary hover:cursor-pointer"
        >
          <font-awesome-icon :icon="['fas', 'play']" />
        </div>
      </div>
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import { directive } from "vue-tippy";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: { ModalComponent },
  emits: ["toggle-modal"],
  directives: {
    tippy: directive,
  },
  props: {
    device: {
      type: Object as PropType<DeviceAssignment>,
      required: true,
    },
    isStaticActionsModalOpened: Boolean,
  },
});
</script>

<style scoped></style>
