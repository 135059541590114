import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "border-none" }
const _hoisted_2 = { class: "border p-2" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = {
  key: 0,
  class: "absolute mt-2 w-full overflow-x-scroll bg-white shadow-lg"
}
const _hoisted_5 = { class: "pt-3 text-sm font-medium not-italic text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "flex cursor-pointer items-center gap-2 whitespace-nowrap text-sm font-medium not-italic text-gray-800",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showConfig && _ctx.showConfig(...args)))
        }, [
          _createTextVNode(" Config schema "),
          _createElementVNode("i", {
            class: _normalizeClass({
              'fa fa-chevron-up': _ctx.visibleConfig,
              'fa fa-chevron-down': !_ctx.visibleConfig,
            }),
            style: {"color":"#929bad"}
          }, null, 2)
        ]),
        (_ctx.visibleConfig)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("pre", _hoisted_5, "                " + _toDisplayString(_ctx.configSchema ? _ctx.configSchema : "No config schema") + "\n            ", 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}