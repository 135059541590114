<template>
  <modal-component
    :is-displayed="isCreateRelationModalOpened"
    :no-padding="true"
    :title="$t('AssignmentMetadata.CreateRelationTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("AssignmentMetadata.AssignmentMetadata") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="assignmentMetadataId"
                :class="`mt-1 w-full`"
                :options="assignmentMetadata"
                :reduce="(metadata : any) => metadata.id"
                label="name"
              ></v-select>
            </div>
          </div>
        </form-divider>
        <button-wrapper />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { AssignmentMetadata } from "@/modules/Devices/Submodules/AssignmentMetadata/Services/AssignmentMetadataTypes";
import AssignmentMetadataService from "@/modules/Devices/Submodules/AssignmentMetadata/Services/AssignmentMetadataService";
import { DeviceAssignment } from "../Services/AssignmentService";

export default defineComponent({
  components: {
    SmallLoader,
    VSelect,
    ButtonWrapper,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isCreateRelationModalOpened: Boolean,
    assignmentIdList: {
      type: Array as PropType<number[]>,
      required: true,
    },
    refreshData: Function,
    tableData: {
      type: Array as PropType<DeviceAssignment[]>,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      assignmentMetadataService: new AssignmentMetadataService(),
      assignmentMetadata: [] as AssignmentMetadata[],
      toast: useToast(),
      assignmentMetadataId: null as number | null,
      isLoading: false,
      isSubmitLoading: false,
    };
  },

  async created() {
    console.log("table data -", this.tableData);

    this.isLoading = true;
    try {
      await this.getDeviceTypeMetadata();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleSubmit() {
      if (!this.assignmentMetadataId) {
        /* throw new Error("Assignment metadata id is not set!"); */
        this.toast.error("Asset metadata id is not set!");
        return;
      }
      this.isSubmitLoading = true;

      this.tableData.forEach((element: DeviceAssignment) => {
        this.assignmentIdList.forEach(
          (newItemInList: number, index: number) => {
            if (
              element.assignment &&
              element.assignment.metadata &&
              element.assignment.metadata.length > 0 && // Provjeri ima li barem jedan element u metadata
              element.assignment.metadata[0].id &&
              element.assignment.metadata[0].id === this.assignmentMetadataId &&
              element.assignment.id &&
              newItemInList === element.assignment.id
            ) {
              this.assignmentIdList.splice(index, 1);
            }
          }
        );
      });

      if (this.assignmentIdList.length > 0) {
        try {
          await this.assignmentMetadataService.createAssignmentMetadataRelation(
            {
              metadataId: this.assignmentMetadataId,
              assignmentIds: this.assignmentIdList,
            }
          );
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      } else {
        this.toast.error("Selected asset metadata is already applied!");
      }
    },
    async getDeviceTypeMetadata() {
      try {
        this.assignmentMetadata =
          await this.assignmentMetadataService.getAllAssignmentMetadata();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
