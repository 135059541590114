<template>
  <create-form
    v-if="isCreateFormModalOpened"
    :is-create-form-modal-opened="isCreateFormModalOpened"
    :refresh-data="refreshData"
    @toggle-modal="isCreateFormModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getResources"
    :selected-resource="selectedResource"
    @toggle-modal="isEditModalOpened = false"
  />
  <delete-modal
    v-if="isDeleteModalOpened"
    :is-deleting="isDeleteModalOpened"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :delete-text="confirmationModalText"
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    @confirm-action="confirmationModalAction"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else>
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.GlobalAuthView"
        :title="$t('GlobalAuthorization.Title')"
        description=" "
        button-text="Create"
        @execute-method="toggleCreateModal"
      />
      <div class="flex gap-2">
        <v-select
          class="mt-1 w-60"
          v-model="selectedFilterOption"
          :options="filterOptions"
          :reduce="(option : any) => option.key"
          label="label"
          :clearable="false"
        />
        <div class="w-60">
          <input-field
            v-model="searchEmailText"
            placeholder="Search by email"
            :no-padding="true"
            @input="handleEmailSearch"
          />
        </div>
      </div>
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="resources.items"
        :table-headers="tableHeaders"
        @force-refresh="getResources()"
        @selected-rows="getSelectedRows"
      />
      <pagination
        :pagination-data="resources"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Loader from "@/core/Components/Loader.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import CreateForm from "../Components/CreateForm.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import EditForm from "../Components/EditForm.vue";
import { Permissions } from "@/core/Services/Enums";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import AuthorizationService from "@/modules/AdminDashboard/Submodules/Authorization/Services/AuthorizationService";
import {
  ResourceDto,
  ResourceProps,
} from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/AuthorizationTypes";
import Pagination from "@/core/Components/Pagination.vue";
import { PaginationSettings } from "@/core/Services/PaginationSettings";
import VSelect from "vue-select";
import InputField from "@/core/Components/FormElements/InputField.vue";

export default defineComponent({
  components: {
    TableActionHeader,
    Loader,
    MainSection,
    CustomTable,
    ConfirmationModal,
    DeleteModal,
    CreateForm,
    EditForm,
    Pagination,
    VSelect,
    InputField,
  },
  data() {
    return {
      //
      filterOptions: [
        { label: "Company", key: "Company" },
        { label: "Asset", key: "DeviceAssignment" },
        { label: "None", key: "None" },
      ],
      selectedFilterOption: "Company" as string | undefined,
      searchEmailText: "",
      searchTimeoutId: undefined as any,
      paginationProps: {} as ResourceProps,
      //
      isCreateFormModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      isEditModalOpened: false,
      confirmationModalText: "",
      isLoading: false,
      isDataSourceLoading: false,
      authorizationService: new AuthorizationService(),
      resources: {} as any,
      selectedResource: {} as ResourceDto,
      permissions: Permissions,
      toast: useToast(),
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "edit"],
          permissionId: Permissions.GlobalAuthView,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.GlobalAuthView,
          action: this.toggleDeleteModal,
        },
      },
      tableHeaders: {
        userEmail: {
          header: "GlobalAuthorization.ToUser",
          visible: true,
          groupIndex: 0,
        },
        roleName: { header: "GlobalAuthorization.Role", visible: true },
        resourceTypeName: {
          header: "GlobalAuthorization.ResourceType",
          visible: true,
        },
        resourceId: { header: "Authorization.Resource", visible: true },
        createdUserEmail: {
          header: "GlobalAuthorization.CreatedResource",
          visible: true,
        },
      },
    };
  },
  watch: {
    selectedFilterOption(newValue, oldValue) {
      this.getResources();
    },
  },
  async created() {
    this.isLoading = true;
    try {
      this.paginationProps.pageNumber = PaginationSettings.DEFAULT_CURRENT_PAGE;
      this.paginationProps.pageSize =
        PaginationSettings.DEFAULT_CURRENT_PAGE_SIZE;
      await this.getResources();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handleEmailSearch() {
      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }

      this.searchTimeoutId = setTimeout(async () => {
        this.getResources();
      }, 800);
    },
    refreshData(selectedFilterOption: number) {
      switch (selectedFilterOption) {
        case 1:
          this.selectedFilterOption = "Company";
          break;
        case 2:
          this.selectedFilterOption = "DeviceAssignment";
          break;
        case 4:
          this.selectedFilterOption = "None";
          break;
        default:
          this.selectedFilterOption = "Company";
          break;
      }
      this.getResources();
    },
    async getResources() {
      this.isDataSourceLoading = true;
      try {
        this.resources = await this.authorizationService.getGlobalResources(
          this.paginationProps.pageNumber,
          this.paginationProps.pageSize,
          this.selectedFilterOption,
          this.searchEmailText
        );
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    handlePageChange(newPage: number) {
      this.paginationProps.pageNumber = newPage;
      this.getResources();
    },
    handlePageSizeChange(newPageSize: number) {
      this.paginationProps.pageSize = newPageSize;
      this.paginationProps.pageNumber = 1;
      this.getResources();
    },
    getSelectedRows(values: ResourceDto[]) {
      if (values.length) {
        this.selectedResource = values[0];
      }
    },
    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteResource();
          break;
      }
      this.isConfirmationModalOpened = false;
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },
    async deleteResource() {
      try {
        await this.authorizationService.deleteResource(
          this.selectedResource.id
        );
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isConfirmationModalOpened = false;
        this.isDeleteModalOpened = false;
        await this.getResources();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    toggleCreateModal() {
      this.isCreateFormModalOpened = !this.isCreateFormModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
  },
});
</script>

<style scoped></style>
