import { RouteRecordRaw } from "vue-router";
import Index from "@/modules/Integrations/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createIntegrationIndexRoute(): RouteRecordRaw {
  return {
    name: "integrations",
    path: "/integrations",
    component: Index,
    meta: {
      id: "integrationsIndex",
      label: "Sidebar.Integrations",
      icon: ["fas", "plug"],
      showInNavigation: true,
      group: "main",
      authorize: [Permissions.ViewIntegration],
    },
  };
}
