<template>
  <div class="tabs max-w-8/12 flex">
    <div v-for="tab in tabs" @click="selectedTab = tab.id">
      <div
        :class="`${selectedTab === tab.id && 'border-primary'}`"
        class="cursor-pointer border-b-4 p-2.5 duration-300 ease-in-out hover:bg-grey"
        @click="$emit('changeTab', tab.id)"
      >
        <p class="text-sm">
          {{ tab.label }}
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Tab } from "@/core/Services/GlobalTypes";

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },
  emits: ["changeTab"],

  data() {
    return {
      selectedTab: "" as string | number,
    };
  },

  mounted() {
    this.getDefaultTab();
  },

  methods: {
    getDefaultTab() {
      const defaultTab = this.tabs.find((tab) => tab.default)?.id;
      /* console.log(defaultTab, this.tabs); */
      if (!defaultTab) {
        return;
      }

      this.selectedTab = defaultTab;
    },
  },
});
</script>
<style>
.tabs::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.tabs::-webkit-scrollbar {
  height: 4px;
  background-color: #f5f5f5;
}

.tabs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  @apply bg-primary;
}
</style>
