import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute inset-0 z-[10000] flex items-center justify-center bg-[rgba(255,255,255,0.3)]" }
const _hoisted_2 = { class: "w-24 flex items-center justify-center h-24 bg-white nice-shadow rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_loader = _resolveComponent("small-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_small_loader)
    ])
  ]))
}