<template>
  <create-form
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getBusControllers"
    @toggle-modal="isCreateModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :bus-controller-prop="selectedBusController"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getBusControllers"
    @toggle-modal="isEditModalOpened = false"
  />
  <display-events
    v-if="isBusControllerEventsShown"
    :is-display-events-opened="isBusControllerEventsShown"
    :id="selectedBusController.externalAssignmentUuid"
    :title="`${selectedBusController.imei}`"
    @toggle-modal="isBusControllerEventsShown = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else class="h-full">
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateAssignment"
        :title="$t('BusControllers.Title')"
        button-text="Create"
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :render-summary="['imei']"
        :show-per-page="16"
        :table-data="busControllers"
        :table-headers="tableHeaders"
        table-height="93%"
        :isActionListNotDisplayed="
          !permissionStore.hasPermission(permissions.EditAssignment)
        "
        @force-refresh="getBusControllers"
        @selected-rows="getSelectedRows"
        @view-display-events="toggleBusControllerEvents"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import BusControllerService from "@/modules/Parkom/Submodules/BusControllers/Services/BusControllerService";
import { BusController } from "@/modules/Parkom/Submodules/BusControllers/Services/BusControllerTypes";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import CustomTable from "@/core/Components/CustomTable.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import Loader from "@/core/Components/Loader.vue";
import CreateForm from "../Components/CreateForm.vue";
import EditForm from "../Components/EditForm.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import DisplayEvents from "@/modules/Parkom/Submodules/Displays/Components/Events.vue";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    Loader,
    TableActionHeader,
    CustomTable,
    MainSection,
    CreateForm,
    EditForm,
    DisplayEvents,
  },
  data() {
    return {
      permissionStore: usePermissionStore(),
      busControllerService: new BusControllerService(),
      busControllers: [] as BusController[],
      selectedBusController: {} as BusController,
      toast: useToast(),
      changeCompanyStore: useChangeCompanyStore(),
      isLoading: false,
      isDataSourceLoading: false,
      isCreateModalOpened: false,
      isEditModalOpened: false,
      isBusControllerEventsShown: false,
      permissions: Permissions,
      tableHeaders: {
        id: { header: "ID", visible: false },
        imei: { header: "BusControllers.IMEI", visible: true },
        lastSync: { header: "BusControllers.LastSync", visible: true },
        spaceName: { header: "BusControllers.Space", visible: true },
        busControllerEventsReport: { header: "", visible: true },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditAssignment,
          action: this.toggleEditModal,
        },
      },
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getBusControllers();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getBusControllers();
    },
  },

  methods: {
    async getBusControllers() {
      this.isDataSourceLoading = true;
      try {
        this.busControllers = await this.busControllerService.getBusControllers(
          {
            pageNumber: 1,
            pageSize: 100000,
          }
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    getSelectedRows(values: BusController[]) {
      if (values.length) {
        this.selectedBusController = values[0];
      }
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleBusControllerEvents(b: BusController) {
      this.selectedBusController = b;
      this.isBusControllerEventsShown = !this.isBusControllerEventsShown;
    },
  },
});
</script>

<style scoped></style>
