import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative items-center py-4" }
const _hoisted_2 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_3 = { class: "flex items-center justify-center" }
const _hoisted_4 = { class: "relative items-center py-4" }
const _hoisted_5 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_6 = { class: "flex items-center justify-center" }
const _hoisted_7 = {
  key: 0,
  class: "relative items-center py-4"
}
const _hoisted_8 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_9 = { class: "flex items-center justify-center" }
const _hoisted_10 = { class: "relative items-center py-4" }
const _hoisted_11 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_12 = { class: "flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isEditModalOpened,
    "no-padding": true,
    title: _ctx.$t('Authorization.EditTitle'),
    onToggleModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_wrapper, {
        "is-form-in-modal": true,
        "is-loading": _ctx.isLoading
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("Authorization.User")), 1),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_v_select, {
                      modelValue: _ctx.resource.toUserId,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resource.toUserId) = $event)),
                      class: _normalizeClass(`mt-1 w-full`),
                      options: _ctx.users,
                      reduce: (user) => user.id,
                      label: "email",
                      disabled: true,
                      clearable: false
                    }, null, 8, ["modelValue", "options", "reduce"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("Authorization.ResourceType")), 1),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_v_select, {
                      modelValue: _ctx.resource.resourceTypeId,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.resource.resourceTypeId) = $event)),
                      class: _normalizeClass(`mt-1 w-full`),
                      options: _ctx.resourceTypes,
                      reduce: (resourceType) => resourceType.id,
                      label: "name",
                      clearable: false,
                      disabled: true
                    }, null, 8, ["modelValue", "options", "reduce"])
                  ])
                ]),
                (_ctx.resource.resourceTypeId === 2)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("Authorization.Asset")), 1),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_v_select, {
                          modelValue: _ctx.resource.onResourceId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.resource.onResourceId) = $event)),
                          class: _normalizeClass(`mt-1 w-full`),
                          "get-option-label": (device) => device?.assignment?.name,
                          options: _ctx.deviceAssignment,
                          reduce: (device) => device.assignmentId,
                          clearable: false
                        }, null, 8, ["modelValue", "get-option-label", "options", "reduce"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("Authorization.Role")), 1),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_v_select, {
                      modelValue: _ctx.resource.roleId,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.resource.roleId) = $event)),
                      class: _normalizeClass(`mt-1 w-full`),
                      options: _ctx.roles,
                      reduce: (device) => device.id,
                      label: "name",
                      clearable: false
                    }, null, 8, ["modelValue", "options", "reduce"])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
          ], 32)
        ]),
        _: 1
      }, 8, ["is-loading"])
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}