<template>
  <div class="flex">
    <div class="w-1/4 space-y-7 align-middle">
      <div>
        <p class="font-semibold">Name</p>
        <InputField
          :model-value="currentDevice.assignment?.name"
          :no-padding="true"
          @input="updateModelValue($event.target.value)"
        />
      </div>
      <div>
        <p class="font-semibold">Owner</p>
        <!-- <p v-if="currentDevice.assignment?.user.username"><font-awesome-icon :icon="['fas', 'user']" class="mr-1"/>Username: <span class="text-sm">{{ currentDevice.assignment?.user.username }}</span></p>
        <p v-else><font-awesome-icon :icon="['fas', 'user']" class="mr-1"/> Username not found</p> -->
        <p v-if="currentDevice.assignment?.user.email">
          <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1" />
          Email:
          <span class="text-sm">{{
            currentDevice.assignment?.user.email
          }}</span>
        </p>
        <p v-else>
          <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1" />
          Email not found
        </p>
      </div>
      <div>
        <p class="font-semibold">Device info</p>
        <p v-if="currentDevice.device?.mac">
          <font-awesome-icon :icon="['fac', 'bluetooth']" class="mr-1" />
          MAC: <span class="text-sm">{{ currentDevice.device?.mac }}</span>
        </p>
        <p v-else>
          <font-awesome-icon :icon="['fac', 'bluetooth']" class="mr-1" />
          MAC address not found
        </p>
      </div>
      <div
        class="flex items-center justify-center justify-items-center align-middle"
      >
        <TheButton
          :text="'Save changes'"
          :is-disabled="
            !currentDevice.assignment?.permissions.find(
              (permission) => permission.id === permissions.EditAssignment
            )
          "
          :variant="
            currentDevice.assignment?.permissions.find(
              (permission) => permission.id === permissions.EditAssignment
            )
              ? 'default'
              : 'ghost'
          "
          @click="saveChanges()"
        />
      </div>
    </div>
    <div class="ml-4 flex w-3/4 flex-col gap-2">
      <div class="flex items-center">
        <p class="ml-1 font-semibold">Device Location</p>
        <div class="flex-grow"></div>
        <p class="mr-2 text-xs font-semibold text-red-500">
          (Double click for pick)
        </p>
      </div>
      <div id="map" class="h-[40vh] relative w-full rounded-lg">
        <!-- <TheButton
          v-if="!mapHasMarker"
          :size="'sm'"
          :text="'Create marker'"
          class="absolute left-1 top-1 z-[2500] !h-fit"
          @click="createMarkerOnMap"
        /> -->
        <div
          v-if="markerCoordinatesChanged"
          class="z-[2500] absolute inset-x-0 bottom-1 flex items-center justify-center"
        >
          <div class="flex items-center gap-2">
            <TheButton
              :size="'sm'"
              :text="'Cancel'"
              class="!h-fit"
              :is-disabled="
                !currentDevice.assignment?.permissions.find(
                  (permission) => permission.id === permissions.EditAssignment
                )
              "
              :variant="
                currentDevice.assignment?.permissions.find(
                  (permission) => permission.id === permissions.EditAssignment
                )
                  ? 'default'
                  : 'ghost'
              "
              @click="cancelButton"
            />
            <TheButton
              :size="'sm'"
              :text="'Save'"
              class="!h-fit"
              :is-disabled="
                !currentDevice.assignment?.permissions.find(
                  (permission) => permission.id === permissions.EditAssignment
                )
              "
              :variant="
                currentDevice.assignment?.permissions.find(
                  (permission) => permission.id === permissions.EditAssignment
                )
                  ? 'default'
                  : 'ghost'
              "
              @click="saveButton"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MapService from "@/modules/MapView/Services/MapService";
import MarkerService from "@/modules/MapView/Services/MarkerService";
import L, { LatLngExpression, LeafletEvent } from "leaflet";
import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import InputField from "@/core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import CoordinateConversionService from "@/modules/MapView/Services/CoordinateConversionService";
import WebSocketService from "@/core/WebSocket/WebSocketService";
import { Permissions } from "@/core/Services/Enums";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default defineComponent({
  components: { InputField, TheButton },
  props: ["deviceData", "deviceTypes", "deviceAssignments", "deviceMetadata"],
  data() {
    return {
      permissions: Permissions,
      mapService: {} as MapService,
      markerService: {} as MarkerService,
      currentDevice: {} as DeviceAssignment,
      assignmentService: new AssignmentService(),
      coordinateConversionService: new CoordinateConversionService(),
      webSocketService: new WebSocketService(
        `${ConfigurationService.configData.WEB_SOCKET_PREFIX_PATH}/device`
      ),
      wsData: {} as any,
      toast: useToast(),
      coordinatesBeforeDrag: {} as LatLngExpression,
      coordinates: { lat: null, lng: null } as {
        lat: number | null;
        lng: number | null;
      },
      marker: {} as L.Marker,
      markerCoordinatesChanged: false,
      mapHasMarker: false,
      isDeviceGps: false,
      isNewMarkerEnabled: false,
      isNewMarkerCreated: false,
      newMarkerCordinates: { lat: null, lng: null } as {
        lat: number | null;
        lng: number | null;
      },
      doubleClickMarker: {} as L.Marker,
    };
  },
  watch: {
    mapHasMarker() {
      if (this.mapHasMarker == true) {
        this.mapService.map.doubleClickZoom.disable();
      }
    },
  },
  async mounted() {
    this.mapService = new MapService(
      document.getElementById("map") as HTMLDivElement
    );
    this.mapService.initMap();
    this.mapService.map.doubleClickZoom.disable();
    if (!(this.mapService.map instanceof L.Map)) {
      return;
    }
    this.markerService = new MarkerService(this.mapService.map);
    this.mapService.map.on("dblclick", this.addMarker);

    this.deviceAssignments.forEach((element: any) => {
      if (element.assignment.dataUuid == this.deviceData[0].dataUuid) {
        this.currentDevice = element;
      }
    });

    //Check if there are lat and lng
    if (
      this.currentDevice.assignment?.latitude &&
      this.currentDevice.assignment?.longitude
    ) {
      this.coordinatesBeforeDrag = [
        this.currentDevice.assignment?.latitude,
        this.currentDevice.assignment?.longitude,
      ];
      this.markerService.addMarker({
        lat: this.currentDevice.assignment?.latitude,
        lng: this.currentDevice.assignment?.longitude,
        groupKey: "PARKOM",
        returnMarker: true,
        markerOptions: { draggable: true },
        eventHandlers: {
          dragend: (event: LeafletEvent) => {
            this.marker = event.target;
            this.getMarkerLatLng(event);
            this.markerCoordinatesChanged = true;
          },
        },
      });
      this.mapHasMarker = true;
      this.markerService.fitGroupToMap("PARKOM");
      console.log(this.mapService.map.getZoom());
      this.mapService.map.setZoom(this.mapService.map.getZoom() - 8);
    }

    //It was imagined to get device location based on device type, but for now, location
    //will be shown if there are assignment latitude or longitude - otherwise user can
    //create marker with double click

    //Check metadata
    /*if (this.deviceMetadata.size == 1) {
      const deviceUuid = this.deviceMetadata.keys().next().value;
      const metadata = this.deviceMetadata.get(deviceUuid);
    }*/

    //Check if there are 54 and 55 properties, lat and lng (Device type metadata tab, 54 and 55 are names)
    /* if (this.deviceData[0].data[0].payload.records) {
      if (
        this.deviceData[0].data[0].payload.records.hasOwnProperty(54) ||
        this.deviceData[0].data[0].payload.records.hasOwnProperty(55)
      ) {
        this.isDeviceGps = true;
        this.coordinatesBeforeDrag = [
          this.deviceData[0].data[0].payload.records[55]?.value,
          this.deviceData[0].data[0].payload.records[54]?.value,
        ];
        this.markerService.addMarker({
          lat: this.deviceData[0].data[0].payload.records[55].value,
          lng: this.deviceData[0].data[0].payload.records[54].value,
          groupKey: "PARKOM",
          returnMarker: true,
          markerOptions: { draggable: true },
          eventHandlers: {
            dragend: (event: LeafletEvent) => {
              this.marker = event.target;
              this.getMarkerLatLng(event);
              this.markerCoordinatesChanged = true;
            },
          },
        });
        this.mapHasMarker = true;
        this.markerService.fitGroupToMap("PARKOM");
        this.mapService.map.setZoom(this.mapService.map.getZoom() - 7);
      }
    } */
  },
  methods: {
    saveChanges() {
      try {
        this.assignmentService.editDeviceAssignment({
          deviceAssignment: [
            {
              op: "replace",
              value: this.currentDevice.assignment!.name,
              path: "/name",
            },
          ],
          id: this.currentDevice.assignmentId!,
        });
        this.toast.success("Changes saved");
        //this.$emit('saveChanges');
        this.$emit("changeName", this.currentDevice.assignment!.name);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    updateModelValue(value?: any) {
      this.currentDevice.assignment!.name = value;
    },
    getMarkerLatLng(e: LeafletEvent) {
      this.coordinates = e.target.getLatLng();
      this.marker.setLatLng(e.target.getLatLng());
    },
    cancelButton() {
      if (this.isNewMarkerCreated) {
        this.markerCoordinatesChanged = false;
        this.markerService.removeGroup("PARKOM");
        this.mapHasMarker = false;
      } else {
        this.marker.setLatLng(this.coordinatesBeforeDrag);
        this.markerCoordinatesChanged = false;
      }
    },
    saveButton() {
      try {
        this.assignmentService.editDeviceAssignment({
          deviceAssignment: [
            {
              op: "replace",
              value: this.marker.getLatLng().lat,
              path: "/latitude",
            },
            {
              op: "replace",
              value: this.marker.getLatLng().lng,
              path: "/longitude",
            },
          ],
          id: this.currentDevice.assignmentId!,
        });
        this.toast.success("Changes saved");
        this.coordinatesBeforeDrag = this.marker.getLatLng();
        //this.$emit('saveChanges');
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
      this.markerCoordinatesChanged = false;
    },
    addMarker(e: any) {
      if (this.mapHasMarker) {
        this.markerService.removeGroup("PARKOM");
        this.doubleClickMarker = {} as L.Marker;
        this.marker = {} as L.Marker;
        //@ts-ignore
        this.doubleClickMarker = this.markerService.addMarker({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
          groupKey: "PARKOM",
          returnMarker: true,
          markerOptions: { draggable: true },
          eventHandlers: {
            dragend: (event: LeafletEvent) => {
              this.marker = event.target;
              this.getMarkerLatLng(event);
              this.markerCoordinatesChanged = true;
            },
          },
        });
        this.mapHasMarker = true;
        this.marker = this.doubleClickMarker;
        this.markerCoordinatesChanged = true;
      } else {
        //@ts-ignore
        this.doubleClickMarker = this.markerService.addMarker({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
          groupKey: "PARKOM",
          markerOptions: { draggable: true },
          returnMarker: true,
          eventHandlers: {
            dragend: (event: LeafletEvent) => {
              this.marker = event.target;
              this.getMarkerLatLng(event);
              this.markerCoordinatesChanged = true;
            },
          },
        });
        this.marker = this.doubleClickMarker;
        this.mapHasMarker = true;
        this.isNewMarkerCreated = true;
        this.newMarkerCordinates.lat = e.latlng.lat;
        this.newMarkerCordinates.lng = e.latlng.lng;
        this.markerCoordinatesChanged = true;
      }
    },
    /* createMarkerOnMap() {
      //@ts-ignore
      this.doubleClickMarker = this.markerService.addMarker({
        lat: 46.287014,
        lng: 16.321252,
        groupKey: "PARKOM",
        returnMarker: true,
        markerOptions: { draggable: true },
        eventHandlers: {
          dragend: (event: LeafletEvent) => {
            this.marker = event.target;
            this.getMarkerLatLng(event);
            this.markerCoordinatesChanged = true;
          },
        },
      });
      this.markerService.fitGroupToMap("PARKOM");
      this.mapService.map.setZoom(13);
      this.isNewMarkerCreated = true;
      this.mapHasMarker = true;
      this.marker = this.doubleClickMarker;
      this.markerCoordinatesChanged = true;
    }, */
  },
});
</script>
<style scoped></style>
