import ApiService from "@/core/Axios/ApiService";
import ResourceService, {
  Resource,
} from "@/modules/AccessManager/SubModules/Resources/Services/ResourceService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ConfigurationService from "@/core/Services/ConfigurationService";

export interface AuthResource {
  id: number;
  toUser: { id: number; email: string };
  onCompany: { id: number; name: string };
  resourceType: { id: number; name: string; resourceId: number };
  onResourceId: number;
  role: { id: number; name: string };
  resourcePermissions: {
    id: number;
    resourceId: number;
    permissionId: number;
  }[];
  createdUser: { id: number; email: string };
  policy: {
    id: number;
    name: string;
    validFrom: string;
    validTo: string;
    company: { id: number; name: string };
    createdUser: { id: number; email: string };
  };
}

export interface AuthResourceDto_ {
  id?: number; //toUserId
  email?: string; //toUserEmail
  authResources: {
    id?: number; //id (resource id)
    onCompanyId: number;
    onCompanyName?: string;
    resourceTypeId: number;
    resourceTypeName?: string;
    onResourceId: number | null;
    onResourceName?: string;
    roleId: number | null;
    roleName?: string;
    createdUserId: number;
    createdUserEmail?: string;
    policyId: number | null;
    policyName?: string;
    policyValidFrom: string;
    policyValidTo?: string | null;
  }[];
}

export interface AuthResourceDto {
  id?: number;
  toUserId?: number;
  toUserEmail?: string;
  onCompanyId: number;
  onCompanyName?: string;
  resourceTypeId: number;
  resourceTypeName?: string;
  onResourceId: number | null;
  onResourceName?: string;
  roleId: number | null;
  roleName?: string;
  createdUserId: number;
  createdUserEmail?: string;
  policyId: number | null;
  policyName?: string;
  policyValidFrom: string;
  policyValidTo: string | null;
}

export interface AuthResourceCreate {
  toCompanyId: number;
  toUserId: number;
  onCompanyId: number;
  resourceTypeId: number;
  onResourceId: number;
  roleId: number;
  permissionIds: number[];
  policyId: number;
  companyId: number;
}

export default class AuthResourceService {
  resourceService = new ResourceService();
  amrResources = {} as Resource[];

  async getAuthResources(
    pageNumber: number = 1,
    pageSize: number = 10
  ): Promise<AuthResourceDto_[]> {
    const url = GenerateUrl({
      path: "/api/authorization/resources",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        Include: ["OnCompany", "ResourceType", "Role", "CreatedUser", "Policy"],
        ...{ ResourceTypeId: 2 },
      },
    });

    const response = await ApiService.get(url);
    this.amrResources = await this.resourceService.getAllResources();

    return this._mapAuthResources(response.data.items);
  }

  private _mapAuthResources(authResources: AuthResource[]): AuthResourceDto_[] {
    return authResources.map((item: any) => {
      const { id, email, resources } = item;
      const authResources = resources.map((resource: any) => {
        return {
          id: resource.id,
          onCompanyId: resource.onCompanyId,
          onCompanyName: resource.onCompany?.name,
          resourceTypeId: resource.resourceTypeId,
          resourceTypeName: resource.resourceType?.name,
          onResourceId: resource.onResourceId,
          onResourceName:
            this.amrResources.find(
              (amrResource: Resource) =>
                amrResource.deviceAssignmentId === resource.onResourceId
            )?.name ?? "",
          roleId: resource.roleId,
          roleName: resource.role?.name,
          createdUserId: resource.createdUserId,
          createdUserEmail: resource.createdUser?.email,
          policyId: resource.policyId,
          policyName: resource.policy?.name,
          policyValidFrom: resource.policy?.validFrom,
          policyValidTo: resource.policy?.validTo,
        };
      });

      return {
        id: id,
        email: email,
        authResources: authResources,
      };
    });
  }

  async getAuthResourceWithIdentifier(id: number): Promise<AuthResource> {
    const response = await ApiService.get(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/authorization/resources/${id}`
    );

    return response.data;
  }

  async editAuthResource(patchObject: PatchObject[], id: number): Promise<any> {
    return await ApiService.patch(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/authorization/resources/${id}`,
      patchObject
    );
  }

  async deleteAuthResource(id: number): Promise<any> {
    return await ApiService.delete(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/authorization/resources/${id}`
    );
  }
}
