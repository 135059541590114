import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "h-[calc(100vh_-_16rem)] w-[350px]" }
const _hoisted_2 = { class: "mb-2 flex h-[8%] w-full space-x-2 p-2" }
const _hoisted_3 = {
  key: 1,
  class: "flex h-full w-full space-x-2"
}
const _hoisted_4 = {
  key: 2,
  class: "flex h-full w-full space-x-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isFloorplanCreateMode && !_ctx.isFloorplanEditMode)
        ? (_openBlock(), _createBlock(_component_the_button, {
            key: 0,
            icon: ['fas', 'plus'],
            text: _ctx.$t('CreateFloorplan'),
            size: "full",
            onExecuteMethod: _ctx.emitToggleCreateFloorplanModal
          }, null, 8, ["text", "onExecuteMethod"]))
        : _createCommentVNode("", true),
      (_ctx.isFloorplanCreateMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_the_button, {
              icon: ['fas', 'save'],
              text: _ctx.$t('Save'),
              size: "full",
              onExecuteMethod: _ctx.emitSaveFloorplan
            }, null, 8, ["text", "onExecuteMethod"]),
            _createVNode(_component_the_button, {
              text: _ctx.$t('Cancel'),
              size: "full",
              variant: "outline",
              onExecuteMethod: _ctx.emitCancelFloorplanCreateMode
            }, null, 8, ["text", "onExecuteMethod"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isFloorplanEditMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_the_button, {
              icon: ['fas', 'save'],
              text: _ctx.$t('Save'),
              size: "full",
              onExecuteMethod: _ctx.emitSaveEditFloorplan
            }, null, 8, ["text", "onExecuteMethod"]),
            _createVNode(_component_the_button, {
              text: _ctx.$t('Cancel'),
              size: "full",
              variant: "outline",
              onExecuteMethod: _ctx.emitCancelFloorplanEditMode
            }, null, 8, ["text", "onExecuteMethod"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_custom_table, {
      options: _ctx.options,
      "remove-table-header": true,
      "table-data": _ctx.floorplans,
      "table-headers": _ctx.tableHeaders,
      "table-height": "90%",
      onSelectedRows: _ctx.getSelectedRow
    }, null, 8, ["options", "table-data", "table-headers", "onSelectedRows"])
  ]))
}