import { defineStore } from "pinia";

export const useModuleToggleStore = defineStore("module", {
  state: () => {
    return {
      isDevelopment: false,
    };
  },

  actions: {
    toggleDevelopment() {
      this.isDevelopment = !this.isDevelopment;
      localStorage.setItem("is_development", String(this.isDevelopment));
    },
  },
  persist: true,
});
