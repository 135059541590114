<template>
  <div class="p-6">
    <last-data-preview
      :data="dataForModal"
      :is-last-data-modal-opened="isLastDataModalOpened"
      :name="deviceName"
      @toggle-modal="toggleModal"
    />
    <div class="mb-4 flex h-[48px] items-center space-x-4">
      <dx-date-box
        v-model="from"
        :height="48"
        display-format="dd.MM.yyyy HH:mm"
        type="datetime"
        class="mt-1"
        :clearable="false"
      />
      <dx-date-box
        v-model="to"
        :height="48"
        display-format="dd.MM.yyyy HH:mm"
        type="datetime"
        class="mt-1"
        :clearable="false"
      />
      <tags-input
        class="min-w-[228px]"
        :tags="channels"
        @on-tag-mutate="(tags) => (channels = tags)"
      />
      <the-button
        :icon="['fas', 'rotate-left']"
        button-type="primaryButton"
        @click="getDataSentByDevice"
        class="mt-1"
      />
    </div>
    <custom-table
      :allow-auto-resize="false"
      :details="true"
      :is-data-source-loading="isDataSourceLoading"
      :table-data="tableData"
      :table-headers="tableHeaders"
      table-height="60vh"
      @open-data-modal="displayData"
      @selected-rows="getSelectedRow"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import DxDateBox from "devextreme-vue/date-box";
import TheButton from "@/core/Components/TheButton.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import moment from "moment";
import Loader from "@/core/Components/Loader.vue";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Modal from "@/core/Components/Modal.vue";
import VSelect from "vue-select";
import { useToast } from "vue-toastification";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import DeviceDataService, {
  DeviceAssignmentData,
} from "@/modules/Devices/Services/DeviceDataService";
import TagsInput from "@/core/Components/FormElements/TagsInput.vue";
import LastDataPreview from "@/modules/Devices/Submodules/Assignments/Components/LastDataPreview.vue";

export default defineComponent({
  components: {
    MainSection,
    DxDateBox,
    TheButton,
    CustomTable,
    LastDataPreview,
    Loader,
    ToastComponent,
    Modal,
    VSelect,
    TagsInput,
  },
  props: ["deviceUuid", "deviceName"],
  data() {
    return {
      isLoading: false,
      dataForModal: {} as DeviceAssignmentData,
      deviceDataService: new DeviceDataService(),
      isLastDataModalOpened: false,
      deviceAssignment: {} as DeviceAssignment,
      isDataSourceLoading: false,
      toast: useToast(),
      from: moment().startOf("day").format("yyyy-MM-DD HH:mm"),
      to: moment().endOf("day").format("yyyy-MM-DD HH:mm"),
      isSentDataModalOpened: false,
      channels: [] as string[],
      tableHeaders: {
        dateTime: { header: "Datetime", visible: true },
        channel: { header: "Channel", visible: true },
        payload: { header: "Payload", visible: true },
      },
      tableData: [] as any[],
      selectedData: [] as any[],
    };
  },
  watch: {},

  async created() {
    this.isLoading = true;
    try {
      await this.getDataSentByDevice();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    displayData(data: any) {
      this.toggleModal();
      this.dataForModal = data;
    },

    async getDataSentByDevice() {
      this.isDataSourceLoading = true;
      let channels = this.channels.map((channel) => Number(channel));

      try {
        this.tableData = await this.deviceDataService.getDeviceData({
          deviceUuid: this.deviceUuid,
          from: this.from,
          to: this.to,
          channel: channels,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    getMaxDateTime() {
      const maxDateTime = moment(this.from)
        .add(14, "days")
        .subtract(1, "minute")
        .format("yyyy-MM-DD HH:mm");

      return new Date(maxDateTime);
    },

    getMinDateTime() {
      const minDateTime = moment(this.to)
        .subtract(14, "days")
        .add(1, "minute")
        .format("yyyy-MM-DD HH:mm");

      return new Date(minDateTime);
    },

    toggleSentDataModal() {
      this.isSentDataModalOpened = !this.isSentDataModalOpened;
    },
    getSelectedRow(selectedRow: any[]) {
      this.selectedData = selectedRow;
    },
    toggleModal() {
      this.isLastDataModalOpened = !this.isLastDataModalOpened;
    },
  },
});
</script>
<style scoped></style>
