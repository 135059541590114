<template>
  <div class="loader z-252 absolute flex w-full items-center justify-center">
    <small-loader
      width="w-[80px]"
      height="h-[80px]"
      :outer-ring-thickness="outerRingThickness"
      :inner-ring-thickness="innerRingThickness"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SmallLoader from "./SmallLoader.vue";
export default defineComponent({
  components: { SmallLoader },
  props: {
    outerRingThickness: {
      type: String,
      default: "border-4",
    },
    innerRingThickness: {
      type: String,
      default: "border-t-4",
    },
  },
});
</script>
<style scoped>
.loader {
  height: calc(100vh - 20rem);
}
</style>
