import axios from "axios";
import moment from "moment";
import { Moment } from "moment";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type AccessProfile = {
  id?: number;
  name: string;
  uuid?: string;
  deviceId?: number;
  hourFrom: string | Date;
  hourTo: string | Date;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export type AccessProfileDto = {
  id?: number;
  name: string;
  uuid?: string;
  deviceId?: number;
  hourFrom: string | Date;
  hourTo: string | Date;
  days: Days;
};

export type Days = {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
};

export default class AccessProfileService {
  async getAllAccessProfiles(): Promise<AccessProfileDto[]> {
    const response = await axios.get(
      `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/access-profiles`
    );

    return this._mapAccessProfiles(response.data);
  }

  async getAccessProfileWithIdentifier(id: number): Promise<AccessProfileDto> {
    const response = await axios.get(
      `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/access-profiles/${id}`
    );

    return this._mapAccessProfile(response.data);
  }

  async createAccessProfile(accessProfile: AccessProfile): Promise<any> {
    accessProfile.hourFrom = moment(accessProfile.hourFrom).format("HH:mm");
    accessProfile.hourTo = moment(accessProfile.hourTo).format("HH:mm");
    return await axios.post(
      `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/access-profiles`,
      accessProfile
    );
  }

  async updateAccessProfile(accessProfile: AccessProfileDto): Promise<any> {
    const updateAccessProfile: AccessProfile = {
      id: accessProfile.id,
      name: accessProfile.name,
      hourFrom: accessProfile.hourFrom,
      hourTo: accessProfile.hourTo,
      deviceId: accessProfile.deviceId,
      uuid: accessProfile.uuid,
      ...accessProfile.days,
    };
    return await axios.put(
      `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/access-profiles/${accessProfile.id}`,
      updateAccessProfile
    );
  }

  async deleteAccessProfile(id: number): Promise<boolean> {
    try {
      await axios.delete(
        `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/access-profiles/${id}`
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  private _mapAccessProfiles(response: AccessProfile[]): AccessProfileDto[] {
    return response.map((accessProfile: AccessProfile) =>
      this._mapAccessProfile(accessProfile)
    );
  }

  private _mapAccessProfile(accessProfile: AccessProfile): AccessProfileDto {
    return {
      id: accessProfile.id,
      name: accessProfile.name,
      uuid: accessProfile.uuid,
      deviceId: accessProfile.deviceId,
      hourFrom: accessProfile.hourFrom,
      hourTo: accessProfile.hourTo,
      days: {
        monday: accessProfile.monday,
        tuesday: accessProfile.tuesday,
        wednesday: accessProfile.wednesday,
        thursday: accessProfile.thursday,
        friday: accessProfile.friday,
        saturday: accessProfile.saturday,
        sunday: accessProfile.sunday,
      },
    };
  }
}
