<template>
  <modal-component
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    title="Edit node"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form @submit.prevent="handleSubmit">
      <form-wrapper :is-form-in-modal="true">
        <form-divider>
          <!--          <input-field v-model="node.displayId" label="Display ID"/>-->
          <input-field v-model="node.index" label="Index" />
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Node type</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isNodeTypeListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="node.typeId"
                :class="`mt-1 w-full border ${
                  v$['node']?.typeId?.$error
                    ? 'rounded-md border-error'
                    : 'border-none'
                }`"
                :options="nodeTypes"
                :reduce="(type : any) => type.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <input-field v-model="node.overrideValue" label="Override value" />
          <input-field v-model="node.correction" label="Correction" />
          <input-field
            v-model="node.overrideFreeArrow"
            label="Override free arrow"
          />
          <input-field
            v-model="node.overrideOccupiedArrow"
            label="Override occupied arrow"
          />
        </form-divider>
      </form-wrapper>
      <button-wrapper :is-submit-loading="isSubmitLoading" />
    </form>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import {
  EditNode,
  Node,
  NodeType,
} from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  components: {
    SmallLoader,
    VSelect,
    InputField,
    ButtonWrapper,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    nodeProp: {
      type: Object as PropType<Node>,
      required: true,
    },
    isEditModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      node: {} as EditNode,
      displayService: new DisplayService(),
      nodeTypes: [] as NodeType[],
      toast: useToast(),
      v$: useVuelidate(),
      isSubmitLoading: false,
      isNodeTypeListLoading: false,
    };
  },
  validations() {
    return {
      node: {
        typeId: {
          required,
        },
      },
    };
  },
  async created() {
    const { refresh, lastValue, ...rest } = this.nodeProp;
    this.node = rest;
    console.log(this.node);
    try {
      await this.getNodeTypes();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },
  methods: {
    async handleSubmit() {
      await this.v$.$validate();
      if (this.v$.$error) {
        this.toast.error("Validation form error");
        return;
      }
      this.isSubmitLoading = true;
      try {
        this.node.correction = Number(this.node.correction)
          ? this.node.correction
          : null;
        this.node.index = Number(this.node.index);
        await this.displayService.editNode(this.node);
        this.toast.success("Node successfully updated!");
        this.$emit("toggle-modal");
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },

    async getNodeTypes() {
      this.isNodeTypeListLoading = true;
      try {
        this.nodeTypes = await this.displayService.getNodeTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isNodeTypeListLoading = false;
      }
    },
  },
});
</script>

<style scoped></style>
