import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type ReportParameters = {
  deviceUuid: string;
  from: string;
  to: string;
  offset: number;
  fileType: string;
  languageCode: string;
};

export default class ReportService {
  async downloadReport({
    deviceUuid,
    from,
    to,
    offset,
    fileType,
    languageCode,
  }: ReportParameters) {
    return await ApiService.get(
      `${ConfigurationService.configData.REPORT_API_PREFIX_PATH}?deviceUuid=${deviceUuid}&from=${from}&to=${to}&offset=${offset}&fileType=${fileType}&languageCode=${languageCode}`,
      { responseType: "blob" }
    );
  }
}
