<template>
  <modal-component
    :have-modal="true"
    :is-displayed="isTagCreateModalOpened"
    :title="'Create tag'"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="createTag">
        <form-divider>
          <input-field
            v-model="tag.id"
            :label="'Id'"
            :errorMessage="v$?.tag['id']?.$errors[0]?.$message"
            :isError="v$?.tag['id']?.$error"
            :required="true"
          />
          <input-field
            v-model="tag.name"
            :label="'Name'"
            :errorMessage="v$?.tag['name']?.$errors[0]?.$message"
            :isError="v$?.tag['name']?.$error"
            :required="true"
          />
        </form-divider>
        <div class="flex w-full items-center gap-4 px-6">
          <the-button
            :text="$t('Cancel')"
            size="full"
            variant="outline"
            @execute-method="$emit('toggle-modal')"
          />
          <the-button :text="'Draw tag'" size="full" />
        </div>
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import TheButton from "@/core/Components/TheButton.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import { maxLength, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    InputField,
    FormDivider,
    FormWrapper,
    TheButton,
    ModalComponent,
  },
  emits: ["toggle-modal", "change-to-create-mode", "add-tag"],
  props: {
    isTagCreateModalOpened: Boolean,
  },
  data() {
    return {
      tag: {
        id: "",
        name: "",
        invertY: false,
      },
      v$: useValidate() as any,
      validationErrors: [] as any,
    };
  },
  validations() {
    return {
      tag: {
        id: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
      },
    };
  },
  methods: {
    createTag() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.$emit("change-to-create-mode", true);
        this.$emit("add-tag", this.tag);
        this.$emit("toggle-modal");
      }
    },
  },
});
</script>

<style scoped></style>
