<template>
  <grid :column-number="2">
    <div
      v-for="type in types"
      :key="type.id"
      :class="`flex flex-col items-center space-y-2 border py-4 grayscale duration-300 ease-in-out hover:grayscale-0  ${
        selectedFileType === type.id && 'border-primary grayscale-0'
      } cursor-pointer`"
      @click="() => selectFileType(type.id)"
    >
      <img :src="type.imgUrl" alt="icon" />
      <p class="font-medium">{{ type.name }}</p>
    </div>
  </grid>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Grid from "@/core/Components/Grid.vue";

export default defineComponent({
  components: { Grid },
  props: {
    selectedFileType: String,
  },
  emits: ["select-file-type"],
  data() {
    return {
      types: [
        {
          id: "XLSX",
          imgUrl: `${process.env.VUE_APP_PUBLIC_PATH}export-icons/excel.png`,
          name: "Excel",
          extension: "xlsx",
        },
        {
          id: "PDF",
          imgUrl: `${process.env.VUE_APP_PUBLIC_PATH}export-icons/pdf.png`,
          name: "PDF",
          extension: "pdf",
        },
      ],
    };
  },
  methods: {
    selectFileType(type: string) {
      this.$emit("select-file-type", type);
    },
  },
});
</script>

<style scoped></style>
