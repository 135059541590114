import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-4 text-2xl font-bold tracking-tighter text-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass(`z-1 relative transform duration-300 ease-in-out ${
      _ctx.sidebarStore.isSidebarOpened ? 'section-class' : 'section-class-closed'
    } ${
      !_ctx.isMap && 'p-4 py-8'
    }  relative overflow-y-auto overflow-x-hidden bg-sectionbackground`)
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}