<template>
  <modal-component
    :remove-outside-click="true"
    :is-displayed="isModalOpened"
    :title="$t('ResponseMessages.Messages')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <loader v-if="isLoading" />
    <div class="px-6" v-else>
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :is-action-list-not-displayed="true"
        :no-selection="true"
        :table-data="paginatedAssetResponseMessages.items"
        :table-headers="tableHeaders"
        @force-refresh="getAllAssetResponseMessages()"
      />
      <pagination
        :pagination-data="paginatedAssetResponseMessages"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import { ResponseMessagesObject } from "../Services/ResponseMessagesTypes";
import Pagination from "@/core/Components/Pagination.vue";
import { PaginationSettings } from "@/core/Services/PaginationSettings";
import ResponseMessagesService from "@/modules/ResponseMessages/Services/ResponseMessagesService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Loader from "@/core/Components/Loader.vue";

export default defineComponent({
  components: { CustomTable, ModalComponent, Loader, Pagination },
  props: {
    isModalOpened: Boolean,
    assetId: {
      type: Number,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      responseMessagesService: new ResponseMessagesService(),
      isDataSourceLoading: false,
      isLoading: false,
      currentPage: PaginationSettings.DEFAULT_CURRENT_PAGE,
      currentPageSize: PaginationSettings.DEFAULT_CURRENT_PAGE_SIZE,
      paginatedAssetResponseMessages: {} as ResponseMessagesObject,
      toast: useToast(),
      tableHeaders: {
        message: { header: this.$t("ResponseMessages.Message"), visible: true },
        createdAt: {
          header: this.$t("ResponseMessages.CreatedAt"),
          visible: true,
        },
        updatedAt: {
          header: this.$t("ResponseMessages.UpdatedAt"),
          visible: true,
        },
        isActive: {
          header: this.$t("ResponseMessages.IsActive"),
          visible: true,
        },
        isSent: {
          header: this.$t("ResponseMessages.IsSent"),
          visible: true,
        },
      },
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getAllAssetResponseMessages();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handlePageChange(newPage: number) {
      this.currentPage = newPage;
      this.getAllAssetResponseMessages();
    },
    handlePageSizeChange(newPageSize: number) {
      this.currentPageSize = newPageSize;
      this.currentPage = 1;
      this.getAllAssetResponseMessages();
    },
    async getAllAssetResponseMessages() {
      this.isDataSourceLoading = true;
      try {
        this.paginatedAssetResponseMessages =
          await this.responseMessagesService.getAllAssetResponseMessages(
            this.currentPage,
            this.currentPageSize,
            this.assetId
          );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
  },
});
</script>

<style scoped></style>
