<template>
  <modal-component
    :is-displayed="isMapLocationModalOpened"
    :is-full-screen-modal="true"
    :no-padding="true"
    :title="
      config.IS_PARKOM == 'true'
        ? $t('ParkingSpaces.ParkingSpaceLocation')
        : 'Edit asset location'
    "
    @toggle-modal="$emit('toggle-modal')"
  >
    <div id="map" class="my-auto h-[700px] w-full"></div>
    <div class="flex w-full items-center justify-end gap-2 px-6 py-3">
      <the-button
        :text="$t('General.Save')"
        button-type="primaryButton"
        @execute-method="handleSubmit"
      />
      <the-button
        :text="$t('General.Cancel')"
        button-type="oulineButton"
        @execute-method="$emit('toggle-modal')"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType, inject } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import MapService from "@/modules/MapView/Services/MapService";
import MarkerService from "@/modules/MapView/Services/MarkerService";
import L, { LatLngExpression, LeafletEvent } from "leaflet";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: { TheButton, ModalComponent },
  props: {
    isMapLocationModalOpened: Boolean,
    coordinatesProp: {
      type: Object as PropType<{ lat: number | null; lng: number | null }>,
      required: true,
    },
  },
  emits: ["toggle-modal", "get-coordinates"],
  data() {
    return {
      config: inject("config") as any,
      mapService: {} as MapService,
      markerService: {} as MarkerService,
      coordinates: { lat: null, lng: null } as {
        lat: number | null;
        lng: number | null;
      },
      marker: {} as L.Marker,
    };
  },
  mounted() {
    this.coordinates = this.coordinatesProp;
    this.mapService = new MapService(
      document.getElementById("map") as HTMLElement
    );
    this.mapService.initMap();
    this.mapService.map.doubleClickZoom.disable();
    if (!(this.mapService.map instanceof L.Map)) {
      return;
    }

    if (!this.coordinatesProp.lat) {
      this.coordinatesProp.lat = 46.28693751708832;
    }

    if (!this.coordinatesProp.lng) {
      this.coordinatesProp.lng = 16.321398521595686;
    }

    this.markerService = new MarkerService(this.mapService.map);

    this.marker = this.markerService.addMarker({
      lat: this.coordinatesProp.lat,
      lng: this.coordinatesProp.lng,
      groupKey: "PARKOM",
      markerOptions: { draggable: true },
      eventHandlers: {
        dragend: (event: LeafletEvent) => {
          this.getMarkerLatLng(event);
        },
      },
    }) as L.Marker;

    this.mapService.map.on("dblclick", this.changePositon);

    this.markerService.fitGroupToMap("PARKOM");
  },

  methods: {
    changePositon(e: any) {
      this.markerService.removeGroup("PARKOM");
      this.coordinates = e.latlng;

      this.marker = this.markerService.addMarker({
        lat: e.latlng.lat,
        lng: e.latlng.lng,
        groupKey: "PARKOM",
        markerOptions: { draggable: true },
        eventHandlers: {
          dragend: (event: LeafletEvent) => {
            this.getMarkerLatLng(event);
          },
        },
      }) as L.Marker;
    },
    handleSubmit() {
      this.$emit("get-coordinates", this.coordinates);
      this.$emit("toggle-modal");
    },
    getMarkerLatLng(e: LeafletEvent) {
      this.coordinates = e.target.getLatLng();
    },
  },
});
</script>

<style scoped></style>
