<template>
  <send-message-modal
    v-if="isSendMessageModalOpened"
    :is-send-message-modal-opened="isSendMessageModalOpened"
    :refresh-data="getResponseMessages"
    :asset-ids="assetIds"
    @refresh-data="getResponseMessages()"
    @toggle-modal="isSendMessageModalOpened = false"
  />
  <messages-view-modal
    v-if="isMessagesViewModalOpened"
    :is-modal-opened="isMessagesViewModalOpened"
    :asset-id="selectedResponseMessage?.id"
    @toggle-modal="isMessagesViewModalOpened = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else>
      <table-action-header
        :button-text="$t('ResponseMessages.SendMessage')"
        :icon="['fas', 'add']"
        :title="$t('ResponseMessages.Title')"
        :permission-id="permissions.CommitAssignmentDeviceAction"
        description=" "
        @execute-method="toggleSendMessageModal"
      />
      <custom-table
        :options="options"
        :is-data-source-loading="isDataSourceLoading"
        :table-data="paginatedResponseMessages.items"
        :table-headers="tableHeaders"
        :multiselect="true"
        @selected-rows="getSelectedRow"
        @force-refresh="getResponseMessages()"
      />
      <pagination
        :pagination-data="paginatedResponseMessages"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import ResponseMessagesService from "@/modules/ResponseMessages/Services/ResponseMessagesService";
import {
  LastResponseMessage,
  LastResponseMessagesObject,
} from "@/modules/ResponseMessages/Services/ResponseMessagesTypes";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import AssignmentService from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import SendMessageModal from "@/modules/ResponseMessages/Components/SendMessageModal.vue";
import Loader from "@/core/Components/Loader.vue";
import MessagesViewModal from "@/modules/ResponseMessages/Components/MessagesViewModal.vue";
import Pagination from "@/core/Components/Pagination.vue";
import { PaginationSettings } from "@/core/Services/PaginationSettings";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    MessagesViewModal,
    Loader,
    TableActionHeader,
    CustomTable,
    MainSection,
    SendMessageModal,
    Pagination,
  },
  data() {
    return {
      responseMessagesService: new ResponseMessagesService(),
      assignmentService: new AssignmentService(),
      currentPage: PaginationSettings.DEFAULT_CURRENT_PAGE,
      currentPageSize: PaginationSettings.DEFAULT_CURRENT_PAGE_SIZE,
      paginatedResponseMessages: {} as LastResponseMessagesObject,
      selectedResponseMessage: {} as LastResponseMessage,
      responseMessages: [] as LastResponseMessage[],
      assetIds: [] as number[],
      permissions: Permissions,
      toast: useToast(),
      isLoading: false,
      isDataSourceLoading: false,
      isSendMessageModalOpened: false,
      isMessagesViewModalOpened: false,
      tableHeaders: {
        id: { header: "ID", visible: false },
        assetName: { header: "Asset name", visible: true },
        mac: { header: "MAC", visible: true },
        imei: { header: "IMEI", visible: true },
        lastTimeDeviceSentData: { header: "Last data sent", visible: true },
        serialNumber: { header: "Serial number", visible: true },
        responseMessage: { header: "Last message", visible: true },
      },
      options: {
        viewAllMessages: {
          header: "View all messages",
          icon: ["fas", "eye"],
          action: this.sentMessages,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getResponseMessages();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handlePageChange(newPage: number) {
      this.currentPage = newPage;
      this.getResponseMessages();
    },
    handlePageSizeChange(newPageSize: number) {
      this.currentPageSize = newPageSize;
      this.currentPage = 1;
      this.getResponseMessages();
    },
    async getResponseMessages() {
      this.isDataSourceLoading = true;
      try {
        this.paginatedResponseMessages =
          await this.responseMessagesService.getResponseMessages(
            this.currentPage,
            this.currentPageSize
          );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    sentMessages() {
      this.isMessagesViewModalOpened = true;
    },
    getSelectedRow(selectedRows: LastResponseMessage[]) {
      this.selectedResponseMessage = selectedRows[0];
      this.assetIds = selectedRows.map((message) => message.id);
    },
    toggleSendMessageModal() {
      this.isSendMessageModalOpened = !this.isSendMessageModalOpened;
    },
    toggleMessagesViewModal() {
      this.isMessagesViewModalOpened = !this.isMessagesViewModalOpened;
    },
  },
});
</script>

<style scoped></style>
