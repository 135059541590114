<template>
  <modal-component
    :have-modal="true"
    :is-displayed="isShowRouteModalOpened"
    :title="'Route time range'"
    :removeOutsideClick="true"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="flex items-center gap-4 px-6">
      <dx-date-box
        v-model="from"
        :height="48"
        display-format="dd.MM.yyyy HH:mm"
        type="datetime"
        class="mt-1"
        :clearable="false"
      />
      <dx-date-box
        v-model="to"
        :height="48"
        display-format="dd.MM.yyyy HH:mm"
        type="datetime"
        class="mt-1"
        :clearable="false"
      />
      <the-button
        text="Show route"
        button-type="primaryButton"
        class="mt-1"
        @click="showAssetRouteOnTheMap"
      />
      <the-button
        text="Cancel"
        button-type="primaryButton"
        class="mt-1"
        @click="$emit('toggle-modal')"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import DxDateBox from "devextreme-vue/date-box";
import TheButton from "@/core/Components/TheButton.vue";
import moment from "moment";

export default defineComponent({
  components: {
    DxDateBox,
    TheButton,
    ModalComponent,
  },
  emits: ["toggle-modal", "show-asset-route"],
  props: {
    isShowRouteModalOpened: Boolean,
  },
  data() {
    return {
      from: moment().startOf("day").format("yyyy-MM-DD HH:mm"),
      to: moment().endOf("day").format("yyyy-MM-DD HH:mm"),
    };
  },
  methods: {
    showAssetRouteOnTheMap() {
      this.$emit("show-asset-route", this.from, this.to);
    },
  },
});
</script>

<style scoped></style>
