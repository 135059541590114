<template>
  <modal
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    :title="$t('Groups.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="flex h-full w-full items-center justify-center">
      <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
        <form @submit.prevent="handleSubmit">
          <form-divider>
            <input-field
              v-model="formData.name"
              :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
              :isError="v$?.formData['name']?.$error"
              :label="$t('Groups.Name')"
              :required="true"
            />
          </form-divider>
          <button-wrapper :is-submit-loading="isLoadingSubmit" />
        </form>
      </form-wrapper>
    </div>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "../../../core/Components/MainSection.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "../../../core/Components/FormElements/InputField.vue";
//@ts-ignore
import VSelect from "vue-select";
import TheButton from "../../../core/Components/TheButton.vue";
import ApplicationService, {
  Application,
} from "../Services/ApplicationService";
import Modal from "@/core/Components/Modal.vue";
import SelectTable from "@/core/Components/SelectTable.vue";
import Loader from "../../../core/Components/Loader.vue";
import ToastComponent from "../../../core/Components/Notifications/ToastComponent.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    MainSection,
    FormWrapper,
    InputField,
    VSelect,
    TheButton,
    Modal,
    SelectTable,
    Loader,
    ToastComponent,
    FormDivider,
    ButtonWrapper,
  },
  props: {
    isEditModalOpened: Boolean,
    objectId: { type: Number, required: true },
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      applicationService: new ApplicationService(),
      isSelectModalOpened: false as Boolean,
      modalTitle: "",
      isLoading: false,
      isLoadingSubmit: false as boolean,
      toast: useToast(),
      tableHeaders: {
        id: "ID",
        name: "Name",
      },
      formData: {
        name: "",
        companyId: 0,
      } as Application,
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getApplication();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
      },
    };
  },

  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isLoadingSubmit = true;
        try {
          await this.applicationService.updateApplication(this.formData);
          this.toast.success(this.$t("General.EditedSuccessfully"));
          await this.refreshData?.();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isLoadingSubmit = false;
        }
      }
    },

    openSelectModal() {
      this.isSelectModalOpened = !this.isSelectModalOpened;
      this.modalTitle = "Select company";
    },

    async getApplication() {
      try {
        this.formData =
          await this.applicationService.getApplicationWithIdentifier(
            this.objectId
          );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    setSelected(data: any) {
      this.formData.companyId = data.id;
      this.isSelectModalOpened = false;
    },
  },
});
</script>
<style scoped></style>
