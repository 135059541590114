import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-6" }
const _hoisted_2 = { class: "mb-4 flex h-[48px] items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_last_data_preview = _resolveComponent("last-data-preview")!
  const _component_dx_date_box = _resolveComponent("dx-date-box")!
  const _component_tags_input = _resolveComponent("tags-input")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_last_data_preview, {
      data: _ctx.dataForModal,
      "is-last-data-modal-opened": _ctx.isLastDataModalOpened,
      name: _ctx.deviceName,
      onToggleModal: _ctx.toggleModal
    }, null, 8, ["data", "is-last-data-modal-opened", "name", "onToggleModal"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_dx_date_box, {
        modelValue: _ctx.from,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.from) = $event)),
        height: 48,
        "display-format": "dd.MM.yyyy HH:mm",
        type: "datetime",
        class: "mt-1",
        clearable: false
      }, null, 8, ["modelValue"]),
      _createVNode(_component_dx_date_box, {
        modelValue: _ctx.to,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.to) = $event)),
        height: 48,
        "display-format": "dd.MM.yyyy HH:mm",
        type: "datetime",
        class: "mt-1",
        clearable: false
      }, null, 8, ["modelValue"]),
      _createVNode(_component_tags_input, {
        class: "min-w-[228px]",
        tags: _ctx.channels,
        onOnTagMutate: _cache[2] || (_cache[2] = (tags) => (_ctx.channels = tags))
      }, null, 8, ["tags"]),
      _createVNode(_component_the_button, {
        icon: ['fas', 'rotate-left'],
        "button-type": "primaryButton",
        onClick: _ctx.getDataSentByDevice,
        class: "mt-1"
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_custom_table, {
      "allow-auto-resize": false,
      details: true,
      "is-data-source-loading": _ctx.isDataSourceLoading,
      "table-data": _ctx.tableData,
      "table-headers": _ctx.tableHeaders,
      "table-height": "60vh",
      onOpenDataModal: _ctx.displayData,
      onSelectedRows: _ctx.getSelectedRow
    }, null, 8, ["is-data-source-loading", "table-data", "table-headers", "onOpenDataModal", "onSelectedRows"])
  ]))
}