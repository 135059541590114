import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-6" }
const _hoisted_2 = { class: "mb-4 flex h-[48px] items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviceMapModal = _resolveComponent("DeviceMapModal")!
  const _component_modal = _resolveComponent("modal")!
  const _component_image_preview_modal = _resolveComponent("image-preview-modal")!
  const _component_last_data_preview = _resolveComponent("last-data-preview")!
  const _component_dx_date_box = _resolveComponent("dx-date-box")!
  const _component_tags_input = _resolveComponent("tags-input")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isMapModalLoaded)
      ? (_openBlock(), _createBlock(_component_modal, {
          key: 0,
          "have-modal": true,
          "is-full-screen-modal": true,
          "is-displayed": _ctx.isMapModalLoaded,
          title: `Device coordinates`,
          "remove-outside-click": true,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isMapModalLoaded = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DeviceMapModal, { data: _ctx.tableData }, null, 8, ["data"])
          ]),
          _: 1
        }, 8, ["is-displayed"]))
      : _createCommentVNode("", true),
    (_ctx.isImageModalOpened)
      ? (_openBlock(), _createBlock(_component_modal, {
          key: 1,
          "have-modal": true,
          "is-displayed": _ctx.isImageModalOpened,
          title: `Image preview`,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isImageModalOpened = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_image_preview_modal, {
              "base64-image": _ctx.selectedData[0].payload.pictureData
            }, null, 8, ["base64-image"])
          ]),
          _: 1
        }, 8, ["is-displayed"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_last_data_preview, {
        data: _ctx.dataForModal,
        "is-last-data-modal-opened": _ctx.isLastDataModalOpened,
        name: _ctx.deviceAssignment?.assignment?.name,
        onToggleModal: _ctx.toggleModal
      }, null, 8, ["data", "is-last-data-modal-opened", "name", "onToggleModal"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_dx_date_box, {
          modelValue: _ctx.from,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.from) = $event)),
          height: 48,
          "display-format": "dd.MM.yyyy HH:mm",
          type: "datetime",
          class: "mt-1",
          clearable: false
        }, null, 8, ["modelValue"]),
        _createVNode(_component_dx_date_box, {
          modelValue: _ctx.to,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.to) = $event)),
          height: 48,
          "display-format": "dd.MM.yyyy HH:mm",
          type: "datetime",
          class: "mt-1",
          clearable: false
        }, null, 8, ["modelValue"]),
        _createVNode(_component_tags_input, {
          class: "min-w-[228px]",
          tags: _ctx.channels,
          onOnTagMutate: _cache[4] || (_cache[4] = (tags) => (_ctx.channels = tags))
        }, null, 8, ["tags"]),
        _createVNode(_component_the_button, {
          icon: ['fas', 'rotate-left'],
          "button-type": "primaryButton",
          onClick: _ctx.getDataSentByDevice,
          class: "mt-1"
        }, null, 8, ["onClick"]),
        (_ctx.isMapLoaded)
          ? (_openBlock(), _createBlock(_component_the_button, {
              key: 0,
              text: "Show map",
              "button-type": "primaryButton",
              class: "mt-1",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isMapModalLoaded = true))
            }))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_custom_table, {
        "allow-auto-resize": false,
        details: true,
        "is-data-source-loading": _ctx.isDataSourceLoading,
        "table-data": _ctx.tableData,
        "table-headers": _ctx.tableHeaders,
        options: _ctx.options,
        "table-height": "60vh",
        onOpenDataModal: _ctx.displayData,
        onSelectedRows: _ctx.getSelectedRow
      }, null, 8, ["is-data-source-loading", "table-data", "table-headers", "options", "onOpenDataModal", "onSelectedRows"])
    ])
  ], 64))
}