<template>
  <div class="flex h-full w-full items-center justify-center">
    <form-wrapper :is-form-in-modal="isFormInModal">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >User</label
            >
            <div class="flex w-full justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <div v-else class="flex w-full items-center space-x-4">
                <v-select
                  v-model="userId"
                  :options="users"
                  :reduce="(type : any) => type.id"
                  class="w-full"
                  label="email"
                  :clearable=false
                ></v-select>
              </div>
            </div>
          </div>
          <checkbox-input
            v-model="isAllPermissionCheckBoxChecked"
            :is-checked="isAllPermissionCheckBoxChecked"
            label="Give permission for all resources"
          />
          <form-group v-if="!isAllPermissionCheckBoxChecked" title="Access">
            <div
              v-for="(access, index) in profileConfiguration.deviceRequest.data
                .accesses"
              :style="{ gridTemplateColumns: '5fr 1fr' }"
              class="grid grid-cols-2 gap-x-4"
            >
              <div class="grid grid-cols-2 gap-x-4">
                <div class="relative col-span-2 items-center py-3">
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >Resource</label
                  >
                  <small-loader v-if="isLoading" height="h-8" width="w-8" />
                  <v-select
                    v-else
                    v-model="access.resourceId"
                    :options="resourcesForSelect"
                    :reduce="(type : any) => type.id"
                    class="mt-1 w-full"
                    label="name"
                    :clearable=false
                  ></v-select>
                </div>
                <div>
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >From</label
                  >
                  <dx-date-box
                    v-model="access.dateActiveFrom"
                    :height="42"
                    :show-clear-button="false"
                    display-format="dd/MM/yyyy, HH:mm"
                    type="datetime"
                    :accept-custom-value="false"
                  />
                </div>
                <div>
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >To</label
                  >
                  <dx-date-box
                    v-model="access.dateActiveTo"
                    :height="42"
                    :show-clear-button="true"
                    display-format="dd/MM/yyyy, HH:mm"
                    type="datetime"
                    :accept-custom-value="false"
                  />
                </div>
              </div>
              <add-delete-buttons
                :accesses="profileConfiguration.deviceRequest.data.accesses"
                :index="index"
                @add-click="addAccess"
                @remove-click="removeAccess(index)"
              />
            </div>
          </form-group>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import Loader from "@/core/Components/Loader.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import FormGroup from "@/core/Components/FormElements/FormGroup.vue";
import DxDateBox from "devextreme-vue/date-box";
//@ts-ignore
import VSelect from "vue-select";
import AddDeleteButtons from "@/core/Components/FormElements/AddDeleteButtons.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import ResourceService, {
  AmrResource,
} from "../../Resources/Services/ResourceService";
import AccessProfileService, {
  AccessProfileDto,
} from "../../AccessProfiles/Services/AccessProfileService";
import ProfileConfigurationService, {
  AmrPolicy,
  IdentifierType,
} from "../Services/ProfileConfigurationService";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import moment from "moment";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import AccessManagerService from "@/modules/AccessManager/Services/AccessManagerService";
import UserService, { User } from "@/modules/Users/Services/UserService";
import TheButton from "@/core/Components/TheButton.vue";
import { directive } from "vue-tippy";
import ModalComponent from "@/core/Components/Modal.vue";
import CreateForm from "@/modules/Users/Components/CreateForm.vue";
import CreateFormUsername from "@/modules/Users/Components/CreateFormEmail.vue";
import { v4 as uuidv4 } from "uuid";
import { useUserStore } from "@/core/Store/userStore";
import ApplicationService, {
  Application,
} from "@/modules/Applications/Services/ApplicationService";
import SpotiumService from "@/core/Services/SpotiumService";
import { useToast } from "vue-toastification";
import {
  CreateAssignment,
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: {
    CreateFormUsername,
    CreateForm,
    ModalComponent,
    TheButton,
    MainSection,
    FormWrapper,
    Loader,
    FormDivider,
    InputField,
    FormGroup,
    VSelect,
    AddDeleteButtons,
    CheckboxInput,
    DxDateBox,
    ButtonWrapper,
    SmallLoader,
  },
  props: {
    isFormInModal: Boolean,
    refreshData: Function,
  },
  directives: {
    tippy: directive,
  },
  data() {
    return {
      profileConfigurationService: new ProfileConfigurationService(),
      resourceService: new ResourceService(),
      spotiumService: new SpotiumService(),
      accessProfileService: new AccessProfileService(),
      applicationService: new ApplicationService(),
      identifierTypes: [{ id: 1, name: "RFID" }] as IdentifierType[],
      userService: new UserService(),
      resources: [] as DeviceAssignment[],
      resourcesForSelect: [] as any,
      accessProfiles: [] as AccessProfileDto[],
      accessManagerService: new AccessManagerService(),
      tdceDevices: [] as DeviceAssignment[],
      isCreateUserModalOpened: false,
      isEmployee: false,
      isLoading: false,
      isSubmitLoading: false,
      toast: useToast(),
      userStore: useUserStore(),
      applications: [] as Application[],
      users: [] as User[],
      createdUserId: 0, //Old property for old logic
      userId: 0,
      userEmail: "",
      isAllPermissionCheckBoxChecked: false,
      profileConfiguration: {
        assignmentRequest: {
          name: "",
          applicationId: 0,
          parentAssignmentId: 0,
        },
        deviceRequest: {
          deviceTypeId: Number(
            process.env.VUE_APP_ACCESS_MANAGER_PERMISSION_TYPE_ID
          ),
          externalId: "",
          data: {
            action: Number(process.env.VUE_APP_CREATE_ACTION),
            name: "",
            uuid: "",
            deviceId: 1,
            identifiers: [
              {
                type: 1,
                value: "",
                uuid: uuidv4(),
                action: Number(process.env.VUE_APP_CREATE_ACTION),
              },
            ],
            accesses: [
              {
                uuid: uuidv4(),
                active: true,
                resourceId: null,
                profiles: undefined,
                dateActiveFrom: moment()
                  .startOf("day")
                  .format("YYYY-MM-DDTHH:mm:ss[Z]"),
                dateActiveTo: null,
                action: Number(process.env.VUE_APP_CREATE_ACTION),
              },
            ],
          },
        },
      } as CreateAssignment,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.getAllResources(),
        this.getAllUsers(),
        this.getAllTdceDevices(),
      ]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    isAllPermissionCheckBoxChecked(value) {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      if (value) {
        this.profileConfiguration.deviceRequest.data.accesses = [];
        this.addAccess(true);
      }
    },
  },

  methods: {
    async handleSubmit() {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      this.isSubmitLoading = true;

      try {
        this.profileConfiguration.deviceRequest.data.name =
          this.profileConfiguration.assignmentRequest.name; /* 
        this.profileConfiguration.deviceRequest.externalId = String(
          this.profileConfiguration.deviceRequest.externalId
        ); */

        await this.createPoliciesAndResources();

        this.toast.success(this.$t("General.CreatedSuccessfully"));
        await this.refreshData?.("users");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },

    async getAllUsers() {
      try {
        const response = await this.userService.getAllUsers(1, 10000);
        this.users = response.items.filter((user) => user.email !== null);
        this.userId = this.users[0].id;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllTdceDevices() {
      try {
        this.tdceDevices = await this.accessManagerService.getAllTdceDevices();
        if (!this.tdceDevices.length) {
          this.toast.error("There are no TDCE devices!");
          return;
        }

        if (!this.profileConfiguration.deviceRequest) {
          this.toast.error("There is no device!");
          return;
        }

        this.profileConfiguration.deviceRequest.data.deviceId =
          this.tdceDevices[0].deviceId;
        this.profileConfiguration.assignmentRequest.parentAssignmentId =
          this.tdceDevices[0].assignmentId;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getCreatedUserId(userId: number) {
      this.createdUserId = userId;
    },

    async getAllResources() {
      try {
        this.resources = await this.accessManagerService.getCompanyResources();

        if (!this.resources.length) {
          return;
        }

        if (!this.profileConfiguration.deviceRequest) {
          this.toast.error("There is no device!");
          return;
        }

        this.resourcesForSelect = this.resources.map((item) => ({
          id: item.assignmentId,
          name: item.assignment?.name,
        }));

        //Is assignmentId actually resourceId ??
        this.profileConfiguration.deviceRequest.data.accesses[0].resourceId =
          this.resources[0].assignmentId;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    addAccess(isAddForAllResources?: boolean) {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      this.profileConfiguration.deviceRequest.data.accesses.push({
        dateActiveFrom: moment()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        dateActiveTo: null,
        onCompanyId: this.userStore.activeCompanyId,
        resourceId: isAddForAllResources
          ? null
          : this.resources[0].assignmentId ?? 0, //onResourceId
        roleId: null,
        policyId: null,
        resourceTypeId: 2,
        createdUserId: this.userStore.userId,
      });
    },
    async createPoliciesAndResources() {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }

      try {
        for (const access of this.profileConfiguration.deviceRequest.data
          .accesses) {
          const policy: AmrPolicy = {
            name: uuidv4(),
            companyId: this.userStore.activeCompanyId,
            createdUserId: this.userStore.userId,
            validFrom: access.dateActiveFrom,
            validTo:
              access.dateActiveTo === null
                ? null
                : moment(access.dateActiveTo).format("YYYY-MM-DDTHH:mm:ss[Z]"),
          };

          const createdPolicyId =
            await this.profileConfigurationService.createAmrPolicy(policy);

          try {
            const amrResource: AmrResource = {
              toCompanyId: null,
              toUserId: this.userId,
              onCompanyId: this.userStore.activeCompanyId,
              resourceTypeId: 2, //2 is type for device assignment
              onResourceId: access.resourceId ?? null,
              roleId: null,
              permissionIds: [50, 401],
              policyId: createdPolicyId,
              companyId: this.userStore.activeCompanyId,
            };
            await this.resourceService.createAmrResource(amrResource);
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    addIdentifier() {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      this.profileConfiguration.deviceRequest.data.identifiers.push({
        type: 1,
        value: "",
      });
    },
    removeAccess(index: number) {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      this.profileConfiguration.deviceRequest.data.accesses.splice(index, 1);
    },
    removeIdentifier(index: number) {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      this.profileConfiguration.deviceRequest.data.identifiers.splice(index, 1);
    },
    toggleUserModal() {
      this.isCreateUserModalOpened = !this.isCreateUserModalOpened;
    },
  },
});
</script>
<style scoped></style>
