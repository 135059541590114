import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_form = _resolveComponent("create-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_nodes_view = _resolveComponent("nodes-view")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_display_events = _resolveComponent("display-events")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_create_form, {
      "is-create-modal-opened": _ctx.isCreateModalOpened,
      "refresh-data": _ctx.getDisplays,
      onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCreateModalOpened = false))
    }, null, 8, ["is-create-modal-opened", "refresh-data"]),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 0,
          "display-prop": _ctx.selectedDisplay,
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "refresh-data": _ctx.getDisplays,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditModalOpened = false))
        }, null, 8, ["display-prop", "is-edit-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isNodeViewModalOpened)
      ? (_openBlock(), _createBlock(_component_nodes_view, {
          key: 1,
          "device-details": _ctx.deviceDetails,
          "is-nodes-view-opened": _ctx.isNodeViewModalOpened,
          onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isNodeViewModalOpened = false))
        }, null, 8, ["device-details", "is-nodes-view-opened"]))
      : _createCommentVNode("", true),
    (_ctx.isDeleteModalOpened)
      ? (_openBlock(), _createBlock(_component_delete_modal, {
          key: 2,
          "is-deleting": _ctx.isDeleteModalOpened,
          onCancelDelete: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
          onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
          onConfirmDelete: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openConfirmationModal('delete')))
        }, null, 8, ["is-deleting"]))
      : _createCommentVNode("", true),
    _createVNode(_component_confirmation_modal, {
      "delete-text": _ctx.confirmationModalText,
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      onConfirmAction: _ctx.confirmationModalAction,
      onCloseConfirmationModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
    }, null, 8, ["delete-text", "is-confirmation-modal-opened", "onConfirmAction"]),
    (_ctx.isDisplayEventsShown)
      ? (_openBlock(), _createBlock(_component_display_events, {
          key: 3,
          "is-display-events-opened": _ctx.isDisplayEventsShown,
          id: _ctx.selectedDisplay.externalAssignmentUuid,
          title: `${_ctx.selectedDisplay.name}`,
          onToggleModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isDisplayEventsShown = false))
        }, null, 8, ["is-display-events-opened", "id", "title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateAssignment,
                title: _ctx.$t('Displays.Title'),
                "button-text": "Create",
                onExecuteMethod: _ctx.toggleCreateModal
              }, null, 8, ["permission-id", "title", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                "nodes-column": true,
                options: _ctx.options,
                "render-summary": ['name'],
                "show-per-page": 16,
                "table-data": _ctx.displays,
                "table-headers": _ctx.tableHeaders,
                "table-height": "93%",
                isActionListNotDisplayed: 
          !_ctx.permissionStore.hasPermission(_ctx.permissions.EditAssignment) &&
          !_ctx.permissionStore.hasPermission(_ctx.permissions.DeleteAssignment)
        ,
                onSelectedRows: _ctx.getSelectedRows,
                onManageNodes: _ctx.manageNodes,
                onForceRefresh: _ctx.getDisplays,
                onViewDisplayEvents: _ctx.toggleDisplayEvents
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "isActionListNotDisplayed", "onSelectedRows", "onManageNodes", "onForceRefresh", "onViewDisplayEvents"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}