<template>
  <div class="flex h-full w-full items-center justify-center">
    <form-wrapper :is-form-in-modal="isFormInModal">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="profileConfiguration.assignmentRequest.name"
            label="Name/License plate/ID"
          />
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >User</label
            >
            <div class="flex w-full justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <div v-else class="flex w-full items-center space-x-4">
                <v-select
                  v-if="!isCreateNewTicketActive"
                  v-model="profileConfiguration.deviceRequest.externalId"
                  :options="usersForSelect"
                  :reduce="(type : any) => type.id"
                  class="w-full"
                  label="name"
                ></v-select>
                <input-field v-else v-model="username" :no-padding="true" />
                <the-button
                  v-tippy="'Create a new RFID TAG user'"
                  :icon="['fas', 'plus']"
                  button-type="primaryButton"
                  type="button"
                  @click="toggleCreateNewTicket"
                />
              </div>
            </div>
          </div>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Device</label
            >
            <div class="flex w-full justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="selectedTdceDevice"
                :get-option-label="
                  (deviceAssignment: any) => deviceAssignment.name
                "
                :options="mappedTdceDevices"
                class="w-full"
                :clearable=false
              ></v-select>
            </div>
          </div>
          <form-group title="Identifiers">
            <div
              v-for="(identifier, index) in profileConfiguration.deviceRequest
                .data.identifiers"
              class="grid grid-cols-2 gap-x-4"
            >
              <div class="relative items-center py-3">
                <label
                  class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                  >Type</label
                >
                <small-loader v-if="isLoading" height="h-8" width="w-8" />
                <v-select
                  v-else
                  v-model="identifier.type"
                  :options="identifierTypes"
                  :reduce="(type : any) => type.id"
                  class="mt-1 w-full"
                  label="name"
                  :clearable=false
                ></v-select>
              </div>
              <input-field v-model="identifier.value" label="Value" />
            </div>
          </form-group>
          <form-group title="Access">
            <div
              v-for="(access, index) in profileConfiguration.deviceRequest.data
                .accesses"
              :style="{ gridTemplateColumns: '0.5fr 5fr 1fr' }"
              class="grid grid-cols-3 gap-x-4"
            >
              <checkbox-input
                v-model="access.active"
                :is-checked="access.active"
              />
              <div class="grid grid-cols-2 gap-x-4">
                <div class="relative col-span-2 items-center py-3">
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >Resource</label
                  >
                  <small-loader v-if="isLoading" height="h-8" width="w-8" />
                  <v-select
                    v-else
                    v-model="access.resourceTriggerId"
                    :options="resourcesForSelect"
                    :reduce="(type : any) => type.uuid"
                    class="mt-1 w-full"
                    label="name"
                    :clearable=false
                  ></v-select>
                </div>
                <div>
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >From</label
                  >
                  <dx-date-box
                    v-model="access.dateActiveFrom"
                    :height="42"
                    :show-clear-button="false"
                    display-format="dd/MM/yyyy, HH:mm"
                    type="datetime"
                    :accept-custom-value="false"
                  />
                </div>
                <div>
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >To</label
                  >
                  <dx-date-box
                    v-model="access.dateActiveTo"
                    :height="42"
                    :show-clear-button="true"
                    display-format="dd/MM/yyyy, HH:mm"
                    type="datetime"
                    :accept-custom-value="false"
                  />
                </div>
              </div>
              <add-delete-buttons
                :accesses="profileConfiguration.deviceRequest.data.accesses"
                :index="index"
                @add-click="addAccess"
                @remove-click="removeAccess(index)"
              />
            </div>
          </form-group>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import Loader from "@/core/Components/Loader.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import FormGroup from "@/core/Components/FormElements/FormGroup.vue";
import DxDateBox from "devextreme-vue/date-box";
import VSelect from "vue-select";
import AddDeleteButtons from "@/core/Components/FormElements/AddDeleteButtons.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import AccessProfileService, {
  AccessProfileDto,
} from "../../AccessProfiles/Services/AccessProfileService";
import ProfileConfigurationService, {
  IdentifierType,
} from "../Services/ProfileConfigurationService";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import moment from "moment";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import AccessManagerService from "@/modules/AccessManager/Services/AccessManagerService";
import UserService, { User } from "@/modules/Users/Services/UserService";
import TheButton from "@/core/Components/TheButton.vue";
import { directive } from "vue-tippy";
import CreateForm from "@/modules/Users/Components/CreateForm.vue";
import CreateFormUsername from "@/modules/Users/Components/CreateFormEmail.vue";
import { v4 as uuidv4 } from "uuid";
import { useUserStore } from "@/core/Store/userStore";
import ApplicationService, {
  Application,
} from "@/modules/Applications/Services/ApplicationService";
import ResourceService from "@/modules/AccessManager/SubModules/Resources/Services/ResourceService";
import { useToast } from "vue-toastification";
import {
  CreateAssignment,
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import SpotiumService from "@/core/Services/SpotiumService";
import AssignmentService from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: {
    CreateFormUsername,
    CreateForm,
    TheButton,
    MainSection,
    FormWrapper,
    Loader,
    FormDivider,
    InputField,
    FormGroup,
    VSelect,
    AddDeleteButtons,
    CheckboxInput,
    DxDateBox,
    ButtonWrapper,
    SmallLoader,
  },
  props: {
    isFormInModal: Boolean,
    refreshData: Function,
  },
  directives: {
    tippy: directive,
  },
  data() {
    return {
      //New data I added
      selectedTdceDevice: {} as {
        deviceId: number | null;
        assignmentId: number | null;
        name?: string;
      },
      resourcesForSelect: [] as any,
      usersForSelect: [] as any,
      mappedTdceDevices: {} as any,
      //Old data
      profileConfigurationService: new ProfileConfigurationService(),
      resourceService: new ResourceService(),
      accessProfileService: new AccessProfileService(),
      applicationService: new ApplicationService(),
      spotiumService: new SpotiumService(),
      assignmentService: new AssignmentService(),
      identifierTypes: [{ id: 1, name: "RFID" }] as IdentifierType[],
      userService: new UserService(),
      resources: [] as DeviceAssignment[],
      accessProfiles: [] as AccessProfileDto[],
      accessManagerService: new AccessManagerService(),
      tdceDevices: [] as DeviceAssignment[],
      isCreateUserModalOpened: false,
      isLoading: false,
      isSubmitLoading: false,
      isCreateNewTicketActive: false,
      toast: useToast(),
      userStore: useUserStore(),
      applications: [] as Application[],
      users: [] as User[],
      username: "",
      profileConfiguration: {
        assignmentRequest: {
          name: "",
          applicationId: 0,
          parentAssignmentId: 0,
        },
        deviceRequest: {
          deviceTypeId: Number(
            process.env.VUE_APP_ACCESS_MANAGER_PERMISSION_TYPE_ID
          ),
          externalId: "",
          data: {
            action: Number(process.env.VUE_APP_CREATE_ACTION),
            name: "",
            uuid: "",
            deviceId: 1,
            identifiers: [
              {
                type: 1,
                value: "",
                uuid: uuidv4(),
                action: Number(process.env.VUE_APP_CREATE_ACTION),
              },
            ],
            accesses: [
              {
                uuid: uuidv4(),
                active: true,
                resourceId: 1,
                resourceTriggerId: "",
                profiles: undefined,
                dateActiveFrom: moment()
                  .startOf("day")
                  .format("YYYY-MM-DDTHH:mm:ss[Z]"),
                dateActiveTo: null,
                action: Number(process.env.VUE_APP_CREATE_ACTION),
              },
            ],
          },
        },
      } as CreateAssignment,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.getAllResources(),
        this.getAllUsers(),
        this.getAllApplications(),
        this.getAllTdceDevices(),
      ]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleSubmit() {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }

      this.isSubmitLoading = true;
      try {
        this.profileConfiguration.deviceRequest.data.name =
          this.profileConfiguration.assignmentRequest.name;
        this.profileConfiguration.deviceRequest.data.deviceId =
          this.selectedTdceDevice.deviceId;
        this.profileConfiguration.assignmentRequest.parentAssignmentId =
          this.selectedTdceDevice.assignmentId;
        this.profileConfiguration.deviceRequest.externalId = String(
          this.profileConfiguration.deviceRequest.externalId
        );

        if (this.isCreateNewTicketActive) {
          try {
            this.profileConfiguration.deviceRequest.externalId = String(
              await this.userService.createUser({
                username: this.username,
                companyId: this.userStore.activeCompanyId,
                languageCode: "en",
              })
            );
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }
        }

        await this.assignmentService.createDeviceAssignment(
          this.profileConfiguration
        );

        //this.profileConfiguration.deviceRequest.data.uuid = permission.uuid;

        /*  try {
          await this.profileConfigurationService.updatePermissionData(
            this.profileConfiguration
          );
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } */

        try {
          const parentTdceDevice = this.tdceDevices.filter(
            (tdceDevice) =>
              tdceDevice.assignmentId ===
              this.profileConfiguration.assignmentRequest.parentAssignmentId
          )[0];
          if (!parentTdceDevice.assignment) {
            this.toast.error(
              "Parent TDCE device is not assigned to any resource!"
            );
            return;
          }
          if (!parentTdceDevice.device) {
            this.toast.error(
              "Parent TDCE device is not assigned to any device!"
            );
            return;
          }
          await this.profileConfigurationService.addPermissionToStorageForSync(
            parentTdceDevice.device.deviceUuid,
            parentTdceDevice.assignment.dataUuid,
            this.profileConfiguration.deviceRequest.data
          );
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        }

        this.toast.success(this.$t("General.CreatedSuccessfully"));

        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },

    async getAllUsers() {
      try {
        const response = await this.userService.getAllUsers(1, 10000);
        if (!response.items.length) {
          return;
        }
        this.users = response.items;

        this.usersForSelect = this.users.map((user: any) => ({
          id: user.id,
          name: user.email != null ? user.email : user.username,
        }));
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllTdceDevices() {
      try {
        this.tdceDevices = await this.accessManagerService.getAllTdceDevices();

        if (!this.tdceDevices.length) {
          this.toast.error("There are no TDCE devices!");
          return;
        }

        if (!this.profileConfiguration.deviceRequest) {
          this.toast.error("There is no device!");
          return;
        }

        this.mappedTdceDevices = this.tdceDevices.map((device: any) => ({
          deviceId: device.deviceId,
          assignmentId: device.assignmentId,
          name: device.assignment?.name,
        }));

        this.selectedTdceDevice.name = this.tdceDevices[0].assignment?.name;
        this.selectedTdceDevice.deviceId = this.tdceDevices[0].deviceId;
        this.selectedTdceDevice.assignmentId = this.tdceDevices[0].assignmentId;

        this.profileConfiguration.deviceRequest.data.deviceId =
          this.tdceDevices[0].deviceId;
        this.profileConfiguration.assignmentRequest.parentAssignmentId =
          this.tdceDevices[0].assignmentId;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllApplications() {
      try {
        const response = await this.applicationService.getAllApplications(
          1,
          10000
        );
        this.applications = response.items;

        this.profileConfiguration.assignmentRequest.applicationId =
          this.applications[0].id;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllResources() {
      try {
        this.resources = await this.accessManagerService.getCompanyResources();

        this.resourcesForSelect = this.resources.map((item) => ({
          id: item.assignmentId,
          name: item.assignment?.name,
          uuid: item.device?.deviceUuid,
        }));

        if (this.profileConfiguration.deviceRequest) {
          this.profileConfiguration.deviceRequest.data.accesses[0].resourceTriggerId =
            this.resourcesForSelect[0]?.uuid;
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    addAccess() {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      this.profileConfiguration.deviceRequest.data.accesses.push({
        uuid: uuidv4(),
        active: true,
        resourceId: 1,
        resourceTriggerId: this.resourcesForSelect[0].uuid ?? "0",
        profiles: undefined,
        dateActiveFrom: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        dateActiveTo: null,
        action: Number(process.env.VUE_APP_CREATE_ACTION),
      });
    },
    addIdentifier() {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }

      this.profileConfiguration.deviceRequest.data.identifiers.push({
        type: 1,
        value: "",
      });
    },
    removeAccess(index: number) {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      this.profileConfiguration.deviceRequest.data.accesses.splice(index, 1);
    },
    removeIdentifier(index: number) {
      if (!this.profileConfiguration.deviceRequest) {
        this.toast.error("There is no device!");
        return;
      }
      this.profileConfiguration.deviceRequest.data.identifiers.splice(index, 1);
    },
    toggleCreateNewTicket() {
      this.isCreateNewTicketActive = !this.isCreateNewTicketActive;
    },
  },
});
</script>
<style scoped></style>
