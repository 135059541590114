<template>
  <main-section :is-map="true">
    <div :style="{ gridTemplateRows: 'min-content auto' }" class="grid h-full w-full grid-rows-2">
      <div class="grid grid-cols-4 gap-4 p-2">
        <parking-status-card :parking-spot-count="parkingSpotCount" />
      </div>
      <map-loader v-if="isLoading" />
      <grid :column-number="2" padding="p-4">
        <div id="map" class="h-full w-full"></div>
        <grid :row-number="2">
          <div class="max-h-[350px] border">
            <custom-table :force-refresh="true" :is-action-list-not-displayed="true" :remove-table-header="true"
              :show-per-page="8" :table-data="dashboardEventsData" :table-headers="tableHeaders" table-height="100%" />
          </div>
          <div class="border">
            <apexchart :key="chartSeries.length" ref="apexChart" :options="chartOptions" :series="chartSeries"
              height="320" />
          </div>
        </grid>
      </grid>
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Grid from "@/core/Components/Grid.vue";
import MapService from "@/modules/MapView/Services/MapService";
import ParkingStatusCard from "@/modules/Parkom/Components/ParkingStatusCard.vue";
import MarkerService from "@/modules/MapView/Services/MarkerService";
import DashboardService from "@/modules/Parkom/Services/DashboardService";
import {
  CompanyDefaultDevice,
  ParkingSpotConfig,
  ParkingSpotsStatus,
  WebsocketMessage,
} from "@/modules/Parkom/Services/DashboardTypes";
import { useToast } from "vue-toastification";
import {
  ErrorMessageFormatter,
  FormatDateTime,
} from "@/core/Services/GlobalFunctions";
import {
  ParkingSpot,
  ParkingSpotMap,
} from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import MapLoader from "@/modules/MapView/Components/MapLoader.vue";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";
import {
  Display,
  DisplayMap,
} from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import WebSocketService from "@/core/WebSocket/WebSocketService";
import L from "leaflet";
import CustomTable from "@/core/Components/CustomTable.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import { DxPager } from "devextreme-vue/data-grid";
import UserCustomisableGraphService from "@/modules/MapView/Services/UserCustomisableGraphService";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import { SelectMapIcon } from "@/modules/Parkom/Services/DashboardHelpers";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import { useParkingSpaceFilterStore } from "@/core/Store/useParkingSpaceFilterStore";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default defineComponent({
  components: {
    DxPager,
    TableActionHeader,
    CustomTable,
    MapLoader,
    ParkingStatusCard,
    Grid,
    MainSection,
  },
  //Injecting from main.ts, so we have one instance of a dashboard events store
  //If store is initialized in this component, it will be initialized for every render of a page
  //What is needed is that events stay after route change
  inject: {
    dashboardEvents: {
      from: "dashboardEvents",
    },
  },
  data() {
    return {
      //initialMounted: false,
      mapService: {} as MapService,
      markerService: {} as MarkerService,
      dashboardService: new DashboardService(),
      parkingSpotService: new ParkingSpotService(),
      displayService: new DisplayService(),
      graphService: new UserCustomisableGraphService(),
      parkingSpaceStatus: {} as ParkingSpotsStatus,
      parkingSpots: [] as ParkingSpotMap[],
      displaysMap: [] as DisplayMap[],
      chart: {} as ApexCharts,
      displays: [] as Display[],
      companyDefaultDevice: {} as CompanyDefaultDevice,
      dashboardEventsData: this.dashboardEvents as any,
      webSocketService: new WebSocketService(
        `${ConfigurationService.configData.WEB_SOCKET_PREFIX_PATH}/device`
      ),
      isLoading: false,
      toast: useToast(),
      changeCompanyStore: useChangeCompanyStore(),
      parkingSpaceFilterStore: useParkingSpaceFilterStore(),
      deviceUuids: [] as { deviceUuid: string }[],
      mappedParkingSpots: new Map<
        number,
        { parkingSpotName: string; parkingSpaceName: string }
      >(),
      parkingSpotMarkerMap: new Map<number, L.Marker>(),
      displayMarkerMap: new Map<
        number,
        { marker: L.Marker; name: string; isOffline: boolean }
      >(),
      canvasWidth: 0,
      displayWidth: 0,
      tableHeaders: {
        time: { header: "Dashboard.Time", isVisible: true },
        type: { header: "Dashboard.Type", isVisible: true },
        event: { header: "Dashboard.Event", isVisible: true },
      },

      parkingSpotCount: [
        {
          id: "total",
          name: "Dashboard.Total",
          count: 0,
          color: "text-[#5eaee6]",
          bgColor: "bg-[#e4f2ff]",
          icon: ["fas", "location-dot"],
          occupationByType: [] as {
            typeId: number;
            count: number;
          }[],
        },
        {
          id: "occupied",
          name: "Dashboard.Occupied",
          count: 0,
          color: "text-[#f68d82]",
          bgColor: "bg-[#ffefef]",
          icon: ["fas", "times"],
          occupationByType: [] as {
            typeId: number;
            count: number;
          }[],
        },
        {
          id: "unoccupied",
          name: "Dashboard.Unoccupied",
          count: 0,
          color: "text-[#7ad29a]",
          bgColor: "bg-[#f2faed]",
          icon: ["fas", "check"],
          occupationByType: [] as {
            typeId: number;
            count: number;
          }[],
        },
        {
          id: "totalOffline",
          name: "Dashboard.Offline",
          count: 0,
          color: "text-[#52525b]",
          bgColor: "bg-[#d4d4d8]",
          icon: ["fac", "wifi-slash"],
          occupationByType: [] as {
            typeId: number;
            count: number;
          }[],
        },
      ] as ParkingSpotConfig[],
      arrowMappings: {
        D: "↓",
        L: "←",
        R: "→",
        U: "↑",
        DL: "↙",
        DR: "↘",
        UL: "↖",
        UR: "↗",
      } as Record<string, string>,
      chartSeries: [] as ApexAxisChartSeries,
      chartOptions: {} as ApexOptions,
    };
  },

  async mounted() {  
    //this.initialMounted = true;
    await this.loadDisplayFont();

    this.isLoading = true;
    try {
      this.mapService = new MapService(
        document.getElementById("map") as HTMLElement
      );
      this.mapService.initMap();

      if (!(this.mapService instanceof MapService)) {
        return;
      }
      this.markerService = new MarkerService(this.mapService.map);

      await this.loadDashboardData();
    } catch (error) {
      console.log(error);

      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {            
      this.isLoading = true;
      try {
        this.webSocketService.closeWebSocketConnection();
        this.webSocketService = new WebSocketService(
          `${ConfigurationService.configData.WEB_SOCKET_PREFIX_PATH}/device`
        );
        this.mappedParkingSpots.clear();
        this.parkingSpotMarkerMap.clear();
        this.displayMarkerMap.clear();
        this.resetParkingSpotTypeStatus();
        this.markerService.removeGroup("PARKOM");

        this.dashboardEventsData = [];
        this.dashboardEvents = [];
        
        await this.parkingSpaceFilterStore.getParkingSpaceTreeList();
        await this.loadDashboardData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    async "parkingSpaceFilterStore.selectedParkingSpaceIds"(value: number[]) {
      console.log("selectedParkingSpaceIds");
      //if (this.initialMounted) { this.initialMounted = false; return; }
      this.isLoading = true;
      try {
        this.webSocketService.closeWebSocketConnection();
        this.webSocketService = new WebSocketService(
          `${ConfigurationService.configData.WEB_SOCKET_PREFIX_PATH}/device`
        );
        this.mappedParkingSpots.clear();
        this.parkingSpotMarkerMap.clear();
        this.displayMarkerMap.clear();
        this.markerService.removeGroup("PARKOM");
        this.resetParkingSpotTypeStatus();
        this.dashboardEventsData = [];
        this.dashboardEvents = [];

        await this.loadDashboardData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },

  async unmounted() {
    this.webSocketService.closeWebSocketConnection();
  },
  methods: {
    async loadDashboardData() {
      try {
        await Promise.all([
          this.getCompanyDefaultDevice(),
          this.getParkingSpaceStatus(),
        ]);

        this.webSocketService.subscribeToDevice(
          [{ deviceUuid: this.companyDefaultDevice.deviceUuid }],
          true,
          (data: any) => this.getDataFromWebSocket(data)
        );

        await this.getParkingSpotsForMap();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getParkingSpaceStatus() {
      try {
        this.parkingSpaceStatus =
          await this.dashboardService.getParkingSpotsStatus(
            this.parkingSpaceFilterStore.selectedParkingSpaceIds
          );

        this.createGraph();

        //Map values from API into parkingSpotCount array
        this.mapParkingSpotCardInformation();

        this.createDisplayMarkers();
      } catch (error) {
        console.log(error);
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async bindToolTipToMarker(e: L.LeafletEvent) {
      const id = e.target.options.id;

      e.target
        .setPopupContent(
          `<div class="w-full flex justify-center"><div class="w-4 h-4 small-loader ease-linear rounded-full border-2 border-t-2 border-b-primary border-gray-200"></div></div>`
        )
        .openPopup();
      try {
        const parkingSpotData =
          await this.parkingSpotService.getParkingSpotByIdentifier(id);
        e.target.setPopupContent(this.generatePopupHtml(parkingSpotData));
      } catch (error) {
        console.log(error);

        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    generatePopupHtml(parkingSpot: ParkingSpot) {
      return `
      <div class="space-y-1">
        <h2>Parking space: <strong>${parkingSpot.space}</strong></h2>
        <p>Parking spot: <strong>${parkingSpot.name}</strong></p>
        <p>Type: <strong>${parkingSpot.type}</strong> | <strong>${parkingSpot.occupied ? "Occupied" : "Unoccupied"
        }</strong></p>
        <p>MAC: <strong>${parkingSpot.macAddress}</strong></p>
        <p>${parkingSpot.occupied ? "Occupied since: " : "Unoccupied since: "
        }<strong>${FormatDateTime(parkingSpot.occupiedTimestamp)}</strong></p>
      </div>`;
    },

    async getParkingSpotsForMap() {
      const self = this;
      try {
        this.parkingSpots = await this.parkingSpotService.getParkingSpotsForMap(
          this.parkingSpaceFilterStore.selectedParkingSpaceIds
        );
        this.parkingSpots.forEach((parkingSpot) => {
          const marker = this.markerService.addMarker({
            lat: parkingSpot.latitude,
            lng: parkingSpot.longitude,
            groupKey: "PARKOM",
            icon: {
              iconUrl: `${process.env.VUE_APP_PUBLIC_PATH
                }map-icons/${SelectMapIcon({
                  occupied: parkingSpot.occupied,
                  offline: parkingSpot.offline,
                  typeId: parkingSpot.typeId,
                })}`,
              iconSize: [10, 10],
              iconAnchor: [5, 5],
            },
            markerOptions: { id: parkingSpot.id },
            bindPopup: true,
            returnMarker: true,
            popupOffset: { x: 0, y: 5 },
            eventHandlers: {
              click(e: L.LeafletEvent) {
                self.bindToolTipToMarker(e);
              },
            },
          }) as L.Marker;
          if (!parkingSpot.id) {
            return;
          }
          this.parkingSpotMarkerMap.set(parkingSpot.id, marker);
          this.mappedParkingSpots.set(parkingSpot.id, {
            parkingSpotName: parkingSpot.spotName,
            parkingSpaceName: parkingSpot.spaceName,
          });
        });

        this.markerService.fitGroupToMap("PARKOM");
      } catch (error) {
        console.log(error);

        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    getDataFromWebSocket(data: any) {
      const parsedData = JSON.parse(data.data);
      if (!parsedData.deviceUuid) {
        return;
      }

      if (parsedData.payload.type === "SpotOccupationStatus") {
        const parkingDetails = this.mappedParkingSpots.get(
          parsedData.payload.data.spotId
        );
        if(parkingDetails == undefined) return;
        const tempEvent = {
          time: moment().format("YYYY-MM-DD HH:mm:ss"),
          type: parsedData.payload.data.isOccupied ? "Occupied" : "Unoccupied",
          event: `[${parkingDetails?.parkingSpotName}] - ${parkingDetails?.parkingSpaceName}`,
        };

        this.dashboardEventsData.length >= 100 &&
          this.dashboardEventsData.shift();
        this.dashboardEventsData.unshift(tempEvent);

        const [total, occupied, unoccupied] = this.parkingSpotCount;
        this.updateParkingSpotStatusCardAndGraph({
          occupied,
          unoccupied,
          parsedData,
        });

        const marker = this.parkingSpotMarkerMap.get(
          parsedData.payload.data.spotId
        );

        if (!marker) {
          return;
        }

        marker.setIcon(
          L.icon({
            iconUrl: `${process.env.VUE_APP_PUBLIC_PATH
              }map-icons/${SelectMapIcon({
                occupied: parsedData.payload.data.isOccupied,
              })}`,
            iconSize: [10, 10],
            iconAnchor: [5, 5],
          })
        );
      } else if (parsedData.payload.type === "DisplayStatus") {
        const displayDetails: { displayId: number; value: string } =
          parsedData.payload.data;
        const displayMap = this.displayMarkerMap.get(displayDetails.displayId);

        if (!displayMap) {
          return;
        }

        displayMap.marker.setIcon(
          L.icon({
            iconUrl:
              this.createDisplayMarker({
                text: displayDetails.value,
                displayName: displayMap.name,
                isOffline: displayMap.isOffline,
              }) || "",
            iconSize: [this.displayWidth, 50],
            iconAnchor: [this.displayWidth / 2, 50],
          })
        );
      }
    },

    async updateParkingSpotStatusCardAndGraph({
      occupied,
      unoccupied,
      parsedData,
    }: {
      occupied: ParkingSpotConfig;
      unoccupied: ParkingSpotConfig;
      parsedData: WebsocketMessage;
    }) {
      if (parsedData.payload.data.isOccupied) {
        occupied.count++;
        unoccupied.count > 0 && unoccupied.count--;
        occupied.occupationByType.find(
          (item) => item.typeId === parsedData.payload.data.spotTypeId
        )!.count++;

        var founded = unoccupied.occupationByType.find(
          (item) => item.typeId === parsedData.payload.data.spotTypeId
        );
        founded!.count > 0 && founded!.count--;

        let typeCount = unoccupied.occupationByType.find(
          (item) => item.typeId === parsedData.payload.data.spotTypeId
        )!.count;
        typeCount > 0 && typeCount--;
        this.updateGraph("Enters");
      } else {
        occupied.count > 0 && occupied.count--;
        unoccupied.count++;
        let typeCount = occupied.occupationByType.find(
          (item) => item.typeId === parsedData.payload.data.spotTypeId
        )!.count;
        typeCount > 0 && typeCount--;

        var founded = occupied.occupationByType.find(
          (item) => item.typeId === parsedData.payload.data.spotTypeId
        );

        founded!.count > 0 && founded!.count--;

        unoccupied.occupationByType.find(
          (item) => item.typeId === parsedData.payload.data.spotTypeId
        )!.count++;
        this.updateGraph("Exits");
      }
    },

    async getDisplaysForMap() {
      try {
        this.displays = await this.displayService.getDisplays({
          pageSize: 100000,
          pageNumber: 1,
        });
      } catch (error) {
        console.log(error);
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getCompanyDefaultDevice() {
      try {
        this.companyDefaultDevice =
          await this.dashboardService.getCompanyDefaultDevice();
      } catch (error) {
        console.log(error);

        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    createDisplayMarkers() {
      this.displaysMap = this.parkingSpaceStatus.displaysMap;

      this.displaysMap.forEach((display) => {
        if (!display.latitude && !display.longitude) {
          throw new Error("Display has no coordinates!");
        }

        const marker = this.markerService.addMarker({
          lat: display.latitude,
          lng: display.longitude,
          groupKey: "PARKOM",
          bindPopup: true,
          popupOffset: { x: 0, y: -45 },
          eventHandlers: {
            click: (e) => {
              const marker = e.target;
              marker.setPopupContent(this.createDisplayPopupHtml(display));
            },
          },
          icon: {
            iconUrl:
              this.createDisplayMarker({
                text: display.lastValue,
                displayName: display.name,
                isOffline: display.offline,
              }) || "",
            iconSize: [display.lastValue ? this.displayWidth : 75, 50],
            iconAnchor: [this.displayWidth / 2, 50],
          },
          markerOptions: { id: display.name, title: display.name },
          returnMarker: true,
        });

        if (!marker) {
          throw new Error("Marker is undefined!");
        }

        if (!display?.id) {
          return;
        }

        this.displayMarkerMap.set(display.id, {
          marker: marker,
          name: display.name,
          isOffline: display.offline,
        });
      });
    },

    createDisplayPopupHtml(display: DisplayMap) {
      return `<div class="w-[150px]">
<div><strong>Name: </strong><span>${display.name}</span></div>
<div><strong>Status: </strong><span class=${display.offline ? "text-error font-bold" : "text-success font-bold"
        }>${display.offline ? "Offline" : "Online"}</span></div>
<div><strong>Last value: </strong><span>${display.lastValue}</span></div>
</div>`;
    },
    mapParkingSpotCardInformation() {
      Object.entries(this.parkingSpaceStatus).forEach(([key, value], idx) => {
        if (key === "occupationByType" && Array.isArray(value)) {
          value.forEach((item: any) => {
            this.parkingSpotCount
              .find((item) => item.id === "occupied")!
              .occupationByType.push({
                typeId: item.typeId,
                count: item.occupied ?? 0,
              });

            this.parkingSpotCount
              .find((item) => item.id === "unoccupied")!
              .occupationByType.push({
                typeId: item.typeId,
                count: item.unoccupied ?? 0,
              });

            this.parkingSpotCount
              .find((item) => item.id === "total")!
              .occupationByType.push({
                typeId: item.typeId,
                //@ts-ignore
                count: item?.unoccupied + item?.occupied ?? 0,
              });
          });
        }
        if (key === "occupationByTypeOffline" && Array.isArray(value)) {
          const offlineConfig = this.parkingSpotCount.find(
            (offlineItem) => offlineItem.id === "totalOffline"
          );
          value.forEach((item: any) => {
            offlineConfig!.occupationByType.push({
              typeId: item.typeId,
              //@ts-ignore
              count: item?.unoccupied + item?.occupied ?? 0,
            });
          });
        }

        if (Array.isArray(value)) {
          return;
        }
        this.parkingSpotCount.find((item) => item.id === key)!.count = value;
      });
    },

    createGraph() {
      const { series, options } = this.graphService.generateGraphSeriesOptions(
        {
          yPropertyConfig: [
            {
              id: "enters",
              visible: true,
              opposite: false,
              title: `${this.$t("Dashboard.Enters")} - ${this.$t(
                "Dashboard.Exits"
              )}`,
              min: 0,
            },
            {
              id: "exits",
              visible: false,
              opposite: false,
              title: `${this.$t("Dashboard.Enters")} - ${this.$t(
                "Dashboard.Exits"
              )}`,
              min: 0,
            },
            {
              id: "load",
              visible: true,
              opposite: true,
              title: this.$t("Dashboard.Load"),
              min: 0,
              max: 100,
            },
          ],
          xPropertyName: "hour",
          graphType: "line",          
          seriesNames: [
            this.$t("Dashboard.Enters"),
            this.$t("Dashboard.Exits"),
            this.$t("Dashboard.Load"),
          ],
          graphTitle: this.$t("Dashboard.OccupationByHour"),
          types: ["bar", "bar", "line"],
          colors: ["#d4515c", "#308e1f", "#00ced1"],
        },
        this.parkingSpaceStatus.occupationByHour
      );
      this.chartSeries = series;
      this.chartOptions = options;

      this.chart = this.$refs.apexChart as ApexCharts;
    },

    updateGraph(seriesName: string) {
      const hours = new Date().getHours();
      const series = this.chartSeries.find((s) => s.name === seriesName);
      if (!series) {
        return;
      }

      const data = series.data as { x: string; y: number }[];

      const dataToUpdate =
        data.at(-1)!.x ===
        moment().hour(hours).minute(0).format("YYYY-MM-DD HH:mm");

      if (dataToUpdate) {
        data.at(-1)!.y++;
      } else {
        data.push({
          x: moment().hour(hours).minute(0).format("YYYY-MM-DD HH:mm"),
          y: data.at(-1)!.y++,
        });
      }
      this.chart.updateSeries([series], false);
    },
    createDisplayMarker({
      text,
      displayName,
      isOffline,
    }: {
      text: string;
      displayName?: string;
      isOffline?: boolean;
    }) {
      const ratio = window.devicePixelRatio;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const fontSize = 24;

      if (!ctx) {
        return;
      }

      ctx.font = `${fontSize}px 'My Custom Font', sans-serif`;

      const splitText = text.split(" ").map((character) => {
        if (character in this.arrowMappings) {
          character = this.arrowMappings[character];
        }

        return character;
      });

      this.canvasWidth = splitText.reduce((acc, curr) => {
        return acc + ctx.measureText(curr).width;
      }, 0);

      const charWidths = splitText.map((char) => ctx.measureText(char).width);

      const width = Math.round(this.canvasWidth) + 80;
      const height = 60;

      canvas.width = width * ratio;
      this.displayWidth = width * ratio;
      canvas.height = height * ratio;
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";

      ctx.scale(ratio, ratio);

      if (!ctx) {
        return "";
      }

      ctx.fillStyle = "#000";
      ctx.fillRect(0, 0, width, height);

      ctx.fillStyle = "#333331";
      ctx.fillRect(5, 5, width - 10, height - 10);

      ctx.fillStyle = "lime";

      ctx.font = `${fontSize}px 'My Custom Font', sans-serif`;

      ctx.textBaseline = "middle";
      let spacing = Math.round(
        (ctx.canvas.width - this.canvasWidth) / splitText.length - 1
      );

      let x = 10;

      splitText.forEach((text, idx) => {
        const letter = this.loadConfig(text, ctx);

        ctx.fillText(letter, x, height / 2);

        x += charWidths[idx] + spacing;
        ctx.fillStyle = "lime";
      });

      return canvas.toDataURL();
    },

    loadConfig(textSymbol: string, ctx: CanvasRenderingContext2D) {
      if (textSymbol === "X") {
        ctx.fillStyle = "red";
        return "X";
      }

      return textSymbol;
    },

    async loadDisplayFont() {
      const customFont = new FontFace(
        "My Custom Font",
        `url(${process.env.VUE_APP_PUBLIC_PATH}digital-7.regular.ttf)`
      );

      const font = await customFont.load();

      document.fonts.add(font);
    },

    resetParkingSpotTypeStatus() {
      this.parkingSpotCount.forEach((item) => {
        item.occupationByType = [];
      });
    },
  },
});
</script>

<style>
.leaflet-popup-content p {
  margin: 0;
}
</style>
