import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7277902a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loader z-252 absolute flex w-full items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_loader = _resolveComponent("small-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_small_loader, {
      width: "w-[80px]",
      height: "h-[80px]",
      "outer-ring-thickness": _ctx.outerRingThickness,
      "inner-ring-thickness": _ctx.innerRingThickness
    }, null, 8, ["outer-ring-thickness", "inner-ring-thickness"])
  ]))
}