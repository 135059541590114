import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between px-6 py-2.5" }
const _hoisted_2 = { class: "text-lg font-medium leading-6 text-darker" }
const _hoisted_3 = { className: "border-t border-gray-200 " }
const _hoisted_4 = { className: "group divide-y divide-gray-100" }
const _hoisted_5 = { className: "bg-white px-4 py-2.5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-lightgray" }
const _hoisted_6 = {
  key: 0,
  className: "text-sm font-medium text-gray-500 "
}
const _hoisted_7 = {
  key: 1,
  class: "mt-1 text-sm text-gray-900"
}
const _hoisted_8 = {
  key: 2,
  className: "mt-1 text-sm text-gray-900 sm:mt-0"
}
const _hoisted_9 = {
  key: 3,
  className: "mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
}
const _hoisted_10 = {
  key: 4,
  class: "w-full text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`bg-white ${
      !_ctx.isDetailsViewInModal && 'shadow-md'
    }  overflow-hidden rounded-sm`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title) + " information ", 1),
      (_ctx.isDevice && !_ctx.noQrIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "cursor-pointer text-dark",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle-qr-modal')))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'qrcode'] })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("dl", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (key, value) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.tableHeaders)
                ? (_openBlock(), _createElementBlock("dt", _hoisted_6, _toDisplayString(_ctx.tableHeaders[value]["header"]
                  ? _ctx.tableHeaders[value]["header"]
                  : _ctx.tableHeaders[value]), 1))
                : _createCommentVNode("", true),
              (
                !value.includes('isActive') && _ctx.generateValue(_ctx.tableData, value)
              )
                ? (_openBlock(), _createElementBlock("dd", _hoisted_7, _toDisplayString(_ctx.generateValue(_ctx.tableData, value)), 1))
                : _createCommentVNode("", true),
              (!_ctx.generateValue(_ctx.tableData, value))
                ? (_openBlock(), _createElementBlock("dd", _hoisted_8, " - "))
                : _createCommentVNode("", true),
              (value.includes('isActive'))
                ? (_openBlock(), _createElementBlock("dd", _hoisted_9, [
                    _createElementVNode("div", {
                      class: _normalizeClass(`h-4 w-4 rounded-full ${
                  _ctx.generateValue(_ctx.tableData, value) ? 'bg-success' : 'bg-error'
                }`)
                    }, null, 2)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.tableHeaders[value]['copy'])
                ? (_openBlock(), _createElementBlock("dd", _hoisted_10, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: ['fas', 'copy'],
                      style: {"color":"#007cc1"},
                      class: "cursor-pointer text-lg",
                      onClick: ($event: any) => (_ctx.copyToClipBoard(_ctx.generateValue(_ctx.tableData, value)))
                    }, null, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 256))
      ])
    ])
  ], 2))
}