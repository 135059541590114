import { RouteRecordRaw } from "vue-router";
import Index from "../Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createResponseMessagesRoute(): RouteRecordRaw {
  return {
    name: "responseMessages",
    path: "/response-messages",
    component: Index,
    meta: {
      id: "responseMessagesIndex",
      label: "Sidebar.ResponseMessages",
      icon: ["fas", "message"],
      showInNavigation: true,
      group: "main",
      authorize: [Permissions.ViewResponseMessage],
    },
  };
}
