<template>
  <modal-component
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    title="Edit source"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form @submit.prevent="handleSubmit">
      <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
        <form-divider>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Parking space</label
            >
            <div class="flex w-full justify-center">
              <v-select
                v-model="source.spaceId"
                :options="parkingSpaces"
                :reduce="(value : any) => value.id"
                class="w-full"
                label="name"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Parking spot</label
            >
            <div class="flex w-full justify-center">
              <v-select
                v-model="source.spotId"
                :options="parkingSpotsFiltered"
                :reduce="(value : any) => value.id"
                class="w-full"
                label="name"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Parking spot type</label
            >
            <div class="flex w-full justify-center">
              <v-select
                v-model="source.spotTypeId"
                :options="parkingSpotTypes"
                :reduce="(value : any) => value.id"
                class="w-full"
                label="name"
              ></v-select>
            </div>
          </div>
          <checkbox-input
            v-model="source.showFree"
            :is-checked="source.showFree"
            label="Show free"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form-wrapper>
    </form>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import SelectInput from "@/core/Components/FormElements/SelectInput.vue";
import {
  EditSource,
  Source,
} from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";
import {
  ParkingSpot,
  ParkingSpotType,
} from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import VSelect from "vue-select";

export default defineComponent({
  components: {
    FormWrapper,
    ModalComponent,
    FormDivider,
    SelectInput,
    CheckboxInput,
    ButtonWrapper,
    VSelect,
  },
  props: {
    sourceProp: {
      type: Object as PropType<Source>,
      required: true,
    },
    isEditModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      parkingSpotService: new ParkingSpotService(),
      parkingSpaceService: new ParkingSpaceService(),
      displayService: new DisplayService(),
      parkingSpotTypes: [] as ParkingSpotType[],
      parkingSpots: [] as ParkingSpot[],
      parkingSpotsFiltered: [] as ParkingSpot[],
      parkingSpaces: [] as ParkingSpace[],
      toast: useToast(),
      source: {} as EditSource,
      isLoading: false,
      isSubmitLoading: false,
    };
  },
  async created() {
    const { displayNode, space, spot, spotType, ...rest } = this.sourceProp;
    this.source = rest;

    this.isLoading = true;
    try {
      await Promise.allSettled([
        this.getParkingSpotTypes(),
        this.getParkingSpots(),
        this.getParkingSpaces(),
      ]);
      this.parkingSpotsFiltered = this.parkingSpots.filter(
        (spot: ParkingSpot) => spot.spaceId === this.source.spaceId
      );
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    "source.spaceId"(spaceId: number) {
      this.parkingSpotsFiltered = this.parkingSpots.filter(
        (spot: ParkingSpot) => spot.spaceId === spaceId
      );
    },
  },
  methods: {
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        await this.displayService.editSource(this.source);
        this.$emit("toggle-modal");
        this.toast.success("Source edited successfully!");
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getParkingSpotTypes() {
      try {
        this.parkingSpotTypes =
          await this.parkingSpotService.getParkingSpotTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getParkingSpots() {
      try {
        this.parkingSpots = await this.parkingSpotService.getParkingSpots({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getParkingSpaces() {
      try {
        this.parkingSpaces = await this.parkingSpaceService.getParkingSpaces({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
