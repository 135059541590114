<template>
  <create-form
    v-if="isCreateFormModalOpened"
    :is-create-form-modal-opened="isCreateFormModalOpened"
    :refresh-data="getResources"
    @toggle-modal="isCreateFormModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getResources"
    :resource-id="selectedResource.id"
    @toggle-modal="isEditModalOpened = false"
  />
  <delete-modal
    v-if="isDeleteModalOpened"
    :is-deleting="isDeleteModalOpened"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :delete-text="confirmationModalText"
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    @confirm-action="confirmationModalAction"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />

  <main-section>
    <loader v-if="isLoading" />
    <div v-else>
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.AuthCreate"
        :title="$t('Authorization.Title')"
        description=" "
        button-text="Create"
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="resources"
        :table-headers="tableHeaders"
        @force-refresh="getResources()"
        @selected-rows="getSelectedRows"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Loader from "@/core/Components/Loader.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import AuthorizationService from "@/modules/AdminDashboard/Submodules/Authorization/Services/AuthorizationService";
import { ResourceDto } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/AuthorizationTypes";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import CreateForm from "../Components/CreateForm.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import EditForm from "../Components/EditForm.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    ConfirmationModal,
    DeleteModal,
    CreateForm,
    CustomTable,
    TableActionHeader,
    Loader,
    MainSection,
    EditForm,
  },
  data() {
    return {
      authorizationService: new AuthorizationService(),
      resources: [] as ResourceDto[],
      selectedResource: {} as ResourceDto,
      toast: useToast(),
      permissions: Permissions,
      changeCompanyStore: useChangeCompanyStore(),
      isLoading: false,
      isCreateFormModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      confirmationModalText: "",
      isDataSourceLoading: false,
      isEditModalOpened: false,
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "edit"],
          permissionId: Permissions.AuthEdit,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.AuthDelete,
          action: this.toggleDeleteModal,
        },
      },
      tableHeaders: {
        userEmail: {
          header: "Authorization.ToUser",
          visible: true,
          groupIndex: 0,
        },
        roleName: { header: "Authorization.Role", visible: true },
        resourceTypeName: {
          header: "Authorization.ResourceType",
          visible: true,
        },
        resourceName: { header: "Authorization.Resource", visible: true },
        createdUserEmail: {
          header: "Authorization.CreatedResource",
          visible: true,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getResources();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getResources();
    },
  },

  methods: {
    async getResources() {
      this.isDataSourceLoading = true;
      try {
        this.resources = await this.authorizationService.getCompanyResources();
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteResource();
          break;
      }
      this.isConfirmationModalOpened = false;
    },

    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },

    async deleteResource() {
      try {
        await this.authorizationService.deleteResource(
          this.selectedResource.id
        );
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isConfirmationModalOpened = false;
        this.isDeleteModalOpened = false;
        await this.getResources();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getSelectedRows(values: ResourceDto[]) {
      if (values.length) {
        this.selectedResource = values[0];
      }
    },
    toggleCreateModal() {
      this.isCreateFormModalOpened = !this.isCreateFormModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
  },
});
</script>

<style scoped></style>
