import { IntegrationTypesTemplate } from "@/core/Services/Enums";

export const IntegrationTypesTemplates = {
  1: IntegrationTypesTemplate.HTTP,
  2: IntegrationTypesTemplate.WS,
  3: IntegrationTypesTemplate.HTTP,
  4: IntegrationTypesTemplate.UDP,
  5: IntegrationTypesTemplate.WS,
  6: IntegrationTypesTemplate.MQTT,
  7: IntegrationTypesTemplate.Invocation,
  997: IntegrationTypesTemplate.Translation,
  998: IntegrationTypesTemplate.RuleEngine,
};

export const IntegrationTypesEditTemplate = {
  1: IntegrationTypesTemplate.HTTPEdit,
  2: IntegrationTypesTemplate.WSEdit,
  3: IntegrationTypesTemplate.HTTPEdit,
  4: IntegrationTypesTemplate.UDPEdit,
  5: IntegrationTypesTemplate.WSEdit,
  6: IntegrationTypesTemplate.MQTTEdit,
  7: IntegrationTypesTemplate.InvocationEdit,
  997: IntegrationTypesTemplate.TranslationEdit,
  998: IntegrationTypesTemplate.RuleEngineEdit,
};
