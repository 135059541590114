import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-4 flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center space-x-2" }
const _hoisted_3 = {
  key: 1,
  class: "space-y-4"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_modal = _resolveComponent("modal")!
  const _component_create_ticket_form = _resolveComponent("create-ticket-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_edit_auth_form = _resolveComponent("edit-auth-form")!
  const _component_tabs_component = _resolveComponent("tabs-component")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_loader = _resolveComponent("loader")!
  const _component_empty_state = _resolveComponent("empty-state")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_delete_modal, {
      "is-deleting": _ctx.isDeleting,
      "selected-data": _ctx.selectedProfileConfiguration,
      "delete-text": "Are you sure you want to delete ",
      onCancelDelete: _ctx.toggleDeleteModal,
      onToggleModal: _ctx.toggleDeleteModal,
      onConfirmDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openConfirmationModal('delete')))
    }, null, 8, ["is-deleting", "selected-data", "onCancelDelete", "onToggleModal"]),
    _createVNode(_component_delete_modal, {
      "is-deleting": _ctx.isDeleteAuthModalOpened,
      "selected-data": _ctx.selectedProfileConfiguration,
      "delete-text": "Are you sure you want to delete this authorization resource?",
      onCancelDelete: _ctx.toggleDeleteAuthModal,
      onToggleModal: _ctx.toggleDeleteAuthModal,
      onConfirmDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openAuthConfirmationModal('delete')))
    }, null, 8, ["is-deleting", "selected-data", "onCancelDelete", "onToggleModal"]),
    _createVNode(_component_confirmation_modal, {
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "delete-text": "delete",
      onCloseConfirmationModal: _ctx.toggleConfirmationModal,
      onToggleModal: _ctx.toggleConfirmationModal,
      onConfirmAction: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmationModalAction()))
    }, null, 8, ["is-confirmation-modal-opened", "onCloseConfirmationModal", "onToggleModal"]),
    _createVNode(_component_confirmation_modal, {
      "is-confirmation-modal-opened": _ctx.isAuthConfirmationModalOpened,
      "delete-text": "delete",
      onCloseConfirmationModal: _ctx.toggleAuthConfirmationModal,
      onToggleModal: _ctx.toggleAuthConfirmationModal,
      onConfirmAction: _ctx.authConfirmationModalAction
    }, null, 8, ["is-confirmation-modal-opened", "onCloseConfirmationModal", "onToggleModal", "onConfirmAction"]),
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isCreateUserModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      "no-padding": true,
      "remove-outside-click": true,
      title: "Create user permission",
      onToggleModal: _ctx.toggleCreateUserModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_create_form, {
          "is-form-in-modal": true,
          "refresh-data": _ctx.changeTab
        }, null, 8, ["refresh-data"])
      ]),
      _: 1
    }, 8, ["is-displayed", "onToggleModal"]),
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isCreateTicketModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      "no-padding": true,
      "remove-outside-click": true,
      title: "Create ticket permission",
      onToggleModal: _ctx.toggleCreateTicketModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_create_ticket_form, {
          "is-form-in-modal": true,
          "refresh-data": _ctx.getAllProfileConfigurations
        }, null, 8, ["refresh-data"])
      ]),
      _: 1
    }, 8, ["is-displayed", "onToggleModal"]),
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isEditModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      "no-padding": true,
      "remove-outside-click": true,
      title: "Edit profile configuration",
      onToggleModal: _ctx.toggleEditModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_edit_form, {
          "is-form-in-modal": true,
          "object-id": _ctx.selectedProfileConfiguration.id,
          "assignment-id": _ctx.selectedProfileConfiguration.assignmentId,
          "refresh-data": _ctx.getAllProfileConfigurations
        }, null, 8, ["object-id", "assignment-id", "refresh-data"])
      ]),
      _: 1
    }, 8, ["is-displayed", "onToggleModal"]),
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isEditAuthModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      "no-padding": true,
      "remove-outside-click": true,
      title: "Edit auth resource and policy",
      onToggleModal: _ctx.toggleEditAuthModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_edit_auth_form, {
          "auth-resource-prop": _ctx.selectedAppUserData,
          "is-form-in-modal": true,
          "refresh-data": _ctx.changeTab
        }, null, 8, ["auth-resource-prop", "refresh-data"])
      ]),
      _: 1
    }, 8, ["is-displayed", "onToggleModal"]),
    _createVNode(_component_main_section, { title: "Access manager/Profile configurations" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_tabs_component, {
            tabs: _ctx.tabs,
            onChangeTab: _ctx.changeTab
          }, null, 8, ["tabs", "onChangeTab"]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.selectedTabId === 'tickets')
              ? _withDirectives((_openBlock(), _createBlock(_component_the_button, {
                  key: 0,
                  icon: ['fas', 'plus'],
                  buttonType: "primaryButton",
                  text: "Ticket",
                  onExecuteMethod: _ctx.createTicketProfileConfiguration
                }, null, 8, ["onExecuteMethod"])), [
                  [_directive_tippy, 'Create profile configuration for user without email']
                ])
              : _withDirectives((_openBlock(), _createBlock(_component_the_button, {
                  key: 1,
                  icon: ['fas', 'plus'],
                  buttonType: "primaryButton",
                  text: "User",
                  onExecuteMethod: _ctx.createUserProfileConfiguration
                }, null, 8, ["onExecuteMethod"])), [
                  [_directive_tippy, 'Create profile configuration for user with email']
                ])
          ])
        ]),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, {
              key: 0,
              "inner-ring-thickness": "border-t-8",
              "outer-ring-thickness": "border-8"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (
          !_ctx.isLoading && !_ctx.profileConfigurations?.length && !_ctx.authResources?.length
        )
                ? (_openBlock(), _createBlock(_component_empty_state, {
                    key: 0,
                    "page-name": "profile configurations"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_the_button, {
                        icon: ['fas', 'plus'],
                        buttonType: "primaryButton",
                        text: "Add new profile configuration",
                        onExecuteMethod: _ctx.createUserProfileConfiguration
                      }, null, 8, ["onExecuteMethod"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_ctx.selectedTabId === 'users')
                      ? (_openBlock(), _createBlock(_component_custom_table, {
                          key: 0,
                          "is-data-source-loading": _ctx.isDataSourceLoading,
                          options: _ctx.optionsUser,
                          "table-data": _ctx.authResources,
                          "table-headers": _ctx.tableHeadersUsers,
                          onSelectedRows: _ctx.getSelectedRows,
                          onForceRefresh: _ctx.getAuthResources
                        }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onForceRefresh"]))
                      : (_openBlock(), _createBlock(_component_custom_table, {
                          key: 1,
                          "is-data-source-loading": _ctx.isDataSourceLoading,
                          options: _ctx.options,
                          "table-data": _ctx.profileConfigurations,
                          "table-headers": _ctx.tableHeaders,
                          onSelectedRows: _ctx.getSelectedRows,
                          onForceRefresh: _ctx.getAllProfileConfigurations
                        }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onForceRefresh"]))
                  ]))
            ]))
      ]),
      _: 1
    })
  ], 64))
}