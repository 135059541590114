import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_wrapper, { "is-form-in-modal": _ctx.isFormInModal }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
        }, [
          _createVNode(_component_form_divider, null, {
            default: _withCtx(() => [
              _createVNode(_component_input_field, {
                modelValue: _ctx.formData.email,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.email) = $event)),
                errorMessage: _ctx.v$?.formData['email']?.$errors[0]?.$message,
                isError: _ctx.v$?.formData['email']?.$error,
                required: true,
                label: "Email",
                placeholder: "Enter email..."
              }, null, 8, ["modelValue", "errorMessage", "isError"])
            ]),
            _: 1
          }),
          _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
        ], 32)
      ]),
      _: 1
    }, 8, ["is-form-in-modal"])
  ]))
}