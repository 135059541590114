import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-6 flex items-center space-x-4" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "flex h-12 w-12 items-center justify-center border",
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-click')))
    }, [
      _createVNode(_component_font_awesome_icon, {
        icon: ['fas', 'plus'],
        class: "h-5 w-5"
      })
    ]),
    _createElementVNode("button", {
      disabled: _ctx.disableButton(),
      class: "delete-button flex h-12 w-12 items-center justify-center border",
      type: "button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('remove-click')))
    }, [
      _createVNode(_component_font_awesome_icon, {
        class: _normalizeClass(` h-5 w-5`),
        icon: ['fas', 'trash']
      })
    ], 8, _hoisted_2)
  ]))
}