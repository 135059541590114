<template>
  <div v-click-away="isUserDropdownOpened && openUserDropdown" class="relative">
    <svg
      :class="`cursor-pointer fill-darkgrey hover:fill-primary ${
        isUserDropdownOpened && 'fill-primary'
      }`"
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      @click="openUserDropdown"
    >
      <path
        d="M12 12q-1.65 0-2.825-1.175Q8 9.65 8 8q0-1.65 1.175-2.825Q10.35 4 12 4q1.65 0 2.825 1.175Q16 6.35 16 8q0 1.65-1.175 2.825Q13.65 12 12 12Zm-8 8v-2.8q0-.85.438-1.563.437-.712 1.162-1.087 1.55-.775 3.15-1.163Q10.35 13 12 13t3.25.387q1.6.388 3.15 1.163.725.375 1.162 1.087Q20 16.35 20 17.2V20Z"
      />
    </svg>
    <dropdown-wrapper :is-dropdown-opened="isUserDropdownOpened">
      <!--        <div class="flex items-center justify-between rounded-md bg-lightgray px-3 py-2 cursor-pointer hover:bg-lightgray" @click="logout">-->
      <!--          <h2 class="text-dark font-bold">Logout</h2>-->
      <!--          <font-awesome-icon class="text-error" :icon="['fas', 'sign-out-alt']" />-->
      <!--        </div>-->
      <!--        <div @click="goToKeycloakProfile" class="flex items-center justify-between px-3 cursor-pointer hover:bg-lightgray rounded-md py-0.5">-->
      <!--          <p class="text-dark">User profile</p>-->
      <!--          <font-awesome-icon class="text-dark" :icon="['fas', 'gear']" />-->
      <!--        </div>-->
      <div class="px-4 py-3">
        <div class="mb-4 flex items-center space-x-3">
          <p v-if="!isLocal" class="text-dark">{{ user.email }}</p>
        </div>
        <div class="flex flex-col space-y-2">
          <the-button
            v-if="!isLocal"
            :full-width="true"
            :icon="['fas', 'user-edit']"
            :text="$t('UserDropdown.EditProfile')"
            size="sm"
            variant="outline"
            @execute-method="goToKeycloakProfile"
          />
          <the-button
            v-if="!isLocal"
            :full-width="true"
            :icon="['fas', 'sign-out-alt']"
            :is-loading="isLoggingOut"
            :text="$t('UserDropdown.Logout')"
            size="sm"
            @execute-method="logout"
          />
          <div
            class="flex cursor-pointer items-center justify-between bg-slate-50 py-1 pl-2"
          >
            <p class="text-dark">Development</p>
            <vue-toggle
              :active-color="'#007cc1'"
              :toggled="moduleToggleStore.isDevelopment"
              name="ParkingManager"
              @toggle="moduleToggleStore.toggleDevelopment()"
            />
          </div>
        </div>
      </div>
    </dropdown-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
//@ts-ignore
import VueToggle from "vue-toggle-component";
import { useModuleToggleStore } from "@/core/Store/moduleToggleStore";
import { FormatDate } from "@/core/Services/GlobalFunctions";
import { useThemeStore } from "@/core/Store/themeStore";
import { ThemeEnum } from "@/core/Services/Enums";
import DropdownWrapper from "@/core/Components/Dropdowns/DropdownWrapper.vue";
import TheButton from "@/core/Components/TheButton.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default defineComponent({
  components: { FontAwesomeIcon, TheButton, DropdownWrapper, VueToggle },
  data() {
    return {
      config: inject("config") as any,
      user: inject("user") as any,
      keycloakService: inject("keycloakService") as any,
      isUserDropdownOpened: false as boolean,
      moduleToggleStore: useModuleToggleStore(),
      themeStore: useThemeStore(),
      isThemeDropdownOpened: false,
      isLoggingOut: false,
      isLocal: ConfigurationService.configData.LOCAL_RUN === "true",
      themes: [
        { value: ThemeEnum.default, label: "Spotium", icon: "spotium" },
        { value: ThemeEnum.sick, label: "Sick", icon: "sick" },
        { value: ThemeEnum.ht, label: "HT", icon: "ht" },
      ],
    };
  },

  methods: {
    formatDate: FormatDate,
    openUserDropdown() {
      this.isUserDropdownOpened = !this.isUserDropdownOpened;
    },

    getPublicPath() {
      return process.env.VUE_APP_PUBLIC_PATH;
    },

    onOutsideClick() {
      this.isUserDropdownOpened = false;
    },

    async logout() {
      this.isLoggingOut = true;
      try {
        await this.keycloakService.doLogout();
        localStorage.removeItem("userId");
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoggingOut = false;
      }
    },
    goToKeycloakProfile() {
      window.open(this.config.KEYCLOAK_PROFILE);
    },
  },
});
</script>
<style scoped></style>
