import {
  CompanyDefaultDevice,
  ParkingSpaceTree,
  ParkingSpotsStatus,
  ParkingSpotsStatusByHour,
} from "@/modules/Parkom/Services/DashboardTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import moment from "moment";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class DashboardService {
  async getParkingSpotsStatus(
    parkingSpaceIds: number[]
  ): Promise<ParkingSpotsStatus> {
    const url = GenerateUrl({
      path: "/api/dashboard",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        parkingSpaceIds: parkingSpaceIds,
      },
    });

    const response = await ApiService.get(url, { data: [] });
    return this._mapParkingSpotsStatus(response.data);
  }

  async getParkingSpaceTreeList(): Promise<ParkingSpaceTree[]> {
    const url = GenerateUrl({
      path: "/api/dashboard/treeview",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.get(url);
    return this._mapParkingSpaceTreeList(response.data);
  }

  async getCompanyDefaultDevice() {
    const url = GenerateUrl({
      path: "/api/company-default-devices",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.get(url);

    return this._mapCompanyDefaultDevice(response.data);
  }

  private _mapParkingSpotsStatus(
    response: ParkingSpotsStatus
  ): ParkingSpotsStatus {
    return {
      total: response.total,
      unoccupied: response.unoccupied,
      occupied: response.occupied,
      totalOffline: response.totalOffline,
      occupationByType: response.occupationByType,
      occupationByTypeOffline: response.occupationByTypeOffline,
      occupationByHour: this._mapOcuppationByHour(response.occupationByHour),
      displaysMap: response.displaysMap,
    };
  }

  private _mapOcuppationByHour(response: ParkingSpotsStatusByHour[]) {
    return response.map((item) => {
      return {
        hour:
          typeof item.hour === "number"
            ? moment().hour(item.hour).minute(0).format("YYYY-MM-DD HH:mm")
            : "",
        enters: item.enters,
        exits: item.exits,
        load: item.load,
      };
    });
  }

  private _mapCompanyDefaultDevice(response: CompanyDefaultDevice) {
    return {
      id: response.id,
      deviceId: response.deviceId,
      deviceUuid: response.deviceUuid,
      companyId: response.companyId,
    };
  }

  private _mapParkingSpaceTreeList(
    response: ParkingSpaceTree[]
  ): ParkingSpaceTree[] {
    return response.map((item) => this._mapParkingSpaceTreeItem(item));
  }

  private _mapParkingSpaceTreeItem(
    response: ParkingSpaceTree
  ): ParkingSpaceTree {
    return {
      id: response.id,
      categoryId: response.categoryId,
      label: response.label,
      expanded: response.expanded,
      selected: response.selected,
      spaceId: response.spaceId,
    };
  }
}
