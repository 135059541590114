import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_multiselect, {
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
      multiple: true,
      options: _ctx.tags,
      taggable: true,
      "tag-placeholder": "Add this as new tag",
      onTag: _ctx.addTag
    }, null, 8, ["modelValue", "options", "onTag"])
  ]))
}