<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    :title="$t('AssignmentMetadata.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="formData.name"
            :label="$t('DeviceTypeMetadata.Name')"
            :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
            :isError="v$?.formData['name']?.$error"
            :required="true"
          />
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceTypeMetadata.MetadataTypes")
              }}<span class="pl-0.5 text-orange-600">*</span></label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="formData.metadataTypeId"
                :class="`mt-1 w-full ${
                  v$?.formData['metadataTypeId']?.$error && 'error'
                }`"
                :options="assignmentMetadataTypes"
                :reduce="(metadataType : any) => metadataType.id"
                label="name"
              ></v-select>
            </div>
            <span
              v-if="v$?.formData['metadataTypeId']?.$error"
              class="mt-2 text-sm text-error"
              >{{ v$?.formData["metadataTypeId"]?.$errors[0]?.$message }}
            </span>
          </div>
          <div class="py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceTypeMetadata.Data")
              }}<span class="pl-0.5 text-orange-600">*</span></label
            >
            <json-editor-vue
              v-model="formData.data"
              :main-menu-bar="false"
              :status-bar="false"
              :class="`mt-1 ${v$?.formData['data']?.$error && 'error'}`"
              mode="text"
            />
            <span
              v-if="v$?.formData['data']?.$error"
              class="mt-2 text-sm text-error"
              >{{ v$?.formData["data"]?.$errors[0]?.$message }}
            </span>
          </div>
          <JsonSchemaDropdown :config-schema="configSchema" />
          <div class="mt-2 flex items-center gap-2 border-none">
            <the-button
              :text="'Validate'"
              @click="validateData"
              :type="'button'"
            />
            <div class="text-sm font-medium not-italic text-gray-800">
              {{ validationMessage }}
            </div>
          </div>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import VSelect from "vue-select";
//@ts-ignore
import JsonEditorVue from "json-editor-vue";
import {
  AssignmentMetadataType,
  CreateAssignmentMetadata,
} from "@/modules/Devices/Submodules/AssignmentMetadata/Services/AssignmentMetadataTypes";
import AssignmentMetadataService from "@/modules/Devices/Submodules/AssignmentMetadata/Services/AssignmentMetadataService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import Ajv from "ajv";
import JsonSchemaDropdown from "@/core/Components/Dropdowns/JsonSchemaDropdown.vue";
import TheButton from "@/core/Components/TheButton.vue";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    ButtonWrapper,
    JsonEditorVue,
    VSelect,
    SmallLoader,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
    JsonSchemaDropdown,
    TheButton,
  },
  watch: {
    "formData.metadataTypeId": "getConfigSchema",
  },
  props: {
    isCreateModalOpened: Boolean,
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      assignmentMetadataService: new AssignmentMetadataService(),
      assignmentMetadataTypes: [] as AssignmentMetadataType[],
      toast: useToast(),
      isLoading: false,
      isSubmitLoading: false,
      formData: {
        name: "",
        metadataTypeId: null,
        data: {},
      } as CreateAssignmentMetadata,
      v$: useValidate() as any,
      validationErrors: [] as any,
      configSchema: null,
      validationMessage: "Click to validate.",
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getAssignmentMetadataTypes();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        metadataTypeId: {
          required,
        },
        data: {
          required,
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      let jsonValidation = this.validateData();
      if (!this.v$.$error && jsonValidation) {
        this.isSubmitLoading = true;
        try {
          if (typeof this.formData.data === "string") {
            this.formData.data = JSON.parse(this.formData.data);
          }
          await this.assignmentMetadataService.createAssignmentMetadata({
            createAssignmentMetadata: this.formData,
          });
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    async getAssignmentMetadataTypes() {
      try {
        this.assignmentMetadataTypes =
          await this.assignmentMetadataService.getAssignmentMetadataTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getConfigSchema() {
      const selectedMetadataType = this.assignmentMetadataTypes.find(
        (type) => type.id === this.formData.metadataTypeId
      );

      if (selectedMetadataType != undefined) {
        this.configSchema = selectedMetadataType.configSchema;
      }
    },
    validateData() {
      const selectedMetadataType = this.assignmentMetadataTypes.find(
        (type) => type.id === this.formData.metadataTypeId
      );

      const ajv = new Ajv();

      if (
        selectedMetadataType?.configSchema == null &&
        Object.keys(this.formData.data).length != 0
      ) {
        this.validationMessage =
          "Selected Metadata type has no Config schema. Any data is acceptable.";
        return true;
      } else if (
        selectedMetadataType != undefined &&
        Object.keys(this.formData.data).length != 0
      ) {
        const validate = ajv.compile(selectedMetadataType.configSchema);
        const data = JSON.parse(this.formData.data);
        const valid = validate(data);

        if (valid) {
          this.validationMessage = "Validation successful!";
          return true;
        } else {
          if (validate.errors) {
            let message = validate.errors[0]?.message;
            this.validationMessage = message
              ? message
              : "Unsuccessful validation!";
          }
          return false;
        }
      } else if (selectedMetadataType == null) {
        this.validationMessage = "No config schema to validate against.";
        return false;
      } else if (Object.keys(this.formData.data).length == 0) {
        this.validationMessage = "No data to validate against config schema.";
        return false;
      }
    },
  },
});
</script>

<style scoped></style>
