<template>
  <div class="flex h-[calc(100vh_-_16rem)] w-full pb-1">
    <div class="w-[350px]">
      <custom-table-list
        :table-data="devices"
        :web-socket-data="webSocketData"
        @show-asset-route-date-picker-modal="showAssetRouteDatePickerModal"
        @open-generic-packet-modal="openGenericPacketModal"
        @selected-rows="getSelectedRow"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CustomTableList from "@/modules/MapView/Components/CustomTableList.vue";

export default defineComponent({
  components: {
    CustomTableList,
  },
  props: [
    "devices",
    "getSelectedRow",
    "webSocketData",
    "showAssetRouteDatePickerModal",
    "openGenericPacketModal",
  ],
  data() {
    return {};
  },
});
</script>

<style scoped></style>
