<template>
  <div class="h-[calc(100vh_-_16rem)] w-[350px] pb-1">
    <div class="mb-2 flex h-[8%] w-full space-x-2 p-2">
      <div v-if="!isZoneCreateMode && !isZoneEditMode" class="w-full">
        <the-button
          :icon="['fas', 'plus']"
          :text="$t('CreateZone')"
          size="full"
          @execute-method="emitToggleCreateZoneModal"
        />
      </div>
      <div v-if="isZoneCreateMode" class="flex h-full w-full space-x-2">
        <the-button
          :text="$t('Cancel')"
          size="full"
          variant="outline"
          @execute-method="emitCancelZoneCreate"
        />
      </div>
      <div v-if="isZoneEditMode" class="flex w-full space-x-2">
        <the-button
          :text="$t('CancelEdit')"
          size="full"
          variant="outline"
          @execute-method="emitCancelZoneEdit"
        />
        <the-button
          :text="$t('Save')"
          size="full"
          @execute-method="emitSaveEditedZone"
        />
      </div>
    </div>
    <custom-table
      :options="options"
      :remove-table-header="true"
      :table-data="zones"
      :table-headers="tableHeaders"
      table-height="90%"
      @selected-rows="getSelectedRow"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: {
    CustomTable,
    TheButton,
  },
  props: ["zones", "getSelectedRow", "isZoneCreateMode", "isZoneEditMode"],
  emits: [
    "toggle-create-zone-modal",
    "cancel-zone-create",
    "cancel-zone-edit",
    "save-edited-zone",
    "toggle-zone-edit",
    "toggle-delete-modal",
    "toggle-tag-actions",
  ],
  data() {
    return {
      tableHeaders: {
        "data.name": { header: "Zone", visible: true },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "fa-pen"],
          action: this.emitToggleZoneEdit,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "fa-trash"],
          action: this.emitToggleDeleteModal,
        },
        view: {
          header: "Action.Markers",
          icon: ["fas", "map-pin"],
          action: this.emitToggleTagActions,
        },
      },
    };
  },
  methods: {
    emitToggleCreateZoneModal() {
      this.$emit("toggle-create-zone-modal");
    },
    emitCancelZoneCreate() {
      this.$emit("cancel-zone-create");
    },
    emitCancelZoneEdit() {
      this.$emit("cancel-zone-edit");
    },
    emitSaveEditedZone() {
      this.$emit("save-edited-zone");
    },
    emitToggleZoneEdit() {
      this.$emit("toggle-zone-edit");
    },
    emitToggleDeleteModal() {
      this.$emit("toggle-delete-modal");
    },
    emitToggleTagActions() {
      this.$emit("toggle-tag-actions");
    },
  },
});
</script>
<style scoped></style>
