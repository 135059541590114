import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 1,
  class: "flex h-full w-full flex-col items-center justify-center space-y-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_component = _resolveComponent("tabs-component")!
  const _component_loader = _resolveComponent("loader")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_device_card = _resolveComponent("device-card")!
  const _component_grid = _resolveComponent("grid")!
  const _component_empty_state = _resolveComponent("empty-state")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createBlock(_component_main_section, null, {
    default: _withCtx(() => [
      _createVNode(_component_tabs_component, {
        tabs: _ctx.tabs,
        onChangeTab: _ctx.handleTabChange
      }, null, 8, ["tabs", "onChangeTab"]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_input_field, {
              modelValue: _ctx.filter,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
              placeholder: "Filter resources"
            }, null, 8, ["modelValue"]),
            (_ctx.resources.length)
              ? (_openBlock(), _createBlock(_component_grid, {
                  key: 0,
                  "column-number": 4
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resources, (resource) => {
                      return (_openBlock(), _createBlock(_component_device_card, {
                        "last-sent-data": _ctx.lastDevicesSentData,
                        resource: resource
                      }, null, 8, ["last-sent-data", "resource"]))
                    }), 256))
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_empty_state, { "page-name": "resources" }),
                  _createVNode(_component_the_button, {
                    icon: ['fas', 'plus'],
                    "button-type": "primaryButton",
                    text: "Add resource",
                    onExecuteMethod: _ctx.redirectToResources
                  }, null, 8, ["onExecuteMethod"])
                ]))
          ]))
    ]),
    _: 1
  }))
}