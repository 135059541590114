import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative flex h-full w-full cursor-pointer items-center justify-center text-center text-gray-600" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_filter_row = _resolveComponent("dx-filter-row")!
  const _component_dx_column = _resolveComponent("dx-column")!
  const _component_dx_pager = _resolveComponent("dx-pager")!
  const _component_dx_paging = _resolveComponent("dx-paging")!
  const _component_dx_selection = _resolveComponent("dx-selection")!
  const _component_dropdown_menu = _resolveComponent("dropdown-menu")!
  const _component_DxMasterDetail = _resolveComponent("DxMasterDetail")!
  const _component_AssetDetails = _resolveComponent("AssetDetails")!
  const _component_dx_data_grid = _resolveComponent("dx-data-grid")!

  return (_openBlock(), _createBlock(_component_dx_data_grid, {
    "data-source": _ctx.tableData,
    "hover-state-enabled": true,
    "show-borders": false,
    height: "100%",
    "key-expr": "assignment.id",
    width: "100%",
    onSelectionChanged: _ctx.onSelectionChanged
  }, {
    openMenu: _withCtx(({ data }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_dropdown_menu, {
          options: _ctx.returnFilteredOptions(data.data),
          "use-for-table": true,
          "is-company-active": true
        }, null, 8, ["options"])
      ])
    ]),
    "group-header": _withCtx(({ data }) => [
      (data.data.items && data.data.items.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: 
                _ctx.IconSelector(
                  data.data.items && data.data.items[0]?.device?.deviceTypeId
                )
              ,
                class: "h-auto w-10"
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("div", null, _toDisplayString(data.data.items[0].device.deviceType.name), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    masterDetailTemplate: _withCtx(({ data: asset }) => [
      _createVNode(_component_AssetDetails, {
        asset: asset,
        "socket-data": _ctx.webSocketData
      }, null, 8, ["asset", "socket-data"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_dx_filter_row, { visible: true }),
      _createVNode(_component_dx_column, {
        caption: "Assets",
        "data-field": "assignment.name"
      }),
      _createVNode(_component_dx_column, {
        width: 50,
        "cell-template": "openMenu"
      }),
      _createVNode(_component_dx_pager, {
        "show-info": false,
        "show-navigation-buttons": false,
        "show-page-size-selector": false,
        visible: false
      }),
      _createVNode(_component_dx_paging, { enabled: false }),
      _createVNode(_component_dx_selection, {
        mode: "single",
        "select-all-mode": "allPages"
      }),
      _createVNode(_component_dx_column, {
        "group-index": 0,
        "data-field": "device.deviceTypeId",
        "group-cell-template": "group-header"
      }),
      _createVNode(_component_DxMasterDetail, {
        enabled: true,
        template: "masterDetailTemplate"
      })
    ]),
    _: 1
  }, 8, ["data-source", "onSelectionChanged"]))
}