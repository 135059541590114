<template>
  <modal
    :is-displayed="isMoveToNewCompanyModalOpened"
    :is-select-modal="true"
    :no-padding="true"
    :have-modal="haveModal"
    title="Move asset to other company"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper
      :is-form-in-modal="true"
      :is-loading="isLoading"
      :need-for-scroll="true"
    >
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Companies</label
            >
            <v-select
              v-model="selectedCompanyId"
              :class="`mt-1`"
              :options="companies"
              :reduce="(spot : any) => spot.id"
              label="name"
            >
              <template #list-footer v-if="hasNextPage">
                <div class="flex justify-center align-middle">
                  <div
                    class="mt-1 cursor-pointer bg-primary px-3 py-1 text-white transition duration-300 hover:bg-primaryhover disabled:cursor-not-allowed"
                    @click="loadMoreCompanies"
                  >
                    Load more...
                  </div>
                </div>
              </template>
            </v-select>
          </div>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SpotiumService from "@/core/Services/SpotiumService";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import CompanyService, {
  Company,
} from "@/modules/Companies/Services/CompanyService";
import Modal from "@/core/Components/Modal.vue";
import VSelect from "vue-select";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: {
    Modal,
    VSelect,
    FormWrapper,
    FormDivider,
    ButtonWrapper,
  },
  props: {
    isMoveToNewCompanyModalOpened: Boolean,
    assignmentId: {
      type: Number,
      required: true,
    },
    refreshData: Function,
    isForMultipleDevices: Boolean,
    closeModal: Function,
    haveModal: Boolean,
  },
  emits: ["toggle-modal", "multiple-move"],
  data() {
    return {
      spotiumService: new SpotiumService(),
      toast: useToast(),
      companies: [] as Company[],
      pageSize: 100,
      pageNumber: 1,
      hasNextPage: false,
      isSubmitLoading: false,
      isLoading: false,
      companyService: new CompanyService(),
      selectedCompanyId: null,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      const response = await this.companyService.getAllCompanies(
        this.pageNumber,
        this.pageSize
      );
      this.hasNextPage = response.hasNextPage;
      this.companies = response.items;
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async handleSubmit() {
      if (this.isForMultipleDevices) {
        this.$emit("multiple-move", this.selectedCompanyId);
        return;
      }
      this.isSubmitLoading = true;
      try {
        if (this.selectedCompanyId === null) {
          throw new Error("Please select company!");
        }
        await this.spotiumService.moveDeviceToNewCompany({
          deviceAssignmentId: this.assignmentId,
          companyId: this.selectedCompanyId,
        });
        this.toast.success("Device moved successfully");
        await this.refreshData?.();
        this.closeModal?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async loadMoreCompanies() {
      if (this.hasNextPage) {
        this.pageNumber += 1;
        try {
          const response = await this.companyService.getAllCompanies(
            this.pageNumber,
            this.pageSize
          );
          this.hasNextPage = response.hasNextPage;

          this.companies.push(...response.items);
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }
    },
  },
});
</script>

<style scoped></style>
