import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_json_pretty = _resolveComponent("vue-json-pretty")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    "have-modal": _ctx.haveModal,
    "is-displayed": _ctx.isLastDataModalOpened,
    "is-modal-without-top-offset": true,
    "last-data-modal": true,
    "need-for-scroll": true,
    title: 
      _ctx.metadata
        ? `Applied metadata for ${_ctx.name}`
        : _ctx.preview
        ? `Data preview for ${_ctx.name}`
        : `Last data sent by ${_ctx.name}`
    ,
    "additional-class": "absolute top-10",
    onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_form_wrapper, {
          "is-form-in-modal": true,
          "is-loading": _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_vue_json_pretty, {
              data: _ctx.data ? _ctx.data : _ctx.lastData,
              path: 'res'
            }, null, 8, ["data"])
          ]),
          _: 1
        }, 8, ["is-loading"])
      ])
    ]),
    _: 1
  }, 8, ["have-modal", "is-displayed", "title"]))
}