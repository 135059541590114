import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "h-[calc(100vh_-_16rem)] w-[350px] pb-1" }
const _hoisted_2 = { class: "mb-2 flex h-[8%] w-full space-x-2 p-2" }
const _hoisted_3 = {
  key: 0,
  class: "w-full"
}
const _hoisted_4 = {
  key: 1,
  class: "flex h-full w-full space-x-2"
}
const _hoisted_5 = {
  key: 2,
  class: "flex w-full space-x-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.isZoneCreateMode && !_ctx.isZoneEditMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_the_button, {
              icon: ['fas', 'plus'],
              text: _ctx.$t('CreateZone'),
              size: "full",
              onExecuteMethod: _ctx.emitToggleCreateZoneModal
            }, null, 8, ["text", "onExecuteMethod"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isZoneCreateMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_the_button, {
              text: _ctx.$t('Cancel'),
              size: "full",
              variant: "outline",
              onExecuteMethod: _ctx.emitCancelZoneCreate
            }, null, 8, ["text", "onExecuteMethod"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isZoneEditMode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_the_button, {
              text: _ctx.$t('CancelEdit'),
              size: "full",
              variant: "outline",
              onExecuteMethod: _ctx.emitCancelZoneEdit
            }, null, 8, ["text", "onExecuteMethod"]),
            _createVNode(_component_the_button, {
              text: _ctx.$t('Save'),
              size: "full",
              onExecuteMethod: _ctx.emitSaveEditedZone
            }, null, 8, ["text", "onExecuteMethod"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_custom_table, {
      options: _ctx.options,
      "remove-table-header": true,
      "table-data": _ctx.zones,
      "table-headers": _ctx.tableHeaders,
      "table-height": "90%",
      onSelectedRows: _ctx.getSelectedRow
    }, null, 8, ["options", "table-data", "table-headers", "onSelectedRows"])
  ]))
}