const defaultDevice = `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/defaultDevice.svg`;

const ICONS: Record<number, string> = {
  1: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/agribox.png`,
  16: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/trash.png`,
  28: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/watermeter.png`,
  32: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/generic.png`,
  47: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/tdc-e.png`,
  49: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/agribox.png`,
  50: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/ikona_rampa.svg`,
  51: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/generic.png`,
  53: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/agribox.png`,
  54: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/agribox.png`,
  55: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/robot_icon_0.png`,
  76: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/meteostation.png`,
  80: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/agribox.png`,
  81: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/meteostation.png`,
  82: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/meteostation.png`,
  101: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/RPS.png`,
  102: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/solarpanel.png`,
  103: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/iolinkSIG.png`,
  104: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/energy-meter.png`,
  107: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/forklift.png`,
  108: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/multi-physics-box.png`,
  110: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/FTMg-sensor.png`,
  111: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/levelMeter.png`,
  112: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/industrialPC.png`,
  113: `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/device-photos/contrastSensor.png`,
} as const;

export function IconSelector(deviceTypeId: number): string {
  return ICONS[deviceTypeId] || defaultDevice;
}
