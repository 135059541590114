import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type DeviceType = {
  id: number;
  name: string;
  productionPN?: string;
  customerPN?: string;
  serialNumberTypeId?: number | null;
  parentDeviceTypeId?: number | null;
  isProductionEnabled?: boolean;
};

export type CreateDeviceType = Omit<DeviceType, "id">;

export type EditDeviceType = DeviceType;

export type SerialNumberType = {
  id: number;
  name: string;
};

export default class DeviceTypeService {
  async getAllDeviceTypes(
    pageNumber = 1,
    pageSize = 10
  ): Promise<DeviceType[]> {
    const response = await ApiService.get(
      `${ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH}/api/device-types?PageNumber=${pageNumber}&PageSize=${pageSize}`
    );

    return this._mapDeviceTypes(response.data.items);
  }

  async getSerialNumberTypes(): Promise<SerialNumberType[]> {
    const response = await ApiService.get(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/serial-number-types`
    );

    return this._mapSerialNumberTypes(response.data.items);
  }

  async createDeviceType(deviceType: CreateDeviceType): Promise<any> {
    return await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/device-types`,
      deviceType
    );
  }

  async updateDeviceType(deviceType: EditDeviceType): Promise<any> {
    return await ApiService.put(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/device-types/${deviceType.id}`,
      deviceType
    );
  }

  async deleteDeviceType(id: number): Promise<any> {
    return await ApiService.delete(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/device-types/${id}`
    );
  }

  private _mapDeviceTypes(response: DeviceType[]): DeviceType[] {
    return response.map((deviceType: DeviceType) =>
      this._mapDeviceType(deviceType)
    );
  }

  private _mapDeviceType(deviceType: DeviceType): DeviceType {
    return {
      id: deviceType.id,
      name: deviceType.name,
      productionPN: deviceType.productionPN,
      customerPN: deviceType.customerPN,
      serialNumberTypeId: deviceType.serialNumberTypeId,
      parentDeviceTypeId: deviceType.parentDeviceTypeId,
      isProductionEnabled: deviceType.isProductionEnabled,
    };
  }

  private _mapSerialNumberTypes(
    response: SerialNumberType[]
  ): SerialNumberType[] {
    return response.map((serialNumberType: SerialNumberType) =>
      this._mapSerialNumberType(serialNumberType)
    );
  }

  private _mapSerialNumberType(
    serialNumberType: SerialNumberType
  ): SerialNumberType {
    return {
      id: serialNumberType.id,
      name: serialNumberType.name,
    };
  }
}
