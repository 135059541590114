<template>
  <last-data-preview
    v-if="isDataPreviewModalOpened"
    :data="dataForModal"
    :is-last-data-modal-opened="isDataPreviewModalOpened"
    :name="selectedDevice[0]?.name"
    :preview="true"
    @toggle-modal="isDataPreviewModalOpened = false"
  />
  <create-form
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getAllDevices"
    @toggle-modal="isCreateModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :device="selectedDevice[0]"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getAllDevices"
    @toggle-modal="isEditModalOpened = false"
  />
  <delete-modal
    v-if="isDeleteModalOpened"
    :is-deleting="isDeleteModalOpened"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    delete-text="delete"
    @confirm-action="deleteDevice"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <modal
    v-if="isSentDataModalOpened"
    :have-modal="true"
    :is-full-screen-modal="true"
    :is-displayed="isSentDataModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    :remove-outside-click="true"
    :title="`Device data sent by ${selectedDevice[0].name}`"
    @toggle-modal="toggleDataSentModal"
  >
    <sent-data-devices
      :deviceUuid="selectedDevice[0].uuid"
      :deviceName="selectedDevice[0].name"
    />
  </modal>
  <q-r-code-devices-modal
    v-if="isQrCodeModalOpened"
    :device-details="selectedDevice[0]"
    :have-modal="true"
    :is-qr-modal-opened="isQrCodeModalOpened"
    @toggle-modal="isQrCodeModalOpened = false"
  />
  <apply-metadata-relation
    v-if="isCreateRelationModalOpened"
    :device-id-list="deviceIdList"
    :is-create-relation-modal-opened="isCreateRelationModalOpened"
    :refresh-data="getAllDevices"
    :table-data="devicePagination.items"
    @toggle-modal="isCreateRelationModalOpened = false"
  />
  <delete-metadata-relation
    v-if="isDeleteRelationModalOpened"
    :device-id="selectedDevice[0].id ?? 0"
    :is-delete-relation-modal-opened="isDeleteRelationModalOpened"
    :metadata="selectedDevice[0].metadata"
    :refresh-data="getAllDevices"
    @toggle-modal="isDeleteRelationModalOpened = false"
  />
  <main-section>
    <table-action-header :title="$t('Devices.Title')" description=" " />
    <loader
      v-if="isLoading"
      inner-ring-thickness="border-t-8"
      outer-ring-thickness="border-8"
    />
    <div v-else>
      <div class="mb-4 flex items-center justify-between">
        <div class="flex gap-2">
          <v-select
            class="mt-1 w-60"
            v-model="selectedFilterOption"
            :options="searchOptions"
            :reduce="(option : any) => option.key"
            label="label"
            :clearable="false"
          />
          <div class="w-60">
            <input-field
              v-model="searchDeviceText"
              placeholder="Enter search value"
              :no-padding="true"
              @input="handleDeviceSearch"
            />
          </div>
        </div>
        <div class="flex items-center gap-4">
          <the-button
            text="Apply metadata"
            isApplyButtonEnabled
            :is-disabled="
              !isApplyButtonEnabled ||
              !permissionStore.hasPermission(permissions.EditDevice)
            "
            :variant="
              isApplyButtonEnabled ||
              !permissionStore.hasPermission(permissions.EditDevice)
                ? 'default'
                : 'ghost'
            "
            @execute-method="toggleCreateRelationModal"
          />
          <the-button
            v-if="permissionStore.hasPermission(permissions.CreateDevice)"
            :icon="['fas', 'add']"
            buttonType="primaryButton"
            text="Create"
            @execute-method="toggleCreateModal"
          />
        </div>
      </div>
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="devicePagination.items"
        :table-headers="tableHeaders"
        :multiselect="true"
        @force-refresh="getAllDevices()"
        @open-data-modal="displayData"
        @selected-rows="getSelectedRow"
      />
      <pagination
        :pagination-data="devicePagination"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import DeviceService, {
  Device,
  DeviceObject,
  DeviceProps,
} from "@/modules/Devices/Services/DeviceService";
import Loader from "@/core/Components/Loader.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import LastDataPreview from "@/modules/Devices/Submodules/Assignments/Components/LastDataPreview.vue";
import CreateForm from "../Components/CreateForm.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import EditForm from "../Components/EditForm.vue";
import Pagination from "@/core/Components/Pagination.vue";
import { PaginationSettings } from "@/core/Services/PaginationSettings";
import { Permissions } from "@/core/Services/Enums";
import VSelect from "vue-select";
import InputField from "@/core/Components/FormElements/InputField.vue";
import QRCodeDevicesModal from "@/modules/Production/Submodules/Devices/Components/QRCodeDevicesModal.vue";
import { usePermissionStore } from "@/core/Store/permissionStore";
import TheButton from "@/core/Components/TheButton.vue";
import ApplyMetadataRelation from "../Components/ApplyMetadataRelation.vue";
import DeleteMetadataRelation from "../Components/DeleteMetadataRelation.vue";
import SentDataDevices from "../Components/SentDataDevices.vue";
import Modal from "@/core/Components/Modal.vue";

export default defineComponent({
  components: {
    DeleteModal,
    ConfirmationModal,
    LastDataPreview,
    CustomTable,
    TableActionHeader,
    Loader,
    MainSection,
    CreateForm,
    EditForm,
    Pagination,
    VSelect,
    InputField,
    QRCodeDevicesModal,
    TheButton,
    ApplyMetadataRelation,
    DeleteMetadataRelation,
    SentDataDevices,
    Modal,
  },

  data() {
    return {
      //
      searchOptions: [
        { label: "Name", key: "name" },
        { label: "Uuid", key: "uuid" },
        { label: "Mac", key: "mac" },
        { label: "Imei", key: "imei" },
        { label: "Imsi", key: "imsi" },
        { label: "External Id", key: "externalId" },
        { label: "Serial number", key: "serialNumber" },
        { label: "Device type name", key: "deviceTypeName" },
      ],
      selectedFilterOption: "name",
      searchDeviceText: "",
      searchTimeoutId: undefined as any,
      //
      deviceService: new DeviceService(),
      device: [] as Device[],
      paginationProps: {} as DeviceProps,
      filterProps: {} as DeviceProps,
      devicePagination: {} as DeviceObject,
      toast: useToast(),
      permissionStore: usePermissionStore(),
      permissions: Permissions,
      dataForModal: {} as any,
      selectedDevice: [] as Device[],
      isQrCodeModalOpened: false,
      isDataPreviewModalOpened: false,
      isLoading: false,
      isDataSourceLoading: false,
      isCreateModalOpened: false,
      isEditModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      isApplyButtonEnabled: false,
      isCreateRelationModalOpened: false,
      isDeleteRelationModalOpened: false,
      isSentDataModalOpened: false,
      tableHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "Name", visible: true },
        uuid: { header: "UUID", visible: true },
        imei: { header: "IMEI", visible: true },
        mac: { header: "MAC", visible: true },
        imsi: { header: "IMSI", visible: true },
        externalId: { header: "External ID", visible: false },
        serialNumber: { header: "Serial number", visible: true },
        isActive: { header: "Active", visible: false },
        "actions.id": { header: "Asset action ID", visible: false },
        "deviceType.name": { header: "Device type", visible: false },
        "company.name": { header: "Company", visible: false },
        parentDeviceId: { header: "Parent device ID", visible: false },
        assignmentId: { header: "Asset ID", visible: false },
        data: { header: "Data", visible: false },
        "metadata.device": { header: "Device metadata", visible: false },
        "assignment.application": {
          header: "Asset application",
          visible: true,
        },
        "assignment.company": { header: "Asset company", visible: true },
        "assignment.user": { header: "Asset user", visible: true },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditDevice,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteDevice,
          action: this.toggleDeleteModal,
        },
        removeRelation: {
          header: "Remove metadata relation",
          icon: ["fas", "link-slash"],
          permissionId: Permissions.EditDevice,
          action: this.toggleDeleteRelationModal,
        },
        deviceData: {
          header: "View all data",
          icon: ["fas", "calendar"],
          action: this.toggleDataSentModal,
        },
        qr: {
          header: "QR code",
          icon: ["fas", "qrcode"],
          action: this.toggleQrCodeModal,
        },
      },
    };
  },
  watch: {
    selectedFilterOption(newValue, oldValue) {
      this.handleDeviceSearch();
    },
  },
  async created() {
    this.isLoading = true;
    try {
      this.paginationProps.pageNumber = PaginationSettings.DEFAULT_CURRENT_PAGE;
      this.paginationProps.pageSize =
        PaginationSettings.DEFAULT_CURRENT_PAGE_SIZE;
      await this.getAllDevices();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    deviceIdList(): number[] {
      return this.selectedDevice
        .map((device) => device.id)
        .filter((item) => item !== null) as number[];
    },
  },
  methods: {
    handleDeviceSearch() {
      this.filterProps = {};
      if (this.searchDeviceText != "") {
        this.filterProps[this.selectedFilterOption] = this.searchDeviceText;
      }

      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }

      this.searchTimeoutId = setTimeout(async () => {
        this.getAllDevices();
      }, 800);
    },
    handlePageChange(newPage: number) {
      this.paginationProps.pageNumber = newPage;
      this.getAllDevices();
    },
    handlePageSizeChange(newPageSize: number) {
      this.paginationProps.pageSize = newPageSize;
      this.paginationProps.pageNumber = 1;
      this.getAllDevices();
    },
    async getAllDevices() {
      this.isDataSourceLoading = true;
      try {
        let deviceProps = { ...this.paginationProps, ...this.filterProps };
        this.devicePagination = await this.deviceService.getAllDevices(
          deviceProps
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async deleteDevice() {
      try {
        await this.deviceService.deleteDevice(this.selectedDevice[0].id);
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isConfirmationModalOpened = false;
        this.isDeleteModalOpened = false;
        await this.getAllDevices();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getSelectedRow(selectedRow: Device[]) {
      this.selectedDevice = selectedRow;
      if (selectedRow.length) {
        this.isApplyButtonEnabled = true;
      } else this.isApplyButtonEnabled = false;
    },
    displayData(data: any) {
      this.isDataPreviewModalOpened = true;
      this.dataForModal = data;
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
    },
    toggleQrCodeModal() {
      this.isQrCodeModalOpened = !this.isQrCodeModalOpened;
    },
    toggleCreateRelationModal() {
      this.isCreateRelationModalOpened = !this.isCreateRelationModalOpened;
    },
    toggleDeleteRelationModal() {
      this.isDeleteRelationModalOpened = !this.isDeleteRelationModalOpened;
    },
    toggleDataSentModal() {
      this.isSentDataModalOpened = !this.isSentDataModalOpened;
    },
  },
});
</script>

<style scoped></style>
