import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "grid grid-cols-2 gap-6",
  style: {"grid-template-columns":"auto min-content"}
}
const _hoisted_2 = { class: "pb-3 pt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_map_location_selector = _resolveComponent("map-location-selector")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isCreateModalOpened,
    "no-padding": true,
    title: _ctx.$t('Displays.CreateTitle'),
    onToggleModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      (_ctx.isMapLocationModalOpened)
        ? (_openBlock(), _createBlock(_component_map_location_selector, {
            key: 0,
            "coordinates-prop": { lat: _ctx.display.latitude, lng: _ctx.display.longitude },
            "is-map-location-modal-opened": _ctx.isMapLocationModalOpened,
            onToggleModal: _ctx.toggleMapLocationModal,
            onGetCoordinates: _ctx.getMarkerCoordinates
          }, null, 8, ["coordinates-prop", "is-map-location-modal-opened", "onToggleModal", "onGetCoordinates"]))
        : _createCommentVNode("", true),
      _createElementVNode("form", {
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
          default: _withCtx(() => [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createVNode(_component_input_field, {
                  modelValue: _ctx.display.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.display.name) = $event)),
                  "error-message": this.v$?.display['name']?.$errors[0]?.$message,
                  "is-error": _ctx.v$?.display['name']?.$error,
                  label: _ctx.$t('Displays.Name'),
                  required: true
                }, null, 8, ["modelValue", "error-message", "is-error", "label"]),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_input_field, {
                      modelValue: _ctx.display.latitude,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.display.latitude) = $event)),
                      "error-message": 
                  this.v$?.display['latitude']?.$errors[0]?.$message
                ,
                      "is-error": _ctx.v$?.display['latitude']?.$error,
                      label: _ctx.$t('Displays.Latitude'),
                      required: true
                    }, null, 8, ["modelValue", "error-message", "is-error", "label"]),
                    _createVNode(_component_input_field, {
                      modelValue: _ctx.display.longitude,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.display.longitude) = $event)),
                      "error-message": 
                  this.v$?.display['longitude']?.$errors[0]?.$message
                ,
                      "is-error": _ctx.v$?.display['longitude']?.$error,
                      label: _ctx.$t('Displays.Longitude'),
                      required: true
                    }, null, 8, ["modelValue", "error-message", "is-error", "label"])
                  ]),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_the_button, {
                      icon: ['fas', 'earth-europe'],
                      "button-type": "primaryButton",
                      type: "button",
                      onExecuteMethod: _ctx.toggleMapLocationModal
                    }, null, 8, ["onExecuteMethod"])
                  ])
                ]),
                _createVNode(_component_input_field, {
                  modelValue: _ctx.display.imei,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.display.imei) = $event)),
                  "error-message": this.v$?.display['imei']?.$errors[0]?.$message,
                  "is-error": _ctx.v$?.display['imei']?.$error,
                  label: _ctx.$t('Displays.IMEI'),
                  required: true
                }, null, 8, ["modelValue", "error-message", "is-error", "label"]),
                _createVNode(_component_checkbox_input, {
                  modelValue: _ctx.display.active,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.display.active) = $event)),
                  "is-checked": _ctx.display.active,
                  label: _ctx.$t('Displays.Active')
                }, null, 8, ["modelValue", "is-checked", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
          ]),
          _: 1
        })
      ], 32)
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}