import { RouteRecordRaw } from "vue-router";
import Index from "@/modules/Devices/Pages/Index.vue";
import AssignmentIndex from "../Submodules/Assignments/Pages/Index.vue";
import AssignmentMetadata from "../Submodules/AssignmentMetadata/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createDeviceIndexRoute(): RouteRecordRaw {
  return {
    name: "devices",
    path: "/",
    component: Index,
    redirect: {
      name: "assignmentsIndex",
    },
    meta: {
      id: "devicesIndex",
      label: "Sidebar.Assignments",
      icon: ["fac", "user-device"],
      showInNavigation: true,
      group: "main",
      authorize: [Permissions.ViewAssignment],
    },
    children: [
      {
        name: "assignmentsIndex",
        path: "assignments",
        component: AssignmentIndex,
        meta: {
          id: "assignmentsIndex",
          label: "Sidebar.Assignments",
          icon: ["fac", "user-device"],
          showInNavigation: true,
          group: "main",
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "assignmentsMetadataIndex",
        path: "assignment-metadata",
        component: AssignmentMetadata,
        meta: {
          id: "assignmentsMetadataIndex",
          label: "Sidebar.AssignmentMetadata",
          icon: ["fas", "file-code"],
          showInNavigation: true,
          group: "main",
          authorize: [Permissions.ViewAssignment],
        },
      },
    ],
  };
}
