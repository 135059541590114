<template>
  <modal-component
    :is-displayed="isNodesViewOpened"
    :is-full-screen-modal="true"
    :remove-outside-click="true"
    :title="`Nodes for ${deviceDetails.name}`"
    @toggle-modal="$emit('toggle-modal')"
  >
    <sources-view
      v-if="isSourcesViewOpened"
      :display-id="deviceDetails.id"
      :is-sources-view-opened="isSourcesViewOpened"
      :node-details="nodeDetails"
      @toggle-modal="isSourcesViewOpened = false"
    />
    <create-form
      v-if="isCreateModalOpened"
      :display-id="deviceDetails.id"
      :is-create-modal-opened="isCreateModalOpened"
      :last-node-index="nodes[nodes.length - 1]?.index || 0"
      :refresh-data="async () => getDisplayNodes(deviceDetails.id)"
      @toggle-modal="isCreateModalOpened = false"
    />
    <edit-form
      v-if="isEditModalOpened"
      :is-edit-modal-opened="isEditModalOpened"
      :node-prop="selectedNode"
      :refresh-data="async () => getDisplayNodes(deviceDetails.id)"
      @toggle-modal="isEditModalOpened = false"
    />
    <delete-modal
      :is-deleting="isDeleteModalOpened"
      @cancel-delete="isDeleteModalOpened = false"
      @toggle-modal="isDeleteModalOpened = false"
      @confirm-delete="openConfirmationModal('delete')"
    />
    <confirmation-modal
      :delete-text="confirmationModalText"
      :is-confirmation-modal-opened="isConfirmationModalOpened"
      @confirm-action="confirmationModalAction"
      @close-confirmation-modal="isConfirmationModalOpened = false"
      @toggle-modal="isConfirmationModalOpened = false"
    />
    <div v-if="isLoading" class="flex w-full items-center justify-center">
      <small-loader />
    </div>
    <div v-else class="px-6">
      <table-action-header
        :icon="['fas', 'add']"
        button-text="Create"
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :is-data-source-loading="isDatasourceLoading"
        :options="options"
        :table-data="nodes"
        :table-headers="tableHeaders"
        @selected-rows="getSelectedRows"
        @manage-sources="manageSources"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import { Node } from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";
import { useToast } from "vue-toastification";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CreateForm from "./CreateForm.vue";
import Loader from "@/core/Components/Loader.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import EditForm from "./EditForm.vue";
import SourcesView from "@/modules/Parkom/Submodules/Displays/Components/Sources/SourcesView.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";

export default defineComponent({
  components: {
    ConfirmationModal,
    DeleteModal,
    SourcesView,
    EditForm,
    SmallLoader,
    Loader,
    CreateForm,
    TableActionHeader,
    CustomTable,
    ModalComponent,
  },
  props: {
    isNodesViewOpened: Boolean,
    deviceDetails: {
      type: Object as PropType<{ id: number; name: string }>,
      required: true,
    },
  },
  data() {
    return {
      displayService: new DisplayService(),
      toast: useToast(),
      nodes: [] as Node[],
      selectedNode: {} as Node,
      isLoading: false,
      isCreateModalOpened: false,
      isDatasourceLoading: false,
      isEditModalOpened: false,
      isSourcesViewOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      confirmationModalText: "",
      tableHeaders: {
        id: { header: "ID", visible: false },
        index: { header: "Node", visible: true, sortOrder: "asc" },
        typeName: { header: "Type", visible: true },
        refresh: { header: "Refresh", visible: true },
        lastValue: { header: "Last Value", visible: true },
        overrideValue: { header: "Override Value", visible: true },
        correction: { header: "Correction", visible: true },
        overrideFreeArrow: { header: "Override Free Arrow", visible: true },
        overrideOccupiedArrow: {
          header: "Override Occupied Arrow",
          visible: true,
        },
        sources: { header: "Sources", visible: true },
      },
      options: {
        edit: {
          header: "Edit",
          icon: ["fas", "pen"],
          action: this.toggleEditModal,
        },
        delete: {
          header: "Delete",
          icon: ["fas", "trash"],
          action: this.toggleDeleteModal,
        },
      },
      nodeDetails: {} as { id: number; index: number },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getDisplayNodes(this.deviceDetails.id);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getDisplayNodes(deviceId: number) {
      this.isDatasourceLoading = true;
      try {
        this.nodes = await this.displayService.getDisplayNodes(deviceId);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDatasourceLoading = false;
      }
    },
    async deleteNode() {
      try {
        await this.displayService.deleteNode(this.selectedNode.id);
        this.toast.success(this.$t("Displays.NodeDeletedSuccessfully"));
        this.isDeleteModalOpened = false;
        await this.getDisplayNodes(this.deviceDetails.id);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getSelectedRows(values: Node[]) {
      if (values.length) {
        this.selectedNode = values[0];
      }
    },
    async manageSources(nodeDetails: { id: number; index: number }) {
      this.nodeDetails = nodeDetails;
      this.isSourcesViewOpened = true;
    },

    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },

    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteNode();
          break;
      }
      this.isConfirmationModalOpened = false;
    },

    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
  },
});
</script>

<style scoped></style>
