import { RouteRecordRaw } from "vue-router";
import Index from "../Pages/Index.vue";
import NodeRedIndex from "../Submodules/NodeRed/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createFieldAnalyticsRoutes(): RouteRecordRaw {
  return {
    name: "fieldAnalyticsIndex",
    path: "/",
    component: Index,
    redirect: {
      name: "nodeRedIndex",
    },
    meta: {
      id: "fieldAnalyticsIndex",
      label: "Field analytics",
      icon: ["fas", "chart-line"],
      showInNavigation: true,
    },
    children: [
      {
        name: "nodeRedIndex",
        path: "node-red",
        component: NodeRedIndex,
        meta: {
          id: "nodeRedIndex",
          label: "Node red",
          icon: ["fas", "circle-nodes"],
          showInNavigation: true,
        },
      },
    ],
  };
}
