<template>
  <modal-component
    :is-displayed="isCreateBulkModalOpened"
    :no-padding="true"
    :title="$t('ParkingSpots.CreateBulkTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("ParkingSpots.Space") }}</label
            >
            <v-select
              v-model="bulkParkingSpot.spaceId"
              :class="`mt-1 ${
                v$?.bulkParkingSpot['spaceId']?.$error && 'border border-error'
              }`"
              :options="parkingSpaces"
              :reduce="(space : any) => space.id"
              label="name"
            ></v-select>
          </div>
          <input-field
            v-model="bulkParkingSpot.startIndex"
            :errorMessage="
              v$?.bulkParkingSpot['startIndex']?.$errors[0]?.$message
            "
            :isError="v$?.bulkParkingSpot['startIndex']?.$error"
            :label="$t('ParkingSpots.StartIndex')"
            :required="true"
            type="number"
          />
          <input-field
            v-model="bulkParkingSpot.quantity"
            :errorMessage="
              v$?.bulkParkingSpot['quantity']?.$errors[0]?.$message
            "
            :isError="v$?.bulkParkingSpot['quantity']?.$error"
            :label="$t('ParkingSpots.Quantity')"
            :required="true"
            type="number"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import VSelect from "vue-select";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import { minValue, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

export default defineComponent({
  components: {
    VSelect,
    InputField,
    ButtonWrapper,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isCreateBulkModalOpened: Boolean,
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      parkingSpotService: new ParkingSpotService(),
      parkingSpaceService: new ParkingSpaceService(),
      parkingSpaces: [] as ParkingSpace[],
      toast: useToast(),
      v$: useValidate(),
      isParkingSpaceListLoading: false,
      isSubmitLoading: false,
      bulkParkingSpot: {
        spaceId: null,
        startIndex: 1,
        quantity: 0,
      },
    };
  },
  async created() {
    try {
      await this.getParkingSpaces();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },
  validations() {
    return {
      bulkParkingSpot: {
        spaceId: {
          required,
        },
        startIndex: {
          required,
          minValue: minValue(1),
        },
        quantity: {
          required,
          minValue: minValue(1),
        },
      },
    };
  },
  watch: {
    async "bulkParkingSpot.spaceId"(spaceId: number) {
      await this.getLastParkingSpotIndex(spaceId);
    },
  },
  methods: {
    async handleSubmit() {
      await this.v$.$validate();
      if (this.v$.$error) {
        return;
      }
      this.isSubmitLoading = true;
      try {
        await this.parkingSpotService.createBulkSpots(this.bulkParkingSpot);
        this.toast.success(this.$t("ParkingSpots.CreateBulkSuccess"));
        this.$emit("toggle-modal");
        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getParkingSpaces() {
      this.isParkingSpaceListLoading = true;
      try {
        this.parkingSpaces = await this.parkingSpaceService.getParkingSpaces({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isParkingSpaceListLoading = false;
      }
    },
    async getLastParkingSpotIndex(parkingSpaceId: number) {
      try {
        this.bulkParkingSpot.startIndex =
          (await this.parkingSpaceService.getParkingSpaceLastIndex(
            parkingSpaceId
          )) + 1;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
