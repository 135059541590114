<template>
  <div
    v-click-away="isVersionDropdownOpened && toggleDropdown"
    class="relative"
  >
    <svg
      :class="`cursor-pointer fill-darkgrey hover:fill-primary ${
        isVersionDropdownOpened && 'fill-primary'
      }`"
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      @click="toggleDropdown"
    >
      <path
        d="M11 17h2v-6h-2Zm1-8q.425 0 .713-.288Q13 8.425 13 8t-.287-.713Q12.425 7 12 7t-.712.287Q11 7.575 11 8t.288.712Q11.575 9 12 9Zm0 13q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Zm0-2q3.35 0 5.675-2.325Q20 15.35 20 12q0-3.35-2.325-5.675Q15.35 4 12 4 8.65 4 6.325 6.325 4 8.65 4 12q0 3.35 2.325 5.675Q8.65 20 12 20Zm0-8Z"
      />
    </svg>
    <dropdown-wrapper :is-dropdown-opened="isVersionDropdownOpened">
      <div class="w-full px-2">
        <h3 class="border-b-2 pb-2 text-lg text-darker">Spotium</h3>
      </div>
      <div class="space-y-2 px-2">
        <div>
          <h3 class="font-bold text-darker">
            {{ $t("VersionDropdown.Version") }}
          </h3>
          <p class="text-darkgrey">{{ config.VERSION }}</p>
          <p class="text-darkgrey">{{ config.VERSION_DATE }}</p>
        </div>
        <div>
          <h3 class="font-bold text-darker">
            {{ $t("VersionDropdown.LegalInformation") }}
          </h3>
          <div class="flex flex-col space-y-1">
            <a
              v-for="information in legalInformation"
              :key="information.id"
              :href="information.url"
              class="mt-2 text-xs text-primary hover:underline"
              target="_blank"
              >{{ $t(information.label) }}</a
            >
          </div>
        </div>
      </div>
    </dropdown-wrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import DropdownWrapper from "@/core/Components/Dropdowns/DropdownWrapper.vue";
import { FormatDate } from "@/core/Services/GlobalFunctions";

export default defineComponent({
  inject: ["config"],
  components: { DropdownWrapper },
  data() {
    return {
      config: inject("config") as any,
      isVersionDropdownOpened: false,
      legalInformation: [
        {
          id: "imprint",
          label: "VersionDropdown.Imprint",
          url: "https://www.sick.com/at/en/imprint/w/imprint/",
        },
        {
          id: "termsAndConditions",
          label: "VersionDropdown.TermsAndConditions",
          url: "https://www.sick.com/at/en/general-terms-and-conditions/w/tac/",
        },
        {
          id: "dataProtection",
          label: "VersionDropdown.DataProtection",
          url: "https://www.sick.com/at/en/sick-data-privacy-declaration/w/dataprotection/",
        },
        {
          id: "termsOfUse",
          label: "VersionDropdown.TermsOfUse",
          url: "https://www.sick.com/at/en/terms-of-use/w/terms-of-use/",
        },
      ] as { id: string; label: string; url: string }[],
    };
  },
  methods: {
    formatDate: FormatDate,
    toggleDropdown() {
      this.isVersionDropdownOpened = !this.isVersionDropdownOpened;
    },
  },
});
</script>

<style>
.svg-icon {
  fill: #9ca3af;
}

.svg-icon:hover {
  fill: #009df4;
}
</style>
