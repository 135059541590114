<template>
  <div
    class="h-fit w-full rounded-md border border-gray-300 bg-white p-4 duration-300 ease-in-out hover:shadow-lg"
  >
    <div class="flex w-full items-center justify-center">
      <img class="w-20" src="@/assets/spotium-ikone/ikona_rampa.svg" />
    </div>
    <div class="my-8 font-medium">
      <h3 class="text-center">{{ resource?.assignment?.name }}</h3>

      <div class="flex items-center justify-center space-x-3">
        <font-awesome-icon :icon="['fas', 'clock-rotate-left']" />
        <p class="text-center">{{ triggerTime }}</p>
      </div>
    </div>
    <div class="flex w-full items-center">
      <the-button
        v-if="isOpenButtonDisplayed"
        size="full"
        :is-loading="isSubmitLoading"
        :text="`${resource?.device?.data?.type === 1 ? 'Open' : 'Open/Close'}`"
        button-type="primaryButton"
        @click="triggerResource"
      />
      <the-button
        v-else
        size="full"
        :is-disabled="true"
        :is-loading="isSubmitLoading"
        button-type="primaryButton"
        variant="ghost"
        text="No permission to open"
        @click="triggerResource"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  ErrorMessageFormatter,
  FormatDateTime,
} from "@/core/Services/GlobalFunctions";
import TheButton from "@/core/Components/TheButton.vue";
import ResourceService from "@/modules/AccessManager/SubModules/Resources/Services/ResourceService";
import { useToast } from "vue-toastification";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import DeviceDataService, {
  LastDeviceAssignmentData,
} from "@/modules/Devices/Services/DeviceDataService";

export default defineComponent({
  components: {
    TheButton,
  },
  props: {
    resource: {
      type: Object as PropType<DeviceAssignment>,
    },
    lastSentData: {
      type: Array as PropType<LastDeviceAssignmentData[]>,
      default: [],
    },
  },

  data() {
    return {
      toast: useToast(),
      resourceService: new ResourceService(),
      deviceDataService: new DeviceDataService(),
      lastDevicesSentData: [] as LastDeviceAssignmentData[],
      isSubmitLoading: false,
      triggerTime: "",
      isOpenButtonDisplayed: false,
      idsToDisplayButton: [1, 401],
    };
  },
  created() {
    this.triggerTime = FormatDateTime(
      this.lastSentData.find(
        (lastData: LastDeviceAssignmentData) =>
          lastData.dataUuid === this.resource?.assignment?.dataUuid
      )?.data[0]?.dateTime || ""
    );
    if (this.resource?.assignment?.permissions?.length) {
      if (
        this.resource?.assignment?.permissions?.find(
          (permission: any) => permission.id === 401
        )
      ) {
        this.isOpenButtonDisplayed = true;
      }
    }
  },
  methods: {
    formatDateTime: FormatDateTime,
    async triggerResource() {
      if (this.resource!.device!.deviceUuid) {
        this.isSubmitLoading = true;
        try {
          await this.resourceService.triggerResource(
            this.resource!.device!.deviceUuid,
            this.resource?.device?.data.topic
          );

          const response =
            await this.deviceDataService.getDeviceAssignmentLastData({
              dataUuids: [this.resource!.assignment!.dataUuid],
              pageSize: 10000,
              pageNumber: 1,
            });

          this.triggerTime = FormatDateTime(response[0].data[0]?.dateTime);
          this.toast.success("Ramp/Gate successfully opened!");
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
  },
});
</script>
<style scoped></style>
