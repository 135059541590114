<template>
  <div class="mb-2">
    <div
      class="mt-4 flex cursor-pointer items-center gap-2 whitespace-nowrap text-sm font-medium not-italic text-gray-800"
      @click="showDeviceParentForm"
    >
      {{ $t("Devices.ParentDeviceId") }}
      <i
        :class="{
          'fa fa-chevron-up': showParentDeviceForm,
          'fa fa-chevron-down': !showParentDeviceForm,
        }"
        style="color: #929bad"
      ></i>
    </div>
    <div v-if="showParentDeviceForm" class="p-3">
      <form>
        <div>
          <label
            class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
            >Type<span class="pl-0.5 text-orange-600">*</span></label
          >
          <div class="flex items-center justify-center">
            <v-select
              :class="`mt-1 w-full ${selectedType == null && 'error'}`"
              v-model="selectedType"
              :options="identifiers"
            ></v-select>
          </div>
          <span v-if="selectedType == null" class="mt-2 text-sm text-error"
            >Value is required
          </span>
        </div>
        <input-field
          v-model="identifier"
          label="Identifier"
          :errorMessage="'Value is required'"
          :isError="identifier == ''"
        />
        <div class="mt-2 flex items-center gap-3">
          <the-button
            :text="'Get device'"
            @click="getParentDeviceId"
            :type="'button'"
          />
          <the-button
            :text="'Clear'"
            @click="clearParentDeviceId"
            :type="'button'"
            variant="outline"
          />
          <div>
            {{ parentDevice }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import VSelect from "vue-select";
import TheButton from "@/core/Components/TheButton.vue";
import DeviceService from "@/modules/Devices/Services/DeviceService";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: { VSelect, InputField, FormDivider, FormWrapper, TheButton },
  props: ["currentDeviceId"],
  emits: ["parent-device-id"],
  data() {
    return {
      deviceService: new DeviceService(),
      toast: useToast(),
      showParentDeviceForm: false,
      identifiers: ["Uuid", "Mac", "Imei", "Imsi", "ExternalId"],
      parentDevice: "No parent device",
      selectedType: "Uuid",
      identifier: "",
    };
  },
  async mounted() {
    if (this.currentDeviceId != null) {
      try {
        const response = await this.deviceService.getDeviceWithIdentifier(
          this.currentDeviceId
        );
        this.parentDevice = response.name;
      } catch (error) {}
    }
  },
  methods: {
    showDeviceParentForm() {
      this.showParentDeviceForm = !this.showParentDeviceForm;
    },
    async getParentDeviceId() {
      if (this.selectedType != null && this.identifier != "") {
        try {
          const response = await this.deviceService.getDeviceWithIdentifierType(
            {
              identifier: this.identifier,
              type: this.selectedType,
            }
          );
          this.parentDevice = response.name;
          this.$emit("parent-device-id", response.id);
        } catch (error) {
          this.clearParentDeviceId();
          this.toast.error(ErrorMessageFormatter(error));
        }
      }
    },
    clearParentDeviceId() {
      this.parentDevice = "No parent device";
      this.$emit("parent-device-id", null);
    },
  },
});
</script>

<style scoped></style>
