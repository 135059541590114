<template>
  <div class="py-4">
    <p class="mb-1 text-md font-semibold">{{ title }}</p>
    <div :class="`border border-gray-300 p-4 rounded-sm`">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  props: ["gridSetup", "style", "title"],
});
</script>
<style scoped></style>
