<template>
  <modal-component
    :is-displayed="isApplyConfirmationModalOpened"
    :no-padding="true"
    title="Apply work order"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="mt-4 space-y-6 px-6 text-center">
      <h4 class="font-bold">Work order apply confirmation</h4>
      <p>
        You are about to apply a work order. This action cannot be undone.
        <br />
        Are you sure you want to proceed?
      </p>
    </div>
    <div class="mt-8 flex">
      <the-button
        :is-disabled="isSubmitLoading"
        size="full"
        text="Cancel"
        variant="outline"
        @execute-method="$emit('toggle-modal')"
      />
      <the-button
        :is-loading="isSubmitLoading"
        size="full"
        text="Apply work order!"
        @execute-method="$emit('apply-work-order', workOrderId)"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: { TheButton, ModalComponent },
  props: {
    isApplyConfirmationModalOpened: Boolean,
    isSubmitLoading: Boolean,
    workOrderId: {
      type: Number,
      required: true,
    },
  },
  emits: ["toggle-modal", "apply-work-order"],
});
</script>

<style scoped></style>
