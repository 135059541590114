import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex h-full w-full items-center justify-center" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.base64Image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: `data:image/png;base64,${_ctx.base64Image}`
        }, null, 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "There is no image to show."))
  ]))
}