<template>
  <div class="h-[calc(100vh_-_9rem)] w-[300px] pb-1">
    <div class="flex h-8 w-full items-center justify-between bg-lightgray px-4">
      <h3>Tags</h3>
      <div :class="`flex h-full items-center justify-end space-x-2 `">
        <font-awesome-icon
          :icon="['fas', 'times']"
          class="cursor-pointer text-darkgrey hover:text-dark"
          @click="$emit('close-tag-actions')"
        />
      </div>
    </div>
    <div class="mb-2 flex h-[7%] w-full space-x-2 p-2">
      <the-button
        v-if="!isTagCreateMode && !isTagEditMode"
        :icon="['fas', 'plus']"
        :text="'Create tag'"
        size="full"
        @execute-method="$emit('toggle-create-tag-modal')"
      />
      <div v-if="isTagCreateMode" class="flex h-full w-full space-x-2">
        <the-button
          :icon="['fas', 'save']"
          :text="$t('Save')"
          size="full"
          @execute-method="$emit('save-tag')"
        />
        <the-button
          :text="$t('Cancel')"
          size="full"
          variant="outline"
          @execute-method="$emit('cancel-creating')"
        />
      </div>
      <div v-if="isTagEditMode" class="flex h-full w-full space-x-2">
        <the-button
          :icon="['fas', 'save']"
          :text="$t('Save')"
          size="full"
          @execute-method="$emit('save-edit-tag')"
        />
        <the-button
          :text="$t('Cancel')"
          size="full"
          variant="outline"
          @execute-method="$emit('cancel-tag-edit-mode')"
        />
      </div>
    </div>
    <custom-table
      :key="tags.length"
      :options="options"
      :remove-table-header="true"
      :table-data="tags"
      :table-headers="tableHeaders"
      table-height="90%"
      @selected-rows="getSelectedRow"
    />
    <!--      <tree-table :table-data="[]" :table-headers="tableHeaders" />-->
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: {
    TheButton,
    CustomTable,
  },
  props: ["tags", "getSelectedRow", "isTagCreateMode", "isTagEditMode"],
  emits: [
    "close-tag-actions",
    "toggle-create-tag-modal",
    "cancel-creating",
    "save-tag",
    "toggle-tag-delete-modal",
    "change-tag-to-edit-mode",
    "cancel-tag-edit-mode",
    "save-edit-tag",
  ],
  data() {
    return {
      tableHeaders: { "tag.name": { header: "Tag", visible: true } },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "fa-pen"],
          action: this.emitChangeFloorplanToEditMode,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "fa-trash"],
          action: this.emitToggleTagDeleteModal,
        },
      },
    };
  },
  watch: {
    tags: {
      handler(newTags) {
        console.log("Tags updated:", newTags);
      },
    },
  },
  methods: {
    emitToggleTagDeleteModal() {
      this.$emit("toggle-tag-delete-modal");
    },
    emitChangeFloorplanToEditMode() {
      this.$emit("change-tag-to-edit-mode");
    },
  },
});
</script>

<style scoped></style>
