<template>
  <div class="flex h-full w-full items-center justify-center">
    <form-wrapper :is-form-in-modal="isFormInModal" :is-loading="isLoading">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field v-model="formData.name" label="Name" />
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >User</label
            >
            <div class="flex w-full justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <div v-else class="flex w-full items-center space-x-4">
                <v-select
                  v-model="formData.externalId"
                  :options="usersForSelect"
                  :reduce="(type : any) => type.id"
                  class="w-full"
                  label="name"
                  :disabled="true"
                  :clearable="false"
                ></v-select>
              </div>
            </div>
          </div>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Device</label
            >
            <div class="flex w-full justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <!-- v-model="formData.data.deviceId" -->
              <v-select
                v-else
                v-model="formData.data.deviceId"
                :get-option-label="
                  (deviceAssignment: any) => deviceAssignment.name
                "
                :reduce="(type : any) => type.deviceId"
                :options="mappedTdceDevices"
                class="w-full"
                :clearable="false"
              ></v-select>
            </div>
          </div>
          <form-group title="Identifiers">
            <div
              v-for="(identifier, index) in formData.data.identifiers"
              class="grid grid-cols-2 gap-x-4"
            >
              <div class="relative items-center py-3">
                <label
                  class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                  >Type</label
                >
                <v-select
                  v-model="identifier.type"
                  :options="identifierTypes"
                  :reduce="(type : any) => type.id"
                  class="mt-1"
                  label="name"
                  :clearable="false"
                ></v-select>
              </div>
              <input-field v-model="identifier.value" label="Value" />
            </div>
          </form-group>
          <form-group title="Access">
            <div v-for="(access, index) in formData.data.accesses">
              <div
                v-if="access.action !== 3"
                :style="{ gridTemplateColumns: '0.5fr 5fr 1fr' }"
                class="grid grid-cols-3 gap-x-4"
              >
                <checkbox-input
                  v-model="access.active"
                  :is-checked="access.active"
                />
                <div class="grid grid-cols-2 gap-x-4">
                  <div class="relative col-span-2 items-center py-3">
                    <label
                      class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                      >Resource</label
                    >
                    <v-select
                      v-model="access.resourceTriggerId"
                      :options="resourcesForSelect"
                      :reduce="(type : any) => type.uuid"
                      class="mt-1"
                      label="name"
                      :clearable="false"
                    ></v-select>
                  </div>
                  <div>
                    <label
                      class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                      >From</label
                    >
                    <dx-date-box
                      v-model="access.dateActiveFrom"
                      :height="42"
                      :show-clear-button="false"
                      display-format="dd/MM/yyyy, HH:mm"
                      type="datetime"
                      :accept-custom-value="false"
                    />
                  </div>
                  <div>
                    <label
                      class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                      >To</label
                    >
                    <dx-date-box
                      v-model="access.dateActiveTo"
                      :height="42"
                      :show-clear-button="true"
                      display-format="dd/MM/yyyy, HH:mm"
                      type="datetime"
                      :accept-custom-value="false"
                    />
                  </div>
                </div>
                <add-delete-buttons
                  :accesses="formData.data.accesses"
                  :index="index"
                  @add-click="addAccess"
                  @remove-click="removeAccess(index)"
                />
              </div>
            </div>
          </form-group>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Loader from "@/core/Components/Loader.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import FormGroup from "@/core/Components/FormElements/FormGroup.vue";
import VSelect from "vue-select";
import moment from "moment";
import AddDeleteButtons from "@/core/Components/FormElements/AddDeleteButtons.vue";
import DxDateBox from "devextreme-vue/date-box";
import ProfileConfigurationService, {
  Access,
  IdentifierType,
} from "../Services/ProfileConfigurationService";
import AccessProfileService, {
  AccessProfileDto,
} from "../../AccessProfiles/Services/AccessProfileService";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import AccessManagerService from "@/modules/AccessManager/Services/AccessManagerService";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { v4 as uuidv4 } from "uuid";
import UserService, { User } from "@/modules/Users/Services/UserService";
import ResourceService from "@/modules/AccessManager/SubModules/Resources/Services/ResourceService";
import DeviceService from "@/modules/Devices/Services/DeviceService";
import { useToast } from "vue-toastification";
import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: {
    MainSection,
    Loader,
    FormWrapper,
    FormDivider,
    InputField,
    FormGroup,
    VSelect,
    AddDeleteButtons,
    DxDateBox,
    CheckboxInput,
    ButtonWrapper,
    ToastComponent,
    SmallLoader,
  },
  props: {
    isFormInModal: Boolean,
    objectId: {
      type: Number,
      required: true,
    },
    assignmentId: {
      type: Number,
      required: true,
    },
    refreshData: Function,
  },
  data() {
    return {
      //New data I added
      resourcesForSelect: [] as any,
      usersForSelect: [] as any,
      mappedTdceDevices: {} as any,
      //Old data
      profileConfigurationService: new ProfileConfigurationService(),
      resourceService: new ResourceService(),
      deviceService: new DeviceService(),
      assignmentService: new AssignmentService(),
      accessProfileService: new AccessProfileService(),
      identifierTypes: [{ id: 1, name: "RFID" }] as IdentifierType[],
      resources: [] as DeviceAssignment[],
      accessProfiles: [] as AccessProfileDto[],
      toast: useToast(),
      accessManagerService: new AccessManagerService(),
      tdceDevices: [] as DeviceAssignment[],
      isLoading: false,
      isSubmitLoading: false,
      userService: new UserService(),
      users: [] as User[],
      formData: {
        assignmentId: 0,
        deviceId: 0,
        name: "",
        applicationId: 185,
        deviceTypeId: Number(
          process.env.VUE_APP_ACCESS_MANAGER_PERMISSION_TYPE_ID
        ),
        externalId: 0,
        uuid: "",
        dataUuid: "",
        parentDeviceId: 0,
        isActive: true,
        data: {
          name: "",
          action: Number(process.env.VUE_APP_EDIT_ACTION),
          deviceId: 1,
          uuid: "",
          identifiers: [
            {
              type: 1,
              value: "",
              action: Number(process.env.VUE_APP_EDIT_ACTION),
              uuid: "",
            },
          ],
          accesses: [
            {
              uuid: "",
              active: true,
              action: Number(process.env.VUE_APP_EDIT_ACTION),
              resourceId: 1,
              resourceTriggerId: "",
              profiles: undefined,
              dateActiveFrom: moment()
                .startOf("day")
                .format("YYYY-MM-DDTHH:mm:ss[Z]"),
              dateActiveTo: moment()
                .endOf("day")
                .format("YYYY-MM-DDTHH:mm:ss[Z]"),
            },
          ],
        },
      } as any,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      // await this.getAllAccessProfiles();
      // await this.getAllIdentifierTypes();
      await Promise.all([
        this.getAllResources(),
        this.getPermission(),
        this.getAllUsers(),
      ]);

      this.tdceDevices = await this.accessManagerService.getAllTdceDevices();

      this.mappedTdceDevices = this.tdceDevices.map((device: any) => ({
        deviceId: device.deviceId,
        assignmentId: device.assignmentId,
        name: device.assignment?.name,
      }));
      /* 
      this.selectedDevice.name = this.tdceDevices[0].assignment?.name;
      this.selectedDevice.deviceId = this.tdceDevices[0].deviceId;
      this.selectedDevice.assignmentId = this.tdceDevices[0].assignmentId; */
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleSubmit() {
      this.formData.data.name = this.formData.name;
      this.formData.data.action = Number(process.env.VUE_APP_EDIT_ACTION);
      this.formData.data.identifiers[0].action = Number(
        process.env.VUE_APP_EDIT_ACTION
      );
      this.formData.data.uuid = this.formData.uuid;
      this.formData.data.accesses.forEach((access: Access) => {
        if (access.action !== 3) {
          access.action = Number(process.env.VUE_APP_EDIT_ACTION);
        }
        return access;
      });
      this.isSubmitLoading = true;

      try {
        const selectedTDCE =
          this.tdceDevices.find(
            (tdce: DeviceAssignment) =>
              this.formData.parentDeviceId === tdce.deviceId
          ) || this.tdceDevices[0];

        if (selectedTDCE) {
          //TODO: Uncomment when edit is thought out
          //await this.deviceService.updateDevice(this.formData);
          try {
            await this.deviceService.updateDevice({
              device: [
                {
                  op: "replace",
                  value: this.formData.data,
                  path: "/data",
                },
              ],
              id: this.formData.deviceId,
            });
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }

          try {
            this.assignmentService.editDeviceAssignment({
              deviceAssignment: [
                {
                  op: "replace",
                  value: this.formData.name,
                  path: "/name",
                },
              ],
              id: this.formData.assignmentId,
            });
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }

          try {
            if (!selectedTDCE.device) {
              this.toast.error("Selected TDCE device is not found!");
              return;
            }

            if (!selectedTDCE.assignment) {
              this.toast.error("Selected TDCE device assignment is not found!");
              return;
            }
            await this.profileConfigurationService.addPermissionToStorageForSync(
              selectedTDCE.device.deviceUuid,
              selectedTDCE.assignment.dataUuid,
              this.formData.data
            );
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }
        }

        this.toast.success(this.$t("General.EditedSuccessfully"));
        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },

    addAccess() {
      this.formData.data.accesses.push({
        active: true,
        action: Number(process.env.VUE_APP_CREATE_ACTION),
        uuid: uuidv4(),
        resourceId: 1,
        resourceTriggerId: this.resourcesForSelect[0].uuid ?? "0",
        profiles: undefined,
        dateActiveFrom: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        dateActiveTo: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
      });
    },
    addIdentifier() {
      this.formData.data.identifiers.push({
        type: 1,
        value: "",
        action: Number(process.env.VUE_APP_CREATE_ACTION),
        uuid: uuidv4(),
      });
    },
    removeAccess(index: number) {
      //Now if user deletes access, we remove it from data.access list
      this.formData.data.accesses.splice(index, 1);
      //There is still check in template if access.action is 2 because of the old logic below
      //which only changed action number: if user decides to bring back deleted access - the new one
      //will be created with action 2 and that didn't make sense.
      /*  this.formData.data.accesses = this.formData.data.accesses.map(
        (access: Access, i: number) => {
          if (index === i) {
            access.action = Number(process.env.VUE_APP_DELETE_ACTION);
          }
          return access;
        }
      ); */
    },
    removeIdentifier(index: number) {
      this.formData.data.identifiers.splice(index, 1);
    },

    async getAllResources() {
      try {
        this.resources = await this.accessManagerService.getCompanyResources();

        this.resourcesForSelect = this.resources.map((item) => ({
          id: item.assignmentId,
          name: item.assignment?.name,
          uuid: item.device?.deviceUuid,
        }));
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllIdentifierTypes() {
      try {
        this.identifierTypes =
          await this.profileConfigurationService.getAllIdentifierTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllAccessProfiles() {
      try {
        this.accessProfiles =
          await this.accessProfileService.getAllAccessProfiles();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllUsers() {
      try {
        const response = await this.userService.getAllUsers(1, 10000);
        this.users = response.items;

        this.usersForSelect = this.users.map((user: any) => ({
          id: user.id,
          name: user.email != null ? user.email : user.username,
        }));

        this.formData.externalId == 0 && this.users[0].id;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getPermission() {
      try {
        /*  await this.profileConfigurationService.getProfileConfigurationWithIdentifier(
            this.objectId
          ); */
        const response =
          await this.profileConfigurationService.getProfileConfigurationWithIdentifier(
            this.assignmentId
          );

        let tdceResponse;
        if (response.assignment?.parentAssignmentId) {
          tdceResponse =
            await this.profileConfigurationService.getProfileConfigurationWithIdentifier(
              response.assignment?.parentAssignmentId
            );
        }

        this.formData.assignmentId = response.assignmentId;
        this.formData.deviceId = response.deviceId;
        this.formData.name = response.assignment?.name;
        this.formData.applicationId = response.assignment?.applicationId; //185 ??
        this.formData.deviceTypeId = response.device?.deviceTypeId; //Number(process.env.VUE_APP_ACCESS_MANAGER_PERMISSION_TYPE_ID)
        this.formData.externalId = Number(response.device?.externalId);
        this.formData.uuid = response.device?.deviceUuid;
        this.formData.dataUuid = response.assignment?.dataUuid;
        this.formData.parentDeviceId = tdceResponse?.deviceId;
        this.formData.isActive = response.assignment?.isActive;
        //Data
        if (this.formData.data != null) {
          this.formData.data.name = response.device?.data.name;
          this.formData.data.deviceId = response.device?.data.deviceId;
          this.formData.data.uuid = response.device?.data.uuid;
          this.formData.data.identifiers = response.device?.data.identifiers;
          this.formData.data.accesses = response.device?.data.accesses;
        } else {
          this.formData.data = {};
          this.formData.data.identifiers = [{ type: 1, value: "" }];
          this.formData.data.accesses = [
            {
              active: true,
              resourceId: 1,
              profiles: undefined,
              resourceTriggerId: "",
              dateActiveFrom: moment()
                .startOf("day")
                .format("YYYY-MM-DDTHH:mm:ss[Z]"),
              dateActiveTo: moment()
                .endOf("day")
                .format("YYYY-MM-DDTHH:mm:ss[Z]"),
            },
          ];
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>
<style scoped></style>
