<template>
  <modal-component
    :is-displayed="isManageParkingSpotsModalOpened"
    :is-full-screen-modal="true"
    :no-padding="true"
    title="Manage spots"
    @toggle-modal="$emit('toggle-modal')"
  >
    <info-modal
      v-if="isInfoModalOpened"
      :is-info-modal-opened="isInfoModalOpened"
      @toggle-modal="toggleInfoModal"
    />
    <map-loader v-if="isLoading" />

    <div ref="modalContent" class="px-6">
      <div id="map" class="z-[150] my-auto h-[700px] w-full">
        <div class="manage-spots-distance-box">
          <div>
            Distance between spots:
            {{
              this.markerSelectService.distanceBetweenSpots == undefined ? 0 : this.markerSelectService.distanceBetweenSpots .toFixed(2)
            }}m
          </div>
          <div>
            Total distance:
            {{
              this.markerSelectService.distanceBetweenFirstAndLastSelected ==
              undefined
                ? 0
                : this.markerSelectService.distanceBetweenFirstAndLastSelected.toFixed(
                    2
                  )
            }}m
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between py-4">
        <div class="flex items-center space-x-4">
          <the-button
            button-type="outlineButton"
            text="Info"
            @execute-method="toggleInfoModal"
          />
          <the-button button-type="outlineButton" text="Recalculate names" />
          <the-button
            button-type="outlineButton"
            text="Reset parking spots position"
            @execute-method="resetParkingSpotPositions"
          />
          <the-button 
            button-type="primaryButton" 
            text="Edit" 
            :is-disabled="markerSelectService.isEditButtonDisabled"
          />
        </div>
        <div class="flex items-center space-x-4">
          <the-button
            :is-loading="isLoading"
            button-type="primaryButton"
            text="Save"
            @execute-method="savePositions"
          />
          <the-button
            button-type="outlineButton"
            text="Close"
            @execute-method="$emit('toggle-modal')"
          />
        </div>
      </div>
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import MapService from "@/modules/MapView/Services/MapService";
import MarkerService from "@/modules/MapView/Services/MarkerService";
import L from "leaflet";
import TheButton from "@/core/Components/TheButton.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { POSITION, useToast } from "vue-toastification";
import {
  ParkingSpot,
  UpdateParkingSpot,
} from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import MarkerSelectService from "@/modules/MapView/Services/MarkerSelectService";
import { MarkerOptionsExtended } from "node_modules/@types/leaflet/index.d.ts";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import MapLoader from "@/modules/MapView/Components/MapLoader.vue";
import { SelectMapIcon } from "@/modules/Parkom/Services/DashboardHelpers";
import InfoModal from "./InfoModal.vue";

export default defineComponent({
  components: { MapLoader, TheButton, ModalComponent, InfoModal },
  props: {
    isManageParkingSpotsModalOpened: {
      type: Boolean,
      required: true,
    },
    parkingSpaceId: {
      type: Number,
      required: true,
    }
  },
  emits: ["toggle-modal"],
  data() {
    return {
      mapService: {} as MapService,
      markerService: {} as MarkerService,
      markerSelectService: {} as MarkerSelectService,
      parkingSpotService: new ParkingSpotService(),
      parkingSpaceService: new ParkingSpaceService(),
      parkingSpots: [] as ParkingSpot[],
      parkingSpace: {} as ParkingSpace,
      spotsToEdit: [] as UpdateParkingSpot[],
      toast: useToast(),
      isLoading: false,
      selectedSpotIndexList: [] as number[],
      parkingSpotMap: new Map<number, ParkingSpot>(),
      isInfoModalOpened: false,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getParkingSpace();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  async mounted() {
    this.mapService = new MapService(
      document.getElementById("map") as HTMLElement
    );
    this.mapService.initMap();
    if (!(this.mapService.map instanceof L.Map)) {
      return;
    }
    this.markerService = new MarkerService(this.mapService.map);
    try {
      await this.getParkingSpaceParkingSpots();
      this.parkingSpots = this.parkingSpots.sort((a, b) => a.index - b.index);
      this.parkingSpots.forEach((parkingSpot) => {
        this.markerService.addMarker({
          lat: parkingSpot.latitude,
          lng: parkingSpot.longitude,
          groupKey: "PARKOM",
          icon: {
            iconUrl: `${
              process.env.VUE_APP_PUBLIC_PATH
            }map-icons/${SelectMapIcon({
              occupied: true,
              offline: false,
              typeId: parkingSpot.typeId,
            })}`,
            iconSize: undefined,
          },
          markerOptions: { id: parkingSpot.id },
          bindPopup: true,
          eventHandlers: {
            click: (event) => {
              if (this.markerSelectService.isCtrlPressed) {
                event.target.closePopup();
                const markerOptions = event.target
                  .options as MarkerOptionsExtended;
                const markerIndex =
                  this.markerSelectService.spaceParkingSpotMarkers.findIndex(
                    (marker) =>
                      (marker.options as MarkerOptionsExtended).id ===
                      markerOptions.id
                  );
                this.selectedSpotIndexList.push(markerIndex);
                return;
              }

              event.target
                .setPopupContent(
                  `
                <div>
                    <div><span>Space: </span><strong>${parkingSpot.space}</strong></div>
                    <div><span>Name: </span><strong>${parkingSpot.name}</strong></div>
                    <div><span>Index: </span><strong>${parkingSpot.index}</strong></div>
                    <div><span>Type: </span><strong>${parkingSpot.type}</strong></div>
                </div>`
                )
                .openPopup();
            },
          },
        });
        this.parkingSpotMap.set(parkingSpot.id, parkingSpot);
      });

      this.markerService.fitGroupToMap("PARKOM");
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
    }

    this.markerSelectService = new MarkerSelectService(
      this.mapService.map,
      this.markerService.markers as L.Marker[],
      this.onDragEnd,
      this.parkingSpotMap
    );

    if (!this.markerSelectService) {
      return;
    }

    this.markerSelectService.addControlKeyEventListener();
    this.markerSelectService.subscribeToParkingSpotPositionEdits();

    (this.$refs.modalContent as HTMLElement).addEventListener(
      "keyup",
      (evt) => {
        if (evt.code === "Space") {
          this.markerSelectService.resetMarkersToDeselectedState();          
          this.markerSelectService.isEditButtonDisabled = true;
        }
      }
    );
  },

  watch: {
    "selectedSpotIndexList.length"(length: number) {
      if (length === 2) {
        this.markerSelectService.selectParkingSpotsOnCtrlClick(
          this.selectedSpotIndexList[0],
          this.selectedSpotIndexList[1]
        );

        this.selectedSpotIndexList = [];
      }
    },
  },

  unmounted() {
    this.markerSelectService.removeCtrlKeyEventListener();
  },

  methods: {
    async savePositions() {
      this.isLoading = true;
      try {
        await this.parkingSpotService.bulkParkingSpotEdit({
          parkingSpots: this.spotsToEdit,
          spaceId: this.parkingSpaceId,
        });
        this.toast.success("Parking spots positions saved!");
        this.$emit("toggle-modal");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isLoading = false;
      }
    },
    async getParkingSpace() {
      try {
        this.parkingSpace =
          await this.parkingSpaceService.getParkingSpaceByIdentifier(
            this.parkingSpaceId
          );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getParkingSpaceParkingSpots() {
      try {
        this.parkingSpots =
          await this.parkingSpotService.getParkingSpotsForMapBySpaceId(
            this.parkingSpaceId
          );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async resetParkingSpotPositions() {
      this.markerSelectService.recalculateParkingSpotLocationsByParkingSpaceLocation(
        {
          lat: this.parkingSpace.latitude,
          lng: this.parkingSpace.longitude,
          markers: this.markerService.markers as L.Marker[],
        }
      );

      this.prepareParkingSpotsForBulkUpdate(
        this.markerService.markers as L.Marker[]
      );

      this.toast.success(
        "Parking spots reset to default positions! Press Save button to apply changes",
        {
          position: POSITION.BOTTOM_CENTER,
        }
      );
    },

    onDragEnd() {
      this.prepareParkingSpotsForBulkUpdate(
        this.markerSelectService.selectedParkingSpots as L.Marker[]
      );
    },

    prepareParkingSpotsForBulkUpdate(markers: L.Marker[]) {
      markers.forEach((marker) => {
        const { lat, lng } = marker.getLatLng();
        const options = marker.options as MarkerOptionsExtended;

        const parkingSpot = this.parkingSpots.find(
          (parkingSpot) => parkingSpot.id === options.id
        );

        if (!parkingSpot) {
          return;
        }

        let indexOfElement = this.spotsToEdit.findIndex(
          (element: any) => element.id === parkingSpot.id
        );

        if (indexOfElement !== -1) {
          this.spotsToEdit[indexOfElement].latitude = lat;
          this.spotsToEdit[indexOfElement].longitude = lng;
        } else {
          parkingSpot.latitude = lat;
          parkingSpot.longitude = lng;
          this.spotsToEdit.push({
            id: parkingSpot.id,
            latitude: parkingSpot.latitude,
            longitude: parkingSpot.longitude,
            name: parkingSpot.name,
            spaceId: parkingSpot.spaceId,
            typeId: parkingSpot.typeId,
            index: parkingSpot.index,
            public: parkingSpot.public,
            visible: parkingSpot.visible,
          });
        }
      });
    },
    toggleInfoModal() {
      this.isInfoModalOpened = !this.isInfoModalOpened;
    },
  },
});
</script>

<style scoped>
#map:focus {
  border: none;
  outline: none;
}
</style>
