<template>
  <modal
    :hide-overflow="true"
    :is-displayed="isShareModalOpened"
    :is-select-modal="true"
    :no-padding="true"
    :have-modal="haveModal"
    title="Share device"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form @submit.prevent="handleSubmit">
      <form-divider>
        <input-field
          v-model="formData.userEmail"
          :errorMessage="v$?.formData['userEmail']?.$errors[0]?.$message"
          :isError="v$?.formData['userEmail']?.$error"
          :required="true"
          label="Email"
          placeholder="Input user email..."
        />
      </form-divider>
      <button-wrapper :is-submit-loading="isSubmitLoading" />
    </form>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Modal from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import { email, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import { useUserStore } from "@/core/Store/userStore";
import { ShareDevice } from "@/core/Services/SpotiumService";

export default defineComponent({
  components: {
    Modal,
    FormWrapper,
    InputField,
    TheButton,
    FormDivider,
    ButtonWrapper,
    CheckboxInput,
  },
  props: ["isSubmitLoading", "deviceData", "isShareModalOpened", "haveModal"],
  emits: ["toggle-modal", "on-submit"],
  data() {
    return {
      userStore: useUserStore(),
      v$: useValidate() as any,
      validationErrors: [] as any,
      isShareToYourselfChecked: false,
      formData: {
        userEmail: "",
      } as ShareDevice,
    };
  },
  watch: {
    isShareModalOpened: function (newValue, oldValue) {
      if (newValue === false) {
        this.formData.userEmail = "";
      }
    },
  },
  validations() {
    return {
      formData: {
        userEmail: {
          required,
          email,
        },
      },
    };
  },
  methods: {
    handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.$emit("on-submit", {
          userEmail: this.formData.userEmail,
          assignmentId: this.deviceData.assignmentId,
        });
      }
    },
  },
});
</script>
<style scoped></style>
