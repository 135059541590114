import { Role } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/RoleTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class RoleService {
  async getAllRoles({
    pageNumber = 1,
    pageSize = 10,
    allRoles = false,
  }: {
    pageNumber: number;
    pageSize: number;
    serviceId?: number;
    allRoles?: boolean;
  }): Promise<Role[]> {
    const url = GenerateUrl({
      path: `/api/authorization/roles`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        AllRoles: allRoles ? "true" : "false",
      },
    });

    const response = await ApiService.get(url);

    return this._mapRoles(response.data.items);
  }

  private _mapRoles(response: Role[]): Role[] {
    return response.map((role) => this._mapRole(role));
  }

  private _mapRole(role: Role): Role {
    return {
      id: role.id,
      name: role.name,
      description: role.description,
    };
  }
}
