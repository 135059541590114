import {
  MapObject,
  MapObjectCreate,
  ObjectTypes,
} from "@/modules/MapView/Services/Types/MapObjectTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export class MapObjectService {
  async getAllMapObjects({
    pageNumber,
    pageSize,
    companyId,
    floorLevel,
    typeId,
  }: {
    pageNumber: number;
    pageSize: number;
    companyId: number;
    floorLevel?: number;
    typeId?: ObjectTypes;
  }): Promise<MapObject[]> {
    const url = GenerateUrl({
      path: "/api/map-objects",
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        CompanyId: companyId,
        ...(floorLevel && { FloorLevel: floorLevel }),
        ...(typeId && { TypeId: typeId }),
      },
    });

    const response = await ApiService.get(url);
    return response.data.items;
  }

  async getAllAvailableFloors(companyId: number): Promise<number[]> {
    const url = GenerateUrl({
      path: "/api/floor-levels",
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
      config: {
        CompanyId: companyId,
      },
    });

    const response = await ApiService.get(url);

    return response.data;
  }

  async createMapObject(mapObject: MapObjectCreate): Promise<number> {
    const url = GenerateUrl({
      path: "/api/map-objects",
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    const response = await ApiService.post(url, mapObject);
    return response.data;
  }

  async updateMapObject(id: number, mapObject: PatchObject[]) {
    const url = GenerateUrl({
      path: `/api/map-objects/${id}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, mapObject);
  }

  async deleteMapObject(id: number) {
    const url = GenerateUrl({
      path: `/api/map-objects/${id}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }
}
