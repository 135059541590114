import { RouteRecordRaw } from "vue-router";
import Index from "../Pages/Index.vue";
import DevicesIndex from "../Submodules/Devices/Pages/Index.vue";
import DeviceMetadataIndex from "../Submodules/DeviceMetadata/Pages/Index.vue";
import DeviceTypeIndex from "../Submodules/DeviceTypes/Pages/Index.vue";
import DeviceTypeMetadataIndex from "../Submodules/DeviceTypeMetadata/Pages/Index.vue";
import FirmwareUpdateIndex from "../Submodules/FirmwareUpdate/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createProductionIndexRoute(): RouteRecordRaw {
  return {
    name: "productionIndex",
    path: "/",
    component: Index,
    redirect: {
      name: "deviceTypesIndex",
    },
    meta: {
      id: "productionIndex",
      label: "Sidebar.Production",
      icon: ["fas", "microchip"],
      showInNavigation: true,
      authorize: [Permissions.ViewDevice, Permissions.ViewDeviceType],
    },
    children: [
      {
        name: "devicesIndex",
        path: "devices",
        component: DevicesIndex,
        meta: {
          id: "devicesIndex",
          label: "Sidebar.Devices",
          icon: ["fas", "tablet-alt"],
          showInNavigation: true,
          authorize: [Permissions.ViewDevice],
        },
      },
      {
        name: "deviceMetadataIndex",
        path: "device-metadata",
        component: DeviceMetadataIndex,
        meta: {
          id: "deviceMetadataIndex",
          label: "Sidebar.DeviceMetadata",
          icon: ["fas", "file-code"],
          showInNavigation: true,
          authorize: [Permissions.ViewDevice],
        },
      },
      {
        name: "deviceTypesIndex",
        path: "device-types",
        component: DeviceTypeIndex,
        meta: {
          id: "deviceTypesIndex",
          label: "Sidebar.DeviceTypes",
          icon: ["fas", "tablet-alt"],
          showInNavigation: true,
          authorize: [Permissions.ViewDeviceType],
        },
      },
      {
        name: "deviceTypesMetadataIndex",
        path: "device-types-metadata",
        component: DeviceTypeMetadataIndex,
        meta: {
          id: "deviceTypesMetadataIndex",
          label: "Sidebar.DeviceTypeMetadata",
          icon: ["fas", "file-code"],
          showInNavigation: true,
          authorize: [Permissions.ViewDeviceType],
        },
      },
      {
        name: "firmwareUpdateIndex",
        path: "firmware-update",
        component: FirmwareUpdateIndex,
        meta: {
          id: "firmwareUpdateIndex",
          label: "Sidebar.FirmwareUpdate",
          icon: ["fas", "upload"],
          showInNavigation: true,
          authorize: [Permissions.ViewFirmwareUpdate],
        },
      },
    ],
  };
}
