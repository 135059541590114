<template>
  <last-data-preview
    v-if="isDataPreviewModalOpened"
    :data="dataForModal.data"
    :is-last-data-modal-opened="isDataPreviewModalOpened"
    :name="selectedMetadata[0]?.name"
    :preview="true"
    @toggle-modal="isDataPreviewModalOpened = false"
  />
  <create-form
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getDeviceTypeMetadata"
    @toggle-modal="isCreateModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :device-type-metadata="selectedMetadata[0]"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getDeviceTypeMetadata"
    @toggle-modal="isEditModalOpened = false"
  />

  <delete-modal
    v-if="isDeleteModalOpened"
    :is-deleting="isDeleteModalOpened"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    delete-text="delete"
    @confirm-action="deleteDeviceTypeMetadata"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />

  <main-section>
    <loader v-if="isLoading" />
    <div v-else>
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateDeviceType"
        :title="$t('DeviceTypeMetadata.Title')"
        description=" "
        button-text="Create"
        @execute-method="toggleCreateModal"
      />

      <custom-table
        :options="options"
        :table-data="deviceTypeMetadata"
        :table-headers="tableHeaders"
        @force-refresh="getDeviceTypeMetadata()"
        @open-data-modal="displayData"
        @selected-rows="getSelectedRow"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import { DeviceTypeMetadataService } from "@/modules/Production/Submodules/DeviceTypeMetadata/Services/DeviceTypeMetadataService";
import { DeviceTypeMetadata } from "@/modules/Production/Submodules/DeviceTypeMetadata/Services/DeviceTypeMetadataTypes";
import Loader from "@/core/Components/Loader.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import LastDataPreview from "@/modules/Devices/Submodules/Assignments/Components/LastDataPreview.vue";
import TheButton from "@/core/Components/TheButton.vue";
import CreateForm from "../Components/CreateForm.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import EditForm from "../Components/EditForm.vue";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    DeleteModal,
    ConfirmationModal,
    TheButton,
    LastDataPreview,
    CustomTable,
    TableActionHeader,
    Loader,
    MainSection,
    CreateForm,
    EditForm,
  },
  data() {
    return {
      deviceTypeMetadataService: new DeviceTypeMetadataService(),
      deviceTypeMetadata: [] as DeviceTypeMetadata[],
      toast: useToast(),
      permissions: Permissions,
      dataForModal: {} as any,
      selectedMetadata: [] as DeviceTypeMetadata[],
      isDataPreviewModalOpened: false,
      isLoading: false,
      isDataSourceLoading: false,
      isCreateModalOpened: false,
      isEditModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      tableHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "Name", visible: true },
        "metadataType.name": { header: "Metadata Type", visible: true },
        data: { header: "Data", visible: true },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditDeviceType,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteDeviceType,
          action: this.toggleDeleteModal,
        },
      },
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getDeviceTypeMetadata();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async getDeviceTypeMetadata() {
      this.isDataSourceLoading = true;
      try {
        this.deviceTypeMetadata =
          await this.deviceTypeMetadataService.getDeviceTypeMetadata();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async deleteDeviceTypeMetadata() {
      try {
        await this.deviceTypeMetadataService.deleteDeviceTypeMetadata(
          this.selectedMetadata[0].id
        );
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isConfirmationModalOpened = false;
        this.isDeleteModalOpened = false;
        await this.getDeviceTypeMetadata();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getSelectedRow(selectedRow: DeviceTypeMetadata[]) {
      this.selectedMetadata = selectedRow;
    },
    displayData(data: any) {
      this.isDataPreviewModalOpened = true;
      this.dataForModal = data;
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
    },
  },
});
</script>

<style scoped></style>
