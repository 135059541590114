import { RouteRecordRaw } from "vue-router";
import StaticActions from "../Pages/StaticActions.vue";
import DynamicActions from "../Pages/DynamicActions.vue";
import Index from "../Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createDeviceActionIndexRoute(): RouteRecordRaw {
  return {
    name: "deviceActions",
    path: "/device-actions",
    component: Index,
    redirect: {
      name: "staticDeviceActions",
    },
    meta: {
      id: "deviceActions",
      label: "Sidebar.DeviceActions",
      icon: ["fas", "repeat"],
      showInNavigation: true,
      authorize: [Permissions.ViewDeviceAction],
    },
    children: [
      {
        name: "staticDeviceActions",
        path: "static",
        component: StaticActions,
        meta: {
          id: "staticDeviceActions",
          label: "Sidebar.StaticDeviceActions",
          icon: ["fas", "repeat"],
          showInNavigation: true,
          authorize: [Permissions.ViewDeviceAction],
        },
      },
      {
        name: "dynamicDeviceActions",
        path: "dynamic",
        component: DynamicActions,
        meta: {
          id: "dynamicDeviceActions",
          label: "Sidebar.DynamicDeviceActions",
          icon: ["fas", "repeat"],
          showInNavigation: true,
          dontDisplayOnMainNavigation: true,
          authorize: [Permissions.ViewDeviceAction],
        },
      },
    ],
  };
}
