<template>
  <div class="h-[calc(100vh_-_16rem)] w-[350px]">
    <div class="mb-2 flex h-[8%] w-full space-x-2 p-2">
      <the-button
        v-if="!isFloorplanCreateMode && !isFloorplanEditMode"
        :icon="['fas', 'plus']"
        :text="$t('CreateFloorplan')"
        size="full"
        @execute-method="emitToggleCreateFloorplanModal"
      />
      <div v-if="isFloorplanCreateMode" class="flex h-full w-full space-x-2">
        <the-button
          :icon="['fas', 'save']"
          :text="$t('Save')"
          size="full"
          @execute-method="emitSaveFloorplan"
        />
        <the-button
          :text="$t('Cancel')"
          size="full"
          variant="outline"
          @execute-method="emitCancelFloorplanCreateMode"
        />
      </div>
      <div v-if="isFloorplanEditMode" class="flex h-full w-full space-x-2">
        <the-button
          :icon="['fas', 'save']"
          :text="$t('Save')"
          size="full"
          @execute-method="emitSaveEditFloorplan"
        />
        <the-button
          :text="$t('Cancel')"
          size="full"
          variant="outline"
          @execute-method="emitCancelFloorplanEditMode"
        />
      </div>
    </div>
    <custom-table
      :options="options"
      :remove-table-header="true"
      :table-data="floorplans"
      :table-headers="tableHeaders"
      table-height="90%"
      @selected-rows="getSelectedRow"
    />
    <!--      <tree-table :table-data="[]" :table-headers="tableHeaders" />-->
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: {
    TheButton,
    CustomTable,
  },
  props: [
    "floorplans",
    "getSelectedRow",
    "isFloorplanCreateMode",
    "isFloorplanEditMode",
  ],
  emits: [
    "toggle-create-floorplan-modal",
    "save-floorplan",
    "cancel-floorplan-create-mode",
    "save-edit-floorplan",
    "cancel-floorplan-edit-mode",
    "change-floorplan-to-edit-mode",
    "toggle-floorplan-delete-modal",
    "toggle-tag-actions",
  ],
  data() {
    return {
      tableHeaders: { "data.name": { header: "Floor plan", visible: true } },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "fa-pen"],
          action: this.emitChangeFloorplanToEditMode,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "fa-trash"],
          action: this.emitToggleFloorplanDeleteModal,
        },
        view: {
          header: "Action.Markers",
          icon: ["fas", "map-pin"],
          action: this.emitToggleTagActions,
        },
      },
    };
  },
  methods: {
    emitToggleCreateFloorplanModal() {
      this.$emit("toggle-create-floorplan-modal");
    },
    emitSaveFloorplan() {
      this.$emit("save-floorplan");
    },
    emitCancelFloorplanCreateMode() {
      this.$emit("cancel-floorplan-create-mode");
    },
    emitSaveEditFloorplan() {
      this.$emit("save-edit-floorplan");
    },
    emitCancelFloorplanEditMode() {
      this.$emit("cancel-floorplan-edit-mode");
    },
    emitChangeFloorplanToEditMode() {
      this.$emit("change-floorplan-to-edit-mode");
    },
    emitToggleFloorplanDeleteModal() {
      this.$emit("toggle-floorplan-delete-modal");
    },
    emitToggleTagActions() {
      this.$emit("toggle-tag-actions");
    },
  },
});
</script>

<style scoped></style>
