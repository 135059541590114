<template>
  <modal-component
    :is-displayed="isDeleteRelationModalOpened"
    :no-padding="true"
    :title="$t('AssignmentMetadata.DeleteRelationTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <last-data-preview
      v-if="isDataPreviewModalOpened"
      :data="selectedMetadata"
      :is-last-data-modal-opened="isDataPreviewModalOpened"
      :name="selectedMetadata.name"
      :preview="true"
      @toggle-modal="isDataPreviewModalOpened = false"
    />
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <div class="space-y-4 px-6 py-6">
          <div
            v-for="data in assignmentMetadata"
            class="flex w-full items-center justify-between"
          >
            <p
              class="cursor-pointer hover:underline"
              @click="() => previewMetadata(data)"
            >
              {{ data.name }}
            </p>
            <font-awesome-icon
              :icon="['fas', 'trash']"
              class="cursor-pointer hover:text-error"
              @click="() => deleteRelation(data.id)"
            />
          </div>
        </div>
        <button-wrapper />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { DeviceTypeMetadata } from "@/modules/Production/Submodules/DeviceTypeMetadata/Services/DeviceTypeMetadataTypes";
import LastDataPreview from "@/modules/Devices/Submodules/Assignments/Components/LastDataPreview.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";
import { AssignmentMetadata } from "@/modules/Devices/Submodules/AssignmentMetadata/Services/AssignmentMetadataTypes";
import AssignmentMetadataService from "@/modules/Devices/Submodules/AssignmentMetadata/Services/AssignmentMetadataService";

export default defineComponent({
  components: {
    FontAwesomeIcon,
    LastDataPreview,
    ButtonWrapper,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isDeleteRelationModalOpened: Boolean,
    metadata: {
      type: Array as PropType<DeviceTypeMetadata[] | undefined>,
      required: true,
    },
    assignmentId: Number,
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      assignmentMetadataService: new AssignmentMetadataService(),
      assignmentMetadata: [] as AssignmentMetadata[],
      selectedMetadata: {} as AssignmentMetadata,
      toast: useToast(),
      isDataPreviewModalOpened: false,
      metadataRelationIdsToDelete: [] as number[],
    };
  },
  created() {
    this.assignmentMetadata = Object.assign([], this.metadata);
  },
  methods: {
    async handleSubmit() {
      try {
        for (let relationId of this.metadataRelationIdsToDelete) {
          await this.assignmentMetadataService.deleteAssignmentMetadataRelation(
            {
              assignmentMetadataId: relationId,
              assignmentIdList: [this.assignmentId ?? 0],
            }
          );
        }
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.$emit("toggle-modal");
        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    deleteRelation(metadataId: number) {
      this.assignmentMetadata = this.assignmentMetadata.filter(
        (metadata) => metadata.id !== metadataId
      );
      this.metadataRelationIdsToDelete.push(metadataId);
    },
    previewMetadata(data: AssignmentMetadata) {
      this.selectedMetadata = data;
      this.isDataPreviewModalOpened = true;
    },
  },
});
</script>

<style scoped></style>
