import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "h-full"
}
const _hoisted_2 = { class: "flex h-[46px] gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sensor_sent_packets = _resolveComponent("sensor-sent-packets")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_dx_date_box = _resolveComponent("dx-date-box")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isSensorPacketsSentDisplay)
      ? (_openBlock(), _createBlock(_component_sensor_sent_packets, {
          key: 0,
          "is-sensor-packets-sent-displayed": _ctx.isSensorPacketsSentDisplay,
          mac: _ctx.selectedSensor.mac,
          title: `${_ctx.selectedSensor.space} ${_ctx.selectedSensor.spot} - ${_ctx.selectedSensor.mac}`,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSensorPacketsSentDisplay = false))
        }, null, 8, ["is-sensor-packets-sent-displayed", "mac", "title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                title: _ctx.$t('Sidebar.SensorHealth')
              }, null, 8, ["title"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_dx_date_box, {
                  modelValue: _ctx.fromDate,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fromDate) = $event)),
                  height: 46,
                  "show-clear-button": true,
                  "display-format": "dd.MM.yyyy HH:mm",
                  type: "datetime"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_dx_date_box, {
                  modelValue: _ctx.toDate,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.toDate) = $event)),
                  height: 46,
                  "show-clear-button": true,
                  "display-format": "dd.MM.yyyy HH:mm",
                  type: "datetime"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_the_button, {
                  icon: ['fas', 'rotate-left'],
                  "button-type": "primaryButton",
                  onExecuteMethod: _ctx.getHealthStatus
                }, null, 8, ["onExecuteMethod"])
              ]),
              _createVNode(_component_custom_table, {
                "allow-auto-resize": true,
                "is-data-source-loading": _ctx.isDataSourceLoading,
                "render-summary": ['name'],
                "show-per-page": 16,
                "table-data": _ctx.healths,
                "table-headers": _ctx.tableHeaders,
                "table-height": "90%",
                onForceRefresh: _ctx.getHealthStatus,
                isActionListNotDisplayed: true,
                onViewSensorPackets: _ctx.toggleSensorSentPackets
              }, null, 8, ["is-data-source-loading", "table-data", "table-headers", "onForceRefresh", "onViewSensorPackets"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}