import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.themeStore.themeClass)
  }, [
    (!_ctx.isMaintenance)
      ? (_openBlock(), _createBlock(_component_HeaderBar, {
          key: 0,
          onToggleSidebar: _ctx.sidebarStore.toggleSidebar
        }, null, 8, ["onToggleSidebar"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isMaintenance)
        ? (_openBlock(), _createBlock(_component_SidebarMenu, {
            key: 0,
            "is-sidebar-opened": _ctx.sidebarStore.isSidebarOpened,
            "sidebar-store": _ctx.sidebarStore
          }, null, 8, ["is-sidebar-opened", "sidebar-store"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view)
    ])
  ], 2))
}