<template>
  <div>
    <create-form
      v-if="isCreateModalOpened"
      :is-create-modal-opened="isCreateModalOpened"
      :refresh-data="getAllUsers"
      @toggle-modal="isCreateModalOpened = false"
    />

    <show-user-info-modal
      v-if="isShowUserInfoModalOpened"
      :selected-data="selectedUser"
      :is-create-modal-opened="isShowUserInfoModalOpened"
      @toggle-modal="isShowUserInfoModalOpened = false"
    />

    <delete-modal
      :is-deleting="isRemoveUserModalOpened"
      :is-submit-loading="isDataSourceLoading"
      :is-user-remove="true"
      :selected-data="selectedUser"
      delete-text="Are you sure you want to remove user "
      @cancel-delete="isRemoveUserModalOpened = false"
      @toggle-modal="isRemoveUserModalOpened = false"
      @confirm-delete="toggleConfirmationModal"
    />
    <confirmation-modal
      :is-confirmation-modal-opened="isConfirmationModalOpened"
      delete-text="removeUser"
      @close-confirmation-modal="isConfirmationModalOpened = false"
      @toggle-modal="isConfirmationModalOpened = false"
      @confirm-action="removeUserFromCompany"
    />
    <main-section>
      <loader
        v-if="isLoading"
        inner-ring-thickness="border-t-8"
        outer-ring-thickness="border-8"
      />
      <div v-else>
        <table-action-header
          :icon="['fas', 'add']"
          :permission-id="permissions.CreateCompanyUser"
          :title="$t('Users.Title')"
          button-text="AddUser"
          description="Users are the people who will be using the platform. They can be assigned to one or more companies. List shows all users in the current company."
          @execute-method="toggleCreateModal"
        />

        <custom-table
          :is-data-source-loading="isDataSourceLoading"
          :options="options"
          :table-data="users"
          :table-headers="tableHeaders"
          @selected-rows="getSelectedRows"
          @force-refresh="getAllUsers"
        />
      </div>
    </main-section>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import UserService, { User, UsersObject } from "../Services/UserService";
import Loader from "@/core/Components/Loader.vue";
import TheButton from "@/core/Components/TheButton.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import {
  CheckIfThereIsError,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import Modal from "@/core/Components/Modal.vue";
import CreateForm from "../Components/CreateForm.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import CompanyService from "@/modules/Companies/Services/CompanyService";
import { useUserStore } from "@/core/Store/userStore";
import { useToast } from "vue-toastification";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import SpotiumService from "@/core/Services/SpotiumService";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import ShowUserInfoModal from "../Components/ShowUserInfoModal.vue";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    TableActionHeader,
    ConfirmationModal,
    DeleteModal,
    MainSection,
    Loader,
    TheButton,
    CustomTable,
    Modal,
    CreateForm,
    ShowUserInfoModal,
  },
  data() {
    return {
      userService: new UserService(),
      companyService: new CompanyService(),
      spotiumService: new SpotiumService(),
      usersPagination: {} as UsersObject,
      userStore: useUserStore(),
      changeCompanyStore: useChangeCompanyStore(),
      users: [] as User[],
      isLoading: false as boolean,
      index: 1 as number,
      isCreateModalOpened: false,
      isRemoveUserModalOpened: false,
      isConfirmationModalOpened: false,
      isShowUserInfoModalOpened: false,
      toast: useToast(),
      permissions: Permissions,
      selectedRows: [] as User[],
      selectedUser: {} as User,
      isDataSourceLoading: false,
      tableHeaders: {
        id: { header: "ID", visible: false },
        email: { header: "Users.Email", visible: true },
        username: { header: "Users.Username", visible: true },
        userRole: { header: "Users.Role", visible: true },
      },
      options: {
        removeUser: {
          header: "Action.RemoveUserFromOrganization",
          icon: ["fas", "user-slash"],
          permissionId: Permissions.DeleteCompanyUser,
          action: this.toggleRemoveUserModal,
        },
        openUserId: {
          header: "User information",
          icon: ["fas", "info"],
          permissionId: Permissions.ViewCompanyUser,
          action: this.toggleShowUserInfo,
        },
      },
    };
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getAllUsers();
    },
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getAllUsers();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    checkIfThereIsError: CheckIfThereIsError,

    async getAllUsers() {
      this.isDataSourceLoading = true;
      try {
        this.usersPagination = await this.userService.getAllUsersWithRoles(
          1,
          10000
        );
        this.users = this.usersPagination.items;
        this.isCreateModalOpened = false;
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async removeUserFromCompany() {
      this.isDataSourceLoading = true;
      try {
        this.isConfirmationModalOpened = false;
        await this.spotiumService.removeUserFromCompany({
          companyId: this.userStore.activeCompanyId,
          userId: this.selectedUser.id,
        });
        this.toast.success(this.$t("Users.UserRemovedSuccess"));
        this.isRemoveUserModalOpened = false;
        await this.getAllUsers();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    getSelectedRows(values: User[]) {
      this.selectedRows = values;
      if (values.length) {
        this.selectedUser = values[0];
      }
    },

    toggleConfirmationModal() {
      this.isConfirmationModalOpened = !this.isConfirmationModalOpened;
    },

    createUser() {
      this.toggleCreateModal();
    },

    toggleRemoveUserModal() {
      this.isRemoveUserModalOpened = !this.isRemoveUserModalOpened;
    },

    toggleShowUserInfo() {
      this.isShowUserInfoModalOpened = !this.isShowUserInfoModalOpened;
    },

    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
  },
});
</script>
<style scoped></style>
