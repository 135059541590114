import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`grid h-full w-full ${
      _ctx.noGap ? 'gap-0' : 'gap-4'
    } ${_ctx.generateGridColTemplateString()} ${_ctx.generateGridRowTemplateString()} ${
      _ctx.centerItems && 'place-items-center'
    } ${_ctx.padding}`)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}