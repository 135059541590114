<template>
  <section
    :class="`z-1 relative transform duration-300 ease-in-out ${
      sidebarStore.isSidebarOpened ? 'section-class' : 'section-class-closed'
    } ${
      !isMap && 'p-4 py-8'
    }  relative overflow-y-auto overflow-x-hidden bg-sectionbackground`"
  >
    <h1 v-if="title" class="mb-4 text-2xl font-bold tracking-tighter text-dark">
      {{ title }}
    </h1>
    <slot></slot>
  </section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useSidebarStore } from "@/core/Store/index";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  name: "MainSection",
  components: { FontAwesomeIcon },
  props: {
    isMap: Boolean,
    title: String,
  },
  data() {
    return {
      sidebarStore: useSidebarStore(),
    };
  },
});
</script>
<style>
.section-class {
  height: calc(100vh - 4rem);
  width: calc(100vw - 16rem);
  margin-top: 4.5rem;
}

.section-class-closed {
  height: calc(100vh - 4rem);
  width: calc(100vw - 3rem);
  margin-top: 4.5rem;
}

.section-class::-webkit-scrollbar-track,
.section-class-closed::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.section-class::-webkit-scrollbar,
.section-class-closed::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.section-class::-webkit-scrollbar-thumb,
.section-class-closed::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  @apply bg-primary;
}
</style>
