<template>
  <div>
    <modal
      :have-modal="haveModal"
      :is-displayed="isChangeApplicationOpened"
      :is-select-modal="true"
      :no-padding="true"
      title="Change device group"
      @toggle-modal="$emit('toggle-modal')"
    >
      <form-wrapper
        :is-form-in-modal="isFormInModal"
        :is-loading="isLoading"
        :need-for-scroll="true"
      >
        <form @submit.prevent="handleSubmit">
          <form-divider>
            <div class="relative items-center py-4">
              <label
                class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                >Group</label
              >
              <v-select
                v-model="selectedApplicationId"
                :class="`mt-1`"
                :options="applications"
                :reduce="(spot : any) => spot.id"
                label="name"
              ></v-select>
            </div>
          </form-divider>
          <button-wrapper :is-submit-loading="isSubmitLoading" />
        </form>
      </form-wrapper>
    </modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Modal from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import VSelect from "vue-select";
import DeviceService from "../../../Services/DeviceService";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";

export default defineComponent({
  components: {
    Modal,
    FormWrapper,
    InputField,
    TheButton,
    VSelect,
    FormDivider,
    ButtonWrapper,
  },
  props: [
    "applications",
    "applicationId",
    "isSubmitLoading",
    "isChangeApplicationOpened",
    "isLoading",
    "isFormInModal",
    "haveModal",
  ],
  emits: ["toggle-modal", "on-submit"],

  data() {
    return {
      selectedApplicationId: this.applicationId,
      deviceService: new DeviceService(),
      deviceId: Number((this as any).$route.params.id),
    };
  },

  methods: {
    async handleSubmit() {
      this.$emit("on-submit", [
        {
          op: "replace",
          value: this.selectedApplicationId,
          path: "/applicationId",
        },
      ]);
    },
  },
});
</script>
<style scoped></style>
