import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative px-6" }
const _hoisted_2 = { class: "mb-2 flex items-center gap-1 text-center text-primary" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex items-center gap-1 text-center" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  id: "code",
  class: "line-numbers"
}
const _hoisted_8 = { class: "language-javascript" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal = _resolveComponent("modal")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_modal, {
    "have-modal": _ctx.haveModal,
    "is-displayed": true,
    title: `Developer options for asset ${_ctx.selectedDevice.assignment.name}`,
    onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(`w-[50%] cursor-pointer border-b-4 border-primary py-1 duration-200 ease-in-out hover:transform hover:font-semibold hover:opacity-100 ${
            _ctx.selectedCategory === category.name
              ? `font-semibold opacity-100`
              : `opacity-60`
          }`),
              onClick: ($event: any) => (_ctx.setCategory(category.name))
            }, _toDisplayString(category.label), 11, _hoisted_3))
          }), 256))
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.selectedCategory === _ctx.categories[0].name)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.languages, (language) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(`w-[50%] cursor-pointer py-1 duration-200 ease-in-out hover:transform hover:font-semibold hover:opacity-100 ${
            _ctx.selectedLanguage === language.name
              ? `bg-primary text-white`
              : `bg-primary text-white opacity-40`
          }`),
                  onClick: ($event: any) => (_ctx.setLanguage(language.name))
                }, _toDisplayString(language.label), 11, _hoisted_5))
              }), 256))
            : _createCommentVNode("", true),
          (_ctx.selectedCategory === _ctx.categories[1].name)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getLanguages, (getLanguage) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(`w-[50%] cursor-pointer py-1 duration-200 ease-in-out hover:transform hover:font-semibold hover:opacity-100 ${
            _ctx.selectedGetLanguage === getLanguage.name
              ? `bg-primary text-white`
              : `bg-primary text-white opacity-40`
          }`),
                  onClick: ($event: any) => (_ctx.setGetLanguage(getLanguage.name))
                }, _toDisplayString(getLanguage.label), 11, _hoisted_6))
              }), 256))
            : _createCommentVNode("", true)
        ]),
        _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "group absolute right-10 top-20 z-10 mt-3 flex h-8 w-8 cursor-pointer items-center justify-center rounded-md bg-primaryhover text-white",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyToClipboard && _ctx.copyToClipboard(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'paste'],
            class: "opacity-80 group-hover:opacity-100"
          })
        ])), [
          [_directive_tippy, {
          arrow: false,
          placement: 'bottom-end',
          content: 'Copy to clipboard',
          animateFill: false,
          theme: 'translucent',
        }]
        ]),
        _createElementVNode("pre", _hoisted_7, [
          _createElementVNode("code", _hoisted_8, _toDisplayString(_ctx.selectedCategory == "pushData" ? _ctx.code[_ctx.selectedLanguage] : _ctx.code[_ctx.selectedGetLanguage]), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["have-modal", "title"]))
}