<template>
  <div>
    <section
      class="flex h-screen w-screen items-center justify-center bg-sectionbackground"
    >
      <img
        src="../../../assets/spotium-ikone/splash_logo_corner.svg"
        class="absolute right-0 top-0 h-72 w-72"
      />
      <div
        class="maintenance-card relative h-[70vh] w-8/12 overflow-hidden rounded-md bg-white"
      >
        <div class="flex h-[6rem] w-full items-center justify-center space-x-2">
          <div class="text-sm">
            <h2 class="font-bold text-primary">SPOTIUM</h2>
            <p>Powered by SICK Mobilisis</p>
          </div>
        </div>
        <div
          class="maintenance-content -mt-10 flex flex-col items-center justify-center"
        >
          <img
            class="h-32 w-32"
            src="../../../assets/spotium-ikone/maintenance.svg"
          />
          <div class="mx-auto w-7/12 space-y-6">
            <h2 class="text-center text-6xl font-bold text-[#444]">
              The site is currently down for maintenance
            </h2>
            <p class="text-center text-[#888]">
              We apologize for any inconveniences caused.
            </p>
          </div>
        </div>
        <div class="h-10 w-full bg-primary">
          <div
            class="mx-auto flex h-full w-7/12 items-center justify-center space-x-6 text-white"
          >
            <p>SICK Mobilisis d.o.o</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  components: {},
});
</script>
<style scoped>
.maintenance-card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.maintenance-content {
  width: 100%;
  height: calc(100% - 6rem);
}
</style>
