<template>
  <div v-click-away="isDropdownOpened && toggleDropdown" class="relative">
    <svg
      :class="`cursor-pointer fill-darkgrey hover:fill-primary ${
        isDropdownOpened && 'fill-primary'
      }`"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
      @click="toggleDropdown"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"
      />
    </svg>
    <div
      :class="`absolute right-0 top-8 z-40 h-auto w-[250px] origin-top transform space-y-2 rounded-lg border-2 border-grey bg-white py-2 px-1 text-sm  shadow-lg duration-300 ease-in-out ${
        isDropdownOpened ? 'scale-y-100' : 'scale-y-0'
      }`"
    >
      <div class="w-full space-y-2 divide-y-2 divide-grey px-4">
        <div class="text-xl font-bold text-primary">
          {{ $t(getSelectedLanguageName()) }}
        </div>
        <div class="flex items-center justify-between pt-1">
          <p
            v-for="language in languages"
            :class="`cursor-pointer text-darkgrey hover:text-primary ${
              language.code === translation.currentLocale && 'text-primary'
            }`"
            @click="() => changeLanguage(language.code)"
          >
            {{ $t(language.name) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Translation from "@/core/i18n/Translations";
import { useUserStore } from "@/core/Store/userStore";
import { Languages } from "@/core/Services/GlobalTypes";

export default defineComponent({
  data() {
    return {
      userStore: useUserStore(),
      isDropdownOpened: false,
      translation: Translation,
      languages: [
        {
          name: "Languages.English",
          code: "en",
        },
        {
          name: "Languages.Croatian",
          code: "hr",
        },
        {
          name: "Languages.Slovakian",
          code: "sk",
        },
      ],
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },

    changeLanguage(languageCode: Languages) {
      Translation.switchLanguage(languageCode);
      this.userStore.setUserLanguage(languageCode);
    },

    getSelectedLanguageName() {
      return this.languages.find(
        (language) => language.code === Translation.currentLocale
      )!.name;
    },
  },
});
</script>

<style scoped></style>
