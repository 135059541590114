import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-6" }
const _hoisted_2 = {
  key: 0,
  class: "flex w-full justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isViewDevicesModalOpened,
    title: _ctx.$t('WorkOrders'),
    "remove-outside-click": true
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_small_loader)
            ]))
          : (_openBlock(), _createBlock(_component_custom_table, {
              key: 1,
              "is-action-list-not-displayed": true,
              "table-data": _ctx.workOrderDevices,
              "table-headers": _ctx.tableHeaders
            }, null, 8, ["table-data", "table-headers"]))
      ])
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}