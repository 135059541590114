import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-[250px] border-2 border-grey absolute z-40 h-auto right-0 top-8 shadow-lg bg-white py-2 px-1 rounded-lg space-y-2 text-sm transform  ease-in-out duration-300 origin-top ${
        _ctx.isDropdownOpened ? 'scale-y-100' : 'scale-y-0'
      }`)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}