<template>
  <modal
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    :title="$t('Groups.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="flex h-full w-full items-center justify-center">
      <form-wrapper :is-form-in-modal="true">
        <form @submit.prevent="handleSubmit">
          <form-divider>
            <input-field
              v-model="formData.name"
              :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
              :isError="v$?.formData['name']?.$error"
              :label="$t('Groups.Name')"
              :required="true"
            />
          </form-divider>
          <button-wrapper :is-submit-loading="isSubmitLoading" />
        </form>
      </form-wrapper>
    </div>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "../../../core/Components/MainSection.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "../../../core/Components/FormElements/InputField.vue";
//@ts-ignore
import VSelect from "vue-select";
import TheButton from "../../../core/Components/TheButton.vue";
import ApplicationService from "../Services/ApplicationService";
import Modal from "@/core/Components/Modal.vue";
import SelectTable from "@/core/Components/SelectTable.vue";
import ToastComponent from "../../../core/Components/Notifications/ToastComponent.vue";
import Loader from "../../../core/Components/Loader.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import { useToast } from "vue-toastification";
import { Company } from "@/modules/Companies/Services/CompanyService";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    MainSection,
    FormWrapper,
    InputField,
    VSelect,
    TheButton,
    Modal,
    SelectTable,
    ToastComponent,
    Loader,
    ButtonWrapper,
    FormDivider,
  },
  props: { refreshData: Function, isCreateModalOpened: Boolean },
  emits: ["toggle-modal"],
  data() {
    return {
      applicationService: new ApplicationService(),
      companies: [] as Company[],
      isSelectModalOpened: false,
      modalTitle: "",
      isSubmitLoading: false,
      toast: useToast(),
      v$: useValidate() as any,
      validationErrors: [] as any,
      tableHeaders: {
        id: "ID",
        name: "Name",
      },
      formData: {
        name: "",
        /*    companyId: 1, */
      },
    };
  },
  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
      },
    };
  },

  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          await this.applicationService.createApplication(this.formData);
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          await this.refreshData?.();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
  },
});
</script>
<style scoped></style>
