<template>
  <modal-component :is-displayed="isUsersRejectApproveShareModalOpened"
                   custom-modal-size="w-6/12"
                   title="Pending requests"
                   @toggle-modal="$emit('toggle-modal')">
    <confirmation-modal :is-confirmation-modal-opened="isConfirmationModalOpened"
                        :object-name="rejectApproveObject.userEmail" delete-text="revoke"
                        @confirm-action="rejectApproveShare"
                        @close-confirmation-modal="isConfirmationModalOpened = false"
                        @toggle-modal="isConfirmationModalOpened = false"/>
    <div class="px-6">
      <div v-if="isLoading" class="w-full flex items-center justify-center">
        <small-loader/>
      </div>
      <custom-table v-else :is-action-list-not-displayed="true" :is-reject-approve-action-displayed="true"
                    :table-data="users"
                    :table-headers="tableHeaders" @reject-approve-action="toggleConfirmationModal"/>
    </div>
  </modal-component>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import UserService, {UserShareAssignment} from "@/modules/Users/Services/UserService";
import SpotiumService from "@/core/Services/SpotiumService";
import {useToast} from "vue-toastification";
import {ErrorMessageFormatter} from "@/core/Services/GlobalFunctions";
import CustomTable from "@/core/Components/CustomTable.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";

export default defineComponent({
  components: {CustomTable, ModalComponent, SmallLoader, ConfirmationModal},
  props: {
    isUsersRejectApproveShareModalOpened: Boolean,
    assignmentId: {
      type: Number,
      required: true
    }
  },
  emits: ["toggle-modal", "refresh-data"],
  data() {
    return {
      userService: new UserService(),
      spotiumService: new SpotiumService(),
      users: [] as UserShareAssignment[],
      toast: useToast(),
      isLoading: false,
      isConfirmationModalOpened: false,
      isSubmitLoading: false,
      isDataSourceLoading: false,
      rejectApproveObject: {} as { status: "reject" | "approve", userEmail: string },
      tableHeaders: {
        email: {header: "Email", visible: true},
        "activeCompany.name": {header: "Company", visible: true},
      }
    }
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getUsers();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getUsers() {
      this.isDataSourceLoading = true;
      try {
        this.users = await this.userService.getAllUsersThatDeviceIsSharedTo({
          assignmentId: this.assignmentId,
          pageSize: 100000,
          pageNumber: 1,
          typeOfShare: "Pending"
        })
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    toggleConfirmationModal({status, userEmail}: { status: "reject" | "approve", userEmail: string }) {
      this.rejectApproveObject = {status, userEmail};
      this.isConfirmationModalOpened = !this.isConfirmationModalOpened;
    },
    async rejectApproveShare() {
      if (this.rejectApproveObject.status === "reject") {
        await this.rejectShare();
      } else if (this.rejectApproveObject.status === "approve") {
        await this.approveShare()
      }
      if (!this.users.length) {
        this.$emit("toggle-modal");
      }

      this.$emit("refresh-data");
    },
    async rejectShare() {
      try {
        await this.spotiumService.revokeShareFromUser({
          assignmentId: this.assignmentId,
          userEmail: this.rejectApproveObject.userEmail
        })
        this.isConfirmationModalOpened = false;
        this.toast.success("Share request rejected successfully!");
        await this.getUsers();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
      }
    },
    async approveShare() {
      try {
        await this.spotiumService.approveShare({
          assignmentId: this.assignmentId,
          userEmail: this.rejectApproveObject.userEmail
        })
        this.isConfirmationModalOpened = false;
        this.toast.success("Share request approved successfully!");
        await this.getUsers();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
      }
    },
  },

});
</script>

<style scoped></style>

