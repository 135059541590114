<template>
  <div class="flex h-full w-full flex-col items-center justify-center px-6">
    <input-field v-model="objectName" :no-padding="true" label="Name" />
    <input-field v-model="selectedFloor" label="Floor" type="number" />
    <label
      class="mt-4 flex w-full cursor-pointer flex-col items-center justify-center space-x-3 border border-dashed border-primary p-8"
      for="file-upload"
    >
      <font-awesome-icon
        v-if="fileSrc === ''"
        :icon="['fas', 'image']"
        class="text-6xl text-primary"
      />
      <img v-else :src="fileSrc" class="w-5/12" />
      <h2 v-if="fileName === ''" class="mt-2">Choose a file to upload</h2>
      <h2 v-else>{{ fileName }}</h2>
    </label>
    <input id="file-upload" class="hidden" type="file" @change="getImageData" />
    <div class="mt-4 w-full">
      <the-button
        size="full"
        text="Upload image"
        variant="default"
        @execute-method="imageSubmit"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { FileToBase64 } from "@/core/Services/GlobalFunctions";
import { defineComponent, PropType } from "vue";
import TheButton from "../TheButton.vue";
import InputField from "./InputField.vue";
import VSelect from "vue-select";
import FloorplansService from "@/modules/MapView/Services/Floorplan/FloorplansService";

export default defineComponent({
  components: {
    TheButton,
    InputField,
    VSelect,
  },

  props: {
    floorplanService: {
      type: Object as PropType<FloorplansService>,
      required: true,
    },
  },

  emits: ["change-to-create-mode", "toggle-modal"],

  data() {
    return {
      file: null as FileList | null,
      fileName: "",
      fileSrc: "",
      objectName: "",
      selectedFloor: 1,
      img: {} as { width: number; height: number },
      base64URL: {} as any,
      floors: [
        {
          id: "floor0",
          name: "Ground floor",
        },
        {
          id: "floor1",
          name: "Floor 1",
        },
        {
          id: "floor2",
          name: "Floor 2",
        },
        {
          id: "floor3",
          name: "Floor 3",
        },
      ],
    };
  },
  methods: {
    async getImageData(event: any) {
      const files = event.target.files;
      this.file = files;
      if (files.length) {
        this.fileName = files[0].name;
        this.fileSrc = URL.createObjectURL(files[0]);
      }

      let img = new Image();
      img.src = window.URL.createObjectURL(event.target.files[0]);
      img.onload = () => {
        this.img.width = img.width;
        this.img.height = img.height;
      };
    },

    async imageSubmit() {
      if (this.file === null) {
        return;
      }
      this.base64URL = await FileToBase64(this.file[0]);
      await this.floorplanService.addFloorPlanToMap({
        imageUrl: this.base64URL,
        name: this.objectName,
        floorId: this.selectedFloor,
        width: this.img.width,
        height: this.img.height,
        imgFile: this.file[0],
      });
      this.$emit("change-to-create-mode", true);
      this.$emit("toggle-modal");
    },
  },
});
</script>
<style scoped></style>
