<template>
  <div
      :class="`${width} ${height} ${outerRingThickness} ${innerRingThickness}`"
      class="small-loader  ease-linear rounded-full border-gray-200 mb-4 border-b-primary"
  ></div>
</template>
<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    width: {
      type: String,
      default: "w-12",
    },
    height: {
      type: String,
      default: "h-12",
    },
    outerRingThickness: {
      type: String,
      default: "border-4",
    },
    innerRingThickness: {
      type: String,
      default: "border-t-4",
    },
  },
});
</script>
<style>
.small-loader {
  @apply border-t-primary;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
