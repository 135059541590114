import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_send_message_modal = _resolveComponent("send-message-modal")!
  const _component_messages_view_modal = _resolveComponent("messages-view-modal")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isSendMessageModalOpened)
      ? (_openBlock(), _createBlock(_component_send_message_modal, {
          key: 0,
          "is-send-message-modal-opened": _ctx.isSendMessageModalOpened,
          "refresh-data": _ctx.getResponseMessages,
          "asset-ids": _ctx.assetIds,
          onRefreshData: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getResponseMessages())),
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isSendMessageModalOpened = false))
        }, null, 8, ["is-send-message-modal-opened", "refresh-data", "asset-ids"]))
      : _createCommentVNode("", true),
    (_ctx.isMessagesViewModalOpened)
      ? (_openBlock(), _createBlock(_component_messages_view_modal, {
          key: 1,
          "is-modal-opened": _ctx.isMessagesViewModalOpened,
          "asset-id": _ctx.selectedResponseMessage?.id,
          onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isMessagesViewModalOpened = false))
        }, null, 8, ["is-modal-opened", "asset-id"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                "button-text": _ctx.$t('ResponseMessages.SendMessage'),
                icon: ['fas', 'add'],
                title: _ctx.$t('ResponseMessages.Title'),
                "permission-id": _ctx.permissions.CommitAssignmentDeviceAction,
                description: " ",
                onExecuteMethod: _ctx.toggleSendMessageModal
              }, null, 8, ["button-text", "title", "permission-id", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                options: _ctx.options,
                "is-data-source-loading": _ctx.isDataSourceLoading,
                "table-data": _ctx.paginatedResponseMessages.items,
                "table-headers": _ctx.tableHeaders,
                multiselect: true,
                onSelectedRows: _ctx.getSelectedRow,
                onForceRefresh: _cache[3] || (_cache[3] = ($event: any) => (_ctx.getResponseMessages()))
              }, null, 8, ["options", "is-data-source-loading", "table-data", "table-headers", "onSelectedRows"]),
              _createVNode(_component_pagination, {
                "pagination-data": _ctx.paginatedResponseMessages,
                onPageChange: _ctx.handlePageChange,
                onPageSizeChange: _ctx.handlePageSizeChange
              }, null, 8, ["pagination-data", "onPageChange", "onPageSizeChange"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}