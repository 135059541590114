<template>
  <div class="flex h-full w-full items-center justify-center">
    <img v-if="base64Image" :src="`data:image/png;base64,${base64Image}`" />
    <div v-else>There is no image to show.</div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    base64Image: String,
  },
  data() {
    return {};
  },
  methods: {},
});
</script>
<style scoped></style>
