<template>
  <modal-component
    :is-displayed="isViewDevicesModalOpened"
    :title="$t('WorkOrders')"
    :remove-outside-click="true"
  >
    <div class="px-6">
      <div v-if="isLoading" class="flex w-full justify-center">
        <small-loader />
      </div>
      <custom-table
        v-else
        :is-action-list-not-displayed="true"
        :table-data="workOrderDevices"
        :table-headers="tableHeaders"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import { WorkOrderDevice } from "@/modules/WorkOrders/Services/WorkOrderTypes";
import WorkOrderService from "@/modules/WorkOrders/Services/WorkOrderService";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: { CustomTable, SmallLoader, ModalComponent },
  props: {
    isViewDevicesModalOpened: {
      type: Boolean,
    },
    workOrderId: {
      type: Number,
      required: true,
    },
  },
  //emits: ["toggle-modal"],
  data() {
    return {
      isLoading: false,
      workOrderService: new WorkOrderService(),
      workOrderDevices: [] as WorkOrderDevice[],
      toast: useToast(),
      tableHeaders: {
        "device.name": { header: "WorkOrders.Name", visible: true },
        "device.mac": { header: "MAC", visible: true },
      },
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getWorkOrderDevices();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async getWorkOrderDevices() {
      try {
        this.workOrderDevices = await this.workOrderService.getWorkOrderDevices(
          {
            workOrderId: this.workOrderId,
            pageSize: 100000,
            pageNumber: 1,
          }
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
