import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import {
  CreateResponseMessage,
  LastResponseMessagesObject,
  ResponseMessagesObject,
  LastResponseMessage,
} from "@/modules/ResponseMessages/Services/ResponseMessagesTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";
import DeviceDataService, {
  DeviceLatestData,
} from "@/modules/Devices/Services/DeviceDataService";

export default class ResponseMessagesService {
  lastDataSent = [] as DeviceLatestData[];
  deviceDataService = new DeviceDataService();

  async getResponseMessages(
    pageNumber: number = 1,
    pageSize: number = 10
  ): Promise<LastResponseMessagesObject> {
    const url = GenerateUrl({
      path: "/api/company/response-messages/device-assignments",
      prefixPath: ConfigurationService.configData.FIRMWARE_UPDATE_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

    const response = await ApiService.get(url);

    const uuids = response.data.items.map(
      (responseMessage: LastResponseMessage) => responseMessage.deviceUuid
    );

    if (uuids.length) {
      this.lastDataSent = await this.deviceDataService.getDevicesLatestData({
        deviceUuids: uuids,
      });
    }

    return {
      items: this._mapDevicesLatestData(response.data.items),
      pageIndex: response.data.pageIndex,
      totalPages: response.data.totalPages,
      totalCount: response.data.totalCount,
      hasPreviousPage: response.data.hasPreviousPage,
      hasNextPage: response.data.hasNextPage,
    };
  }

  async getAllAssetResponseMessages(
    pageNumber: number = 1,
    pageSize: number = 10,
    assetId: number
  ): Promise<ResponseMessagesObject> {
    const url = GenerateUrl({
      path: `/api/company/response-messages/device-assignments/${assetId}`,
      prefixPath: ConfigurationService.configData.FIRMWARE_UPDATE_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortOrder: "Descending",
        SortBy: "CreatedAt",
      },
    });

    const response = await ApiService.get(url);

    return response.data;
  }

  async sendResponseMessage(message: CreateResponseMessage) {
    const url = GenerateUrl({
      path: "/api/company/response-messages",
      prefixPath: ConfigurationService.configData.FIRMWARE_UPDATE_PREFIX_PATH,
    });

    const response = await ApiService.post(url, message);

    return response.data;
  }

  private _mapDevicesLatestData(
    responseMessages: LastResponseMessage[]
  ): LastResponseMessage[] {
    return responseMessages.map((responseMessage) =>
      this._mapDeviceLatestData(responseMessage)
    );
  }

  private _mapDeviceLatestData(
    responseMessage: LastResponseMessage
  ): LastResponseMessage {
    return {
      id: responseMessage.id,
      assetName: responseMessage.assetName,
      deviceId: responseMessage.deviceId,
      deviceUuid: responseMessage.deviceUuid,
      mac: responseMessage.mac,
      imei: responseMessage.imei,
      imsi: responseMessage.imsi,
      serialNumber: responseMessage.serialNumber,
      responseMessages: responseMessage.responseMessages,
      createdAt: responseMessage.createdAt,
      updatedAt: responseMessage.updatedAt,
      ...(this.lastDataSent.length && {
        lastTimeDeviceSentData: this.findTheNewestLastTime(
          responseMessage.deviceUuid
        ),
      }),
    };
  }

  private findTheNewestLastTime(deviceUuid: string) {
    const data = this.lastDataSent.find(
      (sentData) => sentData.deviceUuid === deviceUuid
    )?.data;

    let newestDate;
    if (data) {
      newestDate = data.reduce((latest, current) => {
        const latestDate = new Date(latest.dateTime);
        const currentDate = new Date(current.dateTime);

        return latestDate > currentDate ? latest : current;
      }, data[0]).dateTime;
    }

    return newestDate;
  }
}
