<template>
  <div class="flex h-full w-full items-center justify-center">
    <form-wrapper :is-form-in-modal="isFormInModal" :is-loading="isLoading">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="formData.data.name"
            label="Name"
            placeholder="Enter resource name..."
          />
          <input-field
            v-model="formData.data.shortName"
            label="Short name"
            placeholder="Enter resource short name..."
          />
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Device</label
            >
            <div class="flex w-full justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="selectedAssignment"
                :get-option-label="
                  (deviceAssignment: any) => deviceAssignment.name
                "
                :options="mappedTdceDevices"
                class="w-full"
                :clearable="false"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Type</label
            >
            <v-select
              v-model="formData.data.type"
              :options="resourceTypes"
              :reduce="(type : any) => type.id"
              class="mt-1"
              label="name"
              :clearable="false"
            ></v-select>
          </div>
          <Vue3JsonEditor
            v-model="formData.data.configuration"
            mode="code"
            @json-change="onConfigurationChange"
          />
          <checkbox-input
            v-model="formData.data.active"
            :is-checked="formData.data.active"
            label="Active"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Loader from "@/core/Components/Loader.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import VSelect from "vue-select";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import ResourceService, { SpotiumResource } from "../Services/ResourceService";
import ResourceTypeService, {
  ResourceType,
} from "../Services/ResourceTypeService";
//@ts-ignore
import JsonEditorVue from "json-editor-vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { Vue3JsonEditor } from "vue3-json-editor";
import DeviceService from "@/modules/Devices/Services/DeviceService";
import AccessManagerService from "@/modules/AccessManager/Services/AccessManagerService";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { useUserStore } from "@/core/Store/userStore";
import { useToast } from "vue-toastification";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import AssignmentService from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: {
    MainSection,
    Loader,
    FormWrapper,
    FormDivider,
    ButtonWrapper,
    InputField,
    VSelect,
    CheckboxInput,
    JsonEditorVue,
    Vue3JsonEditor,
    SmallLoader,
  },
  props: {
    isFormInModal: Boolean,
    objectId: { type: Number },
    refreshData: Function,
  },
  data() {
    return {
      selectedAssignment: {} as {
        deviceId: number | null;
        assignmentId: number | null;
        name?: string;
      },
      mappedTdceDevices: {},
      isLoading: false,
      isSubmitLoading: false,
      resourceService: new ResourceService(),
      assignmentService: new AssignmentService(),
      deviceService: new DeviceService(),
      resourceTypeService: new ResourceTypeService(),
      tdceDevices: [] as DeviceAssignment[],
      accessManagerService: new AccessManagerService(),
      resourceTypes: [
        { id: 1, name: "Ramp" },
        { id: 2, name: "Gate" },
      ] as ResourceType[],
      toast: useToast(),
      userStore: useUserStore(),
      formData: {
        assignmentId: 0,
        deviceId: 0,
        name: "",
        uuid: "",
        dataUuid: "",
        applicationId: 0,
        parentDeviceId: 0,
        deviceTypeId: 0,
        isActive: false,
        deviceCompanyId: 0,
        data: {
          id: 0,
          name: "",
          shortName: "",
          type: 0,
          deviceId: 1,
          action: 2,
          active: false,
          configuration: {},
          topic: "",
        },
      } as /*DeviceEdit*/ any,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getResource();
      this.tdceDevices = await this.accessManagerService.getAllTdceDevices();
      this.mappedTdceDevices = this.tdceDevices.map((device: any) => ({
        deviceId: device.deviceId,
        assignmentId: device.assignmentId,
        name: device.assignment?.name,
      }));

      this.selectedAssignment.name = this.tdceDevices[0].assignment?.name;
      this.selectedAssignment.deviceId = this.tdceDevices[0].deviceId;
      this.selectedAssignment.assignmentId = this.tdceDevices[0].assignmentId;
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        this.formData.data.topic = `${process.env.VUE_APP_MQTT_USERNAME}/imei`;
        this.formData.deviceCompanyId = this.userStore.activeCompanyId;
        this.formData.name = this.formData.data.name;
        this.formData.uuid = this.formData.data.uuid;

        this.formData.isActive = this.formData.data.active;
        this.formData.parentDeviceId = this.selectedAssignment.assignmentId;
        this.formData.data.deviceId = this.selectedAssignment.deviceId;
        this.formData.data.action = 2;

        const selectedTDCE = this.tdceDevices.find(
          (tdce: DeviceAssignment) =>
            this.formData.data.deviceId === tdce.deviceId
        );

        if (selectedTDCE) {
          await this.deviceService.updateDevice({
            device: [
              {
                op: "replace",
                value: this.formData.data,
                path: "/data",
              },
            ],
            id: this.formData.deviceId,
          });

          if (!selectedTDCE.device) {
            this.toast.error("No device found!");
            return;
          }

          if (!selectedTDCE.assignment) {
            this.toast.error("No assignment found!");
            return;
          }

          try {
            this.assignmentService.editDeviceAssignment({
              deviceAssignment: [
                {
                  op: "replace",
                  value: this.formData.name,
                  path: "/name",
                },
              ],
              id: this.formData.assignmentId,
            });
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }

          await this.resourceService.addResourceToStorageForSync(
            selectedTDCE.device.deviceUuid,
            selectedTDCE.assignment.dataUuid,
            this.formData.data
          );
        }
        this.toast.success(this.$t("General.EditedSuccessfully"));
        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },

    async getResource() {
      try {
        if (this.objectId) {
          const response = await this.resourceService.getResourceWithIdentifier(
            this.objectId
          );

          this.formData.data = response.device?.data;
          this.formData.uuid = this.formData.data.uuid;
          this.formData.deviceId = response.deviceId;
          this.formData.assignmentId = response.assignmentId;
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getAllResourceTypes() {
      try {
        this.resourceTypes =
          await this.resourceTypeService.getAllResourceTypes();
      } catch (error) {}
    },

    onConfigurationChange(configuration: any) {
      this.formData.data.configuration = configuration;
    },
  },
});
</script>
<style>
.jsoneditor-main.focus.svelte-9i9wy0 {
  box-shadow: none;
}
</style>
