<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="m-8">
      <label
        :class="`flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 p-8 transition duration-500 ${
          isDragging
            ? 'border-dashed border-primary '
            : 'border-dashed border-grey'
        }`"
        for="file-upload"
        @dragover.prevent="handleDragOver"
        @drop.prevent="handleDrop"
        @dragleave.prevent="handleDragLeave"
      >
        <font-awesome-icon
          :icon="['fas', 'file-code']"
          class="mb-6 text-8xl text-primary"
        />
        <div class="text-center">
          <h2 v-if="fileName === ''" class="mb-1 text-xl font-semibold">
            Drag & Drop file here or
            <span class="text-primary">browse</span>
          </h2>
          <h2 class="text-xl font-semibold" v-else>{{ fileName }}</h2>
          <p>File must be BIN and up to 2MB</p>
        </div>
      </label>
      <input
        id="file-upload"
        accept=".bin"
        class="hidden"
        type="file"
        @change="handleFile"
      />
    </div>
    <the-button
      :text="$t('General.Upload')"
      size="full"
      @execute-method="handleSubmit"
    />
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import TheButton from "@/core/Components/TheButton.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import FirmwareUpdateService from "@/modules/Production/Submodules/FirmwareUpdate/Services/FirmwareUpdateService";

export default defineComponent({
  components: { TheButton, ModalComponent },
  props: { isCreateModalOpened: Boolean },
  emits: ["toggle-modal", "refresh-data"],
  data() {
    return {
      firmwareUpdateService: new FirmwareUpdateService(),
      file: null as File | null,
      fileName: "",
      toast: useToast(),
      isSubmitLoading: false,
      isDragging: false,
    };
  },
  methods: {
    handleFile(e: any) {
      this.file = e.target.files[0];

      if (!this.file) {
        return;
      }

      this.fileName = this.file.name;
    },

    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        if (!this.file) {
          return;
        }

        if (this.file.size > 2_000_000) {
          throw new Error("File size is too big");
        }

        if (!this.file.name.endsWith(".bin")) {
          throw new Error(
            "File type is not supported. Only .bin files are allowed."
          );
        }

        await this.firmwareUpdateService.uploadFirmwareFile(this.file);
        this.toast.success("File uploaded successfully!");
        this.$emit("refresh-data");
        this.$emit("toggle-modal");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    handleDragOver(e: DragEvent) {
      this.isDragging = true;
    },
    handleDragLeave(e: DragEvent) {
      this.isDragging = false;
    },
    handleDrop(e: DragEvent) {
      this.isDragging = false;

      if (e.dataTransfer && e.dataTransfer.files.length > 0) {
        this.file = e.dataTransfer.files[0];
        this.fileName = this.file.name;
      }
    },
  },
});
</script>

<style scoped></style>
