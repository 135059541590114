import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  ref: "movableElement",
  class: "absolute bottom-10 left-80 z-[10000] min-w-[250px] bg-white shadow-xl"
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute right-0 top-0" }
const _hoisted_4 = { class: "bg-gray-200 px-4 py-2" }
const _hoisted_5 = { class: "p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_font_awesome_icon, {
          class: "rounded-md p-1.5 hover:bg-primaryhover",
          icon: ['fas', 'xmark'],
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeDataCard')))
        })
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.selectedDevice?.assignment?.name), 1),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (value, key) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createTextVNode(_toDisplayString(value.label) + ": ", 1),
            _createElementVNode("strong", null, _toDisplayString(value.value), 1)
          ]))
        }), 256))
      ])
    ])
  ], 512))
}