<template>
  <div class="flex justify-center">
    <div class="relative h-[70vh] w-full self-center bg-white" id="map"></div>
  </div>
</template>
<script lang="ts">
import MapService from "@/modules/MapView/Services/MapService";
import MarkerService from "@/modules/MapView/Services/MarkerService";
import PolylineService from "@/modules/MapView/Services/PolylineService";
import { defineComponent } from "vue";
export default defineComponent({
  props: ["data"],
  data() {
    return {
      mapService: {} as MapService,
      markerService: {} as MarkerService,
      polylineService: {} as PolylineService,
      mapContainer: {} as HTMLDivElement,
      tableData: [] as any[],
    };
  },
  mounted() {
    try {
      Object.assign(this.tableData, this.data);
      this.mapContainer = document.getElementById("map") as HTMLDivElement;
      this.mapService = new MapService(
        this.mapContainer,
        [44.857937, -93.233807]
      );
      this.mapService.initMap();
      this.markerService = new MarkerService(this.mapService.map as L.Map);
      this.polylineService = new PolylineService(
        this.mapService.map as L.Map,
        this.markerService as MarkerService
      );
    } catch (error) {
      console.log("Error", error);
    } finally {
      this.drawPolylineOnMap();
    }
    console.log("podaci -", this.tableData);
  },
  methods: {
    drawPolylineOnMap() {
      let arr = [] as number[][];
      this.tableData.forEach((element: any) => {
        if (element.payload.x > 0 && element.payload.y > 0) {
          arr.push([element.payload.x, element.payload.y]);
        }
      });
      this.polylineService.addPolyline(arr);
    },
    drawMakersOnMap() {
      this.tableData.forEach((element: any) => {
        if (element.payload.x > 0 && element.payload.y > 0) {
          this.markerService.addMarker({
            lat: element.payload.x,
            lng: element.payload.y,
            groupKey: "PARKOM",
          });
          this.markerService.fitGroupToMap("PARKOM");
        }
      });
    },
  },
});
</script>
<style scoped></style>
