import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-[calc(100vh_-_16rem)] w-full pb-1" }
const _hoisted_2 = { class: "w-[350px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_table_list = _resolveComponent("custom-table-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_custom_table_list, {
        "table-data": _ctx.devices,
        "web-socket-data": _ctx.webSocketData,
        onShowAssetRouteDatePickerModal: _ctx.showAssetRouteDatePickerModal,
        onOpenGenericPacketModal: _ctx.openGenericPacketModal,
        onSelectedRows: _ctx.getSelectedRow
      }, null, 8, ["table-data", "web-socket-data", "onShowAssetRouteDatePickerModal", "onOpenGenericPacketModal", "onSelectedRows"])
    ])
  ]))
}