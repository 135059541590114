import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-6" }
const _hoisted_2 = { class: "mb-4 flex h-[46px] items-center space-x-4" }
const _hoisted_3 = {
  key: 0,
  class: "flex w-full justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_date_box = _resolveComponent("dx-date-box")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isSensorPacketsSentDisplayed,
    "is-full-screen-modal": true,
    "remove-outside-click": true,
    title: _ctx.title,
    onToggleModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_dx_date_box, {
            modelValue: _ctx.from,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.from) = $event)),
            height: 46,
            "show-clear-button": true,
            "display-format": "dd.MM.yyyy HH:mm",
            type: "datetime"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_dx_date_box, {
            modelValue: _ctx.to,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.to) = $event)),
            height: 46,
            "show-clear-button": true,
            "display-format": "dd.MM.yyyy HH:mm",
            type: "datetime"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_v_select, {
            modelValue: _ctx.channel,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.channel) = $event)),
            options: _ctx.chanelOptions,
            reduce: (spot) => spot.id,
            class: "min-w-[160px]",
            label: "name",
            multiple: ""
          }, null, 8, ["modelValue", "options", "reduce"]),
          _createVNode(_component_the_button, {
            icon: ['fas', 'rotate-left'],
            "button-type": "primaryButton",
            onClick: _ctx.getSensorPackets
          }, null, 8, ["onClick"])
        ]),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_small_loader)
            ]))
          : (_openBlock(), _createBlock(_component_custom_table, {
              key: 1,
              "allow-auto-resize": true,
              "is-action-list-not-displayed": true,
              "is-data-source-loading": _ctx.isDataSourceLoading,
              "table-data": _ctx.sensorPackets,
              "table-headers": _ctx.tableHeaders,
              "table-height": "70vh"
            }, null, 8, ["is-data-source-loading", "table-data", "table-headers"]))
      ])
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}