<template>
  <create-form
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getSensors"
    @toggle-modal="isCreateModalOpened = false"
  />
  <assign-form
    v-if="isAssignModalOpened"
    :is-assign-modal-opened="isAssignModalOpened"
    :refresh-data="getSensors"
    :sensor="selectedSensor"
    @toggle-modal="toggleAssignModal"
  />

  <sensor-sent-packets
    v-if="isSensorPacketsSentDisplay"
    :is-sensor-packets-sent-displayed="isSensorPacketsSentDisplay"
    :mac="selectedSensor.mac"
    :title="`${selectedSensor.space} ${selectedSensor.spot} - ${selectedSensor.mac}`"
    @toggle-modal="isSensorPacketsSentDisplay = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else class="h-full">
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateAssignment"
        button-text="Create"
        title="Sensors"
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :allow-auto-resize="true"
        :assign-column="true"
        :is-action-list-not-displayed="true"
        :is-data-source-loading="isDataSourceLoading"
        :render-summary="['mac']"
        :show-per-page="16"
        :table-data="sensors"
        :table-headers="tableHeaders"
        table-height="93%"
        @force-refresh="getSensors"
        @view-sensor-packets="toggleSensorSentPackets"
        @assign-sensor="toggleAssignModal"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import CreateForm from "../Components/CreateForm.vue";
import SensorService from "@/modules/Parkom/Submodules/Sensors/Services/SensorService";
import { Sensor } from "@/modules/Parkom/Submodules/Sensors/Services/SensorTypes";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Loader from "@/core/Components/Loader.vue";
import SensorSentPackets from "@/modules/Parkom/Submodules/Sensors/Components/SensorSentPackets.vue";
import AssignForm from "@/modules/Parkom/Submodules/ParkingSpots/Components/AssignForm.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    SensorSentPackets,
    Loader,
    CreateForm,
    TableActionHeader,
    MainSection,
    CustomTable,
    AssignForm,
  },
  data() {
    return {
      sensorService: new SensorService(),
      toast: useToast(),
      changeCompanyStore: useChangeCompanyStore(),
      isDataSourceLoading: false,
      isLoading: false,
      isCreateModalOpened: false,
      isSensorPacketsSentDisplay: false,
      isAssignModalOpened: false,
      selectedSensor: {} as Sensor,
      sensors: [] as Sensor[],
      permissions: Permissions,
      tableHeaders: {
        id: { header: "ID", visible: false },
        deviceUuid: { header: "UUID", visible: false },
        space: { header: "Sensors.Space", visible: true },
        spaceId: { header: "Sensors.SpaceId", visible: false },
        spot: { header: "Sensors.Spot", visible: true },
        mac: { header: "Sensors.MAC", visible: true },
        imei: { header: "Sensors.IMEI", visible: true },
        spotOccupied: { header: "Sensors.Occupied", visible: true },
        lastSync: { header: "Sensors.LastSync", visible: true },
        firmwareVersion: { header: "Sensors.FirmwareVersion", visible: true },
        mag1Temp: { header: "Sensors.Temperature", visible: true },
        packetsSentMerged: { header: "Sensors.Packets", visible: true },
        batteryLevel: { header: "Sensors.BatteryLevel", visible: true },
        voltage: { header: "Sensors.Voltage", visible: true },
        unhandledRestartCounter: {
          header: "Sensors.UnhandledReboots",
          visible: true,
        },
        rssi: { header: "Sensors.RSSI", visible: true },
        rsrq: { header: "Sensors.RSRQ", visible: true },
        searchingForNetworkCount: {
          header: "Sensors.SearchingForNetwork",
          visible: true,
        },
        cellId: { header: "Sensors.Cell", visible: true },
        band: { header: "Sensors.Band", visible: true },
        ecl: { header: "Sensors.ECL", visible: true },
        rsrp: { header: "Sensors.RSRP", visible: true },
        snr: { header: "Sensors.SNR", visible: true },
        totalConsumptionMAh: {
          header: "Total consumption (mAH)",
          visible: true,
        },
        imsi: { header: "Sensors.IMSI", visible: true },
        ccid: { header: "Sensors.CCID", visible: true },
        //totalSleep: { header: "Sensors.TotalSleep", visible: true },
        modemDisableCounter: {
          header: "Sensors.ModemDisableCounter",
          visible: true,
        },
        noResponseCounter: {
          header: "Sensors.NoResponseCounter",
          visible: true,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getSensors();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getSensors();
    },
  },
  methods: {
    async getSensors() {
      this.isDataSourceLoading = true;
      try {
        this.sensors = await this.sensorService.getSensors({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },

    toggleSensorSentPackets(sensor: Sensor) {
      this.selectedSensor = sensor;
      this.isSensorPacketsSentDisplay = !this.isSensorPacketsSentDisplay;
    },

    toggleAssignModal(sensor: Sensor) {
      this.selectedSensor = sensor;
      this.isAssignModalOpened = !this.isAssignModalOpened;
    },
  },
});
</script>

<style scoped></style>
