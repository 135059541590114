import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-4 px-6 py-6" }
const _hoisted_2 = { class: "flex w-full items-center justify-between" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_last_data_preview = _resolveComponent("last-data-preview")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isDeleteRelationModalOpened,
    "no-padding": true,
    title: _ctx.$t('DeviceTypes.DeleteRelationTitle'),
    onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      (_ctx.isDataPreviewModalOpened)
        ? (_openBlock(), _createBlock(_component_last_data_preview, {
            key: 0,
            data: _ctx.selectedMetadata,
            "is-last-data-modal-opened": _ctx.isDataPreviewModalOpened,
            name: _ctx.selectedMetadata.name,
            preview: true,
            onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDataPreviewModalOpened = false))
          }, null, 8, ["data", "is-last-data-modal-opened", "name"]))
        : _createCommentVNode("", true),
      _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviceTypeMetadata, (data) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("p", {
                    class: "cursor-pointer hover:underline",
                    onClick: () => _ctx.previewMetadata(data)
                  }, _toDisplayString(data.name), 9, _hoisted_3),
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'trash'],
                    class: "cursor-pointer hover:text-error",
                    onClick: () => _ctx.deleteRelation(data.id)
                  }, null, 8, ["onClick"])
                ]))
              }), 256))
            ]),
            _createVNode(_component_button_wrapper)
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}