import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative items-center py-4" }
const _hoisted_2 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isCreateBulkModalOpened,
    "no-padding": true,
    title: _ctx.$t('ParkingSpots.CreateBulkTitle'),
    onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("ParkingSpots.Space")), 1),
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.bulkParkingSpot.spaceId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bulkParkingSpot.spaceId) = $event)),
                    class: _normalizeClass(`mt-1 ${
                _ctx.v$?.bulkParkingSpot['spaceId']?.$error && 'border border-error'
              }`),
                    options: _ctx.parkingSpaces,
                    reduce: (space) => space.id,
                    label: "name"
                  }, null, 8, ["modelValue", "class", "options", "reduce"])
                ]),
                _createVNode(_component_input_field, {
                  modelValue: _ctx.bulkParkingSpot.startIndex,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bulkParkingSpot.startIndex) = $event)),
                  errorMessage: 
              _ctx.v$?.bulkParkingSpot['startIndex']?.$errors[0]?.$message
            ,
                  isError: _ctx.v$?.bulkParkingSpot['startIndex']?.$error,
                  label: _ctx.$t('ParkingSpots.StartIndex'),
                  required: true,
                  type: "number"
                }, null, 8, ["modelValue", "errorMessage", "isError", "label"]),
                _createVNode(_component_input_field, {
                  modelValue: _ctx.bulkParkingSpot.quantity,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.bulkParkingSpot.quantity) = $event)),
                  errorMessage: 
              _ctx.v$?.bulkParkingSpot['quantity']?.$errors[0]?.$message
            ,
                  isError: _ctx.v$?.bulkParkingSpot['quantity']?.$error,
                  label: _ctx.$t('ParkingSpots.Quantity'),
                  required: true,
                  type: "number"
                }, null, 8, ["modelValue", "errorMessage", "isError", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}