import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    "hide-overflow": true,
    "is-displayed": _ctx.isShareModalOpened,
    "is-select-modal": true,
    "no-padding": true,
    "have-modal": _ctx.haveModal,
    title: "Share device",
    onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_form_divider, null, {
          default: _withCtx(() => [
            _createVNode(_component_input_field, {
              modelValue: _ctx.formData.userEmail,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.userEmail) = $event)),
              errorMessage: _ctx.v$?.formData['userEmail']?.$errors[0]?.$message,
              isError: _ctx.v$?.formData['userEmail']?.$error,
              required: true,
              label: "Email",
              placeholder: "Input user email..."
            }, null, 8, ["modelValue", "errorMessage", "isError"])
          ]),
          _: 1
        }),
        _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
      ], 32)
    ]),
    _: 1
  }, 8, ["is-displayed", "have-modal"]))
}