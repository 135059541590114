import { User } from "@/modules/Users/Services/UserService";
import { Company } from "@/modules/Companies/Services/CompanyService";
import { DeviceMetadata } from "@/modules/Metadata/Services/Device/DeviceMetadataService";
import { DeviceType } from "@/modules/DeviceTypes/Services/DeviceTypeService";
import { DeviceAction } from "@/modules/DeviceActions/Services/DeviceActionService";
import { Permission } from "@/modules/Permissions/Services/PermissionService";
import { Application } from "@/modules/Applications/Services/ApplicationService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import DeviceDataService, {
  LastDeviceAssignmentData,
} from "@/modules/Devices/Services/DeviceDataService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import { LastResponseMessage } from "@/modules/ResponseMessages/Services/ResponseMessagesTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type DeviceAssignment = {
  device: Device | null;
  deviceId: number | null;
  assignmentId: number | null;
  assignment: Assignment | null;
  lastTimeDeviceSentData?: string;
  sentMessages?: LastResponseMessage;
};

export type Assignment = {
  id: number;
  dataUuid: string;
  name: string;
  latitude: number;
  longitude: number;
  isActive: true;
  userId: number;
  user: User;
  applicationId: number;
  application: Application;
  companyId: number;
  company: Company;
  metadata: DeviceMetadata[];
  permissions: Permission[];
  parentAssignmentId: number | null;
  parentAssignment: ParentAssignment | null;
};

export type Device = {
  id: number;
  deviceUuid: string;
  imei: string;
  mac: string;
  imsi: string;
  externalId: string;
  serialNumber: string;
  deviceTypeId: number;
  deviceType: DeviceType;
  actions: DeviceAction[];
  data: any;
};

export type CreateAssignment = {
  assignmentRequest: CreateAssignmentRequest;
  deviceRequest?: CreateDeviceRequest;
};
export type EditAssignment = {
  assignmentId: number;
  name: string;
  applicationId: number;
  latitude: number;
  longitude: number;
};

export type CreateAssignmentRequest = {
  parentAssignmentId: number | null;
  name: string;
  applicationId: number;
  latitude: number;
  longitude: number;
};

export type ParentAssignment = Exclude<
  Assignment,
  | "application"
  | "company"
  | "user"
  | "parentAssignment"
  | "metadata"
  | "permissions"
>;

export type CreateDeviceRequest = {
  deviceTypeId: number | null;
  externalId?: string;
  data?: any;
};

type DeviceAssignmentProps = {
  typeOfAssignment: TypeOfAssignment;
  applicationId?: number;
  pageNumber?: number;
  pageSize?: number;
  lastDataSent?: boolean;
  deviceTypeIds?: number[];
  assetName?: string;
};

export type TypeOfAssignment =
  | "Company"
  | "MyDevices"
  | "SharedToMe"
  | "SharedByMe"
  | "SharedByMePending";

export default class AssignmentService {
  lastDataSent = [] as LastDeviceAssignmentData[];
  deviceDataService = new DeviceDataService();

  async getAllDeviceAssignments({
    typeOfAssignment = "Company",
    applicationId,
    pageNumber = 1,
    pageSize = 10,
    lastDataSent = false,
    deviceTypeIds,
    assetName,
  }: DeviceAssignmentProps): Promise<DeviceAssignment[]> {
    const url = GenerateUrl({
      path: "/api/assignments",
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortOrder: "Ascending",
        SortBy: "AssignmentName",
        Include: [
          "DeviceDeviceType",
          "DeviceActions",
          "AssignmentApplication",
          "AssignmentUser",
          "AssignmentCompany",
          "AssignmentMetadata",
          "AssignmentParentAssignment",
        ],
        ...(deviceTypeIds && { DeviceTypeIds: deviceTypeIds }),
        ...(assetName && { AssignmentName: assetName }),
        TypeOfAssignments: typeOfAssignment,
        ...(applicationId && { ApplicationId: applicationId }),
      },
    });

    const response = await ApiService.get(url);

    if (lastDataSent) {
      const uuids = response.data.items.map(
        (deviceAssignment: DeviceAssignment) =>
          deviceAssignment.assignment?.dataUuid
      );

      if (uuids.length) {
        this.lastDataSent =
          await this.deviceDataService.getDeviceAssignmentLastData({
            dataUuids: uuids,
          });
      }
    }

    return this._mapDeviceAssignments(response.data.items);
  }

  async getDeviceAssignmentByIdentifier({
    id,
  }: {
    id: number;
  }): Promise<DeviceAssignment> {
    const url = GenerateUrl({
      path: `/api/assignments/${id}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
      config: {
        Include: [
          "DeviceDeviceType",
          "DeviceActions",
          "AssignmentApplication",
          "AssignmentUser",
          "AssignmentCompany",
          "AssignmentMetadata",
        ],
      },
    });

    const response = await ApiService.get(url);

    return this._mapDeviceAssignment(response.data);
  }

  async createDeviceAssignment(
    deviceAssignment: CreateAssignment
  ): Promise<DeviceAssignment> {
    debugger;
    const url = GenerateUrl({
      path: "/api/assignments",
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    const response = await ApiService.post(url, deviceAssignment);
    return response.data;
  }

  async editDeviceAssignment({
    deviceAssignment, //[{op: "replace", path: "assignment/name", value : "nova_vrijednsot"}]
    id,
  }: {
    deviceAssignment: PatchObject[];
    id: number;
  }) {
    const url = GenerateUrl({
      path: `/api/device-assignments/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, deviceAssignment);
  }

  /**
   * Removes device assignment from user, doesn't delete the device
   */
  async deleteDeviceAssignment({ id }: { id: number }) {
    const url = GenerateUrl({
      path: `/api/assignments/${id}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  /**
   * Delete the device
   */
  async deleteDeviceAssignment_({ id }: { id: number }) {
    const url = GenerateUrl({
      path: `/api/assignments/${id}?Type=Remove`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  async deleteDevice(id: number) {
    const url = GenerateUrl({
      path: `/api/device-assignments/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  private _mapDeviceAssignments(
    response: DeviceAssignment[]
  ): DeviceAssignment[] {
    return response.map((deviceAssignment) =>
      this._mapDeviceAssignment(deviceAssignment)
    );
  }

  private _mapDeviceAssignment(
    deviceAssignment: DeviceAssignment
  ): DeviceAssignment {
    return {
      deviceId: deviceAssignment.deviceId,
      device: deviceAssignment.device,
      assignmentId: deviceAssignment.assignmentId,
      assignment: deviceAssignment.assignment,
      ...(this.lastDataSent.length && {
        lastTimeDeviceSentData: this.findTheNewestLastTime(
          deviceAssignment.assignment!.dataUuid
        ),
      }),
    };
  }

  private findTheNewestLastTime(dataUuuid: string) {
    const data = this.lastDataSent.find(
      (sentData) => sentData.dataUuid === dataUuuid
    )?.data;

    let newestDate;
    if (data) {
      newestDate = data.reduce((latest, current) => {
        const latestDate = new Date(latest.dateTime);
        const currentDate = new Date(current.dateTime);

        return latestDate > currentDate ? latest : current;
      }, data[0]).dateTime;
    }

    return newestDate;
  }
}
