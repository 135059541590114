<template>
  <div
      class="absolute inset-0 z-[10000] flex items-center justify-center bg-[rgba(255,255,255,0.3)]">
    <div class="w-24 flex items-center justify-center h-24 bg-white nice-shadow rounded-lg">
      <small-loader/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";

export default defineComponent({
  components: {SmallLoader}
});
</script>

<style scoped></style>
