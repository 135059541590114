<template>
  <div
    :class="`${label !== '' ? 'flex' : 'flex items-center'} w-full py-4 ${
      smallText ? 'space-x-2' : 'space-x-4'
    }`"
  >
    <label
      v-if="label"
      :class="`${smallText && 'text-sm'}`"
      class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
      >{{ label
      }}<span v-if="required" class="pl-0.5 text-orange-600">*</span></label
    >

    <input
      :checked="modelValue"
      :value="modelValue"
      class="h-5 w-5 cursor-pointer border-grey accent-primary"
      type="checkbox"
      @change="
        $emit('update:modelValue', ($event.target as HTMLInputElement).checked)
      "
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["isChecked", "label", "smallText", "required", "modelValue"],
  emits: ["update:modelValue"],
});
</script>
<style scoped></style>
