import { PatchObject } from "@/core/Services/GlobalTypes";
import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class AuthPolicyService {
  async editPolicy(patchObject: PatchObject[], id: number): Promise<any> {
    return await ApiService.patch(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/authorization/policies/${id}`,
      patchObject
    );
  }

  async deletePolicy(id: number): Promise<any> {
    return await ApiService.delete(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/authorization/policies/${id}`
    );
  }
}
