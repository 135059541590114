<template>

    <sensor-sent-packets v-if="isSensorPacketsSentDisplay"
        :is-sensor-packets-sent-displayed="isSensorPacketsSentDisplay" :mac="selectedSensor.mac"
        :title="`${selectedSensor.space} ${selectedSensor.spot} - ${selectedSensor.mac}`"
        @toggle-modal="isSensorPacketsSentDisplay = false" />

    <main-section>
        <loader v-if="isLoading" />
        <div v-else class="h-full">

            <table-action-header :title="$t('Sidebar.SensorHealth')" />

            <div class="flex h-[46px] gap-4">
                <dx-date-box v-model="fromDate" :height="46" :show-clear-button="true" display-format="dd.MM.yyyy HH:mm"
                    type="datetime" />
                <dx-date-box v-model="toDate" :height="46" :show-clear-button="true" display-format="dd.MM.yyyy HH:mm"
                    type="datetime" />
                <the-button :icon="['fas', 'rotate-left']" button-type="primaryButton"
                    @execute-method="getHealthStatus" />
            </div>

            <custom-table :allow-auto-resize="true" :is-data-source-loading="isDataSourceLoading"
                :render-summary="['name']" :show-per-page="16" :table-data="healths" :table-headers="tableHeaders"
                table-height="90%" @force-refresh="getHealthStatus" :isActionListNotDisplayed="true"
                @view-sensor-packets="toggleSensorSentPackets" />
        </div>
    </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import Healthservice from "@/modules/Parkom/Submodules/Health/Services/HealthService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Loader from "@/core/Components/Loader.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { Permissions } from "@/core/Services/Enums";
import { Health, HealthRequest } from "@/modules/Parkom/Submodules/Health/Services/HealthService";
import { Sensor } from "@/modules/Parkom/Submodules/Sensors/Services/SensorTypes";
import SensorService from "@/modules/Parkom/Submodules/Sensors/Services/SensorService";
import moment from "moment/moment";
import DxDateBox from "devextreme-vue/date-box";
import TheButton from "@/core/Components/TheButton.vue";
import SensorSentPackets from "@/modules/Parkom/Submodules/Sensors/Components/SensorSentPackets.vue";

export default defineComponent({
    components: {
        SensorSentPackets,
        TableActionHeader,
        MainSection,
        CustomTable,
        DxDateBox,
        TheButton,
        Loader
    },
    data() {
        return {
            permissionStore: usePermissionStore(),
            healthService: new Healthservice(),
            sensorService: new SensorService(),
            healths: [] as Health[],
            healthRequest: {} as HealthRequest,
            selectedSensor: {} as Sensor,
            sensors: [] as Sensor[],
            toast: useToast(),
            changeCompanyStore: useChangeCompanyStore(),
            isSensorPacketsSentDisplay: false,
            isDataSourceLoading: false,
            isLoading: false,
            permissions: Permissions,
            fromDate: moment().startOf("day").format("YYYY-MM-DDTHH:mm"),
            toDate: moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
            tableHeaders: {
                mac: { header: "Sensors.MAC", visible: true },
                sensorPackets: { header: "Sensors.Packets", visible: true },
                space: { header: "Sensors.Space", visible: true },
                spot: { header: "Sensors.Spot", visible: true },
                firmwareVersion: { header: "Sensors.FirmwareVersion", visible: true },
                lastSync: { header: "Sensors.LastSync", visible: true },
                batteryPercentage: { header: "Sensors.BatteryLevel", visible: true },
                avgConsumptionPerDay: { header: "Sensors.AvgConsumptionPerDay", visible: true },
                avgRssi: { header: "Sensors.AvgRssi", visible: true },
                minRssi: { header: "Sensors.MinRssi", visible: true },
                avgPacketsPerDay: { header: "Sensors.AvgPacketsPerDay", visible: true },
                notSequencePackets: { header: "Sensors.NotSequencePackets", visible: true },
                missedPackets: { header: "Sensors.MissedPackets", visible: true },
                packetsNotIncrement: { header: "Sensors.PacketsNotIncrement", visible: true },
                lastUnhandledReboot: { header: "Sensors.LastUnhandledReboot", visible: true },
                rebootsGrows: { header: "Sensors.RebootsGrows", visible: true },
                rebootsNegative: { header: "Sensors.RebootsNegative", visible: true },
                rebootsChangingError: { header: "Sensors.RebootsChangingError", visible: true },
                avgPowerOnCountPerDay: { header: "Sensors.AvgPowerOnCountPerDay", visible: true },
                runtimeTotalSecondsHealth: { header: "SensorPackets.RuntimeTotalSecondsHealth", visible: true },
                disableCounter: { header: "Sensors.ModemDisableCounter", visible: true },
                eclTwo: { header: "Sensors.EclTwo", visible: true },
                samePacketIdCounter: { header: "Sensors.SamePacketIdCounter", visible: true },
                mag1RawXFlash: { header: "Sensors.Mag1RawXFlash", visible: true },
                mag1RawYFlash: { header: "Sensors.Mag1RawYFlash", visible: true },
                mag1RawZFlash: { header: "Sensors.Mag1RawZFlash", visible: true },
                radarFlash: { header: "Sensors.RadarFlash", visible: true }
            }
        };
    },

    async created() {
        this.isLoading = true;
        try {
            await this.getSensorsList();
            await this.getHealthStatus();
        } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
        } finally {
            this.isLoading = false;
        }
    },
    watch: {
        async "changeCompanyStore.companyId"() {
            await this.getSensorsList();
            await this.getHealthStatus();
        },
    },
    methods: {
        async getHealthStatus() {
            this.isDataSourceLoading = true;

            let snrList: string[] = [];
            this.sensors.forEach(snr => {
                snrList.push(snr.mac);
            });

            this.healthRequest.macs = snrList;
            this.healthRequest.from = this.fromDate;
            this.healthRequest.to = this.toDate;

            try {
                this.healths = await this.healthService.getHealthStatus(this.healthRequest);
            } catch (error) {
                this.toast.error(ErrorMessageFormatter(error));
            } finally {
                this.isDataSourceLoading = false;
            }
        },
        toggleSensorSentPackets(sensor: Sensor) {
            this.selectedSensor = sensor;
            this.isSensorPacketsSentDisplay = !this.isSensorPacketsSentDisplay;
        },

        async getSensorsList(){
            console.log("getSensorsList");
            this.sensors = await this.sensorService.getSensors({
                pageNumber: 1,
                pageSize: 100000,
            });
        }        
    }
});
</script>

<style scoped></style>
