export default {
  "Sidebar": {
    "Parkom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SmartCity"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webový portál"])},
    "ParkingSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacie zóny"])},
    "ParkingSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacie miesta"])},
    "Sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senzory"])},
    "DigitalParkingSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitálne parkovacie značky"])},
    "ParkingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba parkovania"])},
    "BusControllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroléry"])},
    "WorkOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pracovné príkazy"])},
    "Devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zariadenia"])},
    "Groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupiny"])},
    "Integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrácie"])},
    "Organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizácia"])},
    "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používatelia"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
    "Authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnenie"])},
    "GlobalAuthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprávnenie"])},
    "AccessManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa prístupov"])},
    "ProfileConfigurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia profilu"])},
    "Resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroje"])},
    "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udalosti"])}
  },
  "SidebarMenu": {
    "ParkingSpacesSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spaces selection"])},
    "SelectParkingSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať parkovacie zóny"])}
  },
  "Languages": {
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angličtina"])},
    "Croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chorvátsky"])},
    "Slovakian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenský"])}
  },
  "Action": {
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať"])},
    "ManageSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravovať parkovacie miesta"])},
    "AddDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať zariadenia"])},
    "Integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrácie"])},
    "MakeActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivovať"])},
    "RemoveUserFromOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať používateľa z organizácie"])}
  },
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť"])},
  "CreateBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť skupinovo"])},
  "AddUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať používateľa"])},
  "Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počítať"])},
  "General": {
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmazať"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created successfully!"])},
    "EditedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited successfully!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted successfully!"])}
  },
  "Dashboard": {
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udalosť"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkom"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsadené"])},
    "Unoccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voľné"])},
    "Offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "Enters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vjazdy"])},
    "Exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výjazdy"])},
    "Load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítať"])},
    "OccupationByHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyťaženosť"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacia zóna"])},
    "ParkingSpot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacie miesto"])},
    "UnoccupiedSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voľné od"])},
    "OccupiedSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsadené od"])}
  },
  "ParkingSpaces": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacie zóny"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesto"])},
    "Street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica"])},
    "StreetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsadené"])},
    "Unoccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voľné"])},
    "Navigable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možné navigovať"])},
    "Visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditeľné"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívne"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemepisná šírka"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemepisná dĺžka"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť parkovaciu zónu"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť parkovaciu zónu"])},
    "ParkingSpaceLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať lokalitu parkovacej zóny"])}
  },
  "ParkingSpots": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacie miesta"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacia zóna"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
    "MAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC adresa"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "OccupiedTimestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba obsadenia"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsadené"])},
    "Index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "Public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verejné"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť parkovacie miesto"])},
    "CreateBulkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť skupinovo parkovacie miesta"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť parkovacie miesto"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacia zóna"])},
    "ParkingSpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ parkovacieho miesta"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemepisná šírka"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemepisná dĺžka"])},
    "Visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditeľné"])},
    "StartIndex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Východzia pozícia"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet"])},
    "CreateBulkSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacie miesta úspešne vytvorené!"])}
  },
  "Displays": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LED smerovacie tabule"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledná synchronizácia"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívne"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "LastDisplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledne zobrazené"])},
    "Nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dopravné uzly"])},
    "ManageNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravovať dopravné uzly"])},
    "ManageSources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spravovať vstupy"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemepisná šírka"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemepisná dĺžka"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť smerovaciu tabulu"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť smerovaciu tabulu"])},
    "NodeDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node deleted successfully!"])},
    "SourceDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source deleted successfully!"])},
    "Timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
  },
  "ParkingSessions": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba parkovania"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začiatok"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacia zóna"])},
    "Spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacie miesto"])},
    "SpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ parkovacieho miesta"])},
    "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trvanie"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])}
  },
  "BusControllers": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontroléry"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledná synchronizácia"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zóna"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť kontrolér"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť kontrolér"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacia zóna"])}
  },
  "Sensors": {
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacia zóna"])},
    "SpaceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking ID"])},
    "ParkingSpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ parkovacieho miesta"])},
    "Index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "Spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkovacie miesto"])},
    "MAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsadené"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledná synchronizácia"])},
    "FirmwareVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzia firmvéru"])},
    "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teplota"])},
    "Packets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakety"])},
    "BatteryLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úroveň batérie"])},
    "Voltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napätie"])},
    "UnhandledReboots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neošetrené reštarty"])},
    "RSSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSSI"])},
    "RSRQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRQ"])},
    "SearchingForNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadanie siete"])},
    "Cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunka"])},
    "Band": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Band"])},
    "ECL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECL"])},
    "RSRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRP"])},
    "SNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNR"])},
    "IMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMSI"])},
    "TotalSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totálny spánok"])},
    "ModemDisableCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počítadlo vypnutia modemu"])},
    "NoResponseCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne počítadlo odpovedí"])},
    "Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priradiť"])},
    "AssignSensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priradiť senzor"])},
    "AssignTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Priraďte ", _interpolate(_named("mac")), " k parkovaciemu miestu"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť senzor"])}
  },
  "SensorPackets": {
    "RTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RTC"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "DeviceState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav zariadenia"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavenie"])},
    "Packets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakety"])},
    "BatteryPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percento batérie"])},
    "TotalConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková spotreba (mAh)"])},
    "BatteryVoltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napätie batérie"])},
    "FirmwareVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzia firmvéru"])},
    "RSSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSSI"])},
    "RSRQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRQ"])},
    "Cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunka"])},
    "ECL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECL"])},
    "Band": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Band"])},
    "SearchingForNetworkCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadá sa sieť počet"])},
    "RSRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRP"])},
    "SNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNR"])},
    "MagX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag X"])},
    "MagY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag Y"])},
    "MagZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag Z"])},
    "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teplota"])},
    "Mag1RawX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw X"])},
    "Mag1RawY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw Y"])},
    "Mag1RawZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw Z"])},
    "RadarWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmotnosť radaru"])},
    "RadarWeightKap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmotnosť radaru Kap"])},
    "RadarDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radarová vzdialenosť"])},
    "RadarDistanceKap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radarová vzdialenosť Kap"])},
    "MagDiff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag rozdiel (s)"])},
    "UnhandledRestartCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neošetrené počítadlo reštartov"])},
    "ModemRebootCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet reštartov modemu"])},
    "PowerOnCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počítanie zapnutia"])},
    "RuntimeTotalSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková doba behu (s)"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "IMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMSI"])},
    "FwUpdateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fw začiatok aktualizácie"])},
    "FwUpdateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fw koniec aktualizácie"])},
    "FwUpdateVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fw verziu aktualizácie"])},
    "TotalSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totálny spánok"])},
    "ModemDisableCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počítadlo vypnutia modemu"])},
    "NoResponseCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne počítadlo odpovedí"])},
    "StoredAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložené dňa"])},
    "TimeDifference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas rozdiel (s)"])},
    "BleTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ble tags"])}
  },
  "WorkOrders": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work orders"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "Pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieces"])},
    "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "DeliveredOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered on"])},
    "UserCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User created"])},
    "UserUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User updated"])},
    "AppliedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied to"])},
    "IsApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is applied"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create work order"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit work order"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "AddToWorkOrderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to work order"])},
    "AddToWorkOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to work order successfully!"])},
    "RemoveFromWorkOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed from work order successfully!"])}
  },
  "Authorization": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization"])},
    "ToUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To user"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "ResourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource type"])},
    "Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
    "CreatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created resource"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign role"])},
    "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit role"])},
    "CreateAuthorizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization created successfully!"])},
    "ResourceEditedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource edited successfully!"])}
  },
  "GlobalAuthorization": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global authorization"])},
    "ToUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To user"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "ResourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource type"])},
    "Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
    "CreatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created resource"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign role"])},
    "Asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit role"])},
    "CreateAuthorizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global authorization created successfully!"])},
    "ResourceEditedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource edited successfully!"])}
  },
  "Groups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create group"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit group"])},
    "CreateGroupSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group created successfully!"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])}
  },
  "Integrations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create integration"])},
    "IntegrationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration type"])},
    "Endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endpoint"])},
    "IP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP address"])},
    "Port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port"])},
    "ConnectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection type"])},
    "MqttProtocolVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MQTT protocol version"])},
    "HasCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has credentials"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "CleanSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean session"])},
    "Topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
    "RetainFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retain flag"])},
    "CreateHttpSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP integration created successfully!"])},
    "CreateUdpSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UDP integration created successfully!"])}
  },
  "Organizations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizations"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create organization"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit organization"])},
    "ActiveCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active company"])},
    "CreateOrganizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization created successfully!"])},
    "EditOrganizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization edited successfully!"])},
    "OrganizationChangedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company changed successfully!"])},
    "OrganizationDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization deleted successfully!"])}
  },
  "Users": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používatelia"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užívateľské meno"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "AddUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať používateľa"])},
    "AddTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať používateľa k organizácii"])},
    "AddUserSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používateľ úspešne pridaný!"])},
    "UserRemovedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používateľ úspešne vymazaný!"])}
  },
  "VersionDropdown": {
    "Version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzia"])},
    "LegalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právne upozornenie"])},
    "TermsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podmienky používania"])},
    "Imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "TermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všeobecné obchodné podmienky"])},
    "DataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrana údajov"])}
  },
  "UserDropdown": {
    "EditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť profil"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásenie"])}
  }
}