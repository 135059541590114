<template>
  <div
    v-click-away="isDropdownOpened && closeDropdown"
    class="relative flex h-full w-full cursor-pointer items-center justify-center align-middle"
    @click="toggleDropdown"
  >
    <font-awesome-icon v-if="useForTable" :icon="['fas', 'ellipsis']" />
    <button
      v-else
      class="hover:bg-primaryhovers rounded-md bg-primary px-4 py-3 text-sm text-white"
      @click="toggleDropdown"
    >
      Options
      <font-awesome-icon
        :class="`ml-2 transform ${
          isDropdownOpened ? 'rotate-180' : 'rotate-0'
        } duration-300 ease-out`"
        :icon="['fas', 'chevron-down']"
      />
    </button>
    <div
      :class="`absolute right-0 z-[100] mt-3 h-auto min-w-[200px] transform border border-grey bg-white shadow-lg ${
        useForTable ? 'top-4' : 'top-12'
      } origin-top space-y-1 rounded-lg p-2 duration-300 ease-in-out ${
        isDropdownOpened ? 'scale-y-100' : 'scale-y-0'
      } ${isDropdownOpened ? 'opacity-100' : 'opacity-0'}`"
    >
      <div v-if="checkIfObjectEmpty(componentOptions)">
        No actions available
      </div>
      <div v-else>
        <div v-for="(value, index) in componentOptions">
          <div
            :class="`flex cursor-pointer items-center space-x-4 px-2 py-1 hover:rounded-md hover:bg-lightgray`"
            @click="value.action"
          >
            <div class="flex h-5 w-5 items-center justify-center text-primary">
              <font-awesome-icon :icon="value['icon']" />
            </div>
            <p>{{ $t(value["header"]) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { TableDropdownItem } from "@/core/Services/GlobalTypes";
import { Permissions } from "@/core/Services/Enums";
import checkIfObjectEmpty from "@/core/Services/GlobalFunctions";

export default defineComponent({
  props: {
    options: {
      type: Object as PropType<TableDropdownItem>,
      required: true,
    },
    removeEdit: Boolean,
    useForTable: Boolean,
    assetPermissions: Object,
    isCompanyActive: Boolean,
  },
  data() {
    return {
      isDropdownOpened: false as Boolean,
      permissionStore: usePermissionStore(),
      componentOptions: {} as TableDropdownItem,
    };
  },
  mounted() {
    this.componentOptions = Object.assign({}, this.options);

    if (this.assetPermissions) {
      this.filterAssetOptionsByPermissions();
    }

    if (this.isCompanyActive == false) {
      this.componentOptions = {};
    }

    if (this.removeEdit) {
      delete this.componentOptions.edit;
      delete this.componentOptions.addDevice;
    }

    this.componentOptions = Object.fromEntries(
      Object.entries(this.componentOptions).filter(([key, value]) =>
        this.permissionStore.hasPermission(value.permissionId)
      )
    );
  },
  methods: {
    checkIfObjectEmpty,
    toggleDropdown() {
      this.isDropdownOpened = !this.isDropdownOpened;
    },
    closeDropdown() {
      this.isDropdownOpened = false;
    },
    filterAssetOptionsByPermissions() {
      const optionsToRemove = [
        { key: "edit", permissionId: Permissions.EditAssignment },
        { key: "delete", permissionId: Permissions.DeleteAssignment },
        { key: "remove", permissionId: Permissions.DeleteAssignment },
        { key: "removeRelation", permissionId: Permissions.EditAssignment },
        { key: "changeGroup", permissionId: Permissions.EditAssignment },
        { key: "shareDevice", permissionId: Permissions.Share },
        { key: "changeCompany", permissionId: Permissions.EditAssignment },
        { key: "changeOwnership", permissionId: Permissions.EditAssignment },
      ];

      optionsToRemove.forEach((option) => {
        const permissionExists =
          this.assetPermissions &&
          this.assetPermissions.some(
            (permission: any) => permission.id === option.permissionId
          );

        if (!permissionExists && this.componentOptions[option.key]) {
          delete this.componentOptions[option.key];
        }
      });
    },
  },
});
</script>
<style scoped>
button {
  @apply shadow-md;
}
</style>
