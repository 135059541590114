import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8b1dbba"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type"]
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.cn(_ctx.buttonVariants({ variant: _ctx.variant, size: _ctx.size, className: _ctx.className }))),
    disabled: _ctx.isLoading ? _ctx.isLoading : _ctx.isDisabled,
    type: _ctx.type,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.executeMethod()))
  }, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: ['fas', 'spinner'],
          class: "animate-spin"
        }))
      : _createCommentVNode("", true),
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 1,
          icon: _ctx.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    (_ctx.text)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}