type SpotiumConfig = {
  BASE_URL: string;
  WS_BASE_URL: string;
  MANAGEMENT_API_PREFIX_PATH: string;
  STORAGE_API_PREFIX_PATH: string;
  CONFIGURATION_API_PREFIX_PATH: string;
  NOTIFICATION_API_PREFIX_PATH: string;
  RULE_ENGINE_API_PREFIX_PATH: string;
  ZONE_API_PREFIX_PATH: string;
  SPOTIUM_API_PREFIX_PATH: string;
  GATEWAY_PREFIX_PATH: string;
  REPORT_API_PREFIX_PATH: string;
  WORK_ORDER_PREFIX_PATH: string;
  FIRMWARE_UPDATE_PREFIX_PATH: string;
  PARKOM_API_PREFIX_PATH: string;
  ACCESS_MANAGER_API_PREFIX_PATH: string;
  WEB_SOCKET_PREFIX_PATH: string;
  KEYCLOAK_PROFILE: string;
  KEYCLOAK_URL: string;
  KEYCLOAK_CLIENT_ID: string;
  NOTIFICATION_V2_API_PREFIX_PATH: string;
  VERSION: string;
  VERSION_DATE: string;
  KEYCLOAK_REALM: string;
  LOCAL_RUN: string;
  LOCAL_JWT: string;
  LOCAL_USERNAME: string;
  LOCAL_EMAIL: string;
  LOCAL_LANGUAGE_CODE: string;
  FAVICON: string;
  IS_PARKOM: string;
  IS_ACCESS: string;
};

const ConfigurationService = {
  isConfigurationLoaded: false as boolean,
  configData: {} as SpotiumConfig,

  async loadConfiguration(): Promise<void> {
    try {
      const response = await fetch(
        `${process.env.VUE_APP_PUBLIC_PATH}configuration/configuration.json`
      );
      this.configData = await response.json();
      this.isConfigurationLoaded = true;
    } catch (error) {
      console.error("Error fetching configuration:", error);
    }
  },
};

export default ConfigurationService;
