import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  style: {
        'grid-template-columns': 'auto auto min-content',
      },
  class: "grid grid-cols-3 gap-4 py-3"
}
const _hoisted_2 = { class: "flex items-center space-x-2" }
const _hoisted_3 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = { class: "flex items-center space-x-2" }
const _hoisted_6 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_7 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_the_button = _resolveComponent("the-button")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.json, (obj, index) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("Integrations.Topic")), 1),
            _withDirectives(_createVNode(_component_font_awesome_icon, { icon: ['fas', 'circle-info'] }, null, 512), [
              [_directive_tippy, 
              'Topic is a name of a topic, while channel is numeric value of channel'
            ]
            ])
          ]),
          _withDirectives(_createElementVNode("input", {
            id: "key",
            "onUpdate:modelValue": ($event: any) => ((obj.key) = $event),
            class: "relative mt-1 w-full appearance-none border bg-white p-2 text-left font-sans leading-normal text-dark shadow-sm outline-none focus:border-primary",
            onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.trimTopic && _ctx.trimTopic(...args)))
          }, null, 40, _hoisted_4), [
            [_vModelText, obj.key]
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("Integrations.Channel")), 1),
            _createVNode(_component_font_awesome_icon, {
              icon: ['fas', 'circle-info'],
              class: "invisible"
            })
          ]),
          _withDirectives(_createElementVNode("input", {
            id: "value",
            "onUpdate:modelValue": ($event: any) => ((obj.value) = $event),
            class: "relative mt-1 w-full appearance-none border bg-white p-2 text-left font-sans leading-normal text-dark shadow-sm outline-none focus:border-primary",
            type: "number",
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateValue())),
            min: "0"
          }, null, 40, _hoisted_7), [
            [_vModelText, obj.value]
          ])
        ]),
        _createVNode(_component_the_button, {
          icon: _ctx.isDeleteButton(index) ? ['fas', 'trash'] : ['fas', 'add'],
          type: "button",
          onExecuteMethod: ($event: any) => (
          _ctx.isDeleteButton(index) ? _ctx.deleteValue(obj.key, index) : _ctx.addValue()
        )
        }, null, 8, ["icon", "onExecuteMethod"])
      ]))
    }), 256))
  ]))
}