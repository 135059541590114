import L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { MapObject } from "@/modules/MapView/Services/Types/MapObjectTypes";
import CoordinateConversionService from "@/modules/MapView/Services/CoordinateConversionService";
import { Polygon } from "geojson";
import { PolylineOptionsExtended } from "node_modules/@types/leaflet/index.d.ts";
import { ZoneMapping } from "@/modules/MapView/Services/Types/ZoneTypes";

export default class ZoneService {
  map: L.Map;
  groups = {
    zones: new L.FeatureGroup(),
  };
  zoneCreate: L.Polygon | null = null;
  isZoneFinishedDrawing = false;
  coordinateConversionService = new CoordinateConversionService();
  createdZoneWktCoordinates = "";
  editedZoneWktCoordinates = "";
  private tooltip: L.Tooltip | null = null;

  constructor(map: L.Map) {
    this.map = map;
  }

  async addZoneToMap() {
    this.map.pm.enableDraw("Polygon", {
      snappable: true,
      snapDistance: 20,
    });

    this.map.on("pm:create", (drawnShape) => {
      this.zoneCreate = drawnShape.layer as L.Polygon;
      this.zoneCreate.setStyle({ color: "red" });
      this.isZoneFinishedDrawing = true;
      this.createdZoneWktCoordinates =
        this.coordinateConversionService.convertToWkt(this.zoneCreate);
    });
  }

  drawZonesOnMap(zones: MapObject[], zoneMapping: Map<number, ZoneMapping>) {
    this.groups.zones.addTo(this.map);

    zones.forEach((zone) => {
      const converted = this.coordinateConversionService.convertToGeoJson(
        zone.geometry
      ) as Polygon;
      const convertedCoordinates = converted.coordinates[0].map(
        ([lng, lat]) => {
          return new L.LatLng(lat, lng);
        }
      );

      const polygon = new L.Polygon(convertedCoordinates, {
        id: zone.id,
      } as PolylineOptionsExtended);

      // this._addTooltipToZone(polygon, zone);
      polygon.setStyle({ color: zone?.data?.fillColor });
      zoneMapping.set(zone!.id, { polygon: polygon, zoneData: zone });

      polygon
        .bindTooltip(zone.data.name, {
          permanent: true,
          direction: "center",
        })
        .openTooltip();

      this._addEditEventToZone(polygon);

      this.groups.zones.addLayer(polygon);
    });

    if (!zones.length) {
      return;
    }

    this._fitZoneBounds();
  }

  cancelZoneCreation() {
    this.map.pm.disableDraw();
    this.zoneCreate?.remove();
  }

  removeZonesFromMap() {
    this.groups.zones.clearLayers();
    this.map.removeLayer(this.groups.zones);
  }

  //Build na linuxu nije prolazio zbog tipova za hasLayer metodu
  /* fitSelectedZoneBounds(zone: L.Polygon | null) {
    if (!zone) {
      return;
    }
    if (this.groups.zones.hasLayer(zone)) {
      this.map.fitBounds(zone.getBounds());
    }
  } */

  fitSelectedZoneBounds(latLng: L.LatLngBounds) {
    this.map.fitBounds(latLng);
  }

  private _fitZoneBounds() {
    if (this.map.hasLayer(this.groups.zones)) {
      this.map.fitBounds(this.groups.zones.getBounds());
    }
  }

  private _addTooltipToZone(zone: L.Polygon, zoneData: MapObject) {
    zone.on("mouseover", (e) => this._handleZoneMouseover(e, zoneData));
    zone.on("mouseout", this._handleZoneMouseout);
  }

  private _handleZoneMouseover(e: L.LeafletMouseEvent, zoneData: MapObject) {
    this.tooltip = L.tooltip({
      permanent: true,
      direction: "center",
      className: "zone-tooltip",
    });

    this.tooltip.setContent(zoneData.data.name);
    this.tooltip.setLatLng(e.target.getCenter());
    this.tooltip.addTo(this.groups.zones);
  }

  private _handleZoneMouseout() {
    if (!this.tooltip) {
      return;
    }
    this.tooltip.closeTooltip();
  }

  private _addEditEventToZone(polygon: L.Polygon) {
    polygon.on("pm:edit", (e) => {
      const zone = e.layer as L.Polygon;
      this.editedZoneWktCoordinates =
        this.coordinateConversionService.convertToWkt(zone);
    });
  }
}
