<template>
  <modal-component
    :is-displayed="isSensorPacketsSentDisplayed"
    :is-full-screen-modal="true"
    :remove-outside-click="true"
    :title="title"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="px-6">
      <div class="mb-4 flex h-[46px] items-center space-x-4">
        <dx-date-box
          v-model="from"
          :height="46"
          :show-clear-button="true"
          display-format="dd.MM.yyyy HH:mm"
          type="datetime"
        />
        <dx-date-box
          v-model="to"
          :height="46"
          :show-clear-button="true"
          display-format="dd.MM.yyyy HH:mm"
          type="datetime"
        />
        <v-select
          v-model="channel"
          :options="chanelOptions"
          :reduce="(spot : any) => spot.id"
          class="min-w-[160px]"
          label="name"
          multiple
        />
        <the-button
          :icon="['fas', 'rotate-left']"
          button-type="primaryButton"
          @click="getSensorPackets"
        />
      </div>
      <div v-if="isLoading" class="flex w-full justify-center">
        <small-loader />
      </div>
      <custom-table
        v-else
        :allow-auto-resize="true"
        :is-action-list-not-displayed="true"
        :is-data-source-loading="isDataSourceLoading"
        :table-data="sensorPackets"
        :table-headers="tableHeaders"
        table-height="70vh"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import moment from "moment/moment";
import DxDateBox from "devextreme-vue/date-box";
import TheButton from "@/core/Components/TheButton.vue";
import VSelect from "vue-select";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import SensorService from "@/modules/Parkom/Submodules/Sensors/Services/SensorService";
import {
  PacketType,
  SensorSentPackets,
} from "@/modules/Parkom/Submodules/Sensors/Services/SensorTypes";
import Loader from "@/core/Components/Loader.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";

export default defineComponent({
  components: {
    SmallLoader,
    Loader,
    CustomTable,
    ModalComponent,
    DxDateBox,
    TheButton,
    VSelect,
  },
  props: {
    title: String,
    isSensorPacketsSentDisplayed: {
      type: Boolean,
      required: true,
    },
    mac: {
      type: String,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      sensorService: new SensorService(),
      channel: [
        "data",
        "keepalive",
        "hardware",        
        "icd",
        "firmwareUpdateStart",
        "firmwareUpdateNext"
      ] as PacketType[],
      from: moment().startOf("day").format("yyyy-MM-DD HH:mm"),
      to: moment().endOf("day").format("yyyy-MM-DD HH:mm"),
      toast: useToast(),
      isDataSourceLoading: false,
      isLoading: false,
      sensorPackets: [] as SensorSentPackets[],
      chanelOptions: [
        { id: "data", name: "Data" },
        { id: "keepalive", name: "Keep alive" },
        { id: "hardware", name: "Hardware" },        
        { id: "icd", name: "BLE tag"},
        { id: "firmwareUpdateStart", name: "Firmware Update Start" },
        { id: "firmwareUpdateNext", name: "Firmware Update Next" },
      ],
      tableHeaders: {
        rtc: { header: "SensorPackets.RTC", visible: true },
        storedAt: { header: "SensorPackets.StoredAt", visible: true },
        timeDifference: {
          header: "SensorPackets.TimeDifference",
          visible: true
        },
        pType: { header: "SensorPackets.Type", visible: true },        
        parkingState: { header: "SensorPackets.Status", visible: true },
        packetId: { header: "SensorPackets.Packets", visible: true },
        batteryPercentage: {
          header: "SensorPackets.BatteryPercentage",
          visible: true,
        },        
        batteryVoltage: {
          header: "SensorPackets.BatteryVoltage",
          visible: true,
        },
        totalConsumptionMAh: {
          header: "SensorPackets.TotalConsumption",
          visible: true,
        },
        firmwareVersion: {
          header: "SensorPackets.FirmwareVersion",
          visible: true,
        },
        rssi: { header: "SensorPackets.RSSI", visible: true },
        rsrq: { header: "SensorPackets.RSRQ", visible: true },
        cellId: { header: "SensorPackets.Cell", visible: true },
        ecl: { header: "SensorPackets.ECL", visible: true },
        band: { header: "SensorPackets.Band", visible: true },
        searchingForNetworkCount: {
          header: "SensorPackets.SearchingForNetworkCount",
          visible: true,
        },
        rsrp: { header: "SensorPackets.RSRP", visible: true },
        snr: { header: "SensorPackets.SNR", visible: true },
        // reservedData1: { header: "SensorPackets.MagX", visible: true },
        // reservedData2: { header: "SensorPackets.MagY", visible: true },
        // reservedData3: { header: "SensorPackets.MagZ", visible: true },
        mag1Temp: { header: "SensorPackets.Temperature", visible: true },
        mag1RawX: { header: "SensorPackets.MagX", visible: true },
        mag1RawY: { header: "SensorPackets.MagY", visible: true },
        mag1RawZ: { header: "SensorPackets.MagZ", visible: true },
        // reservedData4: {
        //   header: "SensorPackets.RadarWeightKap",
        //   visible: true,
        // },
        radarWeight: { header: "SensorPackets.RadarWeight", visible: true },
        // reservedData5: {
        //   header: "SensorPackets.RadarDistance",
        //   visible: true,
        // },
        radarDistance: { header: "SensorPackets.RadarDistance", visible: true },
        magdiff: { header: "SensorPackets.MagDiff", visible: true },
        unhandledRestartCounter: {
          header: "SensorPackets.UnhandledRestartCounter",
          visible: true,
        },
        modemRebootCount: {
          header: "SensorPackets.ModemRebootCount",
          visible: true,
        },
        powerOnCount: { header: "SensorPackets.PowerOnCount", visible: true },
        runtimeTotalSeconds: {
          header: "SensorPackets.RuntimeTotalSeconds",
          visible: true,
        },
        imei: { header: "SensorPackets.IMEI", visible: true },
        imsi: { header: "SensorPackets.IMSI", visible: true },        
        //totalSleep: { header: "SensorPackets.TotalSleep", visible: true },
        modemDisableCounter: {
          header: "SensorPackets.ModemDisableCounter",
          visible: true,
        },
        noResponseCounter: {
          header: "SensorPackets.NoResponseCounter",
          visible: true,
        },                
        bletags: { header: "SensorPackets.BleTags", visible: true },
        deviceState: { header: "SensorPackets.DeviceState", visible: true },
        fwStart: { header: "SensorPackets.FwUpdateStart", visible: true },
        fwEnd: { header: "SensorPackets.FwUpdateEnd", visible: true },
        version: { header: "SensorPackets.FwUpdateVersion", visible: true }
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getSensorPackets();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getSensorPackets() {
      this.isDataSourceLoading = true;
      try {
        this.sensorPackets = await this.sensorService.getSensorSentPackets({
          mac: this.mac,
          from: this.from,
          to: this.to,
          packetType: this.channel,
          pageNumber: 1,
          pageSize: 100000,
        });
        //console.log(this.sensorPackets);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
  },
});
</script>

<style scoped></style>
