<template>
  <modal-component
    :is-displayed="isSelectorModalOpened"
    :title="$t('SidebarMenu.SelectParkingSpaces')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div v-if="isLoading" class="flex w-full justify-center">
      <small-loader />
    </div>
    <div v-else class="px-6">
      <dx-tree-view
        id="parking-space-treeview"
        :items="parkingSpaceFilterStore.parkingSpaceTreeList"
        :select-nodes-recursive="true"
        data-structure="plain"
        display-expr="label"
        key-expr="id"
        parent-id-expr="categoryId"
        selection-mode="multiple"
        show-check-boxes-mode="selectAll"
        @selection-changed="onSelectionChanged"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import { ParkingSpaceTree } from "@/modules/Parkom/Services/DashboardTypes";
import { useToast } from "vue-toastification";
import DashboardService from "@/modules/Parkom/Services/DashboardService";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import DxTreeView from "devextreme-vue/tree-view";
import DevExpress from "devextreme";
import dxTreeView from "devextreme/ui/tree_view";
import { useParkingSpaceFilterStore } from "@/core/Store/useParkingSpaceFilterStore";
import EventInfo = DevExpress.events.EventInfo;

export default defineComponent({
  components: { SmallLoader, ModalComponent, DxTreeView },
  props: {
    isSelectorModalOpened: Boolean,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      dashboardService: new DashboardService(),
      parkingSpaceFilterStore: useParkingSpaceFilterStore(),
      toast: useToast(),
      isLoading: false,
      tempParkingSpaceIds: [] as number[],
    };
  },
  unmounted() {
    /* if (!this.tempParkingSpaceIds.length) {
      return;
    } */
    this.parkingSpaceFilterStore.selectedParkingSpaceIds =
      this.tempParkingSpaceIds;
  },

  methods: {
    async getParkingSpaceTreeList() {},
    onSelectionChanged(e: EventInfo<dxTreeView>) {
      const selectedParkingSpaces = e.component
        .getSelectedNodes()
        .map((x) => x.itemData) as ParkingSpaceTree[];

      this.tempParkingSpaceIds = selectedParkingSpaces
        .filter((x) => !!x.spaceId)
        .map((x) => x.spaceId);
    },
  },
});
</script>

<style scoped></style>
