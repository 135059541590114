<template>
  <modal-component
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    :title="$t('DeviceActions.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form @submit.prevent="handleSubmit">
      <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
        <form-divider>
          <input-field
            v-model="formData.name"
            :label="$t('DeviceActions.Name')"
            :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
            :isError="v$?.formData['name']?.$error"
            :required="true"
          />
          <json-editor-vue
            v-model="formData.value"
            :main-menu-bar="false"
            :status-bar="false"
            class="py-3"
            mode="text"
          />
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceActions.Device") }}</label
            >
            <v-select
              v-model="formData.deviceId"
              :getOptionLabel="(assignment : any) => assignment?.assignment?.name"
              :options="assignments"
              :reduce="(assignment : any) => assignment.deviceId"
              class="mt-1"
              :clearable="false"
              :disabled="true"
            ></v-select>
          </div>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceActions.DeviceActionType") }}</label
            >
            <v-select
              v-model="formData.deviceActionTypeId"
              :getOptionLabel="(actionType : any) => actionType.name"
              :options="deviceActionTypes"
              :reduce="(actionType : any) => actionType.id"
              class="mt-1"
            ></v-select>
          </div>
        </form-divider>
      </form-wrapper>
      <button-wrapper :is-submit-loading="isSubmitLoading" />
    </form>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
//@ts-ignore
import JsonEditorVue from "json-editor-vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import VSelect from "vue-select";
import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import { useToast } from "vue-toastification";
import {
  ErrorMessageFormatter,
  CheckObjectsForPatch,
} from "@/core/Services/GlobalFunctions";
import DeviceActionTypeService, {
  DeviceActionType,
} from "@/modules/DeviceActions/Services/DeviceActionTypeService";
import DeviceActionService, {
  DeviceAction,
} from "@/modules/DeviceActions/Services/DeviceActionService";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    VSelect,
    ButtonWrapper,
    JsonEditorVue,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isEditModalOpened: Boolean,
    refreshData: Function,
    deviceActionProp: {
      type: Object as PropType<DeviceAction>,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      assignmentService: new AssignmentService(),
      deviceActionTypeService: new DeviceActionTypeService(),
      deviceActionService: new DeviceActionService(),
      assignments: [] as DeviceAssignment[],
      deviceActionTypes: [] as DeviceActionType[],
      deviceAction: {} as DeviceAction,
      isSubmitLoading: false,
      isLoading: false,
      toast: useToast(),
      formData: {
        name: "",
        value: {},
        deviceId: null as number | null,
        deviceActionTypeId: null as number | null,
      },
      initialObject: {},
      v$: useValidate() as any,
      validationErrors: [] as any,
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await Promise.all([this.getAssignments(), this.getDeviceActionTypes()]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }

    this.formData.name = this.deviceActionProp.name;
    this.formData.value = this.deviceActionProp.value;
    this.formData.deviceId = this.deviceActionProp.deviceId;
    this.formData.deviceActionTypeId = this.deviceActionProp.deviceActionTypeId;

    Object.assign(this.initialObject, this.formData);
  },
  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          if (typeof this.formData.value === "string") {
            this.formData.value = JSON.parse(this.formData.value);
          }
          const patchObj = await CheckObjectsForPatch({
            initialObject: this.initialObject,
            editedObject: this.formData,
          });

          await this.deviceActionService.editAction({
            id: this.deviceActionProp.id,
            editAction: patchObj,
          });
          this.toast.success(this.$t("General.EditedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    async getAssignments() {
      try {
        this.assignments = await this.assignmentService.getAllDeviceAssignments(
          {
            typeOfAssignment: "Company",
            pageNumber: 1,
            pageSize: 100000,
          }
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getDeviceActionTypes() {
      try {
        this.deviceActionTypes =
          await this.deviceActionTypeService.getAllDeviceActionTypes({
            pageNumber: 1,
            pageSize: 100000,
          });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
