<template>
  <div
    :class="`grid h-full w-full ${
      noGap ? 'gap-0' : 'gap-4'
    } ${generateGridColTemplateString()} ${generateGridRowTemplateString()} ${
      centerItems && 'place-items-center'
    } ${padding}`"
  >
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    columnNumber: Number,
    rowNumber: Number,
    centerItems: Boolean,
    padding: String,
    noGap: Boolean,
  },

  methods: {
    generateGridColTemplateString() {
      switch (this.columnNumber) {
        case 1:
          return "grid-cols-1";
        case 2:
          return "grid-cols-2";
        case 3:
          return "grid-cols-3";
        case 4:
          return "grid-cols-4";
        case 5:
          return "grid-cols-5";
        case 6:
          return "grid-cols-6";
      }
    },
    generateGridRowTemplateString() {
      if (!this.rowNumber) {
        return;
      }
      switch (this.rowNumber) {
        case 1:
          return "grid-rows-1";
        case 2:
          return "grid-rows-2";
        case 3:
          return "grid-rows-3";
        case 4:
          return "grid-rows-4";
        case 5:
          return "grid-rows-5";
        case 6:
          return "grid-rows-6";
      }
    },
  },
});
</script>
<style scoped></style>
