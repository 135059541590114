<template>
  <modal
    :is-displayed="isCreateModalOpened"
    :is-select-modal="true"
    :no-padding="true"
    title="Create asset"
    @toggle-modal="$emit('toggle-modal')"
  >
    <map-location-selector
      v-if="isMapLocationModalOpened"
      :coordinates-prop="{
        lat: deviceAssignment.assignmentRequest.latitude,
        lng: deviceAssignment.assignmentRequest.longitude,
      }"
      :is-map-location-modal-opened="isMapLocationModalOpened"
      @toggle-modal="toggleMapLocationModal"
      @get-coordinates="getMarkerCoordinates"
    />
    <div class="flex h-full w-full items-center justify-center">
      <form-wrapper :is-form-in-modal="isFormInModal">
        <form @submit.prevent="handleSubmit">
          <form-divider>
            <input-field
              v-model="deviceAssignment.assignmentRequest.name"
              :errorMessage="
                v$?.deviceAssignment?.assignmentRequest['name']?.$errors[0]
                  ?.$message
              "
              :isError="v$?.deviceAssignment?.assignmentRequest['name']?.$error"
              :required="true"
              label="Name"
              placeholder="Enter device name..."
            />
            <div
              class="grid grid-cols-2 gap-6"
              style="grid-template-columns: auto min-content"
            >
              <div>
                <input-field
                  v-model="deviceAssignment.assignmentRequest.latitude"
                  label="Latitude"
                />
                <input-field
                  v-model="deviceAssignment.assignmentRequest.longitude"
                  label="Longitude"
                />
              </div>
              <div class="pb-3 pt-10">
                <the-button
                  :icon="['fas', 'earth-europe']"
                  button-type="primaryButton"
                  type="button"
                  @execute-method="toggleMapLocationModal"
                />
              </div>
            </div>
            <div class="relative items-center py-4">
              <label
                class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                >Group</label
              >
              <div class="flex items-center justify-center">
                <small-loader
                  v-if="isApplicationListLoading"
                  height="h-8"
                  width="w-8"
                />
                <v-select
                  v-else
                  v-model="deviceAssignment.assignmentRequest.applicationId"
                  :class="`mt-1 w-full border ${
                    v$.deviceAssignment?.assignmentRequest['applicationId']
                      ?.$error
                      ? 'rounded-md border-error'
                      : 'border-none'
                  }`"
                  :options="applications.items"
                  :reduce="(spot : any) => spot.id"
                  label="name"
                  :clearable="false"
                ></v-select>
              </div>
              <span
                v-if="
                  v$.deviceAssignment?.assignmentRequest['applicationId']
                    ?.$error
                "
                class="error-message text-sm text-error"
                >{{
                  v$?.deviceAssignment?.assignmentRequest["applicationId"]
                    ?.$errors[0]?.$message
                }}</span
              >
            </div>
            <div class="relative items-center py-4">
              <label
                class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                >Device type</label
              >
              <div class="flex items-center justify-center">
                <small-loader
                  v-if="isDeviceTypeListLoading"
                  height="h-8"
                  width="w-8"
                />
                <v-select
                  v-else
                  v-model="deviceAssignment.deviceRequest!.deviceTypeId"
                  :class="`mt-1 w-full`"
                  :options="deviceTypes"
                  :reduce="(spot : any) => spot.id"
                  label="name"
                  :clearable="false"
                ></v-select>
              </div>
            </div>
          </form-divider>
          <button-wrapper :is-submit-loading="isSubmitLoading" />
        </form>
      </form-wrapper>
    </div>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import MainSection from "../../../../../core/Components/MainSection.vue";
import TheButton from "../../../../../core/Components/TheButton.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import VSelect from "vue-select";
import ApplicationService, {
  ApplicationObject,
} from "@/modules/Applications/Services/ApplicationService";
import Modal from "@/core/Components/Modal.vue";
import SelectTable from "@/core/Components/SelectTable.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Loader from "@/core/Components/Loader.vue";
import UserService, { User } from "@/modules/Users/Services/UserService";
import { maxLength, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import { useUserStore } from "@/core/Store/userStore";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { useToast } from "vue-toastification";
import AssignmentService, {
  CreateAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import MapLocationSelector from "@/core/Components/MapLocationSelector.vue";
import DeviceTypeService, {
  DeviceType,
} from "@/modules/Devices/Services/DeviceTypeService";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    MapLocationSelector,
    InputField,
    MainSection,
    TheButton,
    FormWrapper,
    VSelect,
    Modal,
    SelectTable,
    Loader,
    ButtonWrapper,
    FormDivider,
    SmallLoader,
  },
  props: {
    isFormInModal: Boolean,
    refreshData: Function,
    isCreateModalOpened: Boolean,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      applicationService: new ApplicationService(),
      assignmentService: new AssignmentService(),
      deviceTypeService: new DeviceTypeService(),
      userService: new UserService(),
      userStore: useUserStore(),
      toast: useToast(),
      v$: useValidate() as any,
      applications: {} as ApplicationObject,
      deviceTypes: [] as DeviceType[],
      modalTitle: "",
      isSubmitLoading: false,
      isLoading: false,
      isMapLocationModalOpened: false,
      userID: 0 as number,
      user: {} as User,
      isApplicationListLoading: false,
      isDeviceTypeListLoading: false,
      validationErrors: [] as any,
      deviceAssignment: {
        assignmentRequest: {
          name: "",
          applicationId: 0,
          latitude: 0,
          longitude: 0,
        },
        deviceRequest: {
          deviceTypeId: 0,
        },
      } as CreateAssignment,
    };
  },

  validations() {
    return {
      deviceAssignment: {
        assignmentRequest: {
          name: {
            required,
            maxLength: maxLength(50),
            specialCharacters: specialCharactersValidator,
          },
          applicationId: {
            required,
          },
        },
      },
    };
  },

  async created() {
    try {
      await Promise.all([this.getAllApplications(), this.getAllDeviceTypes()]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },

  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          await this.assignmentService.createDeviceAssignment(
            this.deviceAssignment
          );
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          await this.refreshData?.();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },

    async getAllApplications() {
      this.isApplicationListLoading = true;
      try {
        this.applications = await this.applicationService.getAllApplications(
          1,
          100000
        );
        this.deviceAssignment.assignmentRequest.applicationId = this
          .applications?.items
          ? this.applications.items[0].id
          : 0;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isApplicationListLoading = false;
      }
    },

    async getAllDeviceTypes() {
      this.isDeviceTypeListLoading = true;
      try {
        this.deviceTypes = await this.deviceTypeService.getAllDeviceTypes(
          1,
          100000
        );
        if (!this.deviceAssignment.deviceRequest) {
          return;
        }
        this.deviceAssignment.deviceRequest.deviceTypeId =
          this.deviceTypes[0].id;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDeviceTypeListLoading = false;
      }
    },

    toggleMapLocationModal() {
      this.isMapLocationModalOpened = !this.isMapLocationModalOpened;
    },
    getMarkerCoordinates(coordinates: { lat: number; lng: number }) {
      this.deviceAssignment.assignmentRequest.latitude = coordinates.lat;
      this.deviceAssignment.assignmentRequest.longitude = coordinates.lng;
    },
  },
});
</script>
<style scoped></style>
