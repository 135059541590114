import Keycloak, { KeycloakConfig } from "keycloak-js";
import ApiService from "../Axios/ApiService";
import NotificationService from "../Services/NotificationService";
import { useUserStore } from "@/core/Store/userStore";

class KeycloakService {
  private keycloak;
  private doLogin;
  public doLogout;
  private refreshTokenValue;
  private token;
  private userName;
  private baseUrl;

  constructor(
    keycloakUrl: string,
    realm: string,
    clientId: string,
    baseUrl: string
  ) {
    const initOptions: KeycloakConfig = {
      url: keycloakUrl,
      realm: realm,
      clientId:
        window.location.host === "sick-web.spotium.hr"
          ? "sick-amr-ui"
          : clientId,
    };

    this.keycloak = new Keycloak(initOptions);
    this.doLogin = this.keycloak.login;
    this.doLogout = this.keycloak.logout;
    this.refreshTokenValue = this.keycloak.refreshToken;
    this.token = this.keycloak.token;
    this.userName = this.keycloak.tokenParsed;
    this.baseUrl = baseUrl;
  }

  public initKeycloak(application: any) {
    try {
      this.keycloak
        .init({
          onLoad: "login-required",
          silentCheckSsoFallback: false,
          pkceMethod: "S256",
          checkLoginIframe: false,
        })
        .then(async (authenticated: any) => {
          /* const notificationService = new NotificationService(); */
          if (authenticated) {
            const userStore = useUserStore();
            ApiService.init(
              process.env.NODE_ENV === "production" ? this.baseUrl : null,
              this.keycloak.token
            );
            localStorage.setItem("keycloakToken", this.keycloak.token || "");
            //console.log(this.keycloak?.idTokenParsed);
            await userStore.getUserId(
              this.keycloak?.idTokenParsed?.email,
              this.keycloak?.idTokenParsed?.preferred_username
            );
            await application(this.keycloak.tokenParsed);
            // await notificationService.sendExternalId(keycloak);
          } else {
            this.doLogin();
          }
        });
    } catch (error) {
      console.log(error);
    }

    this.keycloak.onTokenExpired = () => {
      this.keycloak
        .updateToken(28800)
        .then((value: boolean) => {
          if (value) {
            ApiService.init(
              process.env.NODE_ENV === "production" ? this.baseUrl : null,
              this.keycloak.token
            );
            localStorage.setItem("keycloakToken", this.keycloak.token || "");
          } else {
            console.log("not refreshed");
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    };
  }
}

export default KeycloakService;
