<template>
  <div class="flex h-full w-full items-center justify-center">
    <form-wrapper :is-form-in-modal="isFormInModal" :is-loading="isLoading">
      <div class="relative items-center px-6 py-4">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >Integration type</label
        >
        <v-select
          v-model="integrationTypeId"
          :disabled="true"
          :options="integrationTypes"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <div class="relative items-center px-6">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >Group</label
        >
        <v-select
          v-model="applicationId"
          :options="applications"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <div>
        <component
          :is="integrationTypesEditTemplate[integrationTypeId]"
          :applicationId="applicationId"
          :data="integration"
          :integrationTypeId="integrationTypeId"
          :refresh-data="refreshData"
        ></component>
      </div>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "../../../core/Components/MainSection.vue";
import FormWrapper from "../../../core/Components/FormElements/FormWrapper.vue";
import VSelect from "vue-select";
import IntegrationService, {
  Integration,
  IntegrationType,
} from "../Services/IntegrationService";
import HTTPEdit from "./HTTPEdit.vue";
import UDPEdit from "./UDPEdit.vue";
import WSEdit from "./WSEdit.vue";
import InvocationEdit from "./InvocationEdit.vue";
import Loader from "../../../core/Components/Loader.vue";
import { IntegrationTypesEditTemplate } from "../Services/Helpers";
import MQTTEdit from "./MQTTEdit.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ApplicationService, {
  Application,
} from "@/modules/Applications/Services/ApplicationService";
import TranslationEdit from "./TranslationEdit.vue";
import { useToast } from "vue-toastification";
import InputField from "@/core/Components/FormElements/InputField.vue";

export default defineComponent({
  components: {
    InputField,
    MainSection,
    FormWrapper,
    VSelect,
    HTTPEdit,
    UDPEdit,
    WSEdit,
    Loader,
    MQTTEdit,
    TranslationEdit,
    InvocationEdit,
  },
  props: {
    isFormInModal: Boolean,
    objectId: { type: Number, required: true },
    refreshData: Function,
  },
  data() {
    return {
      integrationTypeId: 1,
      integrationTypes: [] as IntegrationType[],
      integrationService: new IntegrationService(),
      applicationService: new ApplicationService(),
      applications: [] as Application[],
      isLoading: false as boolean,
      integrationTypesEditTemplate: IntegrationTypesEditTemplate as any,
      applicationId: 1,
      executionOrder: null as number | null,
      integration: {} as Integration,
      toast: useToast(),
      id: Number((this as any).$route.params.integrationId),
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getIntegrationTypes();
      await this.getAllApplications();
      await this.getIntegration();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async getIntegrationTypes() {
      try {
        this.integrationTypes =
          await this.integrationService.getIntegrationTypes();
        this.integrationTypes.push({
          id: 997,
          name: "Translation integration",
          config: null,
        });
        // this.integrationTypes.push({id: 998, name: "Rule engine", config: null});
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getIntegration() {
      try {
        this.integration =
          await this.integrationService.getIntegrationWithIdentifier(
            this.objectId
          );
        //@ts-ignore
        this.integrationTypeId = this.integration.integrationTypeId;
        this.applicationId = this.integration.applicationId;
        this.executionOrder = this.integration.executionOrder;

        if (!this.integration.config) {
          return;
        }

        if (!this.integration.config.daprAppId) {
          return;
        }

        if (
          this.integration.config.daprAppId ===
          "platform-amr-rule-engine-gateway"
        ) {
          this.integrationTypeId = 998;
        }
        if (this.integration.config.daprAppId === "platform-translation-api") {
          this.integrationTypeId = 997;
        }
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getAllApplications() {
      try {
        const response = await this.applicationService.getAllApplications(
          1,
          10000
        );
        this.applications = response.items;

        if (this.applications.length && this.applications[0].id) {
          this.applicationId = this.applications[0].id;
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>
<style scoped></style>
