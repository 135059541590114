import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_form = _resolveComponent("create-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_add_to_order = _resolveComponent("add-to-order")!
  const _component_view_order_devices = _resolveComponent("view-order-devices")!
  const _component_apply_confirmation_modal = _resolveComponent("apply-confirmation-modal")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 0,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "refresh-data": _ctx.getWorkOrders,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCreateModalOpened = false))
        }, null, 8, ["is-create-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 1,
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "refresh-data": _ctx.getWorkOrders,
          "work-order-prop": _ctx.selectedWorkOrder,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditModalOpened = false))
        }, null, 8, ["is-edit-modal-opened", "refresh-data", "work-order-prop"]))
      : _createCommentVNode("", true),
    _createVNode(_component_delete_modal, {
      "is-deleting": _ctx.isDeleteModalOpened,
      onCancelDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
      onToggleModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
      onConfirmDelete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openConfirmationModal('delete')))
    }, null, 8, ["is-deleting"]),
    _createVNode(_component_confirmation_modal, {
      "delete-text": _ctx.confirmationModalText,
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      onConfirmAction: _ctx.confirmationModalAction,
      onCloseConfirmationModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
    }, null, 8, ["delete-text", "is-confirmation-modal-opened", "onConfirmAction"]),
    (_ctx.isAddToOrderModalOpened)
      ? (_openBlock(), _createBlock(_component_add_to_order, {
          key: 2,
          "is-add-to-order-modal-opened": _ctx.isAddToOrderModalOpened,
          "refresh-data": _ctx.getWorkOrders,
          "work-order-id": _ctx.selectedWorkOrder.id,
          onToggleModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isAddToOrderModalOpened = false))
        }, null, 8, ["is-add-to-order-modal-opened", "refresh-data", "work-order-id"]))
      : _createCommentVNode("", true),
    (_ctx.isViewDevicesModalOpened)
      ? (_openBlock(), _createBlock(_component_view_order_devices, {
          key: 3,
          "is-view-devices-modal-opened": _ctx.isViewDevicesModalOpened,
          "work-order-id": _ctx.selectedWorkOrder.id,
          onToggleModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isViewDevicesModalOpened = false))
        }, null, 8, ["is-view-devices-modal-opened", "work-order-id"]))
      : _createCommentVNode("", true),
    _createVNode(_component_apply_confirmation_modal, {
      "is-apply-confirmation-modal-opened": _ctx.isApplyConfirmationModalOpened,
      "is-submit-loading": _ctx.isApplyWorkOrderSubmitLoading,
      "work-order-id": _ctx.selectedWorkOrder.id,
      onToggleModal: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isApplyConfirmationModalOpened = false)),
      onApplyWorkOrder: _ctx.applyWorkOrder
    }, null, 8, ["is-apply-confirmation-modal-opened", "is-submit-loading", "work-order-id", "onApplyWorkOrder"]),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateWorkOrder,
                title: _ctx.$t('WorkOrders.Title'),
                "button-text": "Create",
                onExecuteMethod: _ctx.toggleCreateModal
              }, null, 8, ["permission-id", "title", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                options: _ctx.options,
                "render-summary": ['company.name'],
                "table-data": _ctx.workOrders,
                "table-headers": _ctx.tableHeaders,
                onApply: _ctx.toggleApplyConfirmationOrder,
                onSelectedRows: _ctx.getSelectedRows,
                onForceRefresh: _ctx.getWorkOrders
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onApply", "onSelectedRows", "onForceRefresh"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}