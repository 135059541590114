<template>
  <div
    v-click-away="isMoreOptionsDropdownOpened && toggleDropdown"
    class="relative"
  >
    <svg
      class="cursor-pointer fill-darkgrey hover:fill-primary"
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      @click="toggleDropdown"
    >
      <path
        d="M12 20q-.825 0-1.412-.587Q10 18.825 10 18q0-.825.588-1.413Q11.175 16 12 16t1.413.587Q14 17.175 14 18q0 .825-.587 1.413Q12.825 20 12 20Zm0-6q-.825 0-1.412-.588Q10 12.825 10 12t.588-1.413Q11.175 10 12 10t1.413.587Q14 11.175 14 12q0 .825-.587 1.412Q12.825 14 12 14Zm0-6q-.825 0-1.412-.588Q10 6.825 10 6t.588-1.412Q11.175 4 12 4t1.413.588Q14 5.175 14 6t-.587 1.412Q12.825 8 12 8Z"
      />
    </svg>
    <dropdown-wrapper :is-dropdown-opened="isMoreOptionsDropdownOpened">
      <div>
        <div
          class="flex cursor-pointer items-center justify-between rounded-md py-0.5 pl-3 hover:bg-lightgray"
        >
          <p class="text-dark">Development</p>
          <vue-toggle
            :active-color="'#007cc1'"
            :title="''"
            :toggled="moduleToggleStore.isDevelopment"
            name="ParkingManager"
            @toggle="moduleToggleStore.toggleDevelopment()"
          />
        </div>
      </div>
      <!--      <div v-else class="text-dark py-2 text-center">-->
      <!--        You have no modules to toggle!-->
      <!--      </div>-->
    </dropdown-wrapper>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useModuleToggleStore } from "@/core/Store/moduleToggleStore";
//@ts-ignore
import VueToggle from "vue-toggle-component";
import DropdownWrapper from "@/core/Components/Dropdowns/DropdownWrapper.vue";

export default defineComponent({
  components: {
    DropdownWrapper,
    VueToggle,
  },
  data() {
    return {
      moduleToggleStore: useModuleToggleStore(),
      // resourceService: new ResourceService(),
      isMoreOptionsDropdownOpened: false,
      // resources: [] as Resource[]
    };
  },
  // async created() {
  //   try {
  //     this.resources = await this.resourceService.getAllResources();
  //   } catch (error) {
  //     console.log(error)
  //   }
  // },
  methods: {
    toggleDropdown() {
      this.isMoreOptionsDropdownOpened = !this.isMoreOptionsDropdownOpened;
    },
  },
});
</script>

<style scoped></style>
