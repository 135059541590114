<template>
  <div class="flex items-center justify-end space-x-6 bg-gray-50 px-6 py-4">
    <the-button
      v-if="hasReturnToListButton"
      :icon="['fas', 'chevron-left']"
      button-type="linkButton"
      icon-color="text-darkgrey"
      text="Return to list"
      type="button"
      @execute-method="$emit('return-to-list')"
    />
    <the-button
      :isLoading="isSubmitLoading"
      :text="text ? text : $t('General.Save')"
      :icon="icon && icon"
      button-type="primaryButton"
      @execute-method="$emit('execute-method')"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TheButton from "../TheButton.vue";

export default defineComponent({
  components: { TheButton },
  props: ["isSubmitLoading", "text", "hasReturnToListButton", "icon"],
  emits: ["execute-method", "return-to-list"],
});
</script>
<style scoped></style>
