<template>
  <div
    v-for="count in parkingSpotCount"
    :key="count.name"
    :class="`flex  w-full items-center justify-between space-y-1 border border-gray-300 p-4 shadow-sm`"
  >
    <div>
      <h2 :class="`text-3xl font-bold ${count.color}`">{{ count.count }}</h2>
      <h4 class="text-md">{{ $t(count.name) }}</h4>
    </div>
    <div class="flex items-center space-x-4">
      <div v-if="checkIfSpaceContainsType(count, 1)" class="text-center">
        <font-awesome-icon :icon="['fas', 'car']" class="text-gray-400" />
        <p :class="count.color">
          {{ count.occupationByType[0]?.count ?? 0 }}
        </p>
      </div>
      <div v-if="checkIfSpaceContainsType(count, 2)" class="text-center">
        <font-awesome-icon
          :icon="['fas', 'wheelchair']"
          class="text-gray-400"
        />
        <p :class="count.color">
          {{ count.occupationByType[1]?.count ?? 0 }}
        </p>
      </div>
      <div v-if="checkIfSpaceContainsType(count, 3)" class="text-center">
        <font-awesome-icon
          :icon="['fas', 'charging-station']"
          class="text-gray-400"
        />
        <p :class="count.color">
          {{ count.occupationByType[2]?.count ?? 0 }}
        </p>
      </div>
    </div>
    <div
      :class="`${count.bgColor} flex h-14 w-14 items-center justify-center rounded-full`"
    >
      <font-awesome-icon :class="`${count.color} text-xl`" :icon="count.icon" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ParkingSpotConfig } from "@/modules/Parkom/Services/DashboardTypes";

export default defineComponent({
  name: "parking-status-card",
  components: { FontAwesomeIcon },
  props: {
    parkingSpotCount: Array as PropType<ParkingSpotConfig[]>,
  },

  watch: {
    parkingSpotCount: {
      handler: function (val) {
        //console.log(val);
      },
      deep: true,
    },
  },

  methods: {
    checkIfSpaceContainsType(count: ParkingSpotConfig, typeId: number) {
      return count.occupationByType.find(
        (x: { typeId: number; count: number }) => x.typeId === typeId
      );
    },
  },
});
</script>
