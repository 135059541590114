import { RouteRecordRaw } from "vue-router";
import Index from "@/modules/Users/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createUserIndexRoute(): RouteRecordRaw {
  return {
    name: "users",
    path: "/users",
    component: Index,
    meta: {
      id: "users",
      label: "Sidebar.Users",
      icon: ["fas", "user"],
      showInNavigation: true,
      group: "main",
      authorize: [Permissions.ViewCompanyUser],
    },
  };
}
