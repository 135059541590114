import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-104baaa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "modalContent",
  class: "px-6"
}
const _hoisted_2 = {
  id: "map",
  class: "z-[150] my-auto h-[700px] w-full"
}
const _hoisted_3 = { class: "manage-spots-distance-box" }
const _hoisted_4 = { class: "flex items-center justify-between py-4" }
const _hoisted_5 = { class: "flex items-center space-x-4" }
const _hoisted_6 = { class: "flex items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_modal = _resolveComponent("info-modal")!
  const _component_map_loader = _resolveComponent("map-loader")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isManageParkingSpotsModalOpened,
    "is-full-screen-modal": true,
    "no-padding": true,
    title: "Manage spots",
    onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      (_ctx.isInfoModalOpened)
        ? (_openBlock(), _createBlock(_component_info_modal, {
            key: 0,
            "is-info-modal-opened": _ctx.isInfoModalOpened,
            onToggleModal: _ctx.toggleInfoModal
          }, null, 8, ["is-info-modal-opened", "onToggleModal"]))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_map_loader, { key: 1 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, " Distance between spots: " + _toDisplayString(this.markerSelectService.distanceBetweenSpots == undefined ? 0 : this.markerSelectService.distanceBetweenSpots .toFixed(2)) + "m ", 1),
            _createElementVNode("div", null, " Total distance: " + _toDisplayString(this.markerSelectService.distanceBetweenFirstAndLastSelected ==
              undefined
                ? 0
                : this.markerSelectService.distanceBetweenFirstAndLastSelected.toFixed(
                    2
                  )) + "m ", 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_the_button, {
              "button-type": "outlineButton",
              text: "Info",
              onExecuteMethod: _ctx.toggleInfoModal
            }, null, 8, ["onExecuteMethod"]),
            _createVNode(_component_the_button, {
              "button-type": "outlineButton",
              text: "Recalculate names"
            }),
            _createVNode(_component_the_button, {
              "button-type": "outlineButton",
              text: "Reset parking spots position",
              onExecuteMethod: _ctx.resetParkingSpotPositions
            }, null, 8, ["onExecuteMethod"]),
            _createVNode(_component_the_button, {
              "button-type": "primaryButton",
              text: "Edit",
              "is-disabled": _ctx.markerSelectService.isEditButtonDisabled
            }, null, 8, ["is-disabled"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_the_button, {
              "is-loading": _ctx.isLoading,
              "button-type": "primaryButton",
              text: "Save",
              onExecuteMethod: _ctx.savePositions
            }, null, 8, ["is-loading", "onExecuteMethod"]),
            _createVNode(_component_the_button, {
              "button-type": "outlineButton",
              text: "Close",
              onExecuteMethod: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle-modal')))
            })
          ])
        ])
      ], 512)
    ]),
    _: 1
  }, 8, ["is-displayed"]))
}