<template>
  <div class="flex items-center space-x-4 mt-6">
    <button class="flex items-center justify-center border h-12 w-12" @click="$emit('add-click')" type="button">
      <font-awesome-icon class="w-5 h-5" :icon="['fas', 'plus']" />
    </button>
    <button
      class="flex items-center justify-center border h-12 w-12 delete-button"
      :disabled="calculateNumberOfVisibleAccesses() <= 1"
      @click="$emit('remove-click')"
      type="button"
    >
      <font-awesome-icon :class="` w-5 h-5`" :icon="['fas', 'trash']" />
    </button>
  </div>
</template>
<script lang="ts">
import { Access } from "@/modules/AccessManager/SubModules/ProfileConfiguration/Services/ProfileConfigurationService";
import {defineComponent, PropType} from "vue";
import TheButton from "../TheButton.vue";
export default defineComponent({
  components: { TheButton },
  props: {
    index: {
      type: Number,
      required: true,
    },
    accesses: {
      type: Object as PropType<Access[]>,
      required: true,
    },
  },
  emits: ["add-click", "remove-click"],

  methods: {
    calculateNumberOfVisibleAccesses(){
      if (!this.accesses.length) {
        return 0
      }

      return this.accesses.filter((access : Access) => access.action !== 3).length
    }
  }
});
</script>
<style>
.delete-button:disabled,
.delete-button[disabled] {
  @apply text-gray-300;
}
</style>
