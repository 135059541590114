<template>
  <div
    class="mb-1 flex w-[350px] cursor-pointer items-center justify-between bg-primary p-2 text-white duration-300 ease-in-out hover:transform hover:font-semibold"
    @click="emitToggleDatagrid"
  >
    <div class="flex items-center gap-2">
      <font-awesome-icon v-if="icon" :icon="icon" />
      <p>{{ name }}</p>
    </div>
    <font-awesome-icon
      :class="`ml-2 transform ${
        isDropdownOpened ? 'rotate-90' : 'rotate-0'
      } duration-300 ease-out`"
      :icon="['fas', 'chevron-right']"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["name", "isDropdownOpened", "icon"],
  emits: ["toggle-datagrid"],
  methods: {
    emitToggleDatagrid() {
      this.$emit("toggle-datagrid");
    },
  },
});
</script>
<style scoped></style>
