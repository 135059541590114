<template>
  <form @submit.prevent="handleSubmit">
    <form-divider>
      <input-field
        v-model="formData.name"
        :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
        :isError="v$?.formData['name']?.$error"
        :label="$t('Integrations.Name')"
        :required="true"
      />
      <input-field
        v-model="formData.config.ip"
        :errorMessage="v$?.formData?.config['ip']?.$errors[0]?.$message"
        :isError="v$?.formData?.config['ip']?.$error"
        :label="$t('Integrations.IP')"
        :required="true"
      />
      <input-field
        v-model="formData.config.port"
        :errorMessage="v$?.formData?.config['port']?.$errors[0]?.$message"
        :isError="v$?.formData?.config['port']?.$error"
        :label="$t('Integrations.Port')"
        :required="true"
        type="number"
      />
      <multi-json-input @input="handleTopicChange" />
      <tags-input
        :label="$t('Integrations.Channels')"
        :tags="formData.config.channels"
        @on-tag-mutate="(tags) => (formData.config.channels = tags)"
      />
      <input-field
        v-model="formData.executionOrder"
        :label="$t('Integrations.ExecutionOrder')"
        type="number"
        :errorMessage="v$?.formData['executionOrder']?.$errors[0]?.$message"
        :isError="v$?.formData['executionOrder']?.$error"
      />
      <checkbox-input
        v-model="formData.isActive"
        :label="$t('Integrations.Active')"
      />
    </form-divider>
    <button-wrapper :is-submit-loading="isSubmitLoading" />
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import InputField from "../../../core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import VSelect from "vue-select";
import IntegrationService from "../Services/IntegrationService";
import checkIfObjectEmpty, {
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import { maxLength, required, minValue, maxValue } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import MultiJsonInput from "@/core/Components/FormElements/MultiJsonInput.vue";
import TagsInput from "@/core/Components/FormElements/TagsInput.vue";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    TagsInput,
    MultiJsonInput,
    InputField,
    VSelect,
    TheButton,
    CheckboxInput,
    FormDivider,
    ButtonWrapper,
  },
  props: ["integrationTypeId", "applicationId", "refreshData"],
  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      integrationService: new IntegrationService(),
      isSubmitLoading: false as boolean,
      topicToChannel: {} as Record<string, number>,
      toast: useToast(),
      formData: {
        integrationTypeId: this.integrationTypeId,
        executionOrder: null as any,
        name: "",
        config: {
          ip: "",
          port: 0,
          topicToChannel: null,
          channels: [] as any,
        },
        applicationId: this.applicationId,
        isActive: true,
      },
    };
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        executionOrder: {
          minValue: minValue(1),
        },
        config: {
          ip: {
            required,
          },
          port: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(65535),
          },
        },
      },
    };
  },

  watch: {
    applicationId(value) {
      this.formData.applicationId = value;
    },
  },

  methods: {
    errorMessageFormatter: ErrorMessageFormatter,
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          this.formData.config.channels = this.formData.config.channels.map(
            (channel: string) => Number(channel.trim())
          );

          this.formData.config.port = Number(this.formData.config.port);

          if (this.formData.config.channels.length === 0) {
            this.formData.config.channels = null;
          }

          if (
            this.formData.config.topicToChannel == undefined ||
            checkIfObjectEmpty(this.formData.config.topicToChannel)
          ) {
            this.formData.config.topicToChannel = null;
          }

          if (this.formData.executionOrder != null) {
            this.formData.executionOrder = Number(this.formData.executionOrder);
          }

          await this.integrationService.createIntegration(this.formData);
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          await this.refreshData();
        } catch (error: any) {
          this.toast.error(this.errorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    handleTopicChange(value: Record<string, number>) {
      //@ts-ignore
      this.formData.config.topicToChannel = value;
    },
  },
});
</script>
<style scoped></style>
