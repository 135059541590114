<template>
  <form @submit.prevent="handleSubmit">
    <form-divider>
      <input-field
        v-model="formData.name"
        :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
        :isError="v$?.formData['name']?.$error"
        :label="$t('Integrations.Name')"
        :required="true"
      />
      <checkbox-input
        v-model="formData.config.usePayloadFromResponse"
        :is-checked="formData.config.usePayloadFromResponse"
        label="Use payload from response"
      />
      <input-field
        v-model="formData.config.daprAppId"
        :errorMessage="v$?.formData['config'].daprAppId?.$errors[0]?.$message"
        :isError="v$?.formData['config'].daprAppId?.$error"
        :label="'App ID'"
        :required="true"
      />
      <input-field
        v-model="formData.config.url"
        :errorMessage="v$?.formData['config'].url?.$errors[0]?.$message"
        :isError="v$?.formData['config'].url?.$error"
        :label="'URL'"
        :required="true"
      />
      <div class="py-3">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >{{ $t("DeviceTypeMetadata.Data")
          }}<span class="pl-0.5 text-orange-600">*</span></label
        >
        <json-editor-vue
          v-model="formData.config.data"
          :main-menu-bar="false"
          :status-bar="false"
          :class="`mt-1 ${v$?.formData['config'].data?.$error && 'error'}`"
          mode="text"
        />
        <span
          v-if="v$?.formData['config'].data?.$error"
          class="mt-2 text-sm text-error"
          >{{ v$?.formData["config"].data?.$errors[0]?.$message }}
        </span>
      </div>
      <multi-json-input @input="handleTopicChange" />
      <tags-input
        :label="$t('Integrations.Channels')"
        :tags="formData.config.channels"
        @on-tag-mutate="(tags) => (formData.config.channels = tags)"
      />
      <input-field
        v-model="formData.executionOrder"
        :label="$t('Integrations.ExecutionOrder')"
        type="number"
      />
      <checkbox-input
        v-model="formData.isActive"
        :is-checked="formData.isActive"
        :label="$t('Integrations.Active')"
      />
    </form-divider>
    <button-wrapper :is-submit-loading="isSubmitLoading" />
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import InputField from "../../../core/Components/FormElements/InputField.vue";
import VSelect from "vue-select";
import TheButton from "../../../core/Components/TheButton.vue";
import IntegrationService, {
  CreateEditIntegration,
} from "../Services/IntegrationService";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import checkIfObjectEmpty, { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import {
  maxLength,
  required,
  requiredIf,
  minValue,
} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import MultiJsonInput from "@/core/Components/FormElements/MultiJsonInput.vue";
import ChannelsInput from "@/modules/Integrations/Components/ChannelsInput.vue";
import TagsInput from "@/core/Components/FormElements/TagsInput.vue";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";
//@ts-ignore
import JsonEditorVue from "json-editor-vue";

export default defineComponent({
  components: {
    TagsInput,
    JsonEditorVue,
    ChannelsInput,
    MultiJsonInput,
    InputField,
    VSelect,
    TheButton,
    CheckboxInput,
    FormDivider,
    ButtonWrapper,
  },
  props: ["integrationTypeId", "applicationId", "refreshData"],
  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      integrationService: new IntegrationService(),
      isSubmitLoading: false as boolean,
      topicToChannel: {} as Record<string, number>,
      toast: useToast(),
      formData: {
        name: "",
        executionOrder: null,
        integrationTypeId: 7,
        applicationId: this.applicationId,
        isActive: true,
        forwardOnlyPayload: false,
        config: {
          usePayloadFromResponse: false,
          daprAppId: "",
          url: "",
          topicToChannel: null,
          channels: [] as any,
          data: {},
        },
      },
    };
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        executionOrder: {
          minValue: minValue(1),
        },
        config: {
          daprAppId: {
            required,
            maxLength: maxLength(1000),
          },
          url: {
            required,
            maxLength: maxLength(1000),
          },
          data: {
            required,
          },
        },
      },
    };
  },

  watch: {
    integrationTypeId(value) {
      this.formData.integrationTypeId = value;
    },
    applicationId(value) {
      this.formData.applicationId = value;
    },
  },

  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          this.formData.config.channels = this.formData.config.channels.map(
            (channel: string) => Number(channel.trim())
          );

          if (typeof this.formData.config.data === "string") {
            this.formData.config.data = JSON.parse(this.formData.config.data);
          }

          if (this.formData.config.channels.length === 0) {
            this.formData.config.channels = null;
          }

          if (
            this.formData.config.topicToChannel != null &&
            checkIfObjectEmpty(this.formData.config.topicToChannel)
          ) {
            this.formData.config.topicToChannel = null;
          }

          await this.integrationService.createIntegration(this.formData);
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          await this.refreshData();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    handleTopicChange(value: Record<string, number>) {
      //@ts-ignore
      this.formData.config.topicToChannel = value;
    },
  },
});
</script>
<style scoped></style>
