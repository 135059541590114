<template>
  <dx-data-grid
    id="devextreme-table"
    ref="devextremeTable"
    v-model:filter-value="test"
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :column-auto-width="allowAutoResize"
    :data-source="tableData"
    :height="tableHeight && tableHeight"
    :hover-state-enabled="true"
    :remote-operations="false"
    :row-alternation-enabled="false"
    :show-borders="true"
    :show-row-lines="true"
    class="relative"
    show-scrollbar="always"
    width="100%"
    @exporting="onExporting"
    @selection-changed="onSelectionChanged"
    @row-prepared="onRowPrepared"
  >
    <DxScrolling v-if="!showPerPage" mode="virtual" />
    <div
      v-if="isDataSourceLoading"
      class="z-[1000] bg-[rgba(255,255,255,0.4)] absolute inset-0 flex justify-center"
    >
      <div class="mt-20">
        <small-loader height="h-16" width="w-16" />
      </div>
    </div>
    <dx-column
      v-if="isDevicesTable"
      :width="80"
      caption=""
      cell-template="deviceTypeId"
    />
    <dx-column
      v-for="(headerValue, headerKey) in tableHeaders"
      :key="headerKey"
      v-model:visible="headerValue['visible']"
      :allow-filtering="true"
      :caption="$t(headerValue['header'])"
      :cell-template="cellTemplateSelector(headerKey)"
      :customize-text="customizeText"
      :data-field="headerKey"
      :data-type="
        headerKey === 'lastTimeDeviceSentData' ? 'datetime' : 'string'
      "
      :group-index="headerValue['groupIndex']"
      :sort-order="headerValue['sortOrder']"
      alignment="left"
    />
    <DxFilterRow
      v-if="!removeTableHeader"
      :highlight-case-sensitive="false"
      :visible="true"
    />
    <DxGrouping v-if="!removeTableHeader" :context-menu-enabled="true" />
    <DxGroupPanel v-if="!removeTableHeader" :visible="true" />
    <DxGrouping v-if="!removeTableHeader" expand-mode="rowClick" />

    <dx-column
      v-if="isUsersDevicesSharedToDisplayed"
      :width="150"
      caption="Users"
      cell-template="usersDeviceIsSharedTo"
    />
    <dx-column
      v-if="isUsersRejectApproveShareDisplayed"
      :width="150"
      caption="Users"
      cell-template="usersRejectApproveShare"
    />
    <dx-column
      v-if="isRevokeActionDisplayed"
      :width="75"
      caption="Revoke"
      cell-template="revokeAction"
    />
    <dx-column
      v-if="isRejectApproveActionDisplayed"
      :width="150"
      caption="Reject/Approve"
      cell-template="rejectApproveAction"
    />

    <dx-column
      v-if="showDeviceActions"
      :fixed="true"
      :width="100"
      cell-template="deviceActions"
      fixed-position="left"
    />
    <dx-column
      v-if="
        assignColumn &&
        permissionStore.hasPermission(permissions.EditAssignment)
      "
      :caption="$t('Sensors.Assign')"
      cell-template="assignColumn"
    />
    <dx-column
      v-if="!moduleToggleStore.isDevelopment && isDevicesTable"
      :width="150"
      caption="View details"
      cell-template="openViewData"
    />
    <dx-column
      v-if="
        nodesColumn && permissionStore.hasPermission(permissions.EditAssignment)
      "
      :caption="'Nodes'"
      cell-template="manageNodes"
    />
    <dx-column
      v-if="!isActionListNotDisplayed"
      :width="50"
      cell-template="openMenu"
    />
    <dx-column-chooser
      v-if="!removeTableHeader"
      :enabled="true"
      mode="select"
    />
    <dx-export
      v-if="!removeTableHeader"
      :allow-export-selected-data="true"
      :enabled="true"
      :formats="['pdf', 'xlsx']"
    />
    <dx-group-panel v-if="!removeTableHeader" :visible="true" />
    <dx-search-panel
      v-if="!removeTableHeader"
      v-model:text="globalSearch"
      :visible="true"
    />
    <dx-pager
      v-if="!!showPerPage"
      :show-info="true"
      :show-navigation-buttons="true"
      :show-page-size-selector="true"
      :visible="!!showPerPage"
    />

    <dx-paging
      v-if="!!showPerPage"
      :enabled="!!showPerPage"
      :page-size="showPerPage"
    />
    <dx-selection
      v-if="!noSelection"
      :mode="multiselect ? 'multiple' : 'single'"
      select-all-mode="allPages"
    />
    <dx-header-filter :visible="true" />
    <dx-toolbar v-if="!removeTableHeader">
      <dx-item name="groupPanel" />
      <dx-item
        :options="refreshButtonOptions"
        location="after"
        name="refreshButton"
        widget="dxButton"
      />
      <dx-item name="columnChooserButton" />
      <dx-item name="exportButton" />
      <dx-item name="searchPanel" />

      <!-- <dx-item v-if="selectedRows.length > 1" template="actionsTemplate" /> -->
    </dx-toolbar>

    <template #usersDeviceIsSharedTo="{ data }">
      <p
        class="cursor-pointer underline"
        @click="$emit('users-device-is-shared-to', data.data.assignmentId)"
      >
        See users
      </p>
    </template>

    <template #usersRejectApproveShare="{ data }">
      <p
        class="cursor-pointer underline"
        @click="$emit('users-device-is-pending', data.data.assignmentId)"
      >
        See users
      </p>
    </template>

    <template #actionsTemplate>
      <dx-drop-down-button
        :drop-down-options="{ width: 250 }"
        :items="actionsOptions"
        text="Action"
        @item-click="$emit('action-click', $event)"
      />
    </template>

    <template #isActive="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.isActive ? 'bg-success' : 'bg-error'
          }`"
        ></div>
      </div>
    </template>

    <template #isCompanyActive="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.isCompanyActive ? 'bg-success' : 'bg-error'
          }`"
        ></div>
      </div>
    </template>

    <template #isProductionEnabled="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.isProductionEnabled ? 'bg-success' : 'bg-error'
          }`"
        ></div>
      </div>
    </template>

    <template #active="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.active ? 'bg-success' : 'bg-error'
          }`"
        ></div>
      </div>
    </template>

    <template #isSent="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.isSent ? 'bg-success' : 'bg-error'
          }`"
        ></div>
      </div>
    </template>

    <template #occupied="{ data }">
      <!--This check is here because there is a property occupied which is numeric value-->
      <div
        v-if="typeof data.data.occupied === 'boolean'"
        class="flex w-full items-center justify-center"
      >
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.occupied ? 'bg-error' : 'bg-success'
          } ${isMinDate(data.data?.occupiedTimestamp) ? '!bg-slate-400' : ''}`"
        ></div>
      </div>
      <div v-else>{{ data.data.occupied }}</div>
    </template>

    <template #visible="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.visible ? 'bg-success' : 'bg-error'
          }`"
        ></div>
      </div>
    </template>

    <template #public="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.public ? 'bg-success' : 'bg-error'
          }`"
        ></div>
      </div>
    </template>

    <template #navigable="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          :class="`h-3 w-3 rounded-full ${
            data.data.navigable ? 'bg-success' : 'bg-error'
          }`"
        ></div>
      </div>
    </template>

    <template #spotOccupied="{ data }">
      <div class="flex w-full items-center justify-center">
        <div
          v-if="data.data.spotOccupied !== null"
          :class="`h-3 w-3 rounded-full ${
            data.data.lastSync !== null
              ? syncDifferenceTime({
                  hours: 72,
                  lastSyncTime: data.data.lastSync,
                })
                ? 'bg-slate-400'
                : data.data.spotOccupied
                ? 'bg-error'
                : 'bg-success'
              : 'bg-slate-400'
          }`"
        ></div>
        <div v-else>-</div>
      </div>
    </template>

    <template #lastTimeDeviceSentData="{ data }">
      <!-- <p>Loading...</p> -->
      <p>{{ formatDateTime(data.data.lastTimeDeviceSentData) }}</p>
    </template>
    <template #createdAt="{ data }">
      <!-- <p>Loading...</p> -->
      <p>{{ formatDateTimeSeconds(data.data.createdAt) }}</p>
    </template>
    <template #updatedAt="{ data }">
      <!-- <p>Loading...</p> -->
      <p>{{ formatDateTimeSeconds(data.data.updatedAt) }}</p>
    </template>
    <template #policyValidFrom="{ data }">
      <!-- <p>Loading...</p> -->
      <p>{{ formatDateTime(data.data.policyValidFrom) }}</p>
    </template>
    <template #policyValidTo="{ data }">
      <!-- <p>Loading...</p> -->
      <p>{{ formatDateTime(data.data.policyValidTo) }}</p>
    </template>

    <template #dateTime="{ data }">
      {{ formatDateTimeSeconds(data.data.dateTime) }}
    </template>

    <template #text="{ data }">
      {{
        data.data.payload != undefined ? data.data.payload.text : data.data.text
      }}
    </template>

    <template #start="{ data }">
      {{ formatDateTime(data.data.start) }}
    </template>
    <template #end="{ data }">
      {{ formatDateTime(data.data.end) }}
    </template>

    <template #durationMinutes="{ data }">
      {{ convertMinutesToHoursAndMinutesFormatted(data.data.durationMinutes) }}
    </template>

    <template #icdCustomerName="{ data }">
      {{ data.data.icdCustomerName }}
    </template>

    <template #payload="{ data }">
      <div
        class="cursor-pointer text-primary hover:underline"
        @click="$emit('open-data-modal', data.data)"
      >
        {{ JSON.stringify(data.data.payload) }}
      </div>
    </template>

    <template #metadata="{ data }">
      <div
        class="cursor-pointer text-primary hover:underline"
        @click="$emit('open-data-modal', data.data.metadata)"
      >
        {{ JSON.stringify(data.data.metadata) }}
      </div>
    </template>

    <template #assignmentApplication="{ data }">
      <div v-if="data.data.assignment?.application?.name">
        {{ data.data.assignment.application.name }}
      </div>
      <div v-else>-</div>
    </template>

    <template #assignmentCompany="{ data }">
      <div v-if="data.data.assignment?.company?.name">
        {{ data.data.assignment.company.name }}
      </div>
      <div v-else>-</div>
    </template>

    <template #assignmentUser="{ data }">
      <div v-if="data.data.assignment?.user?.username">
        {{ data.data.assignment.user.username }}
      </div>
      <div v-else>-</div>
    </template>

    <template #assignmentMetadata="{ data }">
      <div
        class="cursor-pointer text-primary hover:underline"
        @click="$emit('open-data-modal', data.data.assignment.metadata)"
      >
        {{ JSON.stringify(data.data.assignment.metadata) }}
      </div>
    </template>

    <template #metadataDevice="{ data }">
      <div
        class="cursor-pointer text-primary hover:underline"
        @click="$emit('open-data-modal', data.data.metadata)"
      >
        {{ JSON.stringify(data.data.metadata) }}
      </div>
    </template>

    <template #data="{ data }">
      <div
        v-if="data.data.data"
        class="cursor-pointer text-primary hover:underline"
        @click="$emit('open-data-modal', data.data)"
      >
        {{ JSON.stringify(data.data.data) }}
      </div>
      <div v-else>-</div>
    </template>

    <!-- Manage nodes: Old version - maybe is still used somewhere -->
    <template #nodes="{ data }">
      <div
        class="cursor-pointer underline"
        @click="
          $emit('manage-nodes', { id: data.data.id, name: data.data.name })
        "
      >
        {{ $t("Displays.ManageNodes") }}
      </div>
    </template>
    <!-- Manage nodes: New version - because of permission -->
    <template #manageNodes="{ data }">
      <div
        class="cursor-pointer underline"
        @click="
          $emit('manage-nodes', { id: data.data.id, name: data.data.name })
        "
      >
        {{ $t("Displays.ManageNodes") }}
      </div>
    </template>

    <template #sources="{ data }">
      <div
        class="cursor-pointer underline"
        @click="
          $emit('manage-sources', { id: data.data.id, index: data.data.index })
        "
      >
        {{ $t("Displays.ManageSources") }}
      </div>
    </template>

    <template #goToDetails="{ data }">
      <div
        class="flex w-full cursor-pointer items-center justify-center text-center text-gray-600"
        @click="changeRoute(data.data)"
      >
        <font-awesome-icon :icon="['fas', 'angle-right']" />
      </div>
    </template>
    <template #deviceActions="{ data }">
      <div v-if="data.data?.actions?.length" class="flex w-full justify-center">
        <the-button
          button-type="small-primaryButton"
          text="Actions"
          @execute-method="$emit('toggle-static-actions-modal')"
        />
      </div>
    </template>

    <template #openMenu="{ data }">
      <div
        class="relative flex h-full w-full cursor-pointer items-center justify-center text-center text-gray-600"
        @click="isDropdownOpenedFromOutside = !isDropdownOpenedFromOutside"
      >
        <dropdown-menu
          :options="options"
          :assetPermissions="
            data.data.assignment?.permissions || data.data.resourcePermissions
          "
          :isCompanyActive="data.data.isCompanyActive"
          :remove-edit="data.data.isApplied"
          :use-for-table="true"
        />
      </div>
    </template>

    <template #openViewData="{ data }">
      <div
        v-if="!moduleToggleStore.isDevelopment"
        class="relative flex h-full w-full cursor-pointer items-center justify-center text-center text-gray-600"
        @click="isDropdownOpenedFromOutside = !isDropdownOpenedFromOutside"
      >
        <TheButton
          :text="'View'"
          size="sm"
          @click="
            $emit('openGenericPacketModal', {
              data: data.row.data,
              removeEdit: data.data.isApplied,
            })
          "
          :variant="data.row.data.lastTimeDeviceSentData ? 'default' : 'ghost'"
          :is-disabled="data.row.data.lastTimeDeviceSentData ? false : true"
        />
      </div>
    </template>

    <template #days="{ data }">
      <div class="flex items-center space-x-2 font-bold">
        <p
          v-for="(value, key, index) in data.data.days"
          :class="`${value ? 'text-success' : 'text-error'}`"
        >
          {{ days[Number(index)] }}
        </p>
      </div>
    </template>

    <template #minutesToHoursFrom="{ data }">
      <div>
        {{ convertMinutesToHoursAndMinutes(data.data.fromMinutes) }}
      </div>
    </template>

    <template #minutesToHoursTo="{ data }">
      <div>
        {{ convertMinutesToHoursAndMinutes(data.data.toMinutes) }}
      </div>
    </template>

    <template #occupiedTimestamp="{ data }">
      <div>
        {{ formatDateTime(data.data.occupiedTimestamp) }}
      </div>
    </template>
    <template #utcTimestamp="{ data }">
      <div>
        {{ formatDateTimeSeconds(data.data.utcTimestamp) }}
      </div>
    </template>
    <template #dateTimeEvent="{ data }">
      <div>
        {{ formatDateTimeSeconds(data.data.dateTimeEvent) }}
      </div>
    </template>
    <template #lastSync="{ data }">
      <div
        :class="`${
          syncDifferenceTime({
            hours: data.data.nodes ? 24 : 72,
            lastSyncTime: data.data.lastSync,
          }) === null
            ? 'text-darkgrey'
            : syncDifferenceTime({
                hours: data.data.nodes ? 24 : 72,
                lastSyncTime: data.data.lastSync,
              })
            ? 'text-error'
            : 'text-success'
        }`"
      >
        {{ formatDateTimeSeconds(data.data.lastSync) }}
      </div>
    </template>
    <template #rssi="{ data }">
      <div :class="`${rssiHealth({ signal: data.data.rssi })}`">
        {{ data.data.rssi }}
      </div>
    </template>
    <template #refresh="{ data }">
      <div>
        {{
          isMinDate(data.data.refresh) ? "-" : formatDateTime(data.data.refresh)
        }}
      </div>
    </template>
    <template #rtc="{ data }">
      <div>
        {{
          isMinDate(data.data.rtc) ? "-" : formatDateTimeSeconds(data.data.rtc)
        }}
      </div>
    </template>
    <template #storedAt="{ data }">
      <div>
        {{ formatDateTimeSeconds(data.data.storedAt) }}
      </div>
    </template>

    <template #accesses="{ data }">
      <div class="space-y-2">
        <div v-for="access in data.data?.accesses">
          <div v-if="access.action !== 3">
            {{ access?.resourceName }}:
            {{ formatDateTime(access?.dateActiveFrom) }} -
            {{ formatDateTime(access?.dateActiveTo) }} ({{
              access?.active ? "Active" : "Not active"
            }})
          </div>
        </div>
      </div>
    </template>

    <template #authResources="{ data }">
      <div class="space-y-2 divide-y divide-dark">
        <div v-for="authResource in data.data.authResources">
          <div>
            <p class="pt-2">
              Resource:
              <b>{{ authResource.onResourceName || "All resources" }}</b>
            </p>
            <p>
              Valid: {{ formatDateTimePolicy(authResource.policyValidFrom) }} -
              {{ formatDateTimePolicy(authResource.policyValidTo) }}
            </p>
            <p>Created by: {{ authResource.createdUserEmail }}</p>
          </div>
        </div>
      </div>
    </template>

    <template #resources="{ data }">
      <div class="divide-y divide-gray-300">
        <div v-for="resource in data.data.resources" class="py-2">
          <div>
            Role: <strong>{{ resource.role.name }}</strong>
          </div>
          <div>
            Resource: <strong>{{ resource?.onResource.name }}</strong>
          </div>
          <div>Resource type: {{ resource.resourceType.name }}</div>
          <div>Created by: {{ resource.createdUser.email }}</div>
        </div>
      </div>
    </template>

    <template #identifiers="{ data }">
      <div class="space-y-2">
        <div v-for="identifier in data.data?.identifiers">
          RFID: {{ identifier.value }}
        </div>
      </div>
    </template>

    <template #subscriptionItems="{ data }">
      <div class="space-y-2 divide-y divide-gray-300">
        <div
          v-for="subscriptions in data.data.subscriptionItems"
          class="flex items-center justify-between pt-2 first:pt-0"
        >
          <p>{{ subscriptions.name }}</p>
          <p>{{ subscriptions.price }} &euro;</p>
        </div>
      </div>
    </template>

    <template #revokeAction="{ data }">
      <div
        v-tippy="'Revoke device'"
        class="group h-full w-full cursor-pointer text-center hover:bg-errorlight"
        @click="$emit('revoke-action', data.data.email)"
      >
        <font-awesome-icon
          :icon="['fas', 'xmark']"
          class="text-xl group-hover:text-error"
        />
      </div>
    </template>

    <template #rejectApproveAction="{ data }">
      <div class="grid w-full grid-cols-2">
        <div
          v-tippy="'Reject request'"
          class="group flex w-full cursor-pointer justify-center hover:bg-errorlight"
          @click="
            $emit('reject-approve-action', {
              userEmail: data.data.email,
              status: 'reject',
            })
          "
        >
          <font-awesome-icon
            :icon="['fas', 'xmark']"
            class="text-xl group-hover:text-error"
          />
        </div>
        <div
          v-tippy="'Approve request'"
          class="group flex w-full cursor-pointer justify-center hover:bg-successlight"
          @click="
            $emit('reject-approve-action', {
              userEmail: data.data.email,
              status: 'approve',
            })
          "
        >
          <font-awesome-icon
            :icon="['fas', 'check']"
            class="text-xl group-hover:text-success"
          />
        </div>
      </div>
    </template>

    <template #packetsSentMerged="{ data }">
      <p
        class="cursor-pointer text-center underline hover:text-primary"
        @click="$emit('view-sensor-packets', data.data)"
      >
        {{ data.data.packetsSentMerged }}
      </p>
    </template>

    <template #displayEventsReport="{ data }">
      <p
        class="cursor-pointer underline hover:text-primary"
        @click="$emit('view-display-events', data.data)"
      >
        {{ data.data.displayEventsReport }}
      </p>
    </template>

    <template #busControllerEventsReport="{ data }">
      <p
        class="cursor-pointer text-center underline hover:text-primary"
        @click="$emit('view-display-events', data.data)"
      >
        {{ data.data.busControllerEventsReport }}
      </p>
    </template>

    <template #assignColumn="{ data }">
      <p
        class="cursor-pointer underline hover:text-primary"
        @click="$emit('assign-sensor', data.data)"
      >
        {{ $t("Sensors.AssignSensor") }}
      </p>
    </template>
    <template #isApplied="{ data }">
      <div v-if="data.data.isApplied">
        <p>Applied</p>
      </div>
      <div
        v-if="
          !data.data.isApplied && data.data.currentCount === data.data.pieces
        "
      >
        <p
          class="cursor-pointer underline"
          @click="$emit('apply', data.data.id)"
        >
          Apply
        </p>
      </div>
      <div
        v-if="!data.data.isApplied && data.data.currentCount < data.data.pieces"
      >
        <p class="cursor-not-allowed text-gray-300">Apply</p>
      </div>
    </template>
    <template #parkingState="{ data }">
      <div>
        <p
          v-if="
            data.data.parkingState !== null &&
            data.data.parkingState !== undefined
          "
          :class="`${
            data.data.parkingState === 0 ? 'text-success' : 'text-error'
          }`"
        >
          {{ data.data.parkingState === 0 ? "Unoccupied" : "Occupied" }}
        </p>
        <p v-else>-</p>
      </div>
    </template>

    <!-- Sensor Health START-->
    <template #sensorPackets="{ data }">
      <p
        class="cursor-pointer text-center underline hover:text-primary"
        @click="$emit('view-sensor-packets', data.data)"
      >
        Packets
      </p>
    </template>
    <template #batteryPercentage="{ data }">
      <div
        :class="`${
          data.data.batteryPercentage < 25 ? 'text-error' : 'text-black'
        }`"
      >
        {{ data.data.batteryPercentage }}
      </div>
    </template>
    <template #avgConsumptionPerDay="{ data }">
      <div
        :title="`${data.data.avgConsumptionPerDay}`"
        :class="`${
          data.data.avgConsumptionPerDay > 15 ? 'text-error' : 'text-black'
        }`"
      >
        {{
          data.data.avgConsumptionPerDay == null
            ? "-"
            : data.data.avgConsumptionPerDay.toFixed(2)
        }}
      </div>
    </template>
    <template #avgRssi="{ data }">
      <div
        :title="`${data.data.avgRssi}`"
        :class="`${data.data.avgRssi < -110 ? 'text-error' : 'text-black'}`"
      >
        {{ data.data.avgRssi == null ? "-" : data.data.avgRssi.toFixed(0) }}
      </div>
    </template>
    <template #avgPacketsPerDay="{ data }">
      <div
        :title="`${data.data.avgPacketsPerDay}`"
        :class="`${
          data.data.avgPacketsPerDay > 50 ? 'text-error' : 'text-black'
        }`"
      >
        {{
          data.data.avgPacketsPerDay == null
            ? "-"
            : data.data.avgPacketsPerDay.toFixed(2)
        }}
      </div>
    </template>
    <template #notSequencePackets="{ data }">
      <div
        :class="`${data.data.notSequencePackets ? 'text-error' : 'text-black'}`"
      >
        {{
          data.data.notSequencePackets
            ? "(at " + data.data.notSequencePacketsPoint + ")"
            : "-"
        }}
      </div>
    </template>
    <template #packetsNotIncrement="{ data }">
      <div
        :class="`${
          data.data.packetsNotIncrement ? 'text-error' : 'text-black'
        }`"
      >
        {{ data.data.packetsNotIncrement }}
      </div>
    </template>
    <template #lastUnhandledReboot="{ data }">
      <div
        :class="`${
          data.data.lastUnhandledReboot > 15 ||
          data.data.lastUnhandledReboot < 0
            ? 'text-error'
            : 'text-black'
        }`"
      >
        {{ data.data.lastUnhandledReboot }}
      </div>
    </template>
    <template #rebootsGrows="{ data }">
      <div :class="`${data.data.rebootsGrows ? 'text-error' : 'text-black'}`">
        {{ data.data.rebootsGrows }}
      </div>
    </template>
    <template #rebootsNegative="{ data }">
      <div
        :class="`${data.data.rebootsNegative ? 'text-error' : 'text-black'}`"
      >
        {{ data.data.rebootsNegative }}
      </div>
    </template>
    <template #rebootsChangingError="{ data }">
      <div
        :class="`${
          data.data.rebootsChangingError ? 'text-error' : 'text-black'
        }`"
      >
        {{ data.data.rebootsChangingError }}
      </div>
    </template>
    <template #avgPowerOnCountPerDay="{ data }">
      <div
        :class="`${
          data.data.avgPowerOnCountPerDay > 350 ||
          data.data.avgPowerOnCountPerDay < 250
            ? 'text-error'
            : 'text-black'
        }`"
      >
        {{ data.data.avgPowerOnCountPerDay }}
      </div>
    </template>
    <template #runtimeTotalSecondsHealth="{ data }">
      <div
        :class="`${
          data.data.runtimeTotalSecondsHealth > 700
            ? 'text-error'
            : 'text-black'
        }`"
      >
        {{
          data.data.runtimeTotalSecondsHealth == 0
            ? "-"
            : Math.floor(data.data.runtimeTotalSecondsHealth / 60) + " min/day"
        }}
      </div>
    </template>
    <template #disableCounter="{ data }">
      <div :class="`${data.data.disableCounter ? 'text-error' : 'text-black'}`">
        {{ data.data.disableCounter }}
      </div>
    </template>
    <template #eclTwo="{ data }">
      <div :class="`${data.data.eclTwo ? 'text-error' : 'text-black'}`">
        {{ data.data.eclTwo }}
      </div>
    </template>
    <template #samePacketIdCounter="{ data }">
      <div
        :class="`${
          data.data.samePacketIdCounter > 0 ? 'text-error' : 'text-black'
        }`"
      >
        {{ data.data.samePacketIdCounter }}
      </div>
    </template>
    <template #mag1RawXFlash="{ data }">
      <div :class="`${data.data.mag1RawXFlash ? 'text-error' : 'text-black'}`">
        {{ data.data.mag1RawXFlash }}
      </div>
    </template>
    <template #mag1RawYFlash="{ data }">
      <div :class="`${data.data.mag1RawYFlash ? 'text-error' : 'text-black'}`">
        {{ data.data.mag1RawYFlash }}
      </div>
    </template>
    <template #mag1RawZFlash="{ data }">
      <div :class="`${data.data.mag1RawZFlash ? 'text-error' : 'text-black'}`">
        {{ data.data.mag1RawZFlash }}
      </div>
    </template>
    <template #radarFlash="{ data }">
      <div :class="`${data.data.radarFlash ? 'text-error' : 'text-black'}`">
        {{ data.data.radarFlash }}
      </div>
    </template>

    <!-- Sensor Health END-->

    <template #commitHeader="{ data }">
      <the-button
        :text="`Commit ${data.data.name}`"
        size="full"
        @execute-method="$emit('commit-action', data.data.id)"
      />
    </template>

    <template #responseMessage="{ data }">
      <div v-if="data.data.responseMessages?.message">
        {{ data.data.responseMessages.message }}
      </div>
      <div v-else>-</div>
    </template>

    <!-- Tags column value in Map view -->
    <template #tagName="{ data }">
      <div>
        {{ data.data.name }}
      </div>
    </template>

    <template #userRole="{ data }">
      <div v-for="role in data.data.roles" :key="role.id">
        {{ role.name }}
      </div>
    </template>

    <template #deviceTypeId="{ data }">
      <div class="flex items-center justify-center">
        <img
          :src="IconSelector(data.data.device.deviceTypeId)"
          class="h-auto w-10"
        />
      </div>
    </template>

    <DxSummary v-if="renderSummary?.length">
      <DxTotalItem
        v-for="summary in renderSummary"
        :column="summary"
        :display-format="$t('Count') + ': {0}'"
        summary-type="count"
      />
    </DxSummary>
  </dx-data-grid>
</template>
<script lang="ts">
import { Device } from "@/modules/Devices/Services/DeviceService";
import { defineComponent, PropType } from "vue";
import Pagination from "./Pagination.vue";
import Loader from "./Loader.vue";
import {
  ConvertMinutes,
  ConvertMinutesToHoursAndMinutes,
  ConvertMinutesToHoursAndMinutesFormatted,
  FormatDateTimePolicy,
  FormatDateTimeSeconds,
  IsMinDate,
  SyncDifferenceTime,
  RssiHealth,
} from "@/core/Services/GlobalFunctions";
import DropdownMenu from "./Dropdowns/DropdownMenu.vue";
import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid,
  DxExport,
  DxFilterRow,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxItem,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxSelection,
  DxSummary,
  DxToolbar,
  DxTotalItem,
} from "devextreme-vue/data-grid";
import DxDropDownButton from "devextreme-vue/drop-down-button";
import { directive } from "vue-tippy";
import { FormatDateTime } from "../Services/GlobalFunctions";
import SmallLoader from "./SmallLoader.vue";
import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { useUserStore } from "../Store/userStore";
import "jspdf-autotable";
import TheButton from "@/core/Components/TheButton.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { TableDropdownItem } from "@/core/Services/GlobalTypes";
import { useModuleToggleStore } from "@/core/Store/moduleToggleStore";
import { IconSelector } from "@/modules/MapView/Services/IconService";
import moment from "moment";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { Permissions } from "@/core/Services/Enums";

const dataGridRef = "dataGrid";

export default defineComponent({
  components: {
    FontAwesomeIcon,
    TheButton,
    Pagination,
    Loader,
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxColumnChooser,
    DxFilterRow,
    DxSelection,
    DxHeaderFilter,
    DropdownMenu,
    DxToolbar,
    DxItem,
    DxDropDownButton,
    SmallLoader,
    DxScrolling,
    DxSummary,
    DxTotalItem,
  },
  props: {
    tableData: Array,
    tableHeaders: Object,
    isDevicesTable: Boolean,
    noSelection: Boolean,
    routeName: String,
    allowAutoResize: Boolean,
    isUsersDevicesSharedToDisplayed: Boolean,
    isUsersRejectApproveShareDisplayed: Boolean,
    isRejectApproveActionDisplayed: Boolean,
    isRevokeActionDisplayed: Boolean,
    externalId: Number,
    details: Boolean,
    isDataSourceLoading: Boolean,
    options: Object as PropType<TableDropdownItem>,
    isActionListNotDisplayed: Boolean,
    tableHeight: String,
    showDeviceActions: Boolean,
    forceRefresh: Boolean,
    removeTableHeader: Boolean,
    assignColumn: Boolean,
    nodesColumn: Boolean,
    renderSummary: Array as PropType<string[]>,
    showPerPage: Number,
    multiselect: Boolean,
  },

  directives: {
    tippy: directive,
  },
  emits: [
    "reject-share-request",
    "approve-share-request",
    "remove-share",
    "open-data-modal",
    "selected-rows",
    "action-click",
    "force-refresh",
    "manage-nodes",
    "manage-sources",
    "users-device-is-shared-to",
    "revoke-action",
    "users-device-is-pending",
    "reject-approve-action",
    "toggle-static-actions-modal",
    "view-sensor-packets",
    "assign-sensor",
    "apply",
    "openGenericPacketModal",
    "commit-action",
  ],

  data() {
    return {
      IconSelector: IconSelector,
      moduleToggleStore: useModuleToggleStore(),
      globalSearch: "",
      test: [],
      pageSizes: [10, 25, 50, 100],
      isDropdownOpenedFromOutside: false,
      userStore: useUserStore(),
      days: ["M", "T", "W", "T", "F", "S", "S"],
      actionsOptions: [
        { id: "delete", text: "Delete", icon: "trash", badge: 0 },
        {
          id: "moveCompany",
          text: "Move to another company",
          icon: "movetofolder",
          badge: 0,
        },
      ],
      selectedRows: [] as Device[],
      refreshButtonOptions: {
        icon: "refresh",
        onClick: () => {
          this.$emit("force-refresh");
        },
      },
      permissionStore: usePermissionStore(),
      permissions: Permissions,
    };
  },

  watch: {
    tableData: {
      handler: function (val) {
        if (this.forceRefresh) {
          //@ts-ignore
          this.$refs.devextremeTable.instance.refresh();
        }
      },
      deep: true,
    },
    globalSearch(text: string) {
      console.log("globalSearch", text);
    },
    test: {
      handler(value) {
        console.log("test", value);
      },
      deep: true,
    },
  },

  methods: {
    onRowPrepared(e: any) {
      if (e.data) {
        if (e.data.roles) {
          if (
            e.data.roles.some((role: any) => {
              return role.name === "Admin";
            })
          ) {
            e.rowElement.style.backgroundColor = "#d4af37";
          }
        }
      }
    },
    applyFilter(operation: any, value: any) {
      console.log("applyFilter", operation, value);
    },
    formatDateTimeSeconds: FormatDateTimeSeconds,
    ConvertMinutesToHoursAndMinutesFormatted,
    formatDateTime: FormatDateTime,
    formatDateTimePolicy: FormatDateTimePolicy,
    isMinDate: IsMinDate,
    syncDifferenceTime: SyncDifferenceTime,
    rssiHealth: RssiHealth,
    convertMinutesToHoursAndMinutes: ConvertMinutesToHoursAndMinutes,
    convertMinutesToHoursAndMinutesFormatted: ConvertMinutes,
    cellTemplateSelector(key: string): string {
      switch (key) {
        case "lastTimeDeviceSentData":
          return "lastTimeDeviceSentData";
        case "isActive":
          return "isActive";
        case "isCompanyActive":
          return "isCompanyActive";
        case "active":
          return "active";
        case "days":
          return "days";
        case "fromMinutes":
          return "minutesToHoursFrom";
        case "toMinutes":
          return "minutesToHoursTo";
        case "identifiers":
          return "identifiers";
        case "accesses":
          return "accesses";
        case "payload":
          return "payload";
        case "metadata":
          return "metadata";
        case "data":
          return "data";
        case "dateTime":
          return "dateTime";
        case "text":
          return "text";
        case "policyValidFrom":
          return "policyValidFrom";
        case "policyValidTo":
          return "policyValidTo";
        case "authResources":
          return "authResources";
        case "subscriptionItems":
          return "subscriptionItems";
        case "createdAt":
          return "createdAt";
        case "updatedAt":
          return "updatedAt";
        case "isSent":
          return "isSent";
        case "isProductionEnabled":
          return "isProductionEnabled";
        case "occupiedTimestamp":
          return "occupiedTimestamp";
        case "occupied":
          return "occupied";
        case "visible":
          return "visible";
        case "navigable":
          return "navigable";
        case "nodes":
          return "nodes";
        case "sources":
          return "sources";
        case "public":
          return "public";
        case "lastSync":
          return "lastSync";
        case "refresh":
          return "refresh";
        case "rtc":
          return "rtc";
        case "resources":
          return "resources";
        case "packetsSentMerged":
          return "packetsSentMerged";
        case "displayEventsReport":
          return "displayEventsReport";
        case "busControllerEventsReport":
          return "busControllerEventsReport";
        case "spotOccupied":
          return "spotOccupied";
        case "start":
          return "start";
        case "end":
          return "end";
        case "durationMinutes":
          return "durationMinutes";
        case "icdCustomerName":
          return "icdCustomerName";
        case "isApplied":
          return "isApplied";
        case "parkingState":
          return "parkingState";
        case "storedAt":
          return "storedAt";
        case "utcTimestamp":
          return "utcTimestamp";
        case "dateTimeEvent":
          return "dateTimeEvent";
        case "rssi":
          return "rssi";
        case "responseMessage":
          return "responseMessage";
        case "commitHeader":
          return "commitHeader";
        case "assignment.metadata":
          return "assignmentMetadata";
        case "assignment.application":
          return "assignmentApplication";
        case "assignment.company":
          return "assignmentCompany";
        case "assignment.user":
          return "assignmentUser";
        case "metadata.device":
          return "metadataDevice";
        case "tag.name":
          return "tagName";
        case "userRole":
          return "userRole";
        case "batteryPercentage":
          return "batteryPercentage";
        case "avgConsumptionPerDay":
          return "avgConsumptionPerDay";
        case "avgRssi":
          return "avgRssi";
        case "avgPacketsPerDay":
          return "avgPacketsPerDay";
        case "notSequencePackets":
          return "notSequencePackets";
        case "packetsNotIncrement":
          return "packetsNotIncrement";
        case "lastUnhandledReboot":
          return "lastUnhandledReboot";
        case "rebootsGrows":
          return "rebootsGrows";
        case "rebootsNegative":
          return "rebootsNegative";
        case "rebootsChangingError":
          return "rebootsChangingError";
        case "avgPowerOnCountPerDay":
          return "avgPowerOnCountPerDay";
        case "runtimeTotalSecondsHealth":
          return "runtimeTotalSecondsHealth";
        case "disableCounter":
          return "disableCounter";
        case "eclTwo":
          return "eclTwo";
        case "samePacketIdCounter":
          return "samePacketIdCounter";
        case "mag1RawXFlash":
          return "mag1RawXFlash";
        case "mag1RawYFlash":
          return "mag1RawYFlash";
        case "mag1RawZFlash":
          return "mag1RawZFlash";
        case "radarFlash":
          return "radarFlash";
        case "sensorPackets":
          return "sensorPackets";

        default:
          return "";
      }
    },

    onExporting(e: any) {
      e.cancel = true;

      switch (e.format) {
        case "pdf":
          const doc = new jsPDF({ orientation: "landscape" });
          exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
            indent: 5,
          }).then(() => {
            doc.save(moment().format("YYYY-MM-DD_HH-mm-ss") + ".pdf");
          });
          break;

        case "xlsx":
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet("Sheet1");
          exportDataGridToExcel({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer: any) => {
              saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                moment().format("YYYY-MM-DD_HH-mm-ss") + ".xlsx"
              );
            });
          });
          break;
      }
    },

    onSelectionChanged({ selectedRowsData }: any) {
      this.actionsOptions[0].badge = selectedRowsData.length;
      this.actionsOptions[1].badge = selectedRowsData.length;
      this.selectedRows = selectedRowsData;
      this.$emit("selected-rows", selectedRowsData);
    },

    changeRoute(key: any) {
      if (this.routeName === "editIntegration") {
        this.$router.push({
          name: this.routeName,
          params: { appId: this.externalId, integrationId: key.id },
        });
      } else {
        this.$router.push({ name: this.routeName, params: { id: key.id } });
      }
    },

    customizeText(cellInfo: any) {
      //This checks for null, undefined and empty string values
      if (cellInfo.valueText === "") {
        return "-";
      }
      return cellInfo.valueText;
    },

    checkIfUserCanApplyDevices(
      isApplied: boolean,
      currentCount: number,
      totalCount: number
    ) {
      if (isApplied) {
        return false;
      }

      if (currentCount === totalCount) {
        return true;
      }
    },
  },
});
</script>
<style>
.dx-datagrid {
  @apply !rounded-md;
}

.dx-header-row {
  @apply bg-primary text-white;
}

.dx-datagrid-header-panel {
  padding: 0 1rem;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 40px !important;
}

.dx-datagrid-rowsview {
  overflow: visible !important;
}

.dx-datagrid .dx-column-lines > td:last-child {
  overflow: visible !important;
}

.dx-datagrid-rowsview .dx-row > tr > td:last-child {
  overflow: visible !important;
  padding: 0 !important;
}

.dx-datagrid .dx-column-lines > td {
  @apply !p-[0.5rem] !border-b-lightgray !border-l-lightgray !border-r-lightgray;
}

.dx-datagrid .dx-column-lines .dx-editor-cell {
  @apply !p-[0.15rem];
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  @apply !rounded-md !bg-primary;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  @apply !bg-lightgray !text-dark;
}

/* .dx-checkbox-icon {
  @apply !text-primary;
}

.dx-checkbox.dx-state-focused .dx-checkbox-icon {
  @apply !border-primary;
}

.dx-checkbox.dx-state-hover .dx-checkbox-icon {
  @apply !border-primary;
} */

/* .devextreme-table {
  max-height: 80vh !important;
} */

.dx-datagrid-table td:nth-child(2) {
  overflow: hidden !important;
}

/* .dx-scrollable-scroll,
.dx-scrollable-scroll-content {
  @apply !bg-primarylight;
} */

/* .dx-row{
  font-size: larger;
} */

.dx-datagrid-content .dx-datagrid-table .dx-row > td {
  vertical-align: middle !important;
}
</style>
