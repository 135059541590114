import {
  CreateDisplay,
  CreateNode,
  CreateSource,
  Display,
  EditDisplay,
  EditNode,
  EditSource,
  Node,
  NodeType,
  DisplayEventProps,
  DisplayEvents,
} from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import ApiService from "@/core/Axios/ApiService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class DisplayService {
  async getDisplays({
    pageNumber = 1,
    pageSize = 100000,
  }: {
    pageNumber: number;
    pageSize: number;
  }): Promise<Display[]> {
    const url = GenerateUrl({
      path: "/api/displays",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });
    const response = await ApiService.get(url);
    return this._mapDisplays(response.data.items);
  }

  async getDisplayByIdentifier(id: number): Promise<Display> {
    const url = GenerateUrl({
      path: `/api/displays/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    const response = await ApiService.get(url);
    return this._mapDisplay(response.data);
  }

  async createDisplay(display: CreateDisplay): Promise<any> {
    const url = GenerateUrl({
      path: "/api/displays",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.post(url, display);
  }

  async updateDisplay(display: EditDisplay): Promise<any> {
    const url = GenerateUrl({
      path: `/api/displays/${display.id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.put(url, display);
  }

  async deleteDisplay(id: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/displays/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  // NODES

  async getDisplayNodes(id: number): Promise<Node[]> {
    const url = GenerateUrl({
      path: `/api/displays/nodes/display/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    const response = await ApiService.get(url);
    return this._mapNodes(response.data);
  }

  async getNodeByIdentifier(id: number): Promise<Node> {
    const url = GenerateUrl({
      path: `/api/displays/nodes/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    const response = await ApiService.get(url);

    return this._mapNode(response.data);
  }

  async deleteNode(id: number) {
    const url = GenerateUrl({
      path: `/api/displays/nodes/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.delete(url);
  }

  async getNodeTypes(): Promise<NodeType[]> {
    const url = GenerateUrl({
      path: `/api/displays/nodes/types`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    const response = await ApiService.get(url);
    return this._mapNodeTypes(response.data);
  }

  async createNode(node: CreateNode): Promise<any> {
    const url = GenerateUrl({
      path: "/api/displays/nodes",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.post(url, node);
  }

  async editNode(node: EditNode): Promise<any> {
    debugger;
    const url = GenerateUrl({
      path: `/api/displays/nodes/${node.id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.put(url, node);
  }

  //SOURCES

  async getNodeSources(id: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/displays/nodes/sources/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.get(url);
    return response.data;
  }

  async createSource(source: CreateSource): Promise<any> {
    const url = GenerateUrl({
      path: "/api/displays/nodes/sources",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.post(url, source);
  }

  async editSource(source: EditSource): Promise<any> {
    const url = GenerateUrl({
      path: `/api/displays/nodes/sources`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.put(url, source);
  }

  async deleteSource(id: number) {
    const url = GenerateUrl({
      path: `/api/displays/nodes/sources/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.delete(url);
  }

  async getDisplayEvents({
    id,
    eventType,
    pageSize,
    pageNumber,
    to,
    from,
  }: DisplayEventProps): Promise<DisplayEvents[]> {
    const url = GenerateUrl({
      path: `/api/displays/${id}/packets`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        packetType: eventType,
        PageSize: pageSize,
        PageNumber: pageNumber,
        To: to,
        From: from,
      },
    });

    const response = await ApiService.get(url);
    return response.data.map((packet: any) => {
      return packet;
    });
  }

  private _mapDisplays(response: Display[]): Display[] {
    return response.map((display: Display) => this._mapDisplay(display));
  }

  private _mapDisplay(display: Display): Display {
    return {
      id: display.id,
      name: display.name,
      latitude: display.latitude,
      longitude: display.longitude,
      active: display.active,
      imei: display.imei,
      lastSync: display.lastSync,
      lastDisplayed: display.nodes
        .sort((a, b) => a?.index - b?.index)
        .map((value) => value?.lastValue?.toString())
        .join(" "),
      nodes: display.nodes,
      displayEventsReport: "Events",
      externalAssignmentUuid: display.externalAssignmentUuid,
    };
  }

  private _mapNodeTypes(response: NodeType[]): NodeType[] {
    return response.map((displayType: NodeType) =>
      this._mapNodeType(displayType)
    );
  }

  private _mapNodeType(displayType: NodeType): NodeType {
    return {
      id: displayType.id,
      name: displayType.name,
    };
  }

  private _mapNodes(response: Node[]): Node[] {
    return response.map((node: Node) => this._mapNode(node));
  }

  private _mapNode(node: Node): Node {
    return {
      id: node.id,
      index: node.index,
      refresh: node.refresh,
      lastValue: node.lastValue,
      overrideValue: node.overrideValue,
      correction: node.correction,
      overrideFreeArrow: node.overrideFreeArrow,
      overrideOccupiedArrow: node.overrideOccupiedArrow,
      displayId: node.displayId,
      typeId: node.typeId,
      typeName: node.typeName,
    };
  }
}
