<template>
  <modal-component
    :is-displayed="true"
    :is-full-screen-modal="true"
    :need-for-scroll="true"
    :remove-outside-click="true"
    :title="`Asset details`"
    @toggle-modal="$emit('closeModal', true)"
  >
    <div v-if="!isLoading && !haveData" class="ml-2 text-lg">
      There is no data for choosen device
    </div>
    <div v-if="isLoading" class="h-screen">
      <Loader />
    </div>
    <div class="px-5">
      <div v-if="loadedData" class="flex justify-between">
        <div
          class="flex h-full w-full items-center justify-center justify-items-center space-x-4"
        >
          <dx-date-box
            v-model="from"
            :height="42"
            :show-clear-button="true"
            display-format="dd.MM.yyyy HH:mm"
            type="datetime"
          />
          <dx-date-box
            v-model="to"
            :height="42"
            :show-clear-button="true"
            display-format="dd.MM.yyyy HH:mm"
            type="datetime"
          />
          <TheButton
            :text="'Refresh'"
            class="delete-button my-1 flex h-10 w-24 items-center justify-center border"
            size="sm"
            @click="getDeviceData()"
          />
        </div>
      </div>
      <div
        v-if="loadedData"
        class="my-3 flex flex-col items-center justify-center justify-items-center space-y-2"
      >
        <p class="text-2xl font-semibold">{{ deviceName }}</p>
        <img
          :src="IconSelector(deviceTypeId as number)"
          class="h-[8rem] w-[8rem]"
        />
        <p class="pt-4 text-sm">{{ devices[0].data[0].dateTime }}</p>
      </div>

      <div
        v-if="loadedData"
        class="mb-5 grid grid-cols-1 gap-4 text-sm sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
        style="grid-template-columns: repeat(auto-fit, minmax(200px, 1fr))"
      >
        <div
          class="ShadowStyle flex flex-col items-center justify-center rounded bg-white p-1 text-center"
          v-if="deviceTypeId == 28"
        >
          <p class="font-semibold">Total</p>
          <p>{{ devices[0].data[0].payload.countFwd / 1000 }} m<sup>3</sup></p>
          <p>{{ devices[0].data[0].payload.countFwd }} l</p>
        </div>
        <div
          class="ShadowStyle flex flex-col items-center justify-center rounded bg-white p-1 text-center"
          v-if="deviceTypeId == 28"
        >
          <p class="font-semibold">Measuring spot number</p>
          {{ devices[0].data[0].payload.spotNum }}
        </div>

        <div
          v-for="record in devices[0].data[0].payload.records"
          v-show="record.value > 0"
          class="ShadowStyle flex flex-col rounded bg-white p-1 text-center"
        >
          <p v-if="record.value > 0" class="font-semibold">
            {{ record.idName }}
          </p>
          <div class="flex items-center justify-center gap-1">
            <font-awesome-icon
              :icon="['fas', 'circle']"
              :style="{ color: record.hexColor }"
            />
            <p
              v-if="record.value > 0"
              :class="{
                'text-red-500':
                  record.value < record.minValue ||
                  record.value > record.maxValue,
              }"
            >
              {{ record.value.toFixed(2) }}
              <span v-if="record.unitSymbol">{{ record.unitSymbol }}</span>
            </p>
          </div>
          <p
            v-if="record.isValid == false"
            class="text-xs font-semibold text-red-500"
          >
            Not valid
          </p>
        </div>

        <div
          v-if="devices[0].data[0].payload.sensor1"
          class="rounded bg-white p-1 text-center shadow"
        >
          <p class="font-semibold">T1</p>
          <div class="flex items-center justify-center gap-1 align-middle">
            <p class="text-4xl font-extrabold text-[#125a18]">•</p>
            <p v-if="devices[0].data[0].payload.sensor1 > 0">
              {{ devices[0].data[0].payload.sensor1 }} °C
            </p>
          </div>
        </div>
        <div
          v-if="devices[0].data[0].payload.sensor2"
          class="rounded bg-white p-1 text-center shadow"
        >
          <p class="font-semibold">T2</p>
          <div class="flex items-center justify-center gap-1 align-middle">
            <p class="text-4xl font-extrabold text-[#ffb03b]">•</p>
            <p v-if="devices[0].data[0].payload.sensor2 > 0">
              {{ devices[0].data[0].payload.sensor2 }} °C
            </p>
          </div>
        </div>
        <div
          v-if="devices[0].data[0].payload.sensor3"
          class="rounded bg-white p-1 text-center shadow"
        >
          <p class="font-semibold">T3</p>
          <div class="flex items-center justify-center gap-1 align-middle">
            <p class="text-4xl font-extrabold text-[#644311]">•</p>
            <p v-if="devices[0].data[0].payload.sensor3 > 0">
              {{ devices[0].data[0].payload.sensor3 }} °C
            </p>
          </div>
        </div>
      </div>
      <DeviceRecordChart
        v-if="loadedData"
        :deviceData="devices"
        :deviceTypes="deviceTypeConfigurations"
        :rangeData="deviceDataRange"
      />
      <DeviceMap
        v-if="loadedData"
        class="mt-3"
        :deviceAssignments="deviceAssignments"
        :deviceData="devices"
        :deviceTypes="deviceTypeConfigurations"
        :deviceMetadata="metadataMap"
        @changeName="changeName"
        @saveChanges="saveChanges()"
      />
    </div>
  </modal-component>
</template>
<script lang="ts">
import DeviceDataService, {
  LastDeviceAssignmentData,
} from "@/modules/Devices/Services/DeviceDataService";
import { defineComponent } from "vue";
import VSelect from "vue-select";
import DxDateBox from "devextreme-vue/date-box";
import InputField from "@/core/Components/FormElements/InputField.vue";
import moment from "moment";
import { DeviceTypeConfiguration } from "@/modules/MapView/Services/Types/DeviceTypes";
import DeviceTypeService from "@/modules/MapView/Services/DeviceTypeServices/DeviceTypeService";
import DeviceAssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DeviceRecordChart from "@/modules/GenericPacket/Components/DeviceRecordChart.vue";
import DeviceMap from "../Components/DeviceMap.vue";
import TheButton from "@/core/Components/TheButton.vue";
import Loader from "@/core/Components/Loader.vue";
import ModalComponent from "@/core/Components/Modal.vue";
import DropdownMenu from "@/core/Components/Dropdowns/DropdownMenu.vue";
import { IconSelector } from "@/modules/MapView/Services/IconService";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  components: {
    DxDateBox,
    VSelect,
    InputField,
    DeviceRecordChart,
    DeviceMap,
    TheButton,
    Loader,
    ModalComponent,
    DropdownMenu,
    FontAwesomeIcon,
  },
  data() {
    return {
      deviceDataService: new DeviceDataService(),
      to: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      from: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      devices: [] as LastDeviceAssignmentData[],
      deviceTypeService: new DeviceTypeService(),
      deviceTypeConfigurations: [] as DeviceTypeConfiguration[],
      loadedData: false,
      deviceDataRange: {},
      deviceAssignmentService: new DeviceAssignmentService(),
      deviceAssignments: [] as DeviceAssignment[],
      toast: useToast(),
      deviceName: "",
      allDeviceAssignments: [],
      isLoading: true,
      deviceDataUuid: "",
      haveData: true,
      deviceTypeId: null as number | null,
      metadataMap: new Map<string, any>(),
    };
  },
  props: ["deviceUuid", "removeEdit"],
  created() {},
  async mounted() {
    this.deviceDataUuid = this.deviceUuid;
    try {
      this.deviceAssignments =
        await this.deviceAssignmentService.getAllDeviceAssignments({
          typeOfAssignment: "Company",
          pageSize: 100000,
          pageNumber: 1,
        });
      this.getDeviceData();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },
  methods: {
    IconSelector: IconSelector,
    async getDeviceData() {
      this.isLoading = true;
      this.haveData = true;
      try {
        this.loadedData = false;
        this.deviceDataRange = {};
        this.devices = await this.deviceDataService.getDeviceAssignmentLastData(
          {
            dataUuids: [this.deviceDataUuid],
            channel: 0,
            pageNumber: 1,
            pageSize: 1000000,
          }
        );

        if (!this.devices[0]) {
          this.haveData = false;
          return;
        }

        this.deviceDataRange =
          await this.deviceDataService.getDeviceAssignmentData({
            dataUuid: this.deviceDataUuid,
            from: moment(this.from).format("YYYY-MM-DDTHH:mm:ssZ"),
            to: moment(this.to).format("YYYY-MM-DDTHH:mm:ssZ"),
            channel: 0,
          });
        this.devices[0].data[0].dateTime = moment(
          this.devices[0].data[0].dateTime
        ).format("DD.MM.YYYY HH:mm");
        this.deviceTypeConfigurations =
          await this.deviceTypeService.getDeviceTypesConfiguration(1, 10000);
        if (this.devices[0].data[0].payload.records) {
          this.haveData = true;
          for (let key in this.devices[0].data[0].payload.records) {
            if (this.devices[0].data[0].payload.records.hasOwnProperty(key)) {
              this.deviceTypeConfigurations.forEach((element) => {
                if (element.data.id.toString() === key.toString()) {
                  Object.assign(
                    this.devices[0].data[0].payload.records[key],
                    element.data
                  );
                }
              });
            }
          }
        }
        this.deviceAssignments.forEach((element) => {
          if (element.assignment?.dataUuid === this.devices[0].dataUuid) {
            this.deviceName = element.assignment.name;
            this.deviceTypeId = element.device!.deviceTypeId;
            if (element.assignment.metadata[0] != undefined) {
              this.metadataMap.set(
                this.devices[0].dataUuid,
                element.assignment.metadata[0]
              );
            }
          }
        });
        this.loadedData = true;
      } catch (error) {
        this.haveData = false;
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isLoading = false;
      }
    },
    async saveChanges() {
      this.loadedData = false;
      try {
        this.deviceAssignments =
          await this.deviceAssignmentService.getAllDeviceAssignments({
            typeOfAssignment: "Company",
            pageSize: 100000,
            pageNumber: 1,
          });
        this.getDeviceData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    changeName(newName: any) {
      this.deviceName = newName;
    },
  },
});
</script>
<style scoped>
.ShadowStyle {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 0 4px rgba(0, 0, 0, 0.1);
}
</style>
