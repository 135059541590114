<template>
  <div>
    <multiselect
      v-model="modelValue"
      :multiple="true"
      :options="tags"
      :taggable="true"
      tag-placeholder="Add this as new tag"
      @tag="addTag"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Array as PropType<{ name: string; code: string }[]>,
    },
  },
  data() {
    return {
      tags: [] as { name: string; code: string }[],
    };
  },
  methods: {
    addTag(newTag: string) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.tags.push(tag);
      this.modelValue?.push(tag);
    },
  },
});
</script>

<style scoped></style>
