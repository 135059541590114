<template>
  <header
    class="fixed left-0 top-0 z-10 flex h-[72px] w-full items-center border-b-4 border-grey bg-white"
  >
    <div :class="`ml-[12px] flex  h-[72px] items-center`">
      <div class="flex w-full items-center justify-between">
        <img :class="`h-10`" :src="generateLogoPath()" alt="logo" />
      </div>
    </div>
    <div
      :class="`flex h-16  items-center justify-end space-x-6 ${
        sidebarStore.isSidebarOpened ? 'header-inner' : 'w-full'
      } mx-auto mr-[12px] text-white`"
    >
      <div class="flex items-center space-x-[12px]">
        <version-dropdown />
        <language-dropdown />
        <!-- <other-options-dropdown /> -->
        <user-dropdown />
      </div>
    </div>
  </header>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TheButton from "./TheButton.vue";
import { useSidebarStore } from "@/core/Store";
import UserDropdown from "./Dropdowns/UserDropdown.vue";
import { useThemeStore } from "../Store/themeStore";
import VersionDropdown from "@/core/Components/Dropdowns/VersionDropdown.vue";
import OtherOptionsDropdown from "@/core/Components/Dropdowns/OtherOptionsDropdown.vue";
import LanguageDropdown from "@/core/Components/LanguageDropdown.vue";

export default defineComponent({
  name: "HeaderBar",
  components: {
    OtherOptionsDropdown,
    TheButton,
    UserDropdown,
    VersionDropdown,
    LanguageDropdown,
  },
  data() {
    return {
      test: "",
      sidebarStore: useSidebarStore(),
      themeStore: useThemeStore(),
      isProduction: process.env.NODE_ENV === "production",
    };
  },

  methods: {
    generateLogoPath() {
      if (process.env.VUE_APP_PUBLIC_PATH !== "") {
        return `${process.env.VUE_APP_PUBLIC_PATH}${
          this.themeStore.companyLogo[this.themeStore.themeClass]
        }`;
      }

      return this.themeStore.companyLogo[this.themeStore.themeClass];
    },
  },
});
</script>
<style>
.header-inner {
  width: calc(100% - 20rem);
}
</style>
