import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isCreateModalOpened,
    "no-padding": true,
    title: "Create source",
    onToggleModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_form_wrapper, {
          "is-form-in-modal": true,
          "is-loading": _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createVNode(_component_select_input, {
                  modelValue: _ctx.source.spaceId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.source.spaceId) = $event)),
                  options: _ctx.parkingSpaces,
                  label: "name",
                  "label-text": "Parking space",
                  "reduce-key": "id"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_select_input, {
                  modelValue: _ctx.source.spotTypeId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.source.spotTypeId) = $event)),
                  options: _ctx.parkingSpotTypes,
                  label: "name",
                  "label-text": "Spot type",
                  "reduce-key": "id"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_select_input, {
                  modelValue: _ctx.source.spotId,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.source.spotId) = $event)),
                  options: _ctx.filteredParkingSpots,
                  label: "name",
                  "label-text": "Parking spot",
                  "reduce-key": "id"
                }, null, 8, ["modelValue", "options"]),
                _createVNode(_component_checkbox_input, {
                  modelValue: _ctx.source.showFree,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.source.showFree) = $event)),
                  "is-checked": _ctx.source.showFree,
                  label: "Show free"
                }, null, 8, ["modelValue", "is-checked"])
              ]),
              _: 1
            }),
            _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
          ]),
          _: 1
        }, 8, ["is-loading"])
      ], 32)
    ]),
    _: 1
  }, 8, ["is-displayed"]))
}