<template>
  <modal
    :have-modal="true"
    :is-displayed="isChangeOwnershipOpened"
    :is-select-modal="true"
    :no-padding="true"
    title="Change asset owner"
    @toggle-modal="$emit('toggle-modal')"
  >
    <confirmation-modal
      delete-text="changeOwner"
      :is-confirmation-modal-opened="isConfirmationModalOpened"
      @confirm-action="handleSubmit"
      @close-confirmation-modal="isConfirmationModalOpened = false"
      @toggle-modal="isConfirmationModalOpened = false"
    />
    <form-wrapper
      :is-form-in-modal="true"
      :is-loading="isLoading"
      :need-for-scroll="true"
    >
      <form @submit.prevent="isConfirmationModalOpened = true">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >User</label
            >
            <v-select
              v-model="selectedUserId"
              :class="`mt-1`"
              :options="users"
              :reduce="(user : any) => user.id"
              label="email"
              :clearable="false"
            ></v-select>
          </div>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Modal from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import VSelect from "vue-select";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import UserService, {
  User,
  UsersObject,
} from "@/modules/Users/Services/UserService";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";
import AssignmentService from "../Services/AssignmentService";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";

export default defineComponent({
  components: {
    Modal,
    FormWrapper,
    InputField,
    TheButton,
    VSelect,
    FormDivider,
    ButtonWrapper,
    ConfirmationModal,
  },
  props: ["currentOwnerId", "isChangeOwnershipOpened", "selectedAssetId"],
  emits: ["toggle-modal", "refresh"],
  data() {
    return {
      toast: useToast(),
      selectedUserId: this.currentOwnerId,
      userService: new UserService(),
      assignmentService: new AssignmentService(),
      users: [] as User[],
      usersPagination: {} as UsersObject,
      isLoading: false,
      isSubmitLoading: false,
      isConfirmationModalOpened: false,
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getAllUsers();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        this.assignmentService.editDeviceAssignment({
          deviceAssignment: [
            {
              op: "replace",
              value: this.selectedUserId,
              path: "/UserId",
            },
          ],
          id: this.selectedAssetId,
        });
        this.toast.success("Asset owner successfully changed!");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
        this.$emit("toggle-modal");
        this.$emit("refresh");
      }
    },
    async getAllUsers() {
      try {
        this.usersPagination = await this.userService.getAllUsersWithRoles(
          1,
          10000
        );
        this.users = this.usersPagination.items;
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>
<style scoped></style>
