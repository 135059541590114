import ApiService from "@/core/Axios/ApiService";
import CompanyService, {
  Company,
} from "@/modules/Companies/Services/CompanyService";
import IntegrationService, {
  Integration,
} from "@/modules/Integrations/Services/IntegrationService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type Application = {
  id: number;
  name: string;
  companyId: number;
  company: Company;
  isDefault: boolean;
  integrations: Integration[];
};

export type CreateApplication = Pick<Application, "name">;

export type ApplicationObject = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: Application[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
};

export default class ApplicationService {
  companyService = new CompanyService();
  integrationService = new IntegrationService();

  async getAllApplications(
    pageNumber = 1,
    pageSize = 10
  ): Promise<ApplicationObject> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: "/api/applications",
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortOrder: "Ascending",
        Include: ["Company", "IntegrationsIntegrationType"],
      },
    });
    const response = await ApiService.get(url);

    return {
      hasNextPage: response.data.hasNextPage,
      hasPreviousPage: response.data.hasPreviousPage,
      items: this._mapApplications(response.data.items),
      pageIndex: response.data.pageIndex,
      totalPages: response.data?.totalPages,
      totalCount: response.data?.totalCount,
    };
  }

  async getApplicationWithIdentifier(identifier: number): Promise<Application> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/applications/${identifier}`,
      config: {
        Include: ["Company", "IntegrationsIntegrationType"],
      },
    });
    const response = await ApiService.get(url);
    return this._mapApplication(response.data);
  }

  async createApplication(application: CreateApplication): Promise<any> {
    await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/applications`,
      application
    );
  }

  async updateApplication(application: Application): Promise<any> {
    const patchObject = [
      { op: "replace", path: "/name", value: application.name },
    ];
    await ApiService.patch(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/applications/${application.id}`,
      patchObject
    );
  }

  async deleteApplication(id: number): Promise<any> {
    return await ApiService.delete(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/applications/${id}`
    );
  }

  private _mapApplications(
    response: Application[],
    companies?: Company[]
  ): Application[] {
    return response.map((application: Application) =>
      this._mapApplication(application, companies)
    );
  }

  private _mapApplication(
    application: Application,
    companies?: Company[]
  ): Application {
    return {
      id: application.id,
      name: application.name,
      companyId: application.companyId,
      company: application.company,
      isDefault: application.isDefault,
      integrations: application?.integrations && application.integrations,
    };
  }
}
