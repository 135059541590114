import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "py-3" }
const _hoisted_2 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_3 = { class: "tags-input" }
const _hoisted_4 = { id: "tags" }
const _hoisted_5 = { class: "tag-title" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("ul", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagsNew, (tag, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "tag"
          }, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(tag), 1),
            _createElementVNode("span", {
              class: "tag-close-icon",
              onClick: () => _ctx.removeIndex(index)
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'times'],
                class: "text-[10px]"
              })
            ], 8, _hoisted_6)
          ]))
        }), 128))
      ]),
      _withDirectives(_createElementVNode("input", {
        ref: "tagsInput",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tag) = $event)),
        placeholder: "Press space to add next input",
        type: "text",
        onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.addTags && _ctx.addTags(...args)), ["prevent"]), ["space"]))
      }, null, 544), [
        [_vModelText, _ctx.tag]
      ])
    ])
  ]))
}