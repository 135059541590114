import ApiService from "@/core/Axios/ApiService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type Health = {
    mac: string;
    lastSync: string;
    batteryPercentage: number;        
  };

export type HealthRequest = {
    macs: string[];
    from: string;
    to: string;
  };

export default class Healthservice {
    async getHealthStatus(healthRequest: HealthRequest): Promise<any> {
        const url = GenerateUrl({
          path: "/api/sensors/health",
          prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH
        });
        const response = await ApiService.post(url, healthRequest);
        return response.data.map((packet: any) => {    
            return packet;
          });
      }      
}
