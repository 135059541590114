export default {
  "Sidebar": {
    "Parkom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SmartCity"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolna ploča"])},
    "ParkingSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirališta"])},
    "ParkingSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirna mjesta"])},
    "Sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detektori"])},
    "DigitalParkingSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalni parking znakovi"])},
    "ParkingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirne sesije"])},
    "BusControllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus kontroleri"])},
    "WorkOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radni nalozi"])},
    "Assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moji uređaji"])},
    "AssignmentMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci uređaja"])},
    "Devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređaji"])},
    "DeviceMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci uređaja"])},
    "DeviceTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipovi uređaja"])},
    "DeviceTypeMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci tipa uređaja"])},
    "Groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupe"])},
    "Integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracije"])},
    "Organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacije"])},
    "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisnici"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
    "Authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizacija"])},
    "GlobalAuthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globalna autorizacija"])},
    "AccessManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access manager"])},
    "ProfileConfigurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguracije profila"])},
    "Resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resursi"])},
    "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Događaji"])},
    "DeviceActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcije uređaja"])},
    "DynamicDeviceActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinamičke akcije uređaja"])},
    "StaticDeviceActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statičke akcije uređaja"])},
    "FirmwareUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažuriranje firmware-a"])},
    "ResponseMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odgovori"])},
    "Production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodnja"])}
  },
  "SidebarMenu": {
    "ParkingSpacesSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odabir parkirališta"])},
    "SelectParkingSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberi parkirališta"])}
  },
  "Languages": {
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engleski"])},
    "Croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrvatski"])},
    "Slovakian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovački"])}
  },
  "Action": {
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obriši"])},
    "ManageSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljaj parkirnim mjestima"])},
    "AddDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj uređaje"])},
    "ViewDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregledaj uređaje"])},
    "Integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracije"])},
    "MakeActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavi kao aktivnu"])},
    "RemoveUserFromOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukloni korisnika iz organizacije"])},
    "ViewDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregledaj detalje"])},
    "Markers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markeri"])}
  },
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj"])},
  "CreateBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj skupno"])},
  "CreateZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj zonu"])},
  "CancelEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekini uređivanje"])},
  "CreateFloorplan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj tlocrt"])},
  "AddUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj korisnika"])},
  "Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbroj"])},
  "NoChangesWereMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nema nikakvih promjena!"])},
  "General": {
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremi"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odustani"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obriši"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukloni"])},
    "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Učitaj"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreirano!"])},
    "EditedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uređeno!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obrisano!"])}
  },
  "Dashboard": {
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Događaj"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupno"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zauzeto"])},
    "Unoccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobodno"])},
    "Offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "Enters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulasci"])},
    "Exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izlasci"])},
    "Load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popunjenost"])},
    "OccupationByHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popunjenost po satu"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkiralište"])},
    "ParkingSpot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirno mjesto"])},
    "UnoccupiedSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobodno od"])},
    "OccupiedSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zauzeto od"])}
  },
  "ParkingSpaces": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirališta"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grad"])},
    "Street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulica"])},
    "StreetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj ulice"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zauzeto"])},
    "Unoccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slobodno"])},
    "Navigable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigabilno"])},
    "Visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidljivo"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivno"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geografska širina"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geografska dužina"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj parkiralište"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi parkiralište"])},
    "ParkingSpaceLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odaberi lokaciju parkirališta"])}
  },
  "ParkingSpots": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirna mjesta"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkiralište"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "MAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "OccupiedTimestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zauzeto od"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zauzeto"])},
    "Index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indeks"])},
    "Public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Javno"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj parkirno mjesto"])},
    "CreateBulkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj skupno parkirna mjesta"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi parkirno mjesto"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkiralište"])},
    "ParkingSpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip parkirnog mjesta"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geografska širina"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geografska dužina"])},
    "Visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidljivo"])},
    "StartIndex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početni indeks"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Količina"])},
    "CreateBulkSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirna mjesta su uspješno kreirana!"])}
  },
  "Displays": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasloni"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadnja sinkronizacija"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivno"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "LastDisplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posljednje prikazano"])},
    "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Događaji"])},
    "Nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodovi"])},
    "ManageNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljaj nodovima"])},
    "ManageSources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravaljanje izvorima"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geografska širina"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geografska dužina"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj zaslon"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi zaslon"])},
    "NodeDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node je uspješno obrisan!"])},
    "SourceDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source je uspješno obrisan!"])},
    "Timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vremenska oznaka"])},
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
  },
  "ParkingSessions": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirne sesije"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početak"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkiralište"])},
    "Spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirno mjesto"])},
    "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trajanje"])},
    "SpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip parkirnog mjesta"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisnik"])}
  },
  "BusControllers": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus kontroleri"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadnja sinkronizacija"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkiralište"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj bus kontroler"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi bus kontroler"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkiralište"])}
  },
  "Sensors": {
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkiralište"])},
    "SpaceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking ID"])},
    "ParkingSpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip parkirnog mjesta"])},
    "Index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indeks"])},
    "Spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkirno mjesto"])},
    "MAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zauzeto"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadnja sinkronizacija"])},
    "FirmwareVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzija firmwarea"])},
    "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura"])},
    "Packets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketi"])},
    "BatteryLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razina baterije"])},
    "Voltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napon"])},
    "UnhandledReboots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neobrađeni restartovi"])},
    "RSSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSSI"])},
    "RSRQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRQ"])},
    "SearchingForNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traženje mreže"])},
    "Cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ćelija"])},
    "Band": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pojas"])},
    "ECL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECL"])},
    "RSRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRP"])},
    "SNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNR"])},
    "IMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMSI"])},
    "CCID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCID"])},
    "TotalSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupno vrijeme spavanja"])},
    "ModemDisableCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brojač onemogućavanja modema"])},
    "NoResponseCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brojač bez odgovora"])},
    "Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodijeli"])},
    "AssignSensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodijeli senzor"])},
    "AssignTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dodijeli ", _interpolate(_named("mac")), " na parkirno mjesto"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj senzor"])}
  },
  "SensorPackets": {
    "RTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RTC"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "DeviceState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status uređaja"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Packets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paketi"])},
    "BatteryPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postotak baterije"])},
    "TotalConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupna potrošnja (mAh)"])},
    "BatteryVoltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napon baterije"])},
    "FirmwareVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzija firmwarea"])},
    "RSSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSSI"])},
    "RSRQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRQ"])},
    "Cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ćelija"])},
    "ECL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECL"])},
    "Band": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Band"])},
    "SearchingForNetworkCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brojač traženja mreže"])},
    "RSRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRP"])},
    "SNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNR"])},
    "MagX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag X"])},
    "MagY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag Y"])},
    "MagZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag Z"])},
    "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatura"])},
    "Mag1RawX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw X"])},
    "Mag1RawY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw Y"])},
    "Mag1RawZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw Z"])},
    "RadarWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Težina radara"])},
    "RadarWeightKap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Težina radara Kap"])},
    "RadarDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radarska udaljenost"])},
    "RadarDistanceKap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radarska udaljenost Kap"])},
    "MagDiff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag razlika (s)"])},
    "UnhandledRestartCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neobrađeni brojač ponovnog pokretanja"])},
    "ModemRebootCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj ponovnog pokretanja modema"])},
    "PowerOnCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broj uključivanja"])},
    "RuntimeTotalSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupno vrijeme izvođenja (s)"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "IMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMSI"])},
    "FwUpdateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početak ažuriranja Firmwarea"])},
    "FwUpdateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Završetak ažuriranja Firmwarea"])},
    "FwUpdateVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware verzija"])},
    "TotalSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukupno spavanje"])},
    "ModemDisableCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brojač onemogućavanja modema"])},
    "NoResponseCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brojač bez odgovora"])},
    "StoredAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pohranjeno u"])},
    "TimeDifference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razlika (s)"])},
    "BleTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ble tags"])}
  },
  "WorkOrders": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radni nalozi"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacija"])},
    "Pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Količina"])},
    "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentar"])},
    "DeliveredOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostavljeno"])},
    "UserCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreirao"])},
    "UserUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažurirao"])},
    "AppliedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primjenjeno na"])},
    "IsApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primjenjeno"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj radni nalog"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi radni nalog"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisnik"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "AddToWorkOrderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj u radni nalog"])},
    "AddToWorkOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno dodano u radni nalog!"])},
    "RemoveFromWorkOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uklonjeno iz radnog naloga!"])}
  },
  "Authorization": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizacija"])},
    "ToUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na korisnika"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uloga"])},
    "ResourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip resursa"])},
    "Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resurs"])},
    "CreatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreirao resurs"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisnik"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodijeli ulogu"])},
    "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređaj"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi ulogu"])},
    "CreateAuthorizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno dodijeljena uloga!"])},
    "ResourceEditedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uređen resurs!"])}
  },
  "GlobalAuthorization": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global authorization"])},
    "ToUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To user"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "ResourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource type"])},
    "Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
    "CreatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created resource"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign role"])},
    "Asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit role"])},
    "CreateAuthorizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global authorization created successfully!"])},
    "ResourceEditedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource edited successfully!"])}
  },
  "Groups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupe"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj grupu"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi grupu"])},
    "CreateGroupSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreirana grupa!"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacija"])}
  },
  "Integrations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracije"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip"])},
    "Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupa"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivno"])},
    "ExecutionOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redoslijed izvršavanja"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj integraciju"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi integraciju"])},
    "IntegrationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip integracije"])},
    "Endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endpoint"])},
    "IP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP adresa"])},
    "Port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port"])},
    "ConnectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip konekcije"])},
    "MqttProtocolVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mqtt protokol verzija"])},
    "HasCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaci za prijavu"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisničko ime"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lozinka"])},
    "CleanSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čista sesija"])},
    "Topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
    "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaglavlje"])},
    "Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanal"])},
    "Channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanali"])},
    "RetainFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sačuvaj zastavicu"])},
    "CreateHttpSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreirana HTTP integracija!"])},
    "CreateUdpSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreirana UDP integracija!"])}
  },
  "Organizations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacije"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj organizaciju"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi organizaciju"])},
    "ActiveCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivna organizacija"])},
    "CreateOrganizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreirana organizacija!"])},
    "EditOrganizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uređena organizacija!"])},
    "OrganizationChangedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno promijenjena aktivna organizacija!"])},
    "OrganizationDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obrisana organizacija!"])}
  },
  "Users": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisnici"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korisničko ime"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uloga"])},
    "AddUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj korisnika"])},
    "AddTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj korisnika u organizaciju"])},
    "AddUserSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno dodan korisnik u organizaciju!"])},
    "UserRemovedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uklonjen korisnik iz organizacije!"])}
  },
  "VersionDropdown": {
    "Version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzija"])},
    "LegalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravne informacije"])},
    "TermsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvjeti korištenja"])},
    "Imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "TermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uvjeti i odredbe"])},
    "DataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaštita podataka"])}
  },
  "UserDropdown": {
    "EditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi profil"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjava"])}
  },
  "Delete": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obriši"])},
    "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeste li sigurni da želite"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["obrisati"])},
    "RemoveDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uklonite uređaj"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ukloniti"])}
  },
  "Zone": {
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boja"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj zonu"])},
    "Floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kat"])},
    "ZoneCreatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreirana zona!"])}
  },
  "DeviceActions": {
    "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređaj"])},
    "RequestTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme isteka zahtjeva"])},
    "RequestBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijelo zahtjeva"])},
    "ResponseBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijelo odgovora"])},
    "ClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Klijenta"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "StoreCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremi poruke"])},
    "DeviceActionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip akcije"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj akciju"])}
  },
  "FirmwareUpdate": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažuriranje firmware-a"])}
  },
  "ResponseMessages": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruke odgovora"])},
    "SendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji poruku"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruka"])},
    "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruke"])},
    "IsSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslano"])},
    "UpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ažurirano"])},
    "CreatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreirano"])}
  },
  "Devices": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređaji"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "Imei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "Mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC"])},
    "Imsi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMSI"])},
    "ExternalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanjski ID"])},
    "SerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serijski broj"])},
    "DeviceTypeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip uređaja"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvrtka"])},
    "ParentDeviceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roditeljski uređaj"])},
    "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaci"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj uređaj"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi uređaj"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreiran uređaj!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obrisan uređaj!"])},
    "UpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uređen uređaj!"])}
  },
  "DeviceMetadata": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci uređaja"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaci"])},
    "MetadataTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipovi metapodataka"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj metapodatak za uređaj"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi metapodatak za uređaj"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreiran metapodatak za uređaj!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obrisan metapodatak za uređaj!"])},
    "UpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uređen metapodatak za uređaj!"])}
  },
  "DeviceTypes": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "ProductionPN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkcijski PN"])},
    "CustomerPN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klijentski PN"])},
    "SerialNumberType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip serijskog broja"])},
    "ProductionEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkcijski omogućen"])},
    "DeviceTypeMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci tipa uređaja"])},
    "Metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipovi uređaja"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj tip uređaja"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi tip uređaja"])},
    "ParentDeviceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadređeni tip uređaja"])},
    "CreateRelationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj relaciju tipova uređaja"])},
    "DeviceTypeMetadataRelationCreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreirana relacija tipova uređaja!"])},
    "CreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreiran tip uređaja!"])},
    "DeleteRelationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obriši relaciju metapodataka tipova uređaja"])},
    "EditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uređen tip uređaja!"])},
    "DeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obrisan tip uređaja!"])},
    "RelationDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obrisana relacija tipova uređaja!"])}
  },
  "DeviceTypeMetadata": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci tipa uređaja"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naziv"])},
    "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaci"])},
    "MetadataTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipovi metapodataka"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj metapodatak za tip uređaja"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi metapodatak za tip uređaja"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreiran metapodatak za tip uređaja!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obrisan metapodatak za tip uređaja!"])},
    "UpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno uređen metapodatak za tip uređaja!"])}
  },
  "AssignmentMetadata": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci uređaja"])},
    "AssignmentMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metapodaci uređaja"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj metapodatak za uređaj"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredi metapodatak za uređaj"])},
    "CreateRelationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreiraj relaciju metapodataka uređaja"])},
    "DeleteRelationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obriši relaciju metapodataka uređaja"])},
    "CreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno kreiran metapodatak za uređaj!"])},
    "DeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješno obrisan metapodatak za uređaj!"])}
  }
}