<template>
  <modal
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    :title="$t('Users.AddTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="formData.userEmail"
            :errorMessage="v$?.formData['userEmail']?.$errors[0]?.$message"
            :isError="v$?.formData['userEmail']?.$error"
            :label="$t('Users.Email')"
            :required="true"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import NotificationService from "@/core/Services/NotificationService";
import CompanyService, {
  AddUserToCompany,
} from "@/modules/Companies/Services/CompanyService";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import UserService from "../Services/UserService";
import { useSidebarStore } from "@/core/Store";
import { email, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import { useUserStore } from "@/core/Store/userStore";
import SpotiumService from "@/core/Services/SpotiumService";
import { useToast } from "vue-toastification";
import Modal from "@/core/Components/Modal.vue";
import AuthorizationService from "@/modules/AdminDashboard/Submodules/Authorization/Services/AuthorizationService";
import { ResourceDto } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/AuthorizationTypes";

export default defineComponent({
  components: {
    MainSection,
    FormWrapper,
    InputField,
    TheButton,
    ToastComponent,
    ButtonWrapper,
    FormDivider,
    Modal,
  },
  props: { refreshData: Function, isCreateModalOpened: Boolean },
  emits: ["toggle-modal"],
  inject: ["user"],
  data() {
    return {
      userStore: useUserStore(),
      v$: useValidate() as any,
      isSubmitLoading: false as boolean,
      notificationService: new NotificationService(this.user.name),
      companyService: new CompanyService(),
      userService: new UserService(),
      spotiumService: new SpotiumService(),
      authorizationService: new AuthorizationService(),
      toast: useToast(),
      sidebarStore: useSidebarStore(),
      validationErrors: [] as any,
      allResources: [] as ResourceDto[],
      error: "" as string,
      isLoading: false,
      formData: {
        companyId: 0,
        userEmail: "" as string,
      } as AddUserToCompany,
    };
  },
  validations() {
    return {
      formData: {
        userEmail: {
          required,
          email,
        },
      },
    };
  },

  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;

        try {
          await this.spotiumService.addUserToCompany(
            this.formData.userEmail,
            this.userStore.activeCompanyId
          );
          // await this.getAllResources();
          // const userRole = this.allResources.filter(
          //   (resource) => resource.userEmail === this.formData.userEmail
          // )[0];
          // console.log(userRole);
          // if (userRole) {
          //   await this.authorizationService.editResource(userRole.id, [
          //     {
          //       path: "/roleId",
          //       op: "replace",
          //       value: 70,
          //     },
          //   ]);
          // }
          this.toast.success(this.$t("Users.AddUserSuccess"));
          await this.refreshData?.();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    async getAllResources() {
      try {
        this.allResources = await this.authorizationService.getAllResources();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>
<style scoped></style>
