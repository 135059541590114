import { defineStore } from "pinia";
import { useModuleToggleStore } from "@/core/Store/moduleToggleStore";

export const useServiceStore = defineStore("service", {
  state: () => {
    return {
      currentActiveServiceId: 0,
      moduleStore: useModuleToggleStore(),
      services: [
        {
          id: 1,
          domain: "its.spotium.hr",
        },
        {
          id: null,
          domain: "access.spotium.hr",
        },
        {
          id: 1,
          domain: "localhost:8080",
        },
        {
          id: 1,
          domain: "app.lods.sick.hr",
        },
      ],
    };
  },

  actions: {
    getServiceId(domain: string) {
      switch (domain) {
        case "localhost:8080":
          this.moduleStore.isDevelopment = true;
          break;
        case "access.spotium.hr":
          this.moduleStore.isDevelopment = false;
          break;
        case "its.spotium.hr":
          this.moduleStore.isDevelopment = false;
          break;
        case "app.lods.sick.hr":
          this.moduleStore.isDevelopment = false;
          break;
        case "app.sfi.sick.hr":
          this.moduleStore.isDevelopment = false;
          break;
      }

      const response = this.services.find(
        (service) => service.domain === domain
      );

      if (!response || !response.id) {
        return;
      }

      this.currentActiveServiceId = response.id;
    },
  },
});
