import {
  CreateDeviceTypeMetadata,
  DeviceTypeMetadata,
} from "@/modules/Production/Submodules/DeviceTypeMetadata/Services/DeviceTypeMetadataTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export class DeviceTypeMetadataService {
  async getDeviceTypeMetadata(): Promise<DeviceTypeMetadata[]> {
    const url = GenerateUrl({
      path: "/api/device-type-metadata",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        Include: ["MetadataType"],
        PageNumber: 1,
        PageSize: 100000,
      },
    });

    const response = await ApiService.get(url);

    return this._mapDeviceTypesMetadata(response.data.items);
  }

  async getDeviceTypeMetadataTypes(): Promise<
    { id: number; name: string; configSchema: any | null }[]
  > {
    const url = GenerateUrl({
      path: "/api/device-type-metadata-types",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: 1,
        PageSize: 100000,
      },
    });

    const response = await ApiService.get(url);

    return response.data.items;
  }

  async createDeviceTypeMetadata(
    createDeviceTypeMetadata: CreateDeviceTypeMetadata
  ) {
    const url = GenerateUrl({
      path: "/api/device-type-metadata",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, createDeviceTypeMetadata);
  }

  async createDeviceTypeMetadataRelation({
    deviceTypeMetadataId,
    deviceTypeIdList,
  }: {
    deviceTypeMetadataId: number;
    deviceTypeIdList: number[];
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/device-type-metadata/${deviceTypeMetadataId}/relations`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, { deviceTypeIds: deviceTypeIdList });
  }

  async deleteDeviceTypeMetadataRelation({
    deviceTypeMetadataId,
    deviceTypeIdList,
  }: {
    deviceTypeMetadataId: number;
    deviceTypeIdList: number[];
  }) {
    const url = GenerateUrl({
      path: `/api/device-type-metadata/${deviceTypeMetadataId}/relations`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url, { deviceTypeIds: deviceTypeIdList });
  }

  async updateDeviceTypeMetadata({
    id,
    deviceTypeMetadata,
  }: {
    id: number;
    deviceTypeMetadata: PatchObject[];
  }) {
    const url = GenerateUrl({
      path: `/api/device-type-metadata/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, deviceTypeMetadata);
  }

  async deleteDeviceTypeMetadata(id: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/device-type-metadata/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  private _mapDeviceTypesMetadata(
    response: DeviceTypeMetadata[]
  ): DeviceTypeMetadata[] {
    return response.map((deviceTypeMetadata) =>
      this._mapDeviceTypeMetadata(deviceTypeMetadata)
    );
  }

  private _mapDeviceTypeMetadata(
    deviceTypeMetadata: DeviceTypeMetadata
  ): DeviceTypeMetadata {
    return {
      id: deviceTypeMetadata.id,
      name: deviceTypeMetadata.name,
      metadataTypeId: deviceTypeMetadata.metadataTypeId,
      metadataType: deviceTypeMetadata.metadataType,
      data: deviceTypeMetadata.data,
    };
  }
}
