import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "mb-1 flex w-[350px] cursor-pointer items-center justify-between bg-primary p-2 text-white duration-300 ease-in-out hover:transform hover:font-semibold",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitToggleDatagrid && _ctx.emitToggleDatagrid(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: _ctx.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(_ctx.name), 1)
    ]),
    _createVNode(_component_font_awesome_icon, {
      class: _normalizeClass(`ml-2 transform ${
        _ctx.isDropdownOpened ? 'rotate-90' : 'rotate-0'
      } duration-300 ease-out`),
      icon: ['fas', 'chevron-right']
    }, null, 8, ["class"])
  ]))
}