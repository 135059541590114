import ApiService from "@/core/Axios/ApiService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import { Company } from "@/modules/Companies/Services/CompanyService";
import { Role } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/RoleTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type UsersObject = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: User[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
};

export type User = {
  id: number;
  username: string;
  email: string;
  activeCompanyId: number;
  activeCompany: Company;
  languageCode: string;
  roles?: Role[];
};

export type CreateUser = {
  email?: string;
  companyId: number;
  username?: string;
  languageCode?: string;
};

export type UserShareAssignment = {
  id: number;
  username: string;
  email: string;
  activeCompanyId: number;
  activeCompany: Company;
  languageCode: string;
};

export default class UserService {
  async getAllUsers(
    pageNumber = 1,
    pageSize = 10,
    allUsers = false,
    emailSearchValue = ""
  ): Promise<UsersObject> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: "/api/users",
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        AllUsers: allUsers ? "true" : "false",
        ...(emailSearchValue != "" && { Email: emailSearchValue }),
      },
    });
    const response = await ApiService.get(url);

    return {
      hasNextPage: response.data.hasNextPage,
      hasPreviousPage: response.data.hasPreviousPage,
      items: this._mapUsers(response.data.items),
      pageIndex: response.data.pageIndex,
      totalPages: response.data?.totalPages,
      totalCount: response.data?.totalCount,
    };
  }

  async getAllUsersWithRoles(
    pageNumber = 1,
    pageSize = 10
  ): Promise<UsersObject> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
      path: "/api/companies/users",
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });
    const response = await ApiService.get(url);

    return {
      hasNextPage: response.data.hasNextPage,
      hasPreviousPage: response.data.hasPreviousPage,
      items: this._mapUsers(response.data.items),
      pageIndex: response.data.pageIndex,
      totalPages: response.data?.totalPages,
      totalCount: response.data?.totalCount,
    };
  }

  async getAllUsersThatDeviceIsSharedTo({
    assignmentId,
    pageNumber,
    pageSize,
    typeOfShare,
  }: {
    assignmentId: number;
    pageNumber: number;
    pageSize: number;
    typeOfShare: "Shared" | "Pending";
  }): Promise<UserShareAssignment[]> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
      path: `/api/users/shares/assignment`,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        AssignmentId: assignmentId,
        TypeOfShare: typeOfShare,
      },
    });
    const response = await ApiService.get(url);
    return this._mapUserShareAssignments(response.data.items);
  }

  async getUserWithIdentifier(userId: number): Promise<User> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/users/${userId}`,
    });
    const response = await ApiService.get(url);
    return this._mapUser(response.data);
  }

  async authenticateUser(email: string, username: string): Promise<User> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/authenticate`,
    });
    const response = await ApiService.post(url, {
      email: email,
    });

    return this._mapUser(response.data);
  }

  async updateUser(companyId: number, userId: number): Promise<any> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/users/${userId}`,
    });
    return await ApiService.patch(url, [
      {
        op: "replace",
        value: companyId,
        path: "/activeCompanyId",
      },
    ]);
  }

  async changeUserLanguage({
    userId,
    languageCode,
  }: {
    userId: number;
    languageCode: string;
  }): Promise<any> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/users/${userId}`,
    });

    return await ApiService.patch(url, [
      { op: "replace", value: languageCode, path: "/languageCode" },
    ]);
  }

  async createUser(user: CreateUser): Promise<any> {
    const response = await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/users`,
      user
    );
    return response.data;
  }

  private _mapUsers(response: User[]): User[] {
    return response.map((user: User) => this._mapUser(user));
  }

  private _mapUser(user: User): User {
    return {
      id: user.id,
      email: user.email,
      username: user.username,
      activeCompanyId: user.activeCompanyId,
      activeCompany: user.activeCompany,
      languageCode: user.languageCode,
      roles: user.roles,
    };
  }

  private _mapUserShareAssignments(
    response: UserShareAssignment[]
  ): UserShareAssignment[] {
    return response.map((user: UserShareAssignment) =>
      this._mapUserShareAssignment(user)
    );
  }

  private _mapUserShareAssignment(
    user: UserShareAssignment
  ): UserShareAssignment {
    return {
      id: user.id,
      email: user.email,
      username: user.username,
      activeCompanyId: user.activeCompanyId,
      activeCompany: user.activeCompany,
      languageCode: user.languageCode,
    };
  }
}
