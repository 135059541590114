import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-2 px-6" }
const _hoisted_2 = { class: "count-me flex w-full items-center justify-between rounded-sm bg-lightgray px-4 py-1 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "have-modal": _ctx.haveModal,
    "is-displayed": _ctx.isLiveDataModalOpened,
    "is-full-screen-modal": true,
    "need-for-scroll": true,
    title: `Live data for ${_ctx.device.assignment.name}`,
    onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.webSocketData, (item) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.formatDateTime(item.receivedAt)), 1),
            _createElementVNode("p", null, _toDisplayString(item), 1)
          ]))
        }), 256))
      ])
    ]),
    _: 1
  }, 8, ["have-modal", "is-displayed", "title"]))
}