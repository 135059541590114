<template>
  <modal-component :is-displayed="isOverrideModalOpened" custom-modal-size="w-3/12" title="Override parking spot"
                   @toggle-modal="$emit('toggle-modal')">
    <div>
      <div class="w-full flex flex-col items-center justify-center">
        <div class="w-24 h-24 bg-primarylighthover rounded-full flex justify-center items-center">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']" class="text-4xl text-primary"/>
        </div>
        <div class="pt-4 pb-8 text-center">
          <h2 class="font-medium text-lg">Override selected parking spot</h2>
          <p class="text-sm">Are you sure you want to override selected parking spot?</p>
        </div>
      </div>
      <div class="flex px-6 gap-2">
        <the-button :full-width="true" button-type="oulineButton" text="Cancel"
                    @execute-method="$emit('toggle-modal')"/>
        <the-button :full-width="true" button-type="primaryButton" text="Override"
                    @execute-method="$emit('override-action')"/>
      </div>
    </div>
  </modal-component>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: {TheButton, ModalComponent},
  props: {
    isOverrideModalOpened: Boolean,
  },
  emits: ["toggle-modal", "override-action"],
});
</script>

<style scoped></style>
