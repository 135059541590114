<template>
  <main-section>
    <loader v-if="isLoading" />
    <div v-else class="h-full">
      <div class="flex h-[46px] gap-4">
        <dx-date-box
          v-model="from"
          :height="46"
          :show-clear-button="true"
          display-format="dd.MM.yyyy HH:mm"
          type="datetime"
        />
        <dx-date-box
          v-model="to"
          :height="46"
          :show-clear-button="true"
          display-format="dd.MM.yyyy HH:mm"
          type="datetime"
        />
        <the-button
          :icon="['fas', 'rotate-left']"
          button-type="primaryButton"
          @execute-method="getParkingSessions"
        />
      </div>
      <custom-table
        :is-action-list-not-displayed="true"
        :is-data-source-loading="isDataSourceLoading"
        :show-per-page="16"
        :table-data="parkingSessions"
        :table-headers="tableHeaders"
        table-height="93%"
        @force-refresh="getParkingSessions"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import ParkingSessionService from "@/modules/Parkom/Submodules/ParkingSessions/Services/ParkingSessionService";
import { ParkingSession } from "@/modules/Parkom/Submodules/ParkingSessions/Services/ParkingSessionTypes";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Loader from "@/core/Components/Loader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import DxDateBox from "devextreme-vue/date-box";
import moment from "moment/moment";
import { useParkingSpaceFilterStore } from "@/core/Store/useParkingSpaceFilterStore";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: {
    TheButton,
    DxDateBox,
    TableActionHeader,
    CustomTable,
    Loader,
    MainSection,
  },
  data() {
    return {
      parkingSessionService: new ParkingSessionService(),
      parkingSessions: [] as ParkingSession[],
      toast: useToast(),
      changeCompanyStore: useChangeCompanyStore(),
      isDataSourceLoading: false,
      isLoading: false,
      from: moment().startOf("day").format("YYYY-MM-DDTHH:mm"),
      to: moment().endOf("day").format("YYYY-MM-DDTHH:mm"),
      parkingSpaceFilterStore: useParkingSpaceFilterStore(),
      tableHeaders: {
        start: { header: "ParkingSessions.Start", visible: true },
        end: { header: "ParkingSessions.End", visible: true },
        spaceName: { header: "ParkingSessions.Space", visible: true },
        spotName: { header: "ParkingSessions.Spot", visible: true },
        spotType: { header: "ParkingSessions.SpotType", visible: true },
        durationMinutes: { header: "ParkingSessions.Duration", visible: true },
        icdCustomerName: { header: "ParkingSessions.Customer", visible: true },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getParkingSessions();
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getParkingSessions();
    },
  },
  methods: {
    async getParkingSessions() {
      this.isDataSourceLoading = true;
      try {
        this.parkingSessions =
          await this.parkingSessionService.getParkingSessionsFilter({
            pageNumber: 1,
            pageSize: 10000,
            from: moment(this.from).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), 
            to: moment(this.to).utc().format("YYYY-MM-DDTHH:mm:ss[Z]"),
            parkingSpaceIds:
              this.parkingSpaceFilterStore.selectedParkingSpaceIds,
          });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
  },
});
</script>

<style scoped></style>
