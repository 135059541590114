import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_form = _resolveComponent("create-form")!
  const _component_assign_form = _resolveComponent("assign-form")!
  const _component_sensor_sent_packets = _resolveComponent("sensor-sent-packets")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_create_form, {
      "is-create-modal-opened": _ctx.isCreateModalOpened,
      "refresh-data": _ctx.getSensors,
      onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCreateModalOpened = false))
    }, null, 8, ["is-create-modal-opened", "refresh-data"]),
    (_ctx.isAssignModalOpened)
      ? (_openBlock(), _createBlock(_component_assign_form, {
          key: 0,
          "is-assign-modal-opened": _ctx.isAssignModalOpened,
          "refresh-data": _ctx.getSensors,
          sensor: _ctx.selectedSensor,
          onToggleModal: _ctx.toggleAssignModal
        }, null, 8, ["is-assign-modal-opened", "refresh-data", "sensor", "onToggleModal"]))
      : _createCommentVNode("", true),
    (_ctx.isSensorPacketsSentDisplay)
      ? (_openBlock(), _createBlock(_component_sensor_sent_packets, {
          key: 1,
          "is-sensor-packets-sent-displayed": _ctx.isSensorPacketsSentDisplay,
          mac: _ctx.selectedSensor.mac,
          title: `${_ctx.selectedSensor.space} ${_ctx.selectedSensor.spot} - ${_ctx.selectedSensor.mac}`,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isSensorPacketsSentDisplay = false))
        }, null, 8, ["is-sensor-packets-sent-displayed", "mac", "title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateAssignment,
                "button-text": "Create",
                title: "Sensors",
                onExecuteMethod: _ctx.toggleCreateModal
              }, null, 8, ["permission-id", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                "allow-auto-resize": true,
                "assign-column": true,
                "is-action-list-not-displayed": true,
                "is-data-source-loading": _ctx.isDataSourceLoading,
                "render-summary": ['mac'],
                "show-per-page": 16,
                "table-data": _ctx.sensors,
                "table-headers": _ctx.tableHeaders,
                "table-height": "93%",
                onForceRefresh: _ctx.getSensors,
                onViewSensorPackets: _ctx.toggleSensorSentPackets,
                onAssignSensor: _ctx.toggleAssignModal
              }, null, 8, ["is-data-source-loading", "table-data", "table-headers", "onForceRefresh", "onViewSensorPackets", "onAssignSensor"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}