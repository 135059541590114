import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_form = _resolveComponent("create-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_display_events = _resolveComponent("display-events")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 0,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "refresh-data": _ctx.getBusControllers,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCreateModalOpened = false))
        }, null, 8, ["is-create-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 1,
          "bus-controller-prop": _ctx.selectedBusController,
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "refresh-data": _ctx.getBusControllers,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditModalOpened = false))
        }, null, 8, ["bus-controller-prop", "is-edit-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isBusControllerEventsShown)
      ? (_openBlock(), _createBlock(_component_display_events, {
          key: 2,
          "is-display-events-opened": _ctx.isBusControllerEventsShown,
          id: _ctx.selectedBusController.externalAssignmentUuid,
          title: `${_ctx.selectedBusController.imei}`,
          onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isBusControllerEventsShown = false))
        }, null, 8, ["is-display-events-opened", "id", "title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateAssignment,
                title: _ctx.$t('BusControllers.Title'),
                "button-text": "Create",
                onExecuteMethod: _ctx.toggleCreateModal
              }, null, 8, ["permission-id", "title", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                options: _ctx.options,
                "render-summary": ['imei'],
                "show-per-page": 16,
                "table-data": _ctx.busControllers,
                "table-headers": _ctx.tableHeaders,
                "table-height": "93%",
                isActionListNotDisplayed: 
          !_ctx.permissionStore.hasPermission(_ctx.permissions.EditAssignment)
        ,
                onForceRefresh: _ctx.getBusControllers,
                onSelectedRows: _ctx.getSelectedRows,
                onViewDisplayEvents: _ctx.toggleBusControllerEvents
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "isActionListNotDisplayed", "onForceRefresh", "onSelectedRows", "onViewDisplayEvents"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}