<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    :title="$t('ParkingSpaces.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <map-location-selector
      v-if="isMapLocationModalOpened"
      :coordinates-prop="{
        lat: parkingSpace.latitude,
        lng: parkingSpace.longitude,
      }"
      :is-map-location-modal-opened="isMapLocationModalOpened"
      @toggle-modal="toggleMapLocationModal"
      @get-coordinates="getMarkerCoordinates"
    />
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="parkingSpace.name"
            :error-message="this.v$?.parkingSpace['name']?.$errors[0]?.$message"
            :is-error="v$?.parkingSpace['name']?.$error"
            :label="$t('ParkingSpaces.Name')"
            :required="true"
          />
          <input-field
            v-model="parkingSpace.city"
            :error-message="this.v$?.parkingSpace['city']?.$errors[0]?.$message"
            :is-error="v$?.parkingSpace['city']?.$error"
            :label="$t('ParkingSpaces.City')"
            :required="true"
          />
          <input-field
            v-model="parkingSpace.street"
            :error-message="
              this.v$?.parkingSpace['street']?.$errors[0]?.$message
            "
            :is-error="v$?.parkingSpace['street']?.$error"
            :label="$t('ParkingSpaces.Street')"
            :required="true"
          />
          <input-field
            v-model="parkingSpace.streetNumber"
            :error-message="
              this.v$?.parkingSpace['streetNumber']?.$errors[0]?.$message
            "
            :is-error="v$?.parkingSpace['streetNumber']?.$error"
            :label="$t('ParkingSpaces.StreetNumber')"
            :required="true"
            type="number"
          />
          <div
            class="grid grid-cols-2 gap-6"
            style="grid-template-columns: auto min-content"
          >
            <div>
              <input-field
                v-model="parkingSpace.latitude"
                :error-message="
                  this.v$?.parkingSpace['latitude']?.$errors[0]?.$message
                "
                :is-error="v$?.parkingSpace['latitude']?.$error"
                :label="$t('ParkingSpaces.Latitude')"
                :required="true"
              />
              <input-field
                v-model="parkingSpace.longitude"
                :error-message="
                  this.v$?.parkingSpace['longitude']?.$errors[0]?.$message
                "
                :is-error="v$?.parkingSpace['longitude']?.$error"
                :label="$t('ParkingSpaces.Longitude')"
                :required="true"
              />
            </div>
            <div class="pb-3 pt-10">
              <the-button
                :icon="['fas', 'earth-europe']"
                type="button"
                @execute-method="toggleMapLocationModal"
              />
            </div>
          </div>
          <checkbox-input
            v-model="parkingSpace.navigable"
            :label="$t('ParkingSpaces.Navigable')"
          />
          <checkbox-input
            v-model="parkingSpace.active"
            :label="$t('ParkingSpaces.Active')"
          />
          <checkbox-input
            v-model="parkingSpace.visible"
            :label="$t('ParkingSpaces.Visible')"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import TheButton from "@/core/Components/TheButton.vue";
import MapLocationSelector from "@/core/Components/MapLocationSelector.vue";
import { CreateParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    MapLocationSelector,
    TheButton,
    CheckboxInput,
    InputField,
    ButtonWrapper,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isCreateModalOpened: Boolean,
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      isMapLocationModalOpened: false,
      isSubmitLoading: false,
      toast: useToast(),
      v$: useValidate(),
      parkingSpaceService: new ParkingSpaceService(),
      parkingSpace: {
        name: "",
        street: "",
        city: "",
        streetNumber: 0,
        latitude: 0,
        longitude: 0,
        navigable: false,
        active: false,
        visible: false,
      } as CreateParkingSpace,
    };
  },
  validations() {
    return {
      parkingSpace: {
        name: {
          required,
        },
        city: {
          required,
        },
        street: {
          required,
        },
        streetNumber: {
          required,
        },
        latitude: {
          required,
        },
        longitude: {
          required,
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      await this.v$.$validate();
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          await this.parkingSpaceService.createParkingSpace(this.parkingSpace);
          this.toast.success("Parking space created successfully!");
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    toggleMapLocationModal() {
      this.isMapLocationModalOpened = !this.isMapLocationModalOpened;
    },
    getMarkerCoordinates(coordinates: { lat: number; lng: number }) {
      this.parkingSpace.latitude = coordinates.lat;
      this.parkingSpace.longitude = coordinates.lng;
    },
  },
});
</script>

<style scoped></style>