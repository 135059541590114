<template>
  <modal-component
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    :title="$t('DeviceMetadata.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="formData.name"
            :label="$t('DeviceMetadata.Name')"
            :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
            :isError="v$?.formData['name']?.$error"
            :required="true"
          />
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceMetadata.MetadataTypes") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="formData.metadataTypeId"
                :class="`mt-1 w-full`"
                :options="deviceMetadataTypes"
                :reduce="(metadataType : any) => metadataType.id"
                label="name"
                :clearable="false"
                :disabled="true"
              ></v-select>
            </div>
          </div>
          <div class="py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceMetadata.Data") }}</label
            >
            <json-editor-vue
              v-model="formData.data"
              :main-menu-bar="false"
              :status-bar="false"
              :class="`mt-1 ${v$?.formData['data']?.$error && 'error'}`"
              mode="text"
            />
            <span
              v-if="v$?.formData['data']?.$error"
              class="mt-2 text-sm text-error"
              >{{ v$?.formData["data"]?.$errors[0]?.$message }}
            </span>
          </div>
          <JsonSchemaDropdown :config-schema="configSchema" />
          <div class="mt-2 flex items-center gap-2 border-none">
            <the-button
              :text="'Validate'"
              @click="validateData"
              :type="'button'"
            />
            <div class="text-sm font-medium not-italic text-gray-800">
              {{ validationMessage }}
            </div>
          </div>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import VSelect from "vue-select";
//@ts-ignore
import JsonEditorVue from "json-editor-vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { DeviceMetadata } from "@/modules/Production/Submodules/DeviceMetadata/Services/DeviceMetadataTypes";
import {
  CheckObjectsForPatch,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import { DeviceMetadataService } from "@/modules/Production/Submodules/DeviceMetadata/Services/DeviceMetadataService";
import { useToast } from "vue-toastification";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";
import JsonSchemaDropdown from "@/core/Components/Dropdowns/JsonSchemaDropdown.vue";
import Ajv from "ajv";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: {
    ButtonWrapper,
    SmallLoader,
    InputField,
    JsonEditorVue,
    VSelect,
    FormDivider,
    FormWrapper,
    ModalComponent,
    JsonSchemaDropdown,
    TheButton,
  },
  props: {
    isEditModalOpened: Boolean,
    deviceMetadata: {
      type: Object as PropType<DeviceMetadata>,
      required: true,
    },
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  async created() {
    const newDeviceMetadata = Object.assign({}, this.deviceMetadata);
    this.formData.name = newDeviceMetadata.name;
    this.formData.metadataTypeId = newDeviceMetadata.metadataTypeId;
    this.formData.data = newDeviceMetadata.data;
    this.formData.id = newDeviceMetadata.id;
    this.isLoading = true;
    try {
      await this.getMetadataTypes();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      formData: {
        id: 0,
        name: "",
        metadataTypeId: null as number | null,
        data: {} as any,
      },
      deviceMetadataService: new DeviceMetadataService(),
      toast: useToast(),
      deviceMetadataTypes: [] as {
        id: number;
        name: string;
        configSchema: any | null;
      }[],
      isLoading: false,
      isSubmitLoading: false,
      v$: useValidate() as any,
      validationErrors: [] as any,
      configSchema: null,
      validationMessage: "Click to validate.",
    };
  },
  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        metadataTypeId: {
          required,
        },
        data: {
          required,
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      let jsonValidation = this.validateData();
      if (!this.v$.$error && jsonValidation) {
        this.isSubmitLoading = true;
        try {
          const edited = await CheckObjectsForPatch({
            initialObject: this.deviceMetadata,
            editedObject: this.formData,
          });
          //Remove metadataType from edited object, because it's forwarded from parent component
          //And you can't remove it from prop object because it's read only
          const filtered = edited.filter((x) => x.path !== "/metadataType");

          //json-editor-vue returns data as string, so we need to parse it
          filtered.find((x) => {
            if (x.path === "/data") {
              x.value = JSON.parse(x.value);
            }
          });

          if (!filtered.length) {
            throw new Error(this.$t("NoChangesWereMade"));
          }

          await this.deviceMetadataService.updateDeviceMetadata({
            id: this.formData.id,
            deviceMetadata: filtered,
          });

          this.toast.success(this.$t("General.EditedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    async getMetadataTypes() {
      try {
        this.deviceMetadataTypes =
          await this.deviceMetadataService.getDeviceMetadataTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    validateData() {
      const selectedMetadataType = this.deviceMetadataTypes.find(
        (type) => type.id === this.formData.metadataTypeId
      );

      const ajv = new Ajv();

      if (
        selectedMetadataType?.configSchema == null &&
        Object.keys(this.formData.data).length != 0
      ) {
        this.validationMessage =
          "Selected Metadata type has no Config schema. Any data is acceptable.";
        return true;
      } else if (
        selectedMetadataType != undefined &&
        Object.keys(this.formData.data).length != 0
      ) {
        const validate = ajv.compile(selectedMetadataType.configSchema);
        let data;

        if (typeof this.formData.data === "string") {
          data = JSON.parse(this.formData.data);
        } else {
          data = this.formData.data;
        }

        const valid = validate(data);

        if (valid) {
          this.validationMessage = "Validation successful!";
          return true;
        } else {
          if (validate.errors) {
            let message = validate.errors[0]?.message;
            this.validationMessage = message
              ? message
              : "Unsuccessful validation!";
          }
          return false;
        }
      } else if (Object.keys(this.formData.data).length == 0) {
        this.validationMessage = "No data to validate against config schema.";
        return false;
      }
    },
  },
});
</script>

<style scoped></style>
