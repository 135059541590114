import { Polygon } from "leaflet";
import { geojsonToWKT, wktToGeoJSON } from "@terraformer/wkt";
import { DeviceMetadata } from "@/modules/Metadata/Services/Device/DeviceMetadataService";
import { JSONPath } from "jsonpath-plus";

export default class CoordinateConversionService {
  convertToWkt(polygon: Polygon) {
    const geoJson = polygon.toGeoJSON();
    return geojsonToWKT(geoJson.geometry);
  }

  convertToGeoJson(wkt: string) {
    return wktToGeoJSON(wkt);
  }

  getLatLngFromMetadata({
    metadata,
    wsData,
  }: {
    metadata: DeviceMetadata;
    wsData: any;
  }) {
    if (!metadata?.data) {
      return;
    }

    if (!metadata.data.latitudePath || !metadata.data.longitudePath) {
      return;
    }

    const lat: number[] = JSONPath({
      path: metadata.data.latitudePath,
      json: wsData,
    });
    const lng: number[] = JSONPath({
      path: metadata.data.longitudePath,
      json: wsData,
    });

    let angle;
    if (
      metadata.data.anglePath &&
      (metadata.data.angleOffset || metadata.data.angleOffset == 0)
    ) {
      angle = JSONPath({
        path: metadata.data.anglePath,
        json: wsData,
      });

      if (angle.length > 0) {
        angle[0] = (angle[0] + metadata.data.angleOffset) * -1;
        //Clockwise logic - nothing for now
        /* if (!metadata.data.isAngleClockwise) angle[0] = -1 * angle[0]; */
      }
    }
    return { lat, lng, angle };
  }
}
