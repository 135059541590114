<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    :title="$t('DeviceTypes.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="formData.name"
            :label="$t('DeviceTypes.Name')"
            :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
            :isError="v$?.formData['name']?.$error"
            :required="true"
          />
          <input-field
            v-model="formData.productionPN"
            :label="$t('DeviceTypes.ProductionPN')"
          />
          <input-field
            v-model="formData.customerPN"
            :label="$t('DeviceTypes.CustomerPN')"
          />
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceTypes.SerialNumberType") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="formData.serialNumberTypeId"
                :class="`mt-1 w-full`"
                :options="serialNumberTypes"
                :reduce="(serialNumberType : any) => serialNumberType.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceTypes.ParentDeviceType") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="formData.parentDeviceTypeId"
                :class="`mt-1 w-full`"
                :options="deviceTypes"
                :reduce="(deviceType : any) => deviceType.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <checkbox-input
            v-model="formData.isProductionEnabled"
            :label="$t('DeviceTypes.ProductionEnabled')"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import DeviceTypeService, {
  CreateDeviceType,
  DeviceType,
  SerialNumberType,
} from "@/modules/DeviceTypes/Services/DeviceTypeService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    SmallLoader,
    VSelect,
    ButtonWrapper,
    CheckboxInput,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isCreateModalOpened: Boolean,
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      deviceTypeService: new DeviceTypeService(),
      deviceTypes: [] as DeviceType[],
      serialNumberTypes: [] as SerialNumberType[],
      toast: useToast(),
      isLoading: false,
      isSubmitLoading: false,
      formData: {
        name: "",
        productionPN: "",
        customerPN: "",
        isProductionEnabled: false,
        serialNumberTypeId: null as number | null,
        parentDeviceTypeId: null as number | null,
      } as CreateDeviceType,
      v$: useValidate() as any,
      validationErrors: [] as any,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await Promise.all([this.getDeviceTypes(), this.getSerialNumberTypes()]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          await this.deviceTypeService.createDeviceType(this.formData);
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    async getDeviceTypes() {
      try {
        this.deviceTypes = await this.deviceTypeService.getDeviceTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getSerialNumberTypes() {
      try {
        this.serialNumberTypes =
          await this.deviceTypeService.getSerialNumberTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
