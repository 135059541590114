<template>
  <modal
    :have-modal="haveModal"
    :is-displayed="isLastDataModalOpened"
    :is-modal-without-top-offset="true"
    :last-data-modal="true"
    :need-for-scroll="true"
    :title="
      metadata
        ? `Applied metadata for ${name}`
        : preview
        ? `Data preview for ${name}`
        : `Last data sent by ${name}`
    "
    additional-class="absolute top-10"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="px-8">
      <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
        <vue-json-pretty
          :data="data ? data : lastData"
          :path="'res'"
        ></vue-json-pretty>
      </form-wrapper>
    </div>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Modal from "@/core/Components/Modal.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";
import DeviceDataService from "@/modules/Devices/Services/DeviceDataService";

export default defineComponent({
  components: { Modal, VueJsonPretty, FormWrapper },
  props: [
    "dataUuid",
    "name",
    "isLastDataModalOpened",
    "data",
    "haveModal",
    "preview",
    "metadata",
    "getLastData",
  ],
  emits: ["toggle-modal"],
  data() {
    return {
      isLoading: false,
      lastData: null as any,
      deviceDataService: new DeviceDataService(),
      toast: useToast(),
    };
  },

  async created() {
    if (this.getLastData) {
      await this.getLastDataSentByDevice();
    }
  },

  methods: {
    async getLastDataSentByDevice() {
      this.isLoading = true;
      try {
        this.lastData =
          await this.deviceDataService.getDeviceAssignmentLastData({
            dataUuids: [this.dataUuid],
            pageNumber: 1,
            pageSize: 100000,
          });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
<style>
.vjs-value__string {
  @apply text-primary;
}

.vjs-tree__node:hover {
  @apply bg-primarylight;
}
</style>
