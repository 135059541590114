<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    title="Create source"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form @submit.prevent="handleSubmit">
      <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
        <form-divider>
          <select-input
            v-model="source.spaceId"
            :options="parkingSpaces"
            label="name"
            label-text="Parking space"
            reduce-key="id"
          />
          <select-input
            v-model="source.spotTypeId"
            :options="parkingSpotTypes"
            label="name"
            label-text="Spot type"
            reduce-key="id"
          />
          <select-input
            v-model="source.spotId"
            :options="filteredParkingSpots"
            label="name"
            label-text="Parking spot"
            reduce-key="id"
          />
          <checkbox-input
            v-model="source.showFree"
            :is-checked="source.showFree"
            label="Show free"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form-wrapper>
    </form>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import SelectInput from "@/core/Components/FormElements/SelectInput.vue";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import {
  ParkingSpot,
  ParkingSpotType,
} from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import { CreateSource } from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";

export default defineComponent({
  components: {
    CheckboxInput,
    SelectInput,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
    ButtonWrapper,
  },
  props: {
    isCreateModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
    nodeId: {
      type: Number,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  async created() {
    this.isLoading = true;
    try {
      await Promise.allSettled([
        this.getParkingSpotTypes(),
        this.getParkingSpots(),
        this.getParkingSpaces(),
      ]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      parkingSpotService: new ParkingSpotService(),
      parkingSpaceService: new ParkingSpaceService(),
      displayService: new DisplayService(),
      parkingSpotTypes: [] as ParkingSpotType[],
      parkingSpots: [] as ParkingSpot[],
      filteredParkingSpots: [] as ParkingSpot[],
      parkingSpaces: [] as ParkingSpace[],
      toast: useToast(),
      isLoading: false,
      isSubmitLoading: false,
      source: {
        showFree: true,
        displayNodeId: this.nodeId,
        spotTypeId: null,
        spotId: null,
        spaceId: null,
      } as CreateSource,
    };
  },

  watch: {
    "source.spaceId"(spaceId: number) {
      this.filteredParkingSpots = this.parkingSpots.filter(
        (spot) => spot.spaceId === spaceId
      );

      console.log(this.parkingSpots);
    },
  },
  methods: {
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        await this.displayService.createSource(this.source);
        this.toast.success("Source created successfully!");
        this.$emit("toggle-modal");
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getParkingSpotTypes() {
      try {
        this.parkingSpotTypes =
          await this.parkingSpotService.getParkingSpotTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getParkingSpots() {
      try {
        this.parkingSpots = await this.parkingSpotService.getParkingSpots({
          pageNumber: 1,
          pageSize: 100000,
        });
        console.log(this.parkingSpots);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getParkingSpaces() {
      try {
        this.parkingSpaces = await this.parkingSpaceService.getParkingSpaces({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
