import { RouteRecordRaw } from "vue-router";
import Index from "@/modules/Applications/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createApplicationIndexRoute(): RouteRecordRaw {
  return {
    name: "applications",
    path: "/groups",
    component: Index,
    meta: {
      id: "applicationsIndex",
      label: "Sidebar.Groups",
      icon: ["fas", "cog"],
      showInNavigation: true,
      group: "main",
      authorize: [Permissions.ViewGroup],
    },
  };
}
