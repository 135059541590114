import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isCreateModalOpened,
    "no-padding": true,
    title: _ctx.$t('Sensors.CreateTitle'),
    onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
          default: _withCtx(() => [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createVNode(_component_input_field, {
                  modelValue: _ctx.sensor.mac,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sensor.mac) = $event)),
                  "error-message": this.v$?.sensor['mac']?.$errors[0]?.$message,
                  "is-error": _ctx.v$?.sensor['mac']?.$error,
                  label: _ctx.$t('Sensors.MAC'),
                  required: true
                }, null, 8, ["modelValue", "error-message", "is-error", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
          ]),
          _: 1
        })
      ], 32)
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}