<template>
  <modal-component
    :have-modal="haveModal"
    :is-displayed="isLiveDataModalOpened"
    :is-full-screen-modal="true"
    :need-for-scroll="true"
    :title="`Live data for ${device.assignment.name}`"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="space-y-2 px-6">
      <div
        v-for="item in webSocketData"
        class="count-me flex w-full items-center justify-between rounded-sm bg-lightgray px-4 py-1 text-sm"
      >
        <p>{{ formatDateTime(item.receivedAt) }}</p>
        <p>{{ item }}</p>
      </div>
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType, inject } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import WebSocketService from "@/core/WebSocket/WebSocketService";
import { WebSocketData } from "@/modules/LeafletMap/Services/LeafletMapService";
import {
  ErrorMessageFormatter,
  FormatDateTime,
} from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: { ModalComponent },
  props: {
    device: {
      type: Object as PropType<DeviceAssignment>,
      required: true,
    },
    isLiveDataModalOpened: Boolean,
    haveModal: Boolean,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      config: inject("config") as any,
      toast: useToast(),
      webSocketService: {} as WebSocketService,
      webSocketData: [] as WebSocketData[],
    };
  },
  created() {
    if (!this.device) {
      return;
    }
    this.webSocketService = new WebSocketService(
      `${this.config.WEB_SOCKET_PREFIX_PATH}/device`
    );
  },
  watch: {
    "webSocketData.length"(length: number) {
      if (length > 250) {
        this.webSocketData = this.webSocketData.slice(0, -1);
      }
    },
  },
  mounted() {
    try {
      if (!this.device.device) {
        throw new Error("Device is not defined!");
      }
      setTimeout(() => {
        this.webSocketService.subscribeToDevice(
          [{ deviceUuid: this.device!.device!.deviceUuid }],
          true,
          (e: MessageEvent) => this.getDataFromWebSocket(e)
        );
      }, 500);
      this.toast.success("Subscribed to device!");
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },
  unmounted() {
    this.webSocketService.closeWebSocketConnection();
  },

  methods: {
    formatDateTime: FormatDateTime,
    getDataFromWebSocket(e: MessageEvent) {
      const date = new Date();
      const data = JSON.parse(e.data);
      data.receivedAt = date.toISOString();
      this.webSocketData.unshift(data);
    },
  },
});
</script>

<style scoped></style>
