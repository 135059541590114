<template>
  <form @submit.prevent="handleSubmit">
    <form-divider>
      <input-field v-model="formData.name" label="Name" />
      <input-field
        v-model="formData.config.data.xName"
        label="X name"
        @keyup="trimXName"
      />
      <input-field
        v-model="formData.config.data.yName"
        label="Y name"
        @keyup="trimYName"
      />
      <input-field
        v-model="formData.config.data.angleName"
        label="Angle name"
      />
      <form-group title="Coordinate paths">
        <ul v-auto-animate>
          <li
            v-for="(path, index) in formData.config.data.paths"
            :style="{ gridTemplateColumns: '1fr min-content' }"
            class="grid grid-cols-2 gap-x-4"
          >
            <input-field
              id="index"
              v-model="formData.config.data.paths[index]"
              label="Path"
              @keyup="trimCoordinatePath(index)"
            />
            <add-delete-buttons
              :auth-resources="formData.config.data.paths"
              @add-click="addPath"
              @remove-click="removePath(index)"
            />
          </li>
        </ul>
      </form-group>
      <form-group title="Tag ID paths">
        <ul v-auto-animate>
          <li
            v-for="(idPath, index) in formData.config.data.tagIdPaths"
            :style="{ gridTemplateColumns: '1fr min-content' }"
            class="grid grid-cols-2 gap-x-4"
          >
            <input-field
              v-model="formData.config.data.tagIdPaths[index]"
              label="Path"
              @keyup="trimTagIdPath(index)"
            />
            <add-delete-buttons
              :auth-resources="formData.config.data.tagIdPaths"
              @add-click="addTagIdPath"
              @remove-click="removeTagIdPath(index)"
            />
          </li>
        </ul>
      </form-group>
      <multi-json-input
        @input="handleTopicChange"
        :topics="formData.config.topicToChannel"
      />
      <tags-input
        :label="$t('Integrations.Channels')"
        :tags="formData.config.channels"
        @on-tag-mutate="(tags: any) => (formData.config.channels = tags)"
      />
      <input-field
        v-model="formData.executionOrder"
        :label="$t('Integrations.ExecutionOrder')"
        type="number"
      />
      <checkbox-input
        v-model="formData.isActive"
        :is-checked="formData.isActive"
        label="Active"
      />
    </form-divider>
    <button-wrapper :is-submit-loading="isSubmitLoading" />
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import FormGroup from "@/core/Components/FormElements/FormGroup.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import AddDeleteButtons from "@/modules/AccessManager/SubModules/ProfileConfiguration/Components/AddDeleteButtons.vue";
//@ts-ignore
import checkIfObjectEmpty, {
  CheckObjectsForPatch,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import IntegrationService from "@/modules/Integrations/Services/IntegrationService";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import { useToast } from "vue-toastification";
import MultiJsonInput from "@/core/Components/FormElements/MultiJsonInput.vue";
import TagsInput from "@/core/Components/FormElements/TagsInput.vue";
import {
  maxLength,
  required,
  requiredIf,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    CheckboxInput,
    ButtonWrapper,
    AddDeleteButtons,
    InputField,
    FormGroup,
    FormDivider,
    MultiJsonInput,
    TagsInput,
  },

  props: {
    applicationId: {
      type: Number,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
    data: {
      type: Object as PropType<any>,
      required: true,
    },
  },

  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      toast: useToast(),
      isSubmitLoading: false,
      integrationService: new IntegrationService(),
      integrationFromAPI: {},
      formData: {
        id: this.data.id,
        name: this.data.name,
        integrationTypeId: this.integrationTypeId,
        applicationId: this.applicationId,
        isActive: this.data.isActive,
        executionOrder: this.data.executionOrder,
        forwardOnlyPayload: this.data.forwardOnlyPayload,
        config: {
          topicToChannel: this.data?.config?.topicToChannel,
          channels: this.data?.config?.channels,
          usePayloadFromResponse: this.data?.config?.usePayloadFromResponse,
          daprAppId: "platform-translation-api",
          url: "/trigger",
          data: {
            paths: this.data?.config?.data.paths,
            tagIdPaths: this.data?.config?.data.tagIdPaths,
            xName: this.data?.config?.data.xName,
            yName: this.data?.config?.data.yName,
            angleName: this.data?.config?.data.angleName,
          },
        },
      },
    };
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        executionOrder: {
          minValue: minValue(1),
        },
      },
    };
  },

  created() {
    this.formData = this.data;
    this.integrationFromAPI = Object.assign({}, this.formData);
  },

  methods: {
    trimXName() {
      this.formData.config.data.xName = this.formData.config.data.xName.replace(
        /\s/g,
        ""
      );
    },
    trimYName() {
      this.formData.config.data.yName = this.formData.config.data.yName.replace(
        /\s/g,
        ""
      );
    },
    trimAngleName() {
      this.formData.config.data.angleName =
        this.formData.config.data.angleName.replace(/\s/g, "");
    },
    trimCoordinatePath(index: number) {
      this.formData.config.data.paths[index] = this.formData.config.data.paths[
        index
      ].replace(/\s/g, "");
    },
    trimTagIdPath(index: number) {
      this.formData.config.data.tagIdPaths[index] =
        this.formData.config.data.tagIdPaths[index].replace(/\s/g, "");
    },
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        //Remove reference to form data
        //Reference is removed because format for api si different from data for UI
        const dataForSubmit = JSON.parse(JSON.stringify(this.formData));

        dataForSubmit.config.data.paths = this.formData.config.data.paths.map(
          (path: any) => path.path
        );

        dataForSubmit.config.data.tagIdPaths =
          this.formData.config.data.tagIdPaths.map((path: any) => path.idPath);

        if (this.formData.config.channels) {
          this.formData.config.channels = this.formData.config.channels.map(
            (channel: string) => {
              if (typeof channel === "string") {
                return Number(channel.trim());
              }

              return channel;
            }
          );
        }

        if (
          this.formData.config.channels &&
          this.formData.config.channels.length === 0
        ) {
          this.formData.config.channels = null;
        }

        if (checkIfObjectEmpty(this.formData.config.topicToChannel)) {
          this.formData.config.topicToChannel = null;
        }

        if (this.formData.config.data.angleName == "") {
          this.formData.config.data.angleName = null;
        }

        const integrationPatch = await CheckObjectsForPatch({
          initialObject: this.integrationFromAPI,
          editedObject: this.formData,
        });

        await this.integrationService.editIntegration({
          integrationPatch,
          id: this.data.id,
        });

        this.toast.success(this.$t("General.EditedSuccessfully"));
        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    addPath() {
      this.formData.config.data.paths.push("$.msg");
    },
    addTagIdPath() {
      this.formData.config.data.tagIdPaths.push("$.msg.tag_id");
    },
    removePath(index: number) {
      this.formData.config.data.paths.splice(index, 1);
    },
    removeTagIdPath(index: number) {
      this.formData.config.data.tagIdPaths.splice(index, 1);
    },
    handleTopicChange(value: Record<string, number>) {
      this.formData.config.topicToChannel = value;
    },
  },
});
</script>

<style scoped></style>
