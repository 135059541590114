<template>
  <form @submit.prevent="handleSubmit">
    <form-divider>
      <input-field v-model="formData.name" label="Name" />
      <input-field
        v-model="formData.config.data.xName"
        label="X name"
        @keyup="trimXName"
      />
      <input-field
        v-model="formData.config.data.yName"
        label="Y name"
        @keyup="trimYName"
      />
      <input-field
        v-model="formData.config.data.angleName"
        label="Angle name"
        @keyup="trimAngleName"
      />
      <form-group title="Coordinate paths">
        <ul v-auto-animate>
          <li
            v-for="(path, index) in formData.config.data.paths"
            :style="{ gridTemplateColumns: '1fr min-content' }"
            class="grid grid-cols-2 gap-x-4"
          >
            <input-field
              v-model="path.path"
              label="Path"
              @keyup="trimCoordinatePath(index)"
            />
            <add-delete-buttons
              :auth-resources="formData.config.data.paths"
              @add-click="addPath"
              @remove-click="removePath(index)"
            />
          </li>
        </ul>
      </form-group>
      <form-group title="Tag ID paths">
        <ul v-auto-animate>
          <li
            v-for="(idPath, index) in formData.config.data.tagIdPaths"
            :style="{ gridTemplateColumns: '1fr min-content' }"
            class="grid grid-cols-2 gap-x-4"
          >
            <input-field
              v-model="idPath.idPath"
              label="Path"
              @keyup="trimTagIdPath(index)"
            />
            <add-delete-buttons
              :auth-resources="formData.config.data.tagIdPaths"
              @add-click="addTagIdPath"
              @remove-click="removeTagIdPath(index)"
            />
          </li>
        </ul>
      </form-group>
      <multi-json-input @input="handleTopicChange" />
      <tags-input
        :label="$t('Integrations.Channels')"
        :tags="formData.config.channels"
        @on-tag-mutate="(tags:any) => (formData.config.channels = tags)"
      />
      <input-field
        v-model="formData.executionOrder"
        :label="$t('Integrations.ExecutionOrder')"
        type="number"
        :errorMessage="v$?.formData['executionOrder']?.$errors[0]?.$message"
        :isError="v$?.formData['executionOrder']?.$error"
      />
      <checkbox-input
        v-model="formData.isActive"
        :is-checked="formData.isActive"
        label="Active"
      />
    </form-divider>
    <button-wrapper :is-submit-loading="isSubmitLoading" />
  </form>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import FormGroup from "@/core/Components/FormElements/FormGroup.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import AddDeleteButtons from "@/modules/AccessManager/SubModules/ProfileConfiguration/Components/AddDeleteButtons.vue";
//@ts-ignore
import checkIfObjectEmpty, {
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import IntegrationService from "@/modules/Integrations/Services/IntegrationService";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import { useToast } from "vue-toastification";
import {
  maxLength,
  required,
  requiredIf,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";
import MultiJsonInput from "@/core/Components/FormElements/MultiJsonInput.vue";
import TagsInput from "@/core/Components/FormElements/TagsInput.vue";

export default defineComponent({
  components: {
    CheckboxInput,
    ButtonWrapper,
    AddDeleteButtons,
    InputField,
    FormGroup,
    FormDivider,
    MultiJsonInput,
    TagsInput,
  },

  props: {
    applicationId: {
      type: Number,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      toast: useToast(),
      isSubmitLoading: false,
      integrationService: new IntegrationService(),
      formData: {
        name: "",
        integrationTypeId: 7,
        executionOrder: null as any,
        applicationId: this.applicationId,
        isActive: true,
        forwardOnlyPayload: false,
        config: {
          topicToChannel: null,
          channels: [] as any,
          usePayloadFromResponse: false,
          daprAppId: "platform-translation-api",
          url: "/trigger",
          data: {
            paths: [
              {
                path: "$.msg",
              },
            ] as any[],
            tagIdPaths: [
              {
                idPath: "$.msg.tag_id",
              },
            ] as any[],
            xName: "x",
            yName: "y",
            angleName: "" as string | null,
          },
        },
      },
    };
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        executionOrder: {
          minValue: minValue(1),
        },
      },
    };
  },

  methods: {
    trimXName() {
      this.formData.config.data.xName = this.formData.config.data.xName.replace(
        /\s/g,
        ""
      );
    },
    trimYName() {
      this.formData.config.data.yName = this.formData.config.data.yName.replace(
        /\s/g,
        ""
      );
    },
    trimAngleName() {
      this.formData.config.data.angleName =
        this.formData.config.data.angleName!.replace(/\s/g, "");
    },
    trimCoordinatePath(index: number) {
      this.formData.config.data.paths[index].path =
        this.formData.config.data.paths[index].path.replace(/\s/g, "");
    },
    trimTagIdPath(index: number) {
      this.formData.config.data.tagIdPaths[index].idPath =
        this.formData.config.data.tagIdPaths[index].idPath.replace(/\s/g, "");
    },
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        //Remove reference to form data
        //Reference is removed because format for api si different from data for UI
        this.formData.config.channels = this.formData.config.channels.map(
          (channel: string) => Number(channel.trim())
        );

        if (this.formData.config.channels.length === 0) {
          this.formData.config.channels = null;
        }

        if (
          this.formData.config.topicToChannel != null &&
          checkIfObjectEmpty(this.formData.config.topicToChannel)
        ) {
          this.formData.config.topicToChannel = null;
        }

        if (
          this.formData.config.data.angleName == "" ) {
          this.formData.config.data.angleName = null;
        }

        const dataForSubmit = JSON.parse(JSON.stringify(this.formData));

        dataForSubmit.config.data.paths = this.formData.config.data.paths.map(
          (path) => path.path
        );

        dataForSubmit.config.data.tagIdPaths =
          this.formData.config.data.tagIdPaths.map((path) => path.idPath);

        await this.integrationService.createIntegration(dataForSubmit);
        this.toast.success(this.$t("General.CreatedSuccessfully"));
        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    addPath() {
      this.formData.config.data.paths.push({ path: "" });
    },
    addTagIdPath() {
      this.formData.config.data.tagIdPaths.push({ idPath: "" });
    },
    removePath(index: number) {
      this.formData.config.data.paths.splice(index, 1);
    },
    removeTagIdPath(index: number) {
      this.formData.config.data.tagIdPaths.splice(index, 1);
    },
    handleTopicChange(value: Record<string, number>) {
      //@ts-ignore
      this.formData.config.topicToChannel = value;
    },
  },
});
</script>

<style scoped></style>
