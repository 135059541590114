import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-md" }
const _hoisted_2 = { class: "flex items-center space-x-4" }
const _hoisted_3 = {
  key: 0,
  class: "text-center"
}
const _hoisted_4 = {
  key: 1,
  class: "text-center"
}
const _hoisted_5 = {
  key: 2,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parkingSpotCount, (count) => {
    return (_openBlock(), _createElementBlock("div", {
      key: count.name,
      class: _normalizeClass(`flex  w-full items-center justify-between space-y-1 border border-gray-300 p-4 shadow-sm`)
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", {
          class: _normalizeClass(`text-3xl font-bold ${count.color}`)
        }, _toDisplayString(count.count), 3),
        _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t(count.name)), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.checkIfSpaceContainsType(count, 1))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'car'],
                class: "text-gray-400"
              }),
              _createElementVNode("p", {
                class: _normalizeClass(count.color)
              }, _toDisplayString(count.occupationByType[0]?.count ?? 0), 3)
            ]))
          : _createCommentVNode("", true),
        (_ctx.checkIfSpaceContainsType(count, 2))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'wheelchair'],
                class: "text-gray-400"
              }),
              _createElementVNode("p", {
                class: _normalizeClass(count.color)
              }, _toDisplayString(count.occupationByType[1]?.count ?? 0), 3)
            ]))
          : _createCommentVNode("", true),
        (_ctx.checkIfSpaceContainsType(count, 3))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'charging-station'],
                class: "text-gray-400"
              }),
              _createElementVNode("p", {
                class: _normalizeClass(count.color)
              }, _toDisplayString(count.occupationByType[2]?.count ?? 0), 3)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(`${count.bgColor} flex h-14 w-14 items-center justify-center rounded-full`)
      }, [
        _createVNode(_component_font_awesome_icon, {
          class: _normalizeClass(`${count.color} text-xl`),
          icon: count.icon
        }, null, 8, ["class", "icon"])
      ], 2)
    ]))
  }), 128))
}