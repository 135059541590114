<template>
  <transition name="modal">
    <div
      v-if="isDisplayed"
      :class="`fixed inset-0 ${haveModal ? 'z-[251]' : 'z-[250]'} !z-[1300]`"
      class="bg-[rgba(0,0,0,0.60)]"
    >
      <div
        class="pointer-events-none fixed bottom-0 top-0 z-[201] flex max-h-full w-full items-start justify-center px-[5vw] py-[5vh]"
      >
        <div
          ref="target"
          :class="`modal pointer-events-auto relative block max-h-[calc(95vh)] w-[95%] max-w-3xl sm:w-[75%] ${
            isFullScreenModal ? 'w-[95%] max-w-[1600px] sm:w-[95%]' : ''
          } overflow-y-auto bg-white`"
        >
          <on-click-outside
            @trigger="!removeOutsideClick && $emit('toggle-modal')"
          >
            <div class="max-h-[calc(95vh)] flex-[0_0_27.5rem]">
              <div class="flex flex-[1_1_0%] flex-col overflow-hidden">
                <div
                  class="sticky top-0 z-[150] flex items-center justify-between bg-lightgray px-6 py-6"
                >
                  <div class="flex w-full items-center justify-between">
                    <h2 class="max-w-[100ch] truncate text-xl font-bold">
                      {{ title }}
                    </h2>
                    <p v-if="dateTime" class="text-sm">
                      {{ formatDate(dateTime) }}
                    </p>
                  </div>
                  <x-icon
                    class="h-4 w-4 cursor-pointer hover:text-error"
                    @click="onClick()"
                  />
                </div>
                <div
                  :class="`modal-overflow max-h-[calc(95vh_-_6rem)] overflow-y-auto ${
                    noPadding ? 'py-0' : 'py-6'
                  }`"
                >
                  <slot></slot>
                </div>
              </div>
            </div>
          </on-click-outside>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts">
import { defineComponent } from "vue";
//@ts-ignore
import { XIcon } from "@heroicons/vue/solid";
/* import { Motion, Presence } from "motion/vue"; */
import { FormatDateTime } from "../Services/GlobalFunctions";
import { OnClickOutside } from "@vueuse/components";

export default defineComponent({
  name: "ModalComponent",

  components: {
    XIcon,
    /* Motion,
    Presence, */
    OnClickOutside,
  },
  props: {
    title: String,
    isSelectModal: Boolean,
    noPadding: Boolean,
    hideOverflow: Boolean,
    needForScroll: Boolean,
    isDisplayed: Boolean,
    lastDataModal: Boolean,
    dateTime: Boolean,
    margin: String,
    isConfirmationModal: Boolean,
    customModalSize: String,
    removeOutsideClick: Boolean,
    isModalWithoutTopOffset: Boolean,
    additionalClass: String,
    isFullScreenModal: Boolean,
    haveModal: Boolean,
  },
  emits: ["toggle-modal"],
  created() {
    window.addEventListener("keyup", (e) => this.closeWithEscape(e));
  },

  unmounted() {
    window.removeEventListener("keyup", (e) => this.closeWithEscape(e));
  },

  methods: {
    formatDate: FormatDateTime,

    onClick() {
      this.$emit("toggle-modal");
    },

    closeWithEscape(e: KeyboardEvent) {
      if (e.key === "Escape" && this.isDisplayed) {
        this.onClick();
      }
    },
  },
});
</script>
<style>
.modal-header {
  backdrop-filter: blur(10px);
}

.modal-overflow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 10px;
}

.modal-overflow::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.modal-overflow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}

.modal-overflow {
  scrollbar-width: thin;
}

.modal {
  box-shadow: 0 20px 60px -2px rgb(27 33 58 / 35%);
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  scale: 1.1;
}
</style>
