import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_last_data_preview = _resolveComponent("last-data-preview")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDataPreviewModalOpened)
      ? (_openBlock(), _createBlock(_component_last_data_preview, {
          key: 0,
          data: _ctx.dataForModal.data,
          "is-last-data-modal-opened": _ctx.isDataPreviewModalOpened,
          name: _ctx.selectedMetadata[0]?.name,
          preview: true,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDataPreviewModalOpened = false))
        }, null, 8, ["data", "is-last-data-modal-opened", "name"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 1,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "refresh-data": _ctx.getDeviceMetadata,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isCreateModalOpened = false))
        }, null, 8, ["is-create-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 2,
          "device-metadata": _ctx.selectedMetadata[0],
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "refresh-data": _ctx.getDeviceMetadata,
          onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEditModalOpened = false))
        }, null, 8, ["device-metadata", "is-edit-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isDeleteModalOpened)
      ? (_openBlock(), _createBlock(_component_delete_modal, {
          key: 3,
          "is-deleting": _ctx.isDeleteModalOpened,
          onCancelDelete: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
          onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
          onConfirmDelete: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openConfirmationModal('delete')))
        }, null, 8, ["is-deleting"]))
      : _createCommentVNode("", true),
    _createVNode(_component_confirmation_modal, {
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "delete-text": "delete",
      onConfirmAction: _ctx.deleteDeviceMetadata,
      onCloseConfirmationModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
    }, null, 8, ["is-confirmation-modal-opened", "onConfirmAction"]),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateDevice,
                title: _ctx.$t('DeviceMetadata.Title'),
                description: " ",
                "button-text": "Create",
                onExecuteMethod: _ctx.toggleCreateModal
              }, null, 8, ["permission-id", "title", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                options: _ctx.options,
                "table-data": _ctx.deviceMetadataPagination.items,
                "table-headers": _ctx.tableHeaders,
                onForceRefresh: _ctx.getDeviceMetadata,
                onOpenDataModal: _ctx.displayData,
                onSelectedRows: _ctx.getSelectedRow
              }, null, 8, ["options", "table-data", "table-headers", "onForceRefresh", "onOpenDataModal", "onSelectedRows"]),
              _createVNode(_component_pagination, {
                "pagination-data": _ctx.deviceMetadataPagination,
                onPageChange: _ctx.handlePageChange,
                onPageSizeChange: _ctx.handlePageSizeChange
              }, null, 8, ["pagination-data", "onPageChange", "onPageSizeChange"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}