import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative items-center py-4" }
const _hoisted_2 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_3 = { class: "flex items-center justify-center" }
const _hoisted_4 = { class: "py-3" }
const _hoisted_5 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_6 = {
  key: 0,
  class: "mt-2 text-sm text-error"
}
const _hoisted_7 = { class: "mt-2 flex items-center gap-2 border-none" }
const _hoisted_8 = { class: "text-sm font-medium not-italic text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_json_editor_vue = _resolveComponent("json-editor-vue")!
  const _component_JsonSchemaDropdown = _resolveComponent("JsonSchemaDropdown")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isEditModalOpened,
    "no-padding": true,
    title: _ctx.$t('AssignmentMetadata.EditTitle'),
    onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createVNode(_component_input_field, {
                  modelValue: _ctx.formData.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
                  label: _ctx.$t('DeviceTypeMetadata.Name'),
                  errorMessage: _ctx.v$?.formData['name']?.$errors[0]?.$message,
                  isError: _ctx.v$?.formData['name']?.$error,
                  required: true
                }, null, 8, ["modelValue", "label", "errorMessage", "isError"]),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("DeviceTypeMetadata.MetadataTypes")), 1),
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.isLoading)
                      ? (_openBlock(), _createBlock(_component_small_loader, {
                          key: 0,
                          height: "h-8",
                          width: "w-8"
                        }))
                      : (_openBlock(), _createBlock(_component_v_select, {
                          key: 1,
                          modelValue: _ctx.formData.metadataTypeId,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.metadataTypeId) = $event)),
                          class: _normalizeClass(`mt-1 w-full`),
                          options: _ctx.assignmentMetadataTypes,
                          reduce: (metadataType) => metadataType.id,
                          label: "name",
                          clearable: false,
                          disabled: true
                        }, null, 8, ["modelValue", "options", "reduce"]))
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("DeviceTypeMetadata.Data")), 1),
                  _createVNode(_component_json_editor_vue, {
                    modelValue: _ctx.formData.data,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.data) = $event)),
                    "main-menu-bar": false,
                    "status-bar": false,
                    class: _normalizeClass(`mt-1 ${_ctx.v$?.formData['data']?.$error && 'error'}`),
                    mode: "text"
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.v$?.formData['data']?.$error)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.v$?.formData["data"]?.$errors[0]?.$message), 1))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_JsonSchemaDropdown, { "config-schema": _ctx.configSchema }, null, 8, ["config-schema"]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_the_button, {
                    text: 'Validate',
                    onClick: _ctx.validateData,
                    type: 'button'
                  }, null, 8, ["onClick"]),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.validationMessage), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}