<template>
  <div
    class="absolute z-[250] flex h-screen w-screen items-center justify-center justify-items-center align-middle"
    v-if="isLoading"
  >
    <div class="h-screen w-screen bg-slate-500 opacity-40"></div>
    <Loader class="opacity-100" />
  </div>
  <div
    :class="`absolute ${
      sidebarStore.isSidebarOpened ? 'left-[258px]' : 'left-[50px]'
    }  top-1/2 z-[250] transform duration-300 ease-in-out`"
  >
    <font-awesome-icon
      :icon="['fas', 'grip-lines-vertical']"
      class="cursor-col-resize text-base text-darkgrey"
      @click="sidebarStore.toggleSidebar"
    />
  </div>
  <parking-space-selector-modal
    v-if="isParkingSpaceSelectorModalOpened"
    :is-selector-modal-opened="isParkingSpaceSelectorModalOpened"
    @toggle-modal="isParkingSpaceSelectorModalOpened = false"
  />
  <div
    :class="`sidebar transform duration-300 ease-in-out  ${
      sidebarStore.isSidebarOpened ? 'w-64' : 'w-12'
    }  bottom-0 left-0 flex flex-col bg-sidebarcolor px-2 py-4 ${
      config.IS_PARKOM == 'true' ? 'pb-16' : 'pb-4'
    } `"
  >
    <div :class="`text-white`">
      <div
        :class="`flex pb-1 ${
          sidebarStore.isSidebarOpened ? 'justify-end' : 'justify-center'
        }`"
      >
        <font-awesome-icon
          :icon="
            sidebarStore.isSidebarOpened
              ? ['fas', 'angles-left']
              : ['fas', 'angles-right']
          "
          class="cursor-pointer text-base text-white"
          @click="sidebarStore.toggleSidebar"
        />
      </div>

      <div v-if="sidebarStore.isSidebarOpened" class="company">
        <paginated-company-dropdown
          @update-active-company="updateActiveCompany"
        />
      </div>
    </div>
    <div class="sidebar-scroll overflow-y-auto">
      <div
        :class="`flex flex-col gap-4 ${routes.length >= 2 ? 'divide-y-2' : ''}`"
      >
        <div v-for="group in routes" class="last:pt-4">
          <div v-for="route in group" :key="route.name">
            <tree-list
              v-if="
                !route.meta?.authorize ||
                permissionStore.hasPermissionView(route.meta?.authorize)
              "
              :route="route"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="config.IS_PARKOM == 'true'"
      :class="`absolute bottom-3 flex items-center ${
        sidebarStore.isSidebarOpened
          ? 'left-52 justify-end'
          : 'left-0 justify-center'
      }`"
    >
      <font-awesome-icon
        v-tippy="$t('SidebarMenu.SelectParkingSpaces')"
        :icon="['fas', 'folder-tree']"
        class="cursor-pointer p-3 text-lg text-white hover:bg-darker"
        @click="() => toggleAction('treeView')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { getAllRoutes } from "../Router";
import { RouteRecordRaw } from "vue-router";
import { defineComponent, inject } from "vue";
import VSelect from "vue-select";
import UserService from "@/modules/Users/Services/UserService";
import { useUserStore } from "../Store/userStore";
import { useFloorStore } from "@/core/Store/floorStore";
import TreeList from "./TreeList.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import { usePermissionStore } from "@/core/Store/permissionStore";
import {
  AvatarInitialsGenerator,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import ParkingSpaceSelectorModal from "@/core/Components/ParkingSpaceSelectorModal.vue";
import { useParkingSpaceFilterStore } from "@/core/Store/useParkingSpaceFilterStore";
import { directive } from "vue-tippy";
import { useModuleToggleStore } from "@/core/Store/moduleToggleStore";
import Loader from "@/core/Components/Loader.vue";
import { useToast } from "vue-toastification";
import PaginatedCompanyDropdown from "./Dropdowns/PaginatedCompanyDropdown.vue";

export default defineComponent({
  name: "SidebarMenu",
  components: {
    ParkingSpaceSelectorModal,
    VSelect,
    TreeList,
    Loader,
    PaginatedCompanyDropdown,
  },
  props: ["sidebarStore"],
  directives: {
    tippy: directive,
  },
  data() {
    return {
      name: inject("user") as any,
      config: inject("config") as any,
      userStore: useUserStore(),
      floorStore: useFloorStore(),
      routes: [] as RouteRecordRaw[][],
      userService: new UserService(),
      changeCompanyStore: useChangeCompanyStore(),
      permissionStore: usePermissionStore(),
      parkingSpaceFilterStore: useParkingSpaceFilterStore(),
      moduleToggleStore: useModuleToggleStore(),
      isParkingSpaceSelectorModalOpened: false,
      unfilteredRoutes: [] as any[],
      filterWords: ["integrations", "applications", "responseMessages"],
      isLoading: false,
      toast: useToast(),
    };
  },
  watch: {
    async "moduleToggleStore.isDevelopment"(value) {
      this.isLoading = true;
      try {
        if (!value) {
          this.createSidebarRoutes(this.filterWords);
        } else {
          this.createSidebarRoutes();
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 300);
      }
    },
  },
  async created() {
    //await this.sidebarStore.getCurrentCompany();
    this.sidebarStore.selectedCompany = this.userStore.activeCompanyId;

    if (this.moduleToggleStore.isDevelopment === true) {
      this.createSidebarRoutes();
    } else if (this.moduleToggleStore.isDevelopment === false) {
      this.createSidebarRoutes(this.filterWords);
    }
  },
  methods: {
    async updateActiveCompany(newSelectedCompany: number) {
      if (this.sidebarStore.selectedCompany != newSelectedCompany) {
        this.sidebarStore.selectedCompany = newSelectedCompany;
        await this.userService.updateUser(
          newSelectedCompany,
          this.userStore.userId
        );

        this.userStore.activeCompanyId = newSelectedCompany;
        this.changeCompanyStore.companyId = newSelectedCompany;

        await this.permissionStore.getCurrentCompanyPermissions();
        if (this.moduleToggleStore.isDevelopment === true) {
          this.createSidebarRoutes();
        } else if (this.moduleToggleStore.isDevelopment === false) {
          this.createSidebarRoutes(this.filterWords);
        }

        // Always redirect to assignments, but if user refreshes page - check if company
        // is the same and do not redirect.
        // For access manager - redirect to access dashboard and for parkom redirect to parskom dashboard
        const currentCompanyId = parseInt(
          localStorage.getItem("activeCompanyId")!,
          10
        );

        if (currentCompanyId !== newSelectedCompany) {
          localStorage.setItem("activeCompanyId", String(newSelectedCompany));
          if (this.config.IS_ACCESS == "true") {
            this.$router.push("/access-manager/dashboard");
          } else if (this.config.IS_PARKOM == "true") {
            await this.parkingSpaceFilterStore.getParkingSpaceTreeList();
            this.$router.push("/parkom/dashboard");
          } else {
            this.$router.push("/assignments");
            await this.floorStore.getFloors();
          }
        }
      }
    },
    avatarInitialsGenerator: AvatarInitialsGenerator,
    async createSidebarRoutes(filterWords?: string[]) {
      this.unfilteredRoutes = await getAllRoutes();
      let groupRoutes = this.unfilteredRoutes.filter(
        (route) => route.meta?.group
      );
      const ungroupedRoutes = this.unfilteredRoutes.filter(
        (route) => !route.meta?.group
      );
      if (filterWords && filterWords.length > 0) {
        filterWords.forEach((filterWord) => {
          this.unfilteredRoutes = this.unfilteredRoutes.filter(
            (route) => route.name !== filterWord
          );
          groupRoutes = groupRoutes.filter(
            (route) => route.name !== filterWord
          );
        });
      }
      this.routes = [groupRoutes, ungroupedRoutes];
    },
    toggleAction(actionId: string) {
      switch (actionId) {
        case "treeView":
          this.isParkingSpaceSelectorModalOpened = true;
          break;
      }
    },
  },
});
</script>

<style>
.company .vs__selected {
  @apply text-primary;
}

#vs1__listbox {
  @apply text-primary;
}

.company .v-select {
  @apply text-primary;
}

.company .vs__dropdown-toggle {
  @apply border-white;
}

.company .vs__actions svg {
  @apply fill-white;
}

.company .vs__clear {
  display: none !important;
}

/* Width and height of the scrollbar */
.sidebar-scroll::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Scrollbar thumb */
.sidebar-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Scrollbar track */
.sidebar-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Firefox */
.sidebar-scroll {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}
</style>
