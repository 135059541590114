import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-6" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_modal = _resolveComponent("modal")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_empty_state = _resolveComponent("empty-state")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_delete_modal, {
      "is-deleting": _ctx.isDeleting,
      selectedData: _ctx.selectedApplication,
      "delete-text": "Are you sure you want to delete group ",
      onToggleModal: _ctx.toggleDeleteModal,
      onCancelDelete: _ctx.toggleDeleteModal,
      onConfirmDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openConfirmationModal('delete')))
    }, null, 8, ["is-deleting", "selectedData", "onToggleModal", "onCancelDelete"]),
    _createVNode(_component_confirmation_modal, {
      "delete-text": _ctx.confirmationModalText,
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "number-of-devices": _ctx.selectedRows.length,
      onConfirmAction: _ctx.confirmationModalAction,
      onCloseConfirmationModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
    }, null, 8, ["delete-text", "is-confirmation-modal-opened", "number-of-devices", "onConfirmAction"]),
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isIntegrationsModalOpened,
      "is-select-modal": true,
      title: "Integrations",
      onToggleModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isIntegrationsModalOpened = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_custom_table, {
            "is-action-list-not-displayed": true,
            "is-data-source-loading": _ctx.isDataSourceLoading,
            "table-data": _ctx.applicationDetails.integrations,
            "table-headers": _ctx.integrationHeaders,
            onForceRefresh: _ctx.getApplicationDetails
          }, null, 8, ["is-data-source-loading", "table-data", "table-headers", "onForceRefresh"])
        ])
      ]),
      _: 1
    }, 8, ["is-displayed"]),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 0,
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "object-id": _ctx.selectedRows[0].id,
          "refresh-data": _ctx.getAllApplications,
          onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isEditModalOpened = false))
        }, null, 8, ["is-edit-modal-opened", "object-id", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 1,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "refresh-data": _ctx.getAllApplications,
          onToggleModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isCreateModalOpened = false))
        }, null, 8, ["is-create-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, {
              key: 0,
              "inner-ring-thickness": "border-t-8",
              "outer-ring-thickness": "border-8"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateGroup,
                title: _ctx.$t('Groups.Title'),
                "button-text": "Create",
                description: "Groups are used to group devices together. Each group can have multiple integrations. This allows you to create integrations which will apply to all devices in the group.",
                onExecuteMethod: _ctx.createApplication
              }, null, 8, ["permission-id", "title", "onExecuteMethod"]),
              (!_ctx.isLoading && !_ctx.applications.length)
                ? (_openBlock(), _createBlock(_component_empty_state, {
                    key: 0,
                    "page-name": "groups"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_the_button, {
                        icon: ['fas', 'plus'],
                        buttonType: "primaryButton",
                        text: "Add new group",
                        onExecuteMethod: _ctx.createApplication
                      }, null, 8, ["onExecuteMethod"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_custom_table, {
                    key: 1,
                    "delete-object-modal": _ctx.toggleDeleteModal,
                    "edit-object-modal": _ctx.toggleEditModal,
                    "integrations-modal": _ctx.toggleIntegrationsModal,
                    "is-data-source-loading": _ctx.isDataSourceLoading,
                    options: _ctx.options,
                    "table-data": _ctx.applications,
                    "table-headers": _ctx.tableHeaders,
                    onSelectedRows: _ctx.getSelectedRows,
                    onForceRefresh: _ctx.getAllApplications
                  }, null, 8, ["delete-object-modal", "edit-object-modal", "integrations-modal", "is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onForceRefresh"]))
            ]))
      ]),
      _: 1
    })
  ], 64))
}