import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3971cf16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex h-5 w-5 items-center justify-center text-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "relative flex h-full w-full cursor-pointer items-center justify-center align-middle",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
  }, [
    (_ctx.useForTable)
      ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
          key: 0,
          icon: ['fas', 'ellipsis']
        }))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "hover:bg-primaryhovers rounded-md bg-primary px-4 py-3 text-sm text-white",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
        }, [
          _createTextVNode(" Options "),
          _createVNode(_component_font_awesome_icon, {
            class: _normalizeClass(`ml-2 transform ${
          _ctx.isDropdownOpened ? 'rotate-180' : 'rotate-0'
        } duration-300 ease-out`),
            icon: ['fas', 'chevron-down']
          }, null, 8, ["class"])
        ])),
    _createElementVNode("div", {
      class: _normalizeClass(`absolute right-0 z-[100] mt-3 h-auto min-w-[200px] transform border border-grey bg-white shadow-lg ${
        _ctx.useForTable ? 'top-4' : 'top-12'
      } origin-top space-y-1 rounded-lg p-2 duration-300 ease-in-out ${
        _ctx.isDropdownOpened ? 'scale-y-100' : 'scale-y-0'
      } ${_ctx.isDropdownOpened ? 'opacity-100' : 'opacity-0'}`)
    }, [
      (_ctx.checkIfObjectEmpty(_ctx.componentOptions))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, " No actions available "))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.componentOptions, (value, index) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                  class: _normalizeClass(`flex cursor-pointer items-center space-x-4 px-2 py-1 hover:rounded-md hover:bg-lightgray`),
                  onClick: value.action
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: value['icon']
                    }, null, 8, ["icon"])
                  ]),
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t(value["header"])), 1)
                ], 8, _hoisted_3)
              ]))
            }), 256))
          ]))
    ], 2)
  ])), [
    [_directive_click_away, _ctx.isDropdownOpened && _ctx.closeDropdown]
  ])
}