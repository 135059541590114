import {
  BusController,
  CreateBusController,
  EditBusController,
} from "@/modules/Parkom/Submodules/BusControllers/Services/BusControllerTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class BusControllerService {
  async getBusControllers({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }): Promise<BusController[]> {
    const url = GenerateUrl({
      path: "/api/buscontrollers",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

    const response = await ApiService.get(url);
    return this._mapBusControllers(response.data.items);
  }

  async createBusController(
    createBusController: CreateBusController
  ): Promise<any> {
    const url = GenerateUrl({
      path: "/api/buscontrollers",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.post(url, createBusController);
  }

  async editBusController(editBusController: EditBusController): Promise<any> {
    const url = GenerateUrl({
      path: `/api/buscontrollers/${editBusController.id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });
    return await ApiService.put(url, editBusController);
  }

  private _mapBusControllers(response: BusController[]): BusController[] {
    return response.map((busController) =>
      this._mapBusController(busController)
    );
  }

  private _mapBusController(busController: BusController): BusController {
    return {
      id: busController.id,
      imei: busController.imei,
      lastSync: busController.lastSync,
      spaceId: busController.spaceId,
      spaceName: busController.spaceName,
      busControllerEventsReport: "Events",
      externalAssignmentUuid: busController.externalAssignmentUuid,
    };
  }
}
