<template>
  <modal-component
    :is-displayed="true"
    :is-full-screen-modal="true"
    :remove-outside-click="true"
    :title="title"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="px-6">
      <div class="mb-4 flex h-[46px] items-center space-x-4">
        <dx-date-box
          v-model="from"
          :height="46"
          :show-clear-button="true"
          display-format="dd.MM.yyyy HH:mm"
          type="datetime"
        />
        <dx-date-box
          v-model="to"
          :height="46"
          :show-clear-button="true"
          display-format="dd.MM.yyyy HH:mm"
          type="datetime"
        />       
         <!-- <v-select
          v-model="channel"
          :options="chanelOptions"
          :reduce="(spot : any) => spot.id"
          class="min-w-[160px]"
          label="name"
          multiple
        /> -->
        <the-button
          :icon="['fas', 'rotate-left']"
          button-type="primaryButton"
          @click="getDisplayEvents"
        />
      </div>
      <div v-if="isLoading" class="flex w-full justify-center">
        <small-loader />
      </div>
      <custom-table
        v-else
        :allow-auto-resize="true"
        :is-action-list-not-displayed="true"
        :is-data-source-loading="isDataSourceLoading"
        :table-data="eventList"
        :table-headers="tableHeaders"
        table-height="70vh"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import moment from "moment/moment";
import DxDateBox from "devextreme-vue/date-box";
import TheButton from "@/core/Components/TheButton.vue";
import VSelect from "vue-select";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";
import {
  DisplayEventType,
  DisplayEvents
} from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import Loader from "@/core/Components/Loader.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";

export default defineComponent({
  components: {
    SmallLoader,
    Loader,
    CustomTable,
    ModalComponent,
    DxDateBox,
    TheButton,
    VSelect,
  },
  props: {
    title: String,
    isDisplayEventsShown: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      displayService: new DisplayService(),
      channel: [
        "inbound"
      ] as DisplayEventType[],
      from: moment().startOf("day").format("yyyy-MM-DD HH:mm"),
      to: moment().endOf("day").format("yyyy-MM-DD HH:mm"),
      toast: useToast(),
      isDataSourceLoading: false,
      isLoading: false,
      eventList: [] as DisplayEvents[],
      chanelOptions: [
        { id: "inbound", name: "Inbound" }        
      ],
      tableHeaders: {
        dateTimeEvent: { header: "Displays.Time", visible: true },
        utcTimestamp: { header: "Displays.Timestamp", visible: true },
        text: { header: "Displays.Text", visible: true }        
      }
    };
  },
  async created() {    
    this.isLoading = true;
    try {
      await this.getDisplayEvents();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getDisplayEvents() {      
      this.isDataSourceLoading = true;
      try {
        this.eventList = await this.displayService.getDisplayEvents({
          id: this.id,
          from: this.from,
          to: this.to,
          eventType: this.channel,
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
  },
});
</script>

<style scoped></style>
