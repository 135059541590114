import ConfigurationService from "../Services/ConfigurationService";

export default class WebSocketService {
  baseURL: string;
  webSocketConnection: WebSocket;
  token: any;

  constructor(path: string) {
    this.baseURL = `${ConfigurationService.configData.WS_BASE_URL}${path}`;
    this.webSocketConnection = this.openWSconnection();
    this.token = localStorage.getItem("keycloakToken");
  }

  _onMessageCallBackFunction: Function = (e: any) => {};

  _onOpenCallBackFunction: Function = (e: any) => {};

  public subscribeToDevice(
    deviceUuids: { deviceUuid: string }[],
    isVerbose: boolean,
    callBackFunction: Function
  ) {
    if (this.webSocketConnection.readyState === this.webSocketConnection.OPEN) {
      console.log(`Successfully subscribed to ${deviceUuids.length} devices!`);
      const message = JSON.stringify({
        devices: deviceUuids,
        verbose: true,
      });

      this.webSocketConnection.send(message);
      this._onMessage(callBackFunction);
    }
  }

  public closeWebSocketConnection() {
    this.webSocketConnection.close();
  }

  openWSconnection() {
    const webSocket = new WebSocket(this.baseURL);

    webSocket.onopen = () => {
      console.log("Successfully opened connection!");
      if (ConfigurationService.configData.IS_PARKOM == "true") {
        webSocket.send(
          JSON.stringify({
            Authorization: `Bearer ${this.token}`,
          })
        );
      }
    };

    webSocket.onclose = (error) => {
      console.log("Connection closed!", error);
    };

    webSocket.onerror = (error) => {
      console.log("onerror", error);
    };

    return webSocket;
  }

  private _onMessage(callBackFunction: Function) {
    this.webSocketConnection.onmessage = (message: MessageEvent) => {
      callBackFunction(message);
    };
  }
}
