<template>
  <modal-component
    :is-displayed="isSendMessageModalOpened"
    :no-padding="true"
    :title="$t('ResponseMessages.SendMessage')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="message"
            :label="$t('ResponseMessages.Message')"
            :errorMessage="v$?.message.$errors[0]?.$message"
            :isError="v$?.message?.$error"
            :required="true"
          />
        </form-divider>
        <button-wrapper text="Send" :icon="['fas', 'paper-plane']" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ResponseMessagesService from "@/modules/ResponseMessages/Services/ResponseMessagesService";
import { maxLength, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import AssignmentService from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: {
    FormDivider,
    ButtonWrapper,
    InputField,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isSendMessageModalOpened: Boolean,
    assetIds: Array as PropType<number[]>,
  },
  emits: ["toggle-modal", "refresh-data"],
  data() {
    return {
      assignmentService: new AssignmentService(),
      responseMessagesService: new ResponseMessagesService(),
      toast: useToast(),
      isSubmitLoading: false,
      message: "",
      v$: useValidate() as any,
      validationErrors: [] as any,
    };
  },
  validations() {
    return {
      message: {
        required,
        maxLength: maxLength(1000),
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          if (!this.assetIds?.length) {
            throw new Error("No asset is selected");
          }
          await this.responseMessagesService.sendResponseMessage({
            message: this.message,
            assignmentIds: this.assetIds,
          });
          this.toast.success(this.$t("ResponseMessages.MessageSent"));
          this.$emit("refresh-data");
          this.$emit("toggle-modal");
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
  },
});
</script>

<style scoped></style>
