import { RouteRecordRaw } from "vue-router";
import Index from "../Pages/Index.vue";

export function createMaintenanceRoute(): RouteRecordRaw {
  return {
    name: "maintenance",
    path: "/maintenance",
    component: Index,
    meta: {
      id: "maintenance",
      showInNavigation: false,
    },
  };
}
