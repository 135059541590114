<template>
  <create-form
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getWorkOrders"
    @toggle-modal="isCreateModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getWorkOrders"
    :work-order-prop="selectedWorkOrder"
    @toggle-modal="isEditModalOpened = false"
  />
  <delete-modal
    :is-deleting="isDeleteModalOpened"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :delete-text="confirmationModalText"
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    @confirm-action="confirmationModalAction"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <add-to-order
    v-if="isAddToOrderModalOpened"
    :is-add-to-order-modal-opened="isAddToOrderModalOpened"
    :refresh-data="getWorkOrders"
    :work-order-id="selectedWorkOrder.id"
    @toggle-modal="isAddToOrderModalOpened = false"
  />
  <view-order-devices
    v-if="isViewDevicesModalOpened"
    :is-view-devices-modal-opened="isViewDevicesModalOpened"
    :work-order-id="selectedWorkOrder.id"
    @toggle-modal="isViewDevicesModalOpened = false"
  />
  <apply-confirmation-modal
    :is-apply-confirmation-modal-opened="isApplyConfirmationModalOpened"
    :is-submit-loading="isApplyWorkOrderSubmitLoading"
    :work-order-id="selectedWorkOrder.id"
    @toggle-modal="isApplyConfirmationModalOpened = false"
    @apply-work-order="applyWorkOrder"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else>
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateWorkOrder"
        :title="$t('WorkOrders.Title')"
        button-text="Create"
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :render-summary="['company.name']"
        :table-data="workOrders"
        :table-headers="tableHeaders"
        @apply="toggleApplyConfirmationOrder"
        @selected-rows="getSelectedRows"
        @force-refresh="getWorkOrders"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Loader from "@/core/Components/Loader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CreateForm from "../Components/CreateForm.vue";
import WorkOrderService from "@/modules/WorkOrders/Services/WorkOrderService";
import { WorkOrder } from "@/modules/WorkOrders/Services/WorkOrderTypes";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import AddToOrder from "@/modules/WorkOrders/Components/AddToOrder.vue";
import EditForm from "../Components/EditForm.vue";
import { useUserStore } from "@/core/Store/userStore";
import ApplyConfirmationModal from "@/modules/WorkOrders/Components/ApplyConfirmationModal.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import ViewOrderDevices from "@/modules/WorkOrders/Components/ViewOrderDevices.vue";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    ViewOrderDevices,
    ApplyConfirmationModal,
    AddToOrder,
    ConfirmationModal,
    DeleteModal,
    TableActionHeader,
    CustomTable,
    Loader,
    MainSection,
    CreateForm,
    EditForm,
  },
  data() {
    return {
      workOrderService: new WorkOrderService(),
      workOrders: [] as WorkOrder[],
      selectedWorkOrder: {} as WorkOrder,
      toast: useToast(),
      userStore: useUserStore(),
      changeCompanyStore: useChangeCompanyStore(),
      confirmationModalText: "",
      isLoading: false,
      isDataSourceLoading: false,
      isCreateModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      isApplyWorkOrderSubmitLoading: false,
      isApplyConfirmationModalOpened: false,
      isAddToOrderModalOpened: false,
      isEditModalOpened: false,
      isViewDevicesModalOpened: false,
      permissions: Permissions,
      tableHeaders: {
        id: { header: "ID", visible: false },
        "company.name": { header: "WorkOrders.Company", visible: true },
        piecesFormatted: { header: "WorkOrders.Pieces", visible: true },
        comment: { header: "WorkOrders.Comment", visible: true },
        deliveredOn: { header: "WorkOrders.DeliveredOn", visible: true },
        "userCreated.email": {
          header: "WorkOrders.UserCreated",
          visible: true,
        },
        "userUpdated.email": {
          header: "WorkOrders.UserUpdated",
          visible: true,
        },
        "appliedToUser.email": {
          header: "WorkOrders.AppliedTo",
          visible: true,
        },
        isApplied: { header: "WorkOrders.IsApplied", visible: true },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "edit"],
          permissionId: Permissions.EditWorkOrder,
          action: this.toggleEditModal,
        },
        addDevice: {
          header: "Action.AddDevices",
          icon: ["fas", "laptop-medical"],
          permissionId: Permissions.EditWorkOrder,
          action: this.toggleAddDevicesModal,
        },
        viewDevices: {
          header: "Action.ViewDevices",
          icon: ["fas", "eye"],
          action: this.toggleViewDevicesModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteWorkOrder,
          action: this.toggleDeleteModal,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getWorkOrders();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getWorkOrders();
    },
  },

  methods: {
    async getWorkOrders() {
      this.isDataSourceLoading = true;
      try {
        this.workOrders = await this.workOrderService.getAllWorkOrders({
          pageNumber: 1,
          pageSize: 100000,
          companyId: this.userStore.activeCompanyId,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async deleteWorkOrder() {
      try {
        await this.workOrderService.deleteWorkOrder(this.selectedWorkOrder.id);
        this.isConfirmationModalOpened = false;
        this.isDeleteModalOpened = false;
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        await this.getWorkOrders();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    getSelectedRows(values: WorkOrder[]) {
      if (values.length) {
        this.selectedWorkOrder = values[0];
      }
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
    toggleApplyConfirmationOrder() {
      this.isApplyConfirmationModalOpened =
        !this.isApplyConfirmationModalOpened;
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },
    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteWorkOrder();
          break;
      }
      this.isConfirmationModalOpened = false;
    },
    async applyWorkOrder(id: number) {
      this.isApplyWorkOrderSubmitLoading = true;
      try {
        await this.workOrderService.applyWorkOrderToCompany(id);
        this.toast.success("Work order applied successfully!");
        this.isApplyConfirmationModalOpened = false;
        await this.getWorkOrders();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isApplyWorkOrderSubmitLoading = false;
      }
    },
    toggleAddDevicesModal() {
      this.isAddToOrderModalOpened = !this.isAddToOrderModalOpened;
    },
    toggleViewDevicesModal() {
      this.isViewDevicesModalOpened = !this.isViewDevicesModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
  },
});
</script>

<style scoped></style>
