import ApiService from "@/core/Axios/ApiService";
import { Device } from "@/modules/Devices/Services/DeviceService";
import { AxiosResponse } from "axios";
import { DeviceActionType } from "@/modules/DeviceActions/Services/DeviceActionTypeService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type DeviceActionsObject = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: DeviceAction[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
};

export type DeviceAction = {
  id: number;
  name: string;
  value: object;
  deviceId: number;
  device: Device;
  deviceActionTypeId: number | null;
  deviceActionType: DeviceActionType;
};

export type DeviceActionResponse = {
  id: number;
  commitId: string;
  deviceActionId: string;
  userId: number;
  deviceId: number;
  deviceActionMessage: object;
  deviceActionResponse: object;
  isSuccessful: boolean;
  acknowledgedAt: string;
  queuedAt: string;
  processedAt: string;
};

export type DeviceActionCommit = {
  deviceUuid: string;
  data: any;
  requestTimeout?: number;
  clientId?: string;
  storeCommit?: boolean;
};

export type CreateDeviceAction = {
  name: string;
  data: object;
  deviceId: number | null;
  deviceActionTypeId: number | null;
};

export default class DeviceActionService {
  async getAllDeviceActions(
    pageNumber: number = 1,
    pageSize: number = 10
  ): Promise<DeviceAction[]> {
    const url = GenerateUrl({
      path: "/api/device-actions",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        Include: ["Device", "DeviceActionType"],
      },
    });
    const response: AxiosResponse<DeviceActionsObject> = await ApiService.get(
      url
    );

    return this._mapDeviceActions(response.data.items);
  }

  async getDeviceAction(id: number): Promise<DeviceAction> {
    const url = GenerateUrl({
      path: `/api/device-actions/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        Include: ["Device", "DeviceActionType"],
      },
    });
    const response: AxiosResponse<DeviceAction> = await ApiService.get(url);

    return this._mapDeviceAction(response.data);
  }

  async commitDynamicDeviceAction(
    deviceActionCommit: DeviceActionCommit
  ): Promise<DeviceActionResponse> {
    const response = await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/device-actions/commit`,
      deviceActionCommit
    );
    return response.data;
  }

  async commitDeviceAction(deviceActionId: number) {
    const url = GenerateUrl({
      path: `/api/device-actions/${deviceActionId}/commit`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, {});
  }

  async createAction(deviceAction: CreateDeviceAction) {
    const url = GenerateUrl({
      path: `/api/device-actions`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, deviceAction);
  }

  async editAction({
    id,
    editAction,
  }: {
    id: number;
    editAction: PatchObject[];
  }) {
    const url = GenerateUrl({
      path: `/api/device-actions/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, editAction);
  }

  async deleteAction(deviceActionId: number) {
    const url = GenerateUrl({
      path: `/api/device-actions/${deviceActionId}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  private _mapDeviceActions(response: DeviceAction[]): DeviceAction[] {
    return response.map((deviceAction: DeviceAction) =>
      this._mapDeviceAction(deviceAction)
    );
  }

  private _mapDeviceAction(deviceAction: DeviceAction): DeviceAction {
    return {
      id: deviceAction.id,
      name: deviceAction.name,
      value: deviceAction.value,
      deviceId: deviceAction.deviceId,
      device: deviceAction.device,
      deviceActionTypeId: deviceAction.deviceActionTypeId,
      deviceActionType: deviceAction.deviceActionType,
    };
  }
}
