<template>
  <div class="flex items-center justify-between">
    <div class="flex gap-1">
      <div v-for="pageSize in pageSizes">
        <button
          :class="`flex h-7 w-7 cursor-pointer items-center justify-center rounded-md text-xs text-black hover:bg-primary hover:text-white ${
            pageSize === currentPageSize && 'bg-primary text-white'
          }`"
          @click="setPageSize(pageSize)"
        >
          {{ pageSize }}
        </button>
      </div>
    </div>

    <div class="flex items-center space-x-1 text-xs text-darkgrey">
      <p>
        Page {{ currentPage }} of {{ paginationData?.totalPages }} ({{
          paginationData?.totalCount
        }}
        items)
      </p>
      <button
        class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-md hover:bg-lightgray"
        @click="setPage(1)"
        :disabled="!paginationData?.hasPreviousPage"
      >
        <font-awesome-icon :icon="firstPage" />
      </button>
      <button
        class="flex h-7 w-7 cursor-pointer items-center justify-center rounded-md hover:bg-lightgray"
        @click="setPage(currentPage - 1)"
        :disabled="!paginationData?.hasPreviousPage"
      >
        <font-awesome-icon :icon="previousPage" />
      </button>
      <div
        v-for="page in pages"
        :class="`flex h-7 w-7 cursor-pointer items-center justify-center rounded-md text-black hover:bg-primary hover:text-white ${
          page === currentPage && 'bg-primary text-white'
        }`"
        @click="setPage(page)"
      >
        <div>{{ page }}</div>
      </div>
      <button
        class="flex h-9 w-9 cursor-pointer items-center justify-center rounded-md hover:bg-lightgray"
        @click="setPage(currentPage + 1)"
        :disabled="!paginationData?.hasNextPage"
      >
        <font-awesome-icon :icon="nextPage" />
      </button>
      <button
        class="flex h-9 w-9 cursor-pointer items-center justify-center rounded-md hover:bg-lightgray"
        @click="setPage(paginationData?.totalPages)"
        :disabled="!paginationData?.hasNextPage"
      >
        <font-awesome-icon :icon="lastPage" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from "vue";
import { PaginationSettings } from "../Services/PaginationSettings";

export default defineComponent({
  props: ["paginationData"],
  emits: ["page-change", "page-size-change"],
  data() {
    return {
      currentPage: this.paginationData?.pageIndex,
      lastPage: ["fas", "angle-double-right"],
      firstPage: ["fas", "angle-double-left"],
      nextPage: ["fas", "angle-right"],
      previousPage: ["fas", "angle-left"],
      currentPageSize: PaginationSettings.DEFAULT_CURRENT_PAGE_SIZE,
      pageSizes: PaginationSettings.PAGE_SIZES,
    };
  },
  methods: {
    setPage(index: number) {
      this.currentPage = index;
      this.$emit("page-change", index);
    },
    setPageSize(index: number) {
      this.currentPageSize = index;
      this.currentPage = 1;
      this.$emit("page-size-change", index);
    },
  },
  computed: {
    pages() {
      let numShown = 5; // This sets the number of page tabs
      numShown = Math.min(numShown, this.paginationData?.totalPages);
      let first = this.currentPage - Math.floor(numShown / 2);
      first = Math.max(first, 1);
      first = Math.min(first, this.paginationData?.totalPages - numShown + 1);
      return [...Array(numShown ? numShown : 0)].map((k, i) => i + first);
    },
  },
});
</script>
<style scoped>
button:disabled {
  color: #d1d5db;
  cursor: default;
}

button:disabled:hover {
  background-color: #fff;
}
</style>
