<template>
  <div class="flex items-center justify-center w-full h-full">
    <form-wrapper :is-form-in-modal="isFormInModal">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
              v-model="formData.email"
              :errorMessage="v$?.formData['email']?.$errors[0]?.$message"
              :isError="v$?.formData['email']?.$error"
              :required="true"
              label="Email"
              placeholder="Enter email..."
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading"/>
      </form>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import {AddUserToCompanyWithEmail} from "@/modules/Companies/Services/CompanyService";
import {ErrorMessageFormatter} from "@/core/Services/GlobalFunctions";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import {useToastStore} from "@/core/Store/toastStore";
import {useSidebarStore} from "@/core/Store";
import {required} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import {useUserStore} from "@/core/Store/userStore";
import SpotiumService from "@/core/Services/SpotiumService";

export default defineComponent({
  components: {MainSection, FormWrapper, InputField, TheButton, ToastComponent, ButtonWrapper, FormDivider},
  props: {isFormInModal: Boolean, refreshData: Function},
  inject: ["user"],
  emits: ["created-user-id"],
  data() {
    return {
      userStore: useUserStore(),
      v$: useValidate() as any,
      isSubmitLoading: false as boolean,
      spotiumService: new SpotiumService(),
      toastStore: useToastStore(),
      sidebarStore: useSidebarStore(),
      validationErrors: [] as any,
      error: "" as string,
      formData: {
        companyId: 0,
        email: "" as string,
      } as AddUserToCompanyWithEmail,
    };
  },
  validations() {
    return {
      formData: {
        email: {
          required,
        },
      },
    };
  },

  created() {
    this.formData.companyId = this.userStore.activeCompanyId;
  },

  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          const createdUserId = await this.spotiumService.createUserWithEmail(this.formData.email, this.formData.companyId);
          this.toastStore.displayToastMessage({toastMessage: "User successfully added to company!", isError: false});
          this.$emit("created-user-id", createdUserId);
          await this.refreshData?.();
        } catch (error: any) {
          this.toastStore.displayToastMessage({toastMessage: ErrorMessageFormatter(error), isError: true})
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
  },
});
</script>
<style scoped></style>
