<template>
  <div class="p-4">
    <div class="space-y-4">
      <custom-details-view
        :isDevice="true"
        :no-qr-icon="true"
        :table-data="deviceAssignment"
        :table-headers="deviceLabels"
        title="Asset"
        @copy-to-clipboard="copyToClipBoard"
      />
      <h2 class="pt-8 text-2xl font-bold text-dark">Asset is shared to</h2>
      <custom-table
        :is-action-list-not-displayed="true"
        :table-data="usersSharedToDevice"
        :table-headers="tableHeaders"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "../../../../../core/Components/MainSection.vue";
import { Application } from "@/modules/Applications/Services/ApplicationService";
import CustomDetailsView from "@/core/Components/CustomDetailsView.vue";
import Loader from "@/core/Components/Loader.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DropdownMenu from "@/core/Components/Dropdowns/DropdownMenu.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import ChangeGroupModal from "./ChangeGroupModal.vue";
import ShareDeviceModal from "./ShareDeviceModal.vue";
import UserService, {
  UserShareAssignment,
} from "@/modules/Users/Services/UserService";
import CustomTable from "@/core/Components/CustomTable.vue";
import QRCodeModal from "./QRCodeModal.vue";
import LastDataPreview from "./LastDataPreview.vue";
import { useUserStore } from "@/core/Store/userStore";
import { useToast } from "vue-toastification";
import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import Modal from "@/core/Components/Modal.vue";

export default defineComponent({
  components: {
    MainSection,
    CustomDetailsView,
    Loader,
    DropdownMenu,
    DeleteModal,
    ToastComponent,
    ConfirmationModal,
    ChangeGroupModal,
    ShareDeviceModal,
    CustomTable,
    QRCodeModal,
    LastDataPreview,
    Modal,
  },
  props: {
    assignmentId: { type: Number, required: true },
    haveModal: Boolean,
  },
  inject: ["user"],
  data() {
    return {
      userStore: useUserStore(),
      assignmentService: new AssignmentService(),
      userService: new UserService(),
      deviceAssignment: {} as DeviceAssignment,
      applications: [] as Application[],
      usersSharedToDevice: [] as UserShareAssignment[],
      isDeleting: false as boolean,
      isSubmitLoading: false as boolean,
      userId: 0 as number,
      deviceRemove: false as boolean,
      isLoading: false as boolean,
      isConfirmationModalOpened: false as boolean,
      isChangeApplicationOpened: false as boolean,
      isShareModalOpened: false as boolean,
      isQrModalOpened: false as boolean,
      isLastDataModalOpened: false as boolean,
      methodType: "" as string,
      toast: useToast(),
      deviceMetadataId: 0 as number,
      deviceLabels: {
        "assignment.id": "ID",
        "device.deviceUuid": { header: "UUID", copy: true },
        "assignment.dataUuid": { header: "Data UUID", copy: true },
        "assignment.name": "Name",
        "device.mac": { header: "MAC", copy: true },
        "device.imei": { header: "IMEI", copy: true },
        "assignment.latitude": "Latitude",
        "assignment.longitude": "Longitude",
        "device.serialNumber": "Serial number",
        "assignment.isActive": "Active",
        "assignment.application.name": "Group",
        "device.deviceType.name": "Asset type",
        "assignment.user.email": "Asset Owner",
      },
      options: {
        edit: { header: "Edit", icon: ["fas", "pen"] },
        remove: { header: "Remove", icon: ["fas", "user-slash"] },
        report: { header: "Reports", icon: ["fas", "file-lines"] },
        delete: { header: "Delete", icon: ["fas", "trash"] },
        changeGroup: { header: "Change group", icon: ["fas", "repeat"] },
        shareDevice: {
          header: "Share device",
          icon: ["fas", "share-from-square"],
        },
        lastDataPreview: { header: "Last data", icon: ["fas", "database"] },
        deviceData: { header: "Device data", icon: ["fas", "calendar"] },
      },
      tableHeaders: {
        username: { header: "Username", visible: true },
        email: { header: "Email", visible: true },
      },
    };
  },

  async created() {
    console.log("IMA MODAL - ", this.haveModal);

    this.userId = this.userStore.userId;
    this.isLoading = true;
    try {
      await Promise.all([
        this.getDeviceDetails(),
        this.getUsersDeviceIsSharedTo(),
      ]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async getDeviceDetails() {
      try {
        this.deviceAssignment =
          await this.assignmentService.getDeviceAssignmentByIdentifier({
            id: this.assignmentId,
          });
        console.log("Podaci -", this.deviceAssignment);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getUsersDeviceIsSharedTo() {
      try {
        this.usersSharedToDevice =
          await this.userService.getAllUsersThatDeviceIsSharedTo({
            assignmentId: this.assignmentId,
            pageNumber: 1,
            pageSize: 10000,
            typeOfShare: "Shared",
          });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    openChangeApplicationModal() {
      this.isChangeApplicationOpened = true;
    },

    copyToClipBoard(data: string) {
      navigator.clipboard.writeText(data);
      this.toast.success("Copied to clipboard");
    },
  },
});
</script>
