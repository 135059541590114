import ApiService from "@/core/Axios/ApiService";
import axios from "axios";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type ResourceType = {
  id: number;
  name: string;
};

export default class ResourceTypeService {
  async getAllResourceTypes(): Promise<ResourceType[]> {
    const response = await axios.get(
      `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/resource-types`
    );

    return this._mapResourceTypes(response.data);
  }

  private _mapResourceTypes(response: ResourceType[]): ResourceType[] {
    return response.map((resourceType: ResourceType) =>
      this._mapResourceType(resourceType)
    );
  }

  private _mapResourceType(resourceType: ResourceType): ResourceType {
    return {
      id: resourceType.id,
      name: resourceType.name,
    };
  }
}
