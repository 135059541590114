<template>
  <div>
    <div
      v-for="(obj, index) in json"
      :style="{
        'grid-template-columns': 'auto auto min-content',
      }"
      class="grid grid-cols-3 gap-4 py-3"
    >
      <div>
        <div class="flex items-center space-x-2">
          <label
            class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
            >{{ $t("Integrations.Topic") }}</label
          >
          <font-awesome-icon
            v-tippy="
              'Topic is a name of a topic, while channel is numeric value of channel'
            "
            :icon="['fas', 'circle-info']"
          />
        </div>
        <input
          id="key"
          v-model="obj.key"
          class="relative mt-1 w-full appearance-none border bg-white p-2 text-left font-sans leading-normal text-dark shadow-sm outline-none focus:border-primary"
          @keyup="trimTopic"
        />
      </div>
      <div>
        <div class="flex items-center space-x-2">
          <label
            class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
            >{{ $t("Integrations.Channel") }}</label
          >
          <font-awesome-icon :icon="['fas', 'circle-info']" class="invisible" />
        </div>
        <input
          id="value"
          v-model="obj.value"
          class="relative mt-1 w-full appearance-none border bg-white p-2 text-left font-sans leading-normal text-dark shadow-sm outline-none focus:border-primary"
          type="number"
          @input="updateValue()"
          min="0"
        />
      </div>
      <the-button
        :icon="isDeleteButton(index) ? ['fas', 'trash'] : ['fas', 'add']"
        type="button"
        @execute-method="
          isDeleteButton(index) ? deleteValue(obj.key, index) : addValue()
        "
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TheButton from "@/core/Components/TheButton.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { directive } from "vue-tippy";
import { useToast } from "vue-toastification";

export default defineComponent({
  components: { FontAwesomeIcon, TheButton },
  emits: ["input"],
  props: ["topics"],
  data() {
    return {
      toast: useToast(),
      json: [{ key: "", value: "" }],
      convertedObject: {} as Record<string, number>,
    };
  },
  directives: {
    tippy: directive,
  },
  created() {
    if (this.topics) {
      this.json = Object.entries(this.topics).map(([key, value]) => ({
        key,
        value: String(value),
      }));
    }
  },
  methods: {
    trimTopic(event: any) {
      this.json.forEach((obj) => {
        obj.key = obj.key.replace(/\s/g, "");
      });
      this.updateValue();
    },
    addValue() {
      this.json.push({ key: "", value: "" });
    },
    updateValue() {
      let json = this.json.filter((obj) => obj.key !== "" && obj.value !== "");
      this.convertedObject = json.reduce((obj: any, item) => {
        obj[item.key] = Number(item.value);
        return obj;
      }, {});

      this.$emit("input", this.convertedObject);
    },
    deleteValue(key: string, index: number) {
      delete this.convertedObject[key];
      this.json.splice(index, 1);
      this.updateValue();
      this.$emit("input", this.convertedObject);
    },
    isDeleteButton(index: number) {
      if (index > 0) {
        return true;
      }
    },
  },
});
</script>

<style scoped></style>
