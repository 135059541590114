<template>
  <create-form
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getParkingSpaces"
    @toggle-modal="toggleCreateModal"
  />
  <edit-form
    v-if="isEditModalOpened"
    :is-edit-modal-opened="isEditModalOpened"
    :parking-space-prop="selectedParkingSpace"
    :refresh-data="getParkingSpaces"
    @toggle-modal="isEditModalOpened = false"
  />
  <manage-parking-spots
    v-if="isManageParkingSpotsModalOpened"
    :is-manage-parking-spots-modal-opened="isManageParkingSpotsModalOpened"
    :parking-space-id="selectedParkingSpace.id"
    @toggle-modal="isManageParkingSpotsModalOpened = false"
  />
  <delete-modal
    v-if="isDeleteModalOpened"
    :is-deleting="isDeleteModalOpened"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :delete-text="confirmationModalText"
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    @confirm-action="confirmationModalAction"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else class="h-full">
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateAssignment"
        :title="$t('ParkingSpaces.Title')"
        button-text="Create"
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :render-summary="['name']"
        :show-per-page="16"
        :table-data="parkingSpaces"
        :table-headers="tableHeaders"
        table-height="93%"
        :isActionListNotDisplayed="
          !permissionStore.hasPermission(permissions.EditAssignment) &&
          !permissionStore.hasPermission(permissions.DeleteAssignment)
        "
        @selected-rows="getSelectedRows"
        @force-refresh="getParkingSpaces"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CreateForm from "../Components/CreateForm.vue";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Loader from "@/core/Components/Loader.vue";
import EditForm from "@/modules/Parkom/Submodules/ParkingSpaces/Components/EditForm.vue";
import ManageParkingSpots from "@/modules/Parkom/Submodules/ParkingSpaces/Components/ManageParkingSpots.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    ConfirmationModal,
    DeleteModal,
    ManageParkingSpots,
    Loader,
    CreateForm,
    TableActionHeader,
    CustomTable,
    MainSection,
    EditForm,
  },
  data() {
    return {
      parkingSpaceService: new ParkingSpaceService(),
      isCreateModalOpened: false,
      isLoading: false,
      parkingSpaces: [] as ParkingSpace[],
      selectedParkingSpace: {} as ParkingSpace,
      isDataSourceLoading: false,
      isEditModalOpened: false,
      isManageParkingSpotsModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      confirmationModalText: "",
      toast: useToast(),
      changeCompanyStore: useChangeCompanyStore(),
      permissionStore: usePermissionStore(),
      permissions: Permissions,
      tableHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "ParkingSpaces.Name", visible: true },
        city: { header: "ParkingSpaces.City", visible: true },
        street: { header: "ParkingSpaces.Street", visible: true },
        streetNumber: { header: "ParkingSpaces.StreetNumber", visible: true },
        occupied: { header: "ParkingSpaces.Occupied", visible: true },
        unoccupied: { header: "ParkingSpaces.Unoccupied", visible: true },
        navigable: { header: "ParkingSpaces.Navigable", visible: true },
        visible: { header: "ParkingSpaces.Visible", visible: true },
        active: { header: "ParkingSpaces.Active", visible: true },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditAssignment,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteAssignment,
          action: this.toggleDeleteModal,
        },
        assign: {
          header: "Action.ManageSpots",
          icon: ["fas", "circle-dot"],
          permissionId: Permissions.EditAssignment,
          action: this.toggleManageParkingSpotsModal,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getParkingSpaces();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getParkingSpaces();
    },
  },
  methods: {
    async getParkingSpaces() {
      this.isDataSourceLoading = true;
      try {
        this.parkingSpaces = await this.parkingSpaceService.getParkingSpaces({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async deleteParkingSpace() {
      try {
        await this.parkingSpaceService.deleteParkingSpace(
          this.selectedParkingSpace.id
        );
        this.toast.success("Parking space deleted successfully!");
        this.isDeleteModalOpened = false;
        await this.getParkingSpaces();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },
    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteParkingSpace();
          break;
      }
      this.isConfirmationModalOpened = false;
    },
    getSelectedRows(values: ParkingSpace[]) {
      if (values.length) {
        this.selectedParkingSpace = values[0];
      }
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
    toggleManageParkingSpotsModal() {
      this.isManageParkingSpotsModalOpened =
        !this.isManageParkingSpotsModalOpened;
    },
  },
});
</script>

<style scoped></style>
