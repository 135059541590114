export function SelectMapIcon({
  occupied,
  offline,
  typeId,
}: {
  occupied: boolean;
  offline?: boolean;
  typeId?: number;
}) {
  if (!typeId) {
    return occupied ? "red_point.png" : "green_point.png";
  }
  if (offline) {
    switch (typeId) {
      case 1:
        return "gray_point.png";
      case 2:
        return "handicapped_offline.png";
      case 3:
        return "charge_offline.png";
    }
  }
  switch (typeId) {
    case 1:
      return occupied ? "red_point.png" : "green_point.png";
    case 2:
      return occupied ? "handicapped_red.png" : "handicapped_green.png";
    case 3:
      return occupied ? "charge_red.png" : "charge_green.png";
  }
}
