import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "space-y-4"
}
const _hoisted_2 = { class: "mb-4 flex items-center justify-between" }
const _hoisted_3 = { class: "flex items-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_report_modal = _resolveComponent("report-modal")!
  const _component_static_actions_modal = _resolveComponent("static-actions-modal")!
  const _component_details_modal = _resolveComponent("details-modal")!
  const _component_modal = _resolveComponent("modal")!
  const _component_q_r_code_modal = _resolveComponent("q-r-code-modal")!
  const _component_sent_data = _resolveComponent("sent-data")!
  const _component_change_company_modal = _resolveComponent("change-company-modal")!
  const _component_developer_options_modal = _resolveComponent("developer-options-modal")!
  const _component_change_group_modal = _resolveComponent("change-group-modal")!
  const _component_change_ownership_modal = _resolveComponent("change-ownership-modal")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_share_device_modal = _resolveComponent("share-device-modal")!
  const _component_last_data_preview = _resolveComponent("last-data-preview")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_live_data_modal = _resolveComponent("live-data-modal")!
  const _component_users_device_is_shared_to = _resolveComponent("users-device-is-shared-to")!
  const _component_reject_approve_share = _resolveComponent("reject-approve-share")!
  const _component_generic_packet = _resolveComponent("generic-packet")!
  const _component_create_relation_form = _resolveComponent("create-relation-form")!
  const _component_delete_relation_modal = _resolveComponent("delete-relation-modal")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_loader = _resolveComponent("loader")!
  const _component_tabs_component = _resolveComponent("tabs-component")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isReportModalOpened)
      ? (_openBlock(), _createBlock(_component_report_modal, {
          key: 0,
          "device-name": _ctx.selectedDevice!.assignment!.name,
          "device-uuid": _ctx.selectedDevice!.device!.deviceUuid,
          "have-modal": true,
          "is-report-modal-opened": _ctx.isReportModalOpened,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isReportModalOpened = false))
        }, null, 8, ["device-name", "device-uuid", "is-report-modal-opened"]))
      : _createCommentVNode("", true),
    (_ctx.isStaticActionsModalOpened)
      ? (_openBlock(), _createBlock(_component_static_actions_modal, {
          key: 1,
          device: _ctx.selectedDevice,
          "is-static-actions-modal-opened": _ctx.isStaticActionsModalOpened,
          onToggleModal: _ctx.toggleStaticActionsModal
        }, null, 8, ["device", "is-static-actions-modal-opened", "onToggleModal"]))
      : _createCommentVNode("", true),
    _createVNode(_component_modal, {
      "have-modal": true,
      "is-displayed": _ctx.isDetailsModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      title: `${_ctx.selectedDevice?.assignment?.name} details`,
      onToggleModal: _ctx.toggleViewDetailsModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_details_modal, {
          "assignment-id": _ctx.selectedDevice!.assignmentId,
          "have-modal": true
        }, null, 8, ["assignment-id"])
      ]),
      _: 1
    }, 8, ["is-displayed", "title", "onToggleModal"]),
    (_ctx.isQrCodeModalOpened)
      ? (_openBlock(), _createBlock(_component_q_r_code_modal, {
          key: 2,
          "device-details": _ctx.selectedDevice,
          "have-modal": true,
          "is-qr-modal-opened": _ctx.isQrCodeModalOpened,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isQrCodeModalOpened = false))
        }, null, 8, ["device-details", "is-qr-modal-opened"]))
      : _createCommentVNode("", true),
    (_ctx.isSentDataModalOpened)
      ? (_openBlock(), _createBlock(_component_modal, {
          key: 3,
          "have-modal": true,
          "is-full-screen-modal": true,
          "is-displayed": _ctx.isSentDataModalOpened,
          "is-select-modal": true,
          "need-for-scroll": true,
          "remove-outside-click": true,
          title: `Device data sent by ${_ctx.selectedDevice!.assignment!.name}`,
          onToggleModal: _ctx.toggleDataSentModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_sent_data, {
              dataUuid: _ctx.selectedDevice!.assignment?.dataUuid
            }, null, 8, ["dataUuid"])
          ]),
          _: 1
        }, 8, ["is-displayed", "title", "onToggleModal"]))
      : _createCommentVNode("", true),
    (_ctx.isChangeCompanyModalOpened)
      ? (_openBlock(), _createBlock(_component_change_company_modal, {
          key: 4,
          "assignment-id": _ctx.selectedDevice!.assignmentId,
          "close-modal": _ctx.toggleMoveDeviceToNewCompanyModal,
          "have-modal": true,
          "is-for-multiple-devices": _ctx.isMoveForMultipleDevices,
          "is-move-to-new-company-modal-opened": _ctx.isChangeCompanyModalOpened,
          "refresh-data": async () => await _ctx.changeTab(_ctx.selectedTab),
          onMultipleMove: _ctx.getCompanyIdForMovingDevices,
          onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isChangeCompanyModalOpened = false))
        }, null, 8, ["assignment-id", "close-modal", "is-for-multiple-devices", "is-move-to-new-company-modal-opened", "refresh-data", "onMultipleMove"]))
      : _createCommentVNode("", true),
    (_ctx.isDeveloperOptionsModalOpened)
      ? (_openBlock(), _createBlock(_component_developer_options_modal, {
          key: 5,
          "have-modal": true,
          "selected-device": _ctx.selectedDevice,
          onToggleModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDeveloperOptionsModalOpened = false))
        }, null, 8, ["selected-device"]))
      : _createCommentVNode("", true),
    (_ctx.isChangeApplicationOpened)
      ? (_openBlock(), _createBlock(_component_change_group_modal, {
          key: 6,
          "application-id": _ctx.selectedDevice?.assignment?.applicationId,
          applications: _ctx.applications,
          "have-modal": true,
          "is-change-application-opened": _ctx.isChangeApplicationOpened,
          "is-form-in-modal": true,
          "is-loading": _ctx.isApplicationLoading,
          "is-submit-loading": _ctx.isSubmitLoading,
          onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isChangeApplicationOpened = false)),
          onOnSubmit: _ctx.changeDeviceApplication
        }, null, 8, ["application-id", "applications", "is-change-application-opened", "is-loading", "is-submit-loading", "onOnSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.isChangeOwnershipModalOpened)
      ? (_openBlock(), _createBlock(_component_change_ownership_modal, {
          key: 7,
          "current-owner-id": _ctx.selectedDevice?.assignment?.userId,
          "selected-asset-id": _ctx.selectedDevice.assignmentId,
          "is-change-ownership-opened": _ctx.isChangeOwnershipModalOpened,
          onToggleModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isChangeOwnershipModalOpened = false)),
          onRefresh: _cache[6] || (_cache[6] = async () => await _ctx.changeTab(_ctx.selectedTab))
        }, null, 8, ["current-owner-id", "selected-asset-id", "is-change-ownership-opened"]))
      : _createCommentVNode("", true),
    _createVNode(_component_delete_modal, {
      deviceRemove: _ctx.deviceRemove,
      "have-modal": true,
      "is-deleting": _ctx.isDeleting,
      "is-submit-loading": _ctx.isDataSourceLoading,
      selectedData: _ctx.selectedDevice,
      "delete-text": "Are you sure you want to remove asset ",
      onToggleModal: _ctx.toggleDeleteModal,
      onCancelDelete: _ctx.toggleDeleteModal,
      onConfirmRemove: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openConfirmationModal('remove'))),
      onConfirmUnassign: _cache[8] || (_cache[8] = ($event: any) => (_ctx.openConfirmationModal('unassign'))),
      onConfirmDelete: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openConfirmationModal('delete')))
    }, null, 8, ["deviceRemove", "is-deleting", "is-submit-loading", "selectedData", "onToggleModal", "onCancelDelete"]),
    _createVNode(_component_confirmation_modal, {
      "delete-text": _ctx.confirmationModalText,
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "number-of-devices": _ctx.selectedRows.length,
      onConfirmAction: _ctx.confirmationModalAction,
      onCloseConfirmationModal: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[11] || (_cache[11] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
    }, null, 8, ["delete-text", "is-confirmation-modal-opened", "number-of-devices", "onConfirmAction"]),
    _createVNode(_component_share_device_modal, {
      "device-data": _ctx.selectedDevice,
      "have-modal": true,
      "is-share-modal-opened": _ctx.isShareModalOpened,
      "is-submit-loading": _ctx.isSubmitLoading,
      onToggleModal: _cache[12] || (_cache[12] = ($event: any) => (_ctx.isShareModalOpened = false)),
      onOnSubmit: _ctx.shareDevice
    }, null, 8, ["device-data", "is-share-modal-opened", "is-submit-loading", "onOnSubmit"]),
    (_ctx.isLastDataModalOpened)
      ? (_openBlock(), _createBlock(_component_last_data_preview, {
          key: 8,
          "data-uuid": _ctx.selectedDevice?.assignment?.dataUuid,
          "have-modal": true,
          "is-last-data-modal-opened": _ctx.isLastDataModalOpened,
          getLastData: true,
          name: _ctx.selectedDevice?.assignment?.name,
          onToggleModal: _cache[13] || (_cache[13] = ($event: any) => (_ctx.isLastDataModalOpened = false))
        }, null, 8, ["data-uuid", "is-last-data-modal-opened", "name"]))
      : _createCommentVNode("", true),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 9,
          "have-modal": true,
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "is-form-in-modal": true,
          "object-id": _ctx.selectedDevice!.assignmentId,
          refreshData: async () => await _ctx.changeTab(_ctx.selectedTab),
          onToggleModal: _ctx.toggleEditModal
        }, null, 8, ["is-edit-modal-opened", "object-id", "refreshData", "onToggleModal"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 10,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "is-form-in-modal": true,
          refreshData: async () => await _ctx.changeTab(_ctx.selectedTab),
          onToggleModal: _ctx.toggleCreateModal
        }, null, 8, ["is-create-modal-opened", "refreshData", "onToggleModal"]))
      : _createCommentVNode("", true),
    (_ctx.isLiveDataModalOpened)
      ? (_openBlock(), _createBlock(_component_live_data_modal, {
          key: 11,
          device: _ctx.selectedDevice,
          "have-modal": true,
          "is-live-data-modal-opened": _ctx.isLiveDataModalOpened,
          onToggleModal: _ctx.toggleLiveDataModal
        }, null, 8, ["device", "is-live-data-modal-opened", "onToggleModal"]))
      : _createCommentVNode("", true),
    (_ctx.isUsersDevicesSharedToModalOpened)
      ? (_openBlock(), _createBlock(_component_users_device_is_shared_to, {
          key: 12,
          "assignment-id": _ctx.selectedAssignmentId,
          "is-users-devices-shared-to-modal-displayed": 
        _ctx.isUsersDevicesSharedToModalOpened
      ,
          onToggleModal: _cache[14] || (_cache[14] = ($event: any) => (_ctx.isUsersDevicesSharedToModalOpened = false)),
          onRefreshData: _cache[15] || (_cache[15] = async () => await _ctx.changeTab(_ctx.selectedTab))
        }, null, 8, ["assignment-id", "is-users-devices-shared-to-modal-displayed"]))
      : _createCommentVNode("", true),
    (_ctx.isUsersRejectApproveShareModalOpened)
      ? (_openBlock(), _createBlock(_component_reject_approve_share, {
          key: 13,
          "assignment-id": _ctx.selectedAssignmentId,
          "is-users-reject-approve-share-modal-opened": 
        _ctx.isUsersRejectApproveShareModalOpened
      ,
          onToggleModal: _cache[16] || (_cache[16] = ($event: any) => (_ctx.isUsersRejectApproveShareModalOpened = false)),
          onRefreshData: _cache[17] || (_cache[17] = async () => await _ctx.changeTab(_ctx.selectedTab))
        }, null, 8, ["assignment-id", "is-users-reject-approve-share-modal-opened"]))
      : _createCommentVNode("", true),
    (_ctx.isGenericPacketModalOpened)
      ? (_openBlock(), _createBlock(_component_generic_packet, {
          key: 14,
          "device-uuid": _ctx.genericPacketUuid,
          removeEdit: _ctx.removeEdit,
          onCloseModal: _ctx.closeGenericPacketModal
        }, null, 8, ["device-uuid", "removeEdit", "onCloseModal"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateRelationModalOpened)
      ? (_openBlock(), _createBlock(_component_create_relation_form, {
          key: 15,
          "assignment-id-list": _ctx.assignmentIdList,
          "is-create-relation-modal-opened": _ctx.isCreateRelationModalOpened,
          "refresh-data": _ctx.getDeviceAssignments,
          "table-data": _ctx.deviceAssignments,
          onToggleModal: _cache[18] || (_cache[18] = ($event: any) => (_ctx.isCreateRelationModalOpened = false))
        }, null, 8, ["assignment-id-list", "is-create-relation-modal-opened", "refresh-data", "table-data"]))
      : _createCommentVNode("", true),
    (_ctx.isDataPreviewModalOpened)
      ? (_openBlock(), _createBlock(_component_last_data_preview, {
          key: 16,
          data: _ctx.selectedMetadata,
          "is-last-data-modal-opened": _ctx.isDataPreviewModalOpened,
          name: _ctx.selectedDevice.assignment?.name,
          metadata: true,
          onToggleModal: _cache[19] || (_cache[19] = ($event: any) => (_ctx.isDataPreviewModalOpened = false))
        }, null, 8, ["data", "is-last-data-modal-opened", "name"]))
      : _createCommentVNode("", true),
    (_ctx.isDeleteRelationModalOpened)
      ? (_openBlock(), _createBlock(_component_delete_relation_modal, {
          key: 17,
          "assignment-id": _ctx.selectedDevice.assignmentId ?? 0,
          "is-delete-relation-modal-opened": _ctx.isDeleteRelationModalOpened,
          metadata: _ctx.selectedDevice?.assignment?.metadata,
          "refresh-data": async () => await _ctx.changeTab(_ctx.selectedTab),
          onToggleModal: _cache[20] || (_cache[20] = ($event: any) => (_ctx.isDeleteRelationModalOpened = false))
        }, null, 8, ["assignment-id", "is-delete-relation-modal-opened", "metadata", "refresh-data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        _createVNode(_component_table_action_header, {
          title: 'Assets',
          description: " "
        }),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, {
              key: 0,
              "inner-ring-thickness": "border-t-8",
              "outer-ring-thickness": "border-8"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_tabs_component, {
                  tabs: _ctx.tabs,
                  onChangeTab: _ctx.changeTab
                }, null, 8, ["tabs", "onChangeTab"]),
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.selectedTab == 'MyDevices' || _ctx.selectedTab == 'Company')
                    ? (_openBlock(), _createBlock(_component_the_button, {
                        key: 0,
                        text: "Apply metadata",
                        "is-disabled": !_ctx.isApplyButtonEnabled,
                        variant: _ctx.isApplyButtonEnabled ? 'default' : 'ghost',
                        onExecuteMethod: _ctx.toggleCreateRelationModal
                      }, null, 8, ["is-disabled", "variant", "onExecuteMethod"]))
                    : _createCommentVNode("", true),
                  (
                _ctx.permissionStore.hasPermission(_ctx.permissions.CreateAssignment) &&
                (_ctx.selectedTab == 'MyDevices' || _ctx.selectedTab == 'Company')
              )
                    ? (_openBlock(), _createBlock(_component_the_button, {
                        key: 1,
                        icon: ['fas', 'plus'],
                        buttonType: "primaryButton",
                        text: "Create",
                        onExecuteMethod: _ctx.createDevice
                      }, null, 8, ["onExecuteMethod"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_ctx.isTableLoaded)
                ? (_openBlock(), _createBlock(_component_custom_table, {
                    key: 0,
                    "is-action-list-not-displayed": 
            _ctx.isUsersDevicesSharedToDisplayed ||
            _ctx.isUsersRejectApproveShareDisplayed
          ,
                    "is-data-source-loading": _ctx.isDataSourceLoading,
                    "is-devices-table": true,
                    "is-users-devices-shared-to-displayed": 
            _ctx.isUsersDevicesSharedToDisplayed
          ,
                    "is-users-reject-approve-share-displayed": 
            _ctx.isUsersRejectApproveShareDisplayed
          ,
                    multiselect: true,
                    options: _ctx.options,
                    "show-device-actions": false,
                    "table-data": _ctx.deviceAssignments,
                    "table-headers": _ctx.tableHeaders,
                    onOpenGenericPacketModal: _ctx.openGenericPacketModal,
                    onSelectedRows: _ctx.getSelectedRows,
                    onActionClick: _ctx.multipleItemAction,
                    onApproveShareRequest: _cache[21] || (_cache[21] = ($event: any) => (_ctx.openConfirmationModal('approve'))),
                    onRejectShareRequest: _cache[22] || (_cache[22] = ($event: any) => (_ctx.openConfirmationModal('reject'))),
                    onRemoveShare: _cache[23] || (_cache[23] = ($event: any) => (_ctx.openConfirmationModal('revoke'))),
                    onToggleStaticActionsModal: _ctx.toggleStaticActionsModal,
                    onForceRefresh: _cache[24] || (_cache[24] = async () => await _ctx.changeTab(_ctx.selectedTab)),
                    onUsersDeviceIsSharedTo: _ctx.toggleUsersDeviceIsSharedToModal,
                    onUsersDeviceIsPending: _ctx.toggleUserDeviceRejectApproveShareModal,
                    onOpenDataModal: _ctx.openDataPreviewModal
                  }, null, 8, ["is-action-list-not-displayed", "is-data-source-loading", "is-users-devices-shared-to-displayed", "is-users-reject-approve-share-displayed", "options", "table-data", "table-headers", "onOpenGenericPacketModal", "onSelectedRows", "onActionClick", "onToggleStaticActionsModal", "onUsersDeviceIsSharedTo", "onUsersDeviceIsPending", "onOpenDataModal"]))
                : _createCommentVNode("", true)
            ]))
      ]),
      _: 1
    })
  ]))
}