<template>
  <modal-component
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    :title="$t('Devices.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="formData.name"
            :label="$t('Devices.Name')"
            :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
            :isError="v$?.formData['name']?.$error"
            :required="true"
          />
          <input-field
            v-model="formData.imei"
            :label="$t('Devices.Imei')"
            :errorMessage="v$?.formData['imei']?.$errors[0]?.$message"
            :isError="v$?.formData['imei']?.$error"
          />
          <input-field
            v-model="formData.mac"
            :label="$t('Devices.Mac')"
            :errorMessage="v$?.formData['mac']?.$errors[0]?.$message"
            :isError="v$?.formData['mac']?.$error"
          />
          <input-field
            v-model="formData.imsi"
            :label="$t('Devices.Imsi')"
            :errorMessage="v$?.formData['imsi']?.$errors[0]?.$message"
            :isError="v$?.formData['imsi']?.$error"
          />
          <input-field
            v-model="formData.externalId"
            :label="$t('Devices.ExternalId')"
          />
          <input-field
            v-model="formData.serialNumber"
            :label="$t('Devices.SerialNumber')"
          />
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Devices.DeviceTypeId")
              }}<span class="text-orange-600 pl-0.5">*</span></label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="formData.deviceTypeId"
                :class="`mt-1 w-full ${
                  v$?.formData['deviceTypeId']?.$error && 'error'
                }`"
                :options="deviceTypes"
                :reduce="(deviceType : any) => deviceType.id"
                label="name"
              ></v-select>
            </div>
            <span
              v-if="v$?.formData['deviceTypeId']?.$error"
              class="mt-2 text-sm text-error"
              >{{ v$?.formData["deviceTypeId"]?.$errors[0]?.$message }}
            </span>
          </div>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Devices.CompanyId") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="formData.companyId"
                :class="`mt-1 w-full`"
                :options="companies.items"
                :reduce="(company : any) => company.id"
                label="name"
              >
                <template #list-footer v-if="hasNextPage">
                  <div class="flex justify-center align-middle">
                    <div
                      class="mt-1 cursor-pointer bg-primary px-3 py-1 text-white transition duration-300 hover:bg-primaryhover disabled:cursor-not-allowed"
                      @click="loadMoreCompanies"
                    >
                      Load more...
                    </div>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
          <get-parent-device
            @parent-device-id="handleParentDeviceId"
            :current-device-id="formData.parentDeviceId"
          />
          <div class="py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Devices.Data") }}</label
            >
            <json-editor-vue
              v-model="formData.data"
              :main-menu-bar="false"
              :status-bar="false"
              :class="`mt-1 ${v$?.formData['data']?.$error && 'error'}`"
              mode="text"
            />
          </div>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import DeviceService, {
  Device,
  EditDevice,
} from "@/modules/Devices/Services/DeviceService";
import DeviceTypeService, {
  DeviceType,
} from "@/modules/DeviceTypes/Services/DeviceTypeService";
import CompanyService, {
  CompanyObject,
} from "@/modules/Companies/Services/CompanyService";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import VSelect from "vue-select";
//@ts-ignore
import JsonEditorVue from "json-editor-vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import {
  CheckObjectsForPatch,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import {
  maxLength,
  required,
  macAddress,
  numeric,
  minLength,
} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";
import GetParentDevice from "./GetParentDevice.vue";

export default defineComponent({
  components: {
    JsonEditorVue,
    ButtonWrapper,
    SmallLoader,
    InputField,
    VSelect,
    FormDivider,
    FormWrapper,
    ModalComponent,
    GetParentDevice,
  },
  props: {
    isEditModalOpened: Boolean,
    device: {
      type: Object as PropType<Device>,
      required: true,
    },
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      deviceService: new DeviceService(),
      deviceTypesService: new DeviceTypeService(),
      companyService: new CompanyService(),
      deviceTypes: [] as DeviceType[],
      companies: {} as CompanyObject,
      pageSize: 100,
      pageNumber: 1,
      hasNextPage: false,
      noSelectedCompany: false,
      initialObject: {} as EditDevice,
      toast: useToast(),
      isLoading: false,
      isSubmitLoading: false,
      formData: {
        id: 0,
        name: "",
        imei: null,
        mac: null,
        imsi: null,
        externalId: null,
        serialNumber: null,
        deviceTypeId: null,
        companyId: null,
        parentDeviceId: null,
        data: null,
      } as EditDevice,
      v$: useValidate() as any,
      validationErrors: [] as any,
    };
  },
  async created() {
    this.formData.id = this.device.id;
    this.formData.name = this.device.name;
    this.formData.imei = this.device.imei;
    this.formData.mac = this.device.mac;
    this.formData.imsi = this.device.imsi;
    this.formData.externalId = this.device.externalId;
    this.formData.serialNumber = this.device.serialNumber;
    this.formData.deviceTypeId = this.device.deviceTypeId;
    this.formData.companyId = this.device.companyId;
    this.formData.parentDeviceId = this.device.parentDeviceId;
    this.formData.data = this.device.data ?? {};

    this.initialObject = Object.assign({}, this.formData);

    this.isLoading = true;
    try {
      await Promise.all([this.getDeviceTypes(), this.getCompanies()]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        imei: {
          maxLength: maxLength(15),
          minLength: minLength(15),
          numeric,
        },
        mac: {
          macAddress: macAddress(":"),
        },
        imsi: {
          maxLength: maxLength(20),
          minLength: minLength(15),
          numeric,
        },
        deviceTypeId: {
          required,
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        if (
          Object.keys(this.formData.data).length == 0 ||
          this.formData.data == ""
        ) {
          this.formData.data = null;
        }
        try {
          await this.deviceService.editDevice({
            id: this.formData.id,
            device: await CheckObjectsForPatch({
              initialObject: this.initialObject,
              editedObject: this.formData,
            }),
          });
          this.toast.success(this.$t("General.EditedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    async loadMoreCompanies() {
      if (this.hasNextPage) {
        this.pageNumber += 1;
        try {
          const response = await this.companyService.getAllCompanies(
            this.pageNumber,
            this.pageSize
          );
          this.hasNextPage = response.hasNextPage;
          this.companies.items.push(...response.items);

          //because of pagination there is chance that selected company is not shown. User can't fetch
          //company by id if that's not his active company. Because of that we're checking with every load more
          //if selected company is fetched so that we can show company's name and not id in select box.
          if (this.noSelectedCompany) {
            let missingCompany = this.companies.items.filter(
              (company) => company.id == this.formData.companyId
            );

            if (missingCompany.length) {
              this.formData.companyId = null;
              setTimeout(() => {
                this.formData.companyId = missingCompany[0].id;
              }, 500);
              this.noSelectedCompany = false;
            }
          }
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }
    },
    async getDeviceTypes() {
      try {
        this.deviceTypes = await this.deviceTypesService.getDeviceTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getCompanies() {
      try {
        this.companies = await this.companyService.getAllCompanies(
          this.pageNumber,
          this.pageSize
        );
        this.hasNextPage = this.companies.hasNextPage;

        if (this.formData.companyId) {
          const existingCompany = this.companies.items.find(
            (company) => company.id === this.formData.companyId
          );

          if (!existingCompany) {
            this.noSelectedCompany = true;
          }
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    handleParentDeviceId(id: any) {
      this.formData.parentDeviceId = id;
    },
  },
});
</script>

<style scoped></style>
