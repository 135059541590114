<template>
  <!-- <div
    v-if="isLoadingOnModeChange"
    class="absolute z-[500] flex h-screen w-screen items-center justify-center align-middle"
  >
    <div class="h-screen w-screen bg-slate-500 opacity-50">
    </div>
    <Loader />
  </div> -->
  <div>
    <report-modal
      v-if="isReportModalOpened"
      :device-name="selectedDevice!.assignment!.name"
      :device-uuid="selectedDevice!.device!.deviceUuid"
      :have-modal="true"
      :is-report-modal-opened="isReportModalOpened"
      @toggle-modal="isReportModalOpened = false"
    />
    <static-actions-modal
      v-if="isStaticActionsModalOpened"
      :device="selectedDevice"
      :is-static-actions-modal-opened="isStaticActionsModalOpened"
      @toggle-modal="toggleStaticActionsModal"
    />
    <modal
      :have-modal="true"
      :is-displayed="isDetailsModalOpened"
      :is-select-modal="true"
      :need-for-scroll="true"
      :title="`${selectedDevice?.assignment?.name} details`"
      @toggle-modal="toggleViewDetailsModal"
    >
      <details-modal
        :assignment-id="selectedDevice!.assignmentId"
        :have-modal="true"
      />
    </modal>
    <q-r-code-modal
      v-if="isQrCodeModalOpened"
      :device-details="selectedDevice"
      :have-modal="true"
      :is-qr-modal-opened="isQrCodeModalOpened"
      @toggle-modal="isQrCodeModalOpened = false"
    />
    <modal
      v-if="isSentDataModalOpened"
      :have-modal="true"
      :is-full-screen-modal="true"
      :is-displayed="isSentDataModalOpened"
      :is-select-modal="true"
      :need-for-scroll="true"
      :remove-outside-click="true"
      :title="`Device data sent by ${selectedDevice!.assignment!.name}`"
      @toggle-modal="toggleDataSentModal"
    >
      <sent-data :dataUuid="selectedDevice!.assignment?.dataUuid" />
    </modal>
    <change-company-modal
      v-if="isChangeCompanyModalOpened"
      :assignment-id="selectedDevice!.assignmentId"
      :close-modal="toggleMoveDeviceToNewCompanyModal"
      :have-modal="true"
      :is-for-multiple-devices="isMoveForMultipleDevices"
      :is-move-to-new-company-modal-opened="isChangeCompanyModalOpened"
      :refresh-data="async () => await changeTab(selectedTab)"
      @multiple-move="getCompanyIdForMovingDevices"
      @toggle-modal="isChangeCompanyModalOpened = false"
    />
    <developer-options-modal
      v-if="isDeveloperOptionsModalOpened"
      :have-modal="true"
      :selected-device="selectedDevice"
      @toggle-modal="isDeveloperOptionsModalOpened = false"
    />
    <change-group-modal
      v-if="isChangeApplicationOpened"
      :application-id="selectedDevice?.assignment?.applicationId"
      :applications="applications"
      :have-modal="true"
      :is-change-application-opened="isChangeApplicationOpened"
      :is-form-in-modal="true"
      :is-loading="isApplicationLoading"
      :is-submit-loading="isSubmitLoading"
      @toggle-modal="isChangeApplicationOpened = false"
      @on-submit="changeDeviceApplication"
    />
    <change-ownership-modal
      v-if="isChangeOwnershipModalOpened"
      :current-owner-id="selectedDevice?.assignment?.userId"
      :selected-asset-id="selectedDevice.assignmentId"
      :is-change-ownership-opened="isChangeOwnershipModalOpened"
      @toggle-modal="isChangeOwnershipModalOpened = false"
      @refresh="async () => await changeTab(selectedTab)"
    />
    <delete-modal
      :deviceRemove="deviceRemove"
      :have-modal="true"
      :is-deleting="isDeleting"
      :is-submit-loading="isDataSourceLoading"
      :selectedData="selectedDevice"
      delete-text="Are you sure you want to remove asset "
      @toggle-modal="toggleDeleteModal"
      @cancel-delete="toggleDeleteModal"
      @confirm-remove="openConfirmationModal('remove')"
      @confirm-unassign="openConfirmationModal('unassign')"
      @confirm-delete="openConfirmationModal('delete')"
    />
    <confirmation-modal
      :delete-text="confirmationModalText"
      :is-confirmation-modal-opened="isConfirmationModalOpened"
      :number-of-devices="selectedRows.length"
      @confirm-action="confirmationModalAction"
      @close-confirmation-modal="isConfirmationModalOpened = false"
      @toggle-modal="isConfirmationModalOpened = false"
    />
    <share-device-modal
      :device-data="selectedDevice"
      :have-modal="true"
      :is-share-modal-opened="isShareModalOpened"
      :is-submit-loading="isSubmitLoading"
      @toggle-modal="isShareModalOpened = false"
      @on-submit="shareDevice"
    />

    <last-data-preview
      v-if="isLastDataModalOpened"
      :data-uuid="selectedDevice?.assignment?.dataUuid"
      :have-modal="true"
      :is-last-data-modal-opened="isLastDataModalOpened"
      :getLastData="true"
      :name="selectedDevice?.assignment?.name"
      @toggle-modal="isLastDataModalOpened = false"
    />
    <edit-form
      v-if="isEditModalOpened"
      :have-modal="true"
      :is-edit-modal-opened="isEditModalOpened"
      :is-form-in-modal="true"
      :object-id="selectedDevice!.assignmentId"
      :refreshData="async () => await changeTab(selectedTab)"
      @toggle-modal="toggleEditModal"
    />

    <create-form
      v-if="isCreateModalOpened"
      :is-create-modal-opened="isCreateModalOpened"
      :is-form-in-modal="true"
      :refreshData="async () => await changeTab(selectedTab)"
      @toggle-modal="toggleCreateModal"
    />
    <live-data-modal
      v-if="isLiveDataModalOpened"
      :device="selectedDevice"
      :have-modal="true"
      :is-live-data-modal-opened="isLiveDataModalOpened"
      @toggle-modal="toggleLiveDataModal"
    />

    <users-device-is-shared-to
      v-if="isUsersDevicesSharedToModalOpened"
      :assignment-id="selectedAssignmentId"
      :is-users-devices-shared-to-modal-displayed="
        isUsersDevicesSharedToModalOpened
      "
      @toggle-modal="isUsersDevicesSharedToModalOpened = false"
      @refresh-data="async () => await changeTab(selectedTab)"
    />

    <reject-approve-share
      v-if="isUsersRejectApproveShareModalOpened"
      :assignment-id="selectedAssignmentId"
      :is-users-reject-approve-share-modal-opened="
        isUsersRejectApproveShareModalOpened
      "
      @toggle-modal="isUsersRejectApproveShareModalOpened = false"
      @refresh-data="async () => await changeTab(selectedTab)"
    />

    <generic-packet
      v-if="isGenericPacketModalOpened"
      :device-uuid="genericPacketUuid"
      :removeEdit="removeEdit"
      @closeModal="closeGenericPacketModal"
    />

    <create-relation-form
      v-if="isCreateRelationModalOpened"
      :assignment-id-list="assignmentIdList"
      :is-create-relation-modal-opened="isCreateRelationModalOpened"
      :refresh-data="getDeviceAssignments"
      :table-data="deviceAssignments"
      @toggle-modal="isCreateRelationModalOpened = false"
    />

    <last-data-preview
      v-if="isDataPreviewModalOpened"
      :data="selectedMetadata"
      :is-last-data-modal-opened="isDataPreviewModalOpened"
      :name="selectedDevice.assignment?.name"
      :metadata="true"
      @toggle-modal="isDataPreviewModalOpened = false"
    />

    <delete-relation-modal
      v-if="isDeleteRelationModalOpened"
      :assignment-id="selectedDevice.assignmentId ?? 0"
      :is-delete-relation-modal-opened="isDeleteRelationModalOpened"
      :metadata="selectedDevice?.assignment?.metadata"
      :refresh-data="async () => await changeTab(selectedTab)"
      @toggle-modal="isDeleteRelationModalOpened = false"
    />

    <main-section>
      <table-action-header :title="'Assets'" description=" " />
      <loader
        v-if="isLoading"
        inner-ring-thickness="border-t-8"
        outer-ring-thickness="border-8"
      />
      <div v-else class="space-y-4">
        <div class="mb-4 flex items-center justify-between">
          <tabs-component :tabs="tabs" @change-tab="changeTab" />
          <div class="flex items-center gap-4">
            <the-button
              v-if="selectedTab == 'MyDevices' || selectedTab == 'Company'"
              text="Apply metadata"
              :is-disabled="!isApplyButtonEnabled"
              :variant="isApplyButtonEnabled ? 'default' : 'ghost'"
              @execute-method="toggleCreateRelationModal"
            />
            <the-button
              v-if="
                permissionStore.hasPermission(permissions.CreateAssignment) &&
                (selectedTab == 'MyDevices' || selectedTab == 'Company')
              "
              :icon="['fas', 'plus']"
              buttonType="primaryButton"
              text="Create"
              @execute-method="createDevice"
            />
          </div>
        </div>

        <custom-table
          v-if="isTableLoaded"
          :is-action-list-not-displayed="
            isUsersDevicesSharedToDisplayed ||
            isUsersRejectApproveShareDisplayed
          "
          :is-data-source-loading="isDataSourceLoading"
          :is-devices-table="true"
          :is-users-devices-shared-to-displayed="
            isUsersDevicesSharedToDisplayed
          "
          :is-users-reject-approve-share-displayed="
            isUsersRejectApproveShareDisplayed
          "
          :multiselect="true"
          :options="options"
          :show-device-actions="false"
          :table-data="deviceAssignments"
          :table-headers="tableHeaders"
          @openGenericPacketModal="openGenericPacketModal"
          @selected-rows="getSelectedRows"
          @action-click="multipleItemAction"
          @approve-share-request="openConfirmationModal('approve')"
          @reject-share-request="openConfirmationModal('reject')"
          @remove-share="openConfirmationModal('revoke')"
          @toggle-static-actions-modal="toggleStaticActionsModal"
          @force-refresh="async () => await changeTab(selectedTab)"
          @users-device-is-shared-to="toggleUsersDeviceIsSharedToModal"
          @users-device-is-pending="toggleUserDeviceRejectApproveShareModal"
          @open-data-modal="openDataPreviewModal"
        />
      </div>
    </main-section>
  </div>
</template>
<script lang="ts">
import MainSection from "@/core/Components/MainSection.vue";
import { defineComponent } from "vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import Loader from "@/core/Components/Loader.vue";
import TheButton from "@/core/Components/TheButton.vue";
import {
  CheckIfThereIsError,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import { useUserStore } from "@/core/Store/userStore";
import Modal from "@/core/Components/Modal.vue";
import EditForm from "@/modules/Devices/Submodules/Assignments/Components/EditForm.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ApplicationService, {
  Application,
} from "@/modules/Applications/Services/ApplicationService";
import UserService from "@/modules/Users/Services/UserService";
import ShareDeviceModal from "@/modules/Devices/Submodules/Assignments/Components/ShareDeviceModal.vue";
import ChangeGroupModal from "@/modules/Devices/Submodules/Assignments/Components/ChangeGroupModal.vue";
import LastDataPreview from "@/modules/Devices/Submodules/Assignments/Components/LastDataPreview.vue";
import DeveloperOptionsModal from "@/modules/Devices/Submodules/Assignments/Components/DeveloperOptionsModal.vue";
import TabsComponent from "@/core/Components/TabsComponent.vue";
import CreateForm from "@/modules/Devices/Submodules/Assignments/Components/CreateForm.vue";
import DetailsModal from "@/modules/Devices/Submodules/Assignments/Components/DetailsModal.vue";
import SentData from "@/modules/Devices/Submodules/Assignments/Components/SentData.vue";
import QRCodeModal from "@/modules/Devices/Submodules/Assignments/Components/QRCodeModal.vue";
import SpotiumService from "@/core/Services/SpotiumService";
import ChangeCompanyModal from "@/modules/Devices/Submodules/Assignments/Components/ChangeCompanyModal.vue";
import LiveDataModal from "@/modules/Devices/Submodules/Assignments/Components/LiveDataModal.vue";
import StaticActionsModal from "@/modules/Devices/Submodules/Assignments/Components/StaticActionsModal.vue";
import { useToast } from "vue-toastification";
import DevExpress from "devextreme";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import ReportModal from "@/modules/Devices/Submodules/Assignments/Components/ReportModal.vue";
import AssignmentService, {
  DeviceAssignment,
  TypeOfAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import UsersDeviceIsSharedTo from "@/modules/Devices/Submodules/Assignments/Components/UsersDeviceIsSharedTo.vue";
import RejectApproveShare from "@/modules/Devices/Submodules/Assignments/Components/RejectApproveShare.vue";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { useModuleToggleStore } from "@/core/Store/moduleToggleStore";
import GenericPacket from "@/modules/GenericPacket/Pages/GenericPacket.vue";
import CreateRelationForm from "@/modules/Devices/Submodules/Assignments/Components/CreateRelationForm.vue";
import { AssignmentMetadata } from "@/modules/Devices/Submodules/AssignmentMetadata/Services/AssignmentMetadataTypes";
import DeleteRelationModal from "@/modules/Devices/Submodules/Assignments/Components/DeleteRelationModal.vue";
import ItemClickEvent = DevExpress.ui.dxAccordion.ItemClickEvent;
import { Permissions } from "@/core/Services/Enums";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import ChangeOwnershipModal from "../Components/ChangeOwnershipModal.vue";

export default defineComponent({
  components: {
    DeleteRelationModal,
    CreateRelationForm,
    UsersDeviceIsSharedTo,
    ReportModal,
    StaticActionsModal,
    LiveDataModal,
    ChangeCompanyModal,
    QRCodeModal,
    MainSection,
    CustomTable,
    Loader,
    TheButton,
    ConfirmationModal,
    Modal,
    EditForm,
    DeleteModal,
    ShareDeviceModal,
    ChangeGroupModal,
    LastDataPreview,
    DeveloperOptionsModal,
    TabsComponent,
    CreateForm,
    DetailsModal,
    SentData,
    RejectApproveShare,
    GenericPacket,
    TableActionHeader,
    ChangeOwnershipModal,
  },
  inject: ["user"],
  data() {
    return {
      userStore: useUserStore(),
      moduleToggleStore: useModuleToggleStore(),
      changeCompanyStore: useChangeCompanyStore(),
      assignmentService: new AssignmentService(),
      applicationService: new ApplicationService(),
      userService: new UserService(),
      toast: useToast(),
      permissionStore: usePermissionStore(),
      spotiumService: new SpotiumService(),
      deviceAssignments: [] as DeviceAssignment[],
      index: 1 as number,
      selectedAssignmentId: 0 as number,
      permissions: Permissions,
      isLoading: false as boolean,
      isUsersDevicesSharedToModalOpened: false,
      isApplyButtonEnabled: false,
      isUsersDevicesSharedToDisplayed: false,
      isUsersRejectApproveShareDisplayed: false,
      isUsersRejectApproveShareModalOpened: false,
      isSubmitLoading: false,
      isApplicationLoading: false,
      userId: 0 as number,
      isDeleting: false,
      isCreateRelationModalOpened: false,
      deviceRemove: false,
      isEditModalOpened: false,
      isShareModalOpened: false,
      isCreateModalOpened: false,
      isChangeApplicationOpened: false,
      isConfirmationModalOpened: false as boolean,
      isDetailsModalOpened: false,
      isChangeCompanyModalOpened: false,
      isDeveloperOptionsModalOpened: false,
      isLiveDataModalOpened: false,
      isLastDataModalOpened: false,
      isQrCodeModalOpened: false,
      isReportModalOpened: false,
      isStaticActionsModalOpened: false,
      isGenericPacketModalOpened: false,
      isDataPreviewModalOpened: false,
      isDeleteRelationModalOpened: false,
      isChangeOwnershipModalOpened: false,
      genericPacketUuid: "",
      selectedCompanyId: 0,
      confirmationModalText: "" as string,
      selectedRows: [] as DeviceAssignment[],
      selectedDevice: {} as DeviceAssignment,
      selectedMetadata: [] as AssignmentMetadata[],
      isMoveForMultipleDevices: false,
      isDataSourceLoading: false,
      routeName: "deviceDetails",
      selectedTab: "Company" as TypeOfAssignment,
      applications: [] as Application[],
      isSentDataModalOpened: false,
      tableHeaders: {
        assignmentId: { header: "ID", visible: false },
        "device.deviceUuid": { header: "UUID", visible: false },
        "assignment.name": { header: "Name", visible: true },
        "device.imei": { header: "IMEI", visible: true },
        "device.mac": { header: "MAC", visible: true },
        "assignment.application.name": { header: "Group", visible: true },
        lastTimeDeviceSentData: { header: "Last data sent", visible: true },
        "device.deviceType.name": {
          header: "Device type",
          visible: true,
        },
        "assignment.metadata": { header: "Metadata", visible: true },
      } as Record<
        string,
        { header: string; visible: boolean; sortOrder?: "asc" | "desc" }
      >,
      sharedDevicesHeaders: {
        id: { header: "ID", visible: false },
        userEmail: { header: "Email", visible: true },
        username: { header: "Username", visible: true },
        deviceName: { header: "Device", visible: true },
        deviceTypeName: { header: "Device type", visible: true },
        deviceUuid: { header: "UUID", visible: false },
        from: { header: "From", visible: true },
        to: { header: "To", visible: true },
        createdAt: { header: "Share requested at", visible: true },
      },
      customHeader: {
        id: { header: "ID", visible: false },
        uuid: { header: "UUID", visible: false },
        name: { header: "Name", visible: true },
        imei: { header: "IMEI", visible: true },
        mac: { header: "MAC", visible: true },
      },
      options: {
        view: {
          header: "View",
          icon: ["fas", "eye"],
          action: this.toggleViewDetailsModal,
        },
        edit: {
          header: "Edit",
          icon: ["fas", "pen"],
          action: this.toggleEditModal,
        },
        remove: {
          header: "Remove",
          icon: ["fas", "user-slash"],
          action: this.toggleRemoveModal,
        },
        report: {
          header: "Reports",
          icon: ["fas", "file-lines"],
          action: this.toggleReportModal,
        },
        removeRelation: {
          header: "Remove metadata relation",
          icon: ["fas", "link-slash"],
          action: this.toggleDeleteRelationModal,
        },
        changeGroup: {
          header: "Change group",
          icon: ["fas", "repeat"],
          action: this.toggleChangeApplicationModal,
        },
        shareDevice: {
          header: "Share device",
          icon: ["fas", "share-from-square"],
          action: this.toggleShareModal,
        },
        lastDataPreview: {
          header: "View latest data",
          icon: ["fas", "database"],
          action: this.toggleLastDataModal,
        },
        deviceData: {
          header: "View all data",
          icon: ["fas", "calendar"],
          action: this.toggleDataSentModal,
        },
        developerOptions: {
          header: "Developer options",
          icon: ["fas", "code"],
          action: this.toggleDeveloperOptionsModal,
        },
        qr: {
          header: "QR code",
          icon: ["fas", "qrcode"],
          action: this.toggleQrCodeModal,
        },
        liveData: {
          header: "Live data",
          icon: ["fas", "database"],
          action: this.toggleLiveDataModal,
        },
        changeCompany: {
          header: "Move to new company",
          icon: ["fas", "building-circle-arrow-right"],
          action: this.toggleMoveDeviceToNewCompanyModal,
        },
        changeOwnership: {
          header: "Change ownership",
          icon: ["fac", "user-device"],
          action: this.toggleChangeOwnershipModal,
        },
      },
      tabs: [
        { id: "MyDevices", label: "My assets" },
        { id: "Company", label: "Company assets", default: true },
        { id: "SharedToMe", label: "Assets shared to me" },
        { id: "SharedByMe", label: "Assets shared by me" },
        { id: "SharedByMePending", label: "Pending assets shared by me" },
      ] as { id: TypeOfAssignment; label: string; default?: boolean }[],
      isTableLoaded: true,
      removeEdit: {} as any,
    };
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.changeTab(this.selectedTab);
    },
    async "moduleToggleStore.isDevelopment"(value) {
      try {
        this.toggleDevelopmentMode(value);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },

  async created() {
    this.userId = this.userStore.userId;
    this.isLoading = true;
    try {
      await this.changeTab(this.tabs.filter((tab) => tab.default)[0].id);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
    this.toggleDevelopmentMode(this.moduleToggleStore.isDevelopment);
  },
  computed: {
    assignmentIdList(): number[] {
      return this.selectedRows
        .map((assignment) => assignment.assignmentId)
        .filter((item) => item !== null) as number[];
    },
  },

  methods: {
    checkIfThereIsError: CheckIfThereIsError,

    async getDeviceAssignments(typeOfAssignment: TypeOfAssignment) {
      this.isDataSourceLoading = true;
      try {
        this.deviceAssignments =
          await this.assignmentService.getAllDeviceAssignments({
            typeOfAssignment: typeOfAssignment,
            pageNumber: 1,
            pageSize: 100000,
            lastDataSent: true,
          });

        /* if (!this.moduleToggleStore.isDevelopment) {
          this.sortDeviceByAssignmentID();
        } */
        if (!this.moduleToggleStore.isDevelopment) {
          this.sortDeviceByAssignmentName();
        }

        this.isCreateModalOpened = false;
        this.isEditModalOpened = false;
        this.isChangeApplicationOpened = false;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },

    confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          break;
        case "remove":
          this.removeDeviceAssignment();
          break;
        case "revoke":
          this.removeShare();
          break;
        case "unassign":
          this.unassignDeviceFromUser();
          break;
      }
      this.isConfirmationModalOpened = false;
    },

    async removeShare() {
      // try {
      //   await this.spotiumService.revokeShareFromUser(this.selectedDeviceAssignmentId);
      //   this.toast.success("Share removed successfully!");
      //   await this.changeTab(this.selectedTab);
      // } catch (error) {
      //   this.toast.error(ErrorMessageFormatter(error))
      // } finally {
      // }
    },

    async moveMultipleDevicesToCompany() {
      // let promises: Array<Promise<any>> = [];
      // this.isDataSourceLoading = true;
      // try {
      //   this.selectedRows.forEach((device: Device) => {
      //     if (!device.deviceAssignmentId) {
      //       return;
      //     }
      //     promises.push(this.spotiumService.moveDeviceToNewCompany(this.selectedCompanyId, device.deviceAssignmentId));
      //   });
      //   this.isChangeCompanyModalOpened = false;
      //   await Promise.all(promises);
      //   this.toast.success("Devices moved successfully!");
      // } catch (error) {
      //   this.toast.error(ErrorMessageFormatter(error))
      // } finally {
      //   await this.changeTab(this.selectedTab);
      //   this.isDataSourceLoading = false;
      // }
    },

    async unassignDeviceFromUser() {},

    async removeDeviceAssignment() {
      this.isDataSourceLoading = true;
      try {
        if (!this.selectedDevice.assignmentId) {
          throw new Error("Assignment id is not defined!");
        }
        await this.assignmentService.deleteDeviceAssignment({
          id: this.selectedDevice.assignmentId,
        });
        this.toast.success("Device successfully removed!");
        this.isDeleting = false;
        await this.changeTab(this.selectedTab);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async shareDevice(deviceShareData: {
      assignmentId: number;
      userEmail: string;
    }) {
      this.isSubmitLoading = true;
      try {
        const response = await this.spotiumService.shareDeviceToUser({
          shareData: {
            userId: this.userId,
            userEmail: deviceShareData.userEmail,
          },
          assignmentId: deviceShareData.assignmentId,
        });
        if (response.status === "Pending") {
          throw new Error("Share status is pending!");
        }
        this.toast.success("Asset successfully shared!");
        this.isShareModalOpened = false;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },

    async changeDeviceApplication(value: any) {
      this.isSubmitLoading = true;
      this.isDataSourceLoading = true;
      try {
        await this.assignmentService.editDeviceAssignment({
          deviceAssignment: value,
          id: this.selectedDevice.assignmentId ?? 0,
        });
        this.toast.success("Asset group successfully changed!");
        this.isChangeApplicationOpened = false;
        await this.changeTab(this.selectedTab);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
        this.isDataSourceLoading = false;
      }
    },

    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },

    toggleUsersDeviceIsSharedToModal(assignmentId: number) {
      this.selectedAssignmentId = assignmentId;
      this.isUsersDevicesSharedToModalOpened =
        !this.isUsersDevicesSharedToModalOpened;
    },

    toggleUserDeviceRejectApproveShareModal(assignmentId: number) {
      this.selectedAssignmentId = assignmentId;
      this.isUsersRejectApproveShareModalOpened =
        !this.isUsersRejectApproveShareModalOpened;
    },

    createDevice() {
      this.toggleCreateModal();
    },

    getSelectedRows(values: DeviceAssignment[]) {
      this.selectedRows = values;
      if (values.length) {
        this.selectedDevice = values[0];
        this.isApplyButtonEnabled = values.every((item) =>
          item.assignment?.permissions.some(
            (permission) => permission.id === Permissions.EditAssignment
          )
        );
      } else this.isApplyButtonEnabled = false;
    },

    toggleDeleteModal() {
      this.isDeleting = !this.isDeleting;
    },
    toggleRemoveModal() {
      this.isDeleting = !this.isDeleting;
      this.deviceRemove = true;
    },
    toggleShareModal() {
      this.isShareModalOpened = !this.isShareModalOpened;
    },
    async toggleChangeApplicationModal() {
      this.isChangeApplicationOpened = !this.isChangeApplicationOpened;
      if (this.isChangeApplicationOpened) {
        this.isApplicationLoading = true;
        try {
          const response = await this.applicationService.getAllApplications(
            1,
            10000
          );
          this.applications = response.items;
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isApplicationLoading = false;
        }
      }
    },

    toggleViewDetailsModal() {
      this.isDetailsModalOpened = !this.isDetailsModalOpened;
    },
    toggleLastDataModal() {
      this.isLastDataModalOpened = !this.isLastDataModalOpened;
    },
    toggleDeveloperOptionsModal() {
      this.isDeveloperOptionsModalOpened = !this.isDeveloperOptionsModalOpened;
    },
    toggleCreateRelationModal() {
      this.isCreateRelationModalOpened = !this.isCreateRelationModalOpened;
    },

    async changeTab(tabId: TypeOfAssignment) {
      this.isUsersDevicesSharedToDisplayed = tabId === "SharedByMe";
      this.isUsersRejectApproveShareDisplayed = tabId === "SharedByMePending";
      this.selectedTab = tabId;
      await this.getDeviceAssignments(tabId);
    },

    multipleItemAction(ev: ItemClickEvent) {
      switch (ev.itemData.id) {
        case "delete":
          this.openConfirmationModal("multipleDelete");
          break;
        case "moveCompany":
          this.isChangeCompanyModalOpened = true;
          this.isMoveForMultipleDevices = true;
          break;
      }
    },

    async getCompanyIdForMovingDevices(selectedCompanyId: number) {
      this.selectedCompanyId = selectedCompanyId;
      await this.moveMultipleDevicesToCompany();
      this.isMoveForMultipleDevices = false;
    },

    toggleDataSentModal() {
      this.isSentDataModalOpened = !this.isSentDataModalOpened;
    },

    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },

    toggleQrCodeModal() {
      this.isQrCodeModalOpened = !this.isQrCodeModalOpened;
    },
    toggleReportModal() {
      this.isReportModalOpened = !this.isReportModalOpened;
    },
    toggleMoveDeviceToNewCompanyModal() {
      this.isChangeCompanyModalOpened = !this.isChangeCompanyModalOpened;
    },
    toggleLiveDataModal() {
      this.isLiveDataModalOpened = !this.isLiveDataModalOpened;
    },
    toggleStaticActionsModal() {
      this.isStaticActionsModalOpened = !this.isStaticActionsModalOpened;
    },
    toggleDeleteRelationModal() {
      this.isDeleteRelationModalOpened = !this.isDeleteRelationModalOpened;
    },
    toggleChangeOwnershipModal() {
      if (this.selectedDevice.assignment?.userId == this.userId) {
        this.isChangeOwnershipModalOpened = !this.isChangeOwnershipModalOpened;
      } else {
        this.toast.error("Ownership change limited to current owner.");
      }
    },
    toggleDevelopmentMode(value: boolean) {
      try {
        if (!value) {
          this.tableHeaders["device.imei"].visible = false;
          this.tableHeaders["assignment.application.name"].visible = false;
          //FIXANO
          //this.tableHeaders["device.deviceType.name"].visible = false;
        } else {
          this.tableHeaders["device.imei"].visible = true;
          this.tableHeaders["assignment.application.name"].visible = true;
          this.tableHeaders["device.deviceType.name"].visible = true;
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    openGenericPacketModal(data: { data: any; removeEdit: any }) {
      this.isGenericPacketModalOpened = true;
      this.genericPacketUuid = data.data.assignment.dataUuid;
      this.removeEdit = data.removeEdit;
    },
    closeGenericPacketModal(check: any) {
      if (check === true) {
        this.isGenericPacketModalOpened = false;
      }
    },

    sortDeviceByAssignmentID() {
      this.tableHeaders["assignment.id"].sortOrder = "desc";
    },

    sortDeviceByAssignmentName() {
      /* this.deviceAssignments.sort((a: any, b: any) => {
        console.log("A", a.assignment.name, " B", b.assignment.name);

        return a.assignment.name.localeCompare(b.assignment.name);
      }); */
      this.tableHeaders["assignment.name"].sortOrder = "asc";
    },

    openDataPreviewModal(metadata: AssignmentMetadata[]) {
      this.selectedMetadata = metadata;
      this.isDataPreviewModalOpened = true;
    },
  },
});
</script>
