<template>
  <modal-component
    :is-displayed="isCreateRelationModalOpened"
    :no-padding="true"
    title="Create device metadata relation"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Device metadata</label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="deviceMetadataId"
                :class="`mt-1 w-full`"
                :options="paginatedDeviceMetadata.items"
                :reduce="(metadata : any) => metadata.id"
                label="name"
                :clearable="false"
                @input="handleDeviceMetadataSearch"
                @close="handleDropdownClose"
              >
                <template #list-footer>
                  <div
                    v-if="paginatedDeviceMetadata.hasNextPage"
                    class="flex justify-center align-middle"
                  >
                    <button
                      type="button"
                      class="mt-1 cursor-pointer bg-primary px-3 py-1 text-white transition duration-300 hover:bg-primaryhover disabled:cursor-not-allowed"
                      @click="loadMoreDeviceMetadata"
                    >
                      Load more...
                    </button>
                  </div>
                </template>
              </v-select>
            </div>
          </div>
        </form-divider>
        <button-wrapper />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { DeviceMetadataService } from "@/modules/Production/Submodules/DeviceMetadata/Services/DeviceMetadataService";
import { Device } from "@/modules/Devices/Services/DeviceService";
import {
  DeviceMetadata,
  DeviceMetadataObject,
} from "@/modules/Production/Submodules/DeviceMetadata/Services/DeviceMetadataTypes";

export default defineComponent({
  components: {
    SmallLoader,
    VSelect,
    ButtonWrapper,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isCreateRelationModalOpened: Boolean,
    deviceIdList: {
      type: Array as PropType<number[]>,
      required: true,
    },
    refreshData: Function,
    tableData: {
      type: Array as PropType<Device[]>,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      deviceMetadataService: new DeviceMetadataService(),
      deviceMetadata: [] as DeviceMetadata[],
      paginatedDeviceMetadata: {
        items: [],
        pageIndex: 0,
        totalPages: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      } as DeviceMetadataObject,
      toast: useToast(),
      deviceMetadataId: null as number | null,
      pageSize: 25,
      pageNumber: 1,
      searchDeviceMetadataText: "",
      searchTimeoutId: undefined as any,
      isLoading: false,
      isSubmitLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getDeviceMetadata();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async handleSubmit() {
      if (!this.deviceMetadataId) {
        this.toast.error("Asset metadata id is not set!");
        return;
      }

      this.isSubmitLoading = true;

      this.tableData.forEach((element: Device) => {
        this.deviceIdList.forEach((newItemInList: number, index: number) => {
          if (
            element.metadata &&
            element.metadata.length > 0 &&
            element.metadata[0].id &&
            element.metadata[0].id === this.deviceMetadataId &&
            element.id &&
            newItemInList === element.id
          ) {
            this.deviceIdList.splice(index, 1);
          }
        });
      });

      if (this.deviceIdList.length > 0) {
        try {
          await this.deviceMetadataService.createDeviceMetadataRelation({
            deviceMetadataId: this.deviceMetadataId,
            deviceIdList: this.deviceIdList,
          });
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      } else {
        this.toast.error("Selected asset metadata is already applied!");
      }
    },
    async getDeviceMetadata() {
      try {
        this.paginatedDeviceMetadata =
          await this.deviceMetadataService.getDeviceMetadata(
            this.pageNumber,
            this.pageSize
          );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }

      this.addMissingDeviceMetadata();
    },
    async loadMoreDeviceMetadata() {
      if (this.paginatedDeviceMetadata.hasNextPage) {
        this.pageNumber += 1;
        try {
          const response = await this.deviceMetadataService.getDeviceMetadata(
            this.pageNumber,
            this.pageSize,
            this.searchDeviceMetadataText
          );

          this.paginatedDeviceMetadata.items.push(...response.items);
          this.paginatedDeviceMetadata.hasNextPage = response.hasNextPage;
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }
    },
    async addMissingDeviceMetadata() {
      if (this.deviceMetadataId) {
        const existingDeviceMetadata = this.paginatedDeviceMetadata.items.find(
          (deviceMetadata) => deviceMetadata.id === this.deviceMetadataId
        );

        if (!existingDeviceMetadata) {
          try {
            const deviceMetadata =
              await this.deviceMetadataService.getDeviceMetadataWithIdentifier(
                this.deviceMetadataId!
              );

            this.paginatedDeviceMetadata.items.unshift(deviceMetadata);

            this.deviceMetadataId = null;
            setTimeout(() => {
              this.deviceMetadataId = deviceMetadata.id;
            }, 100);
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }
        }
      }
    },
    async handleDeviceMetadataSearch(e: any) {
      this.searchDeviceMetadataText = e.target.value;
      this.pageNumber = 1;

      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }

      this.searchTimeoutId = setTimeout(async () => {
        try {
          const response = await this.deviceMetadataService.getDeviceMetadata(
            this.pageNumber,
            this.pageSize,
            this.searchDeviceMetadataText
          );
          this.paginatedDeviceMetadata = response;
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }, 800);
    },
    async handleDropdownClose() {
      this.pageNumber = 1;
      this.searchDeviceMetadataText = "";
      this.getDeviceMetadata();
    },
  },
});
</script>

<style scoped></style>
