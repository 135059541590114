import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import {
  StoredFile,
  StoredFilesObject,
} from "@/modules/Production/Submodules/FirmwareUpdate/Services/FirmwareUpdateTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class FirmwareUpdateService {
  async getFirmwareUpdateList(
    pageNumber: number = 1,
    pageSize: number = 10
  ): Promise<StoredFilesObject> {
    const url = GenerateUrl({
      path: "/api/stored-files",
      prefixPath: ConfigurationService.configData.FIRMWARE_UPDATE_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

    const response = await ApiService.get(url);

    return response.data;
  }

  async downloadFirmwareFile(fileName: string) {
    const url = GenerateUrl({
      path: `/api/stored-files/${fileName}`,
      prefixPath: ConfigurationService.configData.FIRMWARE_UPDATE_PREFIX_PATH,
    });

    const response = await ApiService.get(url);

    const blob = new Blob([response.data]);
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async uploadFirmwareFile(file: File) {
    const url = GenerateUrl({
      path: "/api/stored-files",
      prefixPath: ConfigurationService.configData.FIRMWARE_UPDATE_PREFIX_PATH,
    });

    const formData = new FormData();
    formData.append("file", file);

    const response = await ApiService.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  }
}
