import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "remove-outside-click": true,
    "is-displayed": _ctx.isModalOpened,
    title: _ctx.$t('ResponseMessages.Messages'),
    onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_custom_table, {
              "is-data-source-loading": _ctx.isDataSourceLoading,
              "is-action-list-not-displayed": true,
              "no-selection": true,
              "table-data": _ctx.paginatedAssetResponseMessages.items,
              "table-headers": _ctx.tableHeaders,
              onForceRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getAllAssetResponseMessages()))
            }, null, 8, ["is-data-source-loading", "table-data", "table-headers"]),
            _createVNode(_component_pagination, {
              "pagination-data": _ctx.paginatedAssetResponseMessages,
              onPageChange: _ctx.handlePageChange,
              onPageSizeChange: _ctx.handlePageSizeChange
            }, null, 8, ["pagination-data", "onPageChange", "onPageSizeChange"])
          ]))
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}