import ApiService from "@/core/Axios/ApiService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ConfigurationService from "./ConfigurationService";

export type ShareDevice = {
  userId?: number;
  userEmail: string;
};

export default class SpotiumService {
  async createUserWithEmail(email: string, companyId: number): Promise<string> {
    const response = await ApiService.post(
      `${ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH}/api/companies/${companyId}/user`,
      {
        userEmail: email,
        companyId: companyId,
      }
    );
    return String(response.data);
  }

  async addUserToCompany(
    userEmail: string,
    companyId: number
  ): Promise<number> {
    const response = await ApiService.post(
      `${ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH}/api/companies/${companyId}/user`,
      {
        userEmail,
        companyId,
      }
    );

    return response.data;
  }

  async assignDeviceToUser(
    deviceUuid: string,
    userId: number,
    applicationId: number
  ): Promise<any> {
    return await ApiService.post(
      `${ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH}/api/device-assignments/${deviceUuid}/assign`,
      {
        userId,
        applicationId,
      }
    );
  }

  async moveDeviceToNewCompany({
    deviceAssignmentId,
    companyId,
  }: {
    deviceAssignmentId: number;
    companyId: number;
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/assignments/${deviceAssignmentId}/company/${companyId}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });
    return await ApiService.post(url, {});
  }

  async updateDeviceData({ id, data }: { id: number; data: any }) {
    const url = GenerateUrl({
      path: `/api/device/${id}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });
    return await ApiService.put(url, [
      { op: "replace", path: "/data", value: data },
    ]);
  }

  async shareDeviceToUser({
    shareData,
    assignmentId,
  }: {
    shareData: ShareDevice;
    assignmentId: number;
  }): Promise<{ userId: number; status: string }> {
    const response = await ApiService.post(
      `${ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH}/api/shares/assignment/${assignmentId}`,
      shareData
    );

    return response.data;
  }

  async revokeShareFromUser({
    userEmail,
    assignmentId,
  }: {
    userEmail: string;
    assignmentId: number;
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/shares/revoke`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
      config: {
        UserEmail: userEmail,
        AssignmentId: assignmentId,
      },
    });

    return await ApiService.delete(url);
  }

  async approveShare({
    userEmail,
    assignmentId,
  }: {
    userEmail: string;
    assignmentId: number;
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/shares/approve`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
      config: {
        UserEmail: userEmail,
        AssignmentId: assignmentId,
      },
    });

    return await ApiService.post(url, {});
  }

  async removeUserFromCompany({
    companyId,
    userId,
  }: {
    companyId: number;
    userId: number;
  }) {
    const url = GenerateUrl({
      path: `/api/companies/${companyId}/user/${userId}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });
    return await ApiService.delete(url);
  }
}
