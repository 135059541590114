import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "space-y-4"
}
const _hoisted_2 = { class: "mb-4 w-full" }
const _hoisted_3 = { class: "flex h-[42px] items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_dx_date_box = _resolveComponent("dx-date-box")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createBlock(_component_main_section, { title: "Access manager/Events" }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loader, {
            key: 0,
            "inner-ring-thickness": "border-t-8",
            "outer-ring-thickness": "border-8"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_dx_date_box, {
                  modelValue: _ctx.from,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.from) = $event)),
                  height: 42,
                  "show-clear-button": true,
                  "display-format": "dd.MM.yyyy HH:mm",
                  type: "datetime",
                  clearable: false
                }, null, 8, ["modelValue"]),
                _createVNode(_component_dx_date_box, {
                  modelValue: _ctx.to,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.to) = $event)),
                  height: 42,
                  "show-clear-button": true,
                  "display-format": "dd.MM.yyyy HH:mm",
                  type: "datetime",
                  clearable: false
                }, null, 8, ["modelValue"]),
                _createVNode(_component_v_select, {
                  modelValue: _ctx.selectedResourceUuid,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedResourceUuid) = $event)),
                  options: _ctx.resources,
                  reduce: (resource)=>resource.dataUuid,
                  class: "w-72",
                  label: "name",
                  clearable: false
                }, null, 8, ["modelValue", "options", "reduce"])
              ])
            ]),
            _createVNode(_component_custom_table, {
              "is-action-list-not-displayed": true,
              "is-data-source-loading": _ctx.isDataSourceLoading,
              "table-data": _ctx.events,
              "table-headers": _ctx.tableHeaders,
              onForceRefresh: _ctx.getEvents
            }, null, 8, ["is-data-source-loading", "table-data", "table-headers", "onForceRefresh"])
          ]))
    ]),
    _: 1
  }))
}