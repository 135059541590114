<template>
  <create-form
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getDisplays"
    @toggle-modal="isCreateModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :display-prop="selectedDisplay"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getDisplays"
    @toggle-modal="isEditModalOpened = false"
  />
  <nodes-view
    v-if="isNodeViewModalOpened"
    :device-details="deviceDetails"
    :is-nodes-view-opened="isNodeViewModalOpened"
    @toggle-modal="isNodeViewModalOpened = false"
  />
  <delete-modal
    v-if="isDeleteModalOpened"
    :is-deleting="isDeleteModalOpened"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :delete-text="confirmationModalText"
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    @confirm-action="confirmationModalAction"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <display-events
    v-if="isDisplayEventsShown"
    :is-display-events-opened="isDisplayEventsShown"
    :id="selectedDisplay.externalAssignmentUuid"
    :title="`${selectedDisplay.name}`"
    @toggle-modal="isDisplayEventsShown = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else class="h-full">
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateAssignment"
        :title="$t('Displays.Title')"
        button-text="Create"
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :nodes-column="true"
        :options="options"
        :render-summary="['name']"
        :show-per-page="16"
        :table-data="displays"
        :table-headers="tableHeaders"
        table-height="93%"
        :isActionListNotDisplayed="
          !permissionStore.hasPermission(permissions.EditAssignment) &&
          !permissionStore.hasPermission(permissions.DeleteAssignment)
        "
        @selected-rows="getSelectedRows"
        @manage-nodes="manageNodes"
        @force-refresh="getDisplays"
        @view-display-events="toggleDisplayEvents"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import CreateForm from "../Components/CreateForm.vue";
import { Display } from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import EditForm from "../Components/EditForm.vue";
import Loader from "@/core/Components/Loader.vue";
import { Node } from "../Services/DisplayTypes";
import NodesView from "@/modules/Parkom/Submodules/Displays/Components/Nodes/NodesView.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import DisplayEvents from "@/modules/Parkom/Submodules/Displays/Components/Events.vue";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    ConfirmationModal,
    DeleteModal,
    NodesView,
    EditForm,
    CreateForm,
    TableActionHeader,
    MainSection,
    CustomTable,
    Loader,
    DisplayEvents,
  },
  data() {
    return {
      permissionStore: usePermissionStore(),
      displayService: new DisplayService(),
      displays: [] as Display[],
      selectedDisplay: {} as Display,
      toast: useToast(),
      changeCompanyStore: useChangeCompanyStore(),
      isCreateModalOpened: false,
      isEditModalOpened: false,
      isDataSourceLoading: false,
      isNodeViewModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      isDisplayEventsShown: false,
      confirmationModalText: "",
      deviceDetails: {} as { id: number; name: string },
      isLoading: false,
      nodes: [] as Node[],
      permissions: Permissions,
      tableHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "Displays.Name", visible: true },
        lastSync: { header: "Displays.LastSync", visible: true },
        active: { header: "Displays.Active", visible: true },
        imei: { header: "Displays.IMEI", visible: true },
        lastDisplayed: { header: "Displays.LastDisplayed", visible: true },
        displayEventsReport: {
          header: "Displays.Events",
          visible: true,
        },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditAssignment,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteAssignment,
          action: this.toggleDeleteModal,
        },
      },
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getDisplays();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getDisplays();
    },
  },
  methods: {
    async getDisplays() {
      this.isDataSourceLoading = true;
      try {
        this.displays = await this.displayService.getDisplays({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async deleteDisplay() {
      this.isDeleteModalOpened = true;
      try {
        await this.displayService.deleteDisplay(this.selectedDisplay.id);
        this.toast.success("Display deleted successfully!");
        this.isDeleteModalOpened = false;
        this.isConfirmationModalOpened = false;
        await this.getDisplays();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDeleteModalOpened = false;
      }
    },

    async manageNodes(deviceDetails: { id: number; name: string }) {
      this.deviceDetails = deviceDetails;
      this.isNodeViewModalOpened = true;
    },

    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteDisplay();
          break;
      }
      this.isConfirmationModalOpened = false;
    },

    getSelectedRows(values: Display[]) {
      if (values.length) {
        this.selectedDisplay = values[0];
      }
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
    toggleDisplayEvents(display: Display) {
      this.selectedDisplay = display;
      this.isDisplayEventsShown = !this.isDisplayEventsShown;
    },
  },
});
</script>

<style scoped></style>
