<template>
  <div class="flex h-full w-full items-center justify-center">
    <form-wrapper :is-form-in-modal="isFormInModal" :is-loading="isLoading">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="authResource.email"
            :disabled="true"
            label="Email"
          />
          <checkbox-input
            v-model="isAllPermissionCheckBoxChecked"
            :is-checked="isAllPermissionCheckBoxChecked"
            label="Give permission for all resources"
          />
          <form-group v-if="!isAllPermissionCheckBoxChecked">
            <div
              v-for="(resource, index) in authResource.authResources"
              :style="{ gridTemplateColumns: '5fr min-content' }"
              class="grid grid-cols-2 gap-x-4"
            >
              <div class="grid grid-cols-2 gap-x-4">
                <div class="relative col-span-2 items-center py-3">
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >Resource</label
                  >
                  <small-loader v-if="isLoading" height="h-8" width="w-8" />
                  <v-select
                    v-else
                    v-model="resource.onResourceId"
                    :clearable="false"
                    :options="resourcesForSelect"
                    :reduce="(type : any) => type.id"
                    class="mt-1 w-full"
                    label="name"
                  ></v-select>
                </div>
                <div>
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >From</label
                  >
                  <dx-date-box
                    v-model="resource.policyValidFrom"
                    :height="42"
                    :show-clear-button="false"
                    display-format="dd/MM/yyyy, HH:mm"
                    type="datetime"
                    :accept-custom-value="false"
                  />
                </div>
                <div>
                  <label
                    class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                    >To</label
                  >
                  <dx-date-box
                    v-model="resource.policyValidTo"
                    :height="42"
                    :show-clear-button="true"
                    display-format="dd/MM/yyyy, HH:mm"
                    type="datetime"
                    :accept-custom-value="false"
                  />
                </div>
              </div>
              <add-delete-buttons
                :auth-resources="authResource.authResources"
                :index="index"
                @remove-click="() => removeAuthResource(resource, index)"
                @add-click="addAuthResource"
              />
            </div>
          </form-group>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Loader from "@/core/Components/Loader.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import FormGroup from "@/core/Components/FormElements/FormGroup.vue";
import VSelect from "vue-select";
import AddDeleteButtons from "../Components/AddDeleteButtons.vue";
import DxDateBox from "devextreme-vue/date-box";
import ProfileConfigurationService, {
  AmrPolicy,
} from "../Services/ProfileConfigurationService";
import AccessProfileService from "../../AccessProfiles/Services/AccessProfileService";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import AccessManagerService from "@/modules/AccessManager/Services/AccessManagerService";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import UserService, { User } from "@/modules/Users/Services/UserService";
import ResourceService, {
  AmrResource,
} from "@/modules/AccessManager/SubModules/Resources/Services/ResourceService";
import AuthResourceService, {
  AuthResourceDto,
  AuthResourceDto_,
} from "@/modules/Authorization/Services/AuthResourceService";
import AuthPolicyService from "@/modules/Authorization/Services/AuthPolicyService";
import moment from "moment";
import { useUserStore } from "@/core/Store/userStore";
import SpotiumService from "@/core/Services/SpotiumService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "vue-toastification";
import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: {
    MainSection,
    Loader,
    FormWrapper,
    FormDivider,
    InputField,
    FormGroup,
    VSelect,
    AddDeleteButtons,
    DxDateBox,
    CheckboxInput,
    ButtonWrapper,
    ToastComponent,
    SmallLoader,
  },
  props: {
    isFormInModal: Boolean,
    authResourceProp: {
      type: Object,
      required: true,
    },
    refreshData: Function,
  },
  data() {
    return {
      profileConfigurationService: new ProfileConfigurationService(),
      resourceService: new ResourceService(),
      assignmentService: new AssignmentService(),
      accessProfileService: new AccessProfileService(),
      authResourceService: new AuthResourceService(),
      authPolicyService: new AuthPolicyService(),
      spotiumService: new SpotiumService(),
      accessManagerService: new AccessManagerService(),
      userService: new UserService(),
      userStore: useUserStore(),
      toast: useToast(),
      resources: [] as DeviceAssignment[],
      resourcesForSelect: [] as any,
      users: [] as User[],
      formData: {} as any,
      authResource: {} as any,
      authResourcesToDelete: [] as any,
      authResourcesToCreate: [] as any,
      isAllPermissionCheckBoxChecked: false,
      sharedUserId: 0,
      isLoading: false,
      isSharedUser: false,
      isSubmitLoading: false,
    };
  },
  async created() {
    this.authResource = JSON.parse(JSON.stringify(this.authResourceProp));
    this.isLoading = true;
    try {
      await Promise.all([this.getAllUsers(), this.getAllResources()]);
      this.formData = this.authResource;
      this.isAllPermissionCheckBoxChecked =
        this.authResource.authResources.length === 1 &&
        this.authResource.authResources[0].onResourceId === null;
      if (this.authResource.authResources.length == 0) this.addAuthResource();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getAllUsers() {
      try {
        const response = await this.userService.getAllUsers(1, 10000);
        this.users = response.items;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getAllResources() {
      try {
        this.resources = await this.accessManagerService.getCompanyResources();
        this.resourcesForSelect = this.resources.map((item) => ({
          id: item.assignmentId,
          name: item.assignment?.name,
        }));
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async handleSubmit() {
      this.isSubmitLoading = true;

      // Logic when all permission checkbox is checked
      // Delete all individual permissions and add new one with all permissions
      if (this.isAllPermissionCheckBoxChecked) {
        try {
          await Promise.all(
            this.authResource.authResources.map(async (authResource: any) => {
              const resourceId = this.resources.find(
                (resource) =>
                  resource.assignmentId === authResource.onResourceId
              )?.deviceId;

              if (!resourceId) {
                return;
              }
              if (!authResource.id) {
                return;
              }

              if (!authResource.policyId) {
                return;
              }

              await this.authResourceService.deleteAuthResource(
                authResource.id
              );
              await this.authPolicyService.deletePolicy(authResource.policyId);
            })
          );
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        }

        // Add new auth resource with all permissions
        try {
          this.addAuthResource(true);
          await this.createPoliciesAndResources();
          this.toast.success("Successfully added policy for all resources!");
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }

      //Delete old resources
      if (this.authResourcesToDelete.length) {
        try {
          await Promise.all(
            this.authResourcesToDelete.map(async (authResource: any) => {
              if (!authResource.id) {
                return;
              }

              if (!authResource.policyId) {
                return;
              }

              //Is this check still necessary?
              /* if (!authResource.toUserId) {
                return;
              } */

              /* const resourceId = this.resources.find(
                (resource) =>
                  resource.assignmentId === authResource.onResourceId
              )?.deviceId;

              if (!resourceId) {
                return;
              } */

              await this.authResourceService.deleteAuthResource(
                authResource.id
              );
              await this.authPolicyService.deletePolicy(authResource.policyId);
            })
          );
          this.toast.success(this.$t("General.DeletedSuccessfully"));
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
        }
      }

      //Logic when user doesn't have all permissions
      if (!this.isAllPermissionCheckBoxChecked) {
        await Promise.all(
          this.authResource.authResources.map(async (authResource: any) => {
            if (!authResource.id) {
              return;
            }

            if (!authResource.policyId) {
              return;
            }

            /*  if (!authResource.toUserId) {
              return;
            } */

            const authResourceUpdateObject: PatchObject[] = [
              {
                op: "replace",
                path: "/onResourceId",
                value: authResource.onResourceId,
              },
            ];

            const policyUpdateObject: PatchObject[] = [
              {
                op: "replace",
                path: "/name",
                value: authResource.policyName,
              },
              {
                op: "replace",
                path: "/validFrom",
                value: authResource.policyValidFrom,
              },
              {
                op: "replace",
                path: "/validTo",
                value: authResource.policyValidTo,
              },
            ];

            await this.authResourceService.editAuthResource(
              authResourceUpdateObject,
              authResource.id
            );
            await this.authPolicyService.editPolicy(
              policyUpdateObject,
              authResource.policyId
            );
          })
        );

        this.toast.success(this.$t("General.EditedSuccessfully"));

        try {
          try {
            await this.createPoliciesAndResources();
            this.toast.success(this.$t("General.CreatedSuccessfully"));
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
      await this.refreshData?.("users");
    },
    removeAuthResource(resource: AuthResourceDto_, index: number) {
      if (resource.id) {
        this.authResourcesToDelete.push(resource);
        this.authResource.authResources =
          this.authResource.authResources.filter(
            (authResource: AuthResourceDto) => authResource.id !== resource.id
          );
        return;
      }

      this.authResource.authResources.splice(index, 1);
    },
    addAuthResource(isAddForAllResources?: boolean) {
      this.authResource.authResources.push({
        policyValidFrom: moment()
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss[Z]"),
        policyValidTo: null,
        onCompanyId: this.userStore.activeCompanyId,
        onResourceId: isAddForAllResources
          ? null
          : this.resources[0].assignmentId ?? 0,
        roleId: null,
        policyId: null,
        resourceTypeId: 2,
        createdUserId: this.userStore.userId,
      });
    },
    async createPoliciesAndResources() {
      this.authResourcesToCreate = this.authResource.authResources.filter(
        (authResource: any) => !authResource.id
      );

      let toUserId = this.authResource.id;

      try {
        for (const authResource of this.authResourcesToCreate) {
          const policy: AmrPolicy = {
            name: uuidv4(),
            companyId: this.userStore.activeCompanyId,
            createdUserId: this.userStore.userId,
            validFrom: authResource.policyValidFrom,
            validTo:
              authResource.policyValidTo === null
                ? null
                : moment(authResource.policyValidTo).format(
                    "YYYY-MM-DDTHH:mm:ss[Z]"
                  ),
          };

          const createdPolicyId =
            await this.profileConfigurationService.createAmrPolicy(policy);

          try {
            const amrResource: AmrResource = {
              toCompanyId: null,
              toUserId: toUserId,
              onCompanyId: this.userStore.activeCompanyId,
              resourceTypeId: 2, //2 is type for device assignment
              onResourceId: authResource.onResourceId ?? null,
              roleId: null,
              permissionIds: [50, 401],
              policyId: createdPolicyId,
              companyId: this.userStore.activeCompanyId,
            };
            await this.resourceService.createAmrResource(amrResource);
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>
<style scoped></style>
