import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  style: { gridTemplateColumns: '1fr min-content' },
  class: "grid grid-cols-2 gap-x-4"
}
const _hoisted_2 = {
  style: { gridTemplateColumns: '1fr min-content' },
  class: "grid grid-cols-2 gap-x-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_add_delete_buttons = _resolveComponent("add-delete-buttons")!
  const _component_form_group = _resolveComponent("form-group")!
  const _component_multi_json_input = _resolveComponent("multi-json-input")!
  const _component_tags_input = _resolveComponent("tags-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _directive_auto_animate = _resolveDirective("auto-animate")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_form_divider, null, {
      default: _withCtx(() => [
        _createVNode(_component_input_field, {
          modelValue: _ctx.formData.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
          label: "Name"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_input_field, {
          modelValue: _ctx.formData.config.data.xName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.config.data.xName) = $event)),
          label: "X name",
          onKeyup: _ctx.trimXName
        }, null, 8, ["modelValue", "onKeyup"]),
        _createVNode(_component_input_field, {
          modelValue: _ctx.formData.config.data.yName,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.config.data.yName) = $event)),
          label: "Y name",
          onKeyup: _ctx.trimYName
        }, null, 8, ["modelValue", "onKeyup"]),
        _createVNode(_component_input_field, {
          modelValue: _ctx.formData.config.data.angleName,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.config.data.angleName) = $event)),
          label: "Angle name"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_form_group, { title: "Coordinate paths" }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.config.data.paths, (path, index) => {
                return (_openBlock(), _createElementBlock("li", _hoisted_1, [
                  _createVNode(_component_input_field, {
                    id: "index",
                    modelValue: _ctx.formData.config.data.paths[index],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.formData.config.data.paths[index]) = $event),
                    label: "Path",
                    onKeyup: ($event: any) => (_ctx.trimCoordinatePath(index))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"]),
                  _createVNode(_component_add_delete_buttons, {
                    "auth-resources": _ctx.formData.config.data.paths,
                    onAddClick: _ctx.addPath,
                    onRemoveClick: ($event: any) => (_ctx.removePath(index))
                  }, null, 8, ["auth-resources", "onAddClick", "onRemoveClick"])
                ]))
              }), 256))
            ])), [
              [_directive_auto_animate]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_form_group, { title: "Tag ID paths" }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.config.data.tagIdPaths, (idPath, index) => {
                return (_openBlock(), _createElementBlock("li", _hoisted_2, [
                  _createVNode(_component_input_field, {
                    modelValue: _ctx.formData.config.data.tagIdPaths[index],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.formData.config.data.tagIdPaths[index]) = $event),
                    label: "Path",
                    onKeyup: ($event: any) => (_ctx.trimTagIdPath(index))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeyup"]),
                  _createVNode(_component_add_delete_buttons, {
                    "auth-resources": _ctx.formData.config.data.tagIdPaths,
                    onAddClick: _ctx.addTagIdPath,
                    onRemoveClick: ($event: any) => (_ctx.removeTagIdPath(index))
                  }, null, 8, ["auth-resources", "onAddClick", "onRemoveClick"])
                ]))
              }), 256))
            ])), [
              [_directive_auto_animate]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_multi_json_input, {
          onInput: _ctx.handleTopicChange,
          topics: _ctx.formData.config.topicToChannel
        }, null, 8, ["onInput", "topics"]),
        _createVNode(_component_tags_input, {
          label: _ctx.$t('Integrations.Channels'),
          tags: _ctx.formData.config.channels,
          onOnTagMutate: _cache[4] || (_cache[4] = (tags) => (_ctx.formData.config.channels = tags))
        }, null, 8, ["label", "tags"]),
        _createVNode(_component_input_field, {
          modelValue: _ctx.formData.executionOrder,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.executionOrder) = $event)),
          label: _ctx.$t('Integrations.ExecutionOrder'),
          type: "number"
        }, null, 8, ["modelValue", "label"]),
        _createVNode(_component_checkbox_input, {
          modelValue: _ctx.formData.isActive,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.isActive) = $event)),
          "is-checked": _ctx.formData.isActive,
          label: "Active"
        }, null, 8, ["modelValue", "is-checked"])
      ]),
      _: 1
    }),
    _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
  ], 32))
}