import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    rows: 10,
    paginator: true,
    value: _ctx.tableData,
    class: "p-datatable-users",
    "data-key": "id",
    responsiveLayout: "scroll",
    selection: _ctx.selectedValue,
    "onUpdate:selection": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedValue) = $event))
  }, {
    empty: _withCtx(() => [
      _createTextVNode(" There is no data to display! ")
    ]),
    default: _withCtx(() => [
      (_ctx.isSelectModal)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            headerStyle: "width:150px",
            selectionMode: "single",
            dataKey: "id",
            selection: _ctx.selectedValue,
            "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event))
          }, null, 8, ["selection"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (value, key) => {
        return (_openBlock(), _createBlock(_component_Column, {
          headerStyle: "width:150px",
          field: key,
          header: value,
          key: key
        }, {
          body: _withCtx(({ data }) => [
            _createTextVNode(_toDisplayString(data[key]), 1)
          ]),
          _: 2
        }, 1032, ["field", "header"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["value", "selection"]))
}