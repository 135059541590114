import { defineStore } from "pinia";
import { ThemeEnum } from "../Services/Enums";

export const useThemeStore = defineStore("theme", {
  state: () => {
    return {
      themeClass: "default" as ThemeEnum,
      companyLogo: {
        default: "theme-icons/sick.ico",
        sick: "theme-icons/sick-logo.svg",
        ht: "theme-icons/ht.ico",
      },
    };
  },
  actions: {
    changeTheme(theme: ThemeEnum) {
      this.themeClass = theme;
    },
  },
  persist: true,
});
