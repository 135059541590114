<template>
  <modal-component
    :have-modal="haveModal"
    :is-displayed="isReportModalOpened"
    :no-padding="true"
    :remove-outside-click="true"
    :title="`Generate report for ${deviceName}`"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <div class="space-y-4 px-6">
          <grid :column-number="2">
            <div class="flex flex-col space-y-1">
              <label
                class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                >From</label
              >
              <dx-date-box
                v-model="from"
                :height="46"
                :show-clear-button="true"
                display-format="dd.MM.yyyy HH:mm"
                type="datetime"
              />
            </div>
            <div class="flex flex-col space-y-1">
              <label
                class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                >To</label
              >
              <dx-date-box
                v-model="to"
                :height="46"
                :show-clear-button="true"
                display-format="dd.MM.yyyy HH:mm"
                type="datetime"
              />
            </div>
          </grid>
          <file-type-selector
            :selected-file-type="selectedFileType"
            @select-file-type="changeFileTypeSelection"
          />
          <grid :column-number="2">
            <div class="mb-6">
              <label
                class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                >Language</label
              >
              <v-select
                v-model="selectedLanguage"
                :options="options"
                :reduce="(lang) => lang.id"
                class="mt-1"
                label="name"
              >
                <template v-slot:option="option">
                  <div class="flex items-center space-x-2">
                    <img :src="option.image" alt="image" class="w-[24px]" />
                    <p>{{ option.name }}</p>
                  </div>
                </template>
              </v-select>
            </div>
            <input-field
              v-model="offset"
              :no-padding="true"
              label="Time offset"
              type="number"
            />
          </grid>
        </div>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import Grid from "@/core/Components/Grid.vue";
import DxDateBox from "devextreme-vue/date-box";
import moment from "moment";
import FileTypeSelector from "@/modules/Devices/Submodules/Assignments/Components/FileTypeSelector.vue";
import VSelect from "vue-select";
import InputField from "@/core/Components/FormElements/InputField.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import ReportService from "@/modules/Devices/Services/ReportService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";

export default defineComponent({
  components: {
    ButtonWrapper,
    FormWrapper,
    InputField,
    FileTypeSelector,
    Grid,
    ModalComponent,
    DxDateBox,
    VSelect,
  },
  props: {
    isReportModalOpened: Boolean,
    deviceName: {
      type: String,
      required: true,
    },
    deviceUuid: {
      type: String,
      required: true,
    },
    haveModal: Boolean,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      reportService: new ReportService(),
      isSubmitLoading: false,
      toast: useToast(),
      from: moment().startOf("day").format("yyyy-MM-DD HH:mm"),
      to: moment().endOf("day").format("yyyy-MM-DD HH:mm"),
      selectedFileType: "XLSX",
      selectedLanguage: "en",
      offset: 0,
      options: [
        { id: "hr", name: "Croatian", image: "/hr.svg" },
        { id: "en", name: "English", image: "/gb.svg" },
      ],
    };
  },
  methods: {
    async handleSubmit() {
      try {
        this.isSubmitLoading = true;
        const response = await this.reportService.downloadReport({
          deviceUuid: this.deviceUuid,
          from: this.from,
          to: this.to,
          fileType: this.selectedFileType,
          languageCode: this.selectedLanguage,
          offset: this.offset,
        });
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${this.deviceName} ${this.from}-${this.to}`;
        a.click();
        a.remove();
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
        this.toast.success("Report generated successfully!");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    changeFileTypeSelection(fileType: string) {
      this.selectedFileType = fileType;
    },
  },
});
</script>

<style scoped></style>
