import axios from "axios";
import ResourceService, {
  Resource,
} from "../../Resources/Services/ResourceService";
import ApiService from "@/core/Axios/ApiService";
import AssignmentService, {
  CreateAssignment,
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type Permission = {
  id: number; //deviceId
  assignmentId: number;
  uuid?: string;
  name: string;
  parentAssignmentId?: number;
  identifiers: Identifier[];
  accesses: Access[];
  actions?: number[];
};

export type PermissionObject = {
  id: number;
  uuid: string;
  name: string;
  data: Permission;
};

export type Identifier = {
  id?: number;
  uuid?: string;
  value: string;
  type: number;
  action?: number;
};

export type Access = {
  action: number;
  active: boolean;
  resourceId: number;
  dateActiveTo: string;
  dateActiveFrom: string;
  resourceTriggerId: string; //uspoređujem s deviceUuid
};

export type AmrPolicy = {
  name: string;
  validFrom: string;
  validTo: string | null;
  companyId: number;
  createdUserId: number;
};

export type IdentifierType = {
  id: number;
  name: string;
  identifierName?: string;
};

export default class ProfileConfigurationService {
  resourceService: ResourceService;
  assignmentService: AssignmentService;

  constructor() {
    this.resourceService = new ResourceService();
    this.assignmentService = new AssignmentService();
  }

  async getAllPermissions(
    pageNumber = 1,
    pageSize = 10,
    deviceTypeId = process.env.VUE_APP_ACCESS_MANAGER_PERMISSION_TYPE_ID
  ): Promise<Permission[]> {
    const response = await ApiService.get(
      `${
        ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH
      }/api/assignments?PageNumber=${pageNumber}&PageSize=${pageSize}${
        deviceTypeId ? `&DeviceTypeIds=${deviceTypeId}` : ""
      }`
    );

    return this._mapProfileConfigurations(response.data.items);
  }

  async getProfileConfigurationWithIdentifier(
    id: number
  ): Promise<DeviceAssignment> {
    return await this.assignmentService.getDeviceAssignmentByIdentifier({ id });
  }

  async createPermission(
    permission: CreateAssignment,
    userId: number
  ): Promise<any> {
    if (!permission.deviceRequest) {
      return;
    }
    permission.deviceRequest.externalId = String(
      permission.deviceRequest.externalId
    );
    const response = await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/users/${userId}/devices`,
      permission
    );
    return response.data;
  }

  async createAmrPolicy(amrPolicy: AmrPolicy): Promise<number> {
    const response = await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/authorization/policies`,
      amrPolicy
    );
    return response.data;
  }

  /*  async updateProfileConfiguration(
    parkingConfiguration: Permission
  ): Promise<any> {
    return await axios.put(
      `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/parking-profiles/${parkingConfiguration.id}`,
      parkingConfiguration
    );
  } */

  async deleteProfileConfiguration(id: number): Promise<boolean> {
    try {
      await axios.delete(
        `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/parking-profiles/${id}`
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async getAllIdentifierTypes(): Promise<IdentifierType[]> {
    const response = await axios.get(
      `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/identifier-types`
    );
    return response.data;
  }

  async addPermissionToStorageForSync(
    tdceUuid: string,
    tdceDataUuid: string,
    permission: Permission
  ) {
    const storageResourceShape = {
      deviceUuid: tdceUuid,
      dataUuid: tdceDataUuid,
      payload: {
        type: "user",
        data: permission,
      },
      channel: 1,
    };
    return await ApiService.post(
      `${ConfigurationService.configData.STORAGE_API_PREFIX_PATH}/api/data`,
      storageResourceShape
    );
  }

  async updatePermissionData(permission: CreateAssignment): Promise<any> {
    return await ApiService.put(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/devices/uuid/${permission.deviceRequest?.data.uuid}/data`,
      { data: permission.deviceRequest?.data }
    );
  }

  private _mapProfileConfigurations(
    response: PermissionObject[]
  ): Permission[] {
    return response.map((profileConfiguration: PermissionObject) =>
      this._mapProfileConfiguration(profileConfiguration)
    );
  }

  private _mapProfileConfiguration(profileConfiguration: any): Permission {
    return {
      id: profileConfiguration.deviceId,
      assignmentId: profileConfiguration.assignmentId,
      name: profileConfiguration.assignment.name,
      uuid: profileConfiguration.device.data.uuid,
      parentAssignmentId: profileConfiguration.assignment.parentAssignmentId,
      identifiers: this._mapIdentifiers(
        profileConfiguration.device.data.identifiers
      ),
      accesses: this._mapAccesses(profileConfiguration.device.data.accesses),
    };
  }

  private _mapIdentifiers(
    identifiers: Identifier[],
    identifierTypes?: IdentifierType[]
  ): Identifier[] {
    return (
      identifiers?.map((identifier: Identifier) =>
        this._mapIdentifier(identifier, identifierTypes)
      ) || []
    );
  }

  private _mapIdentifier(
    identifier: Identifier,
    identifierTypes?: IdentifierType[]
  ): Identifier {
    return {
      value: identifier.value,
      type: identifier.type,
      ...(identifierTypes && {
        identifierName: identifierTypes.filter(
          (identifierType) => identifierType.id === identifier.type
        )[0]?.name,
      }),
    };
  }

  private _mapAccesses(accesses: Access[], resources?: Resource[]): Access[] {
    return (
      accesses?.map((access: Access) => this._mapAccess(access, resources)) ||
      []
    );
  }

  private _mapAccess(access: Access, resources?: Resource[]): Access {
    return {
      active: access.active,
      dateActiveFrom: access.dateActiveFrom,
      dateActiveTo: access.dateActiveTo,
      resourceId: access.resourceId,
      resourceTriggerId: access.resourceTriggerId,
      action: access.action,
      ...(resources && {
        resourceName: resources.filter(
          (resource) => resource.id === access.resourceId
        )[0]?.name,
      }),
    };
  }
}
