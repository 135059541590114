import { RouteRecordRaw } from "vue-router";
import Index from "../Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createMapViewIndexRoute(): RouteRecordRaw {
  return {
    name: "mapView",
    path: "/map-view",
    component: Index,
    meta: {
      id: "mapViewIndex",
      label: "Map view",
      icon: ["fas", "map-marked-alt"],
      showInNavigation: true,
      group: "main",
      authorize: [Permissions.ViewAssignment],
    },
  };
}
