import moment from "moment";
import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
//@ts-ignore
import Color from "color";
import { MapObject } from "@/modules/MapView/Services/Types/MapObjectTypes";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import ConfigurationService from "./ConfigurationService";

type ToastData = {
  isError: boolean;
  message: string;
  showToast: boolean;
};

export type Config = {
  [key: string]: string | string[] | number | number[];
};

export default function checkIfObjectEmpty(object: object): boolean {
  return (
    object && Object.keys(object).length === 0 && object.constructor === Object
  );
}

export function convertObjectToQueryParameters(queryParameters: any): string {
  return Object.keys(queryParameters)
    .map((parameter) => `${parameter}=${queryParameters[parameter]}`)
    .join("&");
}

export function FormatDateTime(dateTime: any): string {
  if (!dateTime) {
    return "-";
  }

  if (IsMinDate(dateTime)) {
    return "-";
  }

  return moment(dateTime).format("DD.MM.yyyy. HH:mm");
}

export function FormatDateTimeSeconds(dateTime: any): string {
  if (!dateTime) {
    return "-";
  }

  if (IsMinDate(dateTime)) {
    return "-";
  }

  return moment(dateTime).format("DD.MM.yyyy. HH:mm:ss");
}

export function IsMinDate(dateTime: string) {
  return moment(dateTime).isSame(moment("0001-01-01T00:00:00+00:00"));
}

export function FormatDateTimePolicy(dateTime: any): string {
  if (!dateTime) {
    return "No time limit";
  }
  return moment(dateTime).format("DD.MM.yyyy. HH:mm");
}

export function FormatDate(date: any): string {
  if (!date) {
    return "No data";
  }
  return moment(date).format("yyyy/MM/DD");
}

export function FormatTime(dateTime: any): string {
  if (!dateTime) {
    return "No data";
  }
  return moment(dateTime).format("HH:mm");
}

export function AddTimeToDate(
  dateTime: any,
  period: any,
  measuringUnit: string
) {
  if (!dateTime) {
    return "No data";
  }
  return moment(dateTime)
    .add(period, measuringUnit)
    .format("DD.MM.yyyy. HH:mm");
}

export function RoundNumber(value: number): number {
  return Math.round(value * 10) / 10;
}

//generate a function that converts whole number to decimal number
export function ConvertToDecimalNumber(value: number, decimalPlaces: number) {
  return value / Math.pow(10, decimalPlaces);
}

export function ErrorMessageFormatter(error: any): string[] | string {
  const errors: any = error?.response?.data?.errors;

  if (errors) {
    if (Object.keys(errors).length) {
      const errorValues = Object.values(errors).map((error: any) => {
        return error[0];
      });
      return errorValues[0];
    }
  }

  if (error?.response?.status === 401) {
    CustomLog("Unauthorized");
    return "Your token has expired, refresh your page!";
  }
  if (error?.response?.status === 500) {
    CustomLog("Internal server error");
    return "There is a problem with the server!";
  }
  if (error?.response?.status === 503) {
    CustomLog("Service unavailable");
    return "Service unavailable!";
  }
  if (error?.response?.status === 504) {
    CustomLog("Gateway timeout");
    return "Gateway timeout!";
  }
  CustomLog(error.message);
  return error.message;
}

export function Debounce(func: Function, timeout = 300) {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      //@ts-ignore
      func.apply(this, args);
    }, timeout);
  };
}

export function CheckIfThereIsError(isError: string | string[]): boolean {
  if (isError === "true") {
    return true;
  }

  return false;
}

export function ConvertMinutesToHoursAndMinutes(minutes: any) {
  return moment().startOf("day").add(minutes, "minutes").format("HH:mm");
}

export function ConvertMinutesToHoursAndMinutesFormatted(totalMinutes: number) {
  if (totalMinutes < 0) {
    return "-";
  }

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours}h${minutes > 0 ? ` ${minutes}m` : ""}`;
}

export function ConvertMinutes(m: number) {
  if (m == null || m == -1) {
    return "-";
  }
  if (m == 0) {
    return "< 1 " + "min";
  }
  let days = Math.floor(m / 1440) < 1 ? 0 : Math.floor(m / 1440);
  let hour = Math.floor(m / 60) < 1 ? 0 : Math.floor((m - days * 1440) / 60);
  let minutes = Math.round(m % 60);

  let dayString = days != 0 ? days + " " + "d" : "";
  let hourString = hour != 0 ? hour + " " + "h" : "";
  let minutesString = minutes != 0 ? minutes + " " + "min" : "";

  if (days > 0) {
    dayString = dayString + " ";
  }
  if (hour > 0) {
    hourString = hourString + " ";
  }

  let finalDaysHourMinutesString =
    dayString != ""
      ? dayString + hourString + minutesString
      : hourString != ""
      ? hourString + minutesString
      : minutesString;
  finalDaysHourMinutesString =
    finalDaysHourMinutesString.slice(-1) == " "
      ? finalDaysHourMinutesString.slice(0, -1)
      : finalDaysHourMinutesString;

  return finalDaysHourMinutesString;
}

export function ConvertMinutesToHoursAndMinutesInput(minutes: any) {
  return moment().startOf("day").add(minutes, "minutes");
}

export async function FileToBase64(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function radiansToDegrees(radians: number) {
  return radians * (180 / Math.PI);
}

export function LatLngToXY(
  lat_center: number,
  lon_center: number,
  zoom: number
) {
  const C = (256 / (2 * Math.PI)) * 2 ** zoom;

  const x = C * (lon_center * (Math.PI / 180) + Math.PI);
  const y =
    C *
    (Math.PI -
      Math.log(Math.tan(Math.PI / 4 + (lat_center * (Math.PI / 180)) / 2)));

  return { x, y };
}

export function XYToLatLng(
  lat_center: number,
  lon_center: number,
  zoom: number,
  width_internal: number,
  height_internal: number,
  pxX_internal: number,
  pxY_internal: number
) {
  const { x, y } = LatLngToXY(lat_center, lon_center, zoom);

  const xPoint = x - (width_internal / 2 - pxX_internal);
  const yPoint = y - (height_internal / 2 - pxY_internal);

  const C = (256 / (2 * Math.PI)) * 2 ** zoom;
  const M = xPoint / C - Math.PI;
  const N = -(yPoint / C) + Math.PI;

  const lon_Point = radiansToDegrees(M);
  const lat_Point = radiansToDegrees(
    (Math.atan(Math.E ** N) - Math.PI / 4) * 2
  );

  return {
    lat: lat_Point,
    lon: lon_Point,
  };
}

export function CalculateRotationBetweenTwoLatLngPoints(
  tag: { lat: number; lng: number },
  end: { lat: number; lng: number }
) {
  const a = { x: (end.lng / 180) * Math.PI, y: (end.lat / 180) * Math.PI };
  const b = { x: (tag.lng / 180) * Math.PI, y: (tag.lat / 180) * Math.PI };
  return -Math.atan2(
    Math.sin(b.x - a.x) * Math.cos(b.y),
    Math.cos(a.y) * Math.sin(b.y) -
      Math.sin(a.y) * Math.cos(b.y) * Math.cos(b.x - a.x)
  );
}

export function Rotate90DegreesCounterClockwise(
  origin: { lng: number; lat: number },
  yAxisPoint: { lng: number; lat: number }
) {
  const vect = {
    x: yAxisPoint.lng - origin.lng,
    y: yAxisPoint.lat - origin.lat,
  };
  const object = {
    lng: origin.lng - vect.y,
    lat: origin.lat + vect.x,
  };
  return object;
}

export function IsTagUser(word: string) {
  const splitText = word.split("(");
  if (splitText.length > 1) {
    return splitText[1].length > 20;
  }
  return true;
}

export function GenerateUrl({
  path,
  config,
  prefixPath,
}: {
  path: string;
  config?: Config;
  prefixPath?: string;
}) {
  const url =
    ConfigurationService.configData.LOCAL_RUN === "true"
      ? new URL(`${prefixPath}${path}`)
      : new URL(
          `${prefixPath}${path}`,
          ConfigurationService.configData.BASE_URL
        );

  if (!config) {
    return url.toString();
  }

  Object.entries(config).forEach(
    (config: [string, string | number | string[] | number[]]) => {
      if (Array.isArray(config[1])) {
        config[1].forEach((value: string | number) => {
          url.searchParams.append(String(config[0]), String(value));
        });
      } else {
        url.searchParams.append(String(config[0]), String(config[1]));
      }
    }
  );

  return url.toString();
}

function CustomLog(message: string) {
  console.log(
    `%c ${String.fromCodePoint(0x274c)} ${message}`,
    "background-color:#fee2e2; color:#EF4444; font-size: 14px; padding: 10px;"
  );
}

export async function CheckObjectsForPatch({
  initialObject,
  editedObject,
}: {
  initialObject: any;
  editedObject: any;
}) {
  let finalForm = [];
  for (const p in initialObject) {
    if (p === "config") {
      finalForm.push({
        op: "replace",
        path: `/${p}`,
        value: editedObject[p],
      });
    }
    if (initialObject.hasOwnProperty(p)) {
      if (initialObject[p] !== editedObject[p]) {
        finalForm.push({
          op: "replace",
          path: `/${p}`,
          value: editedObject[p],
        });
      }
    }
  }
  return finalForm.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t: { op: string; path: string; value: any }) => t.path === value.path
      )
  );
}

export function SyncDifferenceTime({
  hours,
  lastSyncTime,
}: {
  hours: number;
  lastSyncTime: string;
}) {
  if (!lastSyncTime) {
    return null;
  }

  return moment().isAfter(moment(lastSyncTime).add(hours, "hours"));
}

export function RssiHealth({ signal }: { signal: number }) {
  if (signal >= -99) {
    return "text-success";
  } else if (signal < -99 && signal >= -129) {
    return "text-orange";
  } else if (signal < -129) {
    return "text-error";
  }
}

export function KFormatter(num: number) {
  // @ts-ignore
  return Math.abs(num) > 999
    ? // @ts-ignore
      Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)
    : Math.sign(num) * Math.abs(num);
}

export function Cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function AvatarInitialsGenerator(email: string) {
  const splitEmail = email.split("@");

  const userInfo = splitEmail[0].split(".");

  if (userInfo.length > 1) {
    return `${userInfo[0].charAt(0).toUpperCase()}${userInfo[1]
      .charAt(0)
      .toUpperCase()}`;
  }

  return `${splitEmail[0].charAt(0).toUpperCase()}${splitEmail[0]
    .charAt(1)
    .toUpperCase()}`;
}

export function IsMapObject(
  value: MapObject | DeviceAssignment
): value is MapObject {
  return (value as MapObject).hasOwnProperty("geometry");
}

export function IsAssignment(
  value: MapObject | DeviceAssignment
): value is DeviceAssignment {
  return (value as DeviceAssignment).hasOwnProperty("assignmentId");
}
