<template>
  <modal
    :have-modal="haveModal"
    :is-displayed="isEditModalOpened"
    :is-select-modal="true"
    :no-padding="true"
    title="Asset details"
    @toggle-modal="$emit('toggle-modal')"
  >
    <map-location-selector
      v-if="isMapLocationModalOpened"
      :coordinates-prop="{
        lat: deviceAssignment.latitude,
        lng: deviceAssignment.longitude,
      }"
      :is-map-location-modal-opened="isMapLocationModalOpened"
      @toggle-modal="toggleMapLocationModal"
      @get-coordinates="getMarkerCoordinates"
    />
    <div class="flex h-full w-full items-center justify-center">
      <form-wrapper :is-form-in-modal="isFormInModal" :is-loading="isLoading">
        <form @submit.prevent="handleSubmit">
          <form-divider>
            <input-field
              v-model="deviceAssignment.name"
              :errorMessage="v$?.deviceAssignment['name']?.$errors[0]?.$message"
              :isError="v$?.deviceAssignment['name']?.$error"
              :required="true"
              label="Name"
              placeholder="Enter device name..."
            />
            <div
              class="grid grid-cols-2 gap-6"
              style="grid-template-columns: auto min-content"
            >
              <div>
                <input-field
                  v-model="deviceAssignment.latitude"
                  label="Latitude"
                />
                <input-field
                  v-model="deviceAssignment.longitude"
                  label="Longitude"
                />
              </div>
              <div class="pb-3 pt-10">
                <the-button
                  :icon="['fas', 'earth-europe']"
                  button-type="primaryButton"
                  type="button"
                  @execute-method="toggleMapLocationModal"
                />
              </div>
            </div>
            <div class="relative items-center py-4">
              <label
                class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                >Group</label
              >
              <v-select
                v-model="deviceAssignment.applicationId"
                :class="`mt-1 border ${
                  v$.deviceAssignment['applicationId']?.$error
                    ? 'rounded-md border-error'
                    : 'border-none'
                }`"
                :options="applications.items"
                :reduce="(spot : any) => spot.id"
                class="mt-1"
                label="name"
              ></v-select>
              <span
                v-if="v$.deviceAssignment['applicationId']?.$error"
                class="error-message text-sm text-error"
                >{{
                  v$?.deviceAssignment["applicationId"]?.$errors[0]?.$message
                }}</span
              >
            </div>
          </form-divider>
          <button-wrapper :is-submit-loading="isLoadingSubmit" />
        </form>
      </form-wrapper>
    </div>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import MainSection from "../../../../../core/Components/MainSection.vue";
import TheButton from "../../../../../core/Components/TheButton.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
//@ts-ignore
import VSelect from "vue-select";
import ApplicationService, {
  ApplicationObject,
} from "@/modules/Applications/Services/ApplicationService";
import Modal from "@/core/Components/Modal.vue";
import SelectTable from "@/core/Components/SelectTable.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { maxLength, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import AssignmentService, {
  EditAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import MapLocationSelector from "@/core/Components/MapLocationSelector.vue";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    InputField,
    MainSection,
    TheButton,
    FormWrapper,
    VSelect,
    Modal,
    SelectTable,
    FormDivider,
    ButtonWrapper,
    MapLocationSelector,
  },
  props: {
    isFormInModal: Boolean,
    objectId: { type: Number, required: true },
    refreshData: Function,
    isEditModalOpened: Boolean,
    haveModal: Boolean,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      applications: {} as ApplicationObject,
      applicationService: new ApplicationService(),
      assignmentService: new AssignmentService(),
      selectModalData: [] as any,
      selectedType: "" as String,
      modalTitle: "",
      isLoading: false as boolean,
      isLoadingSubmit: false as boolean,
      isMapLocationModalOpened: false,
      finalForm: [] as { op: string; path: string; value: any }[],
      toast: useToast(),
      tableHeaders: {
        id: "ID",
        name: "Name",
      },
      deviceAssignment: {} as EditAssignment,
      deviceAssignmentFromAPI: {},
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.getAllApplications(1, 2000),
        this.getDeviceAssignmentForEdit(),
      ]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  validations() {
    return {
      deviceAssignment: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        applicationId: {
          required,
        },
      },
    };
  },

  methods: {
    async checkObjectsForPatch(initialObject: any, editedObject: any) {
      this.finalForm = [];
      for (const p in initialObject) {
        if (p === "config") {
          this.finalForm.push({
            op: "replace",
            path: `/${p}`,
            value: editedObject[p],
          });
        }
        if (initialObject.hasOwnProperty(p)) {
          if (initialObject[p] !== editedObject[p]) {
            (this as any).finalForm.push({
              op: "replace",
              path: `/${p}`,
              value: editedObject[p],
            });
          }
        }
      }
      return this.finalForm.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t: { op: string; path: string; value: any }) =>
              t.path === value.path
          )
      );
    },

    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isLoadingSubmit = true;
        try {
          await this.assignmentService.editDeviceAssignment({
            deviceAssignment: await this.checkObjectsForPatch(
              this.deviceAssignmentFromAPI,
              this.deviceAssignment
            ),
            id: this.deviceAssignment.assignmentId,
          });
          const res = await this.checkObjectsForPatch(
            this.deviceAssignmentFromAPI,
            this.deviceAssignment
          );
          console.log(res);
          this.toast.success(this.$t("General.EditedSuccessfully"));
          await this.refreshData?.();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isLoadingSubmit = false;
        }
      }
    },
    async getAllApplications(pageNumber?: number, pageSize?: number) {
      try {
        this.applications = await this.applicationService.getAllApplications(
          pageNumber,
          pageSize
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    async getDeviceAssignmentForEdit() {
      try {
        const response =
          await this.assignmentService.getDeviceAssignmentByIdentifier({
            id: this.objectId,
          });
        if (!response.assignment) {
          return;
        }
        this.deviceAssignment = {
          assignmentId: response.assignment.id,
          name: response.assignment.name,
          applicationId: response.assignment.applicationId,
          latitude: response.assignment.latitude,
          longitude: response.assignment.longitude,
        };

        this.deviceAssignmentFromAPI = Object.assign({}, this.deviceAssignment);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    toggleMapLocationModal() {
      this.isMapLocationModalOpened = !this.isMapLocationModalOpened;
    },
    getMarkerCoordinates(coordinates: { lat: number; lng: number }) {
      this.deviceAssignment.latitude = coordinates.lat;
      this.deviceAssignment.longitude = coordinates.lng;
    },
  },
});
</script>
<style scoped></style>
