import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex justify-center align-middle"
}
const _hoisted_2 = {
  key: 1,
  class: "font-bold text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_ctx.companyCount > 1)
    ? (_openBlock(), _createBlock(_component_v_select, {
        key: 0,
        modelValue: _ctx.selectedCompany,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCompany) = $event)),
        options: _ctx.paginatedCompanies.items,
        reduce: (company) => company.id,
        class: "company-select my-2 w-full",
        label: "name",
        clearable: false,
        onInput: _ctx.handleCompanySearch,
        onClose: _ctx.handleDropdownClose
      }, {
        "list-footer": _withCtx(() => [
          (_ctx.paginatedCompanies.hasNextPage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", {
                  class: "mt-1 cursor-pointer bg-primary px-3 py-1 text-white transition duration-300 hover:bg-primaryhover disabled:cursor-not-allowed",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMoreCompanies && _ctx.loadMoreCompanies(...args)))
                }, " Load more... ")
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "options", "reduce", "onInput", "onClose"]))
    : (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.paginatedCompanies.items.length
        ? _ctx.paginatedCompanies.items[0].name
        : "You are not in any company!"), 1))
}