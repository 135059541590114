import i18n from "./i18n";
import { Languages } from "@/core/Services/GlobalTypes";

class Translations {
  get currentLocale() {
    return i18n.global.locale.value;
  }

  async switchLanguage(locale: Languages) {
    i18n.global.locale.value = locale;
    document.querySelector("html")!.setAttribute("lang", locale);
  }
}

export default new Translations();
