import { Company } from "@/modules/Companies/Services/CompanyService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type DeviceActionType = {
  id: number;
  name: string;
  value: any;
  companyId: number;
  company: Company;
};

export default class DeviceActionTypeService {
  async getAllDeviceActionTypes({
    pageSize,
    pageNumber,
  }: {
    pageSize: number;
    pageNumber: number;
  }): Promise<DeviceActionType[]> {
    const url = GenerateUrl({
      path: "/api/device-action-types",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

    const response = await ApiService.get(url);

    return response.data.items;
  }
}
