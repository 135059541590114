import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_multi_json_input = _resolveComponent("multi-json-input")!
  const _component_tags_input = _resolveComponent("tags-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_form_divider, null, {
      default: _withCtx(() => [
        _createVNode(_component_input_field, {
          modelValue: _ctx.formData.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
          errorMessage: _ctx.v$?.formData['name']?.$errors[0]?.$message,
          isError: _ctx.v$?.formData['name']?.$error,
          required: true,
          label: "Name"
        }, null, 8, ["modelValue", "errorMessage", "isError"]),
        (_ctx.integrationTypeId !== 2)
          ? (_openBlock(), _createBlock(_component_input_field, {
              key: 0,
              modelValue: _ctx.formData.config.endpoint,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.config.endpoint) = $event)),
              errorMessage: _ctx.v$?.formData?.config['endpoint']?.$errors[0]?.$message,
              isError: _ctx.v$?.formData?.config['endpoint']?.$error,
              required: true,
              label: "Endpoint"
            }, null, 8, ["modelValue", "errorMessage", "isError"]))
          : _createCommentVNode("", true),
        _createVNode(_component_multi_json_input, {
          onInput: _ctx.handleTopicChange,
          topics: _ctx.formData.config.topicToChannel
        }, null, 8, ["onInput", "topics"]),
        _createVNode(_component_tags_input, {
          label: _ctx.$t('Integrations.Channels'),
          tags: _ctx.formData.config.channels,
          onOnTagMutate: _cache[2] || (_cache[2] = (tags) => (_ctx.formData.config.channels = tags))
        }, null, 8, ["label", "tags"]),
        _createVNode(_component_input_field, {
          modelValue: _ctx.formData.executionOrder,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.executionOrder) = $event)),
          label: _ctx.$t('Integrations.ExecutionOrder'),
          type: "number",
          errorMessage: _ctx.v$?.formData['executionOrder']?.$errors[0]?.$message,
          isError: _ctx.v$?.formData['executionOrder']?.$error
        }, null, 8, ["modelValue", "label", "errorMessage", "isError"]),
        _createVNode(_component_checkbox_input, {
          modelValue: _ctx.formData.isActive,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.isActive) = $event)),
          "is-checked": _ctx.formData.isActive,
          label: "Active"
        }, null, 8, ["modelValue", "is-checked"])
      ]),
      _: 1
    }),
    _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
  ], 32))
}