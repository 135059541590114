<template>
  <create-form
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getAllDeviceActions"
    @toggle-modal="isCreateModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :device-action-prop="selectedDeviceAction"
    :is-edit-modal-opened="isEditModalOpened"
    :refresh-data="getAllDeviceActions"
    @toggle-modal="isEditModalOpened = false"
  />
  <delete-modal
    v-if="isDeleteModalOpened"
    :is-deleting="isDeleteModalOpened"
    :is-submit-loading="isDeleteLoading"
    @cancel-delete="isDeleteModalOpened = false"
    @toggle-modal="isDeleteModalOpened = false"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    delete-text="delete"
    @confirm-action="deleteDeviceAction"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <main-section>
    <loader
      v-if="isLoading"
      inner-ring-thickness="border-t-8"
      outer-ring-thickness="border-8"
    />
    <div v-else>
      <table-action-header
        button-text="Create"
        :permission-id="permissions.CreateDeviceAction"
        title="Asset actions"
        description=" "
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="deviceActions"
        :table-headers="tableHeaders"
        @force-refresh="getAllDeviceActions()"
        @commit-action="commitDeviceAction"
        @selected-rows="getSelectedRows"
      />
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import DeviceActionService, {
  DeviceAction,
} from "../Services/DeviceActionService";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import Loader from "@/core/Components/Loader.vue";
import TheButton from "@/core/Components/TheButton.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CreateForm from "../Components/CreateForm.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import EditForm from "../Components/EditForm.vue";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    DeleteModal,
    ConfirmationModal,
    TableActionHeader,
    MainSection,
    ToastComponent,
    Loader,
    TheButton,
    CustomTable,
    CreateForm,
    EditForm,
  },
  data() {
    return {
      deviceActionService: new DeviceActionService(),
      deviceActions: [] as DeviceAction[],
      selectedDeviceAction: {} as DeviceAction,
      toast: useToast(),
      isLoading: false,
      isCreateModalOpened: false,
      isDataSourceLoading: false,
      isEditModalOpened: false,
      isDeleteModalOpened: false,
      isConfirmationModalOpened: false,
      isDeleteLoading: false,
      permissions: Permissions,
      tableHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "Name", visible: true },
        "device.name": { header: "Device", visible: true },
        "deviceActionType.name": {
          header: "Asset action type",
          visible: true,
        },
        commitHeader: {
          header: "Commit action",
          visible: true,
        },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditDeviceAction,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteDeviceAction,
          action: this.toggleDeleteModal,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getAllDeviceActions();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async commitDeviceAction(deviceActionId: number) {
      try {
        await this.deviceActionService.commitDeviceAction(deviceActionId);
        this.toast.success("Asset action committed successfully!");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getAllDeviceActions(pageNumber?: number, pageSize?: number) {
      this.isDataSourceLoading = true;
      try {
        this.deviceActions = await this.deviceActionService.getAllDeviceActions(
          1,
          100000
        );
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async deleteDeviceAction() {
      this.isDeleteLoading = true;
      try {
        await this.deviceActionService.deleteAction(
          this.selectedDeviceAction.id
        );
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isConfirmationModalOpened = false;
        this.isDeleteModalOpened = false;
        await this.getAllDeviceActions();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDeleteLoading = false;
      }
    },
    getSelectedRows(values: DeviceAction[]) {
      if (values.length) {
        this.selectedDeviceAction = values[0];
      }
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
  },
});
</script>
<style scoped></style>
