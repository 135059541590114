import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full items-center gap-4 px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isZoneCreateModalOpened,
    "remove-outside-click": true,
    title: _ctx.$t('Zone.CreateTitle'),
    onToggleModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createVNode(_component_input_field, {
                  modelValue: _ctx.zone.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.zone.name) = $event)),
                  label: _ctx.$t('Zone.Name'),
                  errorMessage: _ctx.v$?.zone['name']?.$errors[0]?.$message,
                  isError: _ctx.v$?.zone['name']?.$error,
                  required: true
                }, null, 8, ["modelValue", "label", "errorMessage", "isError"]),
                _createVNode(_component_input_field, {
                  modelValue: _ctx.zone.floorLevel,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.zone.floorLevel) = $event)),
                  label: _ctx.$t('Zone.Floor'),
                  type: 'number'
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_color_picker, {
                  modelValue: _ctx.zone.color,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.zone.color) = $event)),
                  label: _ctx.$t('Zone.Color')
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_the_button, {
                text: _ctx.$t('Cancel'),
                size: "full",
                variant: "outline",
                onExecuteMethod: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggle-modal')))
              }, null, 8, ["text"]),
              _createVNode(_component_the_button, {
                text: _ctx.$t('Save'),
                size: "full"
              }, null, 8, ["text"])
            ])
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}