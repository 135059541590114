<template>
  <!--   <div :class="`${!noGrid && 'grid grid-cols-5 items-center'}  py-4`">
    <label v-if="label">{{ label }}</label>
    <div :class="`${!noGrid && 'col-span-4'} ${noGrid && 'mt-2'} flex flex-col justify-center w-full relative`">
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
        :class="`p-2 leading-normal w-full border ${
          isError ? 'border-error' : 'border-grey'
        } text-dark bg-white font-sans rounded-md text-left appearance-none relative outline-none focus:border-primary  shadow-sm`"
      />
    </div>
    <span v-if="isError" class="error-message text-sm text-error">{{ errorMessage }}</span>
  </div> -->
  <div
    :class="`${noPadding ? 'py-0' : 'py-3'} w-full ${
      isLabelInFrontInput && 'mb-1 flex items-center space-x-2'
    }`"
  >
    <label
      v-if="label"
      class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
      >{{ label
      }}<span v-if="required" class="pl-0.5 text-orange-600">*</span></label
    >
    <div :class="` relative mt-1 flex w-full flex-col justify-center`">
      <input
        id="input"
        :class="`w-full border p-2 leading-normal ${
          isError ? 'border-error' : 'border-[#dee1e3]'
        } relative appearance-none bg-white text-left font-sans text-dark shadow-sm outline-none  focus:border-primary`"
        :disabled="disabled ? disabled : false"
        :placeholder="placeholder"
        :type="type ? type : 'text'"
        :value="modelValue"
        @input="
          $emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
      />
    </div>
    <span v-if="isError" class="error-message text-sm text-error">{{
      errorMessage
    }}</span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: [
    "modelValue",
    "isError",
    "label",
    "errorMessage",
    "required",
    "noGrid",
    "placeholder",
    "type",
    "disabled",
    "noPadding",
    "isLabelInFrontInput",
  ],
  emits: ["update:modelValue"],
});
</script>
<style scoped>
.error-message {
  margin-top: 0.5rem;
}

#input:disabled {
  @apply cursor-not-allowed bg-gray-100;
}
</style>
