<template>
  <div class="py-4">
    <ul class="grid w-full gap-6 md:grid-cols-2">
      <li>
        <input
          id="hosting-small"
          :value="modelValue"
          checked
          class="hidden peer"
          name="hosting"
          type="radio"
          @change="$emit('update:modelValue', 'device')"
        />
        <label
          class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          for="hosting-small"
        >
          <div class="block">
            <font-awesome-icon :icon="['fas', 'tablet-screen-button']" />
            <div class="w-full text-lg font-semibold">Device</div>
            <div class="w-full text-sm">Give user permission for a device</div>
          </div>
        </label>
      </li>
      <li>
        <input
          id="hosting-big"
          :value="modelValue"
          class="hidden peer"
          name="hosting"
          type="radio"
          @change="$emit('update:modelValue', 'company')"
        />
        <label
          class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-primary peer-checked:border-primary peer-checked:text-primary hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
          for="hosting-big"
        >
          <div class="block">
            <font-awesome-icon :icon="['fas', 'building']" />
            <div class="w-full text-lg font-semibold">Company</div>
            <div class="w-full text-sm">
              Give user permission for everything in company
            </div>
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default defineComponent({
  components: { FontAwesomeIcon },
  props: ["modelValue"],
  emits: ["update:modelValue"],
});
</script>

<style scoped></style>
