import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createBlock(_component_main_section, null, {
    default: _withCtx(() => [
      _createElementVNode("iframe", {
        src: _ctx.nodeRedSrc,
        width: "100%",
        height: "100%",
        frameborder: "0",
        allowfullscreen: ""
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}