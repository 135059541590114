import {
  AssignParkingSpot,
  CreateBulkSpot,
  CreateParkingSpot,
  OverrideParkingSpot,
  ParkingSpot,
  ParkingSpotMap,
  ParkingSpotType,
  UpdateParkingSpot,
} from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class ParkingSpotService {
  async getParkingSpots({
    pageNumber = 1,
    pageSize = 100000,
  }: {
    pageNumber: number;
    pageSize: number;
  }): Promise<ParkingSpot[]> {
    const url = GenerateUrl({
      path: "/api/spots",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

    const response = await ApiService.get(url);
    return this._mapParkingSpots(response.data.items);
  }

  async getParkingSpotByIdentifier(id: number): Promise<ParkingSpot> {
    const url = GenerateUrl({
      path: `/api/spots/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.get(url);
    return this._mapParkingSpot(response.data);
  }

  async getParkingSpotsForMap(
    parkingSpaceIds: number[]
  ): Promise<ParkingSpotMap[]> {
    const url = GenerateUrl({
      path: `/api/spots/map`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        parkingSpaceIds: parkingSpaceIds,
      },
    });

    const response = await ApiService.get(url);
    return this._mapParkingSpotsForMap(response.data);
  }

  async getParkingSpotsForMapBySpaceId(
    spaceId: number
  ): Promise<ParkingSpot[]> {
    const url = GenerateUrl({
      path: `/api/spots/space/${spaceId}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.get(url);
    return this._mapParkingSpots(response.data);
  }

  async getParkingSpotTypes(): Promise<ParkingSpotType[]> {
    const url = GenerateUrl({
      path: `/api/spots/types`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.get(url);
    return this._mapParkingSpotTypes(response.data);
  }

  async createSpot(parkingSpot: CreateParkingSpot): Promise<any> {
    const url = GenerateUrl({
      path: `/api/spots`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.post(url, parkingSpot);
  }

  async createBulkSpots(spotsBulk: CreateBulkSpot): Promise<any> {
    const url = GenerateUrl({
      path: `/api/spots/bulk/${spotsBulk.spaceId}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.post(url, spotsBulk);
  }

  async updateParkingSpot(parkingSpot: UpdateParkingSpot): Promise<any> {
    const url = GenerateUrl({
      path: `/api/spots/${parkingSpot.id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.put(url, parkingSpot);
  }

  async assignParkingSpot(parkingSpot: AssignParkingSpot): Promise<number> {
    const url = GenerateUrl({
      path: `/api/spots/assign`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.post(url, parkingSpot);
    return response.data;
  }

  async overrideParkingSpot(parkingSpot: OverrideParkingSpot) {
    const url = GenerateUrl({
      path: `/api/spots/override`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.post(url, parkingSpot);
  }

  async bulkParkingSpotEdit({
    parkingSpots,
    spaceId,
  }: {
    parkingSpots: UpdateParkingSpot[];
    spaceId: number;
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/spots/bulk/${spaceId}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.put(url, parkingSpots);
  }

  private _mapParkingSpots(response: ParkingSpot[]): ParkingSpot[] {
    return response.map((parkingSpot: ParkingSpot) =>
      this._mapParkingSpot(parkingSpot)
    );
  }

  private _mapParkingSpot(parkingSpot: ParkingSpot): ParkingSpot {
    return {
      id: parkingSpot.id,
      name: parkingSpot.name,
      occupied: parkingSpot.occupied,
      occupiedTimestamp: parkingSpot.occupiedTimestamp,
      latitude: parkingSpot.latitude,
      longitude: parkingSpot.longitude,
      index: parkingSpot.index,
      public: parkingSpot.public,
      spaceId: parkingSpot.spaceId,
      space: parkingSpot.space,
      typeId: parkingSpot.typeId,
      type: parkingSpot.type,
      visible: parkingSpot.visible,
      macAddress: parkingSpot?.macAddress,
    };
  }

  private _mapParkingSpotsForMap(response: ParkingSpotMap[]): ParkingSpotMap[] {
    return response.map((parkingSpot: ParkingSpotMap) =>
      this._mapParkingSpotForMap(parkingSpot)
    );
  }

  private _mapParkingSpotForMap(parkingSpot: ParkingSpotMap): ParkingSpotMap {
    return {
      id: parkingSpot.id,
      latitude: parkingSpot.latitude,
      longitude: parkingSpot.longitude,
      occupied: parkingSpot.occupied,
      occupiedTimestamp: parkingSpot.occupiedTimestamp,
      offline: parkingSpot.offline,
      typeId: parkingSpot.typeId,
      spaceName: parkingSpot.spaceName,
      spotName: parkingSpot.spotName,
    };
  }

  private _mapParkingSpotTypes(response: ParkingSpotType[]): ParkingSpotType[] {
    return response.map((parkingSpotType: ParkingSpotType) =>
      this._mapParkingSpotType(parkingSpotType)
    );
  }

  private _mapParkingSpotType(
    parkingSpotType: ParkingSpotType
  ): ParkingSpotType {
    return {
      id: parkingSpotType.id,
      name: parkingSpotType.name,
    };
  }
}
