import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tabs max-w-8/12 flex" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        onClick: ($event: any) => (_ctx.selectedTab = tab.id)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([`${_ctx.selectedTab === tab.id && 'border-primary'}`, "cursor-pointer border-b-4 p-2.5 duration-300 ease-in-out hover:bg-grey"]),
          onClick: ($event: any) => (_ctx.$emit('changeTab', tab.id))
        }, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(tab.label), 1)
        ], 10, _hoisted_3)
      ], 8, _hoisted_2))
    }), 256))
  ]))
}