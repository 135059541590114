import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./core/App.vue";
import router from "./core/Router";
import "devextreme/dist/css/dx.light.compact.css";
import "./index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-orange/theme.css";
import "vue-select/dist/vue-select.css";
import KeycloakService from "./core/Keycloak/KeycloakService";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import VueClickAway from "vue3-click-away";
import { plugin as VueTippy } from "vue-tippy";
import "tippy.js/dist/tippy.css";
import useVuelidate from "@vuelidate/core";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import "vue-json-pretty/lib/styles.css";

import "prismjs";
import "../prism-coldark-dark.css";

//@ts-ignore
import print from "vue3-print-nb";
//@ts-ignore
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue3-apexcharts";
import { useDashboardEventsStore } from "@/core/Store/dashboardEventsStore";
import i18n from "./core/i18n/i18n";
import {
  faUserDevice,
  faCustomBluetooth,
  faParkingLocation,
  faParkomDisplay,
  faParkomSensor,
  faWifiSlash,
} from "@/core/CustomIcons/icons";
import "esri-leaflet-geocoder";
import ConfigurationService from "./core/Services/ConfigurationService";
import { useUserStore } from "@/core/Store/userStore";
import ApiService from "./core/Axios/ApiService";

ConfigurationService.loadConfiguration().then(() => {
  //@ts-ignore
  library.add(fas);
  library.add(
    faParkomSensor,
    faParkomDisplay,
    faWifiSlash,
    faParkingLocation,
    faCustomBluetooth,
    faUserDevice
  );

  const pinia = createPinia();
  const app = createApp(App);
  pinia.use(piniaPluginPersistedstate);

  app.use(pinia);

  if (ConfigurationService.configData.LOCAL_RUN === "true") {
    ApiService.init(null, ConfigurationService.configData.LOCAL_JWT);

    const userStore = useUserStore();
    userStore
      .getUserId(
        ConfigurationService.configData.LOCAL_EMAIL!,
        ConfigurationService.configData.LOCAL_USERNAME!
      )
      .then(() => {
        const dashboardEventsStore = useDashboardEventsStore();
        app.config.globalProperties.$v = useVuelidate();
        app
          .use(router)
          .use(PrimeVue, { ripple: true })
          .use(VueClickAway)
          .use(print)
          .use(autoAnimatePlugin)
          .use(VueApexCharts)
          .use(Toast, {
            transition: "Vue-Toastification__bounce",
            toastClassName: "custom",
            bodyClassName: ["custom"],
            maxToasts: 20,
            newestOnTop: true,
            position: "top-right",
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
            timeout: 2500,
          })
          .use(VueTippy, {
            directive: "tippy",
            component: "tippy",
            componentSingleton: "tippy-singleton",
            defaultProps: {
              placement: "top",
              allowHTML: true,
            },
          })
          .use(i18n)
          .component("font-awesome-icon", FontAwesomeIcon)
          .component("apexchart", VueApexCharts)
          .component("multiselect", Multiselect)
          .provide("dashboardEvents", dashboardEventsStore.events)
          .provide("config", ConfigurationService.configData)
          .mount("#app");
      });
  } else {
    const keycloakService = new KeycloakService(
      ConfigurationService.configData.KEYCLOAK_URL,
      ConfigurationService.configData.KEYCLOAK_REALM,
      ConfigurationService.configData.KEYCLOAK_CLIENT_ID,
      ConfigurationService.configData.BASE_URL
    );

    const renderApp = (user: any) => {
      const dashboardEventsStore = useDashboardEventsStore();
      app.config.globalProperties.$v = useVuelidate();
      app
        .use(router)
        .use(PrimeVue, { ripple: true })
        .use(VueClickAway)
        .use(print)
        .use(autoAnimatePlugin)
        .use(VueApexCharts)
        .use(Toast, {
          transition: "Vue-Toastification__bounce",
          toastClassName: "custom",
          bodyClassName: ["custom"],
          maxToasts: 20,
          newestOnTop: true,
          position: "top-right",
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
          timeout: 2500,
        })
        .use(VueTippy, {
          directive: "tippy",
          component: "tippy",
          componentSingleton: "tippy-singleton",
          defaultProps: {
            placement: "top",
            allowHTML: true,
          },
        })
        .use(i18n)
        .component("font-awesome-icon", FontAwesomeIcon)
        .component("apexchart", VueApexCharts)
        .component("multiselect", Multiselect)
        .provide("user", user)
        .provide("dashboardEvents", dashboardEventsStore.events)
        .provide("config", ConfigurationService.configData)
        .provide("keycloakService", keycloakService)
        .mount("#app");

      // //@ts-ignore
      // window.OneSignal = window.OneSignal || [];
      // //@ts-ignore
      // window.OneSignal.push(() => {
      //     //@ts-ignore
      //     window.OneSignal.init({
      //         appId: process.env.VUE_APP_ONESIGNAL_APP_KEY,
      //         allowLocalhostAsSecureOrigin: true,
      //     });
      // });
    };

    keycloakService.initKeycloak(renderApp);
  }
});
