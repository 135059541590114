<template>
  <modal-component
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    :title="$t('BusControllers.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("BusControllers.ParkingSpace") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isParkingSpaceListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="busController.spaceId"
                :class="`mt-1 w-full`"
                :options="parkingSpaces"
                :reduce="(space : any) => space.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <input-field
            v-model="busController.imei"
            :error-message="
              this.v$?.busController['imei']?.$errors[0]?.$message
            "
            :is-error="v$?.busController['imei']?.$error"
            :label="$t('BusControllers.IMEI')"
            :required="true"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import {
  BusController,
  EditBusController,
} from "@/modules/Parkom/Submodules/BusControllers/Services/BusControllerTypes";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import VSelect from "vue-select";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import BusControllerService from "@/modules/Parkom/Submodules/BusControllers/Services/BusControllerService";
import { maxLength, minLength, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

export default defineComponent({
  components: {
    ButtonWrapper,
    VSelect,
    SmallLoader,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isEditModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
    busControllerProp: {
      type: Object as PropType<BusController>,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  async created() {
    try {
      await this.getParkingSpots();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },
  data() {
    return {
      parkingSpaceService: new ParkingSpaceService(),
      busControllerService: new BusControllerService(),
      parkingSpaces: [] as ParkingSpace[],
      toast: useToast(),
      v$: useValidate(),
      isParkingSpaceListLoading: false,
      isSubmitLoading: false,
      busController: {
        id: this.busControllerProp.id,
        imei: this.busControllerProp.imei,
        spaceId: this.busControllerProp.spaceId,
      } as EditBusController,
    };
  },
  validations() {
    return {
      busController: {
        imei: {
          required,
          minLength: minLength(15),
          maxLength: maxLength(15),
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      await this.v$.$validate();
      if (this.v$.$error) {
        return;
      }
      this.isSubmitLoading = true;
      try {
        await this.busControllerService.editBusController(this.busController);
        this.$emit("toggle-modal");
        this.toast.success("Bus controller edited successfully!");
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getParkingSpots() {
      this.isParkingSpaceListLoading = true;
      try {
        this.parkingSpaces = await this.parkingSpaceService.getParkingSpaces({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isParkingSpaceListLoading = false;
      }
    },
  },
});
</script>

<style scoped></style>
