<template>
  <modal
    :is-displayed="true"
    :no-padding="true"
    :title="'User information'"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="$emit('toggle-modal')">
        <div class="flex flex-col gap-2 p-4">
          <p><span class="font-semibold">ID: </span>{{ selectedData.id }}</p>
          <p>
            <span class="font-semibold">Username: </span
            >{{ selectedData.username }}
          </p>
          <p>
            <span class="font-semibold">Email: </span>{{ selectedData.email }}
          </p>
        </div>
        <button-wrapper :text="'Close'" />
      </form>
    </form-wrapper>
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import Modal from "@/core/Components/Modal.vue";

export default defineComponent({
  components: {
    MainSection,
    FormWrapper,
    InputField,
    TheButton,
    ToastComponent,
    ButtonWrapper,
    FormDivider,
    Modal,
  },
  props: ["selectedData"],
  emits: ["toggle-modal"],
  data() {
    return {};
  },
  mounted() {
    console.log(this.selectedData);
  },

  methods: {},
});
</script>
<style scoped></style>
