import { defineStore } from "pinia";
import { MapObjectService } from "@/modules/MapView/Services/MapObjectService";
import { useUserStore } from "@/core/Store/userStore";

export const useFloorStore = defineStore("floorStore", {
  state: () => {
    return {
      floors: [] as number[],
      selectedFloor: null as null | number,
      floorTabs: [] as { id: number; label: string; default?: boolean }[],
      mapObjectService: new MapObjectService(),
      userStore: useUserStore(),
    };
  },

  actions: {
    async getFloors() {
      try {
        this.floors = await this.mapObjectService.getAllAvailableFloors(
          this.userStore.activeCompanyId
        );
        this.selectedFloor = this.floors[0];

        this.floorTabs = this.floors.map((floor, idx) => {
          return {
            id: floor,
            label: `Floor ${floor}`,
            default: idx === 0,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
});
