<template>
  <div
    :class="`transform duration-300 ease-in-out ${
      sidebarStore.isSidebarOpened ? 'translate-x-0' : '-translate-x-10'
    }`"
    :style="itemMargin"
    class="w-full"
  >
    <div v-if="checkIfRouteIsDisplayed(route)" class="flex w-full items-center">
      <router-link
        :key="route?.name"
        v-slot="{ isActive }"
        :to="{ name: route?.name }"
        class="w-full"
      >
        <div
          v-if="route.meta?.showInNavigation"
          :class="`mb-1 flex items-center ${
            !sidebarStore.isSidebarOpened && 'justify-center'
          } text-md space-x-2 ${
            isActive && 'bg-primary '
          } group  rounded-md px-2 py-2 duration-300  ease-in-out hover:bg-primary`"
        >
          <div
            v-tippy="{
              arrow: true,
              content: $t(route.meta?.label),
              display: !sidebarStore.isSidebarOpened,
            }"
            :class="`flex h-5 items-center justify-center text-white`"
          >
            <font-awesome-icon :icon="route.meta?.icon" />
          </div>
          <h2
            v-if="sidebarStore.isSidebarOpened"
            :class="`text-[13px] text-white duration-300 ease-in-out group-hover:scale-105`"
          >
            {{ $t(route.meta?.label) }}
          </h2>
        </div>
      </router-link>

      <font-awesome-icon
        v-if="hasChildren && sidebarStore.isSidebarOpened"
        :class="`absolute right-2 transform cursor-pointer p-2 text-white duration-200 ease-in-out hover:text-primary ${
          showChildren ? 'rotate-90' : 'rotate-0'
        }`"
        :icon="['fas', 'chevron-right']"
        @click="toggleChildren"
      />
    </div>

    <div v-if="hasChildren && showChildren && sidebarStore.isSidebarOpened">
      <div v-for="child in route?.children" :key="child?.name">
        <tree-list
          v-if="
            !child.meta?.authorize ||
            permissionStore.hasPermissionView(child.meta?.authorize)
          "
          :route="child"
          :spacing="spacing + 16"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RouteRecordRaw } from "vue-router";
import { useSidebarStore } from "../Store";
import { useModuleToggleStore } from "../Store/moduleToggleStore";
/* import { Motion, Presence } from "motion/vue"; */
import { directive } from "vue-tippy";
import { usePermissionStore } from "@/core/Store/permissionStore";

export default defineComponent({
  /* components: { Motion, Presence }, */
  directives: {
    tippy: directive,
  },
  props: {
    route: { type: Object as PropType<RouteRecordRaw>, required: true },
    spacing: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      sidebarStore: useSidebarStore(),
      moduleToggleStore: useModuleToggleStore(),
      permissionStore: usePermissionStore(),
      showChildren: false,
    };
  },

  computed: {
    hasChildren() {
      const { children } = this.route;
      if (!children) return false;

      return children.some((child) => child.meta?.showInNavigation);
    },

    itemMargin() {
      return {
        transform: `translateX(${this.spacing}px)`,
        width: `calc(100% - ${this.spacing}px)`,
      };
    },
  },

  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    checkIfRouteIsDisplayed(route: RouteRecordRaw): boolean {
      if (this.checkIfChildRouteIsInTopLevelNavigation(route)) {
        return false;
      }

      return true;
    },

    checkIfChildRouteIsInTopLevelNavigation(route: RouteRecordRaw) {
      //*
      if (route.meta?.dontDisplayOnMainNavigation && this.spacing === 0) {
        return true;
      }
    },
  },
});
</script>
<style scoped></style>
