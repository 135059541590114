import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-2 px-6" }
const _hoisted_2 = { class: "flex items-center justify-between rounded p-3 hover:bg-lightgray" }
const _hoisted_3 = { class: "flex h-10 w-10 items-center justify-center rounded bg-grey text-primary hover:cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_modal_component = _resolveComponent("modal-component")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isStaticActionsModalOpened,
    "need-for-scroll": true,
    title: `Static actions for ${_ctx.device.assignment.name}`,
    onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.device.device.actions, (action) => {
          return (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(action.name), 1),
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'play'] })
            ])), [
              [_directive_tippy, `Run ${action.name} action`]
            ])
          ]))
        }), 256))
      ])
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}