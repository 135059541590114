import * as L from "leaflet";
import * as Geocode from "leaflet-control-geocoder";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class MapService {
  map = {} as L.Map;
  mapElement = {} as HTMLElement;
  mapView: [number, number];
  mapOptions = {} as L.MapOptions | undefined;

  // markerCanvas = new L.MarkersCanvas();

  constructor(
    mapElement: HTMLElement,
    mapView?: [number, number],
    mapOptions?: L.MapOptions
  ) {
    this.mapElement = mapElement;
    this.mapView = [45.07910752265211, 16.89607894788648];
    this.mapOptions = mapOptions;
  }

  async getUserLocation() {
    /* let userIpAdress = "";
    await fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        userIpAdress = ip;
      });
    console.log(userIpAdress); */
    let coordinates = [45.07910752265211, 16.89607894788648];
    navigator.geolocation.getCurrentPosition((position: any) => {
      console.log(position);
      if (position.coords) {
        coordinates = [position.coords.latitude, position.coords.longitude];
      }
    });
    return coordinates as [number, number];
  }

  initMap(coordinates?: [number, number]) {
    if (coordinates) {
      this.mapView = coordinates;
    }

    const googleLayer = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      {
        maxZoom: 21,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    );

    const satelliteLayer = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
      {
        maxZoom: 21,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    );

    this.map = new L.Map(this.mapElement, {
      zoomControl: false,
      layers:
        ConfigurationService.configData.IS_PARKOM == "true"
          ? [satelliteLayer]
          : [googleLayer],
      boxZoom: true,
      ...(this.mapOptions && { ...this.mapOptions }),
    }).setView(this.mapView, 8);

    const geocoder = Geocode.geocoder({
      defaultMarkGeocode: false,
      showUniqueResult: true,
      collapsed: false,
    })
      .on("markgeocode", (e) => {
        console.log(e);
        const boundingBox = e.geocode.bbox;
        this.map.fitBounds(boundingBox);
      })
      .addTo(this.map);

    const baseMaps = {
      Google: googleLayer,
      Satellite: satelliteLayer,
    };

    L.control
      .layers(baseMaps, undefined, {
        position: "bottomright",
      })
      .addTo(this.map);

    this.map.on("baselayerchange", function (e) {
      e.target.invalidateSize();
    });
  }
}
