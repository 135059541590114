import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex h-full w-full flex-col items-center justify-center px-6" }
const _hoisted_2 = {
  class: "mt-4 flex w-full cursor-pointer flex-col items-center justify-center space-x-3 border border-dashed border-primary p-8",
  for: "file-upload"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "mt-2"
}
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { class: "mt-4 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_the_button = _resolveComponent("the-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_input_field, {
      modelValue: _ctx.objectName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.objectName) = $event)),
      "no-padding": true,
      label: "Name"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_input_field, {
      modelValue: _ctx.selectedFloor,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedFloor) = $event)),
      label: "Floor",
      type: "number"
    }, null, 8, ["modelValue"]),
    _createElementVNode("label", _hoisted_2, [
      (_ctx.fileSrc === '')
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: ['fas', 'image'],
            class: "text-6xl text-primary"
          }))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.fileSrc,
            class: "w-5/12"
          }, null, 8, _hoisted_3)),
      (_ctx.fileName === '')
        ? (_openBlock(), _createElementBlock("h2", _hoisted_4, "Choose a file to upload"))
        : (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString(_ctx.fileName), 1))
    ]),
    _createElementVNode("input", {
      id: "file-upload",
      class: "hidden",
      type: "file",
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.getImageData && _ctx.getImageData(...args)))
    }, null, 32),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_the_button, {
        size: "full",
        text: "Upload image",
        variant: "default",
        onExecuteMethod: _ctx.imageSubmit
      }, null, 8, ["onExecuteMethod"])
    ])
  ]))
}