import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-full w-full items-center justify-center" }
const _hoisted_2 = { class: "relative items-center px-6 py-4" }
const _hoisted_3 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_4 = { class: "relative items-center px-6" }
const _hoisted_5 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_wrapper, {
      "is-form-in-modal": _ctx.isFormInModal,
      "is-loading": _ctx.isLoading,
      "need-for-scroll": true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("Integrations.IntegrationType")), 1),
          _createVNode(_component_v_select, {
            modelValue: _ctx.integrationTypeId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.integrationTypeId) = $event)),
            options: _ctx.integrationTypes,
            reduce: (spot) => spot.id,
            class: "mt-1",
            label: "name",
            clearable: false
          }, null, 8, ["modelValue", "options", "reduce"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("Integrations.Group")), 1),
          _createVNode(_component_v_select, {
            modelValue: _ctx.applicationId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.applicationId) = $event)),
            options: _ctx.applications,
            reduce: (spot) => spot.id,
            class: "mt-1",
            label: "name",
            clearable: false
          }, null, 8, ["modelValue", "options", "reduce"])
        ]),
        _createElementVNode("div", null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.integrationTypeTemplates[_ctx.integrationTypeId]), {
            "application-id": _ctx.applicationId,
            "integration-type-id": _ctx.integrationTypeId,
            "refresh-data": _ctx.refreshData
          }, null, 8, ["application-id", "integration-type-id", "refresh-data"]))
        ])
      ]),
      _: 1
    }, 8, ["is-form-in-modal", "is-loading"])
  ]))
}