<template>
  <modal-component
    :is-displayed="isZoneCreateModalOpened"
    :remove-outside-click="true"
    :title="$t('Zone.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="zone.name"
            :label="$t('Zone.Name')"
            :errorMessage="v$?.zone['name']?.$errors[0]?.$message"
            :isError="v$?.zone['name']?.$error"
            :required="true"
          />
          <input-field
            v-model="zone.floorLevel"
            :label="$t('Zone.Floor')"
            :type="'number'"
          />

          <color-picker v-model="zone.color" :label="$t('Zone.Color')" />
        </form-divider>
        <div class="flex w-full items-center gap-4 px-6">
          <the-button
            :text="$t('Cancel')"
            size="full"
            variant="outline"
            @execute-method="$emit('toggle-modal')"
          />
          <the-button :text="$t('Save')" size="full" />
        </div>
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import TheButton from "@/core/Components/TheButton.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import ColorPicker from "@/core/Components/FormElements/ColorPicker.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { MapObjectService } from "@/modules/MapView/Services/MapObjectService";
import { useUserStore } from "@/core/Store/userStore";
import { MapObjectCreate } from "@/modules/MapView/Services/Types/MapObjectTypes";
import { Zone } from "@/modules/MapView/Services/Types/ZoneTypes";
import { useFloorStore } from "@/core/Store/floorStore";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    InputField,
    FormDivider,
    FormWrapper,
    TheButton,
    ModalComponent,
    ColorPicker,
  },
  props: {
    isZoneCreateModalOpened: Boolean,
    wktCoordinates: String,
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      mapObjectService: new MapObjectService(),
      toast: useToast(),
      userStore: useUserStore(),
      isSubmitLoading: false,
      floorStore: useFloorStore(),
      zone: {
        name: "",
        color: "",
        floorLevel: null,
      },
      v$: useValidate() as any,
      validationErrors: [] as any,
    };
  },
  validations() {
    return {
      zone: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          const mapObject: MapObjectCreate = {
            userId: this.userStore.userId,
            companyId: this.userStore.activeCompanyId,
            geometry: this.wktCoordinates ?? "",
            floorLevel: this.zone.floorLevel
              ? Number(this.zone.floorLevel)
              : null,
            typeId: 2,
            data: {
              name: this.zone.name,
              fillColor: this.zone.color,
            } as Zone,
          };
          await this.mapObjectService.createMapObject(mapObject);
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
          await this.floorStore.getFloors();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
  },
});
</script>

<style scoped></style>
