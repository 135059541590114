import {DeviceTypeConfiguration} from "@/modules/MapView/Services/Types/DeviceTypes";
import {DeviceData} from "@/modules/MapView/Services/Types/DataTypes";
import {ApexOptions} from "apexcharts";
import {FormatDateTime} from "@/core/Services/GlobalFunctions";

export default class GraphService {

    deviceTypeConfiguration: DeviceTypeConfiguration[] = [];
    data: DeviceData[] = [];

    constructor() {
    }

    generateSeries({
                       deviceTypeConfiguration,
                       data
                   }: { deviceTypeConfiguration: DeviceTypeConfiguration[]; data: DeviceData[] }): { series: ApexAxisChartSeries, options: ApexOptions }[] {
        this.data = data;
        this.deviceTypeConfiguration = deviceTypeConfiguration;
        const keys = Object.keys(this.data[0].payload.records)
        const seriesAndOptions = [] as { series: ApexAxisChartSeries, options: ApexOptions }[]
        const series = [] as ApexAxisChartSeries;
        const options = [] as ApexOptions[];

        keys.forEach((key) => {
            const config = this.deviceTypeConfiguration.find((config) => config.data.id === Number(key))
            if (!config) {
                return
            }

            const singleSeries = this._generateSingleSeries(config, key) || {name: "", data: []}
            const options = this.generateOptions(config)
            seriesAndOptions.push({series: [singleSeries], options: options})
        });
        return seriesAndOptions;
    }

    generateOptions(config: DeviceTypeConfiguration): ApexOptions {
        return {
            chart: {
                id: "apex-chart",
                zoom: {
                    enabled: true,
                    type: 'x',
                }
            },
            title: {
                text: config.data.idName
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                type: "datetime",
                labels: {
                    formatter: (date: string) => {
                        return FormatDateTime(date)
                    },
                    style: {
                        fontSize: "10px"
                    }
                },
            },
            yaxis: {
                labels: {
                    formatter: (value: number) => {
                        return `${value.toFixed(2)} ${config.data.unitSymbol}`
                    }
                }
            },
            colors: [config.data.hexColor],
        }
    }


    private _generateSingleSeries(config: DeviceTypeConfiguration, key: string) {
        return {name: config.data.idName, data: this._mapData(key, config)}
    }

    private _mapData(key: string, config: DeviceTypeConfiguration): { y: number | null; x: string }[] {
        return this.data.map((item) => {
            return {y: item.payload.records[key].isValid ? item.payload.records[key].value : null, x: item.dateTime};
        });
    }

    private _labelFormatter() {

    }
}