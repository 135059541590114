<template>
  <div
      :class="`flex w-full items-center ${
      hasPermission ? 'justify-between' : 'justify-start'
    } mb-4`"
  >
    <div class="flex items-center gap-2">
      <h6 class="mb-0 text-xl font-semibold leading-normal">{{ title }}</h6>
      <font-awesome-icon
          v-if="description"
          v-tippy="description"
          :icon="['fas', 'circle-info']"
      />
    </div>
    <div class="flex items-center gap-4">
      <slot></slot>
      <the-button
          v-if="hasPermission && buttonText"
          :icon="icon"
          :text="$t(buttonText || '')"
          button-type="primaryButton"
          type="button"
          @execute-method="$emit('execute-method')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import TheButton from "@/core/Components/TheButton.vue";
import {usePermissionStore} from "@/core/Store/permissionStore";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {directive} from "vue-tippy";

export default defineComponent({
  components: {FontAwesomeIcon, TheButton},
  props: {
    title: String,
    buttonText: String,
    icon: Array as PropType<string[]>,
    permissionId: Number,
    description: String,
  },
  data() {
    return {
      hasPermission: true,
      permissionStore: usePermissionStore(),
    };
  },
  directives: {
    tippy: directive,
  },
  emits: ["execute-method"],
  created() {
    if (!this.permissionId) {
      return;
    }
    this.hasPermission = this.permissionStore.hasPermission(this.permissionId);
  },
});
</script>

<style scoped></style>
