<template>
  <div class="bg-white p-2 shadow-md">
    <table class="w-300">
      <tbody>
        <tr
          v-for="assetDetail in getAssetDetails(asset.data)"
          :key="assetDetail.key"
        >
          <td
            class="px-2 py-2 text-left font-bold"
            style="width: 100px; white-space: normal"
          >
            {{ assetDetail.key }}
          </td>
          <td
            class="px-2 py-2 text-left"
            style="width: 200px; white-space: normal"
          >
            {{ assetDetail.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DxForm } from "devextreme-vue/form";
import { DxList } from "devextreme-vue";
import { JSONPath } from "jsonpath-plus";

export default defineComponent({
  components: {
    DxForm,
    DxList,
  },
  props: ["asset", "socketData"],
  data() {
    return {
      items: ["Address", "City"],
    };
  },
  methods: {
    getAssetDetails(asset: any) {
      const deviceUuid = asset.device.deviceUuid;
      const webSocketItem = this.socketData.get(deviceUuid);
      const channel = webSocketItem ? webSocketItem[2].channel : null;
      const metadataItem = asset.assignment.metadata.find(
        (item: any) => item.metadataTypeId === 2
      );

      if ((channel || channel == 0) && metadataItem) {
        const paths = metadataItem.data.paths.filter((path: any) =>
          path.channels.includes(channel)
        );

        return paths.map((path: any) => {
          const value = JSONPath({
            path: path.path,
            json: webSocketItem[2],
          });

          return { key: path.name, value: value && value[0] };
        });
      }

      return [];
    },
  },
});
</script>
<style scoped>
th,
td {
  word-wrap: break-word;
}
</style>
