<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    :title="$t('Sensors.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form @submit.prevent="handleSubmit">
      <form-wrapper :is-form-in-modal="true">
        <form-divider>
          <input-field
            v-model="sensor.mac"
            :error-message="this.v$?.sensor['mac']?.$errors[0]?.$message"
            :is-error="v$?.sensor['mac']?.$error"
            :label="$t('Sensors.MAC')"
            :required="true"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form-wrapper>
    </form>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import SensorService from "@/modules/Parkom/Submodules/Sensors/Services/SensorService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import useValidate from "@vuelidate/core";
import { macAddress, required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    ButtonWrapper,
    FormDivider,
    FormWrapper,
    ModalComponent,
    InputField,
  },
  props: {
    isCreateModalOpened: Boolean,
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      isSubmitLoading: false,
      sensorService: new SensorService(),
      toast: useToast(),
      v$: useValidate(),
      sensor: {
        mac: "",
      },
    };
  },
  validations() {
    return {
      sensor: {
        mac: {
          required,
          macAddress: macAddress(":"),
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      await this.v$.$validate();
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          await this.sensorService.createSensor(this.sensor);
          this.toast.success("Sensor created successfully!");
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
  },
});
</script>

<style scoped></style>
