import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_modal = _resolveComponent("modal")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isEditModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      "no-padding": true,
      title: _ctx.$t('Integrations.EditTitle'),
      onToggleModal: _ctx.toggleEditModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_edit_form, {
          "is-form-in-modal": true,
          "object-id": _ctx.selectedIntegration.id,
          "refresh-data": _ctx.getAllIntegrations
        }, null, 8, ["object-id", "refresh-data"])
      ]),
      _: 1
    }, 8, ["is-displayed", "title", "onToggleModal"]),
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isCreateModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      "no-padding": true,
      title: _ctx.$t('Integrations.CreateTitle'),
      onToggleModal: _ctx.toggleCreateModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_create_form, {
          "is-form-in-modal": true,
          "refresh-data": _ctx.getAllIntegrations
        }, null, 8, ["refresh-data"])
      ]),
      _: 1
    }, 8, ["is-displayed", "title", "onToggleModal"]),
    _createVNode(_component_delete_modal, {
      "is-deleting": _ctx.isDeleting,
      "selected-data": _ctx.selectedIntegration,
      "delete-text": "Are you sure you want to delete integration ",
      onToggleModal: _ctx.toggleDeleteModal,
      onCancelDelete: _ctx.toggleDeleteModal,
      onConfirmDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isConfirmationModalOpened = true))
    }, null, 8, ["is-deleting", "selected-data", "onToggleModal", "onCancelDelete"]),
    _createVNode(_component_confirmation_modal, {
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "delete-text": "delete",
      onCloseConfirmationModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onConfirmAction: _ctx.deleteIntegration
    }, null, 8, ["is-confirmation-modal-opened", "onConfirmAction"]),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, {
              key: 0,
              "inner-ring-thickness": "border-t-8",
              "outer-ring-thickness": "border-8"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateIntegration,
                title: _ctx.$t('Integrations.Title'),
                "button-text": "Create",
                description: "Integrations are used to route data from outside sources into the platform and routing data from the platform to the external systems.",
                onExecuteMethod: _ctx.createIntegration
              }, null, 8, ["permission-id", "title", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                options: _ctx.options,
                "table-data": _ctx.integrations,
                "table-headers": _ctx.tableHeaders,
                onSelectedRows: _ctx.getSelectedRows,
                onForceRefresh: _ctx.getAllIntegrations
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onForceRefresh"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}