<template>
  <main-section>
    <tabs-component :tabs="tabs" @change-tab="handleTabChange" />
    <loader v-if="isLoading" />
    <div v-else>
      <input-field v-model="filter" placeholder="Filter resources" />
      <grid v-if="resources.length" :column-number="4">
        <device-card
          v-for="resource in resources"
          :last-sent-data="lastDevicesSentData"
          :resource="resource"
        />
      </grid>
      <div
        v-else
        class="flex h-full w-full flex-col items-center justify-center space-y-2"
      >
        <empty-state page-name="resources" />
        <the-button
          :icon="['fas', 'plus']"
          button-type="primaryButton"
          text="Add resource"
          @execute-method="redirectToResources"
        />
      </div>
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TabsComponent from "@/core/Components/TabsComponent.vue";
import TheButton from "@/core/Components/TheButton.vue";
import EmptyState from "@/core/Components/EmptyState.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import MainSection from "@/core/Components/MainSection.vue";
import DeviceCard from "../Components/DeviceCard.vue";
import Grid from "@/core/Components/Grid.vue";
import Loader from "@/core/Components/Loader.vue";
import AccessManagerService from "../Services/AccessManagerService";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useUserStore } from "@/core/Store/userStore";
import { useToast } from "vue-toastification";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import DeviceDataService, {
  LastDeviceAssignmentData,
} from "@/modules/Devices/Services/DeviceDataService";

export default defineComponent({
  components: {
    TabsComponent,
    TheButton,
    EmptyState,
    InputField,
    MainSection,
    DeviceCard,
    Grid,
    Loader,
  },

  data() {
    return {
      isLoading: false,
      resources: [] as DeviceAssignment[],
      filteredResources: [] as DeviceAssignment[],
      accessManagerService: new AccessManagerService(),
      deviceDataService: new DeviceDataService(),
      toast: useToast(),
      lastDevicesSentData: [] as LastDeviceAssignmentData[],
      filter: "",
      userStore: useUserStore(),
      changeCompanyStore: useChangeCompanyStore(),
      tabs: [
        { id: "company", label: "Company resources", default: true },
        { id: "shared", label: "Shared resources" },
      ],
      selectedTab: "company",
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.handleTabChange("company");
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.handleTabChange(this.selectedTab);
    },
    filter(newValue: string) {
      this.resources = this.filteredResources.filter(
        (resource: DeviceAssignment) =>
          resource!
            .assignment!.name.toLowerCase()
            .includes(newValue.toLowerCase())
      );
    },
  },

  methods: {
    redirectToResources() {
      (this as any).$router.push({ name: "accessManagerResources" });
    },
    async handleTabChange(tabId: string) {
      this.selectedTab = tabId;
      switch (this.selectedTab) {
        case "company":
          await this.getCompanyResources();
          break;
        case "shared":
          await this.getSharedResources();
          break;
      }
    },
    async getCompanyResources() {
      this.isLoading = true;
      try {
        this.resources = await this.accessManagerService.getCompanyResources();
        this.filteredResources = this.resources;
        await this.getLastData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isLoading = false;
      }
    },

    async getSharedResources() {
      this.isLoading = true;
      try {
        this.resources = await this.accessManagerService.getSharedResources();
        this.filteredResources = this.resources;
        await this.getLastData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isLoading = false;
      }
    },

    async getLastData() {
      const uuids = this.resources.map(
        (resource: DeviceAssignment) => resource.assignment!.dataUuid
      );
      if (!uuids.length) {
        return;
      }

      this.lastDevicesSentData =
        await this.deviceDataService.getDeviceAssignmentLastData({
          dataUuids: uuids,
          pageNumber: 1,
          pageSize: 100000,
        });
    },
  },
});
</script>
<style scoped></style>
