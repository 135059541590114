import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-full justify-center"
}
const _hoisted_2 = {
  key: 1,
  class: "px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_dx_tree_view = _resolveComponent("dx-tree-view")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isSelectorModalOpened,
    title: _ctx.$t('SidebarMenu.SelectParkingSpaces'),
    onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_small_loader)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_dx_tree_view, {
              id: "parking-space-treeview",
              items: _ctx.parkingSpaceFilterStore.parkingSpaceTreeList,
              "select-nodes-recursive": true,
              "data-structure": "plain",
              "display-expr": "label",
              "key-expr": "id",
              "parent-id-expr": "categoryId",
              "selection-mode": "multiple",
              "show-check-boxes-mode": "selectAll",
              onSelectionChanged: _ctx.onSelectionChanged
            }, null, 8, ["items", "onSelectionChanged"])
          ]))
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}