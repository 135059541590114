import { GraphConfig } from "@/modules/MapView/Services/Types/UserCustomisableGraphTypes";
import { FormatTime } from "@/core/Services/GlobalFunctions";
import { ApexOptions } from "apexcharts";

export default class UserCustomisableGraphService {
  generateGraphSeriesOptions(
    {
      yPropertyConfig,
      xPropertyName,
      graphType,
      seriesNames,
      graphTitle,
      colors,
      types,
    }: GraphConfig,
    data: any[]
  ): { series: ApexAxisChartSeries; options: ApexOptions } {
    const series: ApexAxisChartSeries = [];
    seriesNames.forEach((name, idx) => {
      const generatedSeries = this._generateSeries(
        name,
        yPropertyConfig[idx].id,
        xPropertyName,
        data,
        types && types[idx]
      );

      series.push(generatedSeries);
    });

    const options = this._generateOptions(
      graphTitle,
      graphType,
      colors,
      yPropertyConfig
    );

    return { series, options };
  }

  private _generateSeries(
    seriesName: string,
    yPropertyName: string,
    xPropertyName: string,
    data: any[],
    type?: string
  ) {
    return {
      name: seriesName,
      data: this._mapData(yPropertyName, xPropertyName, data),
      ...(type && { type: type }),
    };
  }

  private _generateOptions(
    graphTitle: string,
    graphType: any,
    colors: string[],
    yaxisConfig: {
      id: string;
      visible: boolean;
      opposite: boolean;
      title: string;
      min: number;
      max?: number;
    }[]
  ): ApexOptions {
    return {
      chart: {
        id: "apex-chart",
        type: graphType,
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: true,
          type: "x",
        },
        stacked: true,
      },
      stroke: {
        curve: ["straight", "straight", "smooth"],
      },
      title: {
        text: graphTitle,
      },
      dataLabels: {
        enabled: false,
      },
      colors: colors,
      xaxis: {
        type: "category",
        labels: {
          formatter: (date: string) => {
            return FormatTime(date);
          },
          style: {
            fontSize: "10px",
          },
        },
      },
      yaxis: yaxisConfig.map((yaxis) => {
        return {
          seriesName: yaxis.id,
          show: yaxis.visible,
          opposite: yaxis.opposite,
          min: yaxis.min,
          ...(yaxis.max && { max: yaxis.max }),
          title: {
            text: yaxis.title,
          },
          labels: {
            formatter: (value: number) => {
              return value.toFixed(0);
            },
          },
        };
      }),
    };
  }

  private _mapData(yPropertyName: string, xPropertyName: string, data: any[]) {
    return data?.map((item) => {
      return { x: item[xPropertyName], y: item[yPropertyName] };
    });
  }
}
