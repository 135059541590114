<template>
  <div>
    <modal
      :is-confirmation-modal="true"
      :is-displayed="isConfirmationModalOpened"
      title="Confirm"
      @toggle-modal="$emit('toggle-modal')"
    >
      <div class="px-6">
        <h2 class="pb-8 pt-6">{{ generateModalText() }}</h2>
        <div class="grid w-full grid-cols-2 gap-4">
          <the-button
            text="No, cancel!"
            variant="outline"
            @execute-method="$emit('close-confirmation-modal')"
          />
          <the-button
            text="Yes, proceed!"
            variant="delete"
            @execute-method="$emit('confirm-action')"
          />
        </div>
      </div>
    </modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TheButton from "../TheButton.vue";
import Modal from "../Modal.vue";

export default defineComponent({
  name: "ConfirmationModal",
  components: { TheButton, Modal },
  props: [
    "deleteText",
    "isConfirmationModalOpened",
    "numberOfDevices",
    "zoneName",
    "objectName",
  ],
  emits: ["close-confirmation-modal", "confirm-action", "toggle-modal"],
  methods: {
    generateModalText() {
      switch (this.deleteText) {
        case "delete":
          return "Are you sure you want to proceed with delete action?";
        case "remove":
          return "Are you sure you want to remove this device?";
        case "unassign":
          return "Are you sure you want to unassign this device?";
        case "approve":
          return "Are you sure you want to approve share to this user?";
        case "reject":
          return "Are you sure you want to reject share to this user?";
        case "revoke":
          return `Are you sure you want to revoke share from user ${this.objectName}?`;
        case "multipleDelete":
          return `Are you sure you want to remove these ${this.numberOfDevices} devices?`;
        case "deleteZone":
          return `Are you sure you want to delete zone ${this.zoneName}?`;
        case "removeUser":
          return "Are you sure you want to remove this user?";
        case "removeSubscription":
          return `Are you sure you want to remove all subscriptions from ${this.objectName}?`;
        case "changeOwner":
          return `Are you sure you want to change the asset owner? This action is irreversible.`;
      }
    },
  },
});
</script>
<style></style>
