<template>
  <delete-modal
    :is-deleting="isDeleting"
    :selected-data="selectedProfileConfiguration"
    delete-text="Are you sure you want to delete "
    @cancel-delete="toggleDeleteModal"
    @toggle-modal="toggleDeleteModal"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <!-- App users delete modal -->
  <delete-modal
    :is-deleting="isDeleteAuthModalOpened"
    :selected-data="selectedProfileConfiguration"
    delete-text="Are you sure you want to delete this authorization resource?"
    @cancel-delete="toggleDeleteAuthModal"
    @toggle-modal="toggleDeleteAuthModal"
    @confirm-delete="openAuthConfirmationModal('delete')"
  />
  <confirmation-modal
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    delete-text="delete"
    @close-confirmation-modal="toggleConfirmationModal"
    @toggle-modal="toggleConfirmationModal"
    @confirm-action="confirmationModalAction()"
  />
  <!-- App users confirmation modal -->
  <confirmation-modal
    :is-confirmation-modal-opened="isAuthConfirmationModalOpened"
    delete-text="delete"
    @close-confirmation-modal="toggleAuthConfirmationModal"
    @toggle-modal="toggleAuthConfirmationModal"
    @confirm-action="authConfirmationModalAction"
  />
  <!-- App users -->
  <modal
    :is-displayed="isCreateUserModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    :no-padding="true"
    :remove-outside-click="true"
    title="Create user permission"
    @toggle-modal="toggleCreateUserModal"
  >
    <create-form :is-form-in-modal="true" :refresh-data="changeTab" />
  </modal>
  <!-- RFID TAG users -->
  <modal
    :is-displayed="isCreateTicketModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    :no-padding="true"
    :remove-outside-click="true"
    title="Create ticket permission"
    @toggle-modal="toggleCreateTicketModal"
  >
    <create-ticket-form
      :is-form-in-modal="true"
      :refresh-data="getAllProfileConfigurations"
    />
  </modal>
  <!-- RFID  users -->
  <modal
    :is-displayed="isEditModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    :no-padding="true"
    :remove-outside-click="true"
    title="Edit profile configuration"
    @toggle-modal="toggleEditModal"
  >
    <edit-form
      :is-form-in-modal="true"
      :object-id="selectedProfileConfiguration.id"
      :assignment-id="selectedProfileConfiguration.assignmentId"
      :refresh-data="getAllProfileConfigurations"
    />
  </modal>
  <!-- App users -->
  <modal
    :is-displayed="isEditAuthModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    :no-padding="true"
    :remove-outside-click="true"
    title="Edit auth resource and policy"
    @toggle-modal="toggleEditAuthModal"
  >
    <edit-auth-form
      :auth-resource-prop="selectedAppUserData"
      :is-form-in-modal="true"
      :refresh-data="changeTab"
    />
  </modal>
  <main-section title="Access manager/Profile configurations">
    <div class="mb-4 flex items-center justify-between">
      <tabs-component :tabs="tabs" @change-tab="changeTab" />
      <div class="flex items-center space-x-2">
        <the-button
          v-if="selectedTabId === 'tickets'"
          v-tippy="'Create profile configuration for user without email'"
          :icon="['fas', 'plus']"
          buttonType="primaryButton"
          text="Ticket"
          @execute-method="createTicketProfileConfiguration"
        />
        <the-button
          v-else
          v-tippy="'Create profile configuration for user with email'"
          :icon="['fas', 'plus']"
          buttonType="primaryButton"
          text="User"
          @execute-method="createUserProfileConfiguration"
        />
      </div>
    </div>
    <loader
      v-if="isLoading"
      inner-ring-thickness="border-t-8"
      outer-ring-thickness="border-8"
    />
    <div v-else class="space-y-4">
      <empty-state
        v-if="
          !isLoading && !profileConfigurations?.length && !authResources?.length
        "
        page-name="profile configurations"
      >
        <the-button
          :icon="['fas', 'plus']"
          buttonType="primaryButton"
          text="Add new profile configuration"
          @execute-method="createUserProfileConfiguration"
        />
      </empty-state>
      <div v-else>
        <custom-table
          v-if="selectedTabId === 'users'"
          :is-data-source-loading="isDataSourceLoading"
          :options="optionsUser"
          :table-data="authResources"
          :table-headers="tableHeadersUsers"
          @selected-rows="getSelectedRows"
          @force-refresh="getAuthResources"
        />
        <custom-table
          v-else
          :is-data-source-loading="isDataSourceLoading"
          :options="options"
          :table-data="profileConfigurations"
          :table-headers="tableHeaders"
          @selected-rows="getSelectedRows"
          @force-refresh="getAllProfileConfigurations"
        />
      </div>
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TheButton from "@/core/Components/TheButton.vue";
import Loader from "@/core/Components/Loader.vue";
import ProfileConfigurationService, {
  Permission,
} from "../Services/ProfileConfigurationService";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import CreateForm from "../Components/CreateForm.vue";
import Modal from "@/core/Components/Modal.vue";
import EditForm from "../Components/EditForm.vue";
import EmptyState from "@/core/Components/EmptyState.vue";
import DeviceService from "@/modules/Devices/Services/DeviceService";
import AccessManagerService from "@/modules/AccessManager/Services/AccessManagerService";
import { directive } from "vue-tippy";
import CreateTicketForm from "@/modules/AccessManager/SubModules/ProfileConfiguration/Components/CreateTicketForm.vue";
import TabsComponent from "@/core/Components/TabsComponent.vue";
import AuthResourceService, {
  AuthResourceDto,
  AuthResourceDto_,
} from "@/modules/Authorization/Services/AuthResourceService";
import EditAuthForm from "@/modules/AccessManager/SubModules/ProfileConfiguration/Components/EditAuthForm.vue";
import AuthPolicyService from "@/modules/Authorization/Services/AuthPolicyService";
import { usePermissionStore } from "@/core/Store/permissionStore";
import ResourceService, {
  Resource,
} from "../../Resources/Services/ResourceService";
import { useToast } from "vue-toastification";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import SpotiumService from "@/core/Services/SpotiumService";
import AssignmentService from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: {
    EditAuthForm,
    TabsComponent,
    CreateTicketForm,
    MainSection,
    CustomTable,
    TheButton,
    Loader,
    DeleteModal,
    ConfirmationModal,
    CreateForm,
    Modal,
    EditForm,
    EmptyState,
  },
  directives: {
    tippy: directive,
  },
  data() {
    return {
      profileConfigurationService: new ProfileConfigurationService(),
      accessManagerService: new AccessManagerService(),
      deviceService: new DeviceService(),
      spotiumService: new SpotiumService(),
      assignmentService: new AssignmentService(),
      authResourceService: new AuthResourceService(),
      permissionStore: usePermissionStore(),
      profileConfigurations: [] as Permission[],
      tdceDevices: [] as DeviceAssignment[],
      selectedRows: [] as Permission[],
      selectedProfileConfiguration: {} as Permission,
      selectedAppUserData: {} as AuthResourceDto_,
      authPolicyService: new AuthPolicyService(),
      resourceService: new ResourceService(),
      authResources: [] as AuthResourceDto_[],
      //authResources: [] as AuthResourceDto[],
      toast: useToast(),
      changeCompanyStore: useChangeCompanyStore(),
      resources: [] as Resource[],
      isLoading: false,
      isEditModalOpened: false,
      isEditAuthModalOpened: false,
      isDetailsModalOpened: false,
      isDeleting: false,
      isAuthDeleting: false,
      isDeleteAuthModalOpened: false,
      isDataSourceLoading: false,
      isConfirmationModalOpened: false,
      isAuthConfirmationModalOpened: false,
      isCreateUserModalOpened: false,
      isCreateTicketModalOpened: false,
      selectedTabId: "",
      confirmationModalText: "",
      authConfirmationModalText: "",
      tableHeaders: {
        name: { header: "Name", visible: true },
        identifiers: { header: "Identifiers", visible: true },
        accesses: { header: "Accesses", visible: true },
      },
      tableHeadersUsers: {
        email: { header: "Email", visible: true },
        authResources: { header: "Auth resources", visible: true },
      },
      options: {
        edit: {
          header: "Edit",
          icon: ["fas", "pen"],
          action: this.toggleEditModal,
        },
        delete: {
          header: "Delete",
          icon: ["fas", "trash"],
          action: this.toggleDeleteModal,
        },
      },
      optionsUser: {
        edit: {
          header: "Edit",
          icon: ["fas", "pen"],
          action: this.toggleEditAuthModal,
        },
        delete: {
          header: "Delete",
          icon: ["fas", "trash"],
          action: this.toggleDeleteAuthModal,
        },
      },
      tabs: [
        { id: "users", label: "App users", default: true },
        { id: "tickets", label: "RFID TAG users" },
      ],
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getPermissions();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getPermissions() {
      this.isDataSourceLoading = true;
      try {
        await this.changeTab("users");
        await this.getAllTdceDevices();
        this.resources = this.authResourceService.amrResources;
        //this.resources = await this.resourceService.getAllResources();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async changeTab(tabId: string) {
      this.selectedTabId = tabId;
      switch (tabId) {
        case "users":
          this.isLoading = true;
          try {
            await this.getAuthResources();
            this.isEditAuthModalOpened = false;
            this.isCreateUserModalOpened = false;
          } catch (error) {
          } finally {
            this.isLoading = false;
          }
          break;
        case "tickets":
          this.isLoading = true;
          try {
            await this.getAllProfileConfigurations();
            this.isEditModalOpened = false;
            this.isCreateTicketModalOpened = false;
          } catch (error) {
          } finally {
            this.isLoading = false;
          }
          break;
      }
    },
    async getAuthResources() {
      this.isDataSourceLoading = true;
      try {
        this.authResources = await this.authResourceService.getAuthResources(
          1,
          10000
        );
        this.isEditModalOpened = false;
        this.isCreateUserModalOpened = false;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async getAllProfileConfigurations() {
      this.isDataSourceLoading = true;
      try {
        this.profileConfigurations =
          await this.profileConfigurationService.getAllPermissions(1, 10000);

        this.isEditModalOpened = false;
        this.isCreateTicketModalOpened = false;
        this.isCreateUserModalOpened = false;
        this.isEditAuthModalOpened = false;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async deleteProfileConfiguration() {
      const selectedTdceDevice = this.tdceDevices.find(
        (tdceDevice: DeviceAssignment) =>
          tdceDevice.assignmentId ===
          this.selectedProfileConfiguration?.parentAssignmentId
      );

      this.isDataSourceLoading = true;

      try {
        if (selectedTdceDevice === undefined) {
          this.toast.error("Device not found");
          this.toggleDeleteModal();
          this.isConfirmationModalOpened = false;
          return;
        }

        if (this.selectedProfileConfiguration.assignmentId) {
          await this.assignmentService.deleteDeviceAssignment_({
            id: this.selectedProfileConfiguration.assignmentId,
          });

          try {
            if (!selectedTdceDevice.device) {
              this.toast.error("Profile configuration not found!");
              return;
            }
            if (!selectedTdceDevice.assignment) {
              this.toast.error("Profile configuration assignment not found!");
              return;
            }
            await this.profileConfigurationService.addPermissionToStorageForSync(
              selectedTdceDevice.device.deviceUuid,
              selectedTdceDevice.assignment.dataUuid,
              this.generatePermissionObject()
            );
            this.toast.success(this.$t("General.DeletedSuccessfully"));
            this.isConfirmationModalOpened = false;
            this.toggleDeleteModal();
          } catch (error) {
            this.toast.error(ErrorMessageFormatter(error));
          }
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        await this.getAllProfileConfigurations();
        this.isDataSourceLoading = false;
      }
    },
    //App users - delete
    async deleteAuthResourceAndPolicy() {
      this.isDataSourceLoading = true;
      try {
        if (this.selectedAppUserData.authResources.length) {
          let toUserId = this.selectedAppUserData.id;

          await Promise.all(
            this.selectedAppUserData.authResources.map(
              async (authResource: any) => {
                if (!authResource.id) {
                  return;
                }

                if (!toUserId) {
                  return;
                }

                await this.authResourceService.deleteAuthResource(
                  authResource.id
                );
                if (authResource.policyId) {
                  await this.authPolicyService.deletePolicy(
                    authResource.policyId
                  );
                }
              }
            )
          );
        }

        this.toast.success(this.$t("General.DeletedSuccessfully"));

        this.isAuthDeleting = false;
        this.isAuthConfirmationModalOpened = false;
        this.isDeleteAuthModalOpened = false;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        await this.changeTab("users");
        this.isDataSourceLoading = false;
      }
    },
    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteProfileConfiguration();
          break;
      }
    },
    async authConfirmationModalAction() {
      switch (this.authConfirmationModalText) {
        case "delete":
          await this.deleteAuthResourceAndPolicy();
          break;
      }
    },
    async getAllTdceDevices() {
      try {
        this.tdceDevices = await this.accessManagerService.getAllTdceDevices();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    generatePermissionObject() {
      const deletedIdentifier = this.selectedProfileConfiguration.identifiers;
      deletedIdentifier[0].action = Number(process.env.VUE_APP_DELETE_ACTION);
      const deletedAccessess = this.selectedProfileConfiguration.accesses;
      deletedAccessess.forEach(
        (access) => (access.action = Number(process.env.VUE_APP_DELETE_ACTION))
      );

      const d: Permission = {
        id: this.selectedProfileConfiguration.id,
        assignmentId: this.selectedProfileConfiguration.assignmentId,
        uuid: this.selectedProfileConfiguration.uuid,
        parentAssignmentId:
          this.selectedProfileConfiguration.parentAssignmentId,
        name: this.selectedProfileConfiguration.name,
        accesses: deletedAccessess,
        identifiers: deletedIdentifier,
        actions: [Number(process.env.VUE_APP_DELETE_ACTION)],
      };

      return d;
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },
    openAuthConfirmationModal(key: string) {
      this.isAuthConfirmationModalOpened = true;
      this.authConfirmationModalText = key;
    },
    createUserProfileConfiguration() {
      this.toggleCreateUserModal();
    },
    createTicketProfileConfiguration() {
      this.toggleCreateTicketModal();
    },
    getSelectedRows(values: any) {
      this.selectedRows = values;
      if (values.length) {
        if (this.selectedTabId === "users") {
          this.selectedAppUserData = values[0];
        } else {
          this.selectedProfileConfiguration = values[0];
        }
      }
    },
    //General - toggle modals
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    toggleEditAuthModal() {
      this.isEditAuthModalOpened = !this.isEditAuthModalOpened;
    },
    toggleDetailsModal() {
      this.isDetailsModalOpened = !this.isDetailsModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleting = !this.isDeleting;
    },
    toggleDeleteAuthModal() {
      this.isDeleteAuthModalOpened = !this.isDeleteAuthModalOpened;
    },
    toggleConfirmationModal() {
      this.isConfirmationModalOpened = !this.isConfirmationModalOpened;
    },
    toggleAuthConfirmationModal() {
      this.isAuthConfirmationModalOpened = !this.isAuthConfirmationModalOpened;
    },
    toggleCreateUserModal() {
      this.isCreateUserModalOpened = !this.isCreateUserModalOpened;
    },
    toggleCreateTicketModal() {
      this.isCreateTicketModalOpened = !this.isCreateTicketModalOpened;
    },
  },
});
</script>
<style scoped></style>
