<template>
  <modal
    :is-displayed="isEditModalOpened"
    :is-select-modal="true"
    :no-padding="true"
    :title="$t('Organizations.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="formData.name"
            :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
            :isError="v$?.formData['name']?.$error"
            :label="$t('Organizations.Name')"
            :required="true"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal>
</template>
<script lang="ts">
import { useUserStore } from "@/core/Store/userStore";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { defineComponent } from "vue";
import CompanyService, {
  Company,
  CompanyObject,
} from "../Services/CompanyService";
import { useSidebarStore } from "@/core/Store";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import { useToast } from "vue-toastification";
import Modal from "@/core/Components/Modal.vue";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    FormWrapper,
    FormDivider,
    ButtonWrapper,
    InputField,
    Modal,
  },
  props: {
    isEditModalOpened: Boolean,
    objectId: {
      type: Number,
      required: true,
    },
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      userStore: useUserStore(),
      toast: useToast(),
      v$: useValidate() as any,
      validationErrors: [] as any,
      companiesPagination: {} as CompanyObject,
      company: {} as Company,
      companyService: new CompanyService(),
      showToast: false as Boolean,
      index: 1 as Number,
      isLoading: false as boolean,
      isSubmitLoading: false as boolean,
      isCreateModalOpened: false as boolean,
      sidebarStore: useSidebarStore(),
      currentCompanyID: 0,
      formData: {
        id: 0,
        name: "",
      },
    };
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
      },
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getCurrentCompanyInformation();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          this.formData.id = this.objectId;
          await this.companyService.editCompany(this.formData);
          location.reload();
          this.toast.success(this.$t("General.EditedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },

    async getCurrentCompanyInformation() {
      this.isLoading = true;
      try {
        this.company = await this.companyService.getCompanyWithIdentifier(
          this.objectId
        );
        this.formData.name = this.company.name;
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
<style scoped></style>
