import { RouteRecordRaw } from "vue-router";
import Index from "@/modules/Companies/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createCompanyIndexRoute(): RouteRecordRaw {
  return {
    name: "companies",
    path: "/organizations",
    component: Index,
    meta: {
      showInNavigation: true,
      id: "companyIndex",
      label: "Sidebar.Organization",
      icon: ["fas", "building"],
      group: "main",
      authorize: [Permissions.ViewCompany],
    },
  };
}
