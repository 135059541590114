<template>
  <form @submit.prevent="handleSubmit">
    <form-divider>
      <input-field
        v-model="formData.name"
        :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
        :isError="v$?.formData['name']?.$error"
        :required="true"
        label="Name"
      />
      <div class="relative items-center py-3">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >Connection type</label
        >
        <v-select
          v-model="formData.config.connectionType"
          :options="connectionTypeOptions"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <div class="relative items-center py-3">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >MQTT protocol version</label
        >
        <v-select
          v-model="formData.config.mqttProtocolVersion"
          :options="mqttProtocolVersionOptions"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <div
        :class="`grid ${
          formData.config.connectionType === 'TCP'
            ? 'grid-cols-2 space-x-4'
            : 'grid-cols-1'
        } `"
      >
        <input-field
          v-model="formData.config.endpoint"
          :errorMessage="v$?.formData?.config['endpoint']?.$errors[0]?.$message"
          :isError="v$?.formData?.config['endpoint']?.$error"
          :required="true"
          label="Endpoint"
        />
        <input-field
          v-if="formData.config.connectionType === 'TCP'"
          v-model="formData.config.port"
          :errorMessage="v$?.formData?.config['port']?.$errors[0]?.$message"
          :isError="v$?.formData?.config['port']?.$error"
          :required="true"
          label="Port"
          type="number"
        />
      </div>
      <checkbox-input
        v-model="formData.config.hasCredentials"
        :is-checked="formData.config.hasCredentials"
        label="Has credentials"
      />
      <div v-if="formData.config.hasCredentials">
        <input-field
          v-model="formData.config.credentials.username"
          :errorMessage="
            v$?.formData?.config?.credentials['username']?.$errors[0]?.$message
          "
          :isError="v$?.formData?.config['port']?.$error"
          :required="true"
          label="Username"
        />
        <input-field
          v-model="formData.config.credentials.password"
          :errorMessage="
            v$?.formData?.config?.credentials['password']?.$errors[0]?.$message
          "
          :isError="v$?.formData?.config['port']?.$error"
          :required="true"
          label="Password"
          type="password"
        />
      </div>
      <checkbox-input
        v-model="formData.config.isWithCleanSession"
        :is-checked="formData.config.isWithCleanSession"
        label="Clean session"
      />
      <input-field
        v-model="formData.config.topic"
        :errorMessage="v$?.formData?.config['topic']?.$errors[0]?.$message"
        :isError="v$?.formData?.config['topic']?.$error"
        :required="true"
        label="Topic"
        @keyup="trimTopic"
      />
      <div class="relative items-center py-3">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >QOS</label
        >
        <v-select
          v-model="formData.config.qos"
          :options="qosOptions"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <checkbox-input
        v-model="formData.config.isWithRetainFlag"
        :is-checked="formData.config.isWithRetainFlag"
        label="Retain flag"
      />
      <multi-json-input
        @input="handleTopicChange"
        :topics="formData.config.topicToChannel"
      />
      <tags-input
        :label="$t('Integrations.Channels')"
        :tags="formData.config.channels"
        @on-tag-mutate="(tags: any) => (formData.config.channels = tags)"
      />
      <input-field
        v-model="formData.executionOrder"
        :label="$t('Integrations.ExecutionOrder')"
        type="number"
        :errorMessage="v$?.formData['executionOrder']?.$errors[0]?.$message"
        :isError="v$?.formData['executionOrder']?.$error"
      />
      <checkbox-input
        v-model="formData.isActive"
        :is-checked="formData.isActive"
        label="Active"
      />
    </form-divider>
    <button-wrapper :is-submit-loading="isSubmitLoading" />
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import VSelect from "vue-select";
import InputField from "@/core/Components/FormElements/InputField.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import TheButton from "@/core/Components/TheButton.vue";
import IntegrationService from "../Services/IntegrationService";
import checkIfObjectEmpty, {
  CheckObjectsForPatch,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import {
  maxLength,
  required,
  requiredIf,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";
import MultiJsonInput from "@/core/Components/FormElements/MultiJsonInput.vue";
import TagsInput from "@/core/Components/FormElements/TagsInput.vue";

export default defineComponent({
  components: {
    VSelect,
    InputField,
    CheckboxInput,
    TheButton,
    FormDivider,
    ButtonWrapper,
    MultiJsonInput,
    TagsInput,
  },
  props: ["integrationTypeId", "applicationId", "data", "refreshData"],
  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      integrationService: new IntegrationService(),
      isSubmitLoading: false as boolean,
      toast: useToast(),
      integrationFromAPI: {},
      connectionTypeOptions: [
        { id: "TCP", name: "TCP" },
        { id: "WebSocket", name: "WebSocket" },
      ],
      mqttProtocolVersionOptions: [
        { id: "V500", name: "V500" },
        { id: "V311", name: "V311" },
        { id: "V300", name: "V300" },
      ],
      qosOptions: [
        { id: "AtMostOnce", name: "At most once" },
        { id: "AtLeastOnce", name: "At least once" },
        { id: "ExactlyOnce", name: "Exactly once" },
      ],
      formData: {
        id: this.data.id,
        name: this.data.name,
        integrationTypeId: this.integrationTypeId,
        applicationId: this.applicationId,
        isActive: this.data.isActive,
        executionOrder: this.data.executionOrder,
        config: {
          connectionType: this.data?.config?.connectionType,
          topicToChannel: this.data?.config?.topicToChannel,
          channels: this.data?.config?.channels,
          mqttProtocolVersion: this.data?.config?.mqttProtocolVersion,
          endpoint: this.data?.config?.endpoint,
          port: this.data?.config?.port,
          hasCredentials: this.data?.config?.hasCredentials,
          credentials: {
            username: this.data?.config?.credentials?.username || "",
            password: this.data?.config?.credentials?.password || "",
          } as {} | null,
          isWithCleanSession: this.data?.config?.isWithCleanSession,
          topic: this.data?.config.topic,
          qos: this.data?.config.qos,
          isWithRetainFlag: this.data?.config?.isWithRetainFlag,
        },
      },
    };
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        executionOrder: {
          minValue: minValue(1),
        },
        config: {
          endpoint: {
            required,
          },
          port: {
            required: requiredIf(
              () => this.formData.config.connectionType === "TCP"
            ),
            minValue: minValue(0),
            maxValue: maxValue(65535),
          },
          credentials: {
            username: {
              required: requiredIf(() => this.formData.config.hasCredentials),
            },
            password: {
              required: requiredIf(() => this.formData.config.hasCredentials),
            },
          },
          topic: {
            required,
          },
        },
      },
    };
  },

  created() {
    this.integrationFromAPI = Object.assign({}, this.formData);
  },

  watch: {
    applicationId(value) {
      this.formData.applicationId = value;
    },
  },

  methods: {
    trimTopic() {
      this.formData.config.topic = this.formData.config.topic.replace(
        /\s/g,
        ""
      );
    },
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          if (this.formData.config.channels) {
            this.formData.config.channels = this.formData.config.channels.map(
              (channel: string) => {
                if (typeof channel === "string") {
                  return Number(channel.trim());
                }

                return channel;
              }
            );
          }

          if (
            this.formData.config.channels &&
            this.formData.config.channels.length === 0
          ) {
            this.formData.config.channels = null;
          }

          if (checkIfObjectEmpty(this.formData.config.topicToChannel)) {
            this.formData.config.topicToChannel = null;
          }

          if (!this.formData.config.hasCredentials) {
            this.formData.config.credentials = null;
          }

          const integrationPatch = await CheckObjectsForPatch({
            initialObject: this.integrationFromAPI,
            editedObject: this.formData,
          });

          await this.integrationService.editIntegration({
            integrationPatch,
            id: this.data.id,
          });
          this.toast.success(this.$t("General.EditedSuccessfully"));
          await this.refreshData();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    handleTopicChange(value: Record<string, number>) {
      this.formData.config.topicToChannel = value;
    },
  },
});
</script>
<style scoped></style>
