import ApiService from "@/core/Axios/ApiService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import { DeviceAction } from "@/modules/DeviceActions/Services/DeviceActionService";
import { DeviceType } from "@/modules/DeviceTypes/Services/DeviceTypeService";
import { Company } from "@/modules/Companies/Services/CompanyService";
import { Assignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import { DeviceMetadata } from "@/modules/Metadata/Services/Device/DeviceMetadataService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type DeviceProps = {
  pageNumber?: number;
  pageSize?: number;
  deviceTypeIds?: number | number[];
  isActive?: boolean;
  filters?: Record<string, string>;
  name?: string;
  mac?: string;
  imei?: string;
  imsi?: string;
  externalId?: string;
  serialNumber?: string;
  deviceTypeName?: string;
  [key: string]:
    | number
    | number[]
    | boolean
    | Record<string, string>
    | string
    | undefined;
};

export type Device = {
  id: number;
  uuid: string;
  name: string;
  imei: string;
  mac: string;
  imsi: string | null;
  externalId: string | null;
  serialNumber: string;
  isActive: boolean;
  actions: DeviceAction;
  deviceTypeId: number | null;
  deviceType: DeviceType;
  companyId: number;
  company: Company;
  parentDeviceId: number | null;
  assignmentId: number;
  assignment: Assignment;
  data: any | null;
  metadata: DeviceMetadata[];
};

export type CreateDevice = {
  name: string;
  imei: string | null;
  mac: string | null;
  imsi: string | null;
  externalId?: string | null;
  deviceTypeId: number | null;
  companyId: number | null;
  parentDeviceId?: number | null;
  data?: any;
};

export type EditDevice = CreateDevice & {
  id: number;
  serialNumber?: string | null;
};

export type DeviceObject = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: Device[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
};

export default class DeviceService {
  async getAllDevices({
    pageNumber = 1,
    pageSize = 10,
    deviceTypeIds,
    isActive,
    name,
    uuid,
    mac,
    imei,
    imsi,
    externalId,
    serialNumber,
    deviceTypeName,
  }: DeviceProps): Promise<DeviceObject> {
    const url = GenerateUrl({
      path: "/api/devices",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortOrder: "Ascending",
        SortBy: "Name",
        ...(deviceTypeIds && { DeviceTypeIds: deviceTypeIds }),
        ...(isActive && { IsActive: String(isActive) }),
        ...(name && { Name: String(name) }),
        ...(uuid && { Uuid: String(uuid) }),
        ...(mac && { Mac: String(mac) }),
        ...(imei && { Imei: String(imei) }),
        ...(imsi && { Imsi: String(imsi) }),
        ...(externalId && { ExternalId: String(externalId) }),
        ...(serialNumber && { SerialNumber: String(serialNumber) }),
        ...(deviceTypeName && { DeviceTypeName: String(deviceTypeName) }),

        Include: [
          "Company",
          "DeviceType",
          "Actions",
          "Assignment",
          "AssignmentApplication",
          "AssignmentCompany",
          "AssignmentUser",
          "AssignmentApplicationIntegrations",
          "Metadata",
        ],
      },
    });

    const response = await ApiService.get(url);

    return {
      hasNextPage: response.data.hasNextPage,
      hasPreviousPage: response.data.hasPreviousPage,
      items: this.mapDevices(response.data.items),
      pageIndex: response.data.pageIndex,
      totalPages: response.data.totalPages,
      totalCount: response.data.totalCount,
    };
  }

  async getDeviceWithIdentifier(identifier: number): Promise<Device> {
    const url = GenerateUrl({
      path: `/api/devices/${identifier}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        Include: [
          "Company",
          "DeviceType",
          "Actions",
          "Assignment",
          "AssignmentApplication",
          "AssignmentApplicationIntegrations",
          "Metadata",
        ],
      },
    });

    const response = await ApiService.get(url);
    return this._mapDevice(response.data);
  }

  async getDeviceWithIdentifierType({
    identifier,
    type,
  }: {
    identifier: number | string;
    type: string;
  }): Promise<Device> {
    const url = GenerateUrl({
      path: `/api/devices/identifier/${identifier}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        Type: type,
      },
    });

    const response = await ApiService.get(url);
    return this._mapDevice(response.data);
  }

  async createDevice(device: CreateDevice): Promise<any> {
    return await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/devices`,
      device
    );
  }

  /* async updateDevice(device: EditDevice): Promise<any> {
    await ApiService.patch(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/devices/${device.id}`,
      device
    );
  } */

  //This is a new method which will be used instead of method above "updateDevice" for
  //updating resource in acces manager/resources/Edit form
  async updateDevice({
    device, //[{op: "replace", path: "/data", value : "nova_vrijednsot"}]
    id,
  }: {
    device: PatchObject[];
    id: number;
  }) {
    const url = GenerateUrl({
      path: `/api/device/${id}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, device);
  }

  async editDevice({
    id,
    device,
  }: {
    id: number;
    device: PatchObject[];
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/devices/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, device);
  }

  async deleteDevice(id: number): Promise<any> {
    return await ApiService.delete(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/devices/${id}`
    );
  }

  public mapDevices(response: Device[], isLoading?: boolean): Device[] {
    return response.map((device: Device) => this._mapDevice(device));
  }

  // async downloadReport(queryParameters: any) {
  //   const queryStringEncoded: string = `https://ingress.mobilisis.com/spotium/reporting/v1/getReport?${convertObjectToQueryParameters(
  //     queryParameters
  //   )}`;
  //
  //   const response = await axios.get(queryStringEncoded, {
  //     responseType: "blob",
  //   });
  //   const url = window.URL.createObjectURL(new Blob([response.data]));
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute(
  //     "download",
  //     `${moment().format(
  //       "DD/MM/yyyy HH:mm"
  //     )}.${queryParameters.file.toLowerCase()}`
  //   );
  //   document.body.appendChild(link);
  //   link.click();
  // }

  private _mapDevice(device: Device): Device {
    return {
      id: device.id,
      uuid: device.uuid,
      name: device.name,
      imei: device.imei,
      mac: device.mac,
      imsi: device.imsi,
      externalId: device.externalId,
      serialNumber: device.serialNumber,
      isActive: device.isActive,
      actions: device.actions,
      deviceTypeId: device.deviceTypeId,
      deviceType: device.deviceType,
      companyId: device.companyId,
      company: device.company,
      parentDeviceId: device.parentDeviceId,
      assignmentId: device.assignmentId,
      assignment: device.assignment,
      data: device.data,
      metadata: device.metadata,
    };
  }
}
