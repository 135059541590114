import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type DeviceAssignmentDataProps = {
  dataUuid: string;
  from: string;
  to: string;
  channel: number | number[];
};

export type DeviceDataProps = {
  deviceUuid: string;
  from: string;
  to: string;
  channel: number | number[];
};

export type LastDeviceDataProps = {
  dataUuids: string[];
  channel?: number;
  pageSize?: number;
  pageNumber?: number;
};

export type DeviceLatestDataProps = {
  deviceUuids: string[];
  channel?: number;
  pageSize?: number;
  pageNumber?: number;
};

export type DeviceAssignmentData = {
  dateTime: string;
  channel: number;
  payload: any;
};

export type LastDeviceAssignmentData = {
  dataUuid: string;
  data: DeviceAssignmentData[];
};

export type DeviceLatestData = {
  deviceUuid: string;
  data: DeviceAssignmentData[];
};

export default class DeviceDataService {
  async getDeviceAssignmentData({
    dataUuid,
    from,
    to,
    channel,
  }: DeviceAssignmentDataProps): Promise<DeviceAssignmentData[]> {
    const url = GenerateUrl({
      path: `/api/data/user/${dataUuid}`,
      prefixPath: ConfigurationService.configData.STORAGE_API_PREFIX_PATH,
      config: {
        From: from,
        To: to,
        Channel: channel,
      },
    });

    const response = await ApiService.get(url);

    return this._mapDeviceAssignmentData(response.data);
  }

  async getDeviceData({
    deviceUuid,
    from,
    to,
    channel,
  }: DeviceDataProps): Promise<DeviceAssignmentData[]> {
    const url = GenerateUrl({
      path: `/api/data/${deviceUuid}`,
      prefixPath: ConfigurationService.configData.STORAGE_API_PREFIX_PATH,
      config: {
        From: from,
        To: to,
        Channel: channel,
      },
    });

    const response = await ApiService.get(url);

    return this._mapDeviceAssignmentData(response.data);
  }

  async getDeviceAssignmentLastData({
    dataUuids,
    channel,
    pageNumber = 1,
    pageSize = 1000000,
  }: LastDeviceDataProps): Promise<LastDeviceAssignmentData[]> {
    const url = GenerateUrl({
      path: "/api/data/user/latest",
      prefixPath: ConfigurationService.configData.STORAGE_API_PREFIX_PATH,
    });

    const response = await ApiService.post(url, {
      dataUuids,
      channel,
      pageNumber,
      pageSize,
    });

    return this._mapLastDeviceAssignmentData(response.data);
  }

  async getDevicesLatestData({
    deviceUuids,
    pageNumber = 1,
    pageSize = 1000000,
    channel,
  }: DeviceLatestDataProps): Promise<DeviceLatestData[]> {
    const url = GenerateUrl({
      path: `/api/data/latest`,
      prefixPath: ConfigurationService.configData.STORAGE_API_PREFIX_PATH,
    });

    const response = await ApiService.post(url, {
      deviceUuids,
      channel,
      pageNumber,
      pageSize,
    });

    return this._mapDevicesLatestData(response.data);
  }

  private _mapDeviceAssignmentData(
    response: DeviceAssignmentData[]
  ): DeviceAssignmentData[] {
    return response.map((deviceData) => {
      return {
        dateTime: deviceData.dateTime,
        channel: deviceData.channel,
        payload: deviceData.payload,
      };
    });
  }

  private _mapLastDeviceAssignmentData(
    response: LastDeviceAssignmentData[]
  ): LastDeviceAssignmentData[] {
    return response.map((deviceData) => {
      return {
        dataUuid: deviceData.dataUuid,
        data: deviceData.data,
      };
    });
  }

  private _mapDevicesLatestData(
    response: DeviceLatestData[]
  ): DeviceLatestData[] {
    return response.map((deviceData) => {
      return {
        deviceUuid: deviceData.deviceUuid,
        data: deviceData.data,
      };
    });
  }
}
