<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    :title="$t('Displays.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <map-location-selector
      v-if="isMapLocationModalOpened"
      :coordinates-prop="{ lat: display.latitude, lng: display.longitude }"
      :is-map-location-modal-opened="isMapLocationModalOpened"
      @toggle-modal="toggleMapLocationModal"
      @get-coordinates="getMarkerCoordinates"
    />
    <form @submit.prevent="handleSubmit">
      <form-wrapper :is-form-in-modal="true">
        <form-divider>
          <input-field
            v-model="display.name"
            :error-message="this.v$?.display['name']?.$errors[0]?.$message"
            :is-error="v$?.display['name']?.$error"
            :label="$t('Displays.Name')"
            :required="true"
          />
          <div
            class="grid grid-cols-2 gap-6"
            style="grid-template-columns: auto min-content"
          >
            <div>
              <input-field
                v-model="display.latitude"
                :error-message="
                  this.v$?.display['latitude']?.$errors[0]?.$message
                "
                :is-error="v$?.display['latitude']?.$error"
                :label="$t('Displays.Latitude')"
                :required="true"
              />
              <input-field
                v-model="display.longitude"
                :error-message="
                  this.v$?.display['longitude']?.$errors[0]?.$message
                "
                :is-error="v$?.display['longitude']?.$error"
                :label="$t('Displays.Longitude')"
                :required="true"
              />
            </div>
            <div class="pb-3 pt-10">
              <the-button
                :icon="['fas', 'earth-europe']"
                button-type="primaryButton"
                type="button"
                @execute-method="toggleMapLocationModal"
              />
            </div>
          </div>
          <input-field
            v-model="display.imei"
            :error-message="this.v$?.display['imei']?.$errors[0]?.$message"
            :is-error="v$?.display['imei']?.$error"
            :label="$t('Displays.IMEI')"
            :required="true"
          />
          <checkbox-input
            v-model="display.active"
            :is-checked="display.active"
            :label="$t('Displays.Active')"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form-wrapper>
    </form>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import { CreateDisplay } from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import MapLocationSelector from "@/core/Components/MapLocationSelector.vue";
import TheButton from "@/core/Components/TheButton.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";
import useValidate from "@vuelidate/core";
import { maxLength, minLength, required } from "@vuelidate/validators";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import VSelect from "vue-select";

export default defineComponent({
  components: {
    VSelect,
    SmallLoader,
    CheckboxInput,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
    ButtonWrapper,
    MapLocationSelector,
    TheButton,
  },
  props: {
    isCreateModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      displayService: new DisplayService(),
      isSubmitLoading: false,
      isMapLocationModalOpened: false,
      toast: useToast(),
      v$: useValidate(),
      display: {
        name: "",
        latitude: 0,
        longitude: 0,
        active: false,
        imei: "",
      } as CreateDisplay,
    };
  },
  validations() {
    return {
      display: {
        name: {
          required,
        },
        latitude: {
          required,
        },
        longitude: {
          required,
        },
        imei: {
          required,
          minLength: minLength(15),
          maxLength: maxLength(15),
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      await this.v$.$validate();
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          await this.displayService.createDisplay(this.display);
          this.toast.success("Display created successfully!");
          this.$emit("toggle-modal");
          await this.refreshData();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    toggleMapLocationModal() {
      this.isMapLocationModalOpened = !this.isMapLocationModalOpened;
    },
    getMarkerCoordinates(coordinates: { lat: number; lng: number }) {
      this.display.latitude = coordinates.lat;
      this.display.longitude = coordinates.lng;
    },
  },
});
</script>

<style scoped></style>
