import { defineStore } from "pinia";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DashboardService from "@/modules/Parkom/Services/DashboardService";
import { useToast } from "vue-toastification";
import { ParkingSpaceTree } from "@/modules/Parkom/Services/DashboardTypes";

export const useParkingSpaceFilterStore = defineStore("parkingSpaceFilter", {
  state: () => {
    return {
      dashboardService: new DashboardService(),
      selectedParkingSpaceIds: [] as number[],
      parkingSpaceTreeList: [] as ParkingSpaceTree[],
      toast: useToast(),
    };
  },

  actions: {
    async getParkingSpaceTreeList() {
      try {
        this.parkingSpaceTreeList =
          await this.dashboardService.getParkingSpaceTreeList();
        this.selectedParkingSpaceIds = this.parkingSpaceTreeList
          .filter((space) => !!space.spaceId)
          .map((parkingSpace) => parkingSpace.spaceId);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
