import { RouteRecordRaw } from "vue-router";
import PageNotFound from "../Components/PageNotFound.vue";

export function createRouteNotFoundRoute(): RouteRecordRaw {
  return {
    name: "notFound",
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    meta: {
      showInNavigation: false,
    },
  };
}
