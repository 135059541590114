import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default class AccessManagerService {
  tdceDevices: DeviceAssignment[];
  assignmentService = new AssignmentService();

  constructor() {
    this.tdceDevices = [];
  }

  async getCompanyResources(): Promise<DeviceAssignment[]> {
    const resources = await this.assignmentService.getAllDeviceAssignments({
      typeOfAssignment: "Company",
      pageNumber: 1,
      pageSize: 100000,
      deviceTypeIds: [50],
    });

    return resources;
  }

  async getSharedResources(): Promise<DeviceAssignment[]> {
    const resources = await this.assignmentService.getAllDeviceAssignments({
      typeOfAssignment: "SharedToMe",
      pageNumber: 1,
      pageSize: 100000,
      deviceTypeIds: [
        Number(process.env.VUE_APP_ACCESS_MANAGER_RESOURCE_TYPE_ID),
      ],
    });

    return resources;
  }

  async getAllTdceDevices(): Promise<DeviceAssignment[]> {
    const resources = await this.assignmentService.getAllDeviceAssignments({
      typeOfAssignment: "Company",
      pageNumber: 1,
      pageSize: 100000,
      deviceTypeIds: [47],
    });

    return resources;
  }
}
