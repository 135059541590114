import { RouteRecordRaw } from "vue-router";
import Index from "../Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createWorkOrderIndexRoute(): RouteRecordRaw {
  return {
    name: "work-orders",
    path: "/work-orders",
    component: Index,
    meta: {
      id: "workOrdersIndex",
      label: "Sidebar.WorkOrders",
      icon: ["fas", "file-circle-plus"],
      showInNavigation: true,
      authorize: [Permissions.ViewWorkOrder],
    },
  };
}
