import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b7677a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex gap-1" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex items-center space-x-1 text-xs text-darkgrey" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageSizes, (pageSize) => {
        return (_openBlock(), _createElementBlock("div", null, [
          _createElementVNode("button", {
            class: _normalizeClass(`flex h-7 w-7 cursor-pointer items-center justify-center rounded-md text-xs text-black hover:bg-primary hover:text-white ${
            pageSize === _ctx.currentPageSize && 'bg-primary text-white'
          }`),
            onClick: ($event: any) => (_ctx.setPageSize(pageSize))
          }, _toDisplayString(pageSize), 11, _hoisted_3)
        ]))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, " Page " + _toDisplayString(_ctx.currentPage) + " of " + _toDisplayString(_ctx.paginationData?.totalPages) + " (" + _toDisplayString(_ctx.paginationData?.totalCount) + " items) ", 1),
      _createElementVNode("button", {
        class: "flex h-7 w-7 cursor-pointer items-center justify-center rounded-md hover:bg-lightgray",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPage(1))),
        disabled: !_ctx.paginationData?.hasPreviousPage
      }, [
        _createVNode(_component_font_awesome_icon, { icon: _ctx.firstPage }, null, 8, ["icon"])
      ], 8, _hoisted_5),
      _createElementVNode("button", {
        class: "flex h-7 w-7 cursor-pointer items-center justify-center rounded-md hover:bg-lightgray",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPage(_ctx.currentPage - 1))),
        disabled: !_ctx.paginationData?.hasPreviousPage
      }, [
        _createVNode(_component_font_awesome_icon, { icon: _ctx.previousPage }, null, 8, ["icon"])
      ], 8, _hoisted_6),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(`flex h-7 w-7 cursor-pointer items-center justify-center rounded-md text-black hover:bg-primary hover:text-white ${
          page === _ctx.currentPage && 'bg-primary text-white'
        }`),
          onClick: ($event: any) => (_ctx.setPage(page))
        }, [
          _createElementVNode("div", null, _toDisplayString(page), 1)
        ], 10, _hoisted_7))
      }), 256)),
      _createElementVNode("button", {
        class: "flex h-9 w-9 cursor-pointer items-center justify-center rounded-md hover:bg-lightgray",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPage(_ctx.currentPage + 1))),
        disabled: !_ctx.paginationData?.hasNextPage
      }, [
        _createVNode(_component_font_awesome_icon, { icon: _ctx.nextPage }, null, 8, ["icon"])
      ], 8, _hoisted_8),
      _createElementVNode("button", {
        class: "flex h-9 w-9 cursor-pointer items-center justify-center rounded-md hover:bg-lightgray",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setPage(_ctx.paginationData?.totalPages))),
        disabled: !_ctx.paginationData?.hasNextPage
      }, [
        _createVNode(_component_font_awesome_icon, { icon: _ctx.lastPage }, null, 8, ["icon"])
      ], 8, _hoisted_9)
    ])
  ]))
}