<template>
  <modal-component
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    :title="$t('WorkOrders.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("WorkOrders.User") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isUserListLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="workOrder.appliedToUserId"
                :class="`mt-1 w-full`"
                :options="users"
                :reduce="(user : any) => user.id"
                label="email"
              ></v-select>
            </div>
          </div>
          <input-field
            v-model="workOrder.pieces"
            :label="$t('WorkOrders.Pieces')"
          />
          <input-field
            v-model="workOrder.comment"
            :label="$t('WorkOrders.Comment')"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import { WorkOrder } from "@/modules/WorkOrders/Services/WorkOrderTypes";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import {
  CheckObjectsForPatch,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import UserService, { User } from "@/modules/Users/Services/UserService";
import WorkOrderService from "@/modules/WorkOrders/Services/WorkOrderService";

export default defineComponent({
  components: {
    InputField,
    SmallLoader,
    VSelect,
    ButtonWrapper,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isEditModalOpened: Boolean,
    workOrderProp: {
      type: Object as PropType<WorkOrder>,
      required: true,
    },
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      userService: new UserService(),
      workOrderService: new WorkOrderService(),
      initialWorkOrder: {} as any,
      users: [] as User[],
      isSubmitLoading: false,
      isCompanyListLoading: false,
      isUserListLoading: false,
      toast: useToast(),
      workOrder: {
        id: 0,
        isDelivered: false,
        pieces: 0,
        comment: "",
        companyId: 0,
        appliedToUserId: 0,
      },
    };
  },
  async created() {
    const { id, isDelivered, pieces, comment, companyId, appliedToUserId } =
      this.workOrderProp;
    this.workOrder = {
      id,
      isDelivered,
      pieces,
      comment,
      companyId,
      appliedToUserId,
    };
    this.initialWorkOrder = Object.assign({}, this.workOrder);
    try {
      await this.getUsers();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },
  methods: {
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        await this.workOrderService.updateWorkOrder({
          workOrderEdit: await CheckObjectsForPatch({
            initialObject: this.initialWorkOrder,
            editedObject: this.workOrder,
          }),
          id: this.workOrder.id,
        });
        this.toast.success(this.$t("General.EditedSuccessfully"));
        this.$emit("toggle-modal");
        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getUsers() {
      this.isUserListLoading = true;
      try {
        const response = await this.userService.getAllUsers(1, 100000);
        this.users = response.items;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isUserListLoading = false;
      }
    },
  },
});
</script>

<style scoped></style>
