<template>
  <div class="border-none">
    <div class="border p-2">
      <div class="relative">
        <div
          class="flex cursor-pointer items-center gap-2 whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          @click="showConfig"
        >
          Config schema
          <i
            :class="{
              'fa fa-chevron-up': visibleConfig,
              'fa fa-chevron-down': !visibleConfig,
            }"
            style="color: #929bad"
          ></i>
        </div>
        <div
          v-if="visibleConfig"
          class="absolute mt-2 w-full overflow-x-scroll bg-white shadow-lg"
        >
          <pre class="pt-3 text-sm font-medium not-italic text-gray-800">
                {{ configSchema ? configSchema : "No config schema" }}
            </pre
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["configSchema"],
  data() {
    return {
      visibleConfig: false,
    };
  },
  methods: {
    showConfig() {
      this.visibleConfig = !this.visibleConfig;
    },
  },
});
</script>

<style scoped></style>
