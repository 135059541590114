import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-6" }
const _hoisted_2 = { class: "flex w-1/2 gap-8" }
const _hoisted_3 = {
  key: 0,
  class: "flex w-full justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isAddToOrderModalOpened,
    "is-full-screen-modal": true,
    title: _ctx.$t('WorkOrders.AddToWorkOrderTitle'),
    onToggleModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_delete_modal, {
        "is-deleting": _ctx.isDeleteModalOpened,
        onCancelDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
        onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
        onConfirmDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openConfirmationModal('delete')))
      }, null, 8, ["is-deleting"]),
      _createVNode(_component_confirmation_modal, {
        "delete-text": _ctx.confirmationModalText,
        "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
        onConfirmAction: _ctx.confirmationModalAction,
        onCloseConfirmationModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
        onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
      }, null, 8, ["delete-text", "is-confirmation-modal-opened", "onConfirmAction"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_input_field, {
            modelValue: _ctx.mac,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.mac) = $event)),
            label: "MAC"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_input_field, {
            modelValue: _ctx.imei,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.imei) = $event)),
            label: "IMEI"
          }, null, 8, ["modelValue"])
        ]),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_small_loader)
            ]))
          : (_openBlock(), _createBlock(_component_custom_table, {
              key: 1,
              "delete-object-modal": _ctx.toggleDeleteModal,
              "force-refresh": true,
              "is-data-source-loading": _ctx.isDataSourceLoading,
              options: _ctx.options,
              "table-data": _ctx.workOrderDevices,
              "table-headers": _ctx.tableHeaders,
              onSelectedRows: _ctx.getSelectedRows
            }, null, 8, ["delete-object-modal", "is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows"]))
      ])
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}