<template>
  <modal-component
    :is-displayed="isUsersDevicesSharedToModalDisplayed"
    custom-modal-size="w-6/12"
    title="Users device is shared to"
    @toggle-modal="$emit('toggle-modal')"
  >
    <confirmation-modal
      :is-confirmation-modal-opened="isConfirmationModalOpened"
      :object-name="userEmail"
      delete-text="revoke"
      @confirm-action="revokeShare"
      @close-confirmation-modal="isConfirmationModalOpened = false"
      @toggle-modal="isConfirmationModalOpened = false"
    />
    <div class="px-6">
      <div v-if="isLoading" class="flex w-full items-center justify-center">
        <small-loader />
      </div>
      <custom-table
        v-else
        :is-action-list-not-displayed="true"
        :is-data-source-loading="isDataSourceLoading"
        :is-revoke-action-displayed="true"
        :no-selection="true"
        :table-data="users"
        :table-headers="tableHeaders"
        @revoke-action="toggleConfirmationModal"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import UserService, {
  UserShareAssignment,
} from "@/modules/Users/Services/UserService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import CustomTable from "@/core/Components/CustomTable.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import SpotiumService from "@/core/Services/SpotiumService";

export default defineComponent({
  components: { ConfirmationModal, SmallLoader, CustomTable, ModalComponent },
  props: {
    isUsersDevicesSharedToModalDisplayed: Boolean,
    assignmentId: {
      type: Number,
      required: true,
    },
  },
  emits: ["toggle-modal", "refresh-data"],
  async created() {
    this.isLoading = true;
    try {
      await this.getUsers();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  data() {
    return {
      userService: new UserService(),
      spotiumService: new SpotiumService(),
      users: [] as UserShareAssignment[],
      toast: useToast(),
      isLoading: false,
      isConfirmationModalOpened: false,
      isSubmitLoading: false,
      isDataSourceLoading: false,
      userEmail: "",
      tableHeaders: {
        email: { header: "Email", visible: true },
        "activeCompany.name": { header: "Company", visible: true },
      },
    };
  },
  methods: {
    async getUsers() {
      this.isDataSourceLoading = true;
      try {
        this.users = await this.userService.getAllUsersThatDeviceIsSharedTo({
          assignmentId: this.assignmentId,
          pageSize: 100000,
          pageNumber: 1,
          typeOfShare: "Shared",
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async revokeShare() {
      this.isSubmitLoading = true;
      try {
        if (!this.userEmail) {
          throw new Error("User email is not provided!");
        }
        if (!this.assignmentId) {
          throw new Error("Device asset is not provided!");
        }
        await this.spotiumService.revokeShareFromUser({
          userEmail: this.userEmail,
          assignmentId: this.assignmentId,
        });
        this.toast.success("Share has been revoked!");
        this.isConfirmationModalOpened = false;
        await this.getUsers();
        if (!this.users.length) {
          this.$emit("toggle-modal");
        }

        this.$emit("refresh-data");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    toggleConfirmationModal(userEmail: string) {
      this.userEmail = userEmail;
      this.isConfirmationModalOpened = !this.isConfirmationModalOpened;
    },
  },
});
</script>

<style scoped></style>
