import L, { PointTuple } from "leaflet";
import {
  GroupType,
  MarkerIcon,
  MarkerProps,
  MarkersProps,
} from "@/modules/MapView/Services/Types/MarkerTypes";
import { toRaw } from "vue";

export default class MarkerService {
  map = {} as L.Map;
  groups = {
    SPOTIUM: new L.FeatureGroup(),
    ZONES: new L.FeatureGroup(),
    FLOORPLANS: new L.FeatureGroup(),
    PARKOM: new L.FeatureGroup(),
    SINGLE_MARKER: new L.FeatureGroup(),
    ACTIVE_DEVICES: new L.FeatureGroup(),
  } as Record<GroupType, L.FeatureGroup>;
  markers: L.Marker[] = [];

  constructor(map: L.Map) {
    this.map = map;
  }

  addMarker({
    lat,
    lng,
    groupKey,
    icon,
    markerOptions,
    eventHandlers,
    bindPopup,
    returnMarker,
    popupOffset,
  }: MarkerProps): void | L.Marker {
    //First add the group to the map, leaflet won't create a same group twice.
    //If the group is already added to the map it will use the one already created.
    if (!this.map.hasLayer(this.groups[groupKey])) {
      this.groups[groupKey].addTo(this.map);
    }

    const marker = new L.Marker(L.latLng(lat, lng), {
      //@ts-ignore
      className: "my-marker",
      ...(icon && {
        icon: this.createIcon(icon.iconUrl, icon.iconSize, icon.iconAnchor),
      }),
      ...markerOptions,
    });

    if (bindPopup) {
      marker.bindPopup(
        "",
        popupOffset && {
          offset: L.point(popupOffset),
        }
      );
    }

    //Add event listeners to the marker if they are provided
    //click, dragend, drag, etc.
    if (eventHandlers) {
      for (const event in eventHandlers) {
        marker.on(event, eventHandlers[event]);
      }
    }

    if (this.map.hasLayer(this.groups[groupKey])) {
      marker.addTo(toRaw(this.groups[groupKey]));
    }

    this.markers.push(marker);

    if (returnMarker) {
      return marker;
    }
  }

  addRobotMarker(
    { lat, lng, groupKey, returnMarker }: MarkerProps,
    customIcon: L.DivIcon
  ): void | L.Marker {
    if (!this.map.hasLayer(this.groups[groupKey])) {
      this.groups[groupKey].addTo(this.map);
    }

    const marker = new L.Marker([lat, lng], {
      icon: customIcon,
    });

    if (this.map.hasLayer(this.groups[groupKey])) {
      marker.addTo(toRaw(this.groups[groupKey]));
    }

    this.markers.push(marker);

    if (returnMarker) {
      return marker;
    }
  }

  fitGroupToMap(groupKey: GroupType, padding?: boolean): void {
    if (padding) {
      if (this.map.hasLayer(this.groups[groupKey])) {
        this.map.fitBounds(this.groups[groupKey].getBounds(), {
          paddingBottomRight: [300, 100],
        });
      }
    } else {
      if (this.map.hasLayer(this.groups[groupKey])) {
        this.map.fitBounds(this.groups[groupKey].getBounds());
      }
    }
  }

  fitMarkerToMap(marker: L.Marker) {
    if (this.map.hasLayer(marker)) {
      this.map.setView(marker.getLatLng(), 20);
    }
  }

  addMarkers({
    latLng,
    groupKey,
    eventHandlers,
    fitBounds,
    icon,
  }: MarkersProps): void {
    //First add the group to the map, leaflet won't create a same group twice.
    //If the group is already added to the map it will use the one already created.
    this.groups[groupKey].addTo(this.map);
    latLng.forEach((latLng) => {
      this.addMarker({
        lat: latLng.lat,
        lng: latLng.lng,
        groupKey: groupKey,
        icon: icon,
        eventHandlers: eventHandlers,
      });
    });

    //If fitBounds is true, the map will zoom to the group
    if (fitBounds) {
      this.map.fitBounds(this.groups[groupKey].getBounds());
    }
  }

  createMarkerWithoutGroup(
    lat: number,
    lng: number,
    icon?: MarkerIcon
  ): L.Marker {
    const latLng = L.latLng(lat, lng);

    return L.marker(latLng, {
      ...(icon && { icon: this.createIcon(icon.iconUrl, icon.iconSize) }),
    });
  }

  removeMarker(marker: L.Marker, groupKey: GroupType): void {
    if (this.groups[groupKey].hasLayer(marker)) {
      this.groups[groupKey].removeLayer(marker);
    }
  }

  removeGroup(groupKey: GroupType): void {
    if (this.map.hasLayer(this.groups[groupKey])) {
      this.groups[groupKey].clearLayers();
      this.groups[groupKey].removeFrom(this.map);
    }
  }

  createIcon(
    iconUrl: string,
    iconSize: PointTuple | undefined,
    iconAnchor?: PointTuple,
    className?: string
  ): L.Icon {
    return L.icon({
      iconUrl: iconUrl,
      iconSize: iconSize,
      ...(iconAnchor && { iconAnchor: iconAnchor }),
      className: className,
    });
  }

  addDragEndEventToMarkers(groupKey: GroupType, callback: Function) {
    this.groups[groupKey].eachLayer((marker) => {
      const castedMarker = marker as L.Marker;
    });
  }
}
