<template>
  <div class="flex items-center justify-center w-full h-[80vh]">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>
<style scoped></style>
