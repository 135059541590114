<template>
  <modal-component
    :is-displayed="isCreateModalOpened"
    :no-padding="true"
    title="Create node"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form @submit.prevent="handleSubmit">
      <form-wrapper :is-form-in-modal="true">
        <form-divider>
          <input-field v-model="node.index" label="Index" />
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Node type</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isNodeTypeListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="node.typeId"
                :class="`mt-1 w-full border ${
                  v$['node']?.typeId?.$error
                    ? 'rounded-md border-error'
                    : 'border-none'
                }`"
                :options="nodeTypes"
                :reduce="(type : any) => type.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <input-field v-model="node.overrideValue" label="Override value" />
          <input-field v-model="node.correction" label="Correction" />
          <input-field
            v-model="node.overrideFreeArrow"
            label="Override free arrow"
          />
          <input-field
            v-model="node.overrideOccupiedArrow"
            label="Override occupied arrow"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form-wrapper>
    </form>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import {
  CreateNode,
  NodeType,
} from "@/modules/Parkom/Submodules/Displays/Services/DisplayTypes";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import MapLocationSelector from "@/core/Components/MapLocationSelector.vue";
import TheButton from "@/core/Components/TheButton.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DisplayService from "@/modules/Parkom/Submodules/Displays/Services/DisplayService";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    SmallLoader,
    VSelect,
    CheckboxInput,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
    ButtonWrapper,
    MapLocationSelector,
    TheButton,
  },
  props: {
    isCreateModalOpened: Boolean,
    displayId: {
      type: Number,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
    lastNodeIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      displayService: new DisplayService(),
      nodeTypes: [] as NodeType[],
      toast: useToast(),
      isSubmitLoading: false,
      isNodeTypeListLoading: false,
      v$: useVuelidate(),

      node: {
        displayId: 0,
        index: 0,
        overrideValue: "",
        correction: 0,
        overrideFreeArrow: "",
        overrideOccupiedArrow: "",
        typeId: null,
      } as CreateNode,
    };
  },

  validations() {
    return {
      node: {
        typeId: {
          required,
        },
      },
    };
  },

  async created() {
    try {
      await this.getNodeTypes();
      console.log(this.lastNodeIndex);
      this.node.index = this.lastNodeIndex + 1;
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },

  methods: {
    async handleSubmit() {
      await this.v$.$validate();
      if (this.v$.$error) {
        return;
      }
      this.isSubmitLoading = true;
      try {
        this.node.displayId = this.displayId;
        this.node.index = Number(this.node.index);
        await this.displayService.createNode(this.node);
        this.toast.success("Display node created successfully!");
        this.$emit("toggle-modal");
        this.resetValues();
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },

    async getNodeTypes() {
      this.isNodeTypeListLoading = true;
      try {
        this.nodeTypes = await this.displayService.getNodeTypes();
        this.node.typeId = this.nodeTypes[0]?.id;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isNodeTypeListLoading = false;
      }
    },

    resetValues() {
      this.node = {
        displayId: 0,
        index: 0,
        overrideValue: "",
        correction: 0,
        overrideFreeArrow: "",
        overrideOccupiedArrow: "",
      } as CreateNode;
    },
  },
});
</script>

<style scoped></style>
