import { defineStore } from "pinia";
import { useUserStore } from "@/core/Store/userStore";
import CompanyService from "@/modules/Companies/Services/CompanyService";

export const usePermissionStore = defineStore("permission", {
  state: () => {
    return {
      currentCompanyPermissions: [] as { id: number; name: string }[],
      userStore: useUserStore(),
      companyService: new CompanyService(),
    };
  },

  actions: {
    async getCurrentCompanyPermissions() {
      try {
        this.currentCompanyPermissions =
          (
            await this.companyService.getCompanyWithIdentifier(
              this.userStore.activeCompanyId
            )
          ).permissions ?? [];
      } catch (error) {
        console.log(error);
      }
    },
    hasPermission(permissionId: number | undefined) {
      if (!permissionId) {
        return true;
      }
      const hasPermission = this.currentCompanyPermissions.find(
        (permission) => permission.id === permissionId
      );
      return !!hasPermission;
    },
    hasPermissionView(permissions?: number[]) {
      if (!permissions) {
        return false;
      }
      for (const companyPermission of this.currentCompanyPermissions) {
        if (permissions.includes(companyPermission.id)) {
          return true;
        }
      }
      return false;
    },
  },
});
