import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full items-center gap-4 px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "have-modal": true,
    "is-displayed": _ctx.isTagCreateModalOpened,
    title: 'Create tag',
    onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createTag && _ctx.createTag(...args)), ["prevent"]))
          }, [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createVNode(_component_input_field, {
                  modelValue: _ctx.tag.id,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tag.id) = $event)),
                  label: 'Id',
                  errorMessage: _ctx.v$?.tag['id']?.$errors[0]?.$message,
                  isError: _ctx.v$?.tag['id']?.$error,
                  required: true
                }, null, 8, ["modelValue", "errorMessage", "isError"]),
                _createVNode(_component_input_field, {
                  modelValue: _ctx.tag.name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tag.name) = $event)),
                  label: 'Name',
                  errorMessage: _ctx.v$?.tag['name']?.$errors[0]?.$message,
                  isError: _ctx.v$?.tag['name']?.$error,
                  required: true
                }, null, 8, ["modelValue", "errorMessage", "isError"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_the_button, {
                text: _ctx.$t('Cancel'),
                size: "full",
                variant: "outline",
                onExecuteMethod: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
              }, null, 8, ["text"]),
              _createVNode(_component_the_button, {
                text: 'Draw tag',
                size: "full"
              })
            ])
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-displayed"]))
}