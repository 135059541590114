<template>
  <div class="items-center py-3 relative">
    <label v-if="labelText" class="text-sm not-italic font-medium text-gray-800 whitespace-nowrap">{{
        labelText
      }}</label>
    <div class="w-full flex justify-center">
      <small-loader v-if="isLoading" height="h-8" width="w-8"/>
      <v-select
          v-else
          :label="label"
          :options="options"
          :reduce="(value : any) => value[reduceKey]"
          :value="modelValue"
          class="w-full"
          @input="$emit('update:modelValue', $event[reduceKey])"
          @option:selecting="$emit('update:modelValue', $event[reduceKey])"
      ></v-select>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";

export default defineComponent({
  components: {
    VSelect,
    SmallLoader
  },
  props: {
    modelValue: {
      type: Number,
      required: true
    },
    isLoading: Boolean,
    options: {
      type: Array as PropType<any[]>,
      required: true
    },
    reduceKey: {
      type: String,
      required: true
    },
    label: {
      type: String,
    },
    labelText: {
      type: String,
    }
  },
  emits: ["update:modelValue"],

});
</script>

<style scoped></style>
