import ApiService from "@/core/Axios/ApiService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class MaintenanceService {
  async getMaintenanceStatus(): Promise<boolean> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.CONFIGURATION_API_PREFIX_PATH,
      path: `/api/configurations/ingress_v2/maintenance_mode`,
    });

    const response = await ApiService.get(url);

    return response.data.value;
  }
}
