<template>
  <modal-component
    :is-displayed="isEditFormModalOpened"
    :no-padding="true"
    :title="$t('ParkingSpots.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <map-location-selector
      v-if="isMapLocationModalOpened"
      :coordinates-prop="{
        lat: parkingSpot.latitude,
        lng: parkingSpot.longitude,
      }"
      :is-map-location-modal-opened="isMapLocationModalOpened"
      @toggle-modal="toggleMapLocationModal"
      @get-coordinates="getMarkerCoordinates"
    />
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="parkingSpot.name"
            :label="$t('ParkingSpots.Name')"
          />
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("ParkingSpots.ParkingSpace") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isParkingSpaceListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="parkingSpot.spaceId"
                :class="`mt-1 w-full`"
                :options="parkingSpaces"
                :reduce="(space : any) => space.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("ParkingSpots.ParkingSpotType") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isParkingSpotTypesListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="parkingSpot.typeId"
                :class="`mt-1 w-full`"
                :options="parkingSpotTypes"
                :reduce="(space : any) => space.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <div
            class="grid grid-cols-2 gap-6"
            style="grid-template-columns: auto min-content"
          >
            <div>
              <input-field
                v-model="parkingSpot.latitude"
                :label="$t('ParkingSpots.Latitude')"
                :required="true"
              />
              <input-field
                v-model="parkingSpot.longitude"
                :label="$t('ParkingSpots.Longitude')"
                :required="true"
              />
            </div>
            <div class="pb-3 pt-10">
              <the-button
                :icon="['fas', 'earth-europe']"
                button-type="primaryButton"
                type="button"
                @execute-method="toggleMapLocationModal"
              />
            </div>
          </div>
          <input-field
            v-model="parkingSpot.index"
            :label="$t('ParkingSpots.Index')"
          />
          <checkbox-input
            v-model="parkingSpot.visible"
            :is-checked="parkingSpot.visible"
            :label="$t('ParkingSpots.Visible')"
          />
          <checkbox-input
            v-model="parkingSpot.public"
            :is-checked="parkingSpot.public"
            :label="$t('ParkingSpots.Public')"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import VSelect from "vue-select";
import MapLocationSelector from "@/core/Components/MapLocationSelector.vue";
import {
  ParkingSpot,
  ParkingSpotType,
  UpdateParkingSpot,
} from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import TheButton from "@/core/Components/TheButton.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import { required } from "@vuelidate/validators";

export default defineComponent({
  components: {
    TheButton,
    MapLocationSelector,
    VSelect,
    SmallLoader,
    ButtonWrapper,
    CheckboxInput,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isEditFormModalOpened: Boolean,
    parkingSpotProp: {
      type: Object as PropType<ParkingSpot>,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      parkingSpotService: new ParkingSpotService(),
      parkingSpaceService: new ParkingSpaceService(),
      parkingSpaces: [] as ParkingSpace[],
      parkingSpotTypes: [] as ParkingSpotType[],
      isSubmitLoading: false,
      isParkingSpaceListLoading: false,
      isMapLocationModalOpened: false,
      isParkingSpotTypesListLoading: false,
      toast: useToast(),
      initialParkingSpot: {} as UpdateParkingSpot,
      parkingSpot: {
        id: 0,
        name: "",
        latitude: null,
        longitude: null,
        index: 0,
        public: false,
        spaceId: null,
        typeId: null,
      } as UpdateParkingSpot,
    };
  },
  validations() {
    return {
      parkingSpot: {
        typeId: {
          required,
        },
      },
    };
  },
  async created() {
    try {
      await Promise.all([this.getParkingSpaces(), this.getParkingSpotTypes()]);
      this.parkingSpot = {
        id: this.parkingSpotProp.id,
        name: this.parkingSpotProp.name,
        latitude: this.parkingSpotProp.latitude,
        longitude: this.parkingSpotProp.longitude,
        index: this.parkingSpotProp.index,
        public: this.parkingSpotProp.public,
        spaceId: this.parkingSpotProp.spaceId,
        typeId: this.parkingSpotProp.typeId,
        visible: this.parkingSpotProp.visible,
      };
      this.initialParkingSpot = Object.assign({}, this.parkingSpot);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    }
  },
  watch: {
    async "parkingSpot.spaceId"(spaceId: number | null) {
      if (!spaceId) {
        this.parkingSpot.index = 0;
        return;
      }

      if (spaceId === this.initialParkingSpot.spaceId) {
        this.parkingSpot.index = this.initialParkingSpot.index;
        return;
      }

      await this.getLastParkingSpotIndex(spaceId);
    },
  },
  methods: {
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        await this.parkingSpotService.updateParkingSpot(this.parkingSpot);
        this.$emit("toggle-modal");
        this.toast.success("Parking spot updated successfully!");
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    toggleMapLocationModal() {
      this.isMapLocationModalOpened = !this.isMapLocationModalOpened;
    },
    async getParkingSpaces() {
      this.isParkingSpaceListLoading = true;
      try {
        this.parkingSpaces = await this.parkingSpaceService.getParkingSpaces({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isParkingSpaceListLoading = false;
      }
    },

    async getParkingSpotTypes() {
      this.isParkingSpotTypesListLoading = true;
      try {
        this.parkingSpotTypes =
          await this.parkingSpotService.getParkingSpotTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isParkingSpotTypesListLoading = false;
      }
    },
    getMarkerCoordinates(coordinates: { lat: number; lng: number }) {
      this.parkingSpot.latitude = coordinates.lat;
      this.parkingSpot.longitude = coordinates.lng;
    },
    async getLastParkingSpotIndex(spaceId: number | null) {
      if (!spaceId) {
        this.parkingSpot.index = 0;
        return;
      }
      try {
        const lastIndex =
          await this.parkingSpaceService.getParkingSpaceLastIndex(spaceId);
        this.parkingSpot.index = lastIndex + 1;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
