import { XYToLatLng } from "@/core/Services/GlobalFunctions";
import L, { Layer, LeafletEvent, Map, Marker } from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "leaflet-toolbar";
import "leaflet-distortableimage";
import "leaflet-distortableimage/dist/leaflet.distortableimage.css";
import "leaflet-toolbar/dist/leaflet.toolbar.css";
import { geojsonToWKT, wktToGeoJSON } from "@terraformer/wkt";

export enum WktTypes {
  Polygon = "Polygon",
  Point = "Point",
  LineString = "LineString",
}

export type CreateTag = {
  rootMarker: L.Marker;
  tipMarker: L.Marker;
  line: L.Polyline;
};

export type TagComponent = {
  id: string;
  name: string;
  rootMarker: Marker;
  tipMarker: Marker;
  line: L.Polyline;
};

export type Tag = {
  geography: string;
  id: string;
  name: string;
  position: { x: number; y: number };
  rotation: number;
  invertY: boolean;
};

export const movingObjectIcon = L.icon({
  iconUrl:
    process.env.VUE_APP_PUBLIC_PATH !== ""
      ? `${process.env.VUE_APP_PUBLIC_PATH}icons/moving-marker.svg`
      : "/icons/moving-marker.svg",
  iconSize: [35, 35],
});

export const movingMarkerIcon = L.icon({
  iconUrl:
    process.env.VUE_APP_PUBLIC_PATH !== ""
      ? `${process.env.VUE_APP_PUBLIC_PATH}icons/marker-tag-moving.svg`
      : "/icons/marker-tag-moving.svg",
  iconSize: [35, 35],
  iconAnchor: [18, 30],
});

export default class LeafletMapService {
  map: L.Map;
  mapContainer: HTMLDivElement;
  mapObjectsTags: TagComponent[];
  currentCreatingTag: CreateTag;

  constructor(map: L.Map, mapContainer: HTMLDivElement) {
    this.map = map;
    this.mapContainer = mapContainer;
    this.mapObjectsTags = [];
    this.currentCreatingTag = {
      rootMarker: {} as Marker,
      tipMarker: {} as Marker,
      line: {} as L.Polyline,
    };
  }

  async displayTagsOnMap(tags: any) {
    const customPointIcon = L.icon({
      iconUrl:
        process.env.VUE_APP_PUBLIC_PATH !== ""
          ? `${process.env.VUE_APP_PUBLIC_PATH}icons/point.svg`
          : "/icons/point.svg",
      iconSize: [60, 60],
    });
    const customTagIcon = L.icon({
      iconUrl:
        process.env.VUE_APP_PUBLIC_PATH !== ""
          ? `${process.env.VUE_APP_PUBLIC_PATH}icons/marker-tag.svg`
          : "/icons/marker-tag.svg",
      iconSize: [35, 35],
      //[x,y]
      iconAnchor: [18, 30],
    });

    tags.forEach((tag: Tag) => {
      const rootMarker = L.marker([tag.position.y, tag.position.x], {
        icon: customTagIcon,
      });
      const geoJsonCoordinates = wktToGeoJSON(tag.geography);

      if (geoJsonCoordinates.type == "LineString") {
        const line = L.polyline(
          [
            [
              geoJsonCoordinates.coordinates[0][1],
              geoJsonCoordinates.coordinates[0][0],
            ],
            [
              geoJsonCoordinates.coordinates[1][1],
              geoJsonCoordinates.coordinates[1][0],
            ],
          ],
          { color: "red" }
        );
        const tipMarker = L.marker(
          [
            geoJsonCoordinates.coordinates[1][1],
            geoJsonCoordinates.coordinates[1][0],
          ],
          {
            icon: customPointIcon,
            draggable: false,
          }
        );

        this.mapObjectsTags.push({
          id: tag.id,
          name: tag.name,
          rootMarker,
          tipMarker,
          line,
        });

        rootMarker.addTo(this.map);
        tipMarker.addTo(this.map);
        line.addTo(this.map);
      }
    });
  }

  async displayNewTag(tag: Tag) {
    const customPointIcon = L.icon({
      iconUrl:
        process.env.VUE_APP_PUBLIC_PATH !== ""
          ? `${process.env.VUE_APP_PUBLIC_PATH}icons/point.svg`
          : "/icons/point.svg",
      iconSize: [60, 60],
    });
    const customTagIcon = L.icon({
      iconUrl:
        process.env.VUE_APP_PUBLIC_PATH !== ""
          ? `${process.env.VUE_APP_PUBLIC_PATH}icons/marker-tag.svg`
          : "/icons/marker-tag.svg",
      iconSize: [35, 35],
      //[x,y]
      iconAnchor: [18, 30],
    });

    const rootMarker = L.marker([tag.position.y, tag.position.x], {
      icon: customTagIcon,
    });
    const geoJsonCoordinates = wktToGeoJSON(tag.geography);

    if (geoJsonCoordinates.type == "LineString") {
      const line = L.polyline(
        [
          [
            geoJsonCoordinates.coordinates[0][1],
            geoJsonCoordinates.coordinates[0][0],
          ],
          [
            geoJsonCoordinates.coordinates[1][1],
            geoJsonCoordinates.coordinates[1][0],
          ],
        ],
        { color: "red" }
      );
      const tipMarker = L.marker(
        [
          geoJsonCoordinates.coordinates[1][1],
          geoJsonCoordinates.coordinates[1][0],
        ],
        {
          icon: customPointIcon,
          draggable: false,
        }
      );

      this.mapObjectsTags.push({
        id: tag.id,
        name: tag.name,
        rootMarker,
        tipMarker,
        line,
      });

      rootMarker.addTo(this.map);
      tipMarker.addTo(this.map);
      line.addTo(this.map);
    }
  }

  addTagToMap() {
    const customPointIcon = L.icon({
      iconUrl:
        process.env.VUE_APP_PUBLIC_PATH !== ""
          ? `${process.env.VUE_APP_PUBLIC_PATH}icons/point.svg`
          : "/icons/point.svg",
      iconSize: [60, 60],
    });
    const mapCenter = this.map.getCenter();
    const tipMarkerCoords = new L.LatLng(
      mapCenter.lat - 0.00005,
      mapCenter.lng - 0.00005
    );

    const rootMarker = L.marker(mapCenter, { draggable: true }).addTo(this.map);
    const tipMarker = L.marker(tipMarkerCoords, {
      icon: customPointIcon,
      draggable: true,
    }).addTo(this.map);

    const line = L.polyline([mapCenter, tipMarkerCoords], {
      color: "red",
    }).addTo(this.map);

    rootMarker.on("drag", (e) => {
      line.setLatLngs([e.target.getLatLng(), tipMarker.getLatLng()]);
    });

    tipMarker.on("drag", (e) => {
      line.setLatLngs([rootMarker.getLatLng(), e.target.getLatLng()]);
    });

    this.currentCreatingTag.rootMarker = rootMarker;
    this.currentCreatingTag.tipMarker = tipMarker;
    this.currentCreatingTag.line = line;
  }

  removeCreatingTag() {
    Object.values(this.currentCreatingTag).forEach((tagComponent: any) => {
      this.map.removeLayer(tagComponent);
    });
  }

  deleteTags(tags: Tag[]) {
    tags.forEach((tag: Tag) => {
      this.deleteTag(tag);
    });
  }

  deleteTag(tagToDelete: Tag) {
    let tagForDeleting = this.mapObjectsTags.filter(
      (tag) => tag.id == tagToDelete.id
    );

    this.map.removeLayer(tagForDeleting[0].rootMarker);
    this.map.removeLayer(tagForDeleting[0].tipMarker);
    this.map.removeLayer(tagForDeleting[0].line);
  }

  convertGeoJsonToWKT(type: WktTypes, coordinates: any) {
    const wktObject: any = {
      type,
      coordinates: [...coordinates],
    };
    return geojsonToWKT(wktObject);
  }

  fitSelectedTagBounds(tagToZoomOn: Tag) {
    let marker = this.mapObjectsTags.filter((tag) => tag.id == tagToZoomOn.id);
    if (marker.length > 0) {
      this.map.fitBounds(L.latLngBounds([marker[0].rootMarker.getLatLng()]));
    } else {
      console.error("Tag not found for zoom it on");
    }
  }
}
