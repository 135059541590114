<template>
  <delete-modal
    :is-deleting="isDeleting"
    :selectedData="selectedApplication"
    delete-text="Are you sure you want to delete group "
    @toggle-modal="toggleDeleteModal"
    @cancel-delete="toggleDeleteModal"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :delete-text="confirmationModalText"
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    :number-of-devices="selectedRows.length"
    @confirm-action="confirmationModalAction"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <modal
    :is-displayed="isIntegrationsModalOpened"
    :is-select-modal="true"
    title="Integrations"
    @toggle-modal="isIntegrationsModalOpened = false"
  >
    <div class="p-6">
      <custom-table
        :is-action-list-not-displayed="true"
        :is-data-source-loading="isDataSourceLoading"
        :table-data="applicationDetails.integrations"
        :table-headers="integrationHeaders"
        @force-refresh="getApplicationDetails"
      />
    </div>
  </modal>

  <edit-form
    v-if="isEditModalOpened"
    :is-edit-modal-opened="isEditModalOpened"
    :object-id="selectedRows[0].id"
    :refresh-data="getAllApplications"
    @toggle-modal="isEditModalOpened = false"
  />

  <create-form
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getAllApplications"
    @toggle-modal="isCreateModalOpened = false"
  />

  <main-section>
    <loader
      v-if="isLoading"
      inner-ring-thickness="border-t-8"
      outer-ring-thickness="border-8"
    />
    <div v-else>
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateGroup"
        :title="$t('Groups.Title')"
        button-text="Create"
        description="Groups are used to group devices together. Each group can have multiple integrations. This allows you to create integrations which will apply to all devices in the group."
        @execute-method="createApplication"
      />
      <empty-state v-if="!isLoading && !applications.length" page-name="groups">
        <the-button
          :icon="['fas', 'plus']"
          buttonType="primaryButton"
          text="Add new group"
          @execute-method="createApplication"
        />
      </empty-state>

      <custom-table
        v-else
        :delete-object-modal="toggleDeleteModal"
        :edit-object-modal="toggleEditModal"
        :integrations-modal="toggleIntegrationsModal"
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="applications"
        :table-headers="tableHeaders"
        @selected-rows="getSelectedRows"
        @force-refresh="getAllApplications"
      />
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "../../../core/Components/MainSection.vue";
import ApplicationService, {
  Application,
  ApplicationObject,
} from "../Services/ApplicationService";
import CustomTable from "../../../core/Components/CustomTable.vue";
import Loader from "@/core/Components/Loader.vue";
import TheButton from "@/core/Components/TheButton.vue";
import {
  CheckIfThereIsError,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import Modal from "@/core/Components/Modal.vue";
import EditForm from "../Components/EditForm.vue";
import CreateForm from "../Components/CreateForm.vue";
import EmptyState from "@/core/Components/EmptyState.vue";
import { useToast } from "vue-toastification";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    TableActionHeader,
    MainSection,
    CustomTable,
    Loader,
    TheButton,
    DeleteModal,
    ConfirmationModal,
    Modal,
    EditForm,
    CreateForm,
    EmptyState,
  },
  data() {
    return {
      applicationService: new ApplicationService(),
      applicationObject: {} as ApplicationObject,
      applications: [] as Application[],
      isDataSourceLoading: false,
      index: 1 as Number,
      isLoading: false,
      isDeleting: false,
      isEditModalOpened: false,
      isCreateModalOpened: false,
      isConfirmationModalOpened: false,
      isIntegrationsModalOpened: false,
      changeCompanyStore: useChangeCompanyStore(),
      confirmationModalText: "",
      toast: useToast(),
      permissions: Permissions,
      routeName: "applicationDetails",
      selectedRows: [] as Application[],
      selectedApplication: {} as Application,
      applicationDetails: {} as Application,
      tableHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "Groups.Name", visible: true },
        "company.name": { header: "Groups.CompanyName", visible: true },
        companyId: { header: "Company ID", visible: false },
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditGroup,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteGroup,
          action: this.toggleDeleteModal,
        },
        integrations: {
          header: "Action.Integrations",
          icon: ["fas", "plug"],
          action: this.toggleIntegrationsModal,
        },
      },
      integrationHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "Integrations.Name", visible: true },
        "integrationType.name": {
          header: "Integrations.IntegrationType",
          visible: true,
        },
        isActive: { header: "Integrations.Active", visible: true },
      },
    };
  },

  watch: {
    async "changeCompanyStore.companyId"(value) {
      await this.getAllApplications();
    },
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getAllApplications();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    checkIfThereIsError: CheckIfThereIsError,

    async getAllApplications() {
      this.isDataSourceLoading = true;
      try {
        this.applicationObject =
          await this.applicationService.getAllApplications(1, 100000);
        this.applications = this.applicationObject.items;
        this.isEditModalOpened = false;
        this.isCreateModalOpened = false;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async deleteApplication() {
      this.isDataSourceLoading = true;
      try {
        if (this.selectedApplication.id !== null) {
          await this.applicationService.deleteApplication(
            this.selectedApplication.id
          );
        }
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.toggleDeleteModal();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        await this.getAllApplications();
        this.isDataSourceLoading = false;
      }
    },

    createApplication() {
      // (this as any).$router.push({ name: "createApplication" });
      this.toggleCreateModal();
    },

    async getApplicationDetails() {
      this.isDataSourceLoading = true;
      try {
        this.applicationDetails =
          await this.applicationService.getApplicationWithIdentifier(
            this.selectedApplication.id
          );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    toggleDeleteModal() {
      this.isDeleting = !this.isDeleting;
    },

    getSelectedRows(values: Application[]) {
      this.selectedRows = values;
      if (values.length) {
        this.selectedApplication = values[0];
      }
    },

    confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          this.deleteApplication();
          break;
      }
      this.isConfirmationModalOpened = false;
    },

    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },

    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },

    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },

    async toggleIntegrationsModal() {
      this.isIntegrationsModalOpened = !this.isIntegrationsModalOpened;

      if (this.isIntegrationsModalOpened) {
        await this.getApplicationDetails();
      }
    },
  },
});
</script>
<style></style>
