import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative items-center py-4" }
const _hoisted_2 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_3 = { class: "flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isCreateRelationModalOpened,
    "no-padding": true,
    title: _ctx.$t('AssignmentMetadata.CreateRelationTitle'),
    onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("AssignmentMetadata.AssignmentMetadata")), 1),
                  _createElementVNode("div", _hoisted_3, [
                    (_ctx.isLoading)
                      ? (_openBlock(), _createBlock(_component_small_loader, {
                          key: 0,
                          height: "h-8",
                          width: "w-8"
                        }))
                      : (_openBlock(), _createBlock(_component_v_select, {
                          key: 1,
                          modelValue: _ctx.assignmentMetadataId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assignmentMetadataId) = $event)),
                          class: _normalizeClass(`mt-1 w-full`),
                          options: _ctx.assignmentMetadata,
                          reduce: (metadata) => metadata.id,
                          label: "name"
                        }, null, 8, ["modelValue", "options", "reduce"]))
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_button_wrapper)
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}