import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2" }
const _hoisted_2 = { class: "mb-0 text-xl font-semibold leading-normal" }
const _hoisted_3 = { class: "flex items-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_the_button = _resolveComponent("the-button")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`flex w-full items-center ${
      _ctx.hasPermission ? 'justify-between' : 'justify-start'
    } mb-4`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.title), 1),
      (_ctx.description)
        ? _withDirectives((_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: ['fas', 'circle-info']
          }, null, 512)), [
            [_directive_tippy, _ctx.description]
          ])
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.hasPermission && _ctx.buttonText)
        ? (_openBlock(), _createBlock(_component_the_button, {
            key: 0,
            icon: _ctx.icon,
            text: _ctx.$t(_ctx.buttonText || ''),
            "button-type": "primaryButton",
            type: "button",
            onExecuteMethod: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('execute-method')))
          }, null, 8, ["icon", "text"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}