import ApiService from "@/core/Axios/ApiService";
import ResourceTypeService from "./ResourceTypeService";
import { useUserStore } from "@/core/Store/userStore";
import AssignmentService, {
  CreateAssignment,
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type ResourceObject = {
  id: number;
  name: string;
  uuid: string;
  dataUuid: string;
  deviceAssignmentId?: number;
  data: Resource;
};

export type Resource = {
  spotiumDeviceId?: number;
  id: number | null;
  uuid?: string;
  name?: string;
  shortName: string;
  configuration: object;
  active: boolean;
  deviceId?: number;
  type: number;
  topic?: string;
  action: number;
  deviceAssignmentId?: number;
  dataUuid?: string;
  resourcePermissions?: {}[];
};

export type ResourceCreate = {
  data: Resource;
};

export type SpotiumResource = {
  id?: number;
  name: string;
  uuid: string;
  deviceTypeId: number;
  applicationId: number;
  parentDeviceId: number;
  data: Resource;
};

export type ResourceAction = {
  deviceUuid: string;
  payload: Payload;
};

export type Payload = {
  deviceUuid: string;
  userId: number;
  username: string;
};

export type AmrResource = {
  toCompanyId: number | null;
  toUserId: number;
  onCompanyId: number;
  resourceTypeId: number;
  roleId: number | null;
  permissionIds: number[];
  policyId: number;
  companyId: number;
  onResourceId: number | null;
};

export type ResourceEvents = {
  dateTime: string;
  user: string;
  userType: string;
};

export default class ResourceService {
  resourceTypeService = new ResourceTypeService();
  userStore = useUserStore();
  deviceAssignmentService = new AssignmentService();

  constructor() {}

  async getAllResources(): Promise<Resource[]> {
    const resources = await Promise.all([
      this.deviceAssignmentService.getAllDeviceAssignments({
        typeOfAssignment: "Company",
        pageNumber: 1,
        pageSize: 10000,
        deviceTypeIds: [50],
      }),
      this.deviceAssignmentService.getAllDeviceAssignments({
        typeOfAssignment: "SharedToMe",
        pageNumber: 1,
        pageSize: 10000,
        deviceTypeIds: [50],
      }),
    ]);

    //resources[0] = company devices
    //resources[1] = devices shared to user
    const mergedDevices = resources[0].concat(resources[1]);

    return this._mapResources(mergedDevices);
  }

  async getResourceWithIdentifier(id: number): Promise<DeviceAssignment> {
    const response =
      await this.deviceAssignmentService.getDeviceAssignmentByIdentifier({
        id,
      });

    return response;
  }

  async createResource(
    resource: CreateAssignment,
    userId: number
  ): Promise<DeviceAssignment> {
    const response = await this.deviceAssignmentService.createDeviceAssignment(
      resource
    );

    return response;
  }

  async createAmrResource(amrResource: AmrResource): Promise<any> {
    return await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/authorization/resources`,
      amrResource
    );
  }

  async updateResource(resource: SpotiumResource): Promise<any> {
    return await ApiService.put(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/device/${resource.id}`,
      resource
    );
  }

  async updateResourceData(resource: any): Promise<any> {
    return await ApiService.put(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/devices/uuid/${resource.uuid}/data`,
      { data: resource.data }
    );
  }

  async deleteResource(id: number): Promise<boolean> {
    try {
      await ApiService.delete(
        `${ConfigurationService.configData.ACCESS_MANAGER_API_PREFIX_PATH}/api/resources/${id}`
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async addResourceToStorageForSync(
    tdceUuid: string,
    tdceDataUuid: string,
    resource: Resource
  ) {
    const storageResourceShape = {
      deviceUuid: tdceUuid,
      dataUuid: tdceDataUuid,
      payload: {
        type: "resource",
        data: resource,
      },
      channel: 1,
    };
    return await ApiService.post(
      `${ConfigurationService.configData.STORAGE_API_PREFIX_PATH}/api/data`,
      storageResourceShape
    );
  }

  async triggerResource(resourceUuid: string, resourceTopic: string) {
    const resourceAction: ResourceAction = {
      deviceUuid: resourceUuid,
      payload: {
        deviceUuid: resourceUuid,
        userId: this.userStore.userId,
        username: this.userStore.userEmail,
      },
    };

    return await ApiService.post(
      `${ConfigurationService.configData.GATEWAY_PREFIX_PATH}/payload`,
      resourceAction
    );
  }

  private _mapResources(response: DeviceAssignment[]): Resource[] {
    return response.map((resource: DeviceAssignment) =>
      this._mapResource(resource)
    );
  }

  private _mapResource(resource: DeviceAssignment): Resource {
    return {
      spotiumDeviceId: resource.device?.id,
      id: resource?.deviceId,
      uuid: resource?.device?.deviceUuid,
      name: resource.assignment?.name,
      shortName: resource?.device?.data?.shortName,
      configuration: resource?.device?.data?.configuration,
      active: resource?.device?.data?.active,
      deviceId: resource?.device?.data?.deviceId,
      type: resource?.device?.data?.type,
      topic: resource?.device?.data?.topic,
      action: resource?.device?.data?.action,
      deviceAssignmentId: resource.assignment?.id,
      dataUuid: resource.assignment?.dataUuid,
      resourcePermissions: resource.assignment?.permissions,
    };
  }
}
