<template>
  <div class="mt-6 flex items-center space-x-4">
    <button
      class="flex h-12 w-12 items-center justify-center border"
      type="button"
      @click="$emit('add-click')"
    >
      <font-awesome-icon :icon="['fas', 'plus']" class="h-5 w-5" />
    </button>
    <button
      :disabled="disableButton()"
      class="delete-button flex h-12 w-12 items-center justify-center border"
      type="button"
      @click="$emit('remove-click')"
    >
      <font-awesome-icon :class="` h-5 w-5`" :icon="['fas', 'trash']" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  components: {},
  props: {
    authResources: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  emits: ["remove-click", "add-click"],
  methods: {
    disableButton() {
      return this.authResources.length === 1;
    },
  },
});
</script>

<style scoped></style>
