<template>
  <div
    :class="`bg-white ${
      !isDetailsViewInModal && 'shadow-md'
    }  overflow-hidden rounded-sm`"
  >
    <div class="flex items-center justify-between px-6 py-2.5">
      <h3 class="text-lg font-medium leading-6 text-darker">
        {{ title }} information
      </h3>
      <div
        v-if="isDevice && !noQrIcon"
        class="cursor-pointer text-dark"
        @click="$emit('toggle-qr-modal')"
      >
        <font-awesome-icon :icon="['fas', 'qrcode']" />
      </div>
    </div>
    <div className="border-t border-gray-200 ">
      <dl className="group divide-y divide-gray-100">
        <div v-for="(key, value) in tableHeaders">
          <div
            className="bg-white px-4 py-2.5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 hover:bg-lightgray"
          >
            <dt
              v-if="tableHeaders"
              className="text-sm font-medium text-gray-500 "
            >
              {{
                tableHeaders[value]["header"]
                  ? tableHeaders[value]["header"]
                  : tableHeaders[value]
              }}
            </dt>
            <dd
              v-if="
                !value.includes('isActive') && generateValue(tableData, value)
              "
              class="mt-1 text-sm text-gray-900"
            >
              {{ generateValue(tableData, value) }}
            </dd>
            <dd
              v-if="!generateValue(tableData, value)"
              className="mt-1 text-sm text-gray-900 sm:mt-0"
            >
              -
            </dd>
            <dd
              v-if="value.includes('isActive')"
              className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
            >
              <div
                :class="`h-4 w-4 rounded-full ${
                  generateValue(tableData, value) ? 'bg-success' : 'bg-error'
                }`"
              ></div>
            </dd>
            <dd v-if="tableHeaders[value]['copy']" class="w-full text-right">
              <font-awesome-icon
                :icon="['fas', 'copy']"
                style="color: #007cc1"
                class="cursor-pointer text-lg"
                @click="copyToClipBoard(generateValue(tableData, value))"
              />
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  ConvertMinutesToHoursAndMinutes,
  FormatDateTime,
} from "../Services/GlobalFunctions";
import MainSection from "./MainSection.vue";
import { directive } from "vue-tippy";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//FIXANO

export default defineComponent({
  components: { FontAwesomeIcon, MainSection },
  props: {
    tableData: Object,
    tableHeaders: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    title: String,
    isDevice: Boolean,
    noQrIcon: Boolean,
    isDetailsViewInModal: Boolean,
  },
  emits: ["toggle-qr-modal", "copy-to-clipboard"],
  directives: {
    tippy: directive,
  },
  data() {
    return {
      days: ["M", "T", "W", "T", "F", "S", "S"],
    };
  },
  methods: {
    convertMinutesToHoursAndMinutes: ConvertMinutesToHoursAndMinutes,
    formatDate: FormatDateTime,

    copyToClipBoard(data: string) {
      this.$emit("copy-to-clipboard", data);
    },

    generateValue(o: any, s: string) {
      s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      s = s.replace(/^\./, ""); // strip a leading dot
      const a = s.split(".");
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];

        if (k in o) {
          if (!o[k]) {
            return;
          }
          o = o[k];
        } else {
          return;
        }
      }
      return o;
    },
  },
});
</script>
<style scoped></style>
