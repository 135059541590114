<template>
  <div>
    <main-section>
      <table-action-header
        title="Commit dynamic asset action"
        description=" "
      />
      <form class="space-y-4" @submit.prevent="handleSubmit">
        <div class="flex items-center justify-end">
          <the-button
            :is-loading="isSubmitLoading"
            button-type="primaryButton"
            text="Commit"
          />
        </div>
        <grid :column-number="2">
          <div class="flex flex-col space-y-4">
            <h2 class="w-full border-b border-b-darkgrey">
              {{ $t("DeviceActions.RequestBody") }}
            </h2>
            <div class="relative items-center">
              <label
                class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
                >{{ $t("DeviceActions.Device") }}</label
              >
              <v-select
                v-model="formData.dataWrapper.deviceUuid"
                :getOptionLabel="(assignment : any) => assignment.assignment.name"
                :options="assignments"
                :reduce="(assignment : any) => assignment.device.deviceUuid"
                class="mt-1"
              ></v-select>
            </div>
            <input-field
              v-model="formData.dataWrapper.clientId"
              :label="$t('DeviceActions.ClientId')"
            />
            <json-editor-vue
              v-model="formData.dataWrapper.data"
              :main-menu-bar="false"
              :status-bar="false"
              mode="text"
            />
            <input-field
              v-model="formData.dataWrapper.requestTimeout"
              :label="$t('DeviceActions.RequestTimeout')"
              :no-padding="true"
            />
            <checkbox-input
              v-model="formData.dataWrapper.storeCommit"
              :label="$t('DeviceActions.StoreCommit')"
            />
          </div>
          <div class="flex flex-col">
            <div
              class="mb-4 flex w-full items-baseline justify-between border-b border-b-darkgrey"
            >
              <h2>
                {{ $t("DeviceActions.ResponseBody") }}
              </h2>
              <the-button
                :icon="['fas', 'clipboard']"
                size="small"
                type="button"
                variant="ghost"
                @execute-method="copyToClipboard"
              />
            </div>
            <div class="relative">
              <div
                v-if="isSubmitLoading"
                class="absolute inset-0 z-[2] flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.5)]"
              >
                <img
                  alt="Loading..."
                  src="../../../assets/loader.min.svg"
                  width="100"
                />
              </div>
              <!--              <json-editor-vue-->
              <!--                v-model="response"-->
              <!--                :main-menu-bar="false"-->
              <!--                :status-bar="false"-->
              <!--                mode="text"-->
              <!--              />-->
              <vue-json-pretty
                :data="response"
                class="scroll max-h-[56vh] overflow-y-auto"
              />
            </div>
          </div>
        </grid>
      </form>
    </main-section>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import Loader from "../../../core/Components/Loader.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import DeviceActionsService, {
  DeviceActionCommit,
  DeviceActionResponse,
} from "../Services/DeviceActionService";
import ToastComponent from "@/core/Components/Notifications/ToastComponent.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
//@ts-ignore
import JsonEditorVue from "json-editor-vue";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Grid from "@/core/Components/Grid.vue";
import VSelect from "vue-select";
import { useToast } from "vue-toastification";
import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import VueJsonPretty from "vue-json-pretty";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";

export default defineComponent({
  components: {
    CheckboxInput,
    MainSection,
    FormWrapper,
    Loader,
    InputField,
    TheButton,
    DeviceActionsService,
    ToastComponent,
    ButtonWrapper,
    FormDivider,
    JsonEditorVue,
    Grid,
    VSelect,
    VueJsonPretty,
    TableActionHeader,
  },

  data() {
    return {
      assignmentService: new AssignmentService(),
      deviceActionsService: new DeviceActionsService(),
      toast: useToast(),
      response: {} as DeviceActionResponse,
      assignments: [] as DeviceAssignment[],
      isSubmitLoading: false,
      formData: {
        dataWrapper: {
          deviceUuid: "",
          clientId: "",
          data: {},
          requestTimeout: 1,
          storeCommit: true,
        } as DeviceActionCommit,
      },
    };
  },

  async created() {
    await this.getAssignments();
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(JSON.stringify(this.response));
      this.toast.success("Copied to clipboard!");
    },
    async getAssignments() {
      try {
        this.assignments = await this.assignmentService.getAllDeviceAssignments(
          {
            typeOfAssignment: "Company",
            pageNumber: 1,
            pageSize: 100000,
          }
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
      }
    },
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        if (typeof this.formData.dataWrapper.data === "string") {
          this.formData.dataWrapper.data = JSON.parse(
            this.formData.dataWrapper.data
          );
        }
        this.response =
          await this.deviceActionsService.commitDynamicDeviceAction(
            this.formData.dataWrapper
          );
        this.toast.success("Successfully committed asset action!");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
  },
});
</script>
<style>
.powered-by {
  display: none !important;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  @apply bg-primary;
}
</style>
