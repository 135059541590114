<template>
  <!-- Device modals -->
  <generic-packet
    v-if="isGenericPacketModalOpened"
    :device-uuid="genericPacketUuid"
    @closeModal="closeGenericPacketModal"
  />
  <date-picker-modal
    v-if="isShowRouteModalOpened"
    :is-show-route-modal-opened="isShowRouteModalOpened"
    @show-asset-route="showAssetRoute"
    @toggle-modal="isShowRouteModalOpened = false"
  />
  <!-- Zone modals -->
  <zone-create-modal
    v-if="zoneService.isZoneFinishedDrawing"
    :is-zone-create-modal-opened="zoneService.isZoneFinishedDrawing"
    :refresh-data="getZones"
    :wkt-coordinates="zoneService.createdZoneWktCoordinates"
    @toggle-modal="cancelZoneCreate"
  />
  <delete-modal
    :is-deleting="isZoneDeleteModalOpened"
    @cancel-delete="isZoneDeleteModalOpened = false"
    @toggle-modal="isZoneDeleteModalOpened = false"
    @confirm-delete="deleteZone"
  />
  <!-- Floorplan modals -->
  <floorplan-create-modal
    v-if="isFloorplanCreateModalOpened"
    :floorplan-service="floorplanService"
    :is-floor-plan-create-modal-opened="isFloorplanCreateModalOpened"
    @toggle-modal="isFloorplanCreateModalOpened = false"
    @change-to-create-mode="changeFloorplanToCreateMode"
  />
  <delete-modal
    :is-deleting="isFloorplanDeleteModalOpened"
    @cancel-delete="isFloorplanDeleteModalOpened = false"
    @toggle-modal="isFloorplanDeleteModalOpened = false"
    @confirm-delete="deleteFloorplan"
  />
  <!-- Tag modals -->
  <tag-create-modal
    v-if="isTagCreateModalOpened"
    :is-tag-create-modal-opened="isTagCreateModalOpened"
    @toggle-modal="isTagCreateModalOpened = false"
    @change-to-create-mode="changeTagToCreateMode"
    @add-tag="addNewTagToMap"
  />
  <delete-modal
    :is-deleting="isTagDeleteModalOpened"
    @cancel-delete="isTagDeleteModalOpened = false"
    @toggle-modal="isTagDeleteModalOpened = false"
    @confirm-delete="deleteTag"
  />
  <!-- Main section -->
  <main-section :is-map="true">
    <map-loader v-if="isLoading || routeIsLoading" />
    <div class="flex h-12 w-full items-center justify-between px-2">
      <div></div>
      <div class="space-x-2">
        <v-select
          v-model="floorStore.selectedFloor"
          :getOptionLabel="(label:any) => `Floor ${label}`"
          :options="floorStore.floors"
          class="w-[150px]"
        />
      </div>
    </div>
    <div class="h-full-3rem flex w-full">
      <div class="h-[calc(100vh_-_7rem)] w-[350px]">
        <dropdown-button
          :icon="['fac', 'user-device']"
          :name="'Assets'"
          :is-dropdown-opened="showDevicesDatagrid"
          @toggle-datagrid="toggleDevicesDatagrid"
        />
        <devices-map
          v-if="showDevicesDatagrid"
          :devices="devices"
          :web-socket-data="webSocketData"
          :get-selected-row="getSelectedDeviceRow"
          :show-asset-route-date-picker-modal="toggleDatePickerModal"
          :open-generic-packet-modal="openGenericPacketModal"
        />
        <dropdown-button
          :icon="['fas', 'draw-polygon']"
          :name="'Zones'"
          :is-dropdown-opened="showZonesDatagrid"
          @toggle-datagrid="toggleZonesDatagrid"
        />
        <zones-map
          v-if="showZonesDatagrid"
          :zones="zones"
          :get-selected-row="getSelectedZoneRow"
          :is-zone-create-mode="isZoneCreateMode"
          :is-zone-edit-mode="isZoneEditMode"
          @save-edited-zone="saveEditedZone"
          @toggle-create-zone-modal="toggleCreateZoneModal"
          @toggle-zone-edit="toggleZoneEdit"
          @toggle-delete-modal="toggleZoneDeleteModal"
          @cancel-zone-edit="cancelZoneEdit"
          @cancel-zone-create="cancelZoneCreate"
          @toggle-tag-actions="toggleTagActions"
        />
        <dropdown-button
          :icon="['fas', 'image']"
          :name="'Floorplans'"
          :is-dropdown-opened="showFloorplansDatagrid"
          @toggle-datagrid="toggleFloorplansDatagrid"
        />
        <floorplan-map
          v-if="showFloorplansDatagrid"
          :floorplans="floorplans"
          :get-selected-row="getSelectedFloorplanRow"
          :is-floorplan-create-mode="isFloorplanCreateMode"
          :is-floorplan-edit-mode="isFloorplanEditMode"
          @save-floorplan="saveFloorplan"
          @save-edit-floorplan="saveEditFloorplan"
          @change-floorplan-to-edit-mode="changeFloorPlanToEditMode"
          @toggle-create-floorplan-modal="toggleCreateFloorPlanModal"
          @toggle-floorplan-delete-modal="toggleFloorplanDeleteModal"
          @cancel-floorplan-edit-mode="cancelFloorPlanEditMode"
          @cancel-floorplan-create-mode="cancelFloorPlanCreateMode"
          @toggle-tag-actions="toggleTagActions"
        />
      </div>
      <div
        class="h-[calc(100vh_-_7rem)] w-[300px] pb-2"
        v-if="showTagsDatagrid"
      >
        <tags-map
          :tags="tags"
          :get-selected-row="getSelectedTagRow"
          :is-tag-create-mode="isTagCreateMode"
          :is-tag-edit-mode="isTagEditMode"
          @save-tag="saveNewTag"
          @save-edit-tag="saveEditTag"
          @change-tag-to-edit-mode="changeTagToEditMode"
          @toggle-create-tag-modal="toggleCreateTagModal"
          @toggle-tag-delete-modal="toggleTagDeleteModal"
          @cancel-tag-edit-mode="cancelTagEditMode"
          @cancel-creating="cancelTagCreateMode"
          @close-tag-actions="closeTagActions"
        />
      </div>
      <div class="h-{calc(100vh-72px-3rem)} w-full">
        <div id="map" class="relative h-full w-full"></div>
      </div>
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
//Komponente
import MainSection from "@/core/Components/MainSection.vue";
import MapLoader from "@/modules/MapView/Components/MapLoader.vue";
import VSelect from "vue-select";
import DevicesMap from "@/modules/MapView/Components/DevicesMap.vue";
import ZonesMap from "@/modules/MapView/Components/ZonesMap.vue";
import FloorplanMap from "@/modules/MapView/Components/FloorplanMap.vue";
import DataCard from "@/modules/MapView/Components/DataCard.vue";
import ZoneCreateModal from "@/modules/MapView/Components/ZoneCreateModal.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import FloorplanCreateModal from "@/modules/MapView/Components/FloorplanCreateModal.vue";
import DatePickerModal from "@/modules/MapView/Components/DatePickerModal.vue";
import TagsMap from "@/modules/MapView/Components/TagsMap.vue";
import TagCreateModal from "@/modules/MapView/Components/TagCreateModal.vue";
import DropdownButton from "@/modules/MapView/Components/DropdownButton.vue";
import GenericPacket from "@/modules/GenericPacket/Pages/GenericPacket.vue";
//Functions, types, general
import { IconSelector } from "../Services/IconService";
import { ZoneMapping } from "@/modules/MapView/Services/Types/ZoneTypes";
import {
  FloorplanMapping,
  Floorplan,
} from "@/modules/MapView/Services/Types/FloorplanTypes";
import {
  MapObject,
  MapObjectCreate,
  OBJECT_TYPES,
} from "@/modules/MapView/Services/Types/MapObjectTypes";
import checkIfObjectEmpty, {
  ErrorMessageFormatter,
  CalculateRotationBetweenTwoLatLngPoints,
} from "@/core/Services/GlobalFunctions";
import { useToast } from "vue-toastification";
import moment from "moment";
import { JSONPath } from "jsonpath-plus";
//Servisi
import MapService from "@/modules/MapView/Services/MapService";
import ZoneService from "@/modules/MapView/Services/Zone/ZoneService";
import MarkerService from "@/modules/MapView/Services/MarkerService";
import FloorplansService from "@/modules/MapView/Services/Floorplan/FloorplansService";
import TagService, {
  Tag,
  movingObjectIcon,
  movingMarkerIcon,
  WktTypes,
} from "../Services/Tag/TagService";
import WebSocketService from "@/core/WebSocket/WebSocketService";
import L from "leaflet";
import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import { MapObjectService } from "@/modules/MapView/Services/MapObjectService";
import CoordinateConversionService from "@/modules/MapView/Services/CoordinateConversionService";
import MapImageService from "@/modules/MapView/Services/MapImageService";
import DeviceDataService from "@/modules/Devices/Services/DeviceDataService";
import PolylineService from "@/modules/MapView/Services/PolylineService";
import ConfigurationService from "@/core/Services/ConfigurationService";
//Store
import { useFloorStore } from "@/core/Store/floorStore";
import { useUserStore } from "@/core/Store/userStore";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";

export default defineComponent({
  components: {
    MainSection,
    MapLoader,
    VSelect,
    DevicesMap,
    ZonesMap,
    FloorplanMap,
    DataCard,
    DeleteModal,
    ZoneCreateModal,
    FloorplanCreateModal,
    DatePickerModal,
    TagsMap,
    TagCreateModal,
    DropdownButton,
    GenericPacket,
  },
  data() {
    return {
      //html
      mapContainer: {} as HTMLDivElement,
      //conditions
      isLoading: false,
      routeIsLoading: false,
      showDevicesDatagrid: true,
      showZonesDatagrid: false,
      showFloorplansDatagrid: false,
      showTagsDatagrid: false,
      showDeviceDetailsCard: false,
      isZoneCreateMode: false,
      isZoneEditMode: false,
      isZoneDeleteModalOpened: false,
      isFloorplanCreateMode: false,
      isFloorplanCreateModalOpened: false,
      isFloorplanDeleteModalOpened: false,
      isFloorplanEditMode: false,
      isTagCreateMode: false,
      isTagEditMode: false,
      isTagDeleteModalOpened: false,
      isTagCreateModalOpened: false,
      isGenericPacketModalOpened: false,
      isShowRouteModalOpened: false,
      //services
      mapService: {} as MapService,
      markerService: {} as MarkerService,
      floorplanService: {} as FloorplansService,
      zoneService: {} as ZoneService,
      tagService: {} as TagService,
      polylineService: {} as PolylineService,
      assignmentService: new AssignmentService(),
      mapObjectService: new MapObjectService(),
      webSocketService: new WebSocketService(
        `${ConfigurationService.configData.WEB_SOCKET_PREFIX_PATH}/device`
      ),
      coordinateConversionService: new CoordinateConversionService(),
      mapImageService: new MapImageService(),
      deviceDataService: new DeviceDataService(),
      //store
      floorStore: useFloorStore(),
      userStore: useUserStore(),
      changeCompanyStore: useChangeCompanyStore(),
      //General
      toast: useToast(),
      //data
      devices: [] as DeviceAssignment[],
      selectedDevice: {} as DeviceAssignment,
      deviceUuids: [] as { deviceUuid: string }[],
      zones: [] as MapObject[],
      selectedZone: {} as MapObject,
      zoneMapping: new Map<number, ZoneMapping>(),
      zoneBeforeEditLatLng: [] as L.LatLng[] | null,
      floorplans: [] as MapObject[],
      selectedFloorplan: {} as MapObject,
      floorplanMapping: new Map<number, FloorplanMapping>(),
      editingFloorPlanCorners: [] as L.LatLng[],
      tags: [] as Tag[],
      selectedTag: {} as Tag,
      newTag: {
        id: "",
        name: "",
        invertY: false,
      },
      wsData: {} as any,
      metadataMap: new Map<string, any>(),
      activeDevicesMap: new Map<
        string,
        { marker: L.Marker | null; deviceDetails?: DeviceAssignment }
      >(),
      webSocketData: new Map<
        string,
        [number, number, {}, number, string, {}]
      >(),
      activeMarker: {} as L.Marker,
      genericPacketUuid: "",
      assetDataUuid: "",
      from: moment().startOf("day").format("yyyy-MM-DD HH:mm"),
      to: moment().endOf("day").format("yyyy-MM-DD HH:mm"),
    };
  },
  watch: {
    async "floorStore.selectedFloor"() {
      this.polylineService.removePolyline();
      this.isLoading = true;
      try {
        await this.getZones();
        await this.getFloorplans();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isLoading = false;
      }
    },
    selectedZone(
      currentZone: MapObject | undefined,
      previousZone: MapObject | undefined
    ) {
      this.polylineService.removePolyline();
      if (previousZone !== undefined && !checkIfObjectEmpty(previousZone)) {
        const prevZone = this.zoneMapping.get(previousZone.id);
        if (!prevZone) {
          return;
        }
        prevZone.polygon?.setStyle({ color: prevZone.zoneData.data.fillColor });
      }

      if (!currentZone || checkIfObjectEmpty(currentZone)) {
        return;
      }

      const zone = this.zoneMapping.get(currentZone.id);
      if (!zone) {
        return;
      }

      zone.polygon?.setStyle({ color: "red" });
      /* this.zoneService.fitSelectedZoneBounds(
        this.zoneMapping.get(currentZone.id)?.polygon
      ); */

      if (zone.polygon) {
        this.zoneService.fitSelectedZoneBounds(zone.polygon.getBounds());
        //this.zoneService.fitSelectedZoneBounds(zone.polygon || null);
      }

      // Tags
      if (this.showTagsDatagrid) {
        this.tagService.deleteTags(this.tags);
      }
      this.tags = [];
      if (this.selectedZone.data?.tags?.length) {
        this.tags = this.selectedZone.data.tags;
        if (this.showTagsDatagrid) this.drawTags();
      }
    },
    selectedFloorplan(currentFloorPlan: MapObject | undefined) {
      this.polylineService.removePolyline();
      if (!currentFloorPlan) {
        return;
      }
      const floorPlan = this.floorplanMapping.get(
        currentFloorPlan.id
      )?.floorplan;

      if (!floorPlan) {
        return;
      }
      this.floorplanService.fitSelectedFloorPlanBounds(floorPlan);

      // Tags
      if (this.showTagsDatagrid) {
        this.tagService.deleteTags(this.tags);
      }

      this.tags = [];
      if (this.selectedFloorplan.data?.tags?.length) {
        this.tags = this.selectedFloorplan.data.tags;
        if (this.showTagsDatagrid) this.drawTags();
      }
    },
    selectedTag(currentTag: any | undefined) {
      this.polylineService.removePolyline();
      if (!currentTag || checkIfObjectEmpty(currentTag)) {
        return;
      }
      try {
        this.tagService.fitSelectedTagBounds(currentTag);
      } catch (error) {
        console.log(error);
      }
    },
    selectedDevice() {
      this.polylineService.removePolyline();
    },
  },
  async mounted() {
    this.isLoading = true;
    try {
      this.webSocketService.openWSconnection();

      this.mapService = new MapService(
        document.getElementById("map") as HTMLDivElement,
        undefined,
        {
          zoomAnimation: false,
        }
      );
      this.mapService.initMap();
      if (!(this.mapService.map instanceof L.Map)) {
        return;
      }
      this.markerService = new MarkerService(this.mapService.map);
      this.zoneService = new ZoneService(this.mapService.map as L.Map);
      this.floorplanService = new FloorplansService(
        this.mapService.map as L.Map,
        document.getElementById("map") as HTMLDivElement
      );
      this.tagService = new TagService(
        this.mapService.map as L.Map,
        document.getElementById("map") as HTMLDivElement
      );
      this.polylineService = new PolylineService(
        this.mapService.map as L.Map,
        this.markerService as MarkerService
      );

      await Promise.all([
        this.getDevices(),
        this.getZones(),
        this.getFloorplans(),
      ]).then(() => {
        this.webSocketService.subscribeToDevice(
          this.deviceUuids,
          true,
          (data: any) => this.handleWebSocketData(data)
        );
      });
    } catch (error) {
      console.log("Error", error);
    } finally {
      this.isLoading = false;
    }
  },
  unmounted() {
    this.webSocketService.closeWebSocketConnection();
    this.markerService.removeGroup("SPOTIUM");
    this.zoneService.removeZonesFromMap();
    this.floorplanService.removeFloorPlans();
  },
  methods: {
    //Devices
    toggleDatePickerModal(assetDataUuid: string) {
      this.isShowRouteModalOpened = !this.isShowRouteModalOpened;
      this.assetDataUuid = assetDataUuid;
    },
    async showAssetRoute(dateFrom: any, dateTo: any) {
      try {
        this.routeIsLoading = true;
        this.isShowRouteModalOpened = false;
        const response = await this.deviceDataService.getDeviceAssignmentData({
          dataUuid: this.assetDataUuid,
          from: dateFrom,
          to: dateTo,
          channel: 0,
        });

        if (response.length) {
          let arr = [] as number[][];
          response.forEach((element: any) => {
            if (element.payload.x > 0 && element.payload.y > 0) {
              arr.push([element.payload.x, element.payload.y]);
            }
          });
          this.polylineService.addPolyline(arr);
        } else {
          this.toast.error("There's no route to show.");
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.routeIsLoading = false;
      }
    },
    openGenericPacketModal(genericPacketUuid: any) {
      this.isGenericPacketModalOpened = true;
      this.genericPacketUuid = genericPacketUuid;
    },
    closeGenericPacketModal(check: any) {
      if (check === true) {
        this.isGenericPacketModalOpened = false;
      }
    },
    async getDevices() {
      try {
        this.markerService.removeGroup("SPOTIUM");
        this.devices = await this.assignmentService.getAllDeviceAssignments({
          pageNumber: 1,
          pageSize: 100000,
          typeOfAssignment: "Company",
          lastDataSent: true,
        });

        this.deviceUuids = this.devices
          .map((device) => {
            return { deviceUuid: device.device!.deviceUuid };
          })
          .filter((mapped) => mapped.deviceUuid !== undefined);

        this.devices.forEach((device) => {
          if (!device.device) {
            return;
          }

          if (!device.assignment) {
            return;
          }

          const metadataType1 = device.assignment.metadata.find(
            (metadata) => metadata.metadataTypeId === 1
          );
          metadataType1 &&
            this.metadataMap.set(device.device.deviceUuid, metadataType1);

          this.activeDevicesMap.set(device.device.deviceUuid, {
            marker: null,
            deviceDetails: device,
          });
        });

        let haveMarker = false;
        this.activeDevicesMap.forEach((device) => {
          if (
            device.deviceDetails!.assignment?.latitude &&
            device.deviceDetails!.assignment?.longitude
          ) {
            haveMarker = true;
            let marker;
            if (
              device.deviceDetails!.device?.deviceTypeId &&
              IconSelector(device.deviceDetails!.device?.deviceTypeId)
            ) {
              marker = this.markerService.addMarker({
                lat: device.deviceDetails!.assignment?.latitude,
                lng: device.deviceDetails!.assignment?.longitude,
                groupKey: "SPOTIUM",
                returnMarker: true,
                icon: {
                  iconUrl: IconSelector(
                    device.deviceDetails!.device?.deviceTypeId as number
                  ),
                  iconSize:
                    device.deviceDetails!.device?.deviceTypeId == 55
                      ? [40, 30]
                      : [60, 50],
                },
              }) as L.Marker;
            } else {
              marker = this.markerService.addMarker({
                lat: device.deviceDetails!.assignment?.latitude,
                lng: device.deviceDetails!.assignment?.longitude,
                groupKey: "SPOTIUM",
                returnMarker: true,
              }) as L.Marker;
            }

            marker.bindTooltip(device.deviceDetails!.assignment?.name || "", {
              permanent: false,
              direction: "top",
              className: "device-tooltip",
              offset: L.point({ x: 0, y: -10 }),
            });

            if (device.deviceDetails?.lastTimeDeviceSentData) {
              marker.addEventListener("click", () => {
                this.openGenericPacketModal(
                  device.deviceDetails?.assignment?.dataUuid
                );
              });
            }

            /* device.marker = marker; */
          }
        });
        if (haveMarker) {
          this.markerService.fitGroupToMap("SPOTIUM", true);
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getSelectedDeviceRow(selectedRows: DeviceAssignment[]) {
      if (
        selectedRows != undefined &&
        selectedRows[0].assignment?.latitude &&
        selectedRows[0].assignment?.longitude
      ) {
        //Error kad se tooltip na klik pojavi pa na hover ugasi - tokom zooma karte greška
        /* this.activeDevicesMap
          .get(selectedRows[0].device?.deviceUuid)
          ?.marker?.openTooltip(); */

        this.mapService.map.setView(
          [
            selectedRows[0].assignment?.latitude,
            selectedRows[0].assignment?.longitude,
          ],
          20
        );
      }

      let deviceUuid = selectedRows[0].device?.deviceUuid;
      if (deviceUuid) {
        const latlng = this.getGpsDeviceSocketData(deviceUuid);
        if (latlng) {
          this.mapService.map.setView([latlng[0], latlng[1]], 20);
        }
      }

      this.showDeviceDetailsCard = true;
      this.selectedDevice = selectedRows[0];
      //Reset
      this.selectedZone = {} as MapObject;
      this.selectedFloorplan = {} as MapObject;
    },
    //Live data
    saveGpsDeviceSocketData(
      wsData: any,
      lat: number,
      lng: number,
      assetName: string,
      assetMetadata: any
    ) {
      //Dodaj koordinate gps uređaja u mapu kako bi na klik u tablici mogli do koordinata
      if (this.webSocketData.has(wsData.deviceUuid)) {
        // Ako ključ već postoji, ažuriraj podatke
        const existingValue = this.webSocketData.get(wsData.deviceUuid);
        if (existingValue) {
          existingValue[0] = lat;
          existingValue[1] = lng;
          existingValue[2] = wsData;
          existingValue[3] = existingValue[3] + 1;
        }
      } else {
        // Ako ključ ne postoji, dodaj ga s novim lat i lng te porukom
        this.webSocketData.set(wsData.deviceUuid, [
          lat,
          lng,
          wsData,
          0,
          assetName,
          assetMetadata,
        ]);
      }
    },
    getGpsDeviceSocketData(deviceUuid: string) {
      return this.webSocketData.get(deviceUuid);
    },
    handleWebSocketData(data: any) {
      this.wsData = JSON.parse(data.data);
      const latLngAngle =
        this.coordinateConversionService.getLatLngFromMetadata({
          metadata: this.metadataMap.get(this.wsData.deviceUuid),
          wsData: this.wsData,
        });

      if (!latLngAngle) {
        return;
      }
      if (!latLngAngle.lat[0] || !latLngAngle.lng[0]) {
        return;
      }

      const currentMarker = this.activeDevicesMap.get(this.wsData.deviceUuid);

      this.saveGpsDeviceSocketData(
        this.wsData,
        latLngAngle.lat[0],
        latLngAngle.lng[0],
        currentMarker?.deviceDetails?.assignment?.name || "",
        currentMarker?.deviceDetails?.assignment?.metadata
      );

      const canvasId = this.wsData.deviceUuid;
      let customIcon = L.divIcon({
        className: "custom-icon",
        iconSize: [50, 50],
        html: `<canvas id="${canvasId}" width="120" height="50" class=""></canvas>`,
      });

      if (!currentMarker?.marker) {
        let marker;
        marker = this.markerService.addRobotMarker(
          {
            lat: latLngAngle.lat[0],
            lng: latLngAngle.lng[0],
            groupKey: "ACTIVE_DEVICES",
            returnMarker: true,
          },
          customIcon
        ) as L.Marker;

        /* marker.bindTooltip(
          currentMarker?.deviceDetails?.assignment?.name || "",
          {
            permanent: true,
            direction: "top",
            className: "device-tooltip",
            offset: L.point({ x: 15, y: -5 }),
          }
        ); */
        /* this.markerService.removeGroup("SPOTIUM"); */
        this.activeMarker = marker;
        this.activeDevicesMap.set(this.wsData.deviceUuid, { marker: marker });
      } else {
        currentMarker.marker.setLatLng([
          latLngAngle.lat[0],
          latLngAngle.lng[0],
        ]);
      }

      let currentAsset = this.getGpsDeviceSocketData(this.wsData.deviceUuid);
      let canvas = document.getElementById(canvasId) as HTMLCanvasElement;
      let context = canvas.getContext("2d");
      let img = new Image();

      img.src = this.getAssetIcon(
        currentAsset != undefined ? currentAsset[5] : null,
        this.wsData,
        latLngAngle.angle
      );

      img.onload = function () {
        if (context) {
          context.clearRect(0, 0, 120, 50);
          context.save();

          // Add blinking dot so that user knows he's still getting socket data
          context.fillStyle =
            currentAsset != undefined && currentAsset[3] % 2 == 0
              ? "#10b981"
              : "#e5e7eb";
          context.beginPath();
          context.arc(5, 5, 5, 0, 2 * Math.PI);
          context.fill();

          // Add background color for asset name
          context.fillStyle = "#FFFFFF";
          const text = currentAsset != undefined ? currentAsset[4] : "";
          const textDimensions = context.measureText(text);

          context.fillRect(15, 0, textDimensions.width + 6, 10);

          // Add asset name
          context.fillStyle = "black";
          context.font = "12px Arial";
          context.fillText(text, 15, 10);

          // Rotate image if there's angle provided
          if (latLngAngle.angle && latLngAngle.angle.length > 0) {
            context.translate(25, 25); // Postaviti sredinu slike kao središte rotacije
            context.rotate((latLngAngle.angle[0] * Math.PI) / 180);
            context.translate(-25, -25);
            context.drawImage(img, 13, 13, 25, 25);
          } else context.drawImage(img, 15, 15, 20, 20);

          context.restore();
        }
      };
    },
    getAssetIcon(assetMetadata: any, wsData: any, angle: any) {
      const channel = wsData.channel;
      const iconMetadata = assetMetadata.find(
        (metadata: any) => metadata.metadataTypeId === 4
      );

      let iconName;
      if ((channel || channel == 0) && iconMetadata) {
        iconName = JSONPath({
          path: iconMetadata.data.paths[0].path,
          json: wsData,
        });
      }

      if (iconName && iconName[0]) {
        return `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/${iconName[0]}.png`;
      } else {
        return angle && angle.length > 0
          ? `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/locationArrow.png`
          : `${process.env.VUE_APP_PUBLIC_PATH}spotium-map-icons/locationDot.png`;
      }
    },
    //Zones
    async getZones() {
      try {
        this.zoneService.removeZonesFromMap();
        this.zones = await this.mapObjectService.getAllMapObjects({
          pageNumber: 1,
          pageSize: 100000,
          companyId: this.userStore.activeCompanyId,
          typeId: OBJECT_TYPES.ZONE,
        });
        this.zones = this.zones.filter(
          (zone) => zone.floorLevel === this.floorStore.selectedFloor
        );
        this.zoneService.drawZonesOnMap(
          this.zones,
          this.zoneMapping as Map<number, ZoneMapping>
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getSelectedZoneRow(selectedRow: MapObject[]) {
      this.selectedZone = selectedRow[0];
      //Reset
      this.selectedFloorplan = {} as MapObject;
    },
    async saveEditedZone() {
      try {
        await this.mapObjectService.updateMapObject(this.selectedZone.id, [
          {
            path: "/geometry",
            op: "replace",
            value: this.zoneService.editedZoneWktCoordinates,
          },
        ]);
        this.toast.success(this.$t("General.EditedSuccessfully"));
        this.cancelZoneEdit();
      } catch (error) {
      } finally {
        await this.getZones();
      }
    },
    async deleteZone() {
      try {
        await this.mapObjectService.deleteMapObject(this.selectedZone.id);
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isZoneDeleteModalOpened = false;
        await this.getZones();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.selectedZone = {} as MapObject;
      }
    },
    toggleCreateZoneModal() {
      this.isZoneCreateMode = !this.isZoneCreateMode;
      this.zoneService.addZoneToMap();
    },
    toggleZoneEdit() {
      const selectedZone = this.zoneMapping.get(this.selectedZone.id)?.polygon;
      this.zoneBeforeEditLatLng = Object.assign([], selectedZone?.getLatLngs());
      selectedZone?.pm?.toggleEdit();
      this.isZoneEditMode = true;
    },
    toggleZoneDeleteModal() {
      this.isZoneDeleteModalOpened = !this.isZoneDeleteModalOpened;
    },
    cancelZoneCreate() {
      this.isZoneCreateMode = false;
      this.zoneService.isZoneFinishedDrawing = false;
      this.zoneService.cancelZoneCreation();
    },
    cancelZoneEdit() {
      const selectedZone = this.zoneMapping.get(this.selectedZone.id)?.polygon;
      selectedZone?.pm?.disable();

      this.isZoneEditMode = false;

      if (!this.zoneBeforeEditLatLng) {
        return;
      }
      selectedZone?.setLatLngs(this.zoneBeforeEditLatLng);
      this.zoneBeforeEditLatLng = null;
    },
    //Floorplans
    async getFloorplans() {
      if (this.showTagsDatagrid) {
        this.closeTagActions();
      }

      this.floorplanService.removeFloorPlans();
      try {
        this.floorplans = await this.mapObjectService.getAllMapObjects({
          pageNumber: 1,
          pageSize: 100000,
          companyId: this.userStore.activeCompanyId,
          typeId: OBJECT_TYPES.FLOORPLAN,
        });

        this.floorplans = this.floorplans.filter(
          (floorplan) => floorplan.floorLevel === this.floorStore.selectedFloor
        );

        await this.floorplanService.drawFloorplansOnMap(
          this.floorplans,
          this.floorplanMapping
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getSelectedFloorplanRow(selectedRow: MapObject[]) {
      this.selectedFloorplan = selectedRow[0];
      //Reset
      this.selectedZone = {} as MapObject;
    },
    async saveFloorplan() {
      try {
        const mapObject: MapObjectCreate = {
          userId: this.userStore.userId,
          companyId: this.userStore.activeCompanyId,
          floorLevel: this.floorplanService.floorplanCreateDetails.floorPlan,
          geometry: this.coordinateConversionService.convertToWkt(
            new L.Polygon(
              this.floorplanService.floorplanCreateDetails.floorplanObject?.getCorners()
            )
          ),
          typeId: 1,
          data: {
            type: "floorplan",
            name: this.floorplanService.floorplanCreateDetails.name,
            tags: [],
          } as Floorplan,
        };

        const floorPlanId = await this.mapObjectService.createMapObject(
          mapObject
        );

        this.toast.success(this.$t("General.CreatedSuccessfully"));
        this.cancelFloorPlanCreateMode();
        if (!this.floorplanService.floorplanCreateDetails.image) {
          return;
        }

        const formData = new FormData();
        formData.append(
          "image",
          this.floorplanService.floorplanCreateDetails.image
        );

        await this.mapImageService.saveMapImage({
          id: floorPlanId,
          formData: formData,
        });
        this.toast.success("Image saved successfully");
        await this.getFloorplans();
        await this.floorStore.getFloors();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
      }
    },
    async saveEditFloorplan() {
      try {
        await this.mapObjectService.updateMapObject(this.selectedFloorplan.id, [
          {
            path: "/geometry",
            op: "replace",
            value: this.floorplanService.editedWktCoordinates,
          },
        ]);
        this.toast.success(this.$t("General.EditedSuccessfully"));
        await this.getFloorplans();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isFloorplanEditMode = false;
      }
    },
    async deleteFloorplan() {
      try {
        await this.deleteMapImage();
        await this.mapObjectService.deleteMapObject(this.selectedFloorplan.id);
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isFloorplanDeleteModalOpened = false;

        if (this.showTagsDatagrid) {
          this.closeTagActions();
        }

        await this.getFloorplans();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.selectedFloorplan = {} as MapObject;
      }
    },
    async deleteMapImage() {
      try {
        await this.mapImageService.deleteMapImage(this.selectedFloorplan.id);
        this.toast.success("Map image deleted successfully");
        this.isFloorplanDeleteModalOpened = false;
        await this.getFloorplans();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    changeFloorplanToCreateMode(isCreateMode: boolean) {
      this.isFloorplanCreateMode = isCreateMode;
    },
    changeFloorPlanToEditMode() {
      this.isFloorplanEditMode = true;
      const floorPlanToEdit = this.floorplanMapping.get(
        this.selectedFloorplan.id
      );

      if (!floorPlanToEdit?.floorplan) {
        return;
      }

      const corners = floorPlanToEdit.floorplan.getCorners();
      this.editingFloorPlanCorners = JSON.parse(JSON.stringify(corners));

      floorPlanToEdit.floorplan.editing.enable();
      floorPlanToEdit.floorplan.select();
      floorPlanToEdit.floorplan.editing.setMode("rotate");
    },
    toggleCreateFloorPlanModal() {
      this.isFloorplanCreateModalOpened = !this.isFloorplanCreateModalOpened;
    },
    toggleFloorplanDeleteModal() {
      this.isFloorplanDeleteModalOpened = !this.isFloorplanDeleteModalOpened;
    },
    cancelFloorPlanCreateMode() {
      this.isFloorplanCreateMode = false;
      this.floorplanService.cancelFloorPlanCreation();
    },
    cancelFloorPlanEditMode() {
      this.isFloorplanEditMode = false;
      const floorPlanToEdit = this.floorplanMapping.get(
        this.selectedFloorplan.id
      );
      if (!floorPlanToEdit?.floorplan) {
        return;
      }
      this.floorplanService.cancelFloorPlanEdit(
        floorPlanToEdit.floorplan,
        this.editingFloorPlanCorners
      );
    },
    //Tags
    getSelectedTagRow(selectedRow: Tag[]) {
      this.selectedTag = selectedRow[0];
    },
    toggleTagActions() {
      this.showTagsDatagrid = true;
      this.drawTags();
    },
    closeTagActions() {
      this.showTagsDatagrid = false;
      this.tagService.deleteTags(this.tags);
      this.tags = [];
    },
    drawTags() {
      this.tagService.mapObjectsTags = [];

      if (!this.tags.length && this.selectedFloorplan.data?.tags?.length) {
        this.tags = this.selectedFloorplan.data.tags;
      } else if (!this.tags.length && this.selectedZone.data?.tags?.length) {
        this.tags = this.selectedZone.data.tags;
      }

      this.tagService.displayTagsOnMap(this.tags);
    },
    toggleCreateTagModal() {
      this.isTagCreateModalOpened = !this.isTagCreateModalOpened;
    },
    addNewTagToMap(tag: { id: string; name: string; invertY: boolean }) {
      this.newTag = tag;
      this.tagService.addTagToMap();
    },
    async saveNewTag() {
      const rotation = CalculateRotationBetweenTwoLatLngPoints(
        this.tagService.currentCreatingTag.rootMarker.getLatLng(),
        this.tagService.currentCreatingTag.tipMarker.getLatLng()
      );

      const geography = this.tagService.convertGeoJsonToWKT(
        WktTypes.LineString,
        [
          [
            this.tagService.currentCreatingTag.rootMarker.getLatLng().lng,
            this.tagService.currentCreatingTag.rootMarker.getLatLng().lat,
          ],
          [
            this.tagService.currentCreatingTag.tipMarker.getLatLng().lng,
            this.tagService.currentCreatingTag.tipMarker.getLatLng().lat,
          ],
        ]
      );

      const tag: Tag = {
        id: this.newTag.id,
        name: this.newTag.name,
        invertY: this.newTag.invertY,
        position: {
          x: this.tagService.currentCreatingTag.rootMarker.getLatLng().lng,
          y: this.tagService.currentCreatingTag.rootMarker.getLatLng().lat,
        },
        rotation,
        geography,
      };
      this.tags.push(tag);

      if (!checkIfObjectEmpty(this.selectedFloorplan)) {
        this.selectedFloorplan.data.tags = this.tags;
        try {
          await this.mapObjectService.updateMapObject(
            this.selectedFloorplan.id,
            [
              {
                path: "/data",
                op: "replace",
                value: this.selectedFloorplan.data,
              },
            ]
          );
          this.isTagCreateMode = false;
          this.toast.success(this.$t("General.CreatedSuccessfully"));
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.tagService.removeCreatingTag();
          this.tagService.displayNewTag(tag);
        }
      }

      if (!checkIfObjectEmpty(this.selectedZone)) {
        this.selectedZone.data.tags = this.tags;
        try {
          await this.mapObjectService.updateMapObject(this.selectedZone.id, [
            {
              path: "/data",
              op: "replace",
              value: this.selectedZone.data,
            },
          ]);
          this.isTagCreateMode = false;
          this.toast.success(this.$t("General.CreatedSuccessfully"));
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.tagService.removeCreatingTag();
          this.tagService.displayNewTag(tag);
        }
      }
    },
    changeTagToCreateMode(isCreateMode: boolean) {
      this.isTagCreateMode = isCreateMode;
    },
    cancelTagCreateMode() {
      this.tagService.removeCreatingTag();
      this.isTagCreateMode = false;
    },
    changeTagToEditMode() {
      this.isTagEditMode = true;
      let tagForEditing = this.tagService.mapObjectsTags.filter(
        (tag) => tag.id == this.selectedTag.id
      );

      let rootMarkerCoords = [
        this.selectedTag.position.y,
        this.selectedTag.position.x,
      ];

      tagForEditing[0].tipMarker.dragging?.enable();
      tagForEditing[0].rootMarker.dragging?.enable();
      //tagForEditing[0].tipMarker.setIcon(movingObjectIcon);
      tagForEditing[0].rootMarker.setIcon(movingMarkerIcon);

      tagForEditing[0].tipMarker.on("drag", (e: any) => {
        tagForEditing[0].tipMarker.setLatLng([e.latlng.lat, e.latlng.lng]);
        tagForEditing[0].line.setLatLngs([
          [rootMarkerCoords[0], rootMarkerCoords[1]],
          [e.latlng.lat, e.latlng.lng],
        ]);
      });

      tagForEditing[0].rootMarker.on("drag", (e: any) => {
        rootMarkerCoords[0] = e.latlng.lat;
        rootMarkerCoords[1] = e.latlng.lng;

        tagForEditing[0].line.setLatLngs([
          [rootMarkerCoords[0], rootMarkerCoords[1]],
          [
            tagForEditing[0].tipMarker.getLatLng().lat,
            tagForEditing[0].tipMarker.getLatLng().lng,
          ],
        ]);
      });
    },
    cancelTagEditMode() {
      this.isTagEditMode = false;
      this.tagService.deleteTags(this.tags);
      this.drawTags();
    },
    async saveEditTag() {
      let tagForEditing = this.tagService.mapObjectsTags.filter(
        (tag) => tag.id == this.selectedTag.id
      );

      const rotation = CalculateRotationBetweenTwoLatLngPoints(
        tagForEditing[0].rootMarker.getLatLng(),
        tagForEditing[0].tipMarker.getLatLng()
      );

      const geography = this.tagService.convertGeoJsonToWKT(
        WktTypes.LineString,
        [
          [
            tagForEditing[0].rootMarker.getLatLng().lng,
            tagForEditing[0].rootMarker.getLatLng().lat,
          ],
          [
            tagForEditing[0].tipMarker.getLatLng().lng,
            tagForEditing[0].tipMarker.getLatLng().lat,
          ],
        ]
      );

      const editedTag: Tag = {
        id: this.selectedTag.id,
        name: this.selectedTag.name,
        invertY: this.selectedTag.invertY,
        position: {
          x: tagForEditing[0].rootMarker.getLatLng().lng,
          y: tagForEditing[0].rootMarker.getLatLng().lat,
        },
        rotation,
        geography,
      };

      this.tags = this.tags.filter((tag) => tag.id != this.selectedTag.id);
      this.tags.push(editedTag);

      if (!checkIfObjectEmpty(this.selectedFloorplan)) {
        this.selectedFloorplan.data.tags = this.tags;
        try {
          await this.mapObjectService.updateMapObject(
            this.selectedFloorplan.id,
            [
              {
                path: "/data",
                op: "replace",
                value: this.selectedFloorplan.data,
              },
            ]
          );
          this.isTagEditMode = false;
          this.toast.success(this.$t("General.EditedSuccessfully"));
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.tagService.deleteTags(this.tags);
          this.drawTags();
        }
      }

      if (!checkIfObjectEmpty(this.selectedZone)) {
        this.selectedZone.data.tags = this.tags;
        try {
          await this.mapObjectService.updateMapObject(this.selectedZone.id, [
            {
              path: "/data",
              op: "replace",
              value: this.selectedZone.data,
            },
          ]);
          this.isTagEditMode = false;
          this.toast.success(this.$t("General.EditedSuccessfully"));
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.tagService.deleteTags(this.tags);
          this.drawTags();
        }
      }
    },
    toggleTagDeleteModal() {
      this.isTagDeleteModalOpened = !this.isTagDeleteModalOpened;
    },
    async deleteTag() {
      this.tagService.deleteTag(this.selectedTag);
      this.tags = this.tags.filter((tag) => tag.id != this.selectedTag.id);

      if (!checkIfObjectEmpty(this.selectedFloorplan)) {
        this.selectedFloorplan.data.tags = this.tags;
        try {
          await this.mapObjectService.updateMapObject(
            this.selectedFloorplan.id,
            [
              {
                path: "/data",
                op: "replace",
                value: this.selectedFloorplan.data,
              },
            ]
          );
          this.toast.success(this.$t("General.DeletedSuccessfully"));
          this.isTagDeleteModalOpened = false;
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }

      if (!checkIfObjectEmpty(this.selectedZone)) {
        this.selectedZone.data.tags = this.tags;
        try {
          await this.mapObjectService.updateMapObject(this.selectedZone.id, [
            {
              path: "/data",
              op: "replace",
              value: this.selectedZone.data,
            },
          ]);
          this.toast.success(this.$t("General.DeletedSuccessfully"));
          this.isTagDeleteModalOpened = false;
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }
    },
    //General
    toggleDevicesDatagrid() {
      this.showDevicesDatagrid = !this.showDevicesDatagrid;
      this.showZonesDatagrid = false;
      this.showFloorplansDatagrid = false;
    },
    toggleZonesDatagrid() {
      this.showZonesDatagrid = !this.showZonesDatagrid;
      this.showDevicesDatagrid = false;
      this.showFloorplansDatagrid = false;
    },
    toggleFloorplansDatagrid() {
      this.showFloorplansDatagrid = !this.showFloorplansDatagrid;
      this.showDevicesDatagrid = false;
      this.showZonesDatagrid = false;
    },
  },
});
</script>

<style scoped></style>
