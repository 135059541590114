import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex h-full w-full items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    "is-displayed": _ctx.isCreateModalOpened,
    "no-padding": true,
    title: _ctx.$t('Groups.CreateTitle'),
    onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
          default: _withCtx(() => [
            _createElementVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
            }, [
              _createVNode(_component_form_divider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_input_field, {
                    modelValue: _ctx.formData.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
                    errorMessage: _ctx.v$?.formData['name']?.$errors[0]?.$message,
                    isError: _ctx.v$?.formData['name']?.$error,
                    label: _ctx.$t('Groups.Name'),
                    required: true
                  }, null, 8, ["modelValue", "errorMessage", "isError", "label"])
                ]),
                _: 1
              }),
              _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
            ], 32)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}