import ApiService from "@/core/Axios/ApiService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import {
  CreateSensor,
  Sensor,
  SensorSentPackets,
  SensorSentPacketsProps,
} from "@/modules/Parkom/Submodules/Sensors/Services/SensorTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class SensorService {
  async getSensors({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }): Promise<Sensor[]> {
    const url = GenerateUrl({
      path: "/api/sensors",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

    const response = await ApiService.get(url);
    return this._mapSensors(response.data.items);
  }

  async getSensorSentPackets({
    mac,
    packetType,
    pageSize,
    pageNumber,
    to,
    from,
  }: SensorSentPacketsProps): Promise<SensorSentPackets[]> {
    const url = GenerateUrl({
      path: `/api/sensors/${mac}/packet`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        PacketType: packetType,
        PageSize: pageSize,
        PageNumber: pageNumber,
        To: to,
        From: from,
      },
    });

    const response = await ApiService.get(url);
    return response.data.map((packet: any) => {
      if (packet?.start) {
        debugger;
        packet["fwStart"] = packet.start;
        delete packet["start"];
      }
      if (packet?.end) {
        packet["fwEnd"] = packet.end;
        delete packet["end"];
      }
      if (packet?.icds) {
        packet["bletags"] = "";
        for (let index = 0; index < packet.icds.length; index++) {
          const el = packet?.icds[index];
          packet["bletags"] += " " + el.icd + " (" + el.signal + ")";
        }
      }
      packet["timeDifference"] = Math.round(packet["timeDifference"]);
      return packet;
    });
  }

  async createSensor(sensor: CreateSensor): Promise<any> {
    const url = GenerateUrl({
      path: "/api/sensors",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.post(url, sensor);
  }

  private _mapSensors(response: Sensor[]): Sensor[] {
    return response.map((sensor: Sensor) => this._mapSensor(sensor));
  }

  private _mapSensor(sensor: Sensor): Sensor {
    return {
      id: sensor.id,
      deviceUuid: sensor.deviceUuid,
      mac: sensor.mac,
      lastSync: sensor.lastSync,
      rtc: sensor.rtc,
      batteryLevel:
        sensor.batteryLevel !== null ? `${sensor.batteryLevel}%` : null,
      voltage:
        sensor.voltage !== null && typeof sensor.voltage === "number"
          ? `${sensor.voltage}V`
          : null,
      firmwareVersion: sensor.firmwareVersion,
      imei: sensor.imei,
      spotId: sensor.spotId,
      spot: sensor.spot,
      spotOccupied: sensor.spotOccupied,
      spaceId: sensor.spaceId,
      space: sensor.space,
      mag1Temp: sensor.mag1Temp !== null ? `${sensor.mag1Temp}°C` : null,
      snr: sensor.snr,
      rsrp: sensor.rsrp,
      ecl: sensor.ecl,
      rssi: sensor.rssi,
      rsrq: sensor.rsrq,
      cellId: sensor.cellId,
      searchingForNetworkCount: sensor.searchingForNetworkCount,
      modemRebootCount: sensor.modemRebootCount,
      modemDisableCounter: sensor.modemDisableCounter,
      noResponseCounter: sensor.noResponseCounter,
      powerOnCount: sensor.powerOnCount,
      band: sensor.band,
      runtimeTotalSeconds: sensor.runtimeTotalSeconds,
      totalSleep: sensor.totalSleep,
      unhandledRestartCounter: sensor.unhandledRestartCounter,
      totalConsumptionMAh: sensor.totalConsumptionMAh,
      ramRetentionCRCError: sensor.ramRetentionCRCError,
      packetSize: sensor.packetSize,
      ccid: sensor.ccid,
      imsi: sensor.imsi,
      packetsSendCount: sensor.packetsSendCount,
      packetSendSum: sensor.packetSendSum,
      packetsSentMerged:
        sensor.packetsSendCount !== null && sensor.packetSendSum !== null
          ? `${sensor.packetsSendCount} (Σ${sensor.packetSendSum})`
          : null,
    };
  }
}
