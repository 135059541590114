import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-6" }
const _hoisted_2 = { class: "pb-8 pt-6" }
const _hoisted_3 = { class: "grid w-full grid-cols-2 gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_button = _resolveComponent("the-button")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_modal, {
      "is-confirmation-modal": true,
      "is-displayed": _ctx.isConfirmationModalOpened,
      title: "Confirm",
      onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.generateModalText()), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_the_button, {
              text: "No, cancel!",
              variant: "outline",
              onExecuteMethod: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-confirmation-modal')))
            }),
            _createVNode(_component_the_button, {
              text: "Yes, proceed!",
              variant: "delete",
              onExecuteMethod: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm-action')))
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["is-displayed"])
  ]))
}