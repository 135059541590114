import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "flex w-full items-center justify-center"
}
const _hoisted_2 = {
  key: 4,
  class: "px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sources_view = _resolveComponent("sources-view")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isNodesViewOpened,
    "is-full-screen-modal": true,
    "remove-outside-click": true,
    title: `Nodes for ${_ctx.deviceDetails.name}`,
    onToggleModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      (_ctx.isSourcesViewOpened)
        ? (_openBlock(), _createBlock(_component_sources_view, {
            key: 0,
            "display-id": _ctx.deviceDetails.id,
            "is-sources-view-opened": _ctx.isSourcesViewOpened,
            "node-details": _ctx.nodeDetails,
            onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isSourcesViewOpened = false))
          }, null, 8, ["display-id", "is-sources-view-opened", "node-details"]))
        : _createCommentVNode("", true),
      (_ctx.isCreateModalOpened)
        ? (_openBlock(), _createBlock(_component_create_form, {
            key: 1,
            "display-id": _ctx.deviceDetails.id,
            "is-create-modal-opened": _ctx.isCreateModalOpened,
            "last-node-index": _ctx.nodes[_ctx.nodes.length - 1]?.index || 0,
            "refresh-data": async () => _ctx.getDisplayNodes(_ctx.deviceDetails.id),
            onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isCreateModalOpened = false))
          }, null, 8, ["display-id", "is-create-modal-opened", "last-node-index", "refresh-data"]))
        : _createCommentVNode("", true),
      (_ctx.isEditModalOpened)
        ? (_openBlock(), _createBlock(_component_edit_form, {
            key: 2,
            "is-edit-modal-opened": _ctx.isEditModalOpened,
            "node-prop": _ctx.selectedNode,
            "refresh-data": async () => _ctx.getDisplayNodes(_ctx.deviceDetails.id),
            onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEditModalOpened = false))
          }, null, 8, ["is-edit-modal-opened", "node-prop", "refresh-data"]))
        : _createCommentVNode("", true),
      _createVNode(_component_delete_modal, {
        "is-deleting": _ctx.isDeleteModalOpened,
        onCancelDelete: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
        onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
        onConfirmDelete: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openConfirmationModal('delete')))
      }, null, 8, ["is-deleting"]),
      _createVNode(_component_confirmation_modal, {
        "delete-text": _ctx.confirmationModalText,
        "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
        onConfirmAction: _ctx.confirmationModalAction,
        onCloseConfirmationModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
        onToggleModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
      }, null, 8, ["delete-text", "is-confirmation-modal-opened", "onConfirmAction"]),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_small_loader)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_table_action_header, {
              icon: ['fas', 'add'],
              "button-text": "Create",
              onExecuteMethod: _ctx.toggleCreateModal
            }, null, 8, ["onExecuteMethod"]),
            _createVNode(_component_custom_table, {
              "is-data-source-loading": _ctx.isDatasourceLoading,
              options: _ctx.options,
              "table-data": _ctx.nodes,
              "table-headers": _ctx.tableHeaders,
              onSelectedRows: _ctx.getSelectedRows,
              onManageSources: _ctx.manageSources
            }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onManageSources"])
          ]))
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}