<template>
  <modal
    :is-displayed="isEditModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    title="Edit resource"
    @toggle-modal="toggleEditModal"
  >
    <edit-form
      :is-form-in-modal="true"
      :object-id="selectedResource.deviceAssignmentId"
      :refresh-data="getAllResources"
    />
  </modal>

  <modal
    :is-displayed="isCreateModalOpened"
    :is-select-modal="true"
    :need-for-scroll="true"
    title="Create resource"
    @toggle-modal="toggleCreateModal"
  >
    <create-form :is-form-in-modal="true" :refresh-data="getAllResources" />
  </modal>

  <delete-modal
    :is-deleting="isDeleting"
    :selected-data="selectedResource"
    delete-text="Are you sure you want to delete "
    @toggle-modal="toggleDeleteModal"
    @cancel-delete="toggleDeleteModal"
    @confirm-delete="openConfirmationModal('delete')"
  />

  <confirmation-modal
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    delete-text="delete"
    @close-confirmation-modal="toggleConfirmationModal"
    @toggle-modal="toggleConfirmationModal"
    @confirm-action="confirmationModalAction()"
  />
  <main-section title="Access manager/Resources">
    <loader
      v-if="isLoading"
      inner-ring-thickness="border-t-8"
      outer-ring-thickness="border-8"
    />
    <div v-else class="space-y-4">
      <div class="mb-4 flex items-center justify-end">
        <the-button
          v-if="permissionStore.hasPermission(permissions.CreateAssignment)"
          :icon="['fas', 'plus']"
          buttonType="primaryButton"
          text="Create"
          @execute-method="changeRoute"
        />
      </div>
      <empty-state v-if="!isLoading && !resources.length" pageName="resources">
        <the-button
          :icon="['fas', 'plus']"
          buttonType="primaryButton"
          text="Add new resource"
          @execute-method="changeRoute"
        />
      </empty-state>
      <custom-table
        v-else
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="resources"
        :table-headers="tableHeaders"
        @selected-rows="getSelectedRows"
        @force-refresh="getResources"
      />
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TheButton from "@/core/Components/TheButton.vue";
import Loader from "@/core/Components/Loader.vue";
import ResourceService, { Resource } from "../Services/ResourceService";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import Modal from "@/core/Components/Modal.vue";
import EditForm from "../Components/EditForm.vue";
import CreateForm from "../Components/CreateForm.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import EmptyState from "@/core/Components/EmptyState.vue";
import AccessManagerService from "@/modules/AccessManager/Services/AccessManagerService";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { useToast } from "vue-toastification";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import DeviceServiceAssignment, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    MainSection,
    CustomTable,
    TheButton,
    Loader,
    Modal,
    EditForm,
    CreateForm,
    DeleteModal,
    ConfirmationModal,
    EmptyState,
  },
  data() {
    return {
      selectedRows: [] as Resource[],
      selectedResource: {} as Resource,
      accessManagerService: new AccessManagerService(),
      isLoading: false,
      isEditModalOpened: false,
      isCreateModalOpened: false,
      isDataSourceLoading: false,
      isDeleting: false,
      isConfirmationModalOpened: false,
      confirmationModalText: "",
      tdceDevices: [] as DeviceAssignment[],
      resourceService: new ResourceService(),
      deviceAssignmentService: new DeviceServiceAssignment(),
      resources: [] as Resource[],
      toast: useToast(),
      permissionStore: usePermissionStore(),
      changeCompanyStore: useChangeCompanyStore(),
      permissions: Permissions,
      tableHeaders: {
        name: { header: "Name", visible: true },
        shortName: { header: "Short name", visible: true },
        resourceTypeName: { header: "Resource type", visible: true },
        active: { header: "Active", visible: true },
      },
      options: {
        edit: {
          header: "Edit",
          icon: ["fas", "pen"],
          action: this.toggleEditModal,
        },
        delete: {
          header: "Delete",
          icon: ["fas", "trash"],
          action: this.toggleDeleteModal,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getResources();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getResources() {
      this.isDataSourceLoading = true;
      try {
        await this.getAllResources();
        await this.getAllTdceDevices();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async getAllResources() {
      this.isDataSourceLoading = true;
      try {
        this.resources = await this.resourceService.getAllResources();
        this.isEditModalOpened = false;
        this.isCreateModalOpened = false;
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async deleteResource() {
      const selectedTdceDevice = this.tdceDevices.find(
        (tdceDevice: DeviceAssignment) =>
          tdceDevice.deviceId === this.selectedResource.deviceId
      );

      this.isDataSourceLoading = true;
      try {
        if (this.selectedResource.deviceAssignmentId && selectedTdceDevice) {
          await this.deviceAssignmentService.deleteDevice(
            this.selectedResource.deviceAssignmentId
          );
          if (!selectedTdceDevice.device) {
            this.toast.error("Device not found!");
            return;
          }
          if (!selectedTdceDevice.assignment) {
            this.toast.error("Device assignment not found!");
            return;
          }
          await this.resourceService.addResourceToStorageForSync(
            selectedTdceDevice.device.deviceUuid,
            selectedTdceDevice.assignment.dataUuid,
            this.generateResourceObject()
          );
        }
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.isConfirmationModalOpened = false;
        this.toggleDeleteModal();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        await this.getAllResources();
        this.isDataSourceLoading = false;
      }
    },

    async getAllTdceDevices() {
      try {
        this.tdceDevices = await this.accessManagerService.getAllTdceDevices();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },

    changeRoute() {
      this.toggleCreateModal();
    },

    getSelectedRows(values: Resource[]) {
      this.selectedRows = values;
      if (values.length) {
        this.selectedResource = values[0];
      }
    },

    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },

    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleDeleteModal() {
      this.isDeleting = !this.isDeleting;
    },

    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },
    toggleConfirmationModal() {
      this.isConfirmationModalOpened = !this.isConfirmationModalOpened;
    },

    generateResourceObject(): Resource {
      const storageResourceObject: Resource = {
        id: this.selectedResource.id,
        uuid: this.selectedResource.uuid,
        name: this.selectedResource.name,
        shortName: this.selectedResource.shortName,
        configuration: this.selectedResource.configuration,
        active: this.selectedResource.active,
        type: this.selectedResource.type,
        action: Number(process.env.VUE_APP_DELETE_ACTION),
        deviceId: this.selectedResource.deviceId,
      };

      return storageResourceObject;
    },

    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteResource();
          break;
      }
    },
  },
});
</script>
<style scoped></style>
