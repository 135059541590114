<template>
  <div class="py-3">
    <label
      class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
      >{{ label }}</label
    >
    <div class="tags-input">
      <ul id="tags">
        <li v-for="(tag, index) in tagsNew" :key="index" class="tag">
          <span class="tag-title">{{ tag }}</span>
          <span class="tag-close-icon" @click="() => removeIndex(index)">
            <font-awesome-icon :icon="['fas', 'times']" class="text-[10px]" />
          </span>
        </li>
      </ul>
      <input
        ref="tagsInput"
        v-model="tag"
        placeholder="Press space to add next input"
        type="text"
        @keyup.space.prevent="addTags"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  emits: ["on-tag-mutate"],
  props: {
    tags: {
      type: Array as PropType<string[] | null>,
    },
    label: String,
  },
  data() {
    return {
      tagsNew: this.tags ? this.tags : ([] as string[]),
      tag: "",
    };
  },
  watch: {
    tags(values) {
      this.tagsNew = values;
    },
  },
  mounted() {
    if (this.$refs.tagsInput instanceof HTMLInputElement) {
      this.$refs.tagsInput.addEventListener("keydown", this.removeElement);
    }
  },
  unmounted() {
    if (this.$refs.tagsInput instanceof HTMLInputElement) {
      this.$refs.tagsInput.removeEventListener("keydown", this.removeElement);
    }
  },
  methods: {
    addTags() {
      if (
        this.tag !== "" &&
        parseInt(this.tag, 10) > -1 &&
        !this.containsDuplicate(String(parseInt(this.tag, 10)), this.tagsNew)
      ) {
        this.tagsNew = [...this.tagsNew, String(parseInt(this.tag, 10))];
        this.tag = "";
        this.$emit("on-tag-mutate", this.tagsNew);
      } else {
        this.tag = "";
      }
    },
    containsDuplicate(tag: string, tags: string[]) {
      if (!tags) {
        return;
      }
      return tags.includes(tag);
    },
    removeIndex(index: number) {
      this.tagsNew = this.tagsNew.filter((tag, i) => i !== index);
      this.$emit("on-tag-mutate", this.tagsNew);
    },
    removeElement(event: KeyboardEvent) {
      if (event.key === "Backspace" && this.tag === "") {
        this.removeIndex(this.tagsNew.length - 1);
      }
    },
  },
});
</script>

<style scoped></style>
