import { RouteRecordRaw } from "vue-router";
import EventsIndex from "@/modules/AccessManager/SubModules/Events/Pages/Index.vue";
import ResourceIndex from "@/modules/AccessManager/SubModules/Resources/Pages/Index.vue";
import ProfileConfigurationIndex from "@/modules/AccessManager/SubModules/ProfileConfiguration/Pages/Index.vue";
import Dashboard from "@/modules/AccessManager/Pages/Dashboard.vue";
import Index from "@/modules/AccessManager/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

// export function createAccessManagerDashboardRoute(): RouteRecordRaw {
//     return {
//         name: "accessManagerDashboard",
//         path: "/dashboard",
//         component: Dashboard,
//         meta: {
//             id: "accessManagerDashboard",
//             label: "Dashboard",
//             icon: ["fas", "table-columns"],
//             showInNavigation: true,
//             dontDisplayOnMainNavigation: true,
//         },
//     };
// }

// export function createAccessManagerEventsRoute(): RouteRecordRaw {
//     return {
//         name: "accessManagerEvents",
//         path: "/events",
//         component: EventsIndex,
//         meta: {
//             id: "accessManagerEvents",
//             label: "Events",
//             icon: ["fas", "calendar-day"],
//             showInNavigation: true,
//             dontDisplayOnMainNavigation: true,
//         },
//     };
// }
//
// export function createAccessManagerResourceRoute(): RouteRecordRaw {
//     return {
//         name: "accessManagerResources",
//         path: "/resources",
//         component: ResourceIndex,
//         meta: {
//             id: "accessManagerResources",
//             label: "Resources",
//             icon: ["fas", "tower-broadcast"],
//             showInNavigation: true,
//             dontDisplayOnMainNavigation: true,
//         },
//     };
// }
//
// export function createAccessManagerProfileConfigurationRoute(): RouteRecordRaw {
//     return {
//         name: "accessManagerProfileConfigurations",
//         path: "/profile-configurations",
//         component: ProfileConfigurationIndex,
//         meta: {
//             id: "accessManagerProfileConfigurations",
//             label: "Profile configurations",
//             icon: ["fas", "id-card"],
//             showInNavigation: true,
//             dontDisplayOnMainNavigation: true,
//         },
//     };
// }

export function createAccessManagerRoute(): RouteRecordRaw {
  return {
    name: "accessManager",
    path: "/",
    redirect: {
      name: "accessManagerDashboard",
    },
    component: Index,
    meta: {
      id: "accessManager",
      label: "Sidebar.AccessManager",
      icon: ["fas", "door-open"],
      showInNavigation: true,
      group: "main",
      authorize: [Permissions.ViewAssignment],
    },
    children: [
      {
        name: "accessManagerDashboard",
        path: "/access-manager/dashboard",
        component: Dashboard,
        meta: {
          id: "accessManagerDashboard",
          label: "Sidebar.Dashboard",
          icon: ["fas", "table-columns"],
          showInNavigation: true,
          group: "main",
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "accessManagerProfileConfigurations",
        path: "/access-manager/profile-configurations",
        component: ProfileConfigurationIndex,
        meta: {
          id: "accessManagerProfileConfigurations",
          label: "Sidebar.ProfileConfigurations",
          icon: ["fas", "id-card"],
          showInNavigation: true,
          group: "main",
          authorize: [Permissions.AuthView],
        },
      },
      {
        name: "accessManagerResources",
        path: "/access-manager/resources",
        component: ResourceIndex,
        meta: {
          id: "accessManagerResources",
          label: "Sidebar.Resources",
          icon: ["fas", "tower-broadcast"],
          showInNavigation: true,
          group: "main",
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "accessManagerEvents",
        path: "/access-manager/events",
        component: EventsIndex,
        meta: {
          id: "accessManagerEvents",
          label: "Sidebar.Events",
          icon: ["fas", "calendar-day"],
          showInNavigation: true,
          group: "main",
          authorize: [Permissions.ViewAssignment],
        },
      },
    ],
  };
}
