import ApiService from "@/core/Axios/ApiService";
import { Application } from "@/modules/Applications/Services/ApplicationService";
import { IntegrationTypes } from "@/core/Services/Enums";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type Integration = {
  id: number;
  name: string;
  config: any;
  isActive: boolean;
  integrationTypeId: number;
  integrationType: IntegrationType;
  applicationId: number;
  application: Application;
  forwardOnlyPayload: boolean;
  executionOrder: number | null;
};

export type IntegrationType = {
  id: number;
  name: string;
  config: any;
};

export type IntegrationObject = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: Integration[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
};

export type CreateEditIntegration = {
  id?: number;
  name: string;
  applicationId: number;
  integrationTypeId: number;
  config: any;
  isActive: boolean;
  executionOrder: number | null;
};

export default class IntegrationService {
  async getAllIntegrations(
    pageNumber = 1,
    pageSize = 10
  ): Promise<IntegrationObject> {
    const url = GenerateUrl({
      path: "/api/integrations",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortOrder: "Ascending",
        SortBy: "Name",
        Include: ["Application", "IntegrationType"],
      },
    });
    const response = await ApiService.get(url);

    const tempObject: IntegrationObject = {
      hasNextPage: response.data.hasNextPage,
      hasPreviousPage: response.data.hasPreviousPage,
      items: this._mapIntegrations(response.data.items),
      pageIndex: response.data.pageIndex,
      totalPages: response.data.totalPages,
      totalCount: response.data.totalCount,
    };

    return tempObject;
  }

  async getIntegrationWithIdentifier(identifier: number): Promise<Integration> {
    const url = GenerateUrl({
      path: `/api/integrations/${identifier}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        Include: ["Application", "IntegrationType"],
      },
    });
    const response = await ApiService.get(url);
    return this._mapIntegrationDetails(response.data);
  }

  async getIntegrationTypes(): Promise<IntegrationType[]> {
    const response = await ApiService.get(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/integration-types`
    );
    return this._mapIntegrationTypes(response.data);
  }

  async createIntegration(integration: CreateEditIntegration): Promise<any> {
    if (integration.integrationTypeId === IntegrationTypes.MQTT) {
      integration.config.port = Number(integration.config.port);
    }

    return await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/integrations`,
      integration
    );
  }

  async editIntegration({
    integrationPatch,
    id,
  }: {
    integrationPatch: PatchObject[];
    id: number;
  }): Promise<any> {
    return await ApiService.patch(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/integrations/${id}`,
      integrationPatch
    );
  }

  async deleteIntegration(id: number): Promise<any> {
    return await ApiService.delete(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/integrations/${id}`
    );
  }

  private _mapIntegrations(response: Integration[]): Integration[] {
    return response.map((integration: Integration) =>
      this._mapIntegration(integration)
    );
  }

  private _mapIntegration(integration: Integration): Integration {
    return {
      id: integration.id,
      name: integration.name,
      integrationType: integration.integrationType,
      integrationTypeId: integration.integrationTypeId,
      isActive: integration.isActive,
      applicationId: integration.applicationId,
      config: integration.config,
      forwardOnlyPayload: integration.forwardOnlyPayload,
      executionOrder: integration.executionOrder,
      application: integration.application,
    };
  }

  private _mapIntegrationDetails(integration: Integration): Integration {
    return {
      id: integration.id,
      name: integration.name,
      integrationType: integration.integrationType,
      integrationTypeId: integration.integrationTypeId,
      isActive: integration.isActive,
      applicationId: integration.applicationId,
      config: integration.config,
      forwardOnlyPayload: integration.forwardOnlyPayload,
      executionOrder: integration.executionOrder,
      application: integration.application,
    };
  }

  private _mapIntegrationTypes(response: IntegrationType[]): IntegrationType[] {
    return response.map((integrationType: IntegrationType) =>
      this._mapIntegrationType(integrationType)
    );
  }

  private _mapIntegrationType(
    integrationType: IntegrationType
  ): IntegrationType {
    return {
      id: integrationType.id,
      name: integrationType.name,
      config: integrationType.config,
    };
  }
}
