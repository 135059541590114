<template>
  <DataTable
    :rows="10"
    :paginator="true"
    :value="tableData"
    class="p-datatable-users"
    data-key="id"
    responsiveLayout="scroll"
    v-model:selection="selectedValue"
  >
    <template #empty> There is no data to display! </template>
    <Column headerStyle="width:150px" v-if="isSelectModal" selectionMode="single" dataKey="id" v-model:selection="selectedValue"> </Column>
    <Column headerStyle="width:150px" v-for="(value, key) in tableHeaders" :field="key" :header="value" :key="key">
      <template #body="{ data }">
        {{ data[key] }}
      </template>
    </Column>
  </DataTable>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
export default defineComponent({
  name: "DynamicTable",
  components: { DataTable, Column },
  props: ["tableData", "title", "tableHeaders", "isSelectModal", "test"],
  emits: ["ud-method", "selected-value"],
  data() {
    return {
      selectedValue: null,
    };
  },
  watch: {
    selectedValue(val) {
      this.$emit("selected-value", val);
    },
  },
});
</script>
<style scoped>
.ud-column td {
  display: flex;
  justify-content: flex-end;
}
</style>
