<template>
  <form @submit.prevent="handleSubmit">
    <form-divider>
      <input-field
        v-model="formData.name"
        :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
        :isError="v$?.formData['name']?.$error"
        :label="$t('Integrations.Name')"
        :required="true"
      />
      <div class="relative items-center py-3">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >{{ $t("Integrations.ConnectionType") }}</label
        >
        <v-select
          v-model="formData.config.connectionType"
          :options="connectionTypeOptions"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <div class="relative items-center py-3">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >{{ $t("Integrations.MqttProtocolVersion") }}</label
        >
        <v-select
          v-model="formData.config.mqttProtocolVersion"
          :options="mqttProtocolVersionOptions"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <div
        :class="`grid ${
          formData.config.connectionType === 'TCP'
            ? 'grid-cols-2 space-x-4'
            : 'grid-cols-1'
        } `"
      >
        <input-field
          v-model="formData.config.endpoint"
          :errorMessage="v$?.formData?.config['endpoint']?.$errors[0]?.$message"
          :isError="v$?.formData?.config['endpoint']?.$error"
          :label="$t('Integrations.Endpoint')"
          :required="true"
        />
        <input-field
          v-if="formData.config.connectionType === 'TCP'"
          v-model="formData.config.port"
          :errorMessage="v$?.formData?.config['port']?.$errors[0]?.$message"
          :isError="v$?.formData?.config['port']?.$error"
          :label="$t('Integrations.Port')"
          :required="true"
          type="number"
        />
      </div>
      <checkbox-input
        v-model="formData.config.hasCredentials"
        :is-checked="formData.config.hasCredentials"
        :label="$t('Integrations.HasCredentials')"
      />
      <div v-if="formData.config.hasCredentials">
        <input-field
          v-model="formData.config.credentials.username"
          :errorMessage="
            v$?.formData?.config?.credentials['username']?.$errors[0]?.$message
          "
          :isError="v$?.formData?.config['username']?.$error"
          :label="$t('Integrations.Username')"
          :required="true"
        />
        <input-field
          v-model="formData.config.credentials.password"
          :errorMessage="
            v$?.formData?.config?.credentials['password']?.$errors[0]?.$message
          "
          :isError="v$?.formData?.config['password']?.$error"
          :label="$t('Integrations.Password')"
          :required="true"
          type="password"
        />
      </div>
      <checkbox-input
        v-model="formData.config.isWithCleanSession"
        :is-checked="formData.config.isWithCleanSession"
        :label="$t('Integrations.CleanSession')"
      />
      <input-field
        v-model="formData.config.topic"
        :errorMessage="v$?.formData?.config['topic']?.$errors[0]?.$message"
        :isError="v$?.formData?.config['topic']?.$error"
        :label="$t('Integrations.Topic')"
        :required="true"
        @keyup="trimTopic"
      />
      <div class="relative items-center py-3">
        <label
          class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
          >QOS</label
        >
        <v-select
          v-model="formData.config.qos"
          :options="qosOptions"
          :reduce="(spot : any) => spot.id"
          class="mt-1"
          label="name"
          :clearable="false"
        ></v-select>
      </div>
      <checkbox-input
        v-model="formData.config.isWithRetainFlag"
        :is-checked="formData.config.isWithRetainFlag"
        :label="$t('Integrations.RetainFlag')"
      />
      <multi-json-input @input="handleTopicChange" />
      <tags-input
        :label="$t('Integrations.Channels')"
        :tags="formData.config.channels"
        @on-tag-mutate="(tags) => (formData.config.channels = tags)"
      />
      <input-field
        v-model="formData.executionOrder"
        :label="$t('Integrations.ExecutionOrder')"
        type="number"
        :errorMessage="v$?.formData['executionOrder']?.$errors[0]?.$message"
        :isError="v$?.formData['executionOrder']?.$error"
      />
      <checkbox-input
        v-model="formData.isActive"
        :is-checked="formData.isActive"
        :label="$t('Integrations.Active')"
      />
    </form-divider>
    <button-wrapper :is-submit-loading="isSubmitLoading" />
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import VSelect from "vue-select";
import InputField from "@/core/Components/FormElements/InputField.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import TheButton from "@/core/Components/TheButton.vue";
import IntegrationService from "../Services/IntegrationService";
import checkIfObjectEmpty, {
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import {
  maxLength,
  required,
  requiredIf,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import MultiJsonInput from "@/core/Components/FormElements/MultiJsonInput.vue";
import TagsInput from "@/core/Components/FormElements/TagsInput.vue";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    TagsInput,
    MultiJsonInput,
    VSelect,
    InputField,
    CheckboxInput,
    TheButton,
    FormDivider,
    ButtonWrapper,
  },
  props: ["integrationTypeId", "applicationId", "refreshData"],
  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      integrationService: new IntegrationService(),
      isSubmitLoading: false as boolean,
      topicToChannel: {} as Record<string, number>,
      toast: useToast(),
      connectionTypeOptions: [
        { id: "TCP", name: "TCP" },
        { id: "WebSocket", name: "WebSocket" },
      ],
      mqttProtocolVersionOptions: [
        { id: "V500", name: "V500" },
        { id: "V311", name: "V311" },
        { id: "V300", name: "V300" },
      ],
      qosOptions: [
        { id: "AtMostOnce", name: "At most once" },
        { id: "AtLeastOnce", name: "At least once" },
        { id: "ExactlyOnce", name: "Exactly once" },
      ],
      formData: {
        name: "",
        integrationTypeId: this.integrationTypeId,
        applicationId: this.applicationId,
        executionOrder: null as any,
        isActive: true,
        config: {
          topicToChannel: null,
          channels: [] as any,
          connectionType: "TCP",
          mqttProtocolVersion: "V311",
          endpoint: "",
          port: 0,
          hasCredentials: false,
          credentials: {} as { username: ""; password: "" } | null,
          isWithCleanSession: false,
          topic: "",
          qos: "AtMostOnce",
          isWithRetainFlag: false,
        },
      },
    };
  },

  watch: {
    applicationId(value) {
      this.formData.applicationId = value;
    },
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        executionOrder: {
          minValue: minValue(1),
        },
        config: {
          endpoint: {
            required,
          },
          port: {
            required: requiredIf(
              () => this.formData.config.connectionType === "TCP"
            ),
            minValue: minValue(0),
            maxValue: maxValue(65535),
          },
          credentials: {
            username: {
              required: requiredIf(() => this.formData.config.hasCredentials),
            },
            password: {
              required: requiredIf(() => this.formData.config.hasCredentials),
            },
          },
          topic: {
            required,
          },
        },
      },
    };
  },

  methods: {
    trimTopic() {
      this.formData.config.topic = this.formData.config.topic.replace(
        /\s/g,
        ""
      );
    },
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          this.formData.config.channels = this.formData.config.channels.map(
            (channel: string) => Number(channel.trim())
          );

          if (this.formData.config.channels.length === 0) {
            this.formData.config.channels = null;
          }

          if (
            this.formData.config.topicToChannel != null &&
            checkIfObjectEmpty(this.formData.config.topicToChannel)
          ) {
            this.formData.config.topicToChannel = null;
          }

          if (!this.formData.config.hasCredentials) {
            this.formData.config.credentials = null;
          }

          await this.integrationService.createIntegration(this.formData);
          this.toast.success(this.$t("General.CreatedSuccessfully"));
          await this.refreshData();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    handleTopicChange(value: Record<string, number>) {
      // @ts-ignore
      this.formData.config.topicToChannel = value;
    },
  },
});
</script>
<style scoped></style>
