export default {
  "Sidebar": {
    "Parkom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SmartCity"])},
    "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "ParkingSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spaces"])},
    "ParkingSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spots"])},
    "Sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detectors"])},
    "DigitalParkingSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital parking signs"])},
    "ParkingSessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking sessions"])},
    "BusControllers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus controllers"])},
    "WorkOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work orders"])},
    "Assignments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
    "AssignmentMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata"])},
    "Devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
    "DeviceMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device metadata"])},
    "DeviceTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device types"])},
    "DeviceTypeMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type metadata"])},
    "Groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "Integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
    "Organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization"])},
    "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin console"])},
    "Authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company authorization"])},
    "GlobalAuthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global authorization"])},
    "AccessManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access manager"])},
    "ProfileConfigurations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile configurations"])},
    "Resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources"])},
    "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "DeviceActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset actions"])},
    "DynamicDeviceActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic asset actions"])},
    "StaticDeviceActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Static asset actions"])},
    "FirmwareUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware management"])},
    "ResponseMessages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response messages"])},
    "Production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
    "SensorHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor health"])}
  },
  "SidebarMenu": {
    "ParkingSpacesSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spaces selection"])},
    "SelectParkingSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select parking spaces"])}
  },
  "Languages": {
    "English": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "Croatian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatian"])},
    "Slovakian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakian"])}
  },
  "Action": {
    "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "ManageSpots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage spots"])},
    "AddDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add devices"])},
    "ViewDevices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View devices"])},
    "Integrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
    "MakeActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make active"])},
    "RemoveUserFromOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove user from organization"])},
    "ViewDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])},
    "Markers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])}
  },
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "CreateBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create in bulk"])},
  "CreateZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create zone"])},
  "CancelEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel edit"])},
  "CreateFloorplan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create floorplan"])},
  "AddUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
  "Count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
  "NoChangesWereMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No changes were made!"])},
  "General": {
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "Upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created successfully!"])},
    "EditedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edited successfully!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted successfully!"])}
  },
  "Dashboard": {
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupied"])},
    "Unoccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unoccupied"])},
    "Offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
    "Enters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enters"])},
    "Exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exits"])},
    "Load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load"])},
    "OccupationByHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking pressure"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking space"])},
    "ParkingSpot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spot"])},
    "UnoccupiedSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unoccupied since"])},
    "OccupiedSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupied since"])}
  },
  "ParkingSpaces": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spaces"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "Street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "StreetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street number"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupied"])},
    "Unoccupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unoccupied"])},
    "Navigable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigable"])},
    "Visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create parking space"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit parking space"])},
    "ParkingSpaceLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select parking space location"])}
  },
  "ParkingSpots": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spots"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking space"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "MAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "OccupiedTimestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupied timestamp"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupied"])},
    "Index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "Public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create parking spot"])},
    "CreateBulkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create parking spots in bulk"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit parking spot"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking space"])},
    "ParkingSpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spot type"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
    "Visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible"])},
    "StartIndex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start index"])},
    "Quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "CreateBulkSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spots created successfully!"])}
  },
  "Displays": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Displays"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last sync"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "LastDisplayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last displayed"])},
    "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "Nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodes"])},
    "ManageNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage nodes"])},
    "ManageSources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage sources"])},
    "Latitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latitude"])},
    "Longitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longitude"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create display"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit display"])},
    "NodeDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node deleted successfully!"])},
    "SourceDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source deleted successfully!"])},
    "Timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
    "Time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
  },
  "ParkingSessions": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking sessions"])},
    "Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space"])},
    "Spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot"])},
    "SpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot type"])},
    "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])}
  },
  "BusControllers": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus controllers"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last sync"])},
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create bus controller"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit bus controller"])},
    "ParkingSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking space"])}
  },
  "Sensors": {
    "Space": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space"])},
    "SpaceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Space ID"])},
    "ParkingSpotType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking spot type"])},
    "Index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "Spot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot"])},
    "MAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "Occupied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupied"])},
    "LastSync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last sync"])},
    "FirmwareVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware version"])},
    "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
    "Packets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packets"])},
    "BatteryLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery level"])},
    "Voltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltage"])},
    "UnhandledReboots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhandled reboots"])},
    "RSSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSSI"])},
    "RSRQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRQ"])},
    "SearchingForNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for network"])},
    "Cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell"])},
    "Band": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Band"])},
    "ECL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECL"])},
    "RSRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRP"])},
    "SNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNR"])},
    "IMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMSI"])},
    "CCID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCID"])},
    "TotalSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sleep"])},
    "ModemDisableCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modem disable counter"])},
    "NoResponseCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No response counter"])},
    "Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign"])},
    "AssignSensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign sensor"])},
    "AssignTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Assign ", _interpolate(_named("mac")), " to parking spot"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create sensor"])},
    "AvgConsumptionPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg consumption/day"])},
    "AvgRssi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg RSSI"])},
    "MinRssi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min RSSI"])},
    "AvgPacketsPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg packets/day"])},
    "NotSequencePackets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not sequence packets"])},
    "MissedPackets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missed packets"])},
    "PacketsNotIncrement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packets not increment"])},
    "LastUnhandledReboot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last unhandled reboot"])},
    "RebootsGrows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboots grows"])},
    "RebootsNegative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboots negative"])},
    "RebootsChangingError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reboots changing"])},
    "AvgPowerOnCountPerDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg power on count/day"])},
    "EclTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECL 2"])},
    "SamePacketIdCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same packet ID counter"])},
    "Mag1RawXFlash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1RawXFlash"])},
    "Mag1RawYFlash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1RawYFlash"])},
    "Mag1RawZFlash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1RawZFlash"])},
    "RadarFlash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radar flash"])}
  },
  "SensorPackets": {
    "RTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RTC"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "DeviceState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device state"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "Packets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packets"])},
    "BatteryPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery percentage"])},
    "TotalConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total consumption (mAh)"])},
    "BatteryVoltage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battery voltage"])},
    "FirmwareVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware version"])},
    "RSSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSSI"])},
    "RSRQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRQ"])},
    "Cell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell"])},
    "ECL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECL"])},
    "Band": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Band"])},
    "SearchingForNetworkCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for network count"])},
    "RSRP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RSRP"])},
    "SNR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SNR"])},
    "MagX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag X"])},
    "MagY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag Y"])},
    "MagZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag Z"])},
    "Temperature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperature"])},
    "Mag1RawX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw X"])},
    "Mag1RawY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw Y"])},
    "Mag1RawZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag1 raw Z"])},
    "RadarWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radar weight"])},
    "RadarWeightKap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radar weight Kap"])},
    "RadarDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radar distance"])},
    "RadarDistanceKap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radar distance Kap"])},
    "MagDiff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mag diff (s)"])},
    "UnhandledRestartCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unhandled restart counter"])},
    "ModemRebootCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modem reboot count"])},
    "PowerOnCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power on count"])},
    "RuntimeTotalSeconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Runtime total seconds (s)"])},
    "RuntimeTotalSecondsHealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uptime/day"])},
    "IMEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "IMSI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMSI"])},
    "FwUpdateStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fw update start"])},
    "FwUpdateEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fw update end"])},
    "FwUpdateVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fw update version"])},
    "TotalSleep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sleep"])},
    "ModemDisableCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modem disable counter"])},
    "NoResponseCounter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No response counter"])},
    "StoredAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stored at"])},
    "TimeDifference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diff (s)"])},
    "BleTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ble tags"])}
  },
  "WorkOrders": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work orders"])},
    "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "Pieces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieces"])},
    "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
    "DeliveredOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivered on"])},
    "UserCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User created"])},
    "UserUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User updated"])},
    "AppliedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applied to"])},
    "IsApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is applied"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create work order"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit work order"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "AddToWorkOrderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to work order"])},
    "AddToWorkOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to work order successfully!"])},
    "RemoveFromWorkOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed from work order successfully!"])}
  },
  "Authorization": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company authorization"])},
    "ToUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To user"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "ResourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource type"])},
    "Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
    "CreatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created resource"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign role"])},
    "Asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit role"])},
    "CreateAuthorizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization created successfully!"])},
    "ResourceEditedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource edited successfully!"])}
  },
  "GlobalAuthorization": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global authorization"])},
    "ToUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To user"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "ResourceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource type"])},
    "Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource"])},
    "CreatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created resource"])},
    "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign role"])},
    "Asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit role"])},
    "CreateAuthorizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global authorization created successfully!"])},
    "ResourceEditedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource edited successfully!"])}
  },
  "Groups": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create group"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit group"])},
    "CreateGroupSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group created successfully!"])},
    "CompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization name"])}
  },
  "Integrations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrations"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "ExecutionOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execution order"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create integration"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit integration"])},
    "IntegrationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration type"])},
    "Endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endpoint"])},
    "IP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP address"])},
    "Port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port"])},
    "ConnectionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection type"])},
    "MqttProtocolVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MQTT protocol version"])},
    "HasCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has credentials"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "CleanSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clean session"])},
    "Topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Topic"])},
    "RetainFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retain flag"])},
    "Channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
    "Channels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channels"])},
    "CreateHttpSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTTP integration created successfully!"])},
    "CreateUdpSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UDP integration created successfully!"])},
    "CreateServiceInvocationCreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Invocation integration created successfully!"])},
    "CreateServiceInvocationEditedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Invocation integration edited successfully!"])},
    "DaprAppId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapr App Id"])},
    "Url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Url"])},
    "UsePayloadFromResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use payload from response"])},
    "QueryDeviceSpecificRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query device specific rules"])}
  },
  "Organizations": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizations"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create organization"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit organization"])},
    "ActiveCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active company"])},
    "CreateOrganizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization created successfully!"])},
    "EditOrganizationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization edited successfully!"])},
    "OrganizationChangedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company changed successfully!"])},
    "OrganizationDeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organization deleted successfully!"])}
  },
  "Users": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "AddUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user"])},
    "AddTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add user to organization"])},
    "AddUserSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added successfully!"])},
    "UserRemovedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User removed successfully!"])}
  },
  "VersionDropdown": {
    "Version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
    "LegalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal information"])},
    "TermsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])},
    "Imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "TermsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "DataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection"])}
  },
  "UserDropdown": {
    "EditProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "Delete": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "AreYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "RemoveDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove device"])},
    "Remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove"])}
  },
  "Zone": {
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create zone"])},
    "Floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
    "ZoneCreatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone created successfully!"])}
  },
  "DeviceActions": {
    "Device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "RequestTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request timeout"])},
    "RequestBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request body"])},
    "ResponseBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response body"])},
    "ClientId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "StoreCommit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store commit"])},
    "DeviceActionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset action type"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create asset action"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit asset action"])}
  },
  "FirmwareUpdate": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware update"])}
  },
  "ResponseMessages": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response messages"])},
    "SendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "IsSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is sent"])},
    "IsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is active"])},
    "CreatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
    "UpdatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
    "MessageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent successfully!"])}
  },
  "Devices": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Imei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMEI"])},
    "Mac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAC"])},
    "Imsi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMSI"])},
    "ExternalId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External ID"])},
    "SerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number"])},
    "DeviceTypeId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type"])},
    "CompanyId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "ParentDeviceId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent device"])},
    "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create device"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit device"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device created successfully!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device deleted successfully!"])},
    "UpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device updated successfully!"])}
  },
  "DeviceMetadata": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device metadata"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "MetadataTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata types"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create device metadata"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit device metadata"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device metadata created successfully!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device metadata deleted successfully!"])},
    "UpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device metadata updated successfully!"])}
  },
  "DeviceTypes": {
    "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "ProductionPN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production PN"])},
    "CustomerPN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer PN"])},
    "SerialNumberType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serial number type"])},
    "ProductionEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production enabled"])},
    "DeviceTypeMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type metadata"])},
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device types"])},
    "Metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create device type"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit device type"])},
    "ParentDeviceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent device type"])},
    "CreateRelationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create device type relation"])},
    "CreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type created successfully!"])},
    "DeleteRelationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete device type metadata relation"])},
    "DeviceTypeMetadataRelationCreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type metadata relation created successfully!"])},
    "EditSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type edited successfully!"])},
    "DeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type deleted successfully!"])},
    "RelationDeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type metadata relation deleted successfully!"])}
  },
  "DeviceTypeMetadata": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type metadata"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "Data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "MetadataTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata types"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create device type metadata"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit device type metadata"])},
    "CreatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type metadata created successfully!"])},
    "DeletedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type metadata deleted successfully!"])},
    "UpdatedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Device type metadata updated successfully!"])}
  },
  "AssignmentMetadata": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata"])},
    "CreateTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create asset metadata"])},
    "EditTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit asset metadata"])},
    "AssignmentMetadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata"])},
    "DeleteRelationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete asset metadata relation"])},
    "CreateRelationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create asset metadata relation"])},
    "CreateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata created successfully!"])},
    "DeleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata deleted successfully!"])}
  }
}