import ApiService from "@/core/Axios/ApiService";
import { DeviceTypeConfiguration } from "@/modules/MapView/Services/Types/DeviceTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class DeviceTypeService {
  async getDeviceTypesConfiguration(
    pageNumber = 1,
    pageSize = 10,
    metaDataTypeId = 1
  ) {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/device-type-metadata?MetadataTypeId=${metaDataTypeId}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    });

    const response = await ApiService.get(url);

    return this._mapDeviceTypeConfigurations(response.data.items);
  }

  private _mapDeviceTypeConfigurations(response: DeviceTypeConfiguration[]) {
    return response.map((deviceTypeConfiguration) =>
      this._mapDeviceTypeConfiguration(deviceTypeConfiguration)
    );
  }

  private _mapDeviceTypeConfiguration(
    deviceTypeConfiguration: DeviceTypeConfiguration
  ) {
    return {
      id: deviceTypeConfiguration.id,
      name: deviceTypeConfiguration.name,
      metadataType: deviceTypeConfiguration.metadataType,
      data: deviceTypeConfiguration.data,
    };
  }
}
