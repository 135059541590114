<template>
  <main-section title="Access manager/Events">
    <loader
      v-if="isLoading"
      inner-ring-thickness="border-t-8"
      outer-ring-thickness="border-8"
    />
    <div v-else class="space-y-4">
      <div class="mb-4 w-full">
        <div class="flex h-[42px] items-center space-x-4">
          <dx-date-box
            v-model="from"
            :height="42"
            :show-clear-button="true"
            display-format="dd.MM.yyyy HH:mm"
            type="datetime"
            :clearable="false"
          />
          <dx-date-box
            v-model="to"
            :height="42"
            :show-clear-button="true"
            display-format="dd.MM.yyyy HH:mm"
            type="datetime"
            :clearable="false"
          />
          <v-select
            v-model="selectedResourceUuid"
            :options="resources"
            :reduce="(resource : Resource)=>resource.dataUuid"
            class="w-72"
            label="name"
            :clearable="false"
          />
        </div>
      </div>
      <custom-table
        :is-action-list-not-displayed="true"
        :is-data-source-loading="isDataSourceLoading"
        :table-data="events"
        :table-headers="tableHeaders"
        @force-refresh="getEvents"
      />
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TheButton from "@/core/Components/TheButton.vue";
import Loader from "@/core/Components/Loader.vue";
import DxDateBox from "devextreme-vue/date-box";
import moment from "moment";
import VSelect from "vue-select";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import ResourceService, {
  Resource,
} from "@/modules/AccessManager/SubModules/Resources/Services/ResourceService";
import { useUserStore } from "@/core/Store/userStore";
import { useToast } from "vue-toastification";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import DeviceDataService, {
  DeviceAssignmentData,
} from "@/modules/Devices/Services/DeviceDataService";

export default defineComponent({
  components: {
    MainSection,
    CustomTable,
    TheButton,
    Loader,
    DxDateBox,
    VSelect,
  },
  data() {
    return {
      isLoading: false,
      toast: useToast(),
      resourceService: new ResourceService(),
      isDataSourceLoading: false,
      deviceDataService: new DeviceDataService(),
      userStore: useUserStore(),
      changeCompanyStore: useChangeCompanyStore(),
      resources: [] as Resource[],
      selectedResourceUuid: "",
      tableHeaders: {
        dateTime: { header: "Datetime", visible: true },
        text: { header: "Text", visible: true },
      },
      to: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      from: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      events: [] as DeviceAssignmentData[],
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getEvents();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async selectedResourceUuid(newUuid) {
      this.isDataSourceLoading = true;
      try {
        if (newUuid != "") {
          await this.getDataSentByDevice(newUuid);
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async from(newFrom) {
      this.from = moment(newFrom).toISOString();
      await this.getDataSentByDevice();
    },
    async to(newTo) {
      this.to = moment(newTo).toISOString();
      await this.getDataSentByDevice();
    },
  },
  methods: {
    changeRoute() {},

    async getEvents() {
      this.isDataSourceLoading = true;
      try {
        this.resources = await this.resourceService.getAllResources();
        if (this.resources[0].dataUuid) {
          this.selectedResourceUuid = this.resources[0].dataUuid;
        }

        if (this.selectedResourceUuid !== "") {
          await this.getDataSentByDevice(this.selectedResourceUuid);
          return;
        }
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },

    async getDataSentByDevice(selectedUuid?: string) {
      this.isDataSourceLoading = true;
      try {
        this.events = await this.deviceDataService.getDeviceAssignmentData({
          dataUuid: !selectedUuid ? this.selectedResourceUuid : selectedUuid,
          from: this.from,
          to: this.to,
          channel: 2,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
  },
});
</script>
<style scoped></style>
