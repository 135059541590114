import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import {
  CreateWorkOrder,
  WorkOrder,
  WorkOrderDevice,
} from "@/modules/WorkOrders/Services/WorkOrderTypes";
import ApiService from "@/core/Axios/ApiService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class WorkOrderService {
  async getAllWorkOrders({
    pageSize,
    pageNumber,
    companyId,
  }: {
    pageSize: number;
    pageNumber: number;
    companyId: number;
  }): Promise<WorkOrder[]> {
    const url = GenerateUrl({
      path: "/api/work-orders",
      prefixPath: ConfigurationService.configData.WORK_ORDER_PREFIX_PATH,
      config: {
        PageSize: pageSize,
        PageNumber: pageNumber,
        CompanyId: companyId,
      },
    });

    const response = await ApiService.get(url);
    return this._mapWorkOrders(response.data.items);
  }

  async createWorkOrder(workOrder: CreateWorkOrder): Promise<number> {
    const url = GenerateUrl({
      path: "/api/work-orders",
      prefixPath: ConfigurationService.configData.WORK_ORDER_PREFIX_PATH,
    });
    const response = await ApiService.post(url, workOrder);
    return response.data;
  }

  async addDevicesToWorkOrder({
    workOrderId,
    deviceIds,
  }: {
    workOrderId: number;
    deviceIds: number[];
  }): Promise<any> {
    const url = GenerateUrl({
      path: "/api/work-order-devices",
      prefixPath: ConfigurationService.configData.WORK_ORDER_PREFIX_PATH,
    });

    return await ApiService.post(url, { workOrderId, deviceIds });
  }

  async addDeviceToWorkOrderWithIdentifier({
    workOrderId,
    identifiers,
    identifierType,
  }: {
    workOrderId: number;
    identifiers: (string | number)[];
    identifierType: "Mac" | "Imei";
  }): Promise<any> {
    const url = GenerateUrl({
      path: "/api/work-order-devices/identifiers",
      prefixPath: ConfigurationService.configData.WORK_ORDER_PREFIX_PATH,
    });

    return await ApiService.post(url, {
      workOrderId,
      identifiers,
      identifierType,
    });
  }

  async getWorkOrderDevices({
    workOrderId,
    pageNumber,
    pageSize,
  }: {
    workOrderId: number;
    pageNumber: number;
    pageSize: number;
  }): Promise<WorkOrderDevice[]> {
    const url = GenerateUrl({
      path: `/api/work-order-devices`,
      prefixPath: ConfigurationService.configData.WORK_ORDER_PREFIX_PATH,
      config: {
        WorkOrderId: workOrderId,
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });
    const response = await ApiService.get(url);
    return this._mapWorkOrderDevices(response.data.items);
  }

  async updateWorkOrder({
    workOrderEdit,
    id,
  }: {
    workOrderEdit: PatchObject[];
    id: number;
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/work-orders/${id}`,
      prefixPath: ConfigurationService.configData.WORK_ORDER_PREFIX_PATH,
    });

    return await ApiService.patch(url, workOrderEdit);
  }

  async applyWorkOrderToCompany(id: number) {
    const url = GenerateUrl({
      path: `/api/work-orders/${id}/apply`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.post(url, {});
  }

  async deleteWorkOrderDevice(deviceId: number) {
    const url = GenerateUrl({
      path: `/api/work-order-devices/${deviceId}`,
      prefixPath: ConfigurationService.configData.WORK_ORDER_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  async deleteWorkOrder(id: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/work-orders/${id}`,
      prefixPath: ConfigurationService.configData.WORK_ORDER_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  private _mapWorkOrders(response: WorkOrder[]): WorkOrder[] {
    return response.map((workOrder) => this._mapWorkOrder(workOrder));
  }

  private _mapWorkOrder(response: WorkOrder): WorkOrder {
    return {
      id: response.id,
      companyId: response.companyId,
      company: response.company,
      comment: response.comment,
      currentCount: response.currentCount,
      piecesFormatted: `${response.currentCount} / ${response.pieces}`,
      pieces: response.pieces,
      isDelivered: response.isDelivered,
      isApplied: response.isApplied,
      deliveredOn: response.deliveredOn,
      userCreatedId: response.userCreatedId,
      userCreated: response.userCreated,
      userUpdatedId: response.userUpdatedId,
      userUpdated: response.userUpdated,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
      appliedToUserId: response.appliedToUserId,
      appliedToUser: response.appliedToUser,
    };
  }

  private _mapWorkOrderDevices(response: WorkOrderDevice[]): WorkOrderDevice[] {
    return response.map((workOrderDevice) =>
      this._mapWorkOrderDevice(workOrderDevice)
    );
  }

  private _mapWorkOrderDevice(response: WorkOrderDevice): WorkOrderDevice {
    return {
      createdAt: response.createdAt,
      deviceId: response.deviceId,
      device: response.device,
      id: response.id,
      updatedAt: response.updatedAt,
      workOrderId: response.workOrderId,
    };
  }
}
