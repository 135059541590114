<template>
  <div class="w-full h-full flex items-center justify-center flex-col space-y-4">
    <div class="bg-primarylight w-96 h-96 rounded-full flex items-center justify-center">
      <img width="250" src="@/assets/empty_state.svg" alt="Empty state image" />
    </div>
    <h3 class="text-md font-medium">No added {{ pageName }} yet!</h3>
    <h4 class="text-darkgrey">This section will contain the list of {{ pageName }}.</h4>
    <div><slot></slot></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    pageName: String,
  },
});
</script>
<style scoped></style>
