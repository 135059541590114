<template>
  <div class="w-full py-3">
    <label
      class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
      >{{ label }}</label
    >
    <input
      :value="modelValue"
      class="mt-1 w-full"
      type="color"
      @change="
        $emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: String,
    modelValue: String,
  },
  emits: ["update:modelValue"],
});
</script>

<style scoped></style>
