import { defineStore } from "pinia";
import UserService from "@/modules/Users/Services/UserService";
import { Languages } from "@/core/Services/GlobalTypes";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      userService: new UserService(),
      userId: 0,
      activeCompanyId: 0,
      userEmail: "",
      languageCode: "en",
    };
  },

  actions: {
    async getUserId(email: string, username: string) {
      try {
        const response = await this.userService.authenticateUser(
          email,
          username
        );
        this.userId = response.id;
        this.userEmail = response.email;
        if (response.activeCompanyId) {
          this.activeCompanyId = response.activeCompanyId;
        }
        this.languageCode = response.languageCode;
      } catch (error) {
        return error;
      }
    },

    async setUserLanguage(languageCode: Languages) {
      try {
        this.languageCode = languageCode;
        await this.userService.changeUserLanguage({
          userId: this.userId,
          languageCode,
        });
      } catch (error) {
        return error;
      }
    },
  },
  persist: true,
});
