<template>
  <div v-if="isLoading" class="w-full h-[200px] flex items-center justify-center">
    <small-loader />
  </div>
  <div v-else :class="`scroll shadow ${widerForm ? 'w-7/12' : 'w-6/12'} ${isFormInModal && '!w-full !max-h-fit !shadow-none'}  bg-white rounded-lg`">
    <div v-if="title" class="flex items-center justify-between mb-4 w-full border-b pb-1 px-6 border-lightgray">
      <h2 class="font-bold text-2xl text-dark">{{ title }}</h2>
    </div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SmallLoader from "../SmallLoader.vue";
export default defineComponent({
  props: {
    title: String,
    needForScroll: Boolean,
    widerForm: Boolean,
    isFormInModal: Boolean,
    isLoading: Boolean,
  },
  components: { SmallLoader },
});
</script>
<style scoped>
.shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  @apply bg-primary;
}
</style>
