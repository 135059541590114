import { RouteRecordRaw } from "vue-router";
import Dashboard from "@/modules/Parkom/Pages/Dashboard.vue";
import ParkingSpacesIndex from "@/modules/Parkom/Submodules/ParkingSpaces/Pages/Index.vue";
import ParkingSpotsIndex from "@/modules/Parkom/Submodules/ParkingSpots/Pages/Index.vue";
import SensorsIndex from "@/modules/Parkom/Submodules/Sensors/Pages/Index.vue";
import DisplaysIndex from "@/modules/Parkom/Submodules/Displays/Pages/Index.vue";
import ParkingSessionsIndex from "@/modules/Parkom/Submodules/ParkingSessions/Pages/Index.vue";
import Index from "@/modules/Parkom/Pages/Index.vue";
import BusControllersIndex from "@/modules/Parkom/Submodules/BusControllers/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";
import HealthIndex from "@/modules/Parkom/Submodules/Health/Pages/Index.vue";

export function createParkomIndexRoute(): RouteRecordRaw {
  return {
    name: "parkom",
    path: "/",
    component: Index,
    redirect: {
      name: "parkomDashboard",
    },
    meta: {
      id: "parkomIndex",
      label: "Sidebar.Parkom",
      icon: ["fas", "square-parking"],
      group: "main",
      showInNavigation: true,
      authorize: [Permissions.ViewAssignment],
    },
    children: [
      {
        name: "parkomDashboard",
        path: "/parkom/dashboard",
        component: Dashboard,
        meta: {
          id: "parkomDashboard",
          label: "Sidebar.Dashboard",
          icon: ["fas", "table-columns"],
          showInNavigation: true,
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "parkomParkingSpaceIndex",
        path: "/parkom/parking-spaces",
        component: ParkingSpacesIndex,
        meta: {
          id: "parkomParkingSpaceIndex",
          label: "Sidebar.ParkingSpaces",
          icon: ["fas", "parking"],
          showInNavigation: true,
          dontDisplayOnMainNavigation: true,
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "parkomParkingSpotIndex",
        path: "/parkom/parking-spots",
        component: ParkingSpotsIndex,
        meta: {
          id: "parkomParkingSpotIndex",
          label: "Sidebar.ParkingSpots",
          icon: ["fas", "location-dot"],
          showInNavigation: true,
          dontDisplayOnMainNavigation: true,
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "parkomSensorsIndex",
        path: "/parkom/sensors",
        component: SensorsIndex,
        meta: {
          id: "parkomSensorsIndex",
          label: "Sidebar.Sensors",
          icon: ["fac", "parkom-sensor"],
          showInNavigation: true,
          dontDisplayOnMainNavigation: true,
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "parkomDisplaysIndex",
        path: "/parkom/displays",
        component: DisplaysIndex,
        meta: {
          id: "parkomDisplaysIndex",
          label: "Sidebar.DigitalParkingSigns",
          icon: ["fac", "parkom-display"],
          showInNavigation: true,
          dontDisplayOnMainNavigation: true,
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "parkomParkingSessionsIndex",
        path: "/parkom/parking-sessions",
        component: ParkingSessionsIndex,
        meta: {
          id: "parkomParkingSessionsIndex",
          label: "Sidebar.ParkingSessions",
          icon: ["fas", "clock"],
          showInNavigation: true,
          dontDisplayOnMainNavigation: true,
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "parkomBusControllersIndex",
        path: "/parkom/bus-controllers",
        component: BusControllersIndex,
        meta: {
          id: "parkomBusControllersIndex",
          label: "Sidebar.BusControllers",
          icon: ["fas", "satellite-dish"],
          showInNavigation: true,
          dontDisplayOnMainNavigation: true,
          authorize: [Permissions.ViewAssignment],
        },
      },
      {
        name: "parkomHealthIndex",
        path: "/parkom/health",
        component: HealthIndex,
        meta: {
          id: "parkomHealthIndex",
          label: "Sidebar.SensorHealth",
          icon: ["fac", "parkom-sensor"],
          showInNavigation: true,
          dontDisplayOnMainNavigation: true,
          authorize: [Permissions.SensorHealthView],
        },
      },
    ],
  };
}
