import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-6" }
const _hoisted_2 = { class: "flex w-full flex-col items-center space-y-4" }
const _hoisted_3 = { class: "flex h-24 w-24 items-center justify-center rounded-full bg-errorlight" }
const _hoisted_4 = { class: "text-lg font-medium" }
const _hoisted_5 = { class: "py-4 text-center" }
const _hoisted_6 = {
  key: 0,
  class: "mt-4 flex justify-end space-x-3"
}
const _hoisted_7 = {
  key: 1,
  class: "mt-4 grid w-full grid-cols-2 gap-10"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isDeleting,
      "remove-outside-click": true,
      title: `${
        _ctx.deviceRemove ? _ctx.$t('Delete.RemoveDevice') : _ctx.$t('Delete.Title')
      }`,
      "have-modal": _ctx.haveModal,
      onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('toggle-modal')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_font_awesome_icon, {
                icon: ['fas', 'triangle-exclamation'],
                class: "h-10 w-10 text-error"
              })
            ]),
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("Delete.AreYouSure")) + " " + _toDisplayString(_ctx.deviceRemove
                ? _ctx.$t("Delete.Remove")
                : _ctx.isUserRemove
                ? _ctx.$t("Delete.Remove")
                : _ctx.$t("Delete.Delete")) + "? ", 1)
          ]),
          _createElementVNode("h2", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.deleteText), 1),
            _createElementVNode("b", null, _toDisplayString(_ctx.selectedData && _ctx.selectedData?.assignment?.name
              ? _ctx.selectedData?.assignment.name
              : _ctx.selectedData?.email), 1)
          ]),
          (_ctx.deviceRemove)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_the_button, {
                  text: _ctx.$t('General.Cancel'),
                  buttonType: "oulineButton",
                  variant: "outline",
                  onExecuteMethod: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel-delete')))
                }, null, 8, ["text"]),
                _createVNode(_component_the_button, {
                  "is-loading": _ctx.isSubmitLoading,
                  text: _ctx.$t('General.Remove'),
                  variant: "delete",
                  onExecuteMethod: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm-remove')))
                }, null, 8, ["is-loading", "text"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_the_button, {
                  text: _ctx.$t('General.Cancel'),
                  variant: "outline",
                  onExecuteMethod: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('cancel-delete')))
                }, null, 8, ["text"]),
                (!_ctx.deviceRemove)
                  ? (_openBlock(), _createBlock(_component_the_button, {
                      key: 0,
                      "is-loading": _ctx.isSubmitLoading,
                      text: _ctx.$t('General.Delete'),
                      variant: "delete",
                      onExecuteMethod: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('confirm-delete')))
                    }, null, 8, ["is-loading", "text"]))
                  : _createCommentVNode("", true)
              ]))
        ])
      ]),
      _: 1
    }, 8, ["is-displayed", "title", "have-modal"])
  ]))
}