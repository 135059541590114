import CompanyService, {
  Company,
  CompanyObject,
} from "@/modules/Companies/Services/CompanyService";
import { defineStore } from "pinia";

export const useSidebarStore = defineStore("sidebar", {
  state: () => {
    return {
      isSidebarOpened: true as boolean,
      currentCompany: {} as Company,
      companyService: new CompanyService(),
      /* companies: [] as Company[], */
      selectedCompany: 0 as number,
    };
  },
  actions: {
    toggleSidebar() {
      this.isSidebarOpened = !this.isSidebarOpened;
    },

    /* async getCurrentCompany() {
      const companyObject: CompanyObject =
        await this.companyService.getAllCompanies(1, 100);
      this.companies = companyObject.items;
    }, */
  },
});
