import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_last_data_preview = _resolveComponent("last-data-preview")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_create_relation_modal = _resolveComponent("create-relation-modal")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_delete_relation_modal = _resolveComponent("delete-relation-modal")!
  const _component_loader = _resolveComponent("loader")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDataPreviewModalOpened)
      ? (_openBlock(), _createBlock(_component_last_data_preview, {
          key: 0,
          data: _ctx.selectedMetadata,
          "is-last-data-modal-opened": _ctx.isDataPreviewModalOpened,
          name: _ctx.selectedDeviceTypes[0]?.name,
          preview: true,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDataPreviewModalOpened = false))
        }, null, 8, ["data", "is-last-data-modal-opened", "name"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 1,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "refresh-data": _ctx.getDeviceTypes,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isCreateModalOpened = false))
        }, null, 8, ["is-create-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 2,
          "device-type": _ctx.selectedDeviceTypes[0],
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "refresh-data": _ctx.getDeviceTypes,
          onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEditModalOpened = false))
        }, null, 8, ["device-type", "is-edit-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateRelationModalOpened)
      ? (_openBlock(), _createBlock(_component_create_relation_modal, {
          key: 3,
          "device-type-id-list": _ctx.deviceTypeIdList,
          "is-create-relation-modal-opened": _ctx.isCreateRelationModalOpened,
          "refresh-data": _ctx.getDeviceTypes,
          onToggleModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isCreateRelationModalOpened = false))
        }, null, 8, ["device-type-id-list", "is-create-relation-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isDeleteModalOpened)
      ? (_openBlock(), _createBlock(_component_delete_modal, {
          key: 4,
          "is-deleting": _ctx.isDeleteModalOpened,
          onCancelDelete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
          onToggleModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
          onConfirmDelete: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openConfirmationModal('delete')))
        }, null, 8, ["is-deleting"]))
      : _createCommentVNode("", true),
    _createVNode(_component_confirmation_modal, {
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "delete-text": "delete",
      onConfirmAction: _ctx.deleteDeviceType,
      onCloseConfirmationModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
    }, null, 8, ["is-confirmation-modal-opened", "onConfirmAction"]),
    (_ctx.isDeleteRelationModalOpened)
      ? (_openBlock(), _createBlock(_component_delete_relation_modal, {
          key: 5,
          "device-type-id": _ctx.selectedDeviceTypes[0].id,
          "is-delete-relation-modal-opened": _ctx.isDeleteRelationModalOpened,
          metadata: _ctx.selectedDeviceTypes[0].metadata,
          "refresh-data": _ctx.getDeviceTypes,
          onToggleModal: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isDeleteRelationModalOpened = false))
        }, null, 8, ["device-type-id", "is-delete-relation-modal-opened", "metadata", "refresh-data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateDeviceType,
                title: _ctx.$t('DeviceTypes.Title'),
                description: " ",
                "button-text": "Create",
                onExecuteMethod: _ctx.toggleCreateModal
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_the_button, {
                    text: "Apply metadata",
                    isApplyButtonEnabled: "",
                    "is-disabled": 
            !_ctx.isApplyButtonEnabled ||
            !_ctx.permissionStore.hasPermission(_ctx.permissions.EditDeviceType)
          ,
                    variant: 
            _ctx.isApplyButtonEnabled ||
            !_ctx.permissionStore.hasPermission(_ctx.permissions.EditDeviceType)
              ? 'default'
              : 'ghost'
          ,
                    onExecuteMethod: _ctx.toggleCreateRelationModal
                  }, null, 8, ["is-disabled", "variant", "onExecuteMethod"])
                ]),
                _: 1
              }, 8, ["permission-id", "title", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                multiselect: true,
                options: _ctx.options,
                "table-data": _ctx.deviceTypes,
                "table-headers": _ctx.tableHeaders,
                onForceRefresh: _cache[10] || (_cache[10] = ($event: any) => (_ctx.getDeviceTypes())),
                onSelectedRows: _ctx.getSelectedRows,
                onOpenDataModal: _ctx.openDataPreviewModal
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onOpenDataModal"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}