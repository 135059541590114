import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "items-center py-3 relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-sm not-italic font-medium text-gray-800 whitespace-nowrap"
}
const _hoisted_3 = { class: "w-full flex justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_loader = _resolveComponent("small-loader")!
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.labelText)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.labelText), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_small_loader, {
            key: 0,
            height: "h-8",
            width: "w-8"
          }))
        : (_openBlock(), _createBlock(_component_v_select, {
            key: 1,
            label: _ctx.label,
            options: _ctx.options,
            reduce: (value) => value[_ctx.reduceKey],
            value: _ctx.modelValue,
            class: "w-full",
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event[_ctx.reduceKey]))),
            "onOption:selecting": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event[_ctx.reduceKey])))
          }, null, 8, ["label", "options", "reduce", "value"]))
    ])
  ]))
}