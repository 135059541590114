<template>
  <file-upload
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    @refresh-data="getStoredFiles()"
    @toggle-modal="isCreateModalOpened = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else>
      <table-action-header
        :button-text="$t('General.Upload')"
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateFirmwareUpdate"
        :title="$t('FirmwareUpdate.Title')"
        description=" "
        @execute-method="toggleCreateModal"
      />
      <custom-table
        :options="options"
        :is-data-source-loading="isDataSourceLoading"
        :table-data="paginatedStoredFiles.items"
        :table-headers="tableHeaders"
        @selected-rows="getSelectedRow"
        @force-refresh="getStoredFiles()"
      />
      <pagination
        :pagination-data="paginatedStoredFiles"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Loader from "@/core/Components/Loader.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import {
  StoredFile,
  StoredFilesObject,
} from "@/modules/Production/Submodules/FirmwareUpdate/Services/FirmwareUpdateTypes";
import FirmwareUpdateService from "@/modules/Production/Submodules/FirmwareUpdate/Services/FirmwareUpdateService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import FileUpload from "@/modules/Production/Submodules/FirmwareUpdate/Components/FileUpload.vue";
import { Permissions } from "@/core/Services/Enums";
import Pagination from "@/core/Components/Pagination.vue";
import { PaginationSettings } from "@/core/Services/PaginationSettings";

export default defineComponent({
  components: {
    TableActionHeader,
    CustomTable,
    Loader,
    MainSection,
    FileUpload,
    Pagination,
  },
  data() {
    return {
      firmwareUpdateService: new FirmwareUpdateService(),
      currentPage: PaginationSettings.DEFAULT_CURRENT_PAGE,
      currentPageSize: PaginationSettings.DEFAULT_CURRENT_PAGE_SIZE,
      paginatedStoredFiles: {} as StoredFilesObject,
      selectedFile: {} as StoredFile,
      toast: useToast(),
      permissions: Permissions,
      isLoading: false,
      isCreateModalOpened: false,
      isDataSourceLoading: false,
      tableHeaders: {
        id: { header: "ID", visible: false },
        fileName: { header: "File Name", visible: true },
        contentType: { header: "Content Type", visible: true },
        name: { header: "Name", visible: false },
        createdAt: { header: "Created At", visible: true },
        updatedAt: { header: "Updated At", visible: true },
      },
      options: {
        downloadFile: {
          header: "Download file",
          icon: ["fas", "circle-down"],
          action: this.downloadFile,
        },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getStoredFiles();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    handlePageChange(newPage: number) {
      this.currentPage = newPage;
      this.getStoredFiles();
    },
    handlePageSizeChange(newPageSize: number) {
      this.currentPageSize = newPageSize;
      this.currentPage = 1;
      this.getStoredFiles();
    },
    async getStoredFiles() {
      this.isDataSourceLoading = true;
      try {
        this.paginatedStoredFiles =
          await this.firmwareUpdateService.getFirmwareUpdateList(
            this.currentPage,
            this.currentPageSize
          );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async downloadFile() {
      try {
        await this.firmwareUpdateService.downloadFirmwareFile(
          this.selectedFile.fileName
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    getSelectedRow(selectedRows: StoredFile[]) {
      this.selectedFile = selectedRows[0];
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
  },
});
</script>

<style scoped></style>
