<template>
  <div
    v-for="(chart, index) in devices[0].data[0].payload.records"
    :key="index"
  >
    <div
      v-if="
        (chart.dataChart[0].datasets[0].data[0] ||
          chart.dataChart[0].datasets[0].data.length > 1) &&
        chart.id !== 54 &&
        chart.id !== 55
      "
    >
      <p
        class="flex items-center justify-center align-middle font-bold"
        v-if="chart.labelName && chart.labelName.en"
      >
        {{ chart.labelName.en }}
      </p>
      <p class="flex items-center justify-center align-middle font-bold" v-else>
        Name not found
      </p>
      <div class="relative">
        <div class="absolute right-0 top-0 mb-8">
          <TheButton
            :text="'Zoom in'"
            @click="() => zoomIn(chart, chart.id)"
            class="!h-fit"
            :size="'sm'"
            :variant="'ghost'"
          />
          <TheButton
            :text="'Zoom out'"
            @click="() => zoomOut(chart, chart.id)"
            class="!h-fit"
            :size="'sm'"
            :variant="'ghost'"
          />
        </div>
        <div class="max-h-[60rem] min-h-[15rem] w-full">
          <Line
            :id="chart.id"
            :data="chart.dataChart[0]"
            :options="chart.chartOptions"
          />
        </div>
      </div>
    </div>
  </div>

  <div v-for="graph in (sensorDataForGraph as any)">
    <div v-if="graph">
      <p class="flex items-center justify-center align-middle font-bold">
        Tempteratures
      </p>
      <div class="relative">
        <div class="absolute right-0 top-0 mb-8">
          <TheButton
            :text="'Zoom in'"
            @click="() => zoomIn(graph, graph.typeOfSensor)"
            class="!h-fit"
            :size="'sm'"
            :variant="'ghost'"
          />
          <TheButton
            :text="'Zoom out'"
            @click="() => zoomOut(graph, graph.typeOfSensor)"
            class="!h-fit"
            :size="'sm'"
            :variant="'ghost'"
          />
        </div>
        <div class="max-h-[60rem] min-h-[15rem] w-full">
          <Line
            :id="graph.typeOfSensor"
            :data="graph"
            :options="optionsForOldDevice"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { LastDeviceAssignmentData } from "@/modules/Devices/Services/DeviceDataService";
import { defineComponent } from "vue";
import GraphService from "@/modules/MapView/Services/GraphService";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartZoom from "chartjs-plugin-zoom";
import TheButton from "@/core/Components/TheButton.vue";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartZoom,
  zoomPlugin
);

export default defineComponent({
  components: { Line, TheButton },
  props: ["deviceData", "rangeData", "deviceTypes"],
  data() {
    return {
      devices: [] as LastDeviceAssignmentData[],
      sensorDataForGraph: {},
      graphservice: new GraphService(),
      counter: 0,
      oldSensor: false,
      firstTemperature: {} as any,
      optionsForOldDevice: {
        interaction: {
          intersect: false,
          mode: "index",
        } as any,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        scales: {
          x: {
            display: false,
            ticks: {
              display: false,
            },
          },
          y: {
            ticks: {
              callback: function (value: any) {
                return parseFloat(value.toFixed(2)) + " °C";
              },
            },
          },
        },

        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: any) {
                var label = context.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label += context.parsed.y + " °C";
                }
                return label;
              },
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
                modifierKey: "ctrl",
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
            },
            pan: {
              enabled: true,
              mode: "x",
            },
          },
        },
      },
    };
  },
  mounted() {},
  created() {
    this.devices = JSON.parse(JSON.stringify(this.deviceData));
    console.log(
      "🚀 ~ created ~ this.devices[0].data[0].payload.records:",
      this.devices[0].data[0].payload.records
    );

    for (const key in this.devices[0].data[0].payload) {
      if (key.startsWith("sensor")) {
        this.oldSensor = true;
        break;
      }
    }
    //OLD SENSOR
    if (this.oldSensor) {
      let sensorKeys = Object.keys(this.devices[0].data[0].payload);
      let sensors = sensorKeys.filter((key) => key.startsWith("sensor"));
      let sensorData = sensors.map((sensor) => ({
        labels: [],
        datasets: [
          {
            label: sensor,
            backgroundColor: "#007cc1",
            borderColor: "#007cc1",
            data: [],
            pointRadius: 0,
            fill: false,
          },
        ],
      }));
      sensorData.forEach((element: any) => {
        this.rangeData.forEach((range: any) => {
          const sensorLabel = element.datasets[0].label;
          if (range.payload.hasOwnProperty(sensorLabel)) {
            if (range.payload) {
              if (range.payload[sensorLabel]) {
                element.datasets[0].data.unshift(range.payload[sensorLabel]);
                element.labels.unshift(
                  moment(range.dateTime).format("DD.MM.YYYY HH:mm")
                );
              }
            }
          }
        });
      });
      if (sensorData[1].datasets[0]) {
        sensorData[0].datasets.push(sensorData[1].datasets[0]);
        sensorData[1].datasets[0].backgroundColor = "#ffb03b";
        sensorData[1].datasets[0].borderColor = "#ffb03b";
        sensorData[1].datasets[0].label = "T2";
        delete sensorData[1];
      }
      if (sensorData[2].datasets[0]) {
        sensorData[0].datasets.push(sensorData[2].datasets[0]);
        sensorData[2].datasets[0].backgroundColor = "#644311";
        sensorData[2].datasets[0].borderColor = "#644311";
        sensorData[2].datasets[0].label = "T3";
        delete sensorData[2];
      }
      if (sensorData[0].datasets[0]) {
        sensorData[0].datasets[0].backgroundColor = "#125a18";
        sensorData[0].datasets[0].borderColor = "#125a18";
        sensorData[0].datasets[0].label = "T1";
      }
      this.sensorDataForGraph = sensorData;
      //@ts-ignore
      this.sensorDataForGraph[0]["typeOfSensor"] = "old";
    }

    for (let key in this.devices[0].data[0].payload.records) {
      if (this.devices[0].data[0].payload.records.hasOwnProperty(key)) {
        if (this.devices[0].data[0].payload.records[key].hexColor) {
          this.devices[0].data[0].payload.records[key]["dataChart"] = [
            {
              labels: [],
              datasets: [
                {
                  label: this.devices[0].data[0].payload.records[key].idName,
                  backgroundColor:
                    this.devices[0].data[0].payload.records[key].hexColor,
                  borderColor:
                    this.devices[0].data[0].payload.records[key].hexColor,
                  data: [],
                  pointRadius: 0,
                  fill: false,
                },
              ],
            },
          ];
        } else {
          this.devices[0].data[0].payload.records[key]["dataChart"] = [
            {
              labels: [],
              datasets: [
                {
                  label: this.devices[0].data[0].payload.records[key].idName,
                  backgroundColor: "#007cc1",
                  borderColor: "#007cc1",
                  data: [],
                  pointRadius: 0,
                  fill: false,
                },
              ],
            },
          ];
        }

        let device = this.devices[0].data[0].payload.records[key];
        this.devices[0].data[0].payload.records[key]["chartOptions"] = {
          interaction: {
            intersect: false,
            mode: "index",
          } as any,
          responsive: true,
          maintainAspectRatio: false,
          animation: {
            duration: 0,
          },
          scales: {
            x: {
              display: true,
              ticks: {
                display: true,
                autoSkipPadding: 50,
              },
            },
            y: {
              ticks: {
                callback: function (value: any) {
                  if (
                    value % 1 !== 0 &&
                    value.toString().split(".")[1].length > 2
                  ) {
                    value = parseFloat(value.toFixed(2));
                  }

                  if (device.unitSymbol) return value + device.unitSymbol;
                  return value;
                }.bind(this),
              },
            },
          },

          plugins: {
            tooltip: {
              callbacks: {
                label: function (context: any) {
                  let label = context.dataset.label || "";
                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += parseFloat(context.parsed.y.toFixed(2));
                  }
                  if (device.unitSymbol) label += device.unitSymbol;
                  return label;
                },
              },
            },
            zoom: {
              zoom: {
                wheel: {
                  enabled: true,
                  modifierKey: "ctrl",
                },
                pinch: {
                  enabled: true,
                },
                mode: "x",
              },
              pan: {
                enabled: true,
                mode: "x",
              },
            },
          },
        };

        this.rangeData
          .slice()
          .reverse()
          .forEach((element: any) => {
            if (typeof element.payload.records[key].value == "number") {
              this.devices[0].data[0].payload.records[
                key
              ].dataChart[0].datasets[0].data.push(
                element.payload.records[key].value
              );
              this.devices[0].data[0].payload.records[
                key
              ].dataChart[0].labels.push(
                moment(element.dateTime).format("DD.MM.YYYY HH:mm")
              );
            } else {
              delete this.devices[0].data[0].payload.records[key];
            }
          });
        if (this.devices[0].data[0].payload.records[key]?.unitSymbol == "°C") {
          if (this.counter == 0) {
            this.firstTemperature =
              this.devices[0].data[0].payload.records[key];
          }
          if (this.counter > 0) {
            this.firstTemperature.labelName.en = "Temperature";
            this.firstTemperature.dataChart[0].datasets.push(
              this.devices[0].data[0].payload.records[key].dataChart[0]
                .datasets[0]
            );
            delete this.devices[0].data[0].payload.records[key];
          }
          this.counter++;
        }
      }
    }
    console.log(
      "Mapirani podaci za chart - ",
      this.devices[0].data[0].payload.records
    );
  },
  methods: {
    zoomIn(myChart: any, chartId: any) {
      console.log("my chart - ", myChart);
      console.log("chart id - ", chartId);

      let chart = document.getElementById(chartId);
      if (chart) {
        const chartInstance = ChartJS.getChart(chart as any);
        chartInstance?.zoom(1.1);
      }
    },

    zoomOut(myChart: any, chartId: any) {
      let chart = document.getElementById(chartId);
      if (chart) {
        const chartInstance = ChartJS.getChart(chart as any);
        chartInstance?.zoom(0.9);
      }
    },
  },
});
</script>
<style scoped></style>
