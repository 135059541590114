<template>
  <modal
    :have-modal="haveModal"
    :is-displayed="true"
    :title="`Developer options for asset ${selectedDevice.assignment.name}`"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div class="relative px-6">
      <div class="mb-2 flex items-center gap-1 text-center text-primary">
        <div
          v-for="category in categories"
          :class="`w-[50%] cursor-pointer border-b-4 border-primary py-1 duration-200 ease-in-out hover:transform hover:font-semibold hover:opacity-100 ${
            selectedCategory === category.name
              ? `font-semibold opacity-100`
              : `opacity-60`
          }`"
          @click="setCategory(category.name)"
        >
          {{ category.label }}
        </div>
      </div>
      <div class="flex items-center gap-1 text-center">
        <div
          v-if="selectedCategory === categories[0].name"
          v-for="language in languages"
          :class="`w-[50%] cursor-pointer py-1 duration-200 ease-in-out hover:transform hover:font-semibold hover:opacity-100 ${
            selectedLanguage === language.name
              ? `bg-primary text-white`
              : `bg-primary text-white opacity-40`
          }`"
          @click="setLanguage(language.name)"
        >
          {{ language.label }}
        </div>
        <div
          v-if="selectedCategory === categories[1].name"
          v-for="getLanguage in getLanguages"
          :class="`w-[50%] cursor-pointer py-1 duration-200 ease-in-out hover:transform hover:font-semibold hover:opacity-100 ${
            selectedGetLanguage === getLanguage.name
              ? `bg-primary text-white`
              : `bg-primary text-white opacity-40`
          }`"
          @click="setGetLanguage(getLanguage.name)"
        >
          {{ getLanguage.label }}
        </div>
      </div>

      <div
        v-tippy="{
          arrow: false,
          placement: 'bottom-end',
          content: 'Copy to clipboard',
          animateFill: false,
          theme: 'translucent',
        }"
        class="group absolute right-10 top-20 z-10 mt-3 flex h-8 w-8 cursor-pointer items-center justify-center rounded-md bg-primaryhover text-white"
        @click="copyToClipboard"
      >
        <font-awesome-icon
          :icon="['fas', 'paste']"
          class="opacity-80 group-hover:opacity-100"
        />
      </div>
      <pre
        id="code"
        class="line-numbers"
      ><code class="language-javascript">{{ selectedCategory == "pushData" ? code[selectedLanguage] : code[selectedGetLanguage] }}</code></pre>
    </div>
  </modal>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import Modal from "@/core/Components/Modal.vue";
//@ts-ignore
import Prism from "prismjs";
import "prismjs/plugins/line-numbers/prism-line-numbers";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import { directive } from "vue-tippy";
import { useToast } from "vue-toastification";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default defineComponent({
  components: { Modal, Prism },
  props: {
    selectedDevice: {
      type: Object as PropType<DeviceAssignment>,
    },
    haveModal: Boolean,
  },
  emits: ["toggle-modal"],
  directives: {
    tippy: directive,
  },
  data() {
    return {
      selectedLanguage: "nodered",
      selectedGetLanguage: "nodeRedLiveData",
      selectedCategory: "pushData",
      isGetDataOpened: true,
      isPushDataOpened: false,
      toast: useToast(),
      languages: [
        { name: "nodered", label: "Node red" },
        { name: "csHttp", label: "C#/HTTP" },
        { name: "csWs", label: "C#/WS" },
      ],
      getLanguages: [
        { name: "nodeRedLiveData", label: "Node red live data" },
        { name: "onlineLiveData", label: "Online live data" },
        { name: "netHistory", label: "NET history" },
        { name: "cURLHistory", label: "cURL history" },
      ],
      categories: [
        { name: "pushData", label: "Push data" },
        { name: "getData", label: "Get data" },
      ],
      code: {
        nodered: `[
    {
        "id": "bbba54b5a05e5a1e",
        "type": "tab",
        "label": "NodeRED HTTP Gateway example",
        "disabled": false,
        "info": "",
        "env": []
    },
    {
        "id": "ed81ddb3154a7d4a",
        "type": "inject",
        "z": "bbba54b5a05e5a1e",
        "name": "Start",
        "props": [],
        "repeat": "",
        "crontab": "",
        "once": false,
        "onceDelay": 0.1,
        "topic": "",
        "x": 450,
        "y": 260,
        "wires": [
            [
                "b4e744f4c73a42d4"
            ]
        ]
    },
    {
        "id": "15dc268d16919277",
        "type": "debug",
        "z": "bbba54b5a05e5a1e",
        "name": "Debug",
        "active": true,
        "tosidebar": true,
        "console": false,
        "tostatus": false,
        "complete": "payload",
        "targetType": "msg",
        "statusVal": "",
        "statusType": "auto",
        "x": 1110,
        "y": 260,
        "wires": []
    },
    {
        "id": "b4e744f4c73a42d4",
        "type": "function",
        "z": "bbba54b5a05e5a1e",
        "name": "Build payload",
        "func": "msg.url = \\"${ConfigurationService.configData.BASE_URL}${ConfigurationService.configData.GATEWAY_PREFIX_PATH}/payload\\";\\nmsg.method = \\"post\\";\\nmsg.payload = \\n{\\n    deviceUuid: <change_me>,\\n   messageType: 1,\\n    channel: 0,\\n    payload: {\\n        message:\\"example\\"\\n    }\\n}\\n\\nreturn msg;",
        "outputs": 1,
        "noerr": 0,
        "initialize": "",
        "finalize": "",
        "libs": [],
        "x": 640,
        "y": 260,
        "wires": [
            [
                "47be63f18455d522"
            ]
        ]
    },
    {
        "id": "47be63f18455d522",
        "type": "http request",
        "z": "bbba54b5a05e5a1e",
        "name": "HTTP Gateway request",
        "method": "use",
        "ret": "obj",
        "paytoqs": "ignore",
        "url": "",
        "tls": "",
        "persist": false,
        "proxy": "",
        "authType": "",
        "senderr": false,
        "x": 890,
        "y": 260,
        "wires": [
            [
                "15dc268d16919277"
            ]
        ]
    }
]`,
        csHttp: `using System.Text;
using System.Text.Json;
var cancellationTokenSource = new CancellationTokenSource();
var stringObject = new {
  deviceUuid = "<change_me>", messageType = 1, channel = 0, payload = new {
    foo = "bar"
  }
};
var client = new HttpClient();
client.BaseAddress = new Uri("${ConfigurationService.configData.BASE_URL}${ConfigurationService.configData.GATEWAY_PREFIX_PATH}/payload");
var stringMessage = JsonSerializer.Serialize(stringObject);
var request = new HttpRequestMessage();
request.Method = HttpMethod.Post;
request.Content = new StringContent(stringMessage, Encoding.UTF8, "application/json");
var result = await client.SendAsync(request, cancellationTokenSource.Token);
Console.WriteLine(result.StatusCode);`,
        csWs: `using System.Net.WebSockets;
using System.Text;
using System.Text.Json;
var cancellationTokenSource = new CancellationTokenSource();
var stringObject = new {
  deviceUuid = "<change_me>", messageType = 1, channel = 0, payload = new {
    foo = "bar"
  }
};
var uri = new Uri("${ConfigurationService.configData.WS_BASE_URL}${ConfigurationService.configData.GATEWAY_PREFIX_PATH}/ws");
var client = new ClientWebSocket();
await client.ConnectAsync(uri, cancellationTokenSource.Token);
if (client.State != WebSocketState.Open) {
  throw new Exception("WebSocket connection is not 'OPEN'");
}
var byteArray = Encoding.UTF8.GetBytes(JsonSerializer.Serialize(stringObject));
await client.SendAsync(byteArray, WebSocketMessageType.Text, true, cancellationTokenSource.Token);`,
        nodeRedLiveData: `[
    {
        "id": "f6f2187d.f17ca8",
        "type": "tab",
        "label": "Flow 1",
        "disabled": false,
        "info": ""
    },
    {
        "id": "0a470b4c33a4d78f",
        "type": "websocket out",
        "z": "f6f2187d.f17ca8",
        "name": "Connect and Subscribe",
        "server": "0194ebee38301712",
        "client": "",
        "x": 450,
        "y": 120,
        "wires": []
    },
    {
        "id": "9853a1823d321773",
        "type": "websocket in",
        "z": "f6f2187d.f17ca8",
        "name": "Listen",
        "server": "0194ebee38301712",
        "client": "",
        "x": 390,
        "y": 220,
        "wires": [
            [
                "6b3c78d15efb169d"
            ]
        ]
    },
    {
        "id": "f680c606f09a99c3",
        "type": "inject",
        "z": "f6f2187d.f17ca8",
        "name": "Subscribe message",
        "props": [
            {
                "p": "payload"
            }
        ],
        "repeat": "",
        "crontab": "",
        "once": false,
        "onceDelay": 0.1,
        "topic": "",
        "payload": "{\\"devices\\":[{\\"deviceUuid\\":\\"<device_uuid>\\"}],\\"verbose\\":true}",
        "payloadType": "json",
        "x": 190,
        "y": 120,
        "wires": [
            [
                "0a470b4c33a4d78f",
                "42f359c3d09e2f6e"
            ]
        ]
    },
    {
        "id": "42f359c3d09e2f6e",
        "type": "debug",
        "z": "f6f2187d.f17ca8",
        "name": "Log",
        "active": true,
        "tosidebar": true,
        "console": false,
        "tostatus": false,
        "complete": "payload",
        "targetType": "msg",
        "statusVal": "",
        "statusType": "auto",
        "x": 390,
        "y": 60,
        "wires": []
    },
    {
        "id": "6b3c78d15efb169d",
        "type": "debug",
        "z": "f6f2187d.f17ca8",
        "name": "Log",
        "active": true,
        "tosidebar": true,
        "console": false,
        "tostatus": false,
        "complete": "payload",
        "targetType": "msg",
        "statusVal": "",
        "statusType": "auto",
        "x": 530,
        "y": 220,
        "wires": []
    },
    {
        "id": "0194ebee38301712",
        "type": "websocket-listener",
        "path": "${ConfigurationService.configData.WS_BASE_URL}${ConfigurationService.configData.WEB_SOCKET_PREFIX_PATH}/device",
        "wholemsg": "false"
    }
]`,
        onlineLiveData: `Visit this website: https://www.piesocket.com/websocket-tester
Paste this URL: ${ConfigurationService.configData.WS_BASE_URL}${ConfigurationService.configData.WEB_SOCKET_PREFIX_PATH}/device
Press 'Connect'
Send this message after establishing the connection:
{
  "devices": [
    {
      "deviceUuid": "<device_uuid>"
    }
  ],
  "verbose": true
}`,
        netHistory: `using System.Net.Http.Headers;
using System.Text.Json;

string access_token = null;

var handler = new HttpClientHandler();
handler.ServerCertificateCustomValidationCallback = (requestMessage, certificate, chain, policyErrors) => true;
using (var httpClient = new HttpClient(handler))
{
    using var requestToken = new HttpRequestMessage(new HttpMethod("POST"), "${ConfigurationService.configData.KEYCLOAK_URL}realms/${ConfigurationService.configData.KEYCLOAK_REALM}/protocol/openid-connect/token");
    requestToken.Content = new StringContent("client_id=mobilisis-3rd-api-consumer&grant_type=password&username=<username>&password=<password>");
    requestToken.Content.Headers.ContentType = MediaTypeHeaderValue.Parse("application/x-www-form-urlencoded");

    var responseToken = await httpClient.SendAsync(requestToken);
    var json = JsonDocument.Parse(await responseToken.Content.ReadAsStringAsync());
    access_token = json.RootElement.GetProperty("access_token").GetString();
}

var client = new HttpClient();
var request = new HttpRequestMessage(HttpMethod.Get, "${ConfigurationService.configData.BASE_URL}${ConfigurationService.configData.STORAGE_API_PREFIX_PATH}/api/data/user/<data_uuid>?From=2023-09-20T00:00:00.000Z&To=2023-09-21T00:00:00.000Z&PageNumber=1&PageSize=10&Channel=0");
request.Headers.Add("Accept", "application/json");
request.Headers.Add("Authorization", $"Bearer {access_token}" );
var response = await client.SendAsync(request);
response.EnsureSuccessStatusCode();
Console.WriteLine(await response.Content.ReadAsStringAsync());
`,
        cURLHistory: `#1 Get token
curl -k -v -X POST -H 'Content-type: application/x-www-form-urlencoded' -d "client_id=mobilisis-3rd-api-consumer&grant_type=password&username=<username>&password=<password>" ${ConfigurationService.configData.KEYCLOAK_URL}realms/${ConfigurationService.configData.KEYCLOAK_REALM}/protocol/openid-connect/token

#2 Get data
curl --location '${ConfigurationService.configData.BASE_URL}${ConfigurationService.configData.STORAGE_API_PREFIX_PATH}/api/data/user/<data_uuid>?From=2023-09-20T00%3A00%3A00.000Z&To=2023-09-21T00%3A00%3A00.000Z&PageNumber=1&PageSize=10&Channel=0' \
--header 'Accept: application/json' \
--header 'Authorization: Bearer <access_token #1>'`,
      } as Record<string, string>,
    };
  },

  created() {
    if (this.selectedLanguage === "nodered") {
      this.code[this.selectedLanguage] = this.code[
        this.selectedLanguage
      ].replaceAll(
        "<change_me>",
        `'${this.selectedDevice?.device?.deviceUuid}'` || ""
      );
    } else {
      this.code[this.selectedLanguage] = this.code[
        this.selectedLanguage
      ].replaceAll(
        '"<change_me>"',
        `"${this.selectedDevice?.device?.deviceUuid}"` || ""
      );
    }

    if (
      this.selectedGetLanguage === "nodeRedLiveData" ||
      this.selectedGetLanguage === "onlineLiveData"
    ) {
      this.code[this.selectedGetLanguage] = this.code[
        this.selectedGetLanguage
      ].replaceAll(
        "<device_uuid>",
        `${this.selectedDevice?.device?.deviceUuid}` || ""
      );
    } else {
      this.code[this.selectedGetLanguage] = this.code[
        this.selectedGetLanguage
      ].replaceAll(
        "<data_uuid>",
        `${this.selectedDevice?.assignment?.dataUuid}` || ""
      );
    }
  },

  mounted() {
    //@ts-ignore
    window.Prism = window.Prism || {};
    //@ts-ignore
    window.Prism.manual = true;
    Prism.highlightAll();
  },

  watch: {
    selectedLanguage(value) {
      if (this.selectedLanguage === "nodered") {
        this.code[this.selectedLanguage] = this.code[
          this.selectedLanguage
        ].replaceAll(
          "<change_me>",
          `'${this.selectedDevice?.device?.deviceUuid}'` || ""
        );
      } else {
        this.code[this.selectedLanguage] = this.code[
          this.selectedLanguage
        ].replaceAll(
          '"<change_me>"',
          `"${this.selectedDevice?.device?.deviceUuid}"` || ""
        );
      }
    },
    selectedGetLanguage(value) {
      if (
        this.selectedGetLanguage === "nodeRedLiveData" ||
        this.selectedGetLanguage === "onlineLiveData"
      ) {
        this.code[this.selectedGetLanguage] = this.code[
          this.selectedGetLanguage
        ].replaceAll(
          "<device_uuid>",
          `${this.selectedDevice?.device?.deviceUuid}` || ""
        );
      } else {
        this.code[this.selectedGetLanguage] = this.code[
          this.selectedGetLanguage
        ].replaceAll(
          "<data_uuid>",
          `${this.selectedDevice?.assignment?.dataUuid}` || ""
        );
      }
    },
  },

  methods: {
    setLanguage(language: string) {
      this.selectedLanguage = language;
      setTimeout(() => {
        Prism.highlightAll();
      }, 1);
    },
    setGetLanguage(getLanguage: string) {
      this.selectedGetLanguage = getLanguage;
      setTimeout(() => {
        Prism.highlightAll();
      }, 1);
    },
    setCategory(category: string) {
      this.selectedCategory = category;
      setTimeout(() => {
        Prism.highlightAll();
      }, 1);
    },

    copyToClipboard() {
      const copyText = document.getElementById("code");
      const textArea = document.createElement("textarea");
      textArea.classList.add("copy-to-clipboard");
      textArea.textContent = copyText?.textContent || "";

      document.body.append(textArea);
      textArea.select();
      document.execCommand("copy");
      this.toast.success("Copied to clipboard");
    },
  },
});
</script>
<style>
pre {
  margin: 0 !important;
}
</style>
