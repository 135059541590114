import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "space-y-4"
}
const _hoisted_2 = { class: "mb-4 flex items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_modal = _resolveComponent("modal")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_loader = _resolveComponent("loader")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_empty_state = _resolveComponent("empty-state")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isEditModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      title: "Edit resource",
      onToggleModal: _ctx.toggleEditModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_edit_form, {
          "is-form-in-modal": true,
          "object-id": _ctx.selectedResource.deviceAssignmentId,
          "refresh-data": _ctx.getAllResources
        }, null, 8, ["object-id", "refresh-data"])
      ]),
      _: 1
    }, 8, ["is-displayed", "onToggleModal"]),
    _createVNode(_component_modal, {
      "is-displayed": _ctx.isCreateModalOpened,
      "is-select-modal": true,
      "need-for-scroll": true,
      title: "Create resource",
      onToggleModal: _ctx.toggleCreateModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_create_form, {
          "is-form-in-modal": true,
          "refresh-data": _ctx.getAllResources
        }, null, 8, ["refresh-data"])
      ]),
      _: 1
    }, 8, ["is-displayed", "onToggleModal"]),
    _createVNode(_component_delete_modal, {
      "is-deleting": _ctx.isDeleting,
      "selected-data": _ctx.selectedResource,
      "delete-text": "Are you sure you want to delete ",
      onToggleModal: _ctx.toggleDeleteModal,
      onCancelDelete: _ctx.toggleDeleteModal,
      onConfirmDelete: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openConfirmationModal('delete')))
    }, null, 8, ["is-deleting", "selected-data", "onToggleModal", "onCancelDelete"]),
    _createVNode(_component_confirmation_modal, {
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "delete-text": "delete",
      onCloseConfirmationModal: _ctx.toggleConfirmationModal,
      onToggleModal: _ctx.toggleConfirmationModal,
      onConfirmAction: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirmationModalAction()))
    }, null, 8, ["is-confirmation-modal-opened", "onCloseConfirmationModal", "onToggleModal"]),
    _createVNode(_component_main_section, { title: "Access manager/Resources" }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, {
              key: 0,
              "inner-ring-thickness": "border-t-8",
              "outer-ring-thickness": "border-8"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.permissionStore.hasPermission(_ctx.permissions.CreateAssignment))
                  ? (_openBlock(), _createBlock(_component_the_button, {
                      key: 0,
                      icon: ['fas', 'plus'],
                      buttonType: "primaryButton",
                      text: "Create",
                      onExecuteMethod: _ctx.changeRoute
                    }, null, 8, ["onExecuteMethod"]))
                  : _createCommentVNode("", true)
              ]),
              (!_ctx.isLoading && !_ctx.resources.length)
                ? (_openBlock(), _createBlock(_component_empty_state, {
                    key: 0,
                    pageName: "resources"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_the_button, {
                        icon: ['fas', 'plus'],
                        buttonType: "primaryButton",
                        text: "Add new resource",
                        onExecuteMethod: _ctx.changeRoute
                      }, null, 8, ["onExecuteMethod"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_custom_table, {
                    key: 1,
                    "is-data-source-loading": _ctx.isDataSourceLoading,
                    options: _ctx.options,
                    "table-data": _ctx.resources,
                    "table-headers": _ctx.tableHeaders,
                    onSelectedRows: _ctx.getSelectedRows,
                    onForceRefresh: _ctx.getResources
                  }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onForceRefresh"]))
            ]))
      ]),
      _: 1
    })
  ], 64))
}