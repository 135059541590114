import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-4 mt-6" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "flex items-center justify-center border h-12 w-12",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-click'))),
      type: "button"
    }, [
      _createVNode(_component_font_awesome_icon, {
        class: "w-5 h-5",
        icon: ['fas', 'plus']
      })
    ]),
    _createElementVNode("button", {
      class: "flex items-center justify-center border h-12 w-12 delete-button",
      disabled: _ctx.calculateNumberOfVisibleAccesses() <= 1,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('remove-click'))),
      type: "button"
    }, [
      _createVNode(_component_font_awesome_icon, {
        class: _normalizeClass(` w-5 h-5`),
        icon: ['fas', 'trash']
      })
    ], 8, _hoisted_2)
  ]))
}