<template>
  <modal-component
    :have-modal="true"
    :is-displayed="isFloorPlanCreateModalOpened"
    :title="'Create floorplan'"
    @toggle-modal="$emit('toggle-modal')"
  >
    <file-input
      :floorplan-service="floorplanService"
      @change-to-create-mode="$emit('change-to-create-mode', $data)"
      @toggle-modal="$emit('toggle-modal')"
    />
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FileInput from "@/core/Components/FormElements/FileInput.vue";
import FloorplansService from "@/modules/MapView/Services/Floorplan/FloorplansService";

export default defineComponent({
  components: { FileInput, ModalComponent },
  emits: ["toggle-modal", "change-to-create-mode"],
  props: {
    isFloorPlanCreateModalOpened: Boolean,
    floorplanService: {
      type: Object as PropType<FloorplansService>,
      required: true,
    },
  },
});
</script>

<style scoped></style>
