import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "h-full"
}
const _hoisted_2 = { class: "mb-4 flex w-full items-center justify-between" }
const _hoisted_3 = { class: "mb-0 text-xl font-semibold leading-normal" }
const _hoisted_4 = { class: "space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_form = _resolveComponent("create-form")!
  const _component_create_bulk_form = _resolveComponent("create-bulk-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_loader = _resolveComponent("loader")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 0,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "refresh-data": _ctx.getParkingSpots,
          onToggleModal: _ctx.toggleCreateModal
        }, null, 8, ["is-create-modal-opened", "refresh-data", "onToggleModal"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateBulkModalOpened)
      ? (_openBlock(), _createBlock(_component_create_bulk_form, {
          key: 1,
          "is-create-bulk-modal-opened": _ctx.isCreateBulkModalOpened,
          "refresh-data": _ctx.getParkingSpots,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCreateBulkModalOpened = false))
        }, null, 8, ["is-create-bulk-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isEditFormModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 2,
          "is-edit-form-modal-opened": _ctx.isEditFormModalOpened,
          "parking-spot-prop": _ctx.selectedParkingSpot,
          "refresh-data": _ctx.getParkingSpots,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditFormModalOpened = false))
        }, null, 8, ["is-edit-form-modal-opened", "parking-spot-prop", "refresh-data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.$t("ParkingSpots.Title")), 1),
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.permissionStore.hasPermission(_ctx.permissions.CreateAssignment))
                    ? (_openBlock(), _createBlock(_component_the_button, {
                        key: 0,
                        icon: ['fas', 'add'],
                        text: _ctx.$t('Create'),
                        onExecuteMethod: _ctx.toggleCreateModal
                      }, null, 8, ["text", "onExecuteMethod"]))
                    : _createCommentVNode("", true),
                  (_ctx.permissionStore.hasPermission(_ctx.permissions.CreateAssignment))
                    ? (_openBlock(), _createBlock(_component_the_button, {
                        key: 1,
                        icon: ['fas', 'add'],
                        text: _ctx.$t('CreateBulk'),
                        onExecuteMethod: _ctx.toggleCreateBulkModal
                      }, null, 8, ["text", "onExecuteMethod"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                options: _ctx.options,
                "render-summary": ['space'],
                "show-per-page": 16,
                "table-data": _ctx.parkingSpots,
                "table-headers": _ctx.tableHeaders,
                "table-height": "93%",
                isActionListNotDisplayed: 
          !_ctx.permissionStore.hasPermission(_ctx.permissions.EditAssignment)
        ,
                onSelectedRows: _ctx.getSelectedRows,
                onForceRefresh: _ctx.getParkingSpots
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "isActionListNotDisplayed", "onSelectedRows", "onForceRefresh"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}