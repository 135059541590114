import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import {
  AssignmentMetadata,
  CreateAssignmentMetadata,
} from "@/modules/Devices/Submodules/AssignmentMetadata/Services/AssignmentMetadataTypes";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class AssignmentMetadataService {
  async getAllAssignmentMetadata(): Promise<AssignmentMetadata[]> {
    const url = GenerateUrl({
      path: "/api/device-assignment-metadata",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        Include: ["MetadataType"],
        PageNumber: 1,
        PageSize: 100000,
      },
    });

    const response = await ApiService.get(url);
    return this._mapAssignmentsMetadata(response.data.items);
  }

  async getAssignmentMetadataTypes() {
    const url = GenerateUrl({
      path: "/api/device-assignment-metadata-types",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: 1,
        PageSize: 100000,
      },
    });

    const response = await ApiService.get(url);
    return response.data.items;
  }

  async createAssignmentMetadata({
    createAssignmentMetadata,
  }: {
    createAssignmentMetadata: CreateAssignmentMetadata;
  }) {
    const url = GenerateUrl({
      path: "/api/device-assignment-metadata",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, createAssignmentMetadata);
  }

  async createAssignmentMetadataRelation({
    metadataId,
    assignmentIds,
  }: {
    metadataId: number;
    assignmentIds: number[];
  }) {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/device-assignment-metadata/${metadataId}/relations`,
    });

    return await ApiService.post(url, { assignmentIds });
  }

  async updateAssignmentMetadata({
    id,
    assignmentMetadata,
  }: {
    id: number;
    assignmentMetadata: PatchObject[];
  }) {
    const url = GenerateUrl({
      path: `/api/device-assignment-metadata/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, assignmentMetadata);
  }

  async deleteAssignmentMetadata(id: number) {
    const url = GenerateUrl({
      path: `/api/device-assignment-metadata/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  async deleteAssignmentMetadataRelation({
    assignmentMetadataId,
    assignmentIdList,
  }: {
    assignmentMetadataId: number;
    assignmentIdList: number[];
  }) {
    const url = GenerateUrl({
      path: `/api/device-assignment-metadata/${assignmentMetadataId}/relations`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url, { assignmentIds: assignmentIdList });
  }

  private _mapAssignmentsMetadata(
    response: AssignmentMetadata[]
  ): AssignmentMetadata[] {
    return response.map((metadata) => this._mapAssignmentMetadata(metadata));
  }

  private _mapAssignmentMetadata(
    metadata: AssignmentMetadata
  ): AssignmentMetadata {
    return {
      id: metadata.id,
      name: metadata.name,
      metadataTypeId: metadata.metadataTypeId,
      metadataType: metadata.metadataType,
      data: metadata.data,
    };
  }
}
