<template>
  <modal-component
    :is-displayed="isInfoModalOpened"
    :no-padding="true"
    title="Info"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div ref="modalContent" class="px-6">
      <div>
        <ul>
          <li>
            - Hold "Shift" key and mark parking spots which you want edit
          </li>
          <li>
            - Hold "Ctrl" key and click on first then on last parking spot which
            you want edit
          </li>
          <li>
            - Unselect parking spots with "Space" key
          </li>
          <li>
            - "Recalculate names" button will be calculate new parking spot
            names
          </li>
          <li>
            - If you have different segments of parking spot names, mark spots,
            give start number and after all, recalculate for these marked
            parking spots
          </li>
          <li>
            - "Move to parking space position" button will be move parking spots
            to location of parking space
          </li>
        </ul>
      </div>
      <div class="flex items-center justify-end py-4">
        <the-button
          button-type="oulineButton"
          text="Close"
          @execute-method="$emit('toggle-modal')"
        />
      </div>
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: {
    TheButton,
    ButtonWrapper,
    ModalComponent,
  },
  props: {
    isInfoModalOpened: Boolean,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      isInfoModalOpenedss: false,
    };
  },
});
</script>

<style scoped>
ul {
  list-style-type: none;
}

li {
  display: flex;
  margin-top: 7px;
}
</style>
