import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import { DeviceTypeMetadata } from "@/modules/Production/Submodules/DeviceTypeMetadata/Services/DeviceTypeMetadataTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type DeviceType = {
  id: number;
  name: string;
  productionPN: string | null;
  customerPN: string | null;
  serialNumberTypeId: number | null;
  serialNumberType?: SerialNumberType;
  isProductionEnabled: boolean;
  metadata?: DeviceTypeMetadata[];
};

export type SerialNumberType = {
  id: number;
  name: string;
};

export type CreateDeviceType = {
  name: string;
  productionPN: string;
  customerPN: string;
  serialNumberTypeId: number | null;
  parentDeviceTypeId: number | null;
  isProductionEnabled: boolean;
};

export type EditeDeviceType = Omit<CreateDeviceType, "parentDeviceTypeId"> & {
  id: number;
};

export default class DeviceTypeService {
  async getDeviceTypes(): Promise<DeviceType[]> {
    const url = GenerateUrl({
      path: "/api/device-types",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        Include: ["SerialNumberType", "Metadata"],
        PageNumber: 1,
        PageSize: 100000,
        SortOrder: "Ascending",
        SortBy: "Name",
      },
    });

    const response = await ApiService.get(url);

    return this._mapDeviceTypes(response.data.items);
  }

  async getSerialNumberTypes(): Promise<SerialNumberType[]> {
    const url = GenerateUrl({
      path: "/api/serial-number-types",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: 1,
        PageSize: 100000,
      },
    });

    const response = await ApiService.get(url);

    return response.data.items;
  }

  async createDeviceType(createDeviceType: CreateDeviceType): Promise<any> {
    const url = GenerateUrl({
      path: "/api/device-types",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, createDeviceType);
  }

  async editDeviceType({
    id,
    deviceType,
  }: {
    id: number;
    deviceType: PatchObject[];
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/device-types/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, deviceType);
  }

  async deleteDeviceType(id: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/device-types/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  private _mapDeviceTypes(response: DeviceType[]): DeviceType[] {
    return response.map((deviceType) => this._mapDeviceType(deviceType));
  }

  private _mapDeviceType(deviceType: DeviceType): DeviceType {
    return {
      id: deviceType.id,
      name: deviceType.name,
      productionPN: deviceType.productionPN,
      customerPN: deviceType.customerPN,
      serialNumberTypeId: deviceType.serialNumberTypeId,
      serialNumberType: deviceType.serialNumberType,
      isProductionEnabled: deviceType.isProductionEnabled,
      metadata: deviceType.metadata,
    };
  }
}
