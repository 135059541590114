import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44533b98"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "w-full h-[200px] flex items-center justify-center"
}
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-between mb-4 w-full border-b pb-1 px-6 border-lightgray"
}
const _hoisted_3 = { class: "font-bold text-2xl text-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_small_loader = _resolveComponent("small-loader")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_small_loader)
      ]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(`scroll shadow ${_ctx.widerForm ? 'w-7/12' : 'w-6/12'} ${_ctx.isFormInModal && '!w-full !max-h-fit !shadow-none'}  bg-white rounded-lg`)
      }, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1)
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2))
}