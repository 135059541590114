import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full py-3" }
const _hoisted_2 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createElementVNode("input", {
      value: _ctx.modelValue,
      class: "mt-1 w-full",
      type: "color",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value)
      ))
    }, null, 40, _hoisted_3)
  ]))
}