import ApiService from "@/core/Axios/ApiService";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import { UserPermission } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export type Company = {
  id: number;
  name: string;
  isCompanyActive?: boolean;
  permissions: UserPermission[];
};

export type CreateCompany = Pick<Company, "name">;

export type EditCompany = Pick<Company, "id" | "name">;

export type AddUserToCompany = {
  userEmail: string;
  companyId: number;
};

export type AddUserToCompanyWithEmail = {
  email: string;
  companyId: number;
};

export type CompanyObject = {
  items: Company[];
  pageIndex: number;
  totalPages: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export default class CompanyService {
  async getAllCompanies(
    pageNumber: number = 1,
    pageSize: number = 10,
    name?: string
  ): Promise<CompanyObject> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: "/api/companies",
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortOrder: "Ascending",
        ...(name && { Name: name }),
      },
    });
    const response = await ApiService.get(url);

    return {
      hasNextPage: response.data.hasNextPage,
      hasPreviousPage: response.data.hasPreviousPage,
      items: this._mapCompanies(response.data.items),
      pageIndex: response.data.pageIndex,
      totalPages: response.data.totalPages,
      totalCount: response.data.totalCount,
    };
  }

  async getCompanyWithIdentifier(identifier: number): Promise<Company> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/companies/${identifier}`,
      config: {},
    });
    const response = await ApiService.get(url);
    return this._mapCompany(response.data);
  }

  async createCompany(company: CreateCompany): Promise<any> {
    await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/companies`,
      company
    );
  }

  async editCompany(company: EditCompany): Promise<any> {
    const patchObject = [{ op: "replace", path: "/name", value: company.name }];
    await ApiService.patch(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/companies/${company.id}`,
      patchObject
    );
  }

  async deleteCompany(identifier: number): Promise<any> {
    return await ApiService.delete(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/companies/${identifier}`
    );
  }

  async addUserToCompany(details: AddUserToCompany): Promise<any> {
    return await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/companies/${details.companyId}/users`,
      details
    );
  }

  async addUserToCompanyWithUsername(
    details: AddUserToCompanyWithEmail
  ): Promise<any> {
    return await ApiService.post(
      `${ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH}/api/companies/${details.companyId}/users`,
      details
    );
  }

  private _mapCompanies(response: Company[]): Company[] {
    return response.length
      ? response.map((company: Company) => this._mapCompany(company))
      : [];
  }

  private _mapCompany(company: Company): Company {
    return {
      id: company.id,
      name: company.name,
      permissions: company.permissions,
    };
  }
}
