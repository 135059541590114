<template>
  <modal-component
    :is-displayed="isCreateFormModalOpened"
    :no-padding="true"
    :title="$t('Authorization.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Authorization.User") }}</label
            >
            <div class="flex items-center justify-center">
              <v-select
                v-model="resource.toUserId"
                :class="`mt-1 w-full`"
                :options="users"
                :reduce="(user : any) => user.id"
                label="email"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Authorization.ResourceType") }}</label
            >
            <div class="flex items-center justify-center">
              <v-select
                v-model="resource.resourceTypeId"
                :class="`mt-1 w-full`"
                :options="resourceTypes"
                :reduce="(resourceType : any) => resourceType.id"
                label="name"
                :disabled="true"
                :clearable="false"
              ></v-select>
            </div>
          </div>
          <div
            v-if="resource.resourceTypeId === 2"
            class="relative items-center py-4"
          >
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Authorization.Asset") }}</label
            >
            <div class="flex items-center justify-center">
              <v-select
                v-model="resource.onResourceId"
                :class="`mt-1 w-full`"
                :get-option-label="(device: any) => device.assignment.name"
                :options="deviceAssignment"
                :reduce="(device : any) => device.device.id"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Authorization.Role") }}</label
            >
            <div class="flex items-center justify-center">
              <v-select
                v-model="resource.roleId"
                :class="`mt-1 w-full`"
                :options="roles"
                :reduce="(device : any) => device.id"
                label="name"
              ></v-select>
            </div>
          </div>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import RoleAssignType from "@/modules/AdminDashboard/Submodules/Authorization/Components/RoleAssignType.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { Role } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/RoleTypes";
import RoleService from "@/modules/AdminDashboard/Submodules/Authorization/Services/RoleService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import UserService, { User } from "@/modules/Users/Services/UserService";
import AssignmentService, {
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import AuthorizationService from "@/modules/AdminDashboard/Submodules/Authorization/Services/AuthorizationService";
import { CreateResource } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/AuthorizationTypes";
import { useUserStore } from "@/core/Store/userStore";
import { ResourceType } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/AuthorizationTypeType";
import { useServiceStore } from "@/core/Store/serviceStore";

export default defineComponent({
  components: {
    ButtonWrapper,
    RoleAssignType,
    SmallLoader,
    FormDivider,
    FormWrapper,
    ModalComponent,
    VSelect,
  },
  props: {
    isCreateFormModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      authorizationService: new AuthorizationService(),
      roleService: new RoleService(),
      userService: new UserService(),
      deviceAssignmentService: new AssignmentService(),
      roles: [] as Role[],
      users: [] as User[],
      deviceAssignment: [] as DeviceAssignment[],
      resourceTypes: [] as ResourceType[],
      roleType: 2,
      isLoading: false,
      isSubmitLoading: false,
      toast: useToast(),
      userStore: useUserStore(),
      serviceStore: useServiceStore(),
      resource: {
        toCompanyId: null,
        toUserId: null,
        onCompanyId: null,
        resourceTypeId: null,
        onResourceId: null,
        roleId: null,
        permissionIds: [],
        createdUserId: null,
        policyId: null,
        companyId: null,
      } as CreateResource,
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.getRoles(),
        this.getUsers(),
        this.getDeviceAssignments(),
        this.getResourceTypes(),
      ]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async handleSubmit() {
      this.resource.createdUserId = this.userStore.userId;
      this.resource.onCompanyId = this.userStore.activeCompanyId;

      if (this.resource.onCompanyId !== null) {
        this.resource.onResourceId = this.resource.onCompanyId;
      }
      this.isSubmitLoading = true;
      try {
        await this.authorizationService.createResource(this.resource);
        this.toast.success(this.$t("General.CreatedSuccessfully"));
        this.$emit("toggle-modal");
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getRoles() {
      try {
        this.roles = await this.roleService.getAllRoles({
          pageNumber: 1,
          pageSize: 100000,
          serviceId: this.serviceStore.currentActiveServiceId,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getResourceTypes() {
      try {
        this.resourceTypes = await this.authorizationService.getResourceTypes();
        const companyResource = this.resourceTypes.find(
          (resource) => resource.name === "Company"
        );
        this.resource.resourceTypeId = companyResource?.id || null;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getUsers() {
      try {
        const response = await this.userService.getAllUsers(1, 100000);
        this.users = response.items;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getDeviceAssignments() {
      try {
        this.deviceAssignment =
          await this.deviceAssignmentService.getAllDeviceAssignments({
            pageNumber: 1,
            pageSize: 100000,
            typeOfAssignment: "Company",
          });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>

<style scoped></style>
