import { ParkingSession } from "@/modules/Parkom/Submodules/ParkingSessions/Services/ParkingSessionTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class ParkingSessionService {
  async getParkingSessions({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number;
    pageSize: number;
  }): Promise<ParkingSession[]> {
    const url = GenerateUrl({
      path: "/api/sessions",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

    const response = await ApiService.get(url);
    return this._mapParkingSessions(response.data.items);
  }

  async getParkingSessionsFilter({
    pageNumber,
    pageSize,
    from,
    to,
    parkingSpaceIds,
  }: {
    pageNumber: number;
    pageSize: number;
    from: string;
    to: string;
    parkingSpaceIds: number[];
  }): Promise<ParkingSession[]> {
    const url = GenerateUrl({
      path: "/api/sessions/filter",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        From: from,
        To: to,
        ParkingSpaceIds: parkingSpaceIds,
      },
    });
    const response = await ApiService.get(url);
    return this._mapParkingSessions(response.data.items);
  }

  private _mapParkingSessions(response: ParkingSession[]): ParkingSession[] {
    return response.map((session) => {
      return this._mapParkingSession(session);
    });
  }

  private _mapParkingSession(response: ParkingSession): ParkingSession {
    return {
      localSessionId: response.localSessionId,
      start: response.start,
      end: response.end,
      spotName: response.spotName,
      spotId: response.spotId,
      spotType: response.spotType,
      durationMinutes: response.durationMinutes,
      spaceName: response.spaceName,
      icdCustomerName: response.icdCustomerName,
    };
  }
}
