<template>
  <div class="divide-y divide-gray mb-8 px-6">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({});
</script>
<style scoped></style>
