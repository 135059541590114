<template>
  <create-form
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getAllCompanies"
    @toggle-modal="isCreateModalOpened = false"
  />
  <edit-form
    v-if="isEditModalOpened"
    :is-edit-modal-opened="isEditModalOpened"
    :object-id="selectedCompany.id"
    :refresh-data="getAllCompanies"
    @toggle-modal="isEditModalOpened = false"
  />
  <delete-modal
    :is-deleting="isDeleting"
    :selectedData="selectedCompany"
    delete-text="Are you sure you want to delete organization "
    @toggle-modal="toggleDeleteModal"
    @cancel-delete="toggleDeleteModal"
    @confirm-delete="openConfirmationModal('delete')"
  />
  <confirmation-modal
    :delete-text="confirmationModalText"
    :is-confirmation-modal-opened="isConfirmationModalOpened"
    :number-of-devices="selectedRows.length"
    @confirm-action="confirmationModalAction"
    @close-confirmation-modal="isConfirmationModalOpened = false"
    @toggle-modal="isConfirmationModalOpened = false"
  />
  <main-section>
    <loader
      v-if="isLoading"
      inner-ring-thickness="border-t-8"
      outer-ring-thickness="border-8"
    />
    <div v-else>
      <table-action-header
        :icon="['fas', 'add']"
        :permission-id="permissions.CreateCompany"
        :title="$t('Organizations.Title')"
        description=" "
        button-text="Create"
        @execute-method="toggleCreateModal"
      />
      <!--    <custom-table :table-data="companies" :pagination-data="companiesPagination" :table-headers="tableHeaders" route-name="companyDetails" /> -->
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="companiesPagination.items"
        :table-headers="tableHeaders"
        @selected-rows="getSelectedRows"
        @force-refresh="getAllCompanies"
      />
      <pagination
        :pagination-data="companiesPagination"
        @page-change="handlePageChange"
        @page-size-change="handlePageSizeChange"
      />
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "../../../core/Components/MainSection.vue";
import CustomTable from "../../../core/Components/CustomTable.vue";
import CompanyService, {
  CompanyObject,
  Company,
} from "../Services/CompanyService";
import TheButton from "../../../core/Components/TheButton.vue";
import Loader from "../../../core/Components/Loader.vue";
import {
  CheckIfThereIsError,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import Center from "@/core/Components/Center.vue";
import { useSidebarStore } from "@/core/Store";
import { maxLength, required } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import { useUserStore } from "@/core/Store/userStore";
import Modal from "@/core/Components/Modal.vue";
import CreateForm from "../Components/CreateForm.vue";
import EditForm from "../Components/EditForm.vue";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import UserService from "@/modules/Users/Services/UserService";
import { useToast } from "vue-toastification";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import { Permissions } from "@/core/Services/Enums";
import Pagination from "@/core/Components/Pagination.vue";
import { PaginationSettings } from "@/core/Services/PaginationSettings";

export default defineComponent({
  components: {
    TableActionHeader,
    MainSection,
    CustomTable,
    TheButton,
    Loader,
    FormWrapper,
    InputField,
    Center,
    ButtonWrapper,
    FormDivider,
    Modal,
    CreateForm,
    EditForm,
    DeleteModal,
    ConfirmationModal,
    Pagination,
  },
  data() {
    return {
      userStore: useUserStore(),
      v$: useValidate() as any,
      changeCompanyStore: useChangeCompanyStore(),
      validationErrors: [] as any,
      currentPage: PaginationSettings.DEFAULT_CURRENT_PAGE,
      currentPageSize: PaginationSettings.DEFAULT_CURRENT_PAGE_SIZE,
      companiesPagination: {} as CompanyObject,
      selectedRows: [] as Company[],
      selectedCompany: {} as Company,
      companyService: new CompanyService(),
      userService: new UserService(),
      showToast: false as Boolean,
      index: 1 as Number,
      isLoading: false as boolean,
      isDataSourceLoading: false,
      isSubmitLoading: false as boolean,
      isCreateModalOpened: false as boolean,
      isEditModalOpened: false,
      currentCompanyID: 0,
      sidebarStore: useSidebarStore(),
      toast: useToast(),
      permissions: Permissions,
      isDeleting: false,
      isConfirmationModalOpened: false,
      confirmationModalText: "",
      tableHeaders: {
        id: { header: "ID", visible: false },
        name: { header: "Organizations.Name", visible: true },
        isCompanyActive: {
          header: "Organizations.ActiveCompany",
          visible: true,
        },
      },
      formData: {
        id: 0,
        name: "",
      },
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditCompany,
          action: this.toggleEditModal,
        },
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteCompany,
          action: this.toggleDeleteModal,
        },
      },
    };
  },
  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getAllCompanies();
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getAllCompanies();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
        },
      },
    };
  },
  methods: {
    checkIfThereIsError: CheckIfThereIsError,
    handlePageChange(newPage: number) {
      this.currentPage = newPage;
      this.getAllCompanies();
    },
    handlePageSizeChange(newPageSize: number) {
      this.currentPageSize = newPageSize;
      this.currentPage = 1;
      this.getAllCompanies();
    },
    getSelectedRows(values: Company[]) {
      this.selectedRows = values;
      if (values.length) {
        this.selectedCompany = values[0];
      }
    },
    async getAllCompanies() {
      this.currentCompanyID = this.userStore.activeCompanyId;
      this.isDataSourceLoading = true;
      try {
        const response = await this.companyService.getAllCompanies(
          this.currentPage,
          this.currentPageSize
        );
        this.companiesPagination = response;
        this.companiesPagination.items = response.items.map(
          (company: Company) => {
            company.id === this.userStore.activeCompanyId
              ? (company.isCompanyActive = true)
              : (company.isCompanyActive = false);
            return company;
          }
        );
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async deleteCompany() {
      this.isDataSourceLoading = true;
      try {
        if (this.selectedCompany.id !== null) {
          await this.companyService.deleteCompany(this.selectedCompany.id);
        }
        this.toast.success(this.$t("General.DeletedSuccessfully"));
        this.toggleDeleteModal();
        location.reload();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        await this.getAllCompanies();
        this.isDataSourceLoading = false;
      }
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleEditModal() {
      this.isEditModalOpened = !this.isEditModalOpened;
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },
    toggleDeleteModal() {
      this.isDeleting = !this.isDeleting;
    },
    confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          this.deleteCompany();
          break;
      }
      this.isConfirmationModalOpened = false;
    },
  },
});
</script>
<style></style>
