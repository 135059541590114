import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "mb-4 flex items-center justify-between" }
const _hoisted_3 = { class: "flex gap-2" }
const _hoisted_4 = { class: "w-60" }
const _hoisted_5 = { class: "flex items-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_last_data_preview = _resolveComponent("last-data-preview")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_sent_data_devices = _resolveComponent("sent-data-devices")!
  const _component_modal = _resolveComponent("modal")!
  const _component_q_r_code_devices_modal = _resolveComponent("q-r-code-devices-modal")!
  const _component_apply_metadata_relation = _resolveComponent("apply-metadata-relation")!
  const _component_delete_metadata_relation = _resolveComponent("delete-metadata-relation")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_loader = _resolveComponent("loader")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDataPreviewModalOpened)
      ? (_openBlock(), _createBlock(_component_last_data_preview, {
          key: 0,
          data: _ctx.dataForModal,
          "is-last-data-modal-opened": _ctx.isDataPreviewModalOpened,
          name: _ctx.selectedDevice[0]?.name,
          preview: true,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDataPreviewModalOpened = false))
        }, null, 8, ["data", "is-last-data-modal-opened", "name"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 1,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "refresh-data": _ctx.getAllDevices,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isCreateModalOpened = false))
        }, null, 8, ["is-create-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isEditModalOpened)
      ? (_openBlock(), _createBlock(_component_edit_form, {
          key: 2,
          device: _ctx.selectedDevice[0],
          "is-edit-modal-opened": _ctx.isEditModalOpened,
          "refresh-data": _ctx.getAllDevices,
          onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEditModalOpened = false))
        }, null, 8, ["device", "is-edit-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isDeleteModalOpened)
      ? (_openBlock(), _createBlock(_component_delete_modal, {
          key: 3,
          "is-deleting": _ctx.isDeleteModalOpened,
          onCancelDelete: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
          onToggleModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDeleteModalOpened = false)),
          onConfirmDelete: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openConfirmationModal('delete')))
        }, null, 8, ["is-deleting"]))
      : _createCommentVNode("", true),
    _createVNode(_component_confirmation_modal, {
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "delete-text": "delete",
      onConfirmAction: _ctx.deleteDevice,
      onCloseConfirmationModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isConfirmationModalOpened = false))
    }, null, 8, ["is-confirmation-modal-opened", "onConfirmAction"]),
    (_ctx.isSentDataModalOpened)
      ? (_openBlock(), _createBlock(_component_modal, {
          key: 4,
          "have-modal": true,
          "is-full-screen-modal": true,
          "is-displayed": _ctx.isSentDataModalOpened,
          "is-select-modal": true,
          "need-for-scroll": true,
          "remove-outside-click": true,
          title: `Device data sent by ${_ctx.selectedDevice[0].name}`,
          onToggleModal: _ctx.toggleDataSentModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_sent_data_devices, {
              deviceUuid: _ctx.selectedDevice[0].uuid,
              deviceName: _ctx.selectedDevice[0].name
            }, null, 8, ["deviceUuid", "deviceName"])
          ]),
          _: 1
        }, 8, ["is-displayed", "title", "onToggleModal"]))
      : _createCommentVNode("", true),
    (_ctx.isQrCodeModalOpened)
      ? (_openBlock(), _createBlock(_component_q_r_code_devices_modal, {
          key: 5,
          "device-details": _ctx.selectedDevice[0],
          "have-modal": true,
          "is-qr-modal-opened": _ctx.isQrCodeModalOpened,
          onToggleModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.isQrCodeModalOpened = false))
        }, null, 8, ["device-details", "is-qr-modal-opened"]))
      : _createCommentVNode("", true),
    (_ctx.isCreateRelationModalOpened)
      ? (_openBlock(), _createBlock(_component_apply_metadata_relation, {
          key: 6,
          "device-id-list": _ctx.deviceIdList,
          "is-create-relation-modal-opened": _ctx.isCreateRelationModalOpened,
          "refresh-data": _ctx.getAllDevices,
          "table-data": _ctx.devicePagination.items,
          onToggleModal: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isCreateRelationModalOpened = false))
        }, null, 8, ["device-id-list", "is-create-relation-modal-opened", "refresh-data", "table-data"]))
      : _createCommentVNode("", true),
    (_ctx.isDeleteRelationModalOpened)
      ? (_openBlock(), _createBlock(_component_delete_metadata_relation, {
          key: 7,
          "device-id": _ctx.selectedDevice[0].id ?? 0,
          "is-delete-relation-modal-opened": _ctx.isDeleteRelationModalOpened,
          metadata: _ctx.selectedDevice[0].metadata,
          "refresh-data": _ctx.getAllDevices,
          onToggleModal: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isDeleteRelationModalOpened = false))
        }, null, 8, ["device-id", "is-delete-relation-modal-opened", "metadata", "refresh-data"]))
      : _createCommentVNode("", true),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        _createVNode(_component_table_action_header, {
          title: _ctx.$t('Devices.Title'),
          description: " "
        }, null, 8, ["title"]),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, {
              key: 0,
              "inner-ring-thickness": "border-t-8",
              "outer-ring-thickness": "border-8"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_v_select, {
                    class: "mt-1 w-60",
                    modelValue: _ctx.selectedFilterOption,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedFilterOption) = $event)),
                    options: _ctx.searchOptions,
                    reduce: (option) => option.key,
                    label: "label",
                    clearable: false
                  }, null, 8, ["modelValue", "options", "reduce"]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_input_field, {
                      modelValue: _ctx.searchDeviceText,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.searchDeviceText) = $event)),
                      placeholder: "Enter search value",
                      "no-padding": true,
                      onInput: _ctx.handleDeviceSearch
                    }, null, 8, ["modelValue", "onInput"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_the_button, {
                    text: "Apply metadata",
                    isApplyButtonEnabled: "",
                    "is-disabled": 
              !_ctx.isApplyButtonEnabled ||
              !_ctx.permissionStore.hasPermission(_ctx.permissions.EditDevice)
            ,
                    variant: 
              _ctx.isApplyButtonEnabled ||
              !_ctx.permissionStore.hasPermission(_ctx.permissions.EditDevice)
                ? 'default'
                : 'ghost'
            ,
                    onExecuteMethod: _ctx.toggleCreateRelationModal
                  }, null, 8, ["is-disabled", "variant", "onExecuteMethod"]),
                  (_ctx.permissionStore.hasPermission(_ctx.permissions.CreateDevice))
                    ? (_openBlock(), _createBlock(_component_the_button, {
                        key: 0,
                        icon: ['fas', 'add'],
                        buttonType: "primaryButton",
                        text: "Create",
                        onExecuteMethod: _ctx.toggleCreateModal
                      }, null, 8, ["onExecuteMethod"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                options: _ctx.options,
                "table-data": _ctx.devicePagination.items,
                "table-headers": _ctx.tableHeaders,
                multiselect: true,
                onForceRefresh: _cache[13] || (_cache[13] = ($event: any) => (_ctx.getAllDevices())),
                onOpenDataModal: _ctx.displayData,
                onSelectedRows: _ctx.getSelectedRow
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onOpenDataModal", "onSelectedRows"]),
              _createVNode(_component_pagination, {
                "pagination-data": _ctx.devicePagination,
                onPageChange: _ctx.handlePageChange,
                onPageSizeChange: _ctx.handlePageSizeChange
              }, null, 8, ["pagination-data", "onPageChange", "onPageSizeChange"])
            ]))
      ]),
      _: 1
    })
  ], 64))
}