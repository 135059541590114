import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pl-0.5 text-orange-600"
}
const _hoisted_2 = ["checked", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.label !== '' ? 'flex' : 'flex items-center'} w-full py-4 ${
      _ctx.smallText ? 'space-x-2' : 'space-x-4'
    }`)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass([`${_ctx.smallText && 'text-sm'}`, "whitespace-nowrap text-sm font-medium not-italic text-gray-800"])
        }, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      checked: _ctx.modelValue,
      value: _ctx.modelValue,
      class: "h-5 w-5 cursor-pointer border-grey accent-primary",
      type: "checkbox",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).checked)
      ))
    }, null, 40, _hoisted_2)
  ], 2))
}