import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isSendMessageModalOpened,
    "no-padding": true,
    title: _ctx.$t('ResponseMessages.SendMessage'),
    onToggleModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
          }, [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createVNode(_component_input_field, {
                  modelValue: _ctx.message,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
                  label: _ctx.$t('ResponseMessages.Message'),
                  errorMessage: _ctx.v$?.message.$errors[0]?.$message,
                  isError: _ctx.v$?.message?.$error,
                  required: true
                }, null, 8, ["modelValue", "label", "errorMessage", "isError"])
              ]),
              _: 1
            }),
            _createVNode(_component_button_wrapper, {
              text: "Send",
              icon: ['fas', 'paper-plane']
            })
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}