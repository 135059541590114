import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pointer-events-none fixed bottom-0 top-0 z-[201] flex max-h-full w-full items-start justify-center px-[5vw] py-[5vh]" }
const _hoisted_2 = { class: "max-h-[calc(95vh)] flex-[0_0_27.5rem]" }
const _hoisted_3 = { class: "flex flex-[1_1_0%] flex-col overflow-hidden" }
const _hoisted_4 = { class: "sticky top-0 z-[150] flex items-center justify-between bg-lightgray px-6 py-6" }
const _hoisted_5 = { class: "flex w-full items-center justify-between" }
const _hoisted_6 = { class: "max-w-[100ch] truncate text-xl font-bold" }
const _hoisted_7 = {
  key: 0,
  class: "text-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_icon = _resolveComponent("x-icon")!
  const _component_on_click_outside = _resolveComponent("on-click-outside")!

  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      (_ctx.isDisplayed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([`fixed inset-0 ${_ctx.haveModal ? 'z-[251]' : 'z-[250]'} !z-[1300]`, "bg-[rgba(0,0,0,0.60)]"])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                ref: "target",
                class: _normalizeClass(`modal pointer-events-auto relative block max-h-[calc(95vh)] w-[95%] max-w-3xl sm:w-[75%] ${
            _ctx.isFullScreenModal ? 'w-[95%] max-w-[1600px] sm:w-[95%]' : ''
          } overflow-y-auto bg-white`)
              }, [
                _createVNode(_component_on_click_outside, {
                  onTrigger: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.removeOutsideClick && _ctx.$emit('toggle-modal')))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.title), 1),
                            (_ctx.dateTime)
                              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.formatDate(_ctx.dateTime)), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _createVNode(_component_x_icon, {
                            class: "h-4 w-4 cursor-pointer hover:text-error",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick()))
                          })
                        ]),
                        _createElementVNode("div", {
                          class: _normalizeClass(`modal-overflow max-h-[calc(95vh_-_6rem)] overflow-y-auto ${
                    _ctx.noPadding ? 'py-0' : 'py-6'
                  }`)
                        }, [
                          _renderSlot(_ctx.$slots, "default")
                        ], 2)
                      ])
                    ])
                  ]),
                  _: 3
                })
              ], 2)
            ])
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}