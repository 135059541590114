<template>
  <modal-component
    :is-displayed="isAssignModalOpened"
    :no-padding="true"
    :title="$t('Sensors.AssignTitle', { mac: sensor.mac })"
    @toggle-modal="$emit('toggle-modal')"
  >
    <override-confirm
      v-if="isOverrideModalOpened"
      :is-override-modal-opened="isOverrideModalOpened"
      @toggle-modal="isOverrideModalOpened = false"
      @override-action="handleOverride"
    />
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Sensors.Space") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isParkingSpaceListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="assign.spaceId"
                :class="`mt-1 w-full`"
                :options="parkingSpaces"
                :reduce="(space : any) => space.id"
                label="name"
              ></v-select>
            </div>
          </div>

          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("Sensors.ParkingSpotType") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader
                v-if="isParkingSpotTypesListLoading"
                height="h-8"
                width="w-8"
              />
              <v-select
                v-else
                v-model="assign.spotTypeId"
                :class="`mt-1 w-full`"
                :options="parkingSpotTypes"
                :reduce="(space : any) => space.id"
                label="name"
              ></v-select>
            </div>
          </div>
          <input-field
            v-model="assign.index"
            :label="$t('Sensors.Index')"
            type="number"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import TheButton from "@/core/Components/TheButton.vue";
import MapLocationSelector from "@/core/Components/MapLocationSelector.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import Grid from "@/core/Components/Grid.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { ParkingSpace } from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import ParkingSpaceService from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceService";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import {
  AssignParkingSpot,
  OverrideParkingSpot,
  ParkingSpotType,
  ParkingSpot,
} from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import OverrideConfirm from "@/modules/Parkom/Submodules/ParkingSpots/Components/OverrideConfirm.vue";
import SensorService from "@/modules/Parkom/Submodules/Sensors/Services/SensorService";
import { Sensor } from "@/modules/Parkom/Submodules/Sensors/Services/SensorTypes";

export default defineComponent({
  components: {
    Grid,
    CheckboxInput,
    MapLocationSelector,
    ButtonWrapper,
    InputField,
    FormDivider,
    ModalComponent,
    FormWrapper,
    VSelect,
    SmallLoader,
    TheButton,
    OverrideConfirm,
  },
  props: {
    isAssignModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
    sensor: {
      type: Object as PropType<Sensor>,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      parkingSpaceService: new ParkingSpaceService(),
      parkingSpotService: new ParkingSpotService(),
      sensorService: new SensorService(),
      parkingSpaces: [] as ParkingSpace[],
      parkingSpots: [] as ParkingSpot[],
      isParkingSpaceListLoading: false,
      isParkingSpotListLoading: false,
      isMapLocationModalOpened: false,
      isParkingSpotTypesListLoading: false,
      isOverrideModalOpened: false,
      isSubmitLoading: false,
      toast: useToast(),
      parkingSpotTypes: [] as ParkingSpotType[],
      assign: {
        spaceId: null,
        mac: this.sensor.mac,
        index: 0,
        spotTypeId: null,
      } as AssignParkingSpot | OverrideParkingSpot,
    };
  },
  async created() {
    await Promise.allSettled([this.getParkingSpaces(), this.getParkingSpots(), this.getParkingSpotTypes()]);
  },

  watch: {
    "assign.spaceId"(spaceId: number) {
      const space = this.parkingSpaces.find((space) => space.id === spaceId);
      if (!space) {
        return;
      }

      this.assign.index =
        this.parkingSpots.filter((spot) => spot.spaceId === spaceId)[0].index +
        1;
    },
  },
  methods: {
    async handleSubmit() {
      this.isSubmitLoading = true;
      try {
        this.assign.index = Number(this.assign.index);
        const response = await this.parkingSpotService.assignParkingSpot(
          this.assign
        );
        if (response === -1) {
          this.isOverrideModalOpened = true;
          return;
        }
        this.$emit("toggle-modal");
        this.toast.success("Parking spot assigned successfully");
        await this.refreshData();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async handleOverride() {
      this.isSubmitLoading = true;
      try {
        this.isOverrideModalOpened = false;
        await this.parkingSpotService.overrideParkingSpot(
          this.assign as OverrideParkingSpot
        );
        this.$emit("toggle-modal");
        this.toast.success("Parking spot assigned successfully");
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getParkingSpaces() {
      this.isParkingSpaceListLoading = true;
      try {
        this.parkingSpaces = await this.parkingSpaceService.getParkingSpaces({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isParkingSpaceListLoading = false;
      }
    },
    async getParkingSpots() {
      this.isParkingSpotListLoading = true;
      try {
        this.parkingSpots = await this.parkingSpotService.getParkingSpots({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isParkingSpotListLoading = false;
      }
    },
    async getParkingSpotTypes() {
      this.isParkingSpotTypesListLoading = true;
      try {
        this.parkingSpotTypes =
          await this.parkingSpotService.getParkingSpotTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isParkingSpotTypesListLoading = false;
      }
    },
    toggleMapLocationModal() {
      this.isMapLocationModalOpened = !this.isMapLocationModalOpened;
    },
  },
});
</script>
