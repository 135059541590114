<template>
  <modal-component
    :is-displayed="isCreateFormModalOpened"
    :no-padding="true"
    :title="$t('GlobalAuthorization.CreateTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true" :is-loading="isLoading">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("GlobalAuthorization.User") }}</label
            >
            <div class="flex items-center justify-center">
              <v-select
                v-model="resource.toUserId"
                :class="`mt-1 w-full`"
                :options="users"
                :reduce="(user : any) => user.id"
                label="email"
                @search="handleUserSearch"
                :clearable="false"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("GlobalAuthorization.ResourceType") }}</label
            >
            <div class="flex items-center justify-center">
              <v-select
                v-model="resource.resourceTypeId"
                :class="`mt-1 w-full`"
                :options="resourceTypes"
                :reduce="(resourceType : any) => resourceType.id"
                :selectable="(resourceType : any) => resourceType.id != 2"
                label="name"
                :clearable="false"
              >
              </v-select>
            </div>
          </div>
          <div
            v-if="resource.resourceTypeId === 2"
            class="relative items-center py-4"
          >
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("GlobalAuthorization.Asset") }}</label
            >
            <div class="flex items-center justify-center">
              <v-select
                v-model="resource.onResourceId"
                :class="`mt-1 w-full`"
                :get-option-label="(device: any) => device.assignment?.name"
                :options="deviceAssignment"
                :reduce="(device : any) => device.device?.id"
                :clearable="false"
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("GlobalAuthorization.Role") }}</label
            >
            <div class="flex items-center justify-center">
              <v-select
                v-model="resource.roleId"
                :class="`mt-1 w-full`"
                :options="roles"
                :reduce="(device : any) => device.id"
                label="name"
                :clearable="false"
              ></v-select>
            </div>
          </div>
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import VSelect from "vue-select";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import RoleService from "@/modules/AdminDashboard/Submodules/Authorization/Services/RoleService";
import UserService from "@/modules/Users/Services/UserService";
import AuthorizationService from "@/modules/AdminDashboard/Submodules/Authorization/Services/AuthorizationService";
import AssignmentService from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import { useUserStore } from "@/core/Store/userStore";
import { CreateResource } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/AuthorizationTypes";

export default defineComponent({
  components: {
    ModalComponent,
    FormWrapper,
    FormDivider,
    VSelect,
    ButtonWrapper,
  },
  props: {
    isCreateFormModalOpened: Boolean,
    refreshData: {
      type: Function,
      required: true,
    },
  },
  emits: ["toggle-modal"],
  data() {
    return {
      isLoading: false,
      isSubmitLoading: false,
      toast: useToast(),
      roles: [] as any,
      users: [] as any,
      resourceTypes: [] as any,
      deviceAssignment: [] as any,
      roleService: new RoleService(),
      userService: new UserService(),
      authorizationService: new AuthorizationService(),
      deviceAssignmentService: new AssignmentService(),
      userStore: useUserStore(),
      searchTimeoutId: undefined as any,
      resource: {
        toCompanyId: null,
        toUserId: null,
        onCompanyId: null,
        resourceTypeId: 1,
        onResourceId: null,
        roleId: 1,
        permissionIds: null,
        policyId: null,
        companyId: null,
      } as CreateResource,
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.getRoles(),
        this.getUsers(),
        //this.getDeviceAssignments(),
        this.getResourceTypes(),
      ]);
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async handleUserSearch(searchUserText: string) {
      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }

      this.searchTimeoutId = setTimeout(async () => {
        try {
          const response = await this.userService.getAllUsers(
            1,
            1000,
            true,
            searchUserText
          );
          this.users = response.items;
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }, 800);
    },
    async handleSubmit() {
      if (this.resource.onCompanyId !== null) {
        this.resource.onResourceId = this.resource.onCompanyId;
      }

      this.isSubmitLoading = true;
      try {
        await this.authorizationService.createResource(this.resource);
        this.toast.success(this.$t("General.CreatedSuccessfully"));
        this.$emit("toggle-modal");
        await this.refreshData(this.resource.resourceTypeId);
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },
    async getRoles() {
      try {
        this.roles = await this.roleService.getAllRoles({
          pageNumber: 1,
          pageSize: 100000,
          allRoles: true,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getUsers() {
      try {
        const response = await this.userService.getAllUsers(1, 1000, true);
        this.users = response.items;
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getDeviceAssignments() {
      try {
        this.deviceAssignment =
          await this.deviceAssignmentService.getAllDeviceAssignments({
            pageNumber: 1,
            pageSize: 100000,
            typeOfAssignment: "Company",
          });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
    async getResourceTypes() {
      try {
        this.resourceTypes = await this.authorizationService.getResourceTypes(
          true
        );
        /* const companyResource = this.resourceTypes.find(
          (resource) => resource.name === "Company"
        );
        this.resource.resourceTypeId = companyResource?.id || null; */
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      }
    },
  },
});
</script>
<style scoped></style>
