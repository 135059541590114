import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4aab8b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white p-2 shadow-md" }
const _hoisted_2 = { class: "w-300" }
const _hoisted_3 = {
  class: "px-2 py-2 text-left font-bold",
  style: {"width":"100px","white-space":"normal"}
}
const _hoisted_4 = {
  class: "px-2 py-2 text-left",
  style: {"width":"200px","white-space":"normal"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAssetDetails(_ctx.asset.data), (assetDetail) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: assetDetail.key
          }, [
            _createElementVNode("td", _hoisted_3, _toDisplayString(assetDetail.key), 1),
            _createElementVNode("td", _hoisted_4, _toDisplayString(assetDetail.value), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}