<template>
  <modal-component
    :is-displayed="isEditModalOpened"
    :no-padding="true"
    :title="$t('DeviceTypes.EditTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <form-wrapper :is-form-in-modal="true">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="formData.name"
            :label="$t('DeviceTypes.Name')"
            :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
            :isError="v$?.formData['name']?.$error"
            :required="true"
          />
          <input-field
            v-model="formData.productionPN"
            :label="$t('DeviceTypes.ProductionPN')"
          />
          <input-field
            v-model="formData.customerPN"
            :label="$t('DeviceTypes.CustomerPN')"
          />

          <div class="relative items-center py-4">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >{{ $t("DeviceTypes.SerialNumberType") }}</label
            >
            <div class="flex items-center justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="formData.serialNumberTypeId"
                :class="`mt-1 w-full`"
                :options="serialNumberTypes"
                :reduce="(serialNumberType : any) => serialNumberType.id"
                label="name"
              ></v-select>
            </div>
          </div>

          <checkbox-input
            v-model="formData.isProductionEnabled"
            :label="$t('DeviceTypes.ProductionEnabled')"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import DeviceTypeService, {
  DeviceType,
  EditeDeviceType,
  SerialNumberType,
} from "@/modules/DeviceTypes/Services/DeviceTypeService";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import VSelect from "vue-select";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { useToast } from "vue-toastification";
import {
  CheckObjectsForPatch,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
import { maxLength, required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";

export default defineComponent({
  components: {
    CheckboxInput,
    SmallLoader,
    VSelect,
    ButtonWrapper,
    InputField,
    FormDivider,
    FormWrapper,
    ModalComponent,
  },
  props: {
    isEditModalOpened: Boolean,
    deviceType: {
      type: Object as PropType<DeviceType>,
      required: true,
    },
    refreshData: Function as PropType<() => Promise<void>>,
  },
  emits: ["toggle-modal"],

  data() {
    return {
      deviceTypeService: new DeviceTypeService(),
      serialNumberTypes: [] as SerialNumberType[],
      initialObject: {} as DeviceType,
      toast: useToast(),
      isSubmitLoading: false,
      isLoading: false,
      formData: {
        id: 0,
        name: "",
        productionPN: "" as string | null,
        customerPN: "" as string | null,
        isProductionEnabled: false,
        serialNumberTypeId: null as number | null,
        parentDeviceTypeId: null as number | null,
      } as EditeDeviceType,
      v$: useValidate() as any,
      validationErrors: [] as any,
    };
  },

  async created() {
    this.initialObject = Object.assign({}, this.deviceType);
    delete this.initialObject.serialNumberType;
    delete this.initialObject.metadata;

    this.formData.id = this.deviceType.id;
    this.formData.name = this.deviceType.name;
    this.formData.productionPN = this.deviceType.productionPN || "";
    this.formData.customerPN = this.deviceType.customerPN || "";
    this.formData.serialNumberTypeId = this.deviceType.serialNumberTypeId;
    this.formData.isProductionEnabled = this.deviceType.isProductionEnabled;

    await this.getSerialNumberTypes();
  },
  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          await this.deviceTypeService.editDeviceType({
            id: this.formData.id,
            deviceType: await CheckObjectsForPatch({
              initialObject: this.initialObject,
              editedObject: this.formData,
            }),
          });
          this.toast.success(this.$t("General.EditedSuccessfully"));
          this.$emit("toggle-modal");
          await this.refreshData?.();
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    async getSerialNumberTypes() {
      this.isLoading = true;
      try {
        this.serialNumberTypes =
          await this.deviceTypeService.getSerialNumberTypes();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>

<style scoped></style>
