export enum DeviceTypesEnum {
  AgristickTS1000 = 1,
  RFIDReader = 2,
  SpotiTrack = 4,
  MinewS3 = 5,
  AgristickHumidTemp = 6,
  AgristickGPS = 13,
  AgristickTS1000H = 14,
  SpotiTrackPlug = 25,
  SpotiTrackAkku = 26,
  WaterMeter = 28,
  WeatherStation = 29,
  ICD = 34,
  WasteBin = 44,
  StartRouteMarker = 9998,
  EndRouteMarker = 9999,
}

export enum ErrorsEnum {
  UserDoesntExist = "email_does_not_exist",
}

export enum DeviceTemplatesEnum {
  Humidity = "HumidityTemplate",
  Agristick = "AgristickTemplate",
  WasteBin = "WasteBinTemplate",
  WaterMeter = "WaterMeterTemplate",
  WeatherStation = "WeatherStationTemplate",
}

export enum PrecipitationsEnum {
  None = 0,
  Rain = 1,
  Snow = 2,
  Solid = 4,
}

export enum PointsOfCompass {
  North = 0,
  East = 90,
  South = 180,
  West = 270,
}

export enum IntegrationTypesTemplate {
  HTTP = "HTTPCreate",
  WS = "WSCreate",
  UDP = "UDPCreate",
  MQTT = "MQTTCreate",
  HTTPEdit = "HTTPEdit",
  WSEdit = "WSEdit",
  UDPEdit = "UDPEdit",
  MQTTEdit = "MQTTEdit",
  RuleEngine = "RuleEngineCreate",
  RuleEngineEdit = "RuleEngineEdit",
  Translation = "TranslationCreate",
  TranslationEdit = "TranslationEdit",
  Invocation = "InvocationCreate",
  InvocationEdit = "InvocationEdit",
}

export enum IntegrationTypes {
  MQTT = 6,
}

export enum MetadataTypes {
  NetworkProfile = 1,
  Platform = 2,
  ProductionNetworkPlatform = 3,
  SensorLabel = 4,
  LicencePlate = 5,
  ParkingTriggerTime = 7,
}

export enum NotificationShareResponseTypes {
  Approve = 1,
  Reject = 2,
  Revoke = 3,
}

export enum DataSendingPeriod {}

export enum ThemeEnum {
  default = "default",
  sick = "sick",
  ht = "ht",
}

export enum Permissions {
  AuthView = 10,
  ViewDevice = 20,
  ViewDeviceType = 25,
  ViewWorkOrder = 30,
  ViewGroup = 40,
  ViewIntegration = 41,
  ViewCompanyUser = 42,
  ViewAssignment = 50,
  ViewCompany = 60,
  ViewDeviceAction = 70,
  ViewFirmwareUpdate = 411,
  ViewResponseMessage = 421,
  AuthCreate = 110,
  CreateDevice = 120,
  CreateDeviceType = 125,
  CreateWorkOrder = 130,
  CreateGroup = 140,
  CreateIntegration = 141,
  CreateCompanyUser = 142,
  CreateAssignment = 150,
  CreateCompany = 160,
  CreateDeviceAction = 170,
  CreateFirmwareUpdate = 412,
  CreateResponseMessage = 422,
  AuthEdit = 210,
  EditDevice = 220,
  EditDeviceType = 225,
  EditWorkOrder = 230,
  EditGroup = 240,
  EditIntegration = 241,
  EditAssignment = 250,
  EditCompany = 260,
  EditDeviceAction = 270,
  EditFirmwareUpdate = 413,
  EditResponseMessage = 423,
  AuthDelete = 310,
  DeleteDevice = 320,
  DeleteDeviceType = 325,
  DeleteWorkOrder = 330,
  DeleteGroup = 340,
  DeleteIntegration = 341,
  DeleteCompanyUser = 342,
  DeleteAssignment = 350,
  DeleteCompany = 360,
  DeleteDeviceAction = 370,
  DeleteUser = 380,
  Share = 400,
  Open = 401,
  RevokeShare = 402,
  ConfigureDevice = 410,
  DeleteFirmwareUpdate = 414,
  DeleteResponseMessage = 424,
  CommitAssignmentDeviceAction = 470,
  GlobalAuthView = 1000,
  SensorHealthView = 10000,
}
