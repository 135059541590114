<template>
  <div :class="themeStore.themeClass">
    <HeaderBar
      v-if="!isMaintenance"
      @toggle-sidebar="sidebarStore.toggleSidebar"
    />
    <div class="flex items-center">
      <SidebarMenu
        v-if="!isMaintenance"
        :is-sidebar-opened="sidebarStore.isSidebarOpened"
        :sidebar-store="sidebarStore"
      />
      <router-view />
    </div>
  </div>
</template>
<script lang="ts">
import SidebarMenu from "./Components/SidebarMenu.vue";
import HeaderBar from "./Components/HeaderBar.vue";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
import { defineComponent, inject } from "vue";
import MaintenanceService from "@/modules/Maintenance/Services/MaintenanceService";
import { useSidebarStore } from "./Store/index";
import { useThemeStore } from "./Store/themeStore";
import { ThemeEnum } from "./Services/Enums";
import { usePermissionStore } from "@/core/Store/permissionStore";
import { useModuleToggleStore } from "@/core/Store/moduleToggleStore";
import DashboardService from "@/modules/Parkom/Services/DashboardService";
import { useToast } from "vue-toastification";
import { useServiceStore } from "@/core/Store/serviceStore";
import { useUserStore } from "@/core/Store/userStore";
import { useParkingSpaceFilterStore } from "@/core/Store/useParkingSpaceFilterStore";
import { useFloorStore } from "@/core/Store/floorStore";

export default defineComponent({
  components: {
    HeaderBar,
    SidebarMenu,
  },
  data() {
    return {
      config: inject("config") as any,
      isMaintenance: false as boolean,
      maintenanceService: new MaintenanceService(),
      dashboardService: new DashboardService(),
      moduleStore: useModuleToggleStore(),
      sidebarStore: useSidebarStore(),
      themeStore: useThemeStore(),
      permissionStore: usePermissionStore(),
      serviceStore: useServiceStore(),
      userStore: useUserStore(),
      toast: useToast(),
      floorStore: useFloorStore(),
      parkingSpaceFilterStore: useParkingSpaceFilterStore(),
    };
  },
  watch: {
    async $route(to, from) {
      try {
        this.isMaintenance =
          await this.maintenanceService.getMaintenanceStatus();
      } catch (error) {
        console.log("error:", error);
      }

      if (to.name === "maintenance" && !this.isMaintenance) {
        await (this as any).$router.push(from.path);
      }

      if (this.isMaintenance) {
        await (this as any).$router.push({ name: "maintenance" });
      }

      this.sidebarStore.isSidebarOpened = to.name !== "mapView";
    },
  },
  async created() {
    await this.permissionStore.getCurrentCompanyPermissions();
    this.serviceStore.getServiceId(window.location.host);

    localStorage.setItem(
      "activeCompanyId",
      String(this.userStore.activeCompanyId)
    );

    if (!localStorage.getItem("is_development")) {
      localStorage.setItem("is_development", "false");
      this.moduleStore.isDevelopment = false;
    } else {
      this.moduleStore.isDevelopment =
        localStorage.getItem("is_development") == "true";
    }

    if (this.config.IS_PARKOM == "true") {
      await this.parkingSpaceFilterStore.getParkingSpaceTreeList();
    } else {
      await this.floorStore.getFloors();
    }

    //Set language from database
    this.$i18n.locale = this.userStore.languageCode;

    try {
      this.isMaintenance = await this.maintenanceService.getMaintenanceStatus();

      if (this.isMaintenance) {
        await (this as any).$router.push({ name: "maintenance" });
      }
    } catch (error: any) {
      console.log(error);
    }

    // Removes logo from local storage, if not removed
    // public path is wrong and image is not displayed properly
    localStorage.removeItem("theme");
  },
  async mounted() {
    const forceWebLocalStorageValue = localStorage.getItem("force_web");

    const urlSearchParams = window.location.search;
    const isWebRedirectDisabledForMobile = urlSearchParams
      .replace("?", "")
      .split("=")[1];

    if (
      isWebRedirectDisabledForMobile === undefined &&
      forceWebLocalStorageValue === null
    ) {
      localStorage.setItem("force_web", "false");
    } else if (isWebRedirectDisabledForMobile !== undefined) {
      localStorage.setItem("force_web", isWebRedirectDisabledForMobile);
    }

    //! Redirect if user comes from phone or tablet
    //@ts-ignore
    // if (this.$isMobile() && this.checkIfWebRedirectIsEnabled()) {
    //   window.location.href = `https://mobile.spotium.hr/?redirect=${window.location.origin}`;
    // }

    this.themeStore.themeClass = ThemeEnum.default;

    if (
      this.config.THEME === "sick" ||
      window.location.hostname.includes("sick")
    ) {
      this.themeStore.themeClass = ThemeEnum.sick;
    }

    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    favicon.href = `${process.env.VUE_APP_PUBLIC_PATH}${this.config.FAVICON}`;
  },
  methods: {
    checkIfWebRedirectIsEnabled() {
      const isWebRedirectDisabled =
        localStorage.getItem("force_web") || "false";
      return isWebRedirectDisabled === "false";
    },
  },
});
</script>
