import {
  CreateParkingSpace,
  ParkingSpace,
  UpdateParkingSpace,
} from "@/modules/Parkom/Submodules/ParkingSpaces/Services/ParkingSpaceTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class ParkingSpaceService {
  async getParkingSpaces({
    pageNumber = 1,
    pageSize = 100000,
  }: {
    pageNumber: number;
    pageSize: number;
  }): Promise<ParkingSpace[]> {
    const url = GenerateUrl({
      path: "/api/spaces",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
      },
    });

    const response = await ApiService.get(url);
    return this._mapParkingSpaces(response.data.items);
  }

  async getParkingSpaceByIdentifier(id: number): Promise<ParkingSpace> {
    const url = GenerateUrl({
      path: `/api/spaces/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.get(url);
    return this._mapParkingSpace(response.data);
  }

  async getParkingSpaceLastIndex(id: number): Promise<number> {
    const url = GenerateUrl({
      path: `/api/spaces/lastspotindex/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    const response = await ApiService.get(url);
    return response.data;
  }

  async createParkingSpace(parkingSpace: CreateParkingSpace): Promise<any> {
    const url = GenerateUrl({
      path: "/api/spaces",
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.post(url, parkingSpace);
  }

  async updateParkingSpace(parkingSpace: UpdateParkingSpace): Promise<any> {
    const url = GenerateUrl({
      path: `/api/spaces/${parkingSpace.id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.put(url, parkingSpace);
  }

  async deleteParkingSpace(id: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/spaces/${id}`,
      prefixPath: ConfigurationService.configData.PARKOM_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  private _mapParkingSpaces(response: ParkingSpace[]): ParkingSpace[] {
    return response.map((parkingSpace: ParkingSpace) =>
      this._mapParkingSpace(parkingSpace)
    );
  }

  private _mapParkingSpace(parkingSpace: ParkingSpace): ParkingSpace {
    return {
      id: parkingSpace.id,
      name: parkingSpace.name,
      occupied: parkingSpace.occupied,
      unoccupied: parkingSpace.unoccupied,
      city: parkingSpace.city,
      street: parkingSpace.street,
      streetNumber: parkingSpace.streetNumber,
      latitude: parkingSpace.latitude,
      longitude: parkingSpace.longitude,
      navigable: parkingSpace.navigable,
      visible: parkingSpace.visible,
      active: parkingSpace.active,
    };
  }
}
