<template>
  <form @submit.prevent="handleSubmit">
    <form-divider>
      <input-field
        v-model="formData.name"
        :errorMessage="v$?.formData['name']?.$errors[0]?.$message"
        :isError="v$?.formData['name']?.$error"
        :required="true"
        label="Name"
      />
      <input-field
        v-model="formData.config.ip"
        :errorMessage="v$?.formData?.config['ip']?.$errors[0]?.$message"
        :isError="v$?.formData?.config['ip']?.$error"
        :required="true"
        label="IP address"
      />
      <input-field
        v-model="formData.config.port"
        :errorMessage="v$?.formData?.config['port']?.$errors[0]?.$message"
        :isError="v$?.formData?.config['port']?.$error"
        :required="true"
        label="Port"
      />
      <multi-json-input
        @input="handleTopicChange"
        :topics="formData.config.topicToChannel"
      />
      <tags-input
        :label="$t('Integrations.Channels')"
        :tags="formData.config.channels"
        @on-tag-mutate="(tags) => (formData.config.channels = tags)"
      />
      <input-field
        v-model="formData.executionOrder"
        :label="$t('Integrations.ExecutionOrder')"
        type="number"
        :errorMessage="v$?.formData['executionOrder']?.$errors[0]?.$message"
        :isError="v$?.formData['executionOrder']?.$error"
      />
      <checkbox-input
        v-model="formData.isActive"
        :is-checked="formData.isActive"
        label="Active"
      />
    </form-divider>
    <button-wrapper :is-submit-loading="isSubmitLoading" />
  </form>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import InputField from "../../../core/Components/FormElements/InputField.vue";
import TheButton from "@/core/Components/TheButton.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
//@ts-ignore
import VSelect from "vue-select";
import IntegrationService from "../Services/IntegrationService";
import checkIfObjectEmpty, {
  CheckObjectsForPatch,
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import {
  maxLength,
  required,
  helpers,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import { useToast } from "vue-toastification";
import TagsInput from "@/core/Components/FormElements/TagsInput.vue";
import { specialCharactersValidator } from "@/core/Services/CustomValidators";
import MultiJsonInput from "@/core/Components/FormElements/MultiJsonInput.vue";

export default defineComponent({
  components: {
    TagsInput,
    InputField,
    VSelect,
    TheButton,
    CheckboxInput,
    FormDivider,
    ButtonWrapper,
    MultiJsonInput,
  },
  props: ["integrationTypeId", "applicationId", "data", "refreshData"],
  data() {
    return {
      v$: useValidate() as any,
      validationErrors: [] as any,
      integrationService: new IntegrationService(),
      isSubmitLoading: false as boolean,
      toast: useToast(),
      finalForm: [] as { op: string; path: string; value: any }[],
      integrationFromAPI: {},
      formData: {
        id: this.data.id,
        integrationTypeId: this.integrationTypeId,
        name: this.data.name,
        executionOrder: this.data.executionOrder,
        config: {
          ip: this.data?.config?.ip,
          port: this.data?.config?.port,
          topicToChannel: this.data?.config?.topicToChannel,
          channels: this.data?.config?.channels,
        },
        applicationId: this.applicationId,
        isActive: this.data.isActive,
      },
    };
  },

  validations() {
    return {
      formData: {
        name: {
          required,
          maxLength: maxLength(50),
          specialCharacters: specialCharactersValidator,
        },
        executionOrder: {
          minValue: minValue(1),
        },
        config: {
          ip: {
            required,
          },
          port: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(65535),
          },
        },
      },
    };
  },

  watch: {
    applicationId(value) {
      this.formData.applicationId = value;
    },
  },

  created() {
    this.integrationFromAPI = Object.assign({}, this.formData);
  },

  methods: {
    errorMessageFormatter: ErrorMessageFormatter,
    async handleSubmit() {
      this.v$.$validate();
      this.validationErrors = this.v$.$errors;
      if (!this.v$.$error) {
        this.isSubmitLoading = true;
        try {
          if (this.formData.config.channels) {
            this.formData.config.channels = this.formData.config.channels.map(
              (channel: string) => {
                if (typeof channel === "string") {
                  return Number(channel.trim());
                }

                return channel;
              }
            );
          }

          if (
            this.formData.config.channels &&
            this.formData.config.channels.length === 0
          ) {
            this.formData.config.channels = null;
          }

          if (checkIfObjectEmpty(this.formData.config.topicToChannel)) {
            this.formData.config.topicToChannel = null;
          }

          const integrationPatch = await CheckObjectsForPatch({
            initialObject: this.integrationFromAPI,
            editedObject: this.formData,
          });

          await this.integrationService.editIntegration({
            integrationPatch,
            id: this.data.id,
          });

          this.toast.success(this.$t("General.EditedSuccessfully"));
          await this.refreshData();
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        } finally {
          this.isSubmitLoading = false;
        }
      }
    },
    handleTopicChange(value: Record<string, number>) {
      this.formData.config.topicToChannel = value;
    },
  },
});
</script>
<style scoped></style>
