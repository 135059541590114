import {
  DeviceMetadata,
  DeviceMetadataObject,
  CreateDeviceMetadata,
} from "@/modules/Production/Submodules/DeviceMetadata/Services/DeviceMetadataTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export class DeviceMetadataService {
  async getDeviceMetadata(
    pageNumber: number = 1,
    pageSize: number = 10000,
    name?: string
  ): Promise<DeviceMetadataObject> {
    const url = GenerateUrl({
      path: "/api/device-metadata",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        Include: ["MetadataType"],
        PageNumber: pageNumber,
        PageSize: pageSize,
        ...(name && { Name: name }),
      },
    });

    const response = await ApiService.get(url);
    return {
      hasNextPage: response.data.hasNextPage,
      hasPreviousPage: response.data.hasPreviousPage,
      items: this._mapDevicesMetadata(response.data.items),
      pageIndex: response.data.pageIndex,
      totalPages: response.data.totalPages,
      totalCount: response.data.totalCount,
    };
  }

  async getDeviceMetadataWithIdentifier(
    identifier: number
  ): Promise<DeviceMetadata> {
    const url = GenerateUrl({
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      path: `/api/device-metadata/${identifier}`,
    });
    const response = await ApiService.get(url);
    return this._mapDeviceMetadata(response.data);
  }

  async getDeviceMetadataTypes(): Promise<
    { id: number; name: string; configSchema: any | null }[]
  > {
    const url = GenerateUrl({
      path: "/api/device-metadata-types",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: 1,
        PageSize: 100000,
      },
    });

    const response = await ApiService.get(url);

    return response.data.items;
  }

  async createDeviceMetadata(createDeviceMetadata: CreateDeviceMetadata) {
    const url = GenerateUrl({
      path: "/api/device-metadata",
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, createDeviceMetadata);
  }

  async createDeviceMetadataRelation({
    deviceMetadataId,
    deviceIdList,
  }: {
    deviceMetadataId: number;
    deviceIdList: number[];
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/device-metadata/${deviceMetadataId}/relations`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, { deviceIds: deviceIdList });
  }

  async deleteDeviceMetadataRelation({
    deviceMetadataId,
    deviceIdList,
  }: {
    deviceMetadataId: number;
    deviceIdList: number[];
  }) {
    const url = GenerateUrl({
      path: `/api/device-metadata/${deviceMetadataId}/relations`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url, { deviceIds: deviceIdList });
  }

  async updateDeviceMetadata({
    id,
    deviceMetadata,
  }: {
    id: number;
    deviceMetadata: PatchObject[];
  }) {
    const url = GenerateUrl({
      path: `/api/device-metadata/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.patch(url, deviceMetadata);
  }

  async deleteDeviceMetadata(id: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/device-metadata/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  private _mapDevicesMetadata(response: DeviceMetadata[]): DeviceMetadata[] {
    return response.map((deviceMetadata) =>
      this._mapDeviceMetadata(deviceMetadata)
    );
  }

  private _mapDeviceMetadata(deviceMetadata: DeviceMetadata): DeviceMetadata {
    return {
      id: deviceMetadata.id,
      name: deviceMetadata.name,
      metadataTypeId: deviceMetadata.metadataTypeId,
      metadataType: deviceMetadata.metadataType,
      data: deviceMetadata.data,
    };
  }
}
