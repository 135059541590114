<template>
  <div class="flex h-full w-full items-center justify-center">
    <form-wrapper :is-form-in-modal="isFormInModal">
      <form @submit.prevent="handleSubmit">
        <form-divider>
          <input-field
            v-model="resource.assignmentRequest.name"
            label="Name"
            required="true"
          />
          <input-field
            v-model="resource.deviceRequest.data.shortName"
            label="Short name"
            required="true"
          />
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Device</label
            >
            <div class="flex w-full justify-center">
              <small-loader v-if="isLoading" height="h-8" width="w-8" />
              <v-select
                v-else
                v-model="selectedAssignment"
                :get-option-label="
                  (deviceAssignment: any) => deviceAssignment.name
                "
                :options="mappedTdceDevices"
                class="w-full"
                :clearable=false
              ></v-select>
            </div>
          </div>
          <div class="relative items-center py-3">
            <label
              class="whitespace-nowrap text-sm font-medium not-italic text-gray-800"
              >Type</label
            >
            <v-select
              v-model="resource.deviceRequest.data.type"
              :options="resourceTypes"
              :reduce="(type : any) => type.id"
              class="mt-1"
              label="name"
              :clearable=false
            ></v-select>
          </div>
          <Vue3JsonEditor
            v-model="resource.deviceRequest.data.configuration"
            mode="code"
            @json-change="onConfigurationChange"
          />
          <checkbox-input
            v-model="resource.deviceRequest.data.active"
            label="Active"
          />
        </form-divider>
        <button-wrapper :is-submit-loading="isSubmitLoading" />
      </form>
    </form-wrapper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import Loader from "@/core/Components/Loader.vue";
import FormWrapper from "@/core/Components/FormElements/FormWrapper.vue";
import FormDivider from "@/core/Components/FormElements/FormDivider.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import VSelect from "vue-select";
import FormGroup from "@/core/Components/FormElements/FormGroup.vue";
import CheckboxInput from "@/core/Components/FormElements/CheckboxInput.vue";
//@ts-ignore
import JsonEditorVue from "json-editor-vue";
import ButtonWrapper from "@/core/Components/FormElements/ButtonWrapper.vue";
import ResourceTypeService, {
  ResourceType,
} from "../Services/ResourceTypeService";
import checkIfObjectEmpty, {
  ErrorMessageFormatter,
} from "@/core/Services/GlobalFunctions";
import { Vue3JsonEditor } from "vue3-json-editor";
import AccessManagerService from "@/modules/AccessManager/Services/AccessManagerService";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { useUserStore } from "@/core/Store/userStore";
import ApplicationService, {
  Application,
} from "@/modules/Applications/Services/ApplicationService";
import ResourceService from "@/modules/AccessManager/SubModules/Resources/Services/ResourceService";
import { useToast } from "vue-toastification";
import DeviceAssignmentService, {
  CreateAssignment,
  DeviceAssignment,
} from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";

export default defineComponent({
  components: {
    MainSection,
    Loader,
    FormWrapper,
    FormDivider,
    InputField,
    VSelect,
    FormGroup,
    CheckboxInput,
    JsonEditorVue,
    ButtonWrapper,
    Vue3JsonEditor,
    SmallLoader,
  },

  props: {
    isFormInModal: Boolean,
    refreshData: Function,
  },

  data() {
    return {
      selectedAssignment: {} as {
        deviceId: number | null;
        assignmentId: number | null;
        name?: string;
      },
      mappedTdceDevices: {},
      isLoading: false,
      isSubmitLoading: false,
      resourceService: new ResourceService(),
      resourceTypeService: new ResourceTypeService(),
      accessManagerService: new AccessManagerService(),
      deviceAssignmentService: new DeviceAssignmentService(),
      resourceTypes: [
        { id: 1, name: "Ramp" },
        { id: 2, name: "Gate" },
      ] as ResourceType[],
      tdceDevices: [] as DeviceAssignment[],
      isDeviceListLoaded: false,
      toast: useToast(),
      userStore: useUserStore(),
      applicationService: new ApplicationService(),
      applications: [] as Application[],
      resource: {
        assignmentRequest: {
          applicationId: 0,
          parentAssignmentId: 0,
          name: "",
        },
        deviceRequest: {
          deviceTypeId: Number(
            process.env.VUE_APP_ACCESS_MANAGER_RESOURCE_TYPE_ID
          ),
          data: {
            name: "",
            shortName: "",
            uuid: "",
            type: 1,
            configuration: {},
            deviceId: 0,
            active: true,
            topic: "",
          },
        },
      } as CreateAssignment,
    };
  },
  async created() {
    this.isLoading = true;
    try {
      this.tdceDevices = await this.accessManagerService.getAllTdceDevices();
      this.mappedTdceDevices = this.tdceDevices.map((device: any) => ({
        deviceId: device.deviceId,
        assignmentId: device.assignmentId,
        name: device.assignment?.name,
      }));

      this.selectedAssignment.name = this.tdceDevices[0].assignment?.name;
      this.selectedAssignment.deviceId = this.tdceDevices[0].deviceId;
      this.selectedAssignment.assignmentId = this.tdceDevices[0].assignmentId;

      const applicationObject =
        await this.applicationService.getAllApplications(1, 10000);
      this.applications = applicationObject.items;

      if (!this.tdceDevices.length) {
        this.toast.error("No TDCE devices found!");
        return;
      }

      if (!this.resource.deviceRequest) {
        this.toast.error("No device found!");
        return;
      }

      this.resource.assignmentRequest.applicationId = this.applications[0].id;
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    async handleSubmit() {
      if (!this.resource.deviceRequest) {
        this.toast.error("No device found!");
        return;
      }
      this.resource.deviceRequest.data.deviceId =
        this.selectedAssignment.deviceId;
      this.resource.assignmentRequest.parentAssignmentId =
        this.selectedAssignment.assignmentId;
      this.resource.deviceRequest.data.name =
        this.resource.assignmentRequest.name;
      this.resource.deviceRequest.data.topic = `${process.env.VUE_APP_MQTT_USERNAME}/imei`;
      this.resource.deviceRequest.data.action = Number(
        process.env.VUE_APP_CREATE_ACTION
      );

      this.isSubmitLoading = true;
      try {
        //@ts-ignore
        if (checkIfObjectEmpty(this.resource.configuration)) {
          this.resource.deviceRequest.data.configuration = {};
        }
        const createdResource = await this.resourceService.createResource(
          this.resource,
          this.userStore.userId
        );

        this.resource.deviceRequest.data.uuid =
          createdResource.device?.deviceUuid;

        /* try {
          await this.resourceService.updateResourceData(this.resource);
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        } */

        try {
          const parentTdceDevice = this.tdceDevices.filter(
            (tdceDevice) =>
              tdceDevice.assignmentId ===
              this.resource.assignmentRequest.parentAssignmentId
          )[0];

          if (!parentTdceDevice.device) {
            this.toast.error("No device found!");
            return;
          }

          if (!parentTdceDevice.assignment) {
            this.toast.error("No assignment found!");
            return;
          }

          await this.resourceService.addResourceToStorageForSync(
            parentTdceDevice.device.deviceUuid,
            parentTdceDevice.assignment.dataUuid,
            this.resource.deviceRequest.data
          );
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        }

        this.toast.success(this.$t("General.CreatedSuccessfully"));
        await this.refreshData?.();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isSubmitLoading = false;
      }
    },

    onConfigurationChange(configuration: any) {
      if (!this.resource.deviceRequest) {
        this.toast.error("No device found!");
        return;
      }
      this.resource.deviceRequest.data.configuration = configuration;
    },
  },
});
</script>
<style scoped></style>
