<template>
  <div>
    <modal
      :is-displayed="isDeleting"
      :remove-outside-click="true"
      :title="`${
        deviceRemove ? $t('Delete.RemoveDevice') : $t('Delete.Title')
      }`"
      :have-modal="haveModal"
      @toggle-modal="$emit('toggle-modal')"
    >
      <div class="px-6">
        <div class="flex w-full flex-col items-center space-y-4">
          <div
            class="flex h-24 w-24 items-center justify-center rounded-full bg-errorlight"
          >
            <font-awesome-icon
              :icon="['fas', 'triangle-exclamation']"
              class="h-10 w-10 text-error"
            />
          </div>
          <h3 class="text-lg font-medium">
            {{ $t("Delete.AreYouSure") }}
            {{
              deviceRemove
                ? $t("Delete.Remove")
                : isUserRemove
                ? $t("Delete.Remove")
                : $t("Delete.Delete")
            }}?
          </h3>
        </div>
        <!--        <div v-if="deviceRemove" class="py-4 space-y-4">-->
        <!--          <h2><b>Remove:</b> user will be removed from the device and all history data from the device will be lost</h2>-->
        <!--          <h2><b>Unassign:</b> user will be removed from the device, but all history data will be preserved</h2>-->
        <!--        </div>-->
        <h2 class="py-4 text-center">
          {{ deleteText
          }}<b>{{
            selectedData && selectedData?.assignment?.name
              ? selectedData?.assignment.name
              : selectedData?.email
          }}</b>
        </h2>
        <div v-if="deviceRemove" class="mt-4 flex justify-end space-x-3">
          <the-button
            :text="$t('General.Cancel')"
            buttonType="oulineButton"
            variant="outline"
            @execute-method="$emit('cancel-delete')"
          />
          <the-button
            :is-loading="isSubmitLoading"
            :text="$t('General.Remove')"
            variant="delete"
            @execute-method="$emit('confirm-remove')"
          />
          <!--          <the-button :is-loading="isSubmitLoading" buttonType="deleteButton"-->
          <!--                      text="Unassign" @execute-method="$emit('confirm-unassign')"/>-->
        </div>
        <div v-else class="mt-4 grid w-full grid-cols-2 gap-10">
          <the-button
            :text="$t('General.Cancel')"
            variant="outline"
            @execute-method="$emit('cancel-delete')"
          />
          <the-button
            v-if="!deviceRemove"
            :is-loading="isSubmitLoading"
            :text="$t('General.Delete')"
            variant="delete"
            @execute-method="$emit('confirm-delete')"
          />
        </div>
      </div>
    </modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import TheButton from "../TheButton.vue";
import Modal from "../Modal.vue";

export default defineComponent({
  name: "DeleteModal",
  components: { TheButton, Modal },
  props: [
    "selectedData",
    "deleteText",
    "deviceRemove",
    "isDeleting",
    "isSubmitLoading",
    "isUserRemove",
    "haveModal",
  ],
  emits: [
    "toggle-modal",
    "confirm-delete",
    "cancel-delete",
    "confirm-unassign",
    "confirm-remove",
  ],
});
</script>
<style></style>
