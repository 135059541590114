import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4 px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dx_date_box = _resolveComponent("dx-date-box")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "have-modal": true,
    "is-displayed": _ctx.isShowRouteModalOpened,
    title: 'Route time range',
    removeOutsideClick: true,
    onToggleModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_dx_date_box, {
          modelValue: _ctx.from,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.from) = $event)),
          height: 48,
          "display-format": "dd.MM.yyyy HH:mm",
          type: "datetime",
          class: "mt-1",
          clearable: false
        }, null, 8, ["modelValue"]),
        _createVNode(_component_dx_date_box, {
          modelValue: _ctx.to,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.to) = $event)),
          height: 48,
          "display-format": "dd.MM.yyyy HH:mm",
          type: "datetime",
          class: "mt-1",
          clearable: false
        }, null, 8, ["modelValue"]),
        _createVNode(_component_the_button, {
          text: "Show route",
          "button-type": "primaryButton",
          class: "mt-1",
          onClick: _ctx.showAssetRouteOnTheMap
        }, null, 8, ["onClick"]),
        _createVNode(_component_the_button, {
          text: "Cancel",
          "button-type": "primaryButton",
          class: "mt-1",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggle-modal')))
        })
      ])
    ]),
    _: 1
  }, 8, ["is-displayed"]))
}