<template>
  <modal
    :is-displayed="isQrModalOpened"
    :no-padding="true"
    :title="deviceDetails.name"
    :have-modal="haveModal"
    @toggle-modal="$emit('toggle-modal')"
  >
    <div
      id="printMe"
      class="flex max-h-[500px] flex-col items-center space-y-4 overflow-y-scroll"
    >
      <div class="flex space-x-4">
        <!--        <p class="text-sm"><b>Device type:</b> {{ deviceDetails.deviceType.name }}</p>-->
        <p class="text-sm"><b>Device UUID:</b> {{ deviceDetails.uuid }}</p>
      </div>
      <div class="flex flex-col items-center border border-lightgray p-2">
        <qrcode-vue
          :size="80"
          :value="`https://a.spotium.hr/act?uuid=${deviceDetails.uuid}`"
          level="L"
        />
        <p class="pb-2 text-center text-[8px]">
          {{ deviceDetails.name }}
        </p>
      </div>
      <div class="flex flex-col items-center border border-lightgray p-2">
        <qrcode-vue
          :size="130"
          :value="`https://a.spotium.hr/act?uuid=${deviceDetails.uuid}`"
          level="L"
        />
        <p class="pb-2 text-center text-[9px]">
          {{ deviceDetails.name }}
        </p>
      </div>
      <div class="flex flex-col items-center border border-lightgray p-2">
        <qrcode-vue
          :size="220"
          :value="`https://a.spotium.hr/act?uuid=${deviceDetails.uuid}`"
          level="L"
        />
        <p class="pb-2 text-center text-[10px]">
          {{ deviceDetails.name }}
        </p>
      </div>
      <div class="flex flex-col items-center border border-lightgray p-2">
        <qrcode-vue
          :size="300"
          :value="`https://a.spotium.hr/act?uuid=${deviceDetails.uuid}`"
          level="L"
        />
        <p class="pb-2 text-center text-[11px]">
          {{ deviceDetails.name }}
        </p>
      </div>
    </div>
    <the-button
      v-print="'#printMe'"
      button-type="primaryButton"
      size="full"
      text="Print"
    />
  </modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Modal from "@/core/Components/Modal.vue";
import QrcodeVue from "qrcode.vue";
import TheButton from "@/core/Components/TheButton.vue";

export default defineComponent({
  components: { Modal, QrcodeVue, TheButton },
  props: ["deviceDetails", "isQrModalOpened", "haveModal"],
  emits: ["toggle-modal"],
  methods: {
    printMe() {},
  },
});
</script>
<style scoped>
@media print {
  #printMe {
    max-height: fit-content;
  }

  #printMe::-webkit-scrollbar {
    display: none;
  }

  #printMe {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

#printMe::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

#printMe::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

#printMe::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  @apply bg-primary;
}
</style>
