import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import { MapImage } from "@/modules/MapView/Services/Types/MapImageTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class MapImageService {
  async getMapImage(floorplanId: number): Promise<MapImage> {
    const url = GenerateUrl({
      path: `/api/map-images/map-object/${floorplanId}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });
    const response = await ApiService.get(url, {
      responseType: "blob",
    });
    return response.data;
  }

  async saveMapImage({
    id,
    formData,
  }: {
    id: number;
    formData: FormData;
  }): Promise<any> {
    const url = GenerateUrl({
      path: `/api/map-images/map-object/${id}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    return await ApiService.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async deleteMapImage(id: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/map-images/map-object/${id}`,
      prefixPath: ConfigurationService.configData.SPOTIUM_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }
}
