import L, { LatLng } from "leaflet";
import "leaflet-arrowheads";
import MarkerService from "@/modules/MapView/Services/MarkerService";

export default class PolylineService {
  map = {} as L.Map;
  polylineGroup = new L.FeatureGroup();
  markerService = {} as MarkerService;

  constructor(map: L.Map, markerService: MarkerService) {
    this.map = map;
    this.markerService = markerService;
  }

  addPolyline(latLng: number[][]) {
    const latLngConverted = latLng
      .reverse()
      .map((latLng) => new LatLng(latLng[0], latLng[1]));

    const polyline = new L.Polyline(latLngConverted, {
      color: "#007CC1",
      weight: 6,
      lineJoin: "round",
      stroke: true,
    }).arrowheads({
      size: "10px",
      weight: 2,
      fill: false,
      frequency: latLng.length / 12,
      color: "#fff",
    });

    if (!latLngConverted.length) {
      return;
    }

    const lastElementIndex = latLngConverted.length - 1;

    const startMarker = this.markerService.createMarkerWithoutGroup(
      latLngConverted[0]?.lat,
      latLngConverted[0]?.lng,
      {
        iconUrl: "map-icons/start_route.png",
        iconSize: [35, 35],
      }
    );
    const endMarker = this.markerService.createMarkerWithoutGroup(
      latLngConverted[lastElementIndex]?.lat,
      latLngConverted[lastElementIndex]?.lng,
      {
        iconUrl: "map-icons/end_route.png",
        iconSize: [35, 35],
      }
    );

    this.polylineGroup
      .addLayer(polyline)
      .addLayer(startMarker)
      .addLayer(endMarker)
      .addTo(this.map);

    this.map.fitBounds(this.polylineGroup.getBounds());
  }

  removePolyline() {
    this.polylineGroup.removeFrom(this.map);
  }
}
