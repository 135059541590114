import { Role } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/RoleTypes";
import { GenerateUrl } from "@/core/Services/GlobalFunctions";
import ApiService from "@/core/Axios/ApiService";
import {
  CreateResource,
  Resource,
  ResourceDto,
  ResourceResponse,
} from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/AuthorizationTypes";
import { ResourceType } from "@/modules/AdminDashboard/Submodules/Authorization/Services/Types/AuthorizationTypeType";
import { PatchObject } from "@/core/Services/GlobalTypes";
import ConfigurationService from "@/core/Services/ConfigurationService";

export default class AuthorizationService {
  async getAllResources(): Promise<ResourceDto[]> {
    const url = GenerateUrl({
      path: `/api/authorization/resources`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: 1,
        PageSize: 100000,
        Include: [
          "ToCompany",
          "OnCompany",
          "ResourceType",
          "Role",
          "CreatedUser",
          "Policy",
          "Company",
        ],
      },
    });

    const response = await ApiService.get(url);

    return this._mapResourceDto(response.data.items);
  }

  async getCompanyResources(): Promise<ResourceDto[]> {
    const url = GenerateUrl({
      path: `/api/authorization/resources`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: 1,
        PageSize: 100000,
        ResourceTypeId: "Company",
        Include: [
          "ToCompany",
          "OnCompany",
          "ResourceType",
          "Role",
          "CreatedUser",
          "Policy",
          "Company",
        ],
      },
    });

    const response = await ApiService.get(url);

    return this._mapResourceDto(response.data.items);
  }

  async getGlobalResources(
    pageNumber = 1,
    pageSize = 10,
    resourceTypeId = "Company",
    emailSearchValue?: string
  ): Promise<ResourceDto[]> {
    const url = GenerateUrl({
      path: `/api/authorization/resources/global/users`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortOrder: "Ascending",
        SortBy: "Email",
        ResourceTypeId: resourceTypeId,
        ...(emailSearchValue != "" && { Email: emailSearchValue }),
      },
    });

    const response = await ApiService.get(url);
    response.data.items = this._mapResourceDto(response.data.items);
    return response.data;
  }

  async getResourceById(resourceId: number): Promise<Resource> {
    const url = GenerateUrl({
      path: `/api/authorization/resources/${resourceId}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: 1,
        PageSize: 100000,
        Include: [
          "ToCompany",
          "OnCompany",
          "ResourceType",
          "Role",
          "CreatedUser",
          "Policy",
          "Company",
        ],
      },
    });

    const response = await ApiService.get(url);

    return this._mapResource(response.data);
  }

  async editResource(id: number, resource: PatchObject[]): Promise<any> {
    const url = GenerateUrl({
      path: `/api/authorization/resources/${id}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });
    return await ApiService.patch(url, resource);
  }

  async deleteResource(resourceId: number): Promise<any> {
    const url = GenerateUrl({
      path: `/api/authorization/resources/${resourceId}`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.delete(url);
  }

  async createResource(resource: CreateResource): Promise<any> {
    const url = GenerateUrl({
      path: `/api/authorization/resources`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
    });

    return await ApiService.post(url, resource);
  }

  async getResourceTypes(allResourceTypes = false): Promise<ResourceType[]> {
    const url = GenerateUrl({
      path: `/api/authorization/resource-types`,
      prefixPath: ConfigurationService.configData.MANAGEMENT_API_PREFIX_PATH,
      config: {
        PageNumber: 1,
        PageSize: 100000,
      },
    });

    const response = await ApiService.get(url);

    //Remove DeviceType and None from Resource type array (DeviceType and None are not used)
    //But are still on API
    return allResourceTypes
      ? this._mapResourceTypes(response.data.items)
      : this._mapResourceTypes(response.data.items).slice(0, -2);
  }

  private _mapResourcesResponse(
    response: ResourceResponse[]
  ): ResourceResponse[] {
    return response.map((resource) => this._mapResourceResponse(resource));
  }

  private _mapResourceResponse(resource: ResourceResponse): ResourceResponse {
    return {
      id: resource.id,
      username: resource.username,
      email: resource.email,
      languageCode: resource.languageCode,
      resources: this._mapResources(resource.resources),
    };
  }

  private _mapResources(response: Resource[]): Resource[] {
    return response.map((resource) => this._mapResource(resource));
  }

  private _mapResource(resource: Resource): Resource {
    return {
      id: resource.id,
      toCompany: resource.toCompany,
      toCompanyId: resource.toCompanyId,
      toUserId: resource.toUserId,
      toUser: resource.toUser,
      onCompanyId: resource.onCompanyId,
      onCompany: resource.onCompany,
      resourceTypeId: resource.resourceTypeId,
      resourceType: resource.resourceType,
      onResourceId: resource.onResourceId,
      onResource: resource.onResource,
      roleId: resource.roleId,
      role: resource.role,
      resourcePermissions: resource.resourcePermissions,
      createdUserId: resource.createdUserId,
      createdUser: resource.createdUser,
      policyId: resource.policyId,
      policy: resource.policy,
      companyId: resource.companyId,
      company: resource.company,
    };
  }

  private _mapResourceDto(resourceResponse: ResourceResponse[]): ResourceDto[] {
    const formattedData: ResourceDto[] = [];
    resourceResponse.forEach((response) => {
      response.resources.forEach((resource) => {
        formattedData.push({
          id: resource.id,
          userEmail: response.email,
          roleName: resource.role?.name,
          resourceTypeName: resource.resourceType?.name,
          resourceName: resource.onResource?.name ?? "",
          resourceId: resource.onResourceId,
          createdUserEmail: resource.createdUser?.email,
        });
      });
    });

    return formattedData;
  }

  private _mapResourceTypes(resourceTypes: ResourceType[]): ResourceType[] {
    return resourceTypes.map((resourceType) =>
      this._mapResourceType(resourceType)
    );
  }

  private _mapResourceType(resourceType: ResourceType): ResourceType {
    //Remove assignment to remove confusion for users
    if (resourceType.name.includes("DeviceAssignment")) {
      resourceType.name = resourceType.name.replace(
        "DeviceAssignment",
        "Asset"
      );
    }
    return {
      id: resourceType.id,
      name: resourceType?.name,
      description: resourceType.description,
      resourceId: resourceType.resourceId,
    };
  }
}
