import checkIfObjectEmpty, {
  XYToLatLng,
} from "@/core/Services/GlobalFunctions";
import L, { LatLng } from "leaflet";
import { MapObjectService } from "@/modules/MapView/Services/MapObjectService";
import { MapObject } from "@/modules/MapView/Services/Types/MapObjectTypes";
import { LayerOptionsExtended } from "node_modules/@types/leaflet/index.d.ts";
import { useUserStore } from "@/core/Store/userStore";
import CoordinateConversionService from "@/modules/MapView/Services/CoordinateConversionService";
import MapImageService from "@/modules/MapView/Services/MapImageService";
import { Polygon } from "geojson";
import { FloorplanMapping } from "@/modules/MapView/Services/Types/FloorplanTypes";
import "leaflet-toolbar";
import "leaflet-distortableimage";
import "leaflet-distortableimage/dist/leaflet.distortableimage.css";
import "leaflet-toolbar/dist/leaflet.toolbar.css";

export default class FloorplansService {
  map: L.Map;
  mapContainer: HTMLDivElement;
  groups = {
    floorPlans: new L.LayerGroup(),
    floorPlanCreate: new L.LayerGroup(),
  };
  mapObjectService = new MapObjectService();
  mapImageService = new MapImageService();
  userStore = useUserStore();
  floorplanCreateDetails: {
    name: string;
    image: File | Blob | null;
    floorplanObject: any;
    floorPlan: number | null;
  } = {
    name: "",
    image: null,
    floorplanObject: null,
    floorPlan: null,
  };
  coordinateConversionService = new CoordinateConversionService();
  editedWktCoordinates: string = "";

  constructor(map: L.Map, mapContainer: HTMLDivElement) {
    this.map = map;
    this.mapContainer = mapContainer;
  }

  async addFloorPlanToMap({
    imageUrl,
    name,
    floorId,
    width,
    height,
    imgFile,
  }: {
    imageUrl: string;
    name: string;
    floorId: number;
    width: number;
    height: number;
    imgFile: File;
  }) {
    const points = this._generateFloorplanImageCoordinates({ width, height });

    const floorPlanOverlay = L.distortableImageOverlay(imageUrl, {
      opacity: 0.5,
      pmIgnore: true,
      selected: true,
      mode: "scale",
      actions: [
        L.RotateAction,
        L.OpacityAction,
        L.DistortAction,
        L.ScaleAction,
        L.LockAction,
        L.StackAction,
      ],
      corners: points,
    });

    this.floorplanCreateDetails = {
      name: name,
      image: imgFile,
      floorplanObject: floorPlanOverlay,
      floorPlan: floorId,
    };
    this.groups.floorPlanCreate.addLayer(floorPlanOverlay);
    this.map.addLayer(this.groups.floorPlanCreate);
  }

  async drawFloorplansOnMap(
    floorplans: MapObject[],
    floorplanMapping?: Map<number, FloorplanMapping>
  ) {
    this.map.addLayer(this.groups.floorPlans);
    for (const floorplan of floorplans) {
      const image = await this.mapImageService.getMapImage(floorplan.id);
      const base64Image = URL.createObjectURL(image);

      const points = this.coordinateConversionService.convertToGeoJson(
        floorplan.geometry
      ) as Polygon;

      const convertedCoordinates = points.coordinates[0].map(([lng, lat]) => {
        return new L.LatLng(lat, lng);
      });

      convertedCoordinates.pop();

      const createdFloorPlan = L.distortableImageOverlay(base64Image, {
        pmIgnore: true,
        editable: false,
        actions: [
          L.RotateAction,
          L.OpacityAction,
          L.DistortAction,
          L.ScaleAction,
          L.LockAction,
          L.StackAction,
        ],
        corners: convertedCoordinates,
      });

      this.groups.floorPlans.addLayer(createdFloorPlan);

      const self = this;
      L.DomEvent.on(createdFloorPlan.getElement(), "load", function () {
        L.DomEvent.on(
          createdFloorPlan,
          "edit",
          () =>
            (self.editedWktCoordinates =
              self.coordinateConversionService.convertToWkt(
                new L.Polygon<any>(createdFloorPlan.getCorners())
              ))
        );
        L.DomEvent.on(
          createdFloorPlan.getElement(),
          "mouseup touchend",
          () =>
            (self.editedWktCoordinates =
              self.coordinateConversionService.convertToWkt(
                new L.Polygon<any>(createdFloorPlan.getCorners())
              ))
        );
      });

      if (!floorplanMapping) {
        return;
      }

      floorplanMapping.set(floorplan.id, {
        floorplan: createdFloorPlan,
        floorplanData: floorplan,
      });
    }

    //this._fitFloorplanBounds();
  }

  cancelFloorPlanCreation() {
    if (this.map.hasLayer(this.groups.floorPlanCreate)) {
      this.groups.floorPlanCreate.clearLayers();
      this.map.removeLayer(this.groups.floorPlanCreate);
    }
  }

  cancelFloorPlanEdit(floorPlan: any, initialFloorPlan: LatLng[]) {
    floorPlan.setCorners(initialFloorPlan);
    floorPlan.editing.disable();
  }

  removeFloorPlan(id: number) {
    this.groups.floorPlans.eachLayer((layer: L.Layer) => {
      const options = layer.options as LayerOptionsExtended;
      if (options.id === id) {
        this.groups.floorPlans.removeLayer(layer);
      }
    });
  }

  removeFloorPlans() {
    if (this.map.hasLayer(this.groups.floorPlans)) {
      console.log("clearing floorplans", this.groups.floorPlans);
      // this.groups.floorPlans.clearLayers();
      this.map.removeLayer(this.groups.floorPlans);
    }
  }

  fitSelectedFloorPlanBounds(selectedFloorplan: any) {
    if (this.map.hasLayer(selectedFloorplan)) {
      this.map.fitBounds(selectedFloorplan.getCorners());
    }
  }

  private _fitFloorplanBounds() {
    const bounds = L.latLngBounds([]);
    this.groups.floorPlans.eachLayer((layer: any) => {
      const imgCorners = layer.getCorners();
      bounds.extend(imgCorners);
    });
    if (checkIfObjectEmpty(bounds)) {
      return;
    }
    this.map.fitBounds(bounds);
  }

  private _generateFloorplanImageCoordinates({
    width,
    height,
  }: {
    width: number;
    height: number;
  }): [L.LatLng, L.LatLng, L.LatLng, L.LatLng] {
    const mapCenter = this.map.getCenter();
    const MAP_CONTAINER_WIDTH = this.mapContainer.offsetWidth;
    const MAP_CONTAINER_HEIGHT = this.mapContainer.offsetHeight;

    const predefinedImageSize = 550;
    const aspectRatio = width / height;

    const latLng = XYToLatLng(
      mapCenter.lat,
      mapCenter.lng,
      21,
      1600,
      873,
      MAP_CONTAINER_WIDTH / 2,
      MAP_CONTAINER_HEIGHT / 2
    );
    const latLng2 = XYToLatLng(
      mapCenter.lat,
      mapCenter.lng,
      21,
      1600,
      873,
      MAP_CONTAINER_WIDTH / 2 + predefinedImageSize * aspectRatio,
      MAP_CONTAINER_HEIGHT / 2
    );
    const latLng3 = XYToLatLng(
      mapCenter.lat,
      mapCenter.lng,
      21,
      1600,
      873,
      MAP_CONTAINER_WIDTH / 2,
      MAP_CONTAINER_HEIGHT / 2 + predefinedImageSize
    );
    const latLng4 = XYToLatLng(
      mapCenter.lat,
      mapCenter.lng,
      21,
      1600,
      873,
      MAP_CONTAINER_WIDTH / 2 + predefinedImageSize * aspectRatio,
      MAP_CONTAINER_HEIGHT / 2 + predefinedImageSize
    );

    const point1 = L.latLng(latLng.lat, latLng.lon);
    const point2 = L.latLng(latLng2.lat, latLng2.lon);
    const point3 = L.latLng(latLng3.lat, latLng3.lon);
    const point4 = L.latLng(latLng4.lat, latLng4.lon);

    return [point1, point2, point3, point4];
  }
}
