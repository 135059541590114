<template>
  <div
    ref="movableElement"
    class="absolute bottom-10 left-80 z-[10000] min-w-[250px] bg-white shadow-xl"
  >
    <div class="relative">
      <div class="absolute right-0 top-0">
        <font-awesome-icon
          class="rounded-md p-1.5 hover:bg-primaryhover"
          :icon="['fas', 'xmark']"
          @click="$emit('closeDataCard')"
        />
      </div>
      <div class="bg-gray-200 px-4 py-2">
        {{ selectedDevice?.assignment?.name }}
      </div>
      <div class="p-4">
        <div v-for="(value, key) in data">
          {{ value.label }}:
          <strong>{{ value.value }}</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { DeviceAssignment } from "@/modules/Devices/Submodules/Assignments/Services/AssignmentService";
import { JSONPath } from "jsonpath-plus";

export default defineComponent({
  props: {
    selectedDevice: {
      type: Object as PropType<DeviceAssignment>,
    },
    webSocketData: {
      type: Object as PropType<any>,
    },
    parentElement: {
      type: Object as PropType<HTMLDivElement>,
    },
  },

  data() {
    return {
      data: {} as any,
      movableElement: {} as HTMLDivElement,
      isDragging: false,
      x: 10,
      y: 10,
    };
  },

  watch: {
    webSocketData: {
      handler: function (val: any) {
        console.log(val);

        this.data = {};
        this.MapMetadataToData();
      },
      deep: true,
    },

    selectedDevice: {
      handler: function (val: DeviceAssignment) {
        console.log(val);

        this.data = {};
        this.MapMetadataToData();
      },
      deep: true,
    },
  },

  mounted() {
    console.log("selected u data card - ", this.selectedDevice);

    this.MapMetadataToData();
    this.movableElement = this.$refs.movableElement as HTMLDivElement;

    // this.movableElement.addEventListener("mousedown", (e) => {
    //   this.isDragging = true;
    //   this.x = e.clientX;
    //   this.y = e.clientY;
    // });
    //
    // this.parentElement?.addEventListener("mousemove", (e) => {
    //   if (this.isDragging) {
    //     this.x = e.clientX;
    //     this.y = e.clientY;
    //     const rect = this.movableElement.getBoundingClientRect();
    //
    //     this.movableElement.style.left = rect.left + this.x + "px";
    //     this.movableElement.style.top = rect.top + this.y + "px";
    //   }
    // });
    //
    // this.parentElement?.addEventListener("mouseup", (e) => {
    //   this.isDragging = false;
    // });
  },

  methods: {
    MapMetadataToData() {
      const dataMetadata = this.selectedDevice?.assignment?.metadata.find(
        (metadata) =>
          metadata.metadataTypeId === 2 || metadata.metadataTypeId === 1
      );
      /* console.log("dataMetadata ", dataMetadata); */

      if (!dataMetadata) {
        return;
      }
      /* const metadataData = dataMetadata.data as {
        channels: number[];
        paths: { name: string; path: string }[];
      };
      metadataData.paths.forEach((path) => {
        const key = this.getKeyFromPath(path.path);
        if (!key) {
          return;
        }

        const value = JSONPath({ path: path.path, json: this.webSocketData });
        this.data[key] = {
          label: path.name,
          value: value.length > 0 ? value[0] : "",
        };
        console.log(this.data[key]);
      }); */
    },

    getKeyFromPath(path: string) {
      return path.split(".").at(-1);
    },
  },
});
</script>

<style scoped></style>
