<template>
  <v-select
    v-if="companyCount > 1"
    v-model="selectedCompany"
    :options="paginatedCompanies.items"
    :reduce="(company : any) => company.id"
    class="company-select my-2 w-full"
    label="name"
    :clearable="false"
    @input="handleCompanySearch"
    @close="handleDropdownClose"
  >
    <template #list-footer>
      <div
        v-if="paginatedCompanies.hasNextPage"
        class="flex justify-center align-middle"
      >
        <button
          class="mt-1 cursor-pointer bg-primary px-3 py-1 text-white transition duration-300 hover:bg-primaryhover disabled:cursor-not-allowed"
          @click="loadMoreCompanies"
        >
          Load more...
        </button>
      </div>
    </template>
  </v-select>
  <h2 v-else class="font-bold text-primary">
    {{
      paginatedCompanies.items.length
        ? paginatedCompanies.items[0].name
        : "You are not in any company!"
    }}
  </h2>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import VSelect from "vue-select";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import { useUserStore } from "@/core/Store/userStore";
import CompanyService, {
  CompanyObject,
} from "@/modules/Companies/Services/CompanyService";

export default defineComponent({
  components: {
    VSelect,
  },
  emits: ["update-active-company"],
  data() {
    return {
      toast: useToast(),
      userStore: useUserStore(),
      companyService: new CompanyService(),
      selectedCompany: null as number | null,
      paginatedCompanies: {
        items: [],
        pageIndex: 0,
        totalPages: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      } as CompanyObject,
      pageSize: 100,
      pageNumber: 1,
      searchCompanyText: "",
      searchTimeoutId: undefined as any,
      companyCount: 0,
    };
  },
  watch: {
    selectedCompany(newSelectedCompany) {
      if (newSelectedCompany) {
        this.$emit("update-active-company", newSelectedCompany);
      }
    },
  },
  created() {
    this.selectedCompany = this.userStore.activeCompanyId;
    this.getCompanies();
  },
  methods: {
    async getCompanies() {
      try {
        this.paginatedCompanies = await this.companyService.getAllCompanies(
          this.pageNumber,
          this.pageSize
        );

        this.companyCount = this.paginatedCompanies.items.length;
      } catch (error: any) {
        this.toast.error(ErrorMessageFormatter(error));
      }

      this.addMissingCompany();
    },
    async loadMoreCompanies() {
      if (this.paginatedCompanies.hasNextPage) {
        this.pageNumber += 1;
        try {
          const response = await this.companyService.getAllCompanies(
            this.pageNumber,
            this.pageSize,
            this.searchCompanyText
          );

          const newCompanies = response.items.filter(
            (newCompany) => newCompany.id !== this.selectedCompany
          );

          this.paginatedCompanies.items.push(...newCompanies);
          this.paginatedCompanies.hasNextPage = response.hasNextPage;
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }
    },
    async addMissingCompany() {
      const existingCompany = this.paginatedCompanies.items.find(
        (company) => company.id === this.selectedCompany
      );

      if (!existingCompany) {
        try {
          const company = await this.companyService.getCompanyWithIdentifier(
            this.selectedCompany!
          );

          this.paginatedCompanies.items.unshift(company);

          this.selectedCompany = null;
          setTimeout(() => {
            this.selectedCompany = company.id;
          }, 100);
        } catch (error) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }
    },
    async handleCompanySearch(e: any) {
      this.searchCompanyText = e.target.value;
      this.pageNumber = 1;

      if (this.searchTimeoutId) {
        clearTimeout(this.searchTimeoutId);
      }

      this.searchTimeoutId = setTimeout(async () => {
        try {
          const response = await this.companyService.getAllCompanies(
            this.pageNumber,
            this.pageSize,
            this.searchCompanyText
          );
          this.paginatedCompanies = response;
        } catch (error: any) {
          this.toast.error(ErrorMessageFormatter(error));
        }
      }, 800);
    },
    async handleDropdownClose() {
      this.pageNumber = 1;
      this.searchCompanyText = "";
      this.getCompanies();
    },
  },
});
</script>

<style scoped></style>
