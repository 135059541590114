<template>
  <main-section>
    <div class="flex h-full items-center justify-center space-x-4">
      <div class="flex items-center border-r-4 border-grey pr-4">
        <h2 class="text-8xl">4</h2>
        <img
          alt="spotium logo"
          class="rounded-full shadow-md"
          src="@/assets/spotium.png"
          width="100"
        />
        <h2 class="text-8xl">4.</h2>
      </div>
      <h2 class="font-medium">This page could not be found!</h2>
    </div>
  </main-section>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "./MainSection.vue";

export default defineComponent({
  components: {
    MainSection,
  },
});
</script>
<style scoped></style>
