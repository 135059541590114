import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-end space-x-6 bg-gray-50 px-6 py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_button = _resolveComponent("the-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasReturnToListButton)
      ? (_openBlock(), _createBlock(_component_the_button, {
          key: 0,
          icon: ['fas', 'chevron-left'],
          "button-type": "linkButton",
          "icon-color": "text-darkgrey",
          text: "Return to list",
          type: "button",
          onExecuteMethod: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('return-to-list')))
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_the_button, {
      isLoading: _ctx.isSubmitLoading,
      text: _ctx.text ? _ctx.text : _ctx.$t('General.Save'),
      icon: _ctx.icon && _ctx.icon,
      "button-type": "primaryButton",
      onExecuteMethod: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('execute-method')))
    }, null, 8, ["isLoading", "text", "icon"])
  ]))
}