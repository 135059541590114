import { Tag } from "../Tag/TagService";

export type MapObject = {
  companyId: number;
  data: { name: string; tags?: Tag[]; fillColor?: string };
  geometry: string;
  id: number;
  userId: number;
  typeId: number;
  floorLevel: number | null;
};

export type MapObjectCreate = Omit<MapObject, "id">;

export const OBJECT_TYPES = {
  FLOORPLAN: 1,
  ZONE: 2,
} as const;

export type ObjectTypes = 1 | 2;
