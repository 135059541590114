<template>
  <div
      :class="`w-[250px] border-2 border-grey absolute z-40 h-auto right-0 top-8 shadow-lg bg-white py-2 px-1 rounded-lg space-y-2 text-sm transform  ease-in-out duration-300 origin-top ${
        isDropdownOpened ? 'scale-y-100' : 'scale-y-0'
      }`"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    isDropdownOpened: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style scoped></style>
