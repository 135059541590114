import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_form = _resolveComponent("create-form")!
  const _component_show_user_info_modal = _resolveComponent("show-user-info-modal")!
  const _component_delete_modal = _resolveComponent("delete-modal")!
  const _component_confirmation_modal = _resolveComponent("confirmation-modal")!
  const _component_loader = _resolveComponent("loader")!
  const _component_table_action_header = _resolveComponent("table-action-header")!
  const _component_custom_table = _resolveComponent("custom-table")!
  const _component_main_section = _resolveComponent("main-section")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isCreateModalOpened)
      ? (_openBlock(), _createBlock(_component_create_form, {
          key: 0,
          "is-create-modal-opened": _ctx.isCreateModalOpened,
          "refresh-data": _ctx.getAllUsers,
          onToggleModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isCreateModalOpened = false))
        }, null, 8, ["is-create-modal-opened", "refresh-data"]))
      : _createCommentVNode("", true),
    (_ctx.isShowUserInfoModalOpened)
      ? (_openBlock(), _createBlock(_component_show_user_info_modal, {
          key: 1,
          "selected-data": _ctx.selectedUser,
          "is-create-modal-opened": _ctx.isShowUserInfoModalOpened,
          onToggleModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowUserInfoModalOpened = false))
        }, null, 8, ["selected-data", "is-create-modal-opened"]))
      : _createCommentVNode("", true),
    _createVNode(_component_delete_modal, {
      "is-deleting": _ctx.isRemoveUserModalOpened,
      "is-submit-loading": _ctx.isDataSourceLoading,
      "is-user-remove": true,
      "selected-data": _ctx.selectedUser,
      "delete-text": "Are you sure you want to remove user ",
      onCancelDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isRemoveUserModalOpened = false)),
      onToggleModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isRemoveUserModalOpened = false)),
      onConfirmDelete: _ctx.toggleConfirmationModal
    }, null, 8, ["is-deleting", "is-submit-loading", "selected-data", "onConfirmDelete"]),
    _createVNode(_component_confirmation_modal, {
      "is-confirmation-modal-opened": _ctx.isConfirmationModalOpened,
      "delete-text": "removeUser",
      onCloseConfirmationModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onToggleModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isConfirmationModalOpened = false)),
      onConfirmAction: _ctx.removeUserFromCompany
    }, null, 8, ["is-confirmation-modal-opened", "onConfirmAction"]),
    _createVNode(_component_main_section, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(_component_loader, {
              key: 0,
              "inner-ring-thickness": "border-t-8",
              "outer-ring-thickness": "border-8"
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_table_action_header, {
                icon: ['fas', 'add'],
                "permission-id": _ctx.permissions.CreateCompanyUser,
                title: _ctx.$t('Users.Title'),
                "button-text": "AddUser",
                description: "Users are the people who will be using the platform. They can be assigned to one or more companies. List shows all users in the current company.",
                onExecuteMethod: _ctx.toggleCreateModal
              }, null, 8, ["permission-id", "title", "onExecuteMethod"]),
              _createVNode(_component_custom_table, {
                "is-data-source-loading": _ctx.isDataSourceLoading,
                options: _ctx.options,
                "table-data": _ctx.users,
                "table-headers": _ctx.tableHeaders,
                onSelectedRows: _ctx.getSelectedRows,
                onForceRefresh: _ctx.getAllUsers
              }, null, 8, ["is-data-source-loading", "options", "table-data", "table-headers", "onSelectedRows", "onForceRefresh"])
            ]))
      ]),
      _: 1
    })
  ]))
}