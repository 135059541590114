import L from "leaflet";
import {
  LeafletEventExtended,
  MarkerOptionsExtended,
} from "node_modules/@types/leaflet/index.d.ts";
import { ParkingSpot } from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";

export default class MarkerSelectService {
  map = {} as L.Map;
  spaceParkingSpotMarkers: L.Marker[] = [];
  selectedParkingSpots: L.Marker[] = [];
  isCtrlPressed = false;
  public onDragEnd: () => void;
  parkingSpotMap: Map<number, ParkingSpot>;
  distanceBetweenFirstAndLastSelected = 0;
  distanceBetweenSpots = 0;
  isEditButtonDisabled = true;

  constructor(
    map: L.Map,
    spaceParkingSpotMarkers: L.Marker[],
    onDragEnd: () => void,
    parkingSpotMap: Map<number, ParkingSpot>
  ) {
    this.map = map;
    this.spaceParkingSpotMarkers = spaceParkingSpotMarkers;
    this.onDragEnd = onDragEnd;
    this.parkingSpotMap = parkingSpotMap;
  }

  subscribeToParkingSpotPositionEdits() {
    this.map.on("boxzoomend", (e: LeafletEventExtended) => {
      this.resetMarkersToDeselectedState();
      this.spaceParkingSpotMarkers.forEach((marker) => {
        if (!e.boxZoomBounds) {
          return;
        }
        if (
          e.boxZoomBounds.contains([
            marker.getLatLng().lat,
            marker.getLatLng().lng,
          ])
        ) {
          const markerId = (marker.options as MarkerOptionsExtended).id;
          if (!markerId) {
            return;
          }
          const parkingSpotTypeId = this.parkingSpotMap.get(
            Number(markerId)
          )!.typeId;
          this._setParkingSpotIcon({
            marker,
            type: "green",
            parkingSpotTypeId,
          });
          // marker?.dragging?.enable();

          this.selectedParkingSpots.push(marker);
        }
      });
      this.reorganizeParkingSpotsOnDrag();
    });
  }

  resetMarkersToDeselectedState() {
    if (!this.selectedParkingSpots.length) {
      return;
    }
    this.distanceBetweenFirstAndLastSelected = 0;
    this.distanceBetweenSpots = 0;
    this.selectedParkingSpots.forEach((marker) => {
      marker.off("dragend");
      const markerId = (marker.options as MarkerOptionsExtended).id;
      if (!markerId) {
        return;
      }
      const parkingSpotTypeId = this.parkingSpotMap.get(
        Number(markerId)
      )!.typeId;

      this._setParkingSpotIcon({ marker, type: "red", parkingSpotTypeId });
      marker?.dragging?.disable();
    });

    this.selectedParkingSpots = [];
  }

  selectParkingSpotsOnCtrlClick(firstSpotIndex: number, lastSpotIndex: number) {
    if (firstSpotIndex < lastSpotIndex) {
      this.selectedParkingSpots = this.spaceParkingSpotMarkers.slice(
        firstSpotIndex,
        lastSpotIndex + 1
      );
    } else {
      this.selectedParkingSpots = this.spaceParkingSpotMarkers.slice(
        lastSpotIndex,
        firstSpotIndex + 1
      );
    }
    this.selectedParkingSpots.forEach((marker) => {
      this._setParkingSpotIcon({ marker, type: "green" });
    });
    this.reorganizeParkingSpotsOnDrag();
  }

  addControlKeyEventListener() {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Control") {
        this.isCtrlPressed = true;
      }
    });

    window.addEventListener("keyup", (e) => {
      if (e.key === "Control") {
        this.isCtrlPressed = false;
      }
    });
  }

  reorganizeParkingSpotsOnDrag() {
    const selectedParkingSpotsCount = this.selectedParkingSpots.length;
    if (selectedParkingSpotsCount < 1) {
      return;
    }

    this.isEditButtonDisabled = false;
    
    this.distanceBetweenFirstAndLastSelected = this.map.distance(this.selectedParkingSpots[0].getLatLng(),
      this.selectedParkingSpots[selectedParkingSpotsCount - 1]?.getLatLng());

    this.distanceBetweenSpots = this.selectedParkingSpots.length <= 1 ? 0 : this.distanceBetweenFirstAndLastSelected / (this.selectedParkingSpots.length - 1);

    this.selectedParkingSpots.forEach((selectedMarker, idx) => {

      if (idx === selectedParkingSpotsCount - 1) {
        selectedMarker.dragging?.enable();
        this._setParkingSpotIcon({ marker: selectedMarker, type: "last" });
        this.addDragListenerToParkingSpot(
          selectedMarker,
          selectedParkingSpotsCount
        );
      }

      if (idx === 0) {
        selectedMarker.dragging?.enable();
        this._setParkingSpotIcon({ marker: selectedMarker, type: "first" });

        this.addDragListenerToParkingSpot(
          selectedMarker,
          selectedParkingSpotsCount
        );
      }

    });
  }

  removeCtrlKeyEventListener() {
    window.removeEventListener("keydown", () => { });
    window.removeEventListener("keyup", () => { });
  }

  recalculateParkingSpotsPositions({
    parkingSensorsNumbers,
    latFirstPos,
    lngFirstPos,
    latLastPos,
    lngLastPos,
    sensorIdlist,
  }: {
    parkingSensorsNumbers: number;
    latFirstPos: number;
    lngFirstPos: number;
    latLastPos: number;
    lngLastPos: number;
    sensorIdlist: number[];
  }) {
    let diffFirstLastPostLat = Math.abs(latFirstPos - latLastPos);
    let diffFirstLastPostLng = Math.abs(lngFirstPos - lngLastPos);

    this.distanceBetweenFirstAndLastSelected = this.map.distance(this.selectedParkingSpots[0].getLatLng(),
      this.selectedParkingSpots[this.selectedParkingSpots.length - 1].getLatLng());
      this.distanceBetweenSpots = this.selectedParkingSpots.length <= 1 ? 0 : this.distanceBetweenFirstAndLastSelected / (this.selectedParkingSpots.length - 1);

    let parkingDistanceNum = parkingSensorsNumbers - 1;
    let sequenceLat = diffFirstLastPostLat / parkingDistanceNum;
    let sequenceLng = diffFirstLastPostLng / parkingDistanceNum;

    let positionList = [];
    if (latFirstPos < latLastPos && lngFirstPos < lngLastPos) {
      for (let i = 0; i < parkingSensorsNumbers; i++) {
        let latItem = latFirstPos + sequenceLat * i;
        let lngItem = lngFirstPos + sequenceLng * i;
        let obj = [latItem, lngItem, sensorIdlist[i]];
        positionList.push(obj);
      }
    } else if (latFirstPos > latLastPos && lngFirstPos > lngLastPos) {
      for (let i = 0; i < parkingSensorsNumbers; i++) {
        let latItem = latFirstPos - sequenceLat * i;
        let lngItem = lngFirstPos - sequenceLng * i;
        let obj = [latItem, lngItem, sensorIdlist[i]];
        positionList.push(obj);
      }
    } else if (latFirstPos <= latLastPos && lngFirstPos > lngLastPos) {
      for (let i = 0; i < parkingSensorsNumbers; i++) {
        let latItem = latFirstPos + sequenceLat * i;
        let lngItem = lngFirstPos - sequenceLng * i;
        let obj = [latItem, lngItem, sensorIdlist[i]];
        positionList.push(obj);
      }
    } else {
      for (let i = 0; i < parkingSensorsNumbers; i++) {
        let latItem = latFirstPos - sequenceLat * i;
        let lngItem = lngFirstPos + sequenceLng * i;
        let obj = [latItem, lngItem, sensorIdlist[i]];
        positionList.push(obj);
      }
    }

    return positionList;
  }

  addDragListenerToParkingSpot(
    selectedParkingSpot: L.Marker,
    selectedParkingSpotsCount: number
  ) {
    selectedParkingSpot.on("dragend", (evt) => {
      const response = this.recalculateParkingSpotsPositions({
        parkingSensorsNumbers: selectedParkingSpotsCount,
        latFirstPos: this.selectedParkingSpots[0].getLatLng().lat,
        lngFirstPos: this.selectedParkingSpots[0].getLatLng().lng,
        latLastPos:
          this.selectedParkingSpots[this.selectedParkingSpots.length - 1].getLatLng()
            .lat,
        lngLastPos:
          this.selectedParkingSpots[this.selectedParkingSpots.length - 1].getLatLng()
            .lng,
        sensorIdlist: this.selectedParkingSpots.map((marker, idx) => idx),
      });
      this.selectedParkingSpots.forEach((marker, idx) => {
        if (response[idx][0] && response[idx][1]) {
          marker.setLatLng({ lat: response[idx][0], lng: response[idx][1] });
        }
      });

      this.onDragEnd();
    });
  }

  recalculateParkingSpotLocationsByParkingSpaceLocation({
    lat,
    lng,
    markers,
  }: {
    lat: number;
    lng: number;
    markers: L.Marker[];
  }) {
    markers.forEach((marker, idx) => {
      marker.setLatLng({
        lat: lat,
        lng: lng + 0.00003 * idx,
      });
    });
  }

  private _setParkingSpotIcon({
    marker,
    type,
    parkingSpotTypeId
  }: {
    marker: L.Marker;
    type: "green" | "red" | "first" | "last";
    parkingSpotTypeId?: number;
  }) {
    switch (type) {
      case "green":
        switch (parkingSpotTypeId) {
          case 1:
            marker.setIcon(
              L.icon({
                iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/green_point.png`,
              })
            );
            break;
          case 2:
            marker.setIcon(
              L.icon({
                iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/handicapped_green.png`,
              })
            );
            break;
          case 3:
            marker.setIcon(
              L.icon({
                iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/charge_green.png`,
              })
            );
            break;
          default:
            marker.setIcon(
              L.icon({
                iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/green_point.png`,
              })
            );
        }

        break;
      case "red":
        switch (parkingSpotTypeId) {
          case 1:
            marker.setIcon(
              L.icon({
                iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/red_point.png`,
              })
            );
            break;
          case 2:
            marker.setIcon(
              L.icon({
                iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/handicapped_red.png`,
              })
            );
            break;
          case 3:
            marker.setIcon(
              L.icon({
                iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/charge_red.png`,
              })
            );
            break;
          default:
            marker.setIcon(
              L.icon({
                iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/red_point.png`,
              })
            );
        }
        break;
      case "first":
        marker.setIcon(
          L.icon({
            iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/first.png`,
          })
        );
        break;
      case "last":
        marker.setIcon(
          L.icon({
            iconUrl: `${process.env.VUE_APP_PUBLIC_PATH}map-icons/last.png`,
          })
        );
        break;
    }
  }
}
