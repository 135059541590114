<template>
  <modal-component
    :is-displayed="isAddToOrderModalOpened"
    :is-full-screen-modal="true"
    :title="$t('WorkOrders.AddToWorkOrderTitle')"
    @toggle-modal="$emit('toggle-modal')"
  >
    <delete-modal
      :is-deleting="isDeleteModalOpened"
      @cancel-delete="isDeleteModalOpened = false"
      @toggle-modal="isDeleteModalOpened = false"
      @confirm-delete="openConfirmationModal('delete')"
    />
    <confirmation-modal
      :delete-text="confirmationModalText"
      :is-confirmation-modal-opened="isConfirmationModalOpened"
      @confirm-action="confirmationModalAction"
      @close-confirmation-modal="isConfirmationModalOpened = false"
      @toggle-modal="isConfirmationModalOpened = false"
    />
    <div class="px-6">
      <div class="flex w-1/2 gap-8">
        <input-field v-model="mac" label="MAC" />
        <input-field v-model="imei" label="IMEI" />
      </div>
      <div v-if="isLoading" class="flex w-full justify-center">
        <small-loader />
      </div>
      <custom-table
        v-else
        :delete-object-modal="toggleDeleteModal"
        :force-refresh="true"
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :table-data="workOrderDevices"
        :table-headers="tableHeaders"
        @selected-rows="getSelectedRows"
      />
    </div>
  </modal-component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ModalComponent from "@/core/Components/Modal.vue";
import InputField from "@/core/Components/FormElements/InputField.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import DeviceService from "@/modules/Devices/Services/DeviceService";
import WorkOrderService from "@/modules/WorkOrders/Services/WorkOrderService";
import DeleteModal from "@/core/Components/Notifications/DeleteModal.vue";
import ConfirmationModal from "@/core/Components/Notifications/ConfirmationModal.vue";
import SmallLoader from "@/core/Components/SmallLoader.vue";
import { WorkOrderDevice } from "@/modules/WorkOrders/Services/WorkOrderTypes";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    SmallLoader,
    ConfirmationModal,
    DeleteModal,
    CustomTable,
    InputField,
    ModalComponent,
  },
  props: {
    isAddToOrderModalOpened: {
      type: Boolean,
      required: true,
    },
    workOrderId: {
      type: Number,
      required: true,
    },
    refreshData: Function,
  },
  emits: ["toggle-modal"],
  data() {
    return {
      deviceService: new DeviceService(),
      workOrderService: new WorkOrderService(),
      workOrderDevices: [] as WorkOrderDevice[],
      selectedDevice: {} as WorkOrderDevice,
      toast: useToast(),
      confirmationModalText: "",
      isConfirmationModalOpened: false,
      isDeleteModalOpened: false,
      isDataSourceLoading: false,
      isLoading: false,
      mac: "",
      imei: "",
      options: {
        delete: {
          header: "Action.Delete",
          icon: ["fas", "trash"],
          permissionId: Permissions.DeleteWorkOrder,
        },
      },
      tableHeaders: {
        "device.name": { header: "WorkOrders.Name", visible: true },
        "device.mac": { header: "MAC", visible: true },
      },
    };
  },

  async created() {
    this.isLoading = true;
    try {
      await this.getWorkOrderDevices();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  async unmounted() {
    await this.refreshData?.();
  },

  watch: {
    async mac(value) {
      const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
      if (macRegex.test(value)) {
        await this.getDeviceByIdentifier({
          identifier: value,
          type: "Mac",
        });
      } else {
        console.log("Invalid MAC");
      }
    },
    async imei(value) {
      const imeiRegex = /^\d{15}$/;
      if (imeiRegex.test(value)) {
        await this.getDeviceByIdentifier({
          identifier: value,
          type: "Imei",
        });
      } else {
        console.log("Invalid IMEI");
      }
    },
  },

  methods: {
    async getWorkOrderDevices() {
      this.isDataSourceLoading = true;
      try {
        this.workOrderDevices = await this.workOrderService.getWorkOrderDevices(
          {
            workOrderId: this.workOrderId,
            pageSize: 100000,
            pageNumber: 1,
          }
        );
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async getDeviceByIdentifier({
      identifier,
      type,
    }: {
      identifier: string | number;
      type: "Mac" | "Imei";
    }) {
      this.isDataSourceLoading = true;
      try {
        await this.workOrderService.addDeviceToWorkOrderWithIdentifier({
          identifiers: [identifier],
          identifierType: type,
          workOrderId: this.workOrderId,
        });
        this.toast.success(this.$t("WorkOrders.AddToWorkOrderSuccess"));
        await this.getWorkOrderDevices();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    async deleteDeviceFromWorkOrder() {
      try {
        await this.workOrderService.deleteWorkOrderDevice(
          this.selectedDevice.id
        );
        this.toast.success(this.$t("WorkOrders.RemoveFromWorkOrderSuccess"));
        this.isDeleteModalOpened = false;
        await this.getWorkOrderDevices();
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
      }
    },
    getSelectedRows(values: WorkOrderDevice[]) {
      if (values.length) {
        this.selectedDevice = values[0];
      }
    },
    async confirmationModalAction() {
      switch (this.confirmationModalText) {
        case "delete":
          await this.deleteDeviceFromWorkOrder();
          break;
      }
      this.isConfirmationModalOpened = false;
    },
    openConfirmationModal(key: string) {
      this.isConfirmationModalOpened = true;
      this.confirmationModalText = key;
    },
    toggleDeleteModal() {
      this.isDeleteModalOpened = !this.isDeleteModalOpened;
    },
  },
});
</script>

<style scoped></style>
