<template>
  <create-form
    v-if="isCreateModalOpened"
    :is-create-modal-opened="isCreateModalOpened"
    :refresh-data="getParkingSpots"
    @toggle-modal="toggleCreateModal"
  />
  <create-bulk-form
    v-if="isCreateBulkModalOpened"
    :is-create-bulk-modal-opened="isCreateBulkModalOpened"
    :refresh-data="getParkingSpots"
    @toggle-modal="isCreateBulkModalOpened = false"
  />
  <edit-form
    v-if="isEditFormModalOpened"
    :is-edit-form-modal-opened="isEditFormModalOpened"
    :parking-spot-prop="selectedParkingSpot"
    :refresh-data="getParkingSpots"
    @toggle-modal="isEditFormModalOpened = false"
  />
  <main-section>
    <loader v-if="isLoading" />
    <div v-else class="h-full">
      <div class="mb-4 flex w-full items-center justify-between">
        <h6 class="mb-0 text-xl font-semibold leading-normal">
          {{ $t("ParkingSpots.Title") }}
        </h6>
        <div class="space-x-4">
          <the-button
            v-if="permissionStore.hasPermission(permissions.CreateAssignment)"
            :icon="['fas', 'add']"
            :text="$t('Create')"
            @execute-method="toggleCreateModal"
          />
          <the-button
            v-if="permissionStore.hasPermission(permissions.CreateAssignment)"
            :icon="['fas', 'add']"
            :text="$t('CreateBulk')"
            @execute-method="toggleCreateBulkModal"
          />
        </div>
      </div>
      <custom-table
        :is-data-source-loading="isDataSourceLoading"
        :options="options"
        :render-summary="['space']"
        :show-per-page="16"
        :table-data="parkingSpots"
        :table-headers="tableHeaders"
        table-height="93%"
        :isActionListNotDisplayed="
          !permissionStore.hasPermission(permissions.EditAssignment)
        "
        @selected-rows="getSelectedRows"
        @force-refresh="getParkingSpots"
      />
    </div>
  </main-section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainSection from "@/core/Components/MainSection.vue";
import CustomTable from "@/core/Components/CustomTable.vue";
import TableActionHeader from "@/core/Components/TableActionHeader.vue";
import CreateForm from "../Components/CreateForm.vue";
import { ParkingSpot } from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotTypes";
import ParkingSpotService from "@/modules/Parkom/Submodules/ParkingSpots/Services/ParkingSpotService";
import { useToast } from "vue-toastification";
import { ErrorMessageFormatter } from "@/core/Services/GlobalFunctions";
import AssignForm from "../Components/AssignForm.vue";
import Loader from "@/core/Components/Loader.vue";
import EditForm from "../Components/EditForm.vue";
import { useChangeCompanyStore } from "@/core/Store/changeCompanyStore";
import TheButton from "@/core/Components/TheButton.vue";
import { usePermissionStore } from "@/core/Store/permissionStore";
import CreateBulkForm from "@/modules/Parkom/Submodules/ParkingSpots/Components/CreateBulkForm.vue";
import { Permissions } from "@/core/Services/Enums";

export default defineComponent({
  components: {
    CreateBulkForm,
    TheButton,
    Loader,
    CreateForm,
    CustomTable,
    MainSection,
    TableActionHeader,
    AssignForm,
    EditForm,
  },
  data() {
    return {
      parkingSpotService: new ParkingSpotService(),
      parkingSpots: [] as ParkingSpot[],
      selectedParkingSpot: {} as ParkingSpot,
      toast: useToast(),
      changeCompanyStore: useChangeCompanyStore(),
      permissionStore: usePermissionStore(),
      isCreateModalOpened: false,
      isCreateBulkModalOpened: false,
      isDataSourceLoading: false,
      isAssignModalOpened: false,
      isEditFormModalOpened: false,
      isLoading: false,
      permissions: Permissions,
      options: {
        edit: {
          header: "Action.Edit",
          icon: ["fas", "pen"],
          permissionId: Permissions.EditAssignment,
          action: this.toggleEditFormModal,
        },
      },
      tableHeaders: {
        id: { header: "ID", visible: false },
        space: { header: "ParkingSpots.Space", visible: true },
        spaceId: { header: "Sensors.SpaceId", visible: false },
        name: { header: "ParkingSpots.Name", visible: true },
        macAddress: { header: "ParkingSpots.MAC", visible: true },
        type: { header: "ParkingSpots.Type", visible: true },
        occupiedTimestamp: {
          header: "ParkingSpots.OccupiedTimestamp",
          visible: true,
        },
        occupied: { header: "ParkingSpots.Occupied", visible: true },
        index: { header: "ParkingSpots.Index", visible: false },
        public: { header: "ParkingSpots.Public", visible: true },
        latitude: { header: "ParkingSpots.Latitude", visible: false },
        longitude: { header: "ParkingSpots.Longitude", visible: false },
      },
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await this.getParkingSpots();
    } catch (error) {
      this.toast.error(ErrorMessageFormatter(error));
    } finally {
      this.isLoading = false;
    }
  },

  watch: {
    async "changeCompanyStore.companyId"() {
      await this.getParkingSpots();
    },
  },
  methods: {
    async getParkingSpots() {
      this.isDataSourceLoading = true;
      try {
        this.parkingSpots = await this.parkingSpotService.getParkingSpots({
          pageNumber: 1,
          pageSize: 100000,
        });
      } catch (error) {
        this.toast.error(ErrorMessageFormatter(error));
      } finally {
        this.isDataSourceLoading = false;
      }
    },
    getSelectedRows(values: ParkingSpot[]) {
      if (values.length) {
        this.selectedParkingSpot = values[0];
      }
    },
    toggleCreateModal() {
      this.isCreateModalOpened = !this.isCreateModalOpened;
    },
    toggleCreateBulkModal() {
      this.isCreateBulkModalOpened = !this.isCreateBulkModalOpened;
    },
    toggleEditFormModal() {
      this.isEditFormModalOpened = !this.isEditFormModalOpened;
    },
  },
});
</script>

<style scoped></style>
