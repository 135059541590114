import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { createApplicationIndexRoute } from "@/modules/Applications/Routes/ApplicationRoutes";
import { createIntegrationIndexRoute } from "@/modules/Integrations/Routes/IntegrationRoutes";
import { createCompanyIndexRoute } from "@/modules/Companies/Routes/CompanyRoutes";
import { createMaintenanceRoute } from "@/modules/Maintenance/Routes/MaintenanceRoutes";
import { createUserIndexRoute } from "@/modules/Users/Routes/UserRoutes";
import { createAccessManagerRoute } from "@/modules/AccessManager/Routes/AccessManagerRoutes";
import { createRouteNotFoundRoute } from "./RouteNotFound";
import { createParkomIndexRoute } from "@/modules/Parkom/Routes/ParkomRoutes";
import { createAdminIndexRoute } from "@/modules/AdminDashboard/Routes/AdminRoutes";
import { createWorkOrderIndexRoute } from "@/modules/WorkOrders/Routes/WorkOrderRoutes";
import { createDeviceActionIndexRoute } from "@/modules/DeviceActions/Routes/DeviceActionRoutes";
import { createDeviceIndexRoute } from "@/modules/Devices/Routes/DeviceRoutes";
import { createMapViewIndexRoute } from "@/modules/MapView/Routes/MapViewRoutes";
import { createResponseMessagesRoute } from "@/modules/ResponseMessages/Routes/ResponseMessagesRoutes";
import { createProductionIndexRoute } from "@/modules/Production/Routes/ProductionRoutes";
import { createFieldAnalyticsRoutes } from "@/modules/FieldAnalytics/Routes/FieldAnalyticsRoutes";
import ConfigurationService from "../Services/ConfigurationService";

export async function getAllRoutes(): Promise<RouteRecordRaw[]> {
  await ConfigurationService.loadConfiguration();

  if (ConfigurationService.configData.IS_ACCESS === "true") {
    return [
      createAccessManagerRoute(),
      createRouteNotFoundRoute(),
      createMaintenanceRoute(),
      createUserIndexRoute(),
      createAdminIndexRoute(),
    ];
  }

  if (ConfigurationService.configData.IS_PARKOM === "true") {
    return [
      createParkomIndexRoute(),
      createWorkOrderIndexRoute(),
      // createEVChargersIndexRoute(),
      createMaintenanceRoute(),
      createApplicationIndexRoute(),
      createCompanyIndexRoute(),
      createUserIndexRoute(),
      createRouteNotFoundRoute(),
      createAdminIndexRoute(),
      createResponseMessagesRoute(),
      createProductionIndexRoute(),
    ];
  }

  const routesArray = [
    createMapViewIndexRoute(),
    createMaintenanceRoute(),
    createDeviceIndexRoute(),
    createApplicationIndexRoute(),
    createIntegrationIndexRoute(),
    createCompanyIndexRoute(),
    createUserIndexRoute(),
    createDeviceActionIndexRoute(),
    createRouteNotFoundRoute(),
    createAdminIndexRoute(),
    createResponseMessagesRoute(),
    createProductionIndexRoute(),
  ];

  if (
    window.location.host === "fang-spt.pca.sick.hr" ||
    window.location.host === "spt-ipc.cops.sick.hr" ||
    window.location.host === "localhost:8080"
  ) {
    routesArray.push(createFieldAnalyticsRoutes());
  }

  return routesArray;
}

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes: await getAllRoutes(),
});

export default router;
