import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative items-center py-3" }
const _hoisted_2 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }
const _hoisted_3 = { class: "relative items-center py-3" }
const _hoisted_4 = { class: "whitespace-nowrap text-sm font-medium not-italic text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_json_editor_vue = _resolveComponent("json-editor-vue")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_form_divider = _resolveComponent("form-divider")!
  const _component_form_wrapper = _resolveComponent("form-wrapper")!
  const _component_button_wrapper = _resolveComponent("button-wrapper")!
  const _component_modal_component = _resolveComponent("modal-component")!

  return (_openBlock(), _createBlock(_component_modal_component, {
    "is-displayed": _ctx.isCreateModalOpened,
    "no-padding": true,
    title: _ctx.$t('DeviceActions.CreateTitle'),
    onToggleModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('toggle-modal')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_form_wrapper, { "is-form-in-modal": true }, {
          default: _withCtx(() => [
            _createVNode(_component_form_divider, null, {
              default: _withCtx(() => [
                _createVNode(_component_input_field, {
                  modelValue: _ctx.formData.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
                  label: _ctx.$t('DeviceActions.Name'),
                  errorMessage: _ctx.v$?.formData['name']?.$errors[0]?.$message,
                  isError: _ctx.v$?.formData['name']?.$error,
                  required: true
                }, null, 8, ["modelValue", "label", "errorMessage", "isError"]),
                _createVNode(_component_json_editor_vue, {
                  modelValue: _ctx.formData.data,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.data) = $event)),
                  "main-menu-bar": false,
                  "status-bar": false,
                  class: "py-3",
                  mode: "text"
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("DeviceActions.Device")), 1),
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.formData.deviceId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.deviceId) = $event)),
                    getOptionLabel: (assignment) => assignment.assignment.name,
                    options: _ctx.assignments,
                    reduce: (assignment) => assignment.deviceId,
                    class: "mt-1"
                  }, null, 8, ["modelValue", "getOptionLabel", "options", "reduce"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t("DeviceActions.DeviceActionType")), 1),
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.formData.deviceActionTypeId,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.deviceActionTypeId) = $event)),
                    getOptionLabel: (actionType) => actionType.name,
                    options: _ctx.deviceActionTypes,
                    reduce: (actionType) => actionType.id,
                    class: "mt-1"
                  }, null, 8, ["modelValue", "getOptionLabel", "options", "reduce"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_button_wrapper, { "is-submit-loading": _ctx.isSubmitLoading }, null, 8, ["is-submit-loading"])
      ], 32)
    ]),
    _: 1
  }, 8, ["is-displayed", "title"]))
}