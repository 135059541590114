import { RouteRecordRaw } from "vue-router";
import Index from "../Pages/Index.vue";
import CompanyAuthorizationIndex from "../Submodules/Authorization/Pages/Index.vue";
import GlobalAuthorizationIndex from "../Submodules/GlobalAuthorization/Pages/Index.vue";
import { Permissions } from "@/core/Services/Enums";

export function createAdminIndexRoute(): RouteRecordRaw {
  return {
    name: "adminIndex",
    path: "/",
    component: Index,
    redirect: {
      name: "companyAuthorizationIndex",
    },
    meta: {
      id: "adminIndex",
      label: "Sidebar.Admin",
      icon: ["fas", "user-gear"],
      showInNavigation: true,
      authorize: [Permissions.AuthView, Permissions.GlobalAuthView],
    },
    children: [
      {
        name: "companyAuthorizationIndex",
        path: "company-authorization",
        component: CompanyAuthorizationIndex,
        meta: {
          id: "companyAuthorizationIndex",
          label: "Sidebar.Authorization",
          icon: ["fas", "unlock"],
          showInNavigation: true,
          authorize: [Permissions.AuthView],
        },
      },
      {
        name: "adminGlobalAuthorization",
        path: "global-authorization",
        component: GlobalAuthorizationIndex,
        meta: {
          id: "adminGlobalAuthorization",
          label: "Sidebar.GlobalAuthorization",
          icon: ["fas", "earth-europe"],
          showInNavigation: true,
          authorize: [Permissions.GlobalAuthView],
        },
      },
    ],
  };
}
